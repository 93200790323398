<template>
  <div>
    <importcsv v-if="$route.params.mode=='create'"/>
    <queryresult v-if="!$route.params.mode" queryid="import"/>
    <record collection="imports" v-if="$route.params.mode=='view'"/>
    
  </div>
</template>

<script>
import importcsv from "../../components/importCSV/View";
/* eslint-disable */
export default {
  data() {
    return {
      loading: true
    };
  },
  components: {
    importcsv
  }
};
</script>
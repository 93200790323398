<template>
  <div>
    <v-autocomplete
      v-model="file"
      :items="records"
      item-text="name"
      item-value="id"
      :label="label"
      :prepend-icon="$route.params.mode == 'view' ? '' : 'add_box'"
      placeholder
      :search-input.sync="search"
      :return-object="recordObj"
      :rules="[rules.required]"
      :multiple="multiple"
      :readonly="$route.params.mode == 'view'"
      append-icon="file_copy"
      @click:prepend="add"
      outlined
      small-chips
      dense
    >
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          @click="data.select"
          small
          outlined
        >
          <v-icon left v-if="data.item.name.indexOf('.pdf') > 0">
            mdi-file-pdf
          </v-icon>
          <v-avatar left v-else>
            <v-img :src="data.item.url" alt="asd"></v-img>
          </v-avatar>
          <a
            color="primary"
            :key="data.item._id"
            :href="data.item.url"
            target="_blank"
            class="link d-block"
            >{{ data.item.displayname || data.item.name }}</a
          >
        </v-chip>
      </template>
    </v-autocomplete>
    <v-layout row justify-center>
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">New File</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-xl>
              <form>
                <v-file-input
                  v-model="files"
                  outlined
                  name="sampleFile"
                  :show-size="1000"
                  label="File input"
                  placeholder="Select your file"
                  prepend-icon="mdi-paperclip"
                  counter
                  :multiple="multiple"
                ></v-file-input>
                <!-- <v-text-field
                  v-if="files.length == 1"
                  v-model="uploadfile.name"
                  label="Name"
                  outlined
                ></v-text-field> -->
                <!-- <v-text-field
                  v-if="files.length == 1"
                  v-model="uploadfile.url"
                  label="URL"
                  outlined
                ></v-text-field> -->
              </form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">Close</v-btn>
            <v-btn color="primary" text @click="uploadFile()">Add</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
import service from "../../api/index";
import fieldCalculated from "../../common/methods/fieldCalculated";
/* eslint-disable */
export default {
  props: ["value", "record", "fieldSettings", "formSettings"],
  data() {
    return {
      recordObj: true,
      dialog: false,
      records: [],
      image: "",
      file: this.value,
      newfile: null,
      required:
        this.formSettings.required ||
        this.fieldSettings.setup.required ||
        false,
      field: this.formSettings.field,
      label: this.formSettings.name || this.fieldSettings.name,
      files: [],
      multiple: this.formSettings.multiple || this.fieldSettings.setup.multiple,
      uploadfile: {
        name: "",
        recordtype: "file",
        collection: "files",
        url: "",
      },
      rules: {
        required: (value) => (this.required ? !!value || "Required." : true),
      },
      loading: false,
      search: null,
      _timerId: "search",
      typing: false,
    };
  },
  watch: {
    async file(value) {
      if (this.record[this.field] != value)
        this.$emit("emit", { field: this.field, value: value });
      //this.records = await this.getData(this.fieldSettings, this.formSettings);
    },

    record: {
      handler: async function (after, before) {
        if (this.record[this.field]) {
          let value = this.record[this.field];
          if (Array.isArray(value))
            value.forEach((val) => {
              val.displayname = val.displayname || val.name;
            });
          else {
            value = this.record[this.field];
            if (value.displayname || value.name)
              value.displayname = value.displayname || value.name;
          }
          this.file = this.record[this.field];
          if (this.multiple) this.records.push(...this.record[this.field]);
          else this.records.push(this.record[this.field]);
        } else this.file = null;
      },
      deep: true,
    },
    async search(val) {
      this.fetchEntriesDebounced(val);
    },
  },
  async created() {
    if (this.record[this.field]) {
      let value = this.record[this.field];
      if (Array.isArray(value)) {
        value.forEach((val) => {
          val.displayname = val.displayname || val.name;
        });
        this.records.push(...value);
      } else {
        value = this.record[this.field];
        if ((value.displayname || value.name) && value._id)
          value.displayname = value.displayname || value.name;
        this.records.push(value);
      }
      this.file = value;
    }
  },
  methods: {
    fetchEntriesDebounced(val) {
      this.typing = true;
      // cancel pending call
      clearTimeout(this._timerId);
      // delay new call 500ms
      this._timerId = setTimeout(async () => {
        this.typing = false;
        if (val) {
          if (this.loading) return;

          if (val.length >= 3 && !this.loading && this.typing == false) {
            this.loading = true;
            this.records = await this.getData(
              this.fieldSettings,
              this.formSettings,
              val
            );
            if (this.recordSelected) {
              if (this.multiple)
                this.records.push(...(this.recordSelected || []));
              else this.records.push(this.recordSelected);
            }
            this.loading = false;
          }
        }
      }, 1000);
    },
    add() {
      this.dialog = !this.dialog;
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.image = files[0];
      //this.createImage(files[0]);
    },

    async uploadFile() {
      this.dialog = false;
      try {
        let formdata = new FormData();
        if (!Array.isArray(this.files)) this.files = [this.files];
        this.files.forEach((file) => {
          formdata.append("file", file, file.name);
        });
        let uploaded = await service("files", formdata, "file");
        this.records = await this.getData(this.fieldSettings);
        if (this.multiple) {
          this.file = !this.file ? [] : this.file;
          uploaded.forEach((uploadedfile) => {
            this.file.push(uploadedfile);
          });
        } else this.file = { _id: uploaded[0]._id, name: uploaded[0].name };
      } catch (error) {
        if (error._id && !this.multiple)
          this.file = { _id: error._id, name: this.files[0].name };
        alert(error.message);
      }
      this.files = [];
    },
    async getData(source, form, search) {
      //console.log(source,form)
      source.setup.filters = source.setup.filters || {};

      let field = fieldCalculated(source, this.record);
      if (search)
        source.filters["$or"] = [
          { name: { $regex: `.*${search}.*`, $options: "i" } },
          { displayname: { $regex: `.*${search}.*`, $options: "i" } },
        ];
      let parameters = [
        source.filters,
        { name: true, displayname: true, recordtype: true, collection: true },
      ];
      let data = {
        action: "find",
        data: parameters,
        limit: 50,
        sort: { name: 1 },
      };
      //console.log("Select", data);
      let selected = this.multiple ? this.file : [this.file];
      let options = await service(field.setup.collection, data);

      options.forEach((obj) => {
        if (this.recordObj) {
          obj.displayname = obj.displayname || obj.name;
          options.push(obj);
        }
      });
      return options;
    },
  },
};
</script>

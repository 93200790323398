<template>
  <v-row v-if="hasAccess">
    <v-autocomplete
      item-text="displayname"
      item-value="_id"
      :label="label"
      :items="records"
      outlined
      class="mx-4"
      :hide-no-data="hideNoData"
      no-data-text="Not found"
      hide-details
      :search-input.sync="search"
      :loading="loading"
      v-model="recordSelected"
      return-object
      prepend-icon="mdi-database-search"
      v-on:keyup.enter="fetchEntriesDebounced(search, true)"
      dense
      no-filter
      disable-lookup
    >
      <template v-slot:prepend>
        <v-menu
          transition="scale-transition"
          offset-y
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small v-bind="attrs" v-on="on" @click="showSetings()">
              <v-icon dark>mdi-database-search</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-select
              style="width: 150px"
              solo
              v-model="collections"
              :items="items"
              hide-no-data
              hide-details
              dense
              multiple
              item-text="name"
              item-value="_id"
              label=""
              ref="collections"
            ></v-select>
          </v-card>
        </v-menu>
      </template>

      <template v-slot:item="data">
        <template>
          <v-list-item
            link
            :to="data.item.link"
            @click="
              records = [];
              recordSelected = '';
              search = '';
            "
          >
            <v-list-item-content>
              <v-list-item-title
                v-html="data.item.displayname || data.item.name"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-html="data.item.info"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </template>
    </v-autocomplete>
  </v-row>
</template>

<script>
/* eslint-disable */
import service from "../../api/index";
export default {
  data() {
    return {
      hideNoData: true,
      recordSelected: false,
      records: [],

      items: [
        { _id: "transactions", name: "Transactions" },
        { _id: "users", name: "Customers" },
        { _id: "deliveries", name: "Deliveries" },
        { _id: "items", name: "Inventory Items" },
      ],
      collections: ["transactions", "users", "deliveries", "items"],
      search: null,
      loading: false,
      _timerId: "globalsearch",
      typing: false,
    };
  },
  watch: {
    async recordSelected(value) {
      if (value) {
        this.$router.push({
          path: `/${value.collection}/${value.recordtype}/view/${value._id}`,
        });
      }
    },
    async search(val) {
      this.fetchEntriesDebounced(val);
    },
  },
  computed: {
    label() {
      if (this.collections.length < 4)
        return `Serach (${this.collections.join(", ")})`;
      else return "Search";
    },
    hasAccess() {
      if (
        this.$store.state.user &&
        this.$store.state.user.role._id != "role_vendor"
      )
        return true;
      else return false;
    },
  },
  methods: {
    showSetings() {
      setTimeout(async () => {
        this.$refs["collections"].focus();
        this.$refs["collections"].activateMenu(false);
      }, 55);
    },
    fetchEntriesDebounced(val, init) {
      this.typing = true;
      this.hideNoData = true;
      // cancel pending call
      clearTimeout(this._timerId);
      // delay new call 500ms
      this._timerId = setTimeout(async () => {
        this.typing = false;
        if (val) {
          if (this.loading) return;

          if (
            (val.length >= 5 || init) &&
            !this.loading &&
            this.typing == false
          ) {
            this.loading = true;
            this.records = await this.getData(val);
            this.hideNoData = false;
            this.loading = false;
          }
        }
      }, 750);
    },
    getData(search) {
      let data = {
        action: "globalSearch",
        search: search,
        collections: this.collections.length ? this.collections : null,
      };
      return service("globalsearch", data)
        .catch((error) => {
          throw error;
        })
        .then((response) => {
          //console.log("select", response);
          return response.map((obj) => {
            obj.displayname = obj.displayname || obj.name;
            return obj;
          });
        })
        .catch((error) => {});
    },
  },
};
</script>
<template>
  <v-tabs vertical dark>
    <v-tooltip bottom>
      <span>Overview</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-information-outline</v-icon>
        </v-tab>
      </template>
    </v-tooltip>

    <v-tooltip bottom>
      <span>Related Transactions</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-format-list-bulleted</v-icon>
        </v-tab>
      </template>
    </v-tooltip>

    <v-tooltip bottom>
      <span>Communications</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-email-open</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Logs</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-math-log</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tab-item>
      <v-container fluid class="py-0">
        <v-alert
          type="error"
          dense
          v-if="daysAlert >= 30 && record.created == 'new'"
          >Apply discount - return over 30 days from purchase ({{
            daysAlert
          }}
          days)</v-alert
        >
        <v-alert
          type="warning"
          dense
          v-for="instruction of record.customer
            ? record.customer.additionalinstructions
            : []"
          :key="instruction._id"
          >{{ instructions[instruction._id || instruction] }}</v-alert
        >

        <v-alert
          type="info"
          dense
          v-if="record.customer && record.customer.shippinginstructions"
        >
          <pre>{{ record.customer.shippinginstructions }}</pre>
        </v-alert>
        <v-alert v-if="overdue" type="error" dense icon="mdi-fire">
          Overdue Invoice
        </v-alert>

        <v-alert
          type="error"
          dense
          v-if="
            record.validtaxnumber == false &&
            this.record.billcountry &&
            !this.record.billcountry.exportcustomsclearance &&
            this.record.customer &&
            !this.record.customer.isperson
          "
          >Invalid VAT Number</v-alert
        >
        <v-alert
          type="error"
          dense
          v-if="record.customer && record.customer.accountonhold"
          >Account On Hold!</v-alert
        >
        <v-row row wrap>
          <v-col>
            <v-subheader>Main</v-subheader>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Customer:</v-list-item-subtitle>

                      <v-list-item-title>
                        <router-link
                          v-if="record.customer"
                          :to="`/users/${record.customer.recordtype}/view/${record.customer._id}`"
                          >{{ record.customer.name }}</router-link
                        >
                      </v-list-item-title>
                    </v-col>
                    <v-col
                      class="py-0"
                      v-if="
                        record.salesrep ||
                        (record.customer && record.customer.salesrep)
                      "
                    >
                      <v-list-item-subtitle>Sales Rep:</v-list-item-subtitle>

                      <v-list-item-title
                        v-if="record.location._id == '62de6196fa2fc522dc692c7e'"
                      >
                        {{
                          record.salesrep
                            ? record.salesrep.name
                            : record.customer.salesrep
                            ? record.customer.salesrep.name
                            : ""
                        }}
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        {{
                          record.customer.salesrep
                            ? record.customer.salesrep.name
                            : record.salesrep
                            ? record.salesrep.name
                            : ""
                        }}
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Date:</v-list-item-subtitle>
                      <v-list-item-title>{{
                        record.date
                          ? new Date(record.date).toISOString().substr(0, 10)
                          : ""
                      }}</v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Created By:</v-list-item-subtitle>
                      <v-list-item-title>{{
                        record.createdby
                          ? record.createdby.name
                          : record.customer.name
                      }}</v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="
                  (record.createdfrom && record.createdfrom.length) ||
                  (record.correlated && record.correlated.length)
                "
              ></v-divider>
              <v-list-item
                v-if="
                  (record.createdfrom && record.createdfrom.length) ||
                  (record.correlated && record.correlated.length)
                "
              >
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Created From:</v-list-item-subtitle>
                      <v-list-item-title>
                        <router-link
                          v-for="createdfrom of record.createdfrom"
                          :key="createdfrom._id"
                          :to="`/transactions/${createdfrom.recordtype}/view/${createdfrom._id}`"
                        >
                          {{ createdfrom.name }}
                          <br />
                        </router-link>
                      </v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle
                        >Correlated WZ:</v-list-item-subtitle
                      >
                      <v-list-item-title>
                        <router-link
                          v-for="correlated of record.correlated"
                          :key="correlated._id"
                          :to="`/transactions/${correlated.recordtype}/view/${correlated._id}`"
                        >
                          {{ correlated.name }}
                          <br />
                        </router-link>
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col>
            <v-subheader>Details</v-subheader>
            <v-list>
              <v-list-item
                v-if="
                  (record.createdfrom && record.createdfrom.length) ||
                  (record.correlated && record.correlated.length)
                "
              >
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0" v-if="record.returnrequest">
                      <v-list-item-subtitle
                        >Return Request:</v-list-item-subtitle
                      >
                      <v-list-item-title>
                        <router-link
                          :to="`/transactions/${record.returnrequest.recordtype}/view/${record.returnrequest._id}`"
                        >
                          {{ record.returnrequest.name }}
                        </router-link>
                      </v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Return Auth:</v-list-item-subtitle>
                      <v-list-item-title>
                        <router-link
                          v-for="correlated of correlatedRA"
                          :key="correlated._id"
                          :to="`/transactions/${correlated.recordtype}/view/${correlated._id}`"
                        >
                          {{ correlated.name }}
                          <br />
                        </router-link>
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="primary">business</v-icon>
                </v-list-item-action>

                <v-list-item-content class="py-1">
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-title>{{
                        billingaddress.name
                      }}</v-list-item-title>
                      <v-list-item-subtitle
                        >{{ billingaddress.address }}<br />{{
                          billingaddress.country
                        }}</v-list-item-subtitle
                      >
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider inset></v-divider>
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="primary">local_shipping</v-icon>
                </v-list-item-action>

                <v-list-item-content class="py-1">
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-title>{{
                        shippingaddress.name
                      }}</v-list-item-title>
                      <v-list-item-subtitle
                        >{{ shippingaddress.address }}<br />{{
                          shippingaddress.country
                        }}</v-list-item-subtitle
                      >
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col>
            <v-subheader>Summary</v-subheader>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Net. Amount:</v-list-item-subtitle>
                      <v-list-item-title>{{ amount }}</v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Items Amount:</v-list-item-subtitle>
                      <v-list-item-title>{{ itemsamount }}</v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Tax Amount:</v-list-item-subtitle>
                      <v-list-item-title>{{ taxamount }}</v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Gross Amount:</v-list-item-subtitle>
                      <v-list-item-title>
                        <b>{{ grossamount }}</b>
                      </v-list-item-title>
                    </v-col>
                    <v-col class="py-0" v-if="record.grossamount > 0">
                      <v-list-item-subtitle>Paid:</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ paid }}
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item v-if="duedate || record.amountdue">
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Due Amount:</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ amountdue }}
                      </v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Due Date:</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ duedate }}
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="record.grossamount < 0">
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Applied:</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ applied }}
                      </v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Unappiled:</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ unapplied }}
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </v-tab-item>
    <v-tab-item>
      <tool-relatedtransactions :record="record" />
    </v-tab-item>
    <v-tab-item>
      <tool-inbox :record="record" :customer="record._id" filter="record" />
    </v-tab-item>
    <v-tab-item>
      <tool-logs :record="record._id" />
    </v-tab-item>
  </v-tabs>
</template>
<script>
import service from "../../../api/index";
/* eslint-disable */
export default {
  props: ["record"],
  data() {
    return {
      loading: false,
      photosrc: "",
      instructions: {},
    };
  },
  methods: {
    formatCurrency(value, currency = "PLN") {
      return new Intl.NumberFormat(currency !== "PLN" ? "en-EN" : "pl-PL", {
        style: "currency",
        currency: currency,
      }).format(value || 0);
    },
  },
  async created() {
    if (this.record.customer && this.record.customer.salesrep)
      this.record.customer.salesrep = await service("users", {
        action: "findOne",
        data: [{ _id: this.record.customer.salesrep }],
      });
    this.instructions = await service("lists", {
      action: "find",
      data: [{ recordtype: "additionalinstruction" }],
    }).then((response) => {
      let ids = {};
      response.map((v) => {
        ids[v._id] = v.name;
      });
      return ids;
    });
  },
  computed: {
    date() {
      return this.record.date
        ? new Date(this.record.date).toISOString().substr(0, 10)
        : "";
    },
    weight() {
      return this.record.weight
        ? `${parseFloat(this.record.weight).toFixed(2)} kg`
        : `0.00 kg`;
    },
    customer() {
      //console.log("Customer", this.record.customer)
      return this.record.customer ? this.record.customer.name : "";
    },
    duedate() {
      return this.record.duedate
        ? new Date(this.record.duedate).toISOString().substr(0, 10)
        : null;
    },
    applied() {
      return this.formatCurrency(
        this.record.applied,
        this.record.currency ? this.record.currency.iso : "PLN"
      );
    },
    unapplied() {
      return this.formatCurrency(
        this.record.unapplied,
        this.record.currency.iso
      );
    },
    amount() {
      return this.formatCurrency(
        this.record.amount,
        this.record.currency ? this.record.currency.iso : "PLN"
      );
    },
    grossamount() {
      return this.formatCurrency(
        this.record.grossamount,
        this.record.currency ? this.record.currency.iso : "PLN"
      );
    },
    taxamount() {
      return this.formatCurrency(
        this.record.taxamount,
        this.record.currency ? this.record.currency.iso : "PLN"
      );
    },
    paid() {
      return this.formatCurrency(
        this.record.paid,
        this.record.currency ? this.record.currency.iso : "PLN"
      );
    },
    amountdue() {
      return this.formatCurrency(
        this.record.amountdue,
        this.record.currency ? this.record.currency.iso : "PLN"
      );
    },
    shippingamount() {
      return this.formatCurrency(
        this.record.shippingcost,
        this.record.currency ? this.record.currency.iso : "PLN"
      );
    },
    itemsamount() {
      return this.formatCurrency(
        this.record.itemsamount,
        this.record.currency ? this.record.currency.iso : "PLN"
      );
    },
    cost() {
      return this.formatCurrency(this.record.cost);
    },

    profit() {
      return this.formatCurrency(this.record.profit);
    },
    addresses() {
      let addresses = [];
      if (this.record.billingaddress) {
        this.record.billingaddress.image =
          "https://maps.gstatic.com/mapfiles/ms2/micons/red-dot.png";
        addresses.push(this.record.billingaddress);
      }
      if (this.record.shippingaddress) {
        this.record.shippingaddress.image =
          "https://maps.gstatic.com/mapfiles/ms2/micons/blue-dot.png";
        addresses.push(this.record.shippingaddress);
      }
      return addresses;
    },
    billingaddress() {
      let address = { name: "", address: "", country: "" };
      address.name = this.record.billaddressee || "";
      if (this.record.billaddress) address.address += this.record.billaddress;
      if (this.record.billaddress2)
        address.address += ` ${this.record.billaddress2}`;
      if (this.record.billcity) address.country += `${this.record.billcity}`;
      if (this.record.billzip) address.country += `, ${this.record.billzip}`;
      if (this.record.billcountry)
        address.country += `, ${
          this.record.billcountry.name || this.record.billcountry
        }`;
      return address;
    },

    shippingaddress() {
      let address = { name: "", address: "", country: "" };
      address.name = this.record.shipaddressee || "";
      if (this.record.shipaddress) address.address += this.record.shipaddress;
      if (this.record.shipaddress2)
        address.address += `, ${this.record.shipaddress2}`;
      if (this.record.shipcity) address.country += `${this.record.shipcity}`;
      if (this.record.shipzip) address.country += `, ${this.record.shipzip}`;
      if (this.record.shipcountry)
        address.country += `, ${
          this.record.shipcountry.name || this.record.shipcountry
        }`;
      return address;
    },
    overdue() {
      let overdue = false;
      if (this.record.duedate < new Date() && this.record.amountdue > 0)
        overdue = true;
      return overdue;
    },
    correlatedRA() {
      return [
        ...(this.record.childs || []),
        ...(this.record.correlated || []),
      ].filter((rec) => {
        return rec.recordtype == "returnauthorization";
      });
    },
    daysAlert() {
      let dni = 0;
      if (this.record.createdfrom && this.record.createdfrom[0]) {
        const obecnaData = new Date();
        const data = new Date(this.record.createdfrom[0].date);

        // Różnica w czasie w milisekundach
        const roznicaCzasu = obecnaData - data;

        // Przeliczanie milisekund na dni
        dni = roznicaCzasu / (1000 * 60 * 60 * 24);
        return Math.floor(dni); // Zwraca liczbę pełnych dni
      }
    },
  },
};
</script>

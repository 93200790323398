<template>
  <v-container fluid grid-list-xl>
    <v-layout row wrap>
      <v-flex xs12 md5 d-flex text-xs-center>
        <v-card flat>
          <v-card-text>
            <h3 class="headline mb-2">{{ record.addressee }}</h3>
            <div class="blue--text mb-2" v-if="record.phone">
              <v-icon small left>phone</v-icon>
              {{ record.phone }}
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <div class="grey--text">{{ record.address }}</div>
            <div class="grey--text">{{ record.zip}}, {{record.city }}</div>
            <div class="grey--text">{{ getCountry }}</div>
            <div class="grey--text">{{ record.notes }}</div>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs12 md7>
        <field-map :addresses="[record]" :key="record.v" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { getName } from 'country-list';
/* eslint-disable */
export default {
  props: ['record'],
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    
    getCountry() {
    //  return getName(this.record.country);
    return this.record.country ? this.record.country.name : ""
    },
  },
};
</script>

<template>
  <div class="text-xs-center">
    <v-bottom-sheet v-model="sheet">
      <v-tooltip left slot="activator">
        <v-icon slot="activator">grade</v-icon>
        <span>Shortcuts</span>
      </v-tooltip>
      <v-card height="200px" flat>
        <div class="headline text-xs-center pa-5">Active: {{ bottomNav }}</div>
        <v-bottom-nav :active.sync="bottomNav" :value="true" absolute color="transparent">
          <v-btn color="teal" flat value="recent">
            <span>Recent</span>
            <v-icon>history</v-icon>
          </v-btn>

          <v-btn color="teal" flat value="favorites">
            <span>Favorites</span>
            <v-icon>favorite</v-icon>
          </v-btn>

          <v-btn color="teal" flat value="nearby">
            <span>Nearby</span>
            <v-icon>place</v-icon>
          </v-btn>
        </v-bottom-nav>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  data: () => ({
    sheet: false,
    bottomNav: "recent",
    tiles: [
      { img: "keep.png", title: "Keep" },
      { img: "inbox.png", title: "Inbox" },
      { img: "hangouts.png", title: "Hangouts" },
      { img: "messenger.png", title: "Messenger" },
      { img: "google.png", title: "Google+" }
    ]
  })
};
</script>

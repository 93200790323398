<template>
  <div>
    <v-container fluid v-if="$route.params.mode != 'view'">
      <v-row>
        <v-col cols="6">
          <v-text-field
            :disabled="!box"
            v-model="scan"
            append-icon="search"
            label="Search by Barcode"
            single-line
            hide-details
            outlined
            append-outer-icon="mdi-barcode-scan"
            @click:append-outer="mode = 'name'"
            v-on:keyup.enter="decodeBarcode($event, scan)"
          ></v-text-field>
        </v-col>

        <v-col v-if="scans.length">
          <small class="text-caption">LAST SCANS:</small>
          <small class="text-caption">
            <li v-for="(scan, index) of scans" :key="index">
              {{ scan.scan }} From:{{ scan.frombox }} To: {{ scan.tobox }}
            </li>
          </small>
        </v-col>
        <v-col> </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-btn @click="assign" tile color="primary" x-large>
            Assign Pallet</v-btn
          >
        </v-col>
        <v-col cols="1">
          <v-text-field
            v-model="frombox"
            label="From Pallet"
            hide-details
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-text-field
            v-model="box"
            label="To Pallet"
            hide-details
            outlined
          ></v-text-field>
        </v-col>

        <v-col></v-col>
        <v-col></v-col>
      </v-row>
    </v-container>

    <v-data-table
      :headers="headers"
      :items="record.transactionlines"
      :loading="loading"
      loading-text="Loading... Please wait"
      item-key="index"
      :search="search"
      :footer-props="{ itemsPerPageOptions: rows }"
      :custom-filter="filter"
      :custom-sort="sort"
      :group-by="groupby"
      :custom-group="customGroup"
    >
      <template v-slot:[`group.header`]="{ group, toggle, isOpen, items }">
        <th>Pallet# {{ group }}</th>
        <th>
          Total:
          {{
            items.reduce((t, v) => {
              t += v.quantity;
              return t;
            }, 0)
          }}
        </th>
        <th>
          Total:
          {{
            items
              .reduce((t, v) => {
                t += (v.weight || v.item.weight || 0) * v.quantity;
                return t;
              }, 0)
              .toFixed(2)
          }}Kg
        </th>
      </template>
      <template v-slot:item.weight="{ item }">
        <span> {{ item.weight || item.item.weight }}</span>
      </template>
    </v-data-table>
  </div>
</template>
  <script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import sort from "../../dataTable/sort";
import ding from "../../../assets/ding.mp3";
export default {
  props: ["record"],
  data() {
    return {
      loading: false,
      headers: [
        { text: "Item", value: "displayname" },
        { text: "Quantity", value: "quantity" },
        { text: "Weight", value: "weight" },
        { text: "Box", value: "transaction_box" },
      ],
      items: [],
      scans: [],
      rows: [-1],
      search: "",
      scan: "",
      show: false,
      groupby: "transaction_box",
      box: null,
      frombox: "1",
    };
  },
  async created() {
    this.items = this.record.transactionlines;
  },
  methods: {
    playSound(sound) {
      var audio = new Audio(sound || ding);
      audio.play();
    },
    decodeBarcode(e, scan) {
      let scanparts = scan.split(";");

      if (scanparts.length > 1) {
        scan = scanparts[1];
      }
      scan = scan.trim();
      this.scans.push({ scan, frombox: this.frombox, tobox: this.box });
      if (this.scans.length > 3) this.scans.shift();
      let items = this.record.transactionlines;
      let minus = null;
      let plus = null;
      let plusLine = null;
      for (let line of items) {
        if (!line["transaction_box"]) line["transaction_box"] = [];
        if (
          line.item &&
          (line.item.barcode == scan || line.item.name == scan)
        ) {
          let sum = line["transaction_box"].reduce((t, v) => {
            if (!v.deleted) t += v.quantity;
            return t;
          }, 0);

          if (!minus) {
            minus = line["transaction_box"].find(
              (box) => box.box == this.frombox && box.quantity
            );
            if (minus) sum--;
          }
          if (!plus && sum < line.quantitytopack) {
            plus = line["transaction_box"].find((box) => box.box == this.box);
            if (!plusLine) plusLine = line;
          }
        }
      }
      if (minus && minus.quantity) {
        minus.quantity--;
        minus.name = `${minus.box} (${minus.quantity})`;
        minus.displayname = `${minus.box} (${minus.quantity})`;
        if (!minus.quantity) minus.deleted = true;
        if (plus) {
          plus.quantity++;
          plus.name = `${plus.box} (${plus.quantity})`;
          plus.displayname = `${plus.box} (${plus.quantity})`;
          if (plus.deleted) minus.deleted = false;
        } else {
          plusLine["transaction_box"].push({
            box: this.box,
            quantity: 1,
            name: `${this.box} (1)`,
            displayname: `${this.box} (1)`,
          });
        }
      } else {
        this.playSound();
        alert("Item not found");
      }
      this.scan = "";
    },
    assign() {
      this.record.transactionlines.forEach((line) => {
        if (!line["transaction_box"]) line["transaction_box"] = [];
        let sum = line["transaction_box"].reduce((t, v) => {
          if (!v.deleted) t += v.quantity;
          return t;
        }, 0);
        if (
          line.item.recordtype == "inventoryitem" &&
          (!line["transaction_box"] ||
            !line["transaction_box"].length ||
            sum < line.quantitytopack ||
            this.box)
        ) {
          if (this.box && line["transaction_box"]) {
            line["transaction_box"].forEach((b) => {
              if (b.box == this.frombox) {
                b.box = this.box;
                b.name = `${this.box} (${b.quantity})`;
                b.displayname = `${this.box} (${b.quantity})`;
              }
            });
          } else {
            line["transaction_box"] = [
              {
                box: this.frombox,
                quantity: line.quantitytopack,
                name: `${this.frombox} (${line.quantitytopack - sum})`,
                displayname: `${this.frombox} (${line.quantitytopack - sum})`,
              },
            ];
          }
        }
      });
    },
    customGroup(items, groupBy, groupDesc) {
      const key = groupBy[0];
      let groups = items.reduce((rv, x) => {
        const groups = x[key]
          ? Array.isArray(x[key])
            ? x[key]
            : [x[key]]
          : [];
        groups.forEach((group) => {
          if (group.box) {
            (rv[group.box] = rv[group.box] || []).push({
              ...x,
              quantity: group.quantity,
            });
          }
        });

        return rv;
      }, {});

      return Object.keys(groups).map((key) => ({
        name: key,
        items: groups[key],
      }));
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    sort(items, sortBy, sortDesc) {
      return sort(items, sortBy, sortDesc);
    },
  },
};
</script>

     <template>
  <v-overflow-btn
    style="min-width=250px"
    flat
    class="mt-0"
    :items="loginas"
    label="Login As"
    segmented
    menu-props="{ maxHeight: unset }"
  >
  </v-overflow-btn>
</template> 
<script>
/* eslint-disable */
import service from "../../../api/index";
export default {
  props: ["record"],
  data() {
    return {
      loading: false,
      saved: true,
      modified: false,
      loginas: [],
    };
  },

  async created() {
    this.loginas = [];
    const templates = this.record.access;
    templates.forEach((template) => {
      this.loginas.push({
        text: `${this.record.name} - ${template.source.name}`,
        callback: () => {
          var response = confirm("Login As - Are you sure?");
          if (response) {
            this.logas(template._id);
          }
        },
      });
    });
  },
  methods: {
    logas(access) {
      let data = {
        action: "loginAs",
        access: access,
      };
      return service("users", data)
        .catch((error) => {
          throw error;
        })
        .then((response) => {
          console.log(response);
          window.open(response.url, "_blank");
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>
<style  scoped>
.v-dialog__container {
  display: unset;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{attrs:{"background-color":"primary","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" Item Fulfillments ")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('exportData',{attrs:{"headers":_vm.headers,"items":_vm.items}})],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Month:","placeholder":"","append-icon":"event","readonly":"","outlined":"","dense":"","hide-details":""},on:{"change":function($event){return _vm.getData()}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},on))]}}]),model:{value:(_vm.menuMonth),callback:function ($$v) {_vm.menuMonth=$$v},expression:"menuMonth"}},[_c('v-date-picker',{attrs:{"type":"month"},on:{"input":function($event){_vm.menuMonth = false},"change":function($event){return _vm.getData()}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-select',{staticClass:"mt-2 mb-2",attrs:{"items":_vm.companies,"label":"Company","item-text":"name","item-value":"_id","outlined":"","clearable":"","hide-details":"","dense":""},on:{"change":function($event){return _vm.getData()}},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"show-select":"","items":_vm.items.filter(function (l) { return _vm.onlyErrors ? l.lastQty != l.lastQuantityonhand : true; }
                ),"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","search":_vm.search,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter},scopedSlots:_vm._u([{key:"item.transaction",fn:function(ref){
                var item = ref.item;
return [(item._id)?_c('router-link',{staticClass:"link",attrs:{"to":{
                    path: ("/transactions/itemfulfillment/view/" + (item._id)),
                  }}},[_vm._v(_vm._s(item.displayname || item.name))]):_vm._e()]}},{key:"item.customer",fn:function(ref){
                  var item = ref.item;
return [(item.customer)?_c('router-link',{staticClass:"link",attrs:{"to":{
                    path: ("/" + (item.customer.collection) + "/" + (item.customer.recordtype) + "/view/" + (item.customer._id)),
                  }}},[_vm._v(_vm._s(item.customer.displayname || item.customer.name))]):_vm._e()]}},{key:"item.date",fn:function(ref){
                  var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Date:","placeholder":"","append-icon":"event","readonly":"","outlined":"","dense":"","hide-details":""},on:{"change":function($event){return _vm.changeDate($event, item._id, 'date')}},model:{value:(item.date),callback:function ($$v) {_vm.$set(item, "date", $$v)},expression:"item.date"}},on))]}}],null,true),model:{value:(item.menu),callback:function ($$v) {_vm.$set(item, "menu", $$v)},expression:"item.menu"}},[_c('v-date-picker',{on:{"input":function($event){item.menu = false},"change":function($event){return _vm.changeDate($event, item._id, 'date')}},model:{value:(item.date),callback:function ($$v) {_vm.$set(item, "date", $$v)},expression:"item.date"}})],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
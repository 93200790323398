<template>
  <v-card tile>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Warehouse Management Tools</v-toolbar-title>
    </v-toolbar>
    <v-tabs dark v-model="active">
      <v-tab :key="1">Ready To Pack</v-tab>
      <v-tab :key="2">Deliveries</v-tab>
      <v-tab :key="3">Pending Transfers</v-tab>
      <v-tab :key="4">Returns</v-tab>
      <v-tab :key="5">Reports</v-tab>
      <v-tab-item :key="1">
        <readytopack v-if="active == 0" />
      </v-tab-item>
      <v-tab-item :key="2">
        <deliveriescalendar v-if="active == 1" />
      </v-tab-item>
      <v-tab-item :key="3">
        <pendingtransfers v-if="active == 2" />
      </v-tab-item>
      <v-tab-item :key="4">
        <returnrequest v-if="active == 3" />
      </v-tab-item>
      <v-tab-item :key="5">
        <reports v-if="active == 4" />
      </v-tab-item>
      <!-- <v-tab-item :key="3">
        <div v-if="active==2">
          <v-container>
            <v-row>
              <v-col>
                <router-link
                  to="/queries/report/view/5ed63231ab45184b2c366711"
                  class="link d-block"
                >Stock Check - PL</router-link>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-tab-item>-->
    </v-tabs>
  </v-card>
</template>

<script>
import readytopack from "../components/tools/readytopack/View";
import deliveriescalendar from "../components/tools/deliveriescalendar/View";
import reports from "../components/tools/reports/View";
import pendingtransfers from "../components/tools/pendingtransfers/View";
import returnrequest from "../components/tools/returnrequest/View";
/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
      active: null,
    };
  },
  components: {
    readytopack,
    reports,
    pendingtransfers,
    deliveriescalendar,
    returnrequest,
  },
};
</script>
 <template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          loading-text="Loading... Please wait"
          :loading="loading"
          item-key="_id"
          :search="search"
          :single-select="singleSelect"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
        >
          <template v-slot:item.date="{ item }">
            <span>{{
              item.date ? new Date(item.date).toISOString().substr(0, 10) : ""
            }}</span>
          </template>
          <template v-slot:item.isperson="{ item }">
            <v-chip label :color="item.isperson ? 'red' : 'none'" dark>{{
              item.isperson ? "Yes" : "No"
            }}</v-chip>
          </template>
          <template v-slot:item.customer="{ item }">
            <router-link
              v-if="item.customer"
              :to="{
                path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
              }"
              class="link"
              >{{
                item.customer.displayname || item.customer.name
              }}</router-link
            >
          </template>
          <template v-slot:item.item="{ item }">
            <router-link
              v-if="item.item"
              :to="{
                path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`,
              }"
              class="link"
              >{{ item.item.displayname || item.item.name }}</router-link
            >
          </template>
          <template v-slot:item.transaction="{ item }">
            <router-link
              v-if="item.transaction"
              :to="{
                path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
              }"
              class="link"
              >{{
                item.transaction.displayname || item.transaction.name
              }}</router-link
            >
          </template>
          <template v-slot:item.correlated="{ item }">
            <router-link
              v-for="(so, index) in item.correlated"
              :key="index"
              :to="{
                path: `/${so.collection}/${so.recordtype}/view/${so._id}`,
              }"
              class="link"
            >
              {{ so.displayname || so.name }}
              <span v-if="index + 1 < item.correlated.length">,</span>
            </router-link>
          </template>
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col>
                  <exportData :headers="headers" :items="items" />
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                  <v-select
                    :items="companies"
                    v-model="company"
                    label="Company"
                    item-text="name"
                    item-value="_id"
                    class="mt-2 mb-2"
                    outlined
                    multiple
                    dense
                  ></v-select>
                </v-col>
                <v-col>
                  <v-menu
                    v-model="menufrom"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        label="Date from"
                        placeholder
                        v-model="datefrom"
                        append-icon="event"
                        readonly
                        v-on="on"
                        outlined
                        dense
                        hide-details
                        class="mt-2 mb-2"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="datefrom"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-menu
                    v-model="menuto"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        label="Date To"
                        placeholder
                        v-model="dateto"
                        append-icon="event"
                        readonly
                        v-on="on"
                        outlined
                        dense
                        hide-details
                        class="mt-2 mb-2"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="dateto"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-btn
                    @click="getData()"
                    block
                    tile
                    color="primary"
                    class="mt-2 mb-2"
                    >Refresh</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";
export default {
  data() {
    return {
      loading: false,
      headers: [
        { text: "Date", value: "date" },
        { text: "Transaction", value: "transaction" },
        { text: "Record Type", value: "recordtype" },
        { text: "Customer", value: "customer" },
        { text: "Manufacturer", value: "manufacturer" },
        { text: "Item", value: "item" },
        { text: "Quantity", value: "quantity" },
        { text: "Purchase Price [SUM]", value: "purchaseprice" },
        { text: "Currency", value: "currency" },
        { text: "Purchase Amount", value: "purchaseamount" },
        { text: "Import Duty Cost [PLN]", value: "importdutycost" },
        { text: "Delivery Cost [PLN]", value: "deliverycost" },
        { text: "Inventory Valuation", value: "inventoryvaluation" },
        { text: "Correlated", value: "correlated" },
      ],
      items: [],
      rows: [100],
      singleSelect: false,
      selected: [],
      companies: [],
      company: [],
      search: "",
      datefrom: new Date(
        new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)
      )
        .toISOString()
        .substr(0, 10),
      dateto: new Date(new Date().setDate(0)).toISOString().substr(0, 10),
      menufrom: false,
      menuto: false,
    };
  },
  async created() {
    await this.getData();
    this.companies = await service("users", {
      action: "find",
      data: [{ recordtype: "company" }],
    });
  },

  methods: {
    async getData() {
      this.loading = true;
      let result = await service(
        "orders",
        {
          action: "getCostDetails",
          datefrom: this.datefrom,
          dateto: this.dateto,
          company: this.company,
        },
        false,
        true
      );
      this.items = result.items;
      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
  },
  components: {
    exportData,
  },
};
</script>
<template>
  <tr>
    <th v-if="options.allowSelect" align="left" width="80px">
      <v-checkbox
        :input-value="props.all"
        :indeterminate="props.indeterminate"
        primary
        hide-details
        @click.stop="toggleAll"
      ></v-checkbox>
    </th>
    <th
      v-for="header in props.headers"
      :key="header.text"
      :class="['column',header.sortable ? 'sortable' : '', header.sortable ? pagination.descending ? 'desc' : 'asc' : '', header.value === pagination.sortBy ? 'active' : '']"
      @click="header.sortable?changeSort(header.value):''"
      :align="header.align"
      :width="header.width"
    >
      {{ header.text }}
      <v-icon small v-if="header.sortable">arrow_upward</v-icon>
    </th>
  </tr>
</template>
<script>
/* eslint-disable */
export default {
  props: ["props", "pagination", "selected", "items", "options"],
  data() {
    return {};
  },
  methods: {
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.items.slice();
      console.log(this.selected);
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    }
  }
};
</script>
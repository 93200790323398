<template>
  <v-stepper v-model="e1" vertical style="box-shadow:none">
    <v-container fluid grid-list-xl>
      <v-layout row wrap align-start>
        <v-flex md6>
          <v-text-field
            v-model="tab.name"
            label="Tab Name"
            append-icon="remove_circle"
            @click:append="delTab()"
            style="max-width:400px"
          ></v-text-field>
        </v-flex>
        <v-flex md6>
          <v-select
            v-model="tab.table.field"
            hint="Select Table field"
            :items="fields"
            item-text="name"
            item-value="value"
            label="Select"
            persistent-hint
            single-line
          ></v-select>          
          <v-select
            v-model="tab.table.query"
            hint="Select Table Source"
            :items="queries"
            item-text="name"
            item-value="value"
            label="Select"
            persistent-hint
            single-line
          ></v-select>
          <v-textarea
            :id="`tab-${tabindex}-table-filters`"
            label="Filters Object [JSON]"
            hint="validation"
            :value="JSON.stringify(tab.table.filters, undefined, 4)"
            rows="10"
            @input="prettyJSON(`tab.table.filters`,`tab-${tabindex}-table-filters`);"
          ></v-textarea>
        </v-flex>
      </v-layout>
    </v-container>
    <template v-for="(section, index) in tab.sections">
      <v-stepper-step :step="index + 1" :complete="e1 > index + 1" editable :key="index">
        <v-text-field v-model="section.name"></v-text-field>
      </v-stepper-step>

      <v-stepper-content :key="`${index}-content`" :step="index + 1" style="position:relative;">
        <v-speed-dial
          v-if="e1 == index + 1"
          v-model="fab"
          small
          left
          top
          direction="bottom"
          style="top:-65px; left:-30px"
        >
          <v-btn slot="activator" v-model="fab" color="primary" dark small fab>
            <v-icon>menu</v-icon>
            <v-icon>close</v-icon>
          </v-btn>

          <v-btn fab dark small color="green" @click="newStep(index);">
            <v-icon>add</v-icon>
          </v-btn>
          <v-btn fab dark small color="indigo" @click="moveStep(index, -1);">
            <v-icon>keyboard_arrow_up</v-icon>
          </v-btn>
          <v-btn fab dark small color="indigo" @click="moveStep(index, 1);">
            <v-icon>keyboard_arrow_down</v-icon>
          </v-btn>
          <v-btn fab dark small color="red" @click="delStep(index);">
            <v-icon>delete</v-icon>
          </v-btn>
        </v-speed-dial>
        <v-container fluid grid-list-xl>
          <v-layout row wrap align-start>
            <v-flex md12>
              <v-textarea
                :id="`tab-${tabindex}-section-${index}`"
                label="Fields Array [JSON]"
                hint="validation"
                :value="JSON.stringify(section.fields, undefined, 4)"
                rows="40"
                @input="prettyJSON(index,`tab-${tabindex}-section-${index}`);"
              ></v-textarea>
            </v-flex>
            <v-flex offset-md1 md5 :id="`hint-${index}`"></v-flex>
          </v-layout>
        </v-container>
      </v-stepper-content>
    </template>
    <v-btn
      fab
      dark
      small
      left
      color="primary"
      style="left: 8px;"
      @click="newStep(tab.sections.length);"
    >
      <v-icon>add</v-icon>
    </v-btn>
  </v-stepper>
</template>

<script>
/* eslint-disable */
export default {
  props: ['tab', 'tabindex'],
  data() {
    return {
      e1: 1,
      fab: false,
      queries: [{ name: 'Employees', value: 'employee' }, { name: 'Addresses', value: 'address' }],
      fields:[{name:'Addresses', value:'addresses'}]
    };
  },
  methods: {
    delTab(i) {
      const sure = confirm('Are you sure you want to delete this tab?');
      if (sure) this.tab = {}; // this.$delete(this.tab, "sections");
    },
    newStep(i) {
      if (i == 0) this.tab.sections.unshift({ name: 'Section Label', fields: [] });
      else this.tab.sections.splice(i, 0, { name: 'Section Label', fields: [] });
    },
    delStep(i) {
      const sure = confirm('Are you sure you want to delete this section?');
      if (sure) this.tab.sections.splice(i, 1);
    },
    moveStep(i, dir) {
      if (dir == 1 && i < this.tab.sections.length) {
        this.e1++;
        this.tab.sections.splice(i, 2, this.tab.sections[i + 1], this.tab.sections[i]);
      }
      if (dir == -1 && i > 0) {
        this.e1--;
        this.tab.sections.splice(i - 1, 2, this.tab.sections[i], this.tab.sections[i - 1]);
      }
    },
    prettyJSON(i, id) {
      try {
        var ugly = document.getElementById(id).value;
        var obj = JSON.parse(ugly);
        console.log(obj);
        if (i == 'tab.table.filters') this.tab.table.filters = obj;
        else this.tab.sections[i].fields = obj;
      } catch (error) {
        // this.stages[i].error = error.message;
      }
    },
  },
};
</script>
<style scoped>
.v-textarea {
  font-family: monospace;
}

/* This is for documentation purposes and will not be needed in your application */
.v-speed-dial {
  position: absolute;
}

.v-btn--floating {
  position: relative;
}
</style>

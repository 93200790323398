 <template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          loading-text="Loading... Please wait"
          item-key="_id"
          :search="search"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
          :custom-sort="sort"
        >
          <template v-slot:item.date="{ item }">
            <span>{{ new Date(item.date).toISOString().substr(0, 10) }}</span>
          </template>
          <template v-slot:item.transaction="{ item }">
            <router-link
              :to="{
                path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
              }"
              class="link"
              >{{
                item.transaction.displayname || item.transaction.name
              }}</router-link
            >
          </template>
          <template v-slot:item.customer="{ item }">
            <router-link
              :to="{
                path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
              }"
              class="link"
              >{{
                item.customer.displayname || item.customer.name
              }}</router-link
            >
          </template>
          <template v-slot:item.item="{ item }">
            <router-link
              :to="{
                path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`,
              }"
              class="link"
              >{{ item.item.displayname || item.item.name }}</router-link
            >
          </template>
          <template v-slot:item.fleetorder="{ item }">
            <v-chip label :color="item.fleetorder ? 'red' : 'none'" dark>{{
              item.fleetorder ? "Yes" : "No"
            }}</v-chip>
          </template>
          <template v-slot:item.dropship="{ item }">
            <v-chip label :color="item.dropship ? 'red' : 'none'" dark>{{
              item.dropship ? "Yes" : "No"
            }}</v-chip>
          </template>
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col>
                  <exportData :headers="headers" :items="items" />
                </v-col>
                <v-col>
                  <v-switch v-model="dropship" label="Drop Ship"></v-switch>
                </v-col>
                <v-col>
                  <v-switch v-model="open" label="Only Open"></v-switch>
                </v-col>
                <v-col>
                  <v-combobox
                    :items="deliverymethods"
                    v-model="deliverymethod"
                    label="Delivery Methods"
                    item-text="name"
                    item-value="_id"
                    outlined
                    dense
                    clearable
                    multiple
                    hide-details
                    class="mt-2 mb-2"
                    :loading="loading"
                  ></v-combobox>
                </v-col>
                <v-col>
                  <v-btn
                    @click="getData()"
                    block
                    tile
                    color="primary"
                    class="mt-2 mb-2"
                    >Refresh</v-btn
                  >
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";
import sort from "../../dataTable/sort";
export default {
  props: ["customer"],
  data() {
    return {
      loading: false,
      headers: [
        { text: "Location", value: "location" },
        { text: "Destination", value: "destination" },
        { text: "Vendor", value: "customer" },
        { text: "PO#", value: "transaction" },
        { text: "SO#", value: "correlated" },
        { text: "Date", value: "date" },
        { text: "Item", value: "item" },
        { text: "Qty.", value: "quantity" },
        { text: "Qty. Billed", value: "quantitybilled" },
        { text: "Price", value: "price" },
        { text: "Delivery Method", value: "deliverymethod" },
        { text: "Internal Comments", value: "memo" },
        //{ text: "Drop Ship", value: "dropship", sortable: false }
        //{ text: "Fleet Order", value: "fleetorder", sortable: false }
      ],
      items: [],
      rows: [100],
      search: "",
      show: false,
      deliverymethods: [],
      dropship: false,
      open: true,
      deliverymethod: [{ _id: "5e0dbaba9e33df43f0b3a4f5", name: "AIR AU" }],
    };
  },
  async created() {
    this.loading = true;
    this.deliverymethods = await service("lists", {
      action: "find",
      data: [{ recordtype: "deliverymethod" }],
    });
    await this.getData();
    this.loading = false;
  },
  methods: {
    async getData() {
      this.loading = true;
      let result = await service(
        "orders",
        {
          action: "getOpenAdditionalServiceOrders",
          dropship: this.dropship,
          open: this.open,
          deliverymethod: this.deliverymethod.map((id) => {
            return id._id;
          }),
        },
        false,
        true
      );
      this.items = result.items;
      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    sort(items, sortBy, sortDesc) {
      return sort(items, sortBy, sortDesc);
    },
  },
  components: {
    exportData: exportData,
  },
};
</script>
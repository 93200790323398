<template>
  <v-data-table
    v-if="query.setup.collection"
    :headers="advanced ? advancedheaders : headers"
    :items="query.setup.filters"
    loading-text="Loading... Please wait"
    hide-default-footer
    item-key="index"
    :footer-props="{ itemsPerPageOptions: rows }"
    :item-class="rowClass"
  >
    <template v-slot:item.name="{ item }">
      <v-text-field
        class="mt-4"
        v-model="item.name"
        label="Name"
        outlined
        dense
      ></v-text-field>
    </template>
    <template v-slot:item.field="{ item }">
      <v-text-field
        v-if="item.show"
        class="mt-4 mb-2"
        v-model="item.name"
        label="Name"
        outlined
        hide-details
        dense
      ></v-text-field>
      <v-autocomplete
        :items="
          getFilteredFields(
            item.sourcesubrecord || item.source || item.subrecord
          )
        "
        v-model="item.field"
        label="Field"
        item-text="name"
        item-value="_id"
        outlined
        dense
        :rules="[rules.required]"
      ></v-autocomplete>
    </template>
    <template v-slot:item.subrecord="{ item }">
      <v-autocomplete
        class="mt-4"
        :items="getFilteredSubrecords()"
        v-model="item.subrecord"
        label="Subrecord"
        item-text="name"
        item-value="_id"
        outlined
        dense
        clearable
      ></v-autocomplete>
    </template>

    <template v-slot:item.source="{ item }">
      <v-autocomplete
        class="mt-4 mb-2"
        :items="getFilteredFields(item.subrecord, true)"
        v-model="item.source"
        label="Field Source"
        item-text="name"
        item-value="_id"
        outlined
        hide-details
        dense
      ></v-autocomplete>
      <v-autocomplete
        :items="getFilteredSubrecords(item.source)"
        v-model="item.sourcesubrecord"
        label="Subrecords / Lists"
        item-text="name"
        item-value="_id"
        outlined
        dense
        :disabled="!item.source"
      ></v-autocomplete>
    </template>
    <!-- <template v-slot:item.sourcesubrecord="{ item }">

    </template>-->
    <!-- <template v-slot:item.operator="{ item }">

    </template>-->
    <template v-slot:item.value="{ item }">
      <v-select
        v-if="item.field"
        :key="item.field"
        class="mt-4 mb-2"
        :items="
          operators.filter((op) => {
            if (
              ['field-select', 'field-autocomplete'].includes(
                fieldsObj[item.field].setup.type
              ) &&
              ['$eq', '$ne', '$nin', '$in', '$exists'].includes(op._id)
            )
              return true;
            if (
              ['field-currency', 'field-number', 'field-int'].includes(
                fieldsObj[item.field].setup.type
              ) &&
              ['$eq', '$gte', '$gt', '$lt', '$lte', '$ne'].includes(op._id)
            )
              return true;
            if (
              ['field-text', 'field-textarea'].includes(
                fieldsObj[item.field].setup.type
              ) &&
              [
                '$eq',
                '$ne',
                '$contains',
                '$notcontain',
                '$startswith',
                '$notstartwith',
              ].includes(op._id)
            )
              return true;
            if (
              ['field-date'].includes(fieldsObj[item.field].setup.type) &&
              ['$eq', '$gte', '$gt', '$lt', '$lte', '$ne'].includes(op._id)
            )
              return true;
            if (
              ['field-boolean'].includes(fieldsObj[item.field].setup.type) &&
              ['$eq', '$ne'].includes(op._id)
            )
              return true;
          })
        "
        v-model="item.operator"
        label="Operator"
        item-text="name"
        item-value="_id"
        outlined
        hide-details
        @change="item.value = null"
        dense
      ></v-select>
      <v-select
        v-if="
          fieldsObj[item.field] &&
          fieldsObj[item.field].setup.type == 'field-date'
            ? ((item.value && item.value[0] == '$') || !item.value) &&
              item.value != '$custom'
              ? true
              : false
            : false
        "
        :items="range"
        v-model="item.value"
        label="Date"
        item-text="name"
        item-value="_id"
        outlined
        dense
      ></v-select>
      <component
        v-else
        class="mt-0"
        :key="item.operator"
        :is="
          item.operator && fieldsObj[item.field]
            ? fieldsObj[item.field].setup.type || 'span'
            : 'span'
        "
        :record="item"
        :value="filterDecode(item)"
        :fieldSettings="fieldsObj[item.field]"
        :formSettings="{ ...item, show: true }"
        @emit="catchEmitFilter($event, item)"
      />
    </template>
    <template v-slot:item.show="{ item }">
      <v-switch
        class=""
        v-model="item.show"
        label="Show in footer"
        outlined
        dense
      ></v-switch>
      <br />
      <v-switch
        v-if="item.sourcesubrecord || item.subrecord"
        class="mt-0 mb-0"
        v-model="item.isempty"
        label="Filter if empty"
        outlined
        dense
      ></v-switch>
      <!-- <v-fab-transition>
        <v-btn
          color="grey"
          dark
          absolute
          top
          x-small
          right
          fab
          @click="delFilter(item)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-fab-transition> -->
    </template>
    <template v-slot:item.action="{ item }">
      <v-btn @click="upColumn(item)" block icon tile color="primary">
        <v-icon>mdi-arrow-up-bold</v-icon>
      </v-btn>
      <v-btn @click="delFilter(item)" block icon tile>
        <v-icon>mdi-delete</v-icon>
      </v-btn>

      <v-btn @click="downColumn(item)" block icon tile color="primary">
        <v-icon>mdi-arrow-down-bold</v-icon>
      </v-btn>
    </template>
    <template v-slot:footer>
      <v-container style="position: relative">
        <v-row>
          <v-col>
            <v-fab-transition>
              <v-btn
                color="primary"
                dark
                absolute
                top
                small
                left
                fab
                @click="addFilter"
                style="margin-left: -20px"
              >
                <v-icon>add</v-icon>
              </v-btn>
            </v-fab-transition>
          </v-col>
          <v-switch v-model="advanced" label="Advanced"></v-switch>
        </v-row>
      </v-container>
    </template>
  </v-data-table>
</template>
          
    <!-- <template v-slot:item.actions="{ item }">
      <v-icon @click="delMapLine(item)">mdi-delete</v-icon>
    </template>-->


<script>
/* eslint-disable */
import service from "../../../api/index";
import getFields from "../../../common/methods/getFields";
export default {
  props: ["rid", "mode", "query", "fieldsObj", "fields", "subrecords"],
  data() {
    return {
      e1: 1,
      fab: false,
      advanced: true,
      columns: {},
      loading: true,
      filteredFileds: [],
      operators: [
        { name: "Equal", _id: "$eq" },
        { name: "Greater Than", _id: "$gt" },
        { name: "Greater Than or Equal", _id: "$gte" },
        { name: "Less Than ", _id: "$lt" },
        { name: "Less Than or Equal", _id: "$lte" },
        { name: "Not Equal", _id: "$ne" },
        { name: "Include", _id: "$in" },
        { name: "Not Include", _id: "$nin" },
        { name: "Contains", _id: "$contains" },
        { name: "Does not Contain", _id: "$notcontain" },
        { name: "Starts With", _id: "$startswith" },
        { name: "Does not Starts With", _id: "$notstartwith" },
        { name: "Exists", _id: "$exists" },
      ],
      headers: [
        { text: "Action", value: "action", sortable: false },
        { text: "Field", value: "field", sortable: false },
        { text: "Value", value: "value", sortable: false },
        { text: "Options", value: "show", sortable: false },
      ],
      advancedheaders: [
        { text: "Action", value: "action", sortable: false },
        { text: "Subrecords / Lists", value: "subrecord", sortable: false },
        { text: "Field Source", value: "source", sortable: false },
        { text: "Field", value: "field", sortable: false },
        { text: "Value", value: "value", sortable: false },
        { text: "Options", value: "show", sortable: false },
      ],
      range: [
        { name: "Custom", _id: "$custom" },
        { name: "Yesterday", _id: "$yesterday" },
        { name: "Today", _id: "$today" },
        { name: "Tomorrow", _id: "$tomorrow" },
        { name: "Same Day Year Before Last", _id: "$sameDayYearBeforeLast" },
        { name: "Same Day Last Year", _id: "$sameDayLastYear" },
        { name: "Same Day Last Month", _id: "$sameDayLastMonth" },
        { name: "Same Day Last Week", _id: "$sameDayLastWeek" },
        { name: "Start Of Last Year", _id: "$startOfLastYear" },
        { name: "Start Of Last Month", _id: "$startOfLastMonth" },
        { name: "Start Of 3 Months Ago", _id: "$startOf3MonthsAgo" },
        { name: "Start Of Last Week", _id: "$startOfLastWeek" },
        { name: "Start Of This Year", _id: "$startOfThisYear" },
        { name: "Start Of This Month", _id: "$startOfThisMonth" },
        { name: "Start Of This Week", _id: "$startOfThisWeek" },
        { name: "End Of Last Month", _id: "$endOfLastMonth" },
      ],
      rows: [100],
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  async created() {},
  methods: {
    rowClass() {
      return "queryrow";
    },
    addFilter() {
      let idx = this.query.setup.filters.length;
      if (idx <= 100) {
        this.query.setup.filters.push({
          value: null,
          subrecord: null,
          field: null,
        });
      } else {
        alert("to many columns");
      }
    },
    delFilter(item) {
      const index = this.query.setup.filters.indexOf(item);
      confirm("Are you sure?") && this.query.setup.filters.splice(index, 1);
    },
    upColumn(item) {
      const index = this.query.setup.filters.indexOf(item);
      if (index > 0) {
        let tmp = { ...this.query.setup.filters[index - 1] };
        this.$set(
          this.query.setup.filters,
          index - 1,
          this.query.setup.filters[index]
        );
        this.$set(this.query.setup.filters, index, tmp);
      }
    },
    downColumn(item) {
      const index = this.query.setup.filters.indexOf(item);
      if (index < this.query.setup.filters.length - 1) {
        let tmp = { ...this.query.setup.filters[index + 1] };
        this.$set(
          this.query.setup.filters,
          index + 1,
          this.query.setup.filters[index]
        );
        this.$set(this.query.setup.filters, index, tmp);
      }
    },
    catchEmitFilter(e, props) {
      if (Array.isArray(e.value))
        props.value = e.value.map((v) => (v ? v._id || v : v));
      else props.value = e.value ? e.value._id || e.value : e.value;
      console.log(props);
    },
    getFilteredFields(source, obj) {
      let collection = this.fieldsObj[source]
        ? this.fieldsObj[source].setup.collection
        : this.query.setup.collection;
      let filteredFields = this.fields.filter((field) => {
        return (
          field.setup.collection == collection ||
          (field &&
            field.form &&
            field.form.some((form) => {
              return (
                [collection, "all"].includes(form.collection) &&
                (obj && !field.setup.collection ? false : true)
              );
            }))
        );
      });
      return filteredFields;
    },
    getFilteredSubrecords(source) {
      let collection = this.fieldsObj[source]
        ? this.fieldsObj[source].setup.collection
        : this.query.setup.collection;
      let filteredFields = this.subrecords.filter((field) => {
        return (
          field &&
          field.form &&
          field.form.some((form) => {
            return [collection, "all"].includes(form.collection);
          })
        );
      });
      //console.log(filteredFields,collection)
      return filteredFields;
    },
    filterDecode(filter) {
      if (filter.value == "$today" || filter.value == "$custom")
        filter.value = new Date().toISOString().substr(0, 10);
      if (filter.value == "$yesterday")
        filter.value = new Date(new Date().setDate(new Date().getDate() - 1))
          .toISOString()
          .substr(0, 10);
      if (filter.value == "$tomorrow")
        filter.value = new Date(new Date().setDate(new Date().getDate() + 1))
          .toISOString()
          .substr(0, 10);

      if (filter.value == "$sameDayLastYear")
        filter.value = new Date(
          new Date(new Date().setYear(new Date().getFullYear() - 1))
        )
          .toISOString()
          .substr(0, 10);
      if (filter.value == "$sameDayYearBeforeLast")
        filter.value = new Date(
          new Date(new Date().setYear(new Date().getFullYear() - 2))
        )
          .toISOString()
          .substr(0, 10);
      if (filter.value == "$sameDayLastMonth")
        filter.value = new Date(
          new Date(new Date().setMonth(new Date().getMonth() - 1))
        )
          .toISOString()
          .substr(0, 10);
      if (filter.value == "$sameDayLastWeek")
        filter.value = new Date(new Date().setDate(new Date().getDate() - 7))
          .toISOString()
          .substr(0, 10);

      if (filter.value == "$startOfLastYear")
        filter.value = new Date(new Date().getFullYear() - 1, 0, 2)
          .toISOString()
          .substr(0, 10);
      if (filter.value == "$startOfLastMonth")
        filter.value = new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 1,
          2
        )
          .toISOString()
          .substr(0, 10);
      if (filter.value == "$startOf3MonthsAgo")
        filter.value = new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 3,
          2
        )
          .toISOString()
          .substr(0, 10);

      if (filter.value == "$startOfLastWeek")
        filter.value = new Date(
          new Date().setDate(new Date().getDate() - (6 + new Date().getDay()))
        )
          .toISOString()
          .substr(0, 10);

      if (filter.value == "$startOfThisYear")
        filter.value = new Date(new Date().getFullYear(), 0, 2)
          .toISOString()
          .substr(0, 10);
      if (filter.value == "$startOfThisMonth")
        filter.value = new Date(
          new Date(new Date().setMonth(new Date().getMonth())).setDate(1)
        )
          .toISOString()
          .substr(0, 10);
      if (filter.value == "$startOfThisWeek")
        filter.value = new Date(
          new Date().setDate(new Date().getDate() - (new Date().getDay() - 1))
        )
          .toISOString()
          .substr(0, 10);

      if (filter.value == "$endOfLastMonth")
        filter.value = new Date(new Date().setDate(0))
          .toISOString()
          .substr(0, 10);
      return filter.value;
    },
  },
};
</script>

<style scoped>
/* This is for documentation purposes and will not be needed in your application */
.v-speed-dial {
  position: absolute;
}

.v-btn--floating {
  position: relative;
}
</style>

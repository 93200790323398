 <template>
  <div>
    <v-row>
      <v-col>
        <v-tabs dark v-model="active">
          <v-tab
            v-for="(tab, index) of Object.values(tabs).filter(
              (tab) =>
                this.locationsFilter.includes(tab.location) ||
                !this.locationsFilter.length
            )"
            :key="index"
          >
            <v-badge color="primary">
              <span>{{ tab.name }}</span>
              <span slot="badge">{{ tab.items.length }}</span>
            </v-badge>
          </v-tab>

          <v-tab-item
            v-for="(tab, index) of Object.values(tabs).filter(
              (tab) =>
                this.locationsFilter.includes(tab.location) ||
                !this.locationsFilter.length
            )"
            :key="index"
          >
            <v-data-table
              :headers="tab.headers"
              :items="tab.items"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              :search="search"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
              :custom-sort="sort"
              @current-items="getFiltered"
              id="inventorybackorder"
            >
              <template v-slot:item.date="{ item }">
                <span>{{
                  new Date(item.date).toISOString().substr(0, 10)
                }}</span>
              </template>
              <template v-slot:item.eta="{ item }">
                <span>{{
                  item.eta ? new Date(item.eta).toISOString().substr(0, 10) : ""
                }}</span>
              </template>
              <template v-slot:item.transaction="{ item }">
                <router-link
                  :to="{
                    path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                  }"
                  class="link"
                  >{{
                    item.transaction.displayname || item.transaction.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.correlatedsoeta="{ item }">
                <span>{{
                  item.correlatedsoeta
                    ? new Date(item.correlatedsoeta).toISOString().substr(0, 10)
                    : ""
                }}</span>
              </template>
              <template v-slot:item.correlatedso="{ item }">
                <router-link
                  v-if="item.correlatedso"
                  :to="{
                    path: `/${item.correlatedso.collection}/${item.correlatedso.recordtype}/view/${item.correlatedso._id}`,
                  }"
                  class="link"
                  >{{
                    item.correlatedso.displayname || item.correlatedso.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.item="{ item }">
                <router-link
                  :to="{
                    path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`,
                  }"
                  class="link"
                  >{{ item.item.displayname || item.item.name }}</router-link
                >
              </template>
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                  }"
                  class="link"
                  >{{
                    item.customer.displayname || item.customer.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.fleetorder="{ item }">
                <v-chip label :color="item.fleetorder ? 'red' : 'none'" dark>{{
                  item.fleetorder ? "Yes" : "No"
                }}</v-chip>
              </template>
              <template v-slot:item.stockorder="{ item }">
                <v-chip label :color="item.stockorder ? 'red' : 'none'" dark>{{
                  item.stockorder ? "Yes" : "No"
                }}</v-chip>
              </template>
              <template v-slot:item.dropship="{ item }">
                <v-chip label :color="item.dropship ? 'red' : 'none'" dark>{{
                  item.dropship ? "Yes" : "No"
                }}</v-chip>
              </template>
              <template v-slot:item.deliveries="{ item }">
                <span v-for="po in item.deliveries" :key="po._id">
                  {{ po }}
                  <br />
                </span>
              </template>
              <template v-slot:top>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <exportData :headers="headers" :items="tab.items" />
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-select
                        dense
                        v-model="customer"
                        :items="customers"
                        label="Customer"
                        outlined
                        item-text="name"
                        item-value="_id"
                        hide-details
                        clearable
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        dense
                        v-model="manufacturer"
                        :items="manufacturers"
                        item-text="name"
                        item-value="_id"
                        label="Manufacturer"
                        outlined
                        return-object
                        hide-details
                        clearable
                        multiple
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        dense
                        v-model="service"
                        :items="
                          services.filter((service) =>
                            service.forlocations.includes(tab.location)
                          )
                        "
                        label="Services"
                        outlined
                        item-text="name"
                        item-value="_id"
                        hide-details
                        clearable
                        multiple
                      ></v-select>
                    </v-col>
                    <!-- <v-col>
                  <v-btn @click="action" block tile color="primary">Ready To Pack</v-btn>
                    </v-col>-->
                  </v-row>
                  <v-row v-for="m of manufacturer" :key="m._id">
                    <v-col></v-col>
                    <v-col>
                      <v-subheader>{{ m.name }}</v-subheader>
                      <pre class="pa-4" style="text-wrap: balance">{{
                        m.detaildescription
                      }}</pre>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import sort from "../../dataTable/sort";
import footer from "../backorderitems/footer";
import exportData from "../../dataTable/exportData";
export default {
  data() {
    return {
      loading: false,
      active: 0,
      headers: [
        { text: "Fleet Order", value: "fleetorder" },
        { text: "Drop Ship", value: "dropship" },
        { text: "Stock Order", value: "stockorder" },
        { text: "From", value: "dropshipfrom" },
        { text: "Item", value: "item" },
        { text: "Manufacturer", value: "manufacturer" },
        { text: "CoO", value: "coo" },
        {
          text: "Date",
          value: "created",
          width: "100px",
          function: "datetime",
        },
        { text: "Sales Order", value: "transaction" },
        { text: "Reference Number", value: "referencenumber" },
        { text: "Customer", value: "customer" },
        { text: "Bill Country", value: "billcountry" },
        { text: "Ordered", value: "quantity" },
        { text: "Fulfilled", value: "quantityfulfilled" },
        { text: "Backordered", value: "quantitybackorder" },
        { text: "Man. Stock", value: "manufacturerstock" },
        { text: "ETA", value: "eta" },
        { text: "ETA Memo", value: "etamemo" },
        { text: "ETA Internal", value: "etamemo2" },
        { text: "Service", value: "additionalservice" },
        { text: "Shipping Method", value: "shippingmethod" },
        { text: "PO", value: "correlated" },
        { text: "SO", value: "correlatedso" },
        { text: "SO ETA", value: "correlatedsoeta" },
        { text: "Internal Comments", value: "memo" },
        { text: "In Transit", value: "deliveries", width: "250px" },
        { text: "Sales Rep", value: "salesrep" },
        //{ text: "Shipping Method", value: "shippingmethod" },
      ],
      show: false,
      dropship: false,
      fleetorder: false,
      selected: [],
      singleSelect: false,
      items: [],
      itemsUK: [],
      itemsPL: [],
      itemsPEDDERSUK: [],
      services: [],
      service: [],
      manufacturer: [],
      manufacturers: [],
      customers: [],
      customer: null,
      rows: [-1, 100],
      search: "",
      filteredItems: [],
      locationsFilter: [],
      tabs: {},
    };
  },
  async created() {
    this.loading = true;
    this.tabs = {
      PL: {
        name: "Warehouse PL",
        location: "5e0dbaba9e33df43f0b3a495",
        items: [],
        headers: this.headers,
      },
      NL: {
        name: "Warehouse NL",
        location: "66e04fcba16b2e1e7d873be8",
        items: [],
        headers: this.headers,
      },
      // UK: {
      //   name: "Warehouse UK (Birmingham)",
      //   location: "5faa7485371aa739b8a08843",
      //   items: [],
      //   headers: this.headers,
      // },
      PeddersUK: {
        name: "Warehouse UK",
        location: "61e14c6ae4a35a668517a8fe",
        items: [],
        headers: this.headers,
      },
      BrakesPL: {
        name: "Brakes PL",
        location: "62de6196fa2fc522dc692c7e",
        items: [],
        headers: this.headers,
      },
    };
    if (this.$store.state.user.location)
      this.locationsFilter.push(this.$store.state.user.location);
    if (
      this.$store.state.user.forlocations &&
      this.$store.state.user.forlocations.length
    )
      this.locationsFilter.push(...this.$store.state.user.forlocations);
    console.log(this.locationsFilter);
    await this.getData("created");
  },
  watch: {
    async customer(value) {
      await this.getData("customer");
    },
    async manufacturer(value) {
      await this.getData("manufacturer");
    },
    async service(value) {
      await this.getData("service");
    },
    active(v) {
      if (this.service.length) this.service = [];
    },
  },
  updated: function () {
    let rows = document.querySelectorAll("#inventorybackorder tbody tr");
    this.filteredItems.forEach((item, index) => {
      if (item.kitcomponent && rows[index]) {
        rows[index].classList.add("kitcomponent");
      }
    });
  },
  methods: {
    sortSelection() {
      this.manufacturers.sort((x, y) => {
        if (x.name > y.name) return 1;
        else if (y.name > x.name) return -1;
        else return 0;
      });
    },
    async getData(init) {
      this.loading = true;
      let result = await service(
        "orders",
        {
          action: "getInventoryBackorder",
          customer: this.customer,
          manufacturer: this.manufacturer.map((m) => m._id),
          service: this.service,
        },
        false,
        true
      );
      result.items.forEach((item) => {
        item.created = item.created
          ? new Date(
              new Date(item.created).getTime() -
                new Date(item.created).getTimezoneOffset() * 60000
            )
              .toISOString()
              .substr(0, 10) +
            " " +
            new Date(
              new Date(item.created).getTime() -
                new Date(item.created).getTimezoneOffset() * 60000
            )
              .toISOString()
              .substr(11, 5)
          : "";
        item.shippingaddress =
          item.shippingaddress || item.shipaddressee || item.shipaddress;
      });
      this.items = [];
      if (init == "manufacturer" || init == "created") this.services = [];
      this.tabs["PL"].items = [];
      this.tabs["NL"].items = [];
      //this.tabs["UK"].items = [];
      this.tabs["PeddersUK"].items = [];
      this.tabs["BrakesPL"].items = [];

      result.items.forEach((item) => {
        if (item.company && ["maincompany"].includes(item.company)) {
          this.tabs["PL"].items.push(item);
        }
        if (item.company && ["65cf227de8d07217f255104f"].includes(item.company)) {
          this.tabs["NL"].items.push(item);
        }
        // if (
        //   item.company &&
        //   ["ozpartsuklimited"].includes(item.company) &&
        //   item.location &&
        //   !["61e14c6ae4a35a668517a8fe", "620bd994628dd8bb034ce62f"].includes(
        //     item.location
        //   )
        // ) {
        //   this.tabs["UK"].items.push(item);
        // }
        if (
          item.location &&
          ["61e14c6ae4a35a668517a8fe", "620bd994628dd8bb034ce62f"].includes(
            item.location
          )
        ) {
          this.tabs["PeddersUK"].items.push(item);
        }
        if (item.company && ["brakespl"].includes(item.company)) {
          this.tabs["BrakesPL"].items.push(item);
        }
        if (init == "created") {
          if (item.manufacturer)
            this.manufacturers.push(item.manufacturergroup);
          this.customers.push(item.customer);
        }
        if (init == "manufacturer" || init == "created")
          this.services.push(...item.services);
        this.sortSelection();
      });
      this.manufacturers = this.manufacturers.sort();
      this.customers = this.customers.sort();
      this.items = result.items;
      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    getFiltered(e) {
      this.filteredItems = e;
    },
    sort(items, sortBy, sortDesc) {
      return sort(items, sortBy, sortDesc);
    },
  },
  components: {
    exportData,
    toolfooter: footer,
  },
};
</script>
<style>
.kitcomponent {
  background: #ececec;
}
</style>
 <template>
  <div>
    <v-progress-linear
      :indeterminate="loading"
      height="3"
      class="ma-0"
    ></v-progress-linear>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-select
            :items="companies"
            v-model="company"
            label="Company"
            item-text="name"
            item-value="_id"
            class="mt-2 mb-2"
            outlined
            multiple
            dense
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="locations"
            v-model="location"
            label="Location"
            item-text="name"
            item-value="_id"
            class="mt-2 mb-2"
            multiple
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col>
          <v-menu
            v-model="menufrom"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            full-width
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                label="From"
                placeholder
                v-model="datefrom"
                append-icon="event"
                readonly
                v-on="on"
                outlined
                dense
                hide-details
                class="mt-2 mb-2"
                clearable
              ></v-text-field>
            </template>
            <v-date-picker v-model="datefrom"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-menu
            v-model="menuto"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            full-width
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                label="To"
                placeholder
                v-model="dateto"
                append-icon="event"
                readonly
                v-on="on"
                outlined
                dense
                hide-details
                class="mt-2 mb-2"
                clearable
              ></v-text-field>
            </template>
            <v-date-picker v-model="dateto"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col v-if="active == 0">
          <v-switch v-model="bill" label="Bills"></v-switch>
        </v-col>
      </v-row>
    </v-container>
    <v-row>
      <v-col>
        <v-tabs dark v-model="active">
          <v-tab :key="3">Enova Export</v-tab>
          <v-tab :key="4">Enova MAG</v-tab>
          <v-tab :key="1">Inventory Valuation</v-tab>
          <!-- <v-tab :key="2">Item Receipts</v-tab> -->
          <v-tab-item :key="3">
            <v-data-table
              :headers="bill ? headers3bill : headers3"
              :items="enova"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              :search="search"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
            >
              <template v-slot:footer>
                <v-divider></v-divider>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <exportData
                        :headers="bill ? headers3bill : headers3"
                        :items="enova"
                      />
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-btn
                        @click="getEnova()"
                        block
                        tile
                        color="primary"
                        class="mt-2 mb-2"
                        >Refresh</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item :key="4">
            <v-data-table
              :headers="headers4"
              :items="enovamag"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              :search="search"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
            >
              <template v-slot:footer>
                <v-divider></v-divider>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <exportData :headers="headers4" :items="enovamag" />
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-btn
                        @click="getEnovaMag()"
                        block
                        tile
                        color="primary"
                        class="mt-2 mb-2"
                        >Refresh</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item :key="1">
            <v-data-table
              :headers="headers"
              :items="inventoryvaluation"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              :search="search"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
            >
              <template v-slot:footer>
                <v-divider></v-divider>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <exportData
                        :headers="headers"
                        :items="inventoryvaluation"
                      />
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-btn
                        @click="getInventoryValuation()"
                        block
                        tile
                        color="primary"
                        class="mt-2 mb-2"
                        >Refresh</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import chartpie from "../../charts/pie";
import colors from "../../charts/colors";
import chartdoughnut from "../../charts/doughnut";
import exportData from "../../dataTable/exportData";
export default {
  props: ["customer"],
  data() {
    return {
      loading: false,
      bill: false,
      active: 0,
      datefrom: new Date(
        new Date(new Date().setMonth(new Date().getMonth())).setDate(1)
      )
        .toISOString()
        .substr(0, 10),
      dateto: new Date().toISOString().substr(0, 10),
      items: {},
      enova: [],
      inventoryvaluation: [],
      enovamag: [],
      menuto: false,
      menufrom: false,
      data: null,
      company: [],
      companies: [],
      location: [],
      locations: [],
      initdate: null,
      headers: [
        { text: "Item", value: "item" },
        { text: "Valuation", value: "inventoryvaluation" },
        { text: "On Hand", value: "quantityonhand" },
        { text: "Manufacturer", value: "manufacturer" },
        { text: "Price Group", value: "pricegroup" },
      ],
      headers2: [
        { text: "Item", value: "item" },
        { text: "Manufacturer", value: "manufacturer" },
        { text: "Valuation", value: "inventoryvaluation" },
        { text: "Amount", value: "amount" },
        { text: "Import Duty Cost", value: "importdutycost" },
        { text: "Delivery Cost", value: "deliverycost" },
        { text: "Tax Amount", value: "taxamount" },
      ],
      headers3: [
        { text: "Kod", value: "customernumber" },
        { text: "Tax Number", value: "taxnumber" },
        { text: "Enova Type", value: "customtype" },
        { text: "Customer", value: "customer" },
        { text: "Address", value: "billaddress" },
        { text: "Zip", value: "billzip" },
        { text: "City", value: "billcity" },
        { text: "Country", value: "billcountry" },
        { text: "Record Type", value: "recordtype" },
        { text: "Document Number", value: "name" },
        { text: "Date", value: "date" },
        { text: "Ship Date", value: "shippingdate" },
        { text: "Created From", value: "createdfrom" },
        { text: "Created From Date", value: "createdfromdate" },
        { text: "Exchange Rate", value: "exchangerate" },
        { text: "Type", value: "type" },
        { text: "Currency", value: "currency" },
        { text: "Amount", value: "amount" },
        { text: "tax", value: "tax" },
        { text: "Tax Amount", value: "taxamount" },
        { text: "Gross Amount", value: "grossamount" },
        { text: "Due Date", value: "duedate" },
        { text: "Terms", value: "terms" },
        { text: "Amount [PLN]", value: "plnamount" },
        { text: "Tax Amount [PLN]", value: "plntaxamount" },
        { text: "Gross Amount [PLN]", value: "plngrossamount" },
        { text: "Typ Dokumentu VAT", value: "vatdoctype" },
        { text: "GTU", value: "gtu" },
        { text: "Procedury VAT", value: "vatprocedure" },
      ],
      headers3bill: [
        { text: "Kod", value: "customernumber" },
        { text: "Tax Number", value: "taxnumber" },
        { text: "Enova Type", value: "customtype" },
        { text: "Customer", value: "customer" },
        { text: "Address", value: "billaddress" },
        { text: "Zip", value: "billzip" },
        { text: "City", value: "billcity" },
        { text: "Country", value: "billcountry" },
        { text: "Record Type", value: "recordtype" },
        { text: "Document Number", value: "name" },
        { text: "Date", value: "date" },
        { text: "Ship Date", value: "shippingdate" },
        { text: "Created From", value: "createdfrom" },
        { text: "Created From Date", value: "createdfromdate" },
        { text: "Exchange Rate", value: "exchangerate" },
        { text: "Type", value: "type" },
        { text: "Currency", value: "currency" },
        { text: "Amount", value: "amount" },
        { text: "tax", value: "tax" },
        { text: "Tax Amount", value: "taxamount" },
        { text: "Gross Amount", value: "grossamount" },
        { text: "Due Date", value: "duedate" },
        { text: "Terms", value: "terms" },
        { text: "Amount [PLN]", value: "plnamount" },
        { text: "Tax Amount [PLN]", value: "plntaxamount" },
        { text: "Gross Amount [PLN]", value: "plngrossamount" },
        { text: "Typ Dokumentu VAT", value: "vatdoctype" },
        { text: "Procedury VAT", value: "vatprocedure" },
        { text: "PZ", value: "itemreceipt" },
        { text: "Delivery Method", value: "deliverymethod" },
      ],
      headers4: [
        { text: "Enova Type", value: "customtype" },
        { text: "Date", value: "date" },
        { text: "Record Type", value: "recordtype" },
        { text: "Transaction", value: "transaction" },
        { text: "Valuation", value: "inventoryvaluation" },
        { text: "Customer/Vendor", value: "customer" },
        { text: "Correlated", value: "correlated" },
        { text: "Memo", value: "memo" },
        { text: "MPK", value: "mpk" },
      ],
      items: {},
      rows: [10],
      search: "",
      show: true,
    };
  },
  async created() {
    this.loading = true;
    this.companies = await service("users", {
      action: "find",
      data: [{ recordtype: "company" }],
    });
    this.locations = await service("accounting", {
      action: "find",
      data: [{ recordtype: "location", company: this.company }],
    });
    this.loading = false;
  },
  watch: {
    async company() {
      this.locations = await service("accounting", {
        action: "find",
        data: [{ recordtype: "location", company: { $in: this.company } }],
      });
    },
  },
  methods: {
    async action() {
      this.loading = true;
      //await this.getData();
      let enovaSales = this.getEnova();
      let ennovaMag = this.getEnovaMag();
      [enovaSales, ennovaMag] = await Promise.all([enovaSales, ennovaMag]);
      this.loading = false;
    },
    toChartData(type, data) {
      let chartData = { ...data };
      if (type == "pie" || type == "doughnut") {
        // chartData.datasets = chartData.datasets2;
        // chartData.labels = chartData.labels2;
        chartData.datasets.map((data) => {
          data.backgroundColor = colors;
        });
      } else {
        chartData.datasets.map((data, index) => {
          data.backgroundColor = colors[index];
        });
      }
      return chartData;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    async getEnova() {
      this.loading = true;
      let data = await service("report", {
        action: "getEnovaSalesData",
        company: this.company,
        location: this.location,
        dateto: this.dateto,
        datefrom: this.datefrom,
        bill: this.bill,
      }, false, true);
      this.enova = data.items;
      this.loading = false;
    },
    async getEnovaMag() {
      this.loading = true;
      let data = await service("report", {
        action: "getEnovaMagData",
        location: this.location,
        dateto: this.dateto,
        datefrom: this.datefrom,
        company: this.company,
      }, false, true);

      this.enovamag = data.items;
      this.loading = false;
    },
    async getInventoryValuation() {
      this.loading = true;
      let data = await service("report", {
        action: "getInventoryValuation",
        location: this.location,
        dateto: this.dateto,
        datefrom: this.datefrom,
        company: this.company,
      }, false, true);
      this.inventoryvaluation = data.items;
      this.loading = false;
    },
    async getData() {
      let initdate = new Date().toISOString();
      this.initdate = initdate;

      this.data = null;
      this.items = {};
      let sort = { date: 1 };
      let newresult = [];
      let step = 0;
      let size = 5000;
      let minsize = 100;
      let skip = 0;
      while (initdate && this.initdate && this.initdate == initdate) {
        const options = [
          { $sort: sort },
          { $skip: skip },
          { $limit: step ? size : minsize },
        ];
        skip += step == 0 ? minsize : size;
        let data = await service(
          "report",
          {
            action: "getInventoryValuation",
            location: this.location,
            dateto: this.dateto,
            datefrom: this.datefrom,
            data: this.data,
            initdate: this.initdate,
            options: options,
          },
          false,
          true
        );
        initdate = data.initdate;
        if (this.initdate == data.initdate || !data.initdate) {
          this.data = data.data;
          this.items = data.items;
        } else {
          console.log("zmiana");
        }
        step++;
      }
    },
  },
  beforeDestroy() {
    this.initdate = null;
  },
  components: {
    chartpie,
    chartdoughnut,
    exportData,
  },
};
</script>

<style scoped>
.v-picker.v-card {
  box-shadow: none;
  border-radius: 0;
}
</style>
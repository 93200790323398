var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","show-select":"","search":_vm.search,"single-select":_vm.singleSelect,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter,"item-class":_vm.rowClass},scopedSlots:_vm._u([{key:"item.transaction",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.transaction.collection) + "/" + (item.transaction.recordtype) + "/view/" + (item.transaction._id)),
            }}},[_vm._v(_vm._s(item.transaction.displayname || item.transaction.name))])]}},{key:"item.customer",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.customer.collection) + "/" + (item.customer.recordtype) + "/view/" + (item.customer._id)),
            }}},[_vm._v(_vm._s(item.customer.displayname || item.customer.name))])]}},{key:"item.item",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.item.collection) + "/" + (item.item.recordtype) + "/view/" + (item.item._id)),
            }}},[_vm._v(_vm._s(item.item.displayname || item.item.name))])]}},{key:"item.accountonhold",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.accountonhold ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.accountonhold ? "Yes" : "No"))])]}},{key:"item.quantitytopack",fn:function(ref){
            var item = ref.item;
return [(item.quantitytopack)?_c('v-chip',{attrs:{"label":"","color":"yellow","dark":""}},[_vm._v(_vm._s(item.quantitytopack))]):_vm._e()]}},{key:"item.newquantitycommitted",fn:function(ref){
            var item = ref.item;
return [_c('v-text-field',{attrs:{"type":"number","dense":"","label":"Qty. Committed","single-line":"","hide-details":"","outlined":"","disabled":!!(
                item.quantityreceived &&
                item.quantityreceived == item.quantitycommitted &&
                !item.quantitybackorder
              ),"min":item.quantityreceived,"max":item.quantity},model:{value:(item.newquantitycommitted),callback:function ($$v) {_vm.$set(item, "newquantitycommitted", $$v)},expression:"item.newquantitycommitted"}})]}},{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('v-combobox',{attrs:{"dense":"","items":_vm.filteritems,"label":"Item","outlined":"","return-object":"","item-text":"name","item-value":"_id"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchRecords.apply(null, arguments)}},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}})],1),_c('v-col',[_c('v-switch',{attrs:{"label":"Auto Mode"},model:{value:(_vm.autorecalc),callback:function ($$v) {_vm.autorecalc=$$v},expression:"autorecalc"}})],1),_c('v-spacer'),_c('v-col',[_c('v-btn',{attrs:{"block":"","tile":"","color":"primary"},on:{"click":_vm.action}},[_vm._v("Reallocate")])],1)],1)],1)]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
 <template>
  <div>
    <v-row>
      <v-col>
        <v-tabs dark v-model="active">
          <v-tab v-for="(location, index) in locations" :key="index">
            <v-badge color="primary">
              <span>Warehouse {{ location }}</span>
              <span slot="badge">{{
                items.filter(
                  (item) =>
                    item.location == location &&
                    (["open", "received", "pendingapproval"].includes(
                      item.status._id
                    ) ||
                      showall)
                ).length
              }}</span>
            </v-badge>
          </v-tab>
          <v-tab-item v-for="(location, index) in locations" :key="index">
            <v-data-table
              :headers="headers"
              :items="
                items.filter(
                  (item) =>
                    item.location == location &&
                    (['open', 'received', 'pendingapproval'].includes(
                      item.status._id
                    ) ||
                      showall)
                )
              "
              loading-text="Loading... Please wait"
              :loading="loading"
              item-key="_id"
              v-model="selected"
              :search="search"
              :single-select="singleSelect"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
            >
              <template v-slot:item.print="{ item }">
                <v-btn
                  @click="printPDF(item)"
                  block
                  tile
                  color="primary"
                  class="mt-2 mb-2"
                  >Print</v-btn
                >
              </template>
              <template v-slot:item.date="{ item }">
                <span>{{
                  new Date(item.date).toISOString().substr(0, 10)
                }}</span>
              </template>
              <template v-slot:item.shipaddress="{ item }">
                <span v-if="item.shipaddress">{{
                  item.shipaddress.toString()
                }}</span>
              </template>
              <template v-slot:item.transaction="{ item }">
                <router-link
                  :to="{
                    path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                  }"
                  class="link"
                  >{{
                    item.transaction.displayname || item.transaction.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                  }"
                  class="link"
                  >{{
                    item.customer.displayname || item.customer.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.delivery="{ item }">
                <router-link
                  :to="{
                    path: `/${item.delivery.collection}/${item.delivery.recordtype}/view/${item.delivery._id}`,
                  }"
                  class="link"
                  >{{
                    item.delivery.displayname || item.delivery.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.status="{ item }">
                <span>{{ item.status.displayname || item.status.name }}</span>
              </template>
              <template v-slot:item.createdfrom="{ item }">
                <router-link
                  v-for="po in item.createdfrom"
                  :key="po._id"
                  :to="{
                    path: `/${po.collection}/${po.recordtype}/view/${po._id}`,
                  }"
                  class="link text-decoration-none"
                >
                  {{ po.displayname || po.name }}

                  <v-tooltip
                    bottom
                    v-if="
                      Math.round(
                        Math.abs(
                          (new Date(item.date) - new Date(po.date)) / 86400000
                        )
                      ) > 30
                    "
                  >
                    <span class="text-decoration-none">{{
                      new Date(po.date).toISOString().substr(0, 10)
                    }}</span>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-alert</v-icon>
                    </template>
                  </v-tooltip>
                </router-link>
              </template>
              <template v-slot:item.correlated="{ item }">
                <router-link
                  v-for="(po, index) in item.correlated"
                  :key="po._id"
                  :to="{
                    path: `/${po.collection}/${po.recordtype}/view/${po._id}`,
                  }"
                  class="link"
                >
                  {{ po.displayname || po.name }}
                  <span v-if="index + 1 < item.correlated.length">/</span>
                </router-link>
              </template>
              <template v-slot:item.arrived="{ item }">
                <v-checkbox
                  :disabled="item.status._id != 'pendingapproval'"
                  :input-value="
                    ['received', 'open', 'completed'].includes(item.status._id)
                  "
                  @click="setStatus(item, 'received')"
                  block
                  tile
                  color="primary"
                  class="mt-2 mb-2"
                  >Print</v-checkbox
                >
              </template>
              <template v-slot:item.approved="{ item }">
                <v-checkbox
                  :disabled="item.status._id != 'received'"
                  :input-value="['open', 'completed'].includes(item.status._id)"
                  @click="setStatus(item, 'open')"
                  block
                  tile
                  color="primary"
                  class="mt-2 mb-2"
                ></v-checkbox>
              </template>
              <template v-slot:top>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-switch v-model="showall" label="Show All"></v-switch>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-data-table>
            <v-subheader class="my-3">Return To Vendor</v-subheader>
            <v-data-table
              :headers="headers2"
              :items="items2.filter((item) => item.location == location)"
              loading-text="Loading... Please wait"
              :loading="loading"
              item-key="_id"
              v-model="selected2"
              :search="search"
              show-select="true"
              :single-select="singleSelect"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
            >
              <template v-slot:item.date="{ item }">
                <span>{{
                  new Date(item.date).toISOString().substr(0, 10)
                }}</span>
              </template>

              <template v-slot:item.transaction="{ item }">
                <router-link
                  :to="{
                    path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                  }"
                  class="link"
                  >{{
                    item.transaction.displayname || item.transaction.name
                  }}</router-link
                >
              </template>
            </v-data-table>
            <v-btn
              :disabled="!selected2.length"
              @click="action()"
              block
              tile
              color="primary"
              class="mt-2 mb-2"
              >Credit Memo</v-btn
            >
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
export default {
  props: ["record"],
  data() {
    return {
      active: 0,
      loading: false,
      headers: [
        { text: "Location", value: "location", sortable: false },
        { text: "Status", value: "status", sortable: true },
        { text: "Date", value: "date", sortable: true },
        { text: "Return#", value: "transaction", sortable: false },
        { text: "Customer", value: "customer", sortable: false },
        { text: "Shipping Address", value: "shipaddress" },
        { text: "Invoice", value: "createdfrom", sortable: false },
        { text: "KFV/RA", value: "correlated", sortable: false },
        { text: "Sales Rep", value: "salesrep", sortable: false },
        { text: "Received", value: "arrived", sortable: false },
        { text: "Approval", value: "approved", sortable: false },
        { text: "Return Form", value: "print" },
      ],
      headers2: [
        { text: "Location", value: "location", sortable: false },
        { text: "Date", value: "date", sortable: true },
        { text: "Return To Vendor", value: "transaction", sortable: false },
      ],
      items: [],
      items2: [],
      rows: [100],
      singleSelect: false,
      showall: false,
      selected: [],
      selected2: [],
      search: "",
      show: false,
      vendor: null,
      vendors: [],
      message: "",
      delivery: this.record,
      locations: ["PL", "UK"],
    };
  },
  async created() {
    await this.getData();
  },

  methods: {
    async action() {
      let createdfroms = this.selected2.map((line) => line._id);
      this.$router.push({
        name: "Transactions",
        params: {
          recordtype: "creditmemo",
          mode: "create",
          createdfrom: createdfroms,
        },
      });
    },
    async getData() {
      this.loading = true;
      let result = await service(
        "delivery",
        {
          action: "getReturnRequests",
        },
        false,
        true
      );
      this.locations = [];
      this.items = result.items;
      this.items2 = result.returntovendors;
      this.items.forEach((item) => {
        if (!this.locations.includes(item.location))
          this.locations.push(item.location);
      });
      this.items2.forEach((item) => {
        if (!this.locations.includes(item.location))
          this.locations.push(item.location);
      });
      this.loading = false;
    },
    async printPDF(item) {
      let pdf = `http://localhost:8080/api/pdf/deliveries/${item._id}/`;
      window.open(pdf, "_blank");
    },
    async setStatus(item, status) {
      this.loading = true;
      item.status = { _id: status, name: status };
      await service("deliveries", {
        action: "changeStatus",
        rid: item._id,
        status: item.status,
      }).catch((error) => {
        item.status = null;
        alert(error.message);
      });
      await this.getData();
      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
  },
};
</script>
/* eslint-disable */
import service from "../../../api/index";
export default async () => {
    let items = [];

    let results = await service("transactions", { action: "find", data: [{ dropship: true, recordtype: "salesorder" }] }, false, true);

    for (let so of results) {
        let solines = await service("transactions_lines", { action: "find", data: [{ transaction: so._id }] }, false, true)
            .catch(error => {
                console.log(error.message);
            });

        for (let line of solines) {
            let row = {
                _id: line._id,
                date: new Date(so.date).toISOString().substr(0, 10),
                salesorder: so,
                customer: so.customer,
                quantity: line.quantity,
                quantityonorder: line.quantityonorder,
                deliverymehod: so.deliverymethod,
                weight: line.weight,
                memo: so.memo,
                po: ""
            };
            row.item = await service("items", { action: "findOne", data: [{ _id: line.item }] }, false, true);
            if (row.item.recordtype == "inventoryitem") {

                row.location = await service("accounting", { action: "findOne", data: [{ _id: so.location }] }, false, true);

                if (so.deliverymethod)
                    row.deliverymethod = await service("lists", { action: "findOne", data: [{ _id: so.deliverymethod }] }, false, true);

                let inventory = await service("items_inventory", { action: "findOne", data: [{ location: so.location, item: line.item }] }, false, true)
                    .catch(error => { return {} });
                row.available = inventory.available;
                row.stockgroup = inventory.stockgroup ? await service("classification", { action: "findOne", data: [{ _id: inventory.stockgroup, recordtype: "stockgroup" }] }, false, true).catch(error => { return "" }).then(response => { return response.name }) : "";
                row.stockcategory = inventory.stockcategory ? await service("classification", { action: "findOne", data: [{ _id: inventory.stockcategory, recordtype: "stockcategory" }] }, false, true).catch(error => { return "" }).then(response => { return response.name }) : "";
                row.customer = await service("users", { action: "findOne", data: [{ _id: so.customer }] }, false, true);
                let lines = await service("transactions_lines", { action: "find", data: [{ createdfromline: line._id, parentrecordtype: "purchaseorder" }] }, false, true);
                let polines = [];
                lines.forEach(line => { polines.push(line.transaction); });

                row.po = await service("transactions", { action: "find", data: [{ _id: { $in: polines } }] }, false, true);
                items.push(row);
            }
        }
    }
    return items;
}
<template>
  <v-card tile>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Key Account Manager</v-toolbar-title>
    </v-toolbar>
    <v-tabs dark v-model="active">
      <v-tab :key="1">Statistics</v-tab>
      <v-tab :key="2">Map</v-tab>
      <v-tab :key="3">Shipping Methods</v-tab>
      <v-tab :key="4">Activities</v-tab>
      <v-tab :key="5">Reports</v-tab>
      <v-tab :key="6"><v-icon>mdi-close-outline</v-icon></v-tab>

      <v-tab-item :key="1">
        <statistics v-if="active == 0" :kam="this.$store.state.user"/>
      </v-tab-item>
      <v-tab-item :key="2">
        <googlemap v-if="active == 1" />
      </v-tab-item>
      <v-tab-item :key="3">
        <shippingMethods v-if="active == 2" />
      </v-tab-item>
      <v-tab-item :key="4">
        <activities v-if="active == 3" :record="this.$store.state.user"/>
      </v-tab-item>
      <v-tab-item :key="5">
        <reports v-if="active == 4" />
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>
  
  <script>
import reports from "../components/tools/reports/View";
import googlemap from "../components/tools/map/View";
import statistics from "../components/tools/statistics/ViewNEW";
import shippingMethods from "../components/tools/shippingMethods/View";
import activities from "../components/tools/activities/View.vue";
/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
      active: 6,
    };
  },
  components: {
    googlemap,
    reports,
    statistics,
    shippingMethods,
    activities
  },
};
</script>
 <template>
  <v-row>
    <v-col>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Quantity:</v-list-item-subtitle>
            <v-list-item-title>{{ quantity }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Weight:</v-list-item-subtitle>
            <v-list-item-title>{{ weight }} kg</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Amount:</v-list-item-subtitle>
            <v-list-item-title>{{ amount }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Additional Charge:</v-list-item-subtitle>
            <v-list-item-title>TBA</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
    <v-col v-if="recordtype != 'inventorytransfer'">
      <v-select
        :items="vendors"
        v-model="vendor"
        label="Vendor"
        item-text="displayname"
        item-value="_id"
        return-object
        outlined
        dense
        clearable
        :loading="loading"
      ></v-select>
      <v-select
        :items="deliverymethods"
        v-model="deliverymethod"
        label="Delivery Method"
        item-text="displayname"
        item-value="_id"
        return-object
        outlined
        dense
        :loading="loading"
        clearable
      ></v-select>
      <v-select
        :items="currencies"
        v-model="currency"
        label="Currency"
        item-text="displayname"
        item-value="_id"
        return-object
        outlined
        dense
        clearable
        :loading="loading"
      ></v-select>
    </v-col>
    <v-col v-if="recordtype != 'inventorytransfer'">
      <v-text-field
        v-model="address.shipaddressee"
        label="Addressee"
        outlined
        dense
      ></v-text-field>
      <v-text-field
        v-model="address.shipaddress"
        label="Address"
        outlined
        dense
      ></v-text-field>
      <v-text-field
        v-model="address.shipcity"
        label="City"
        outlined
        dense
      ></v-text-field>
    </v-col>
    <v-col v-if="recordtype != 'inventorytransfer'">
      <v-text-field
        v-model="address.shipzip"
        label="Zip"
        outlined
        dense
      ></v-text-field>
      <v-autocomplete
        :items="countries"
        v-model="address.shipcountry"
        label="Counrty"
        item-text="displayname"
        item-value="_id"
        return-object
        outlined
        dense
      ></v-autocomplete>
      <v-text-field
        v-model="address.shipphone"
        label="Phone"
        outlined
        dense
      ></v-text-field>
      <!-- <v-btn @click="action('stockrequest')" block tile color="primary"
        >Stock Request</v-btn
      > -->
    </v-col>

    <v-col>
      <v-textarea
        outlined
        label="Message"
        v-model="message"
        height="168"
      ></v-textarea>
      <v-btn @click="action(recordtype)" block tile color="primary"
        >Purchase Order</v-btn
      >
    </v-col>
  </v-row>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
export default {
  props: [
    "items",
    "selected",
    "field",
    "defvendor",
    "stockorder",
    "dropship",
    "fleetorder",
    "recordtype",
    "location",
    "destination",
    "lowstock",
  ],
  data() {
    return {
      loading: false,
      vendor: this.defvendor || null,
      vendors: [],
      currency: null,
      currencies: [],
      message: "",
      countries: [],
      address: {},
      deliverymethod: null,
      deliverymethods: [],
      quantity: 0,
      weight: 0,
      amount: 0,
    };
  },
  async created() {
    this.loading = true;
    let currencies = [];
    let items = [];
    this.items.forEach((line) => {
      items.push(line.item._id);
    });
    let vendors = await service(
      "items_vendor",
      {
        action: "find",
        data: [{ item: { $in: items } }],
        limit: items.length * 3,
      },
      false,
      true
    ).then((response) => {
      let ids = [];
      response.forEach((v) => {
        if (!ids.includes(v.customer)) ids.push(v.customer);
        currencies.push(v.currency);
      });
      return ids;
    });
    vendors.push(
      "maincompany",
      "brakespl",
      "ozpartsuklimited",
      "65cf227de8d07217f255104f"
    );
    this.vendors = await service(
      "users",
      {
        action: "find",
        data: [{ _id: { $in: vendors } }],
      },
      false,
      true
    ).then((response) => {
      response.forEach((v) => {
        if (!currencies.includes(v.currency)) currencies.push(v.currency);
        v.displayname = v.displayname || v.name;
      });
      return response;
    });
    this.currencies = await service(
      "accounting",
      {
        action: "find",
        data: [{ _id: { $in: currencies } }],
      },
      false,
      true
    ).then((response) => {
      response.forEach((v) => {
        v.displayname = v.displayname || v.name;
      });
      return response;
    });

    this.deliverymethods = await service(
      "lists",
      {
        action: "find",
        data: [{ recordtype: "deliverymethod" }],
      },
      false,
      true
    ).then((response) => {
      response.forEach((v) => {
        v.displayname = v.displayname || v.name;
      });
      return response;
    });

    this.countries = await service(
      "countries",
      {
        action: "find",
        data: [{}],
      },
      false,
      true
    ).then((response) => {
      response.forEach((v) => {
        v.displayname = v.displayname || v.name;
      });
      return response;
    });
    this.loading = false;
  },
  watch: {
    async vendor() {
      await this.recalctotal("vendor");
    },
    async deliverymethod() {
      await this.recalctotal("deliverymethod");
    },
    async currency() {
      await this.recalctotal("currency");
    },
    async selected(value) {
      //console.log(value);
      if (value[0] && value[0].dropship) {
        let so = value[0].transaction;
        this.address.shipaddressee = so.shipaddressee;
        this.address.shipaddress = so.shipaddress;
        this.address.shipcity = so.shipcity;
        this.address.shipzip = so.shipzip;
        this.address.shipcountry = so.shipcountry;
        this.address.shipphone = so.shipphone;
        this.deliverymethod = so.deliverymethod;
        this.message =
          "Please send this directly to our customer to the address below. Please use your forwarder for that shipment. Once shipped please send us tracking number and invoice. Thank you.";
      } else {
        this.address = {};
      }
      if (!this.loading) await this.recalctotal();
    },
  },
  methods: {
    async recalctotal(field) {
      this.loading = true;
      let weight = 0;
      let amount = 0;
      let quantity = 0;
      if (this.vendor) {
        let critery = {
          customer: this.vendor._id,
          // $or: [
          //   { deliverymethod: { $in: [null, ""] } },
          //   { deliverymethod: { $exists: false } },
          // ],
        };
        if (this.vendor.currency && field == "vendor")
          this.currency = this.vendor.currency;
        if (this.currency)
          critery.currency = this.currency._id || this.currency;
        // if (this.deliverymethod) {
        //   delete critery.$or;
        //   critery.deliverymethod = this.deliverymethod;
        // }

        for (let index = 0; index < this.selected.length; index++) {
          let line = this.selected[index];
          weight += line[this.field || "quantitybackorder"] * line.weight || 0;
          quantity += line[this.field || "quantitybackorder"];
          critery.item = line.item._id;
          let vendorline = await service(
            "items_vendor",
            {
              action: "findOne",
              data: [critery],
            },
            false,
            true
          ).catch((error) => {
            if (this.vendor.recordtype != "company") {
              this.selected.splice(index, 1);
              index--;
            }
          });
          if (vendorline) {
            amount +=
              line[this.field || "quantitybackorder"] *
              vendorline.purchaseprice;
          }
        }
      }
      // for (let index = 0; index < this.selected.length; index++) {
      //   weight += line[this.field || "quantitybackorder"] * line.weight || 0;
      //   quantity += line[this.field || "quantitybackorder"];
      // }
      this.weight = weight.toFixed(2);
      this.amount = amount.toFixed(2);
      this.quantity = quantity;
      this.loading = false;
    },
    action(recordtype = "purchaseorder") {
      let lines = [];
      let location = null;
      let salesorder = null;
      let dropship = false;
      let customer = null;
      let error = false;
      let pomemo = {};
      this.selected.forEach((select) => {
        if (!customer && select.customer)
          customer = select.customer._id || select.customer;
        if (select.pomemo) pomemo[select.transaction.name] = select.pomemo;
        if (select.dropship) dropship = true;
        if (
          location &&
          select.location &&
          (select.location._id || select.location) != location
        ) {
          alert("You cannot select more locations");
          error = true;
        }
        location = select.location
          ? select.location._id || select.location
          : null;
        if (
          dropship &&
          salesorder &&
          select.transaction &&
          select.customer &&
          select.customer._id != customer
        ) {
          alert("You cannot select more Customers");
          error = true;
        }
        salesorder = select.transaction ? select.transaction._id : null;
        let line = {
          item: select.item,
          memoline: select.memoline,
          quantity: select[this.field || "quantitybackorder"],
        };
        if (!select.constructionpart) {
          line.createdfromline = select.transaction
            ? {
                _id: select._id,
                stockorder: select.stockorder,
                fleetorder: select.fleetorder,
                specialorder: select.specialorder,
                filled: true,
              }
            : null;
          line.createdfromtransaction = select.transaction
            ? select.transaction._id
            : null;
        }
        if (line.quantity) {
          console.log(line.quantity);
          lines.push(line);
        }
      });
      if (!error)
        if (recordtype == "purchaseorder") {
          console.log(location, this.location);
          pomemo = Object.entries(pomemo)
            .map((memo) => {
              return `${memo[0]}-${memo[1]}`;
            })
            .toString();
          this.$router.push({
            name: "Transactions",
            params: {
              recordtype: recordtype,
              mode: "create",
              transactionlines: lines,
              location: location || this.location,
              destination: this.destination,
              message: this.message,
              customer: this.vendor,
              currency: this.currency,
              shipaddressee: this.address.shipaddressee,
              shipaddress: this.address.shipaddress,
              shipcity: this.address.shipcity,
              shipzip: this.address.shipzip,
              shipcountry: this.address.shipcountry,
              shipphone: this.address.shipphone,
              deliverymethod: this.deliverymethod,
              stockorder: this.stockorder,
              dropship: this.dropship,
              fleetorder: this.fleetorder,
              memo: pomemo,
              lowstockorder: this.lowstock,
            },
          });
        }
      if (recordtype == "inventorytransfer") {
        this.$router.push({
          name: "Transactions",
          params: {
            recordtype: recordtype,
            customer: this.vendor,
            mode: "create",
            transactionlines: lines,
            message: this.message,
            location: location || this.location,
            destination: this.destination,
          },
        });
      }
    },
  },
};
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.query.setup.collection)?_c('v-data-table',{attrs:{"headers":_vm.advanced ? _vm.advancedheaders : _vm.headers,"items":_vm.query.setup.filters,"loading-text":"Loading... Please wait","hide-default-footer":"","item-key":"index","footer-props":{ itemsPerPageOptions: _vm.rows },"item-class":_vm.rowClass},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"mt-4",attrs:{"label":"Name","outlined":"","dense":""},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})]}},{key:"item.field",fn:function(ref){
var item = ref.item;
return [(item.show)?_c('v-text-field',{staticClass:"mt-4 mb-2",attrs:{"label":"Name","outlined":"","hide-details":"","dense":""},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}):_vm._e(),_c('v-autocomplete',{attrs:{"items":_vm.getFilteredFields(
          item.sourcesubrecord || item.source || item.subrecord
        ),"label":"Field","item-text":"name","item-value":"_id","outlined":"","dense":"","rules":[_vm.rules.required]},model:{value:(item.field),callback:function ($$v) {_vm.$set(item, "field", $$v)},expression:"item.field"}})]}},{key:"item.subrecord",fn:function(ref){
        var item = ref.item;
return [_c('v-autocomplete',{staticClass:"mt-4",attrs:{"items":_vm.getFilteredSubrecords(),"label":"Subrecord","item-text":"name","item-value":"_id","outlined":"","dense":"","clearable":""},model:{value:(item.subrecord),callback:function ($$v) {_vm.$set(item, "subrecord", $$v)},expression:"item.subrecord"}})]}},{key:"item.source",fn:function(ref){
        var item = ref.item;
return [_c('v-autocomplete',{staticClass:"mt-4 mb-2",attrs:{"items":_vm.getFilteredFields(item.subrecord, true),"label":"Field Source","item-text":"name","item-value":"_id","outlined":"","hide-details":"","dense":""},model:{value:(item.source),callback:function ($$v) {_vm.$set(item, "source", $$v)},expression:"item.source"}}),_c('v-autocomplete',{attrs:{"items":_vm.getFilteredSubrecords(item.source),"label":"Subrecords / Lists","item-text":"name","item-value":"_id","outlined":"","dense":"","disabled":!item.source},model:{value:(item.sourcesubrecord),callback:function ($$v) {_vm.$set(item, "sourcesubrecord", $$v)},expression:"item.sourcesubrecord"}})]}},{key:"item.value",fn:function(ref){
        var item = ref.item;
return [(item.field)?_c('v-select',{key:item.field,staticClass:"mt-4 mb-2",attrs:{"items":_vm.operators.filter(function (op) {
          if (
            ['field-select', 'field-autocomplete'].includes(
              _vm.fieldsObj[item.field].setup.type
            ) &&
            ['$eq', '$ne', '$nin', '$in', '$exists'].includes(op._id)
          )
            { return true; }
          if (
            ['field-currency', 'field-number', 'field-int'].includes(
              _vm.fieldsObj[item.field].setup.type
            ) &&
            ['$eq', '$gte', '$gt', '$lt', '$lte', '$ne'].includes(op._id)
          )
            { return true; }
          if (
            ['field-text', 'field-textarea'].includes(
              _vm.fieldsObj[item.field].setup.type
            ) &&
            [
              '$eq',
              '$ne',
              '$contains',
              '$notcontain',
              '$startswith',
              '$notstartwith' ].includes(op._id)
          )
            { return true; }
          if (
            ['field-date'].includes(_vm.fieldsObj[item.field].setup.type) &&
            ['$eq', '$gte', '$gt', '$lt', '$lte', '$ne'].includes(op._id)
          )
            { return true; }
          if (
            ['field-boolean'].includes(_vm.fieldsObj[item.field].setup.type) &&
            ['$eq', '$ne'].includes(op._id)
          )
            { return true; }
        }),"label":"Operator","item-text":"name","item-value":"_id","outlined":"","hide-details":"","dense":""},on:{"change":function($event){item.value = null}},model:{value:(item.operator),callback:function ($$v) {_vm.$set(item, "operator", $$v)},expression:"item.operator"}}):_vm._e(),(
        _vm.fieldsObj[item.field] &&
        _vm.fieldsObj[item.field].setup.type == 'field-date'
          ? ((item.value && item.value[0] == '$') || !item.value) &&
            item.value != '$custom'
            ? true
            : false
          : false
      )?_c('v-select',{attrs:{"items":_vm.range,"label":"Date","item-text":"name","item-value":"_id","outlined":"","dense":""},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}):_c(item.operator && _vm.fieldsObj[item.field]
          ? _vm.fieldsObj[item.field].setup.type || 'span'
          : 'span',{key:item.operator,tag:"component",staticClass:"mt-0",attrs:{"record":item,"value":_vm.filterDecode(item),"fieldSettings":_vm.fieldsObj[item.field],"formSettings":Object.assign({}, item, {show: true})},on:{"emit":function($event){return _vm.catchEmitFilter($event, item)}}})]}},{key:"item.show",fn:function(ref){
          var item = ref.item;
return [_c('v-switch',{attrs:{"label":"Show in footer","outlined":"","dense":""},model:{value:(item.show),callback:function ($$v) {_vm.$set(item, "show", $$v)},expression:"item.show"}}),_c('br'),(item.sourcesubrecord || item.subrecord)?_c('v-switch',{staticClass:"mt-0 mb-0",attrs:{"label":"Filter if empty","outlined":"","dense":""},model:{value:(item.isempty),callback:function ($$v) {_vm.$set(item, "isempty", $$v)},expression:"item.isempty"}}):_vm._e()]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"block":"","icon":"","tile":"","color":"primary"},on:{"click":function($event){return _vm.upColumn(item)}}},[_c('v-icon',[_vm._v("mdi-arrow-up-bold")])],1),_c('v-btn',{attrs:{"block":"","icon":"","tile":""},on:{"click":function($event){return _vm.delFilter(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-btn',{attrs:{"block":"","icon":"","tile":"","color":"primary"},on:{"click":function($event){return _vm.downColumn(item)}}},[_c('v-icon',[_vm._v("mdi-arrow-down-bold")])],1)]}},{key:"footer",fn:function(){return [_c('v-container',{staticStyle:{"position":"relative"}},[_c('v-row',[_c('v-col',[_c('v-fab-transition',[_c('v-btn',{staticStyle:{"margin-left":"-20px"},attrs:{"color":"primary","dark":"","absolute":"","top":"","small":"","left":"","fab":""},on:{"click":_vm.addFilter}},[_c('v-icon',[_vm._v("add")])],1)],1)],1),_c('v-switch',{attrs:{"label":"Advanced"},model:{value:(_vm.advanced),callback:function ($$v) {_vm.advanced=$$v},expression:"advanced"}})],1)],1)]},proxy:true}],null,false,4090067246)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
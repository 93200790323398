<template>
  <v-textarea
    @blur="emitChangedValue()"
    @focus="active = true"
    v-model="text"
    :label="label"
    :rules="[rules.required, rules.max]"
    :readonly="$route.params.mode == 'view'"
    outlined
    dense
    :disabled="
      (this.formSettings.disabled || this.fieldSettings.disabled == true) &&
      !this.formSettings.show
    "
    rows="6"
  ></v-textarea>
</template>

<script>
import fieldCalculated from "../../common/methods/fieldCalculated";
/* eslint-disable */
export default {
  props: ["value", "record", "fieldSettings", "formSettings"],
  data() {
    return {
      active: false,
      text: this.value,
      required:
        this.formSettings.required ||
        this.fieldSettings.setup.required ||
        false,
      min: this.formSettings.min || this.fieldSettings.min,
      max: this.formSettings.max || this.fieldSettings.max,
      field: this.formSettings.field || this.formSettings.setup.field,
      label: this.formSettings.name || this.fieldSettings.name,
      rules: {
        required: (value) => (this.required ? !!value || "Required." : true),
        allowspaces: (value) =>
          ((value || "").indexOf(" ") < 0 && this.allowspaces) ||
          "No spaces are allowed",
        max: (value) =>
          this.max > 0
            ? (value || "").length <= this.max ||
              `A maximum of ${this.max} characters is allowed`
            : true,
        min: (value) =>
          this.min > 0
            ? (value || "").length >= this.min ||
              `A minimum of ${this.min} characters is allowed`
            : true,
      },
      mode: this.$route.params.mode,
    };
  },
  created() {
    // if (this.formSettings.default) {
    //   this.fieldSettings.default = this.formSettings.default
    //     ? this.formSettings.default
    //     : this.fieldSettings.setup.default;
    // }
    // if (this.formSettings.filters) {
    //   this.formSettings.default = this.formSettings.default ? this.formSettings.default : this.formSettings.default;
    // }
    let field = fieldCalculated(this.fieldSettings, this.record);
    // if (field.default && !this.text && this.mode=="create") {
    //   this.text = field.default;
    //   this.record[field._id] = field.default;
    // }
  },
  watch: {
    record: {
      handler: async function (after, before) {
        if (!this.active) this.text = this.record[this.field];
      },
      deep: true,
    },
  },
  methods: {
    emitChangedValue() {
      this.active = false;
      this.$emit("emit", { field: this.field, value: this.text });
    },
  },
};
</script>
<style scoped>
.v-input.v-textarea >>> .v-input__slot{
  padding-bottom: 4px!important;
}
</style>
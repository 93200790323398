<template>
  <div>
    <v-subheader>Main Setup</v-subheader>
    <v-container fluid grid-list-xl>
      <v-layout row wrap>
        <v-flex md6>
          <v-text-field
            v-model="query.name"
            label="Query Name"
            outlined
            :rules="[rules.required]"
          ></v-text-field>
        </v-flex>
        <v-flex md6>
          <v-select
            v-model="query.setup.collection"
            :items="items"
            item-text="name"
            item-value="collection"
            label="Collection"
            outlined
            :rules="[rules.required]"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex md6>
          <v-text-field
            v-model="query.description"
            outlined
            label="Description"
          ></v-text-field>
        </v-flex>
        <v-flex md3>
          <v-select
            v-model="query.employee"
            :items="employees"
            item-text="name"
            item-value="_id"
            label="Owner"
            outlined
            :disabled="!query.employee && query.created"
            :rules="[rules.required]"
          ></v-select>
        </v-flex>
        <v-flex md3>
          <v-select
            v-model="query.foremployees"
            :items="employees"
            item-text="name"
            item-value="_id"
            label="Audience"
            multiple
            outlined
          ></v-select>
        </v-flex>
      </v-layout>
    </v-container>
    <v-subheader>Email Schedule</v-subheader>
    <schedule :mode="mode" :rid="rid" :query="query"></schedule>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import schedule from "./schedule";
export default {
  props: ["rid", "mode", "query"],
  data() {
    return {
      items: [
        { name: "Items", collection: "items" },
        //{ name: "Inventory Status", collection: "items_inventory" },
        //{ name: "Purchase Details", collection: "items_vendor" },
        //{ name: "Pricing", collection: "items_pricing" },
        { name: "Users", collection: "users" },
        //{ name: "Users Price Structure", collection: "users_grouppricing" },
        //{ name: "Contacts", collection: "users_contacts" },
        { name: "Transactions", collection: "transactions" },
        //{ name: "Transactions Lines", collection: "transactions_lines" },
        //{ name: "Deliveries", collection: "deliveries" },
        //{ name: "Deliveries Lines", collection: "deliveries_items" },
        //{ name: "Activities", collection: "activities" },
      ],
      rules: {
        required: (value) => !!value || "Required.",
      },
      employees: [],
    };
  },
  async created() {
    this.employees = await service("users", {
      action: "find",
      data: [
        {
          recordtype: { $in: ["employee"] },
        },
      ],
    });
    if (!this.query.created || this.query.created == "new")
      this.query.employee = this.$store.state.user._id;
  },
  components: {
    schedule,
  },
};
</script>

import Vue from "vue";
import Router from "vue-router";
import Login from "../pages/login/View";
import Dashboard from "../pages/dashboard/View";
// import Home from '../pages/home/View';
import Users from "../pages/users/View";
import Items from "../pages/items/View";
import Queries from "../pages/queries/View";
import ExchangeRates from "../pages/exchangerates/View";
import Accounting from "../pages/accounting/View";
import Lists from "../pages/lists/View";
import Forms from "../pages/forms/View";
import Fields from "../pages/fields/View";
import Maps from "../pages/maps/View";
import Addresses from "../pages/addresses/View";
import Activities from "../pages/activities/View";
import Transactions from "../pages/transactions/View";
import Classification from "../pages/classification/View";
import Emails from "../pages/emails/View";
import Imports from "../pages/imports/View";
import Deliveries from "../pages/deliveries/View";
import Tools from "../pages/tools/View";
import Sources from "../pages/sources/View";
import Locations from "../pages/locations/View";
import Countries from "../pages/countries/View";
import Promotions from "../pages/promotions/View";
import Campaigns from "../pages/campaigns/View";
import Catalogues from "../pages/catalogues/View";

Vue.use(Router);
/* eslint-disable */
export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Dashboard",
      component: Dashboard,
      meta: {
        breadcrumb: [{ name: "Dashboard" }]
      }
    },
    {
      path: "/login/:mode?",
      name: "Login",
      component: Login,
      meta: {
        allowAnonymous: true
      }
    },
    {
      path: "/exchangerates",
      name: "Exchange Rates",
      component: ExchangeRates
    },
    {
      path: "/accounting/:recordtype?/:mode?/:id?",
      name: "Accounting",
      component: Accounting
    },
    {
      path: "/lists/:recordtype?/:mode?/:id?",
      name: "Lists",
      component: Lists
    },
    {
      path: "/exchangerates/:recordtype?/:mode?/:id?",
      name: "ExchangeRates",
      component: ExchangeRates
    },
    {
      path: "/users/:recordtype?/:mode?/:id?",
      name: "Users",
      component: Users,
      meta: {
        breadcrumb: [{ name: "Users" }]
      }
    },
    {
      path: "/items/:recordtype?/:mode?/:id?",
      name: "Items",
      component: Items,
      meta: {
        breadcrumb: [{ name: "Items" }]
      }
    },
    {
      path: "/queries/:recordtype?/:mode?/:id?",
      name: "Queries",
      component: Queries,
      meta: {
        breadcrumb: [{ name: "Tools" }, { name: "Queries" }]
      }
    },
    {
      path: "/forms/:recordtype?/:mode?/:id?",
      name: "Forms",
      component: Forms,
      meta: {
        breadcrumb: [{ name: "Tools" }, { name: "Forms" }]
      }
    },
    {
      path: "/fields/:recordtype?/:mode?/:id?",
      name: "Fields",
      component: Fields,
      meta: {
        breadcrumb: [{ name: "Tools" }, { name: "Fields" }]
      }
    },
    {
      path: "/addresses/:recordtype?/:mode?/:id?",
      name: "Addresses",
      component: Addresses,
      meta: {
        breadcrumb: [{ name: "Tools" }, { name: "Addresses" }]
      }
    },
    {
      path: "/maps/:recordtype?/:mode?/:id?",
      name: "Maps",
      component: Maps,
      meta: {
        breadcrumb: [{ name: "Tools" }, { name: "Maps" }]
      }
    },
    {
      path: "/activities/:recordtype?/:mode?/:id?",
      name: "Activities",
      component: Activities,
      meta: {
        breadcrumb: [{ name: "Activities" }]
      }
    },
    {
      path: "/promotions/:recordtype?/:mode?/:id?",
      name: "Promotions",
      component: Promotions,
      meta: {
        breadcrumb: [{ name: "Promotions" }]
      }
    },
    {
      path: "/campaigns/:recordtype?/:mode?/:id?",
      name: "Campaigns",
      component: Campaigns,
      meta: {
        breadcrumb: [{ name: "Promotions" }]
      }
    },
    {
      path: "/transactions/:recordtype?/:mode?/:id?",
      name: "Transactions",
      component: Transactions,
      props: true,
      meta: {
        breadcrumb: [{ name: "Transactions" }]
      }
    },
    {
      path: "/classification/:recordtype?/:mode?/:id?",
      name: "Classification",
      component: Classification,
      meta: {
        breadcrumb: [{ name: "Classification" }]
      }
    },
    {
      path: "/emails/:recordtype?/:mode?/:id?",
      name: "Emails",
      component: Emails,
      meta: {
        breadcrumb: [{ name: "Emails" }]
      }
    },
    {
      path: "/imports/:recordtype?/:mode?/:id?",
      name: "Imports",
      component: Imports,
      meta: {
        breadcrumb: [{ name: "Imports" }]
      }
    },
    {
      path: "/deliveries/:recordtype?/:mode?/:id?",
      name: "Deliveries",
      component: Deliveries,
      meta: {
        breadcrumb: [{ name: "Deliveries" }]
      }
    },
    {
      path: "/tools/:tool",
      name: "Tools",
      component: Tools,
      meta: {
        breadcrumb: [{ name: "Tools" }]
      }
    },
    {
      path: "/sources/:recordtype?/:mode?/:id?",
      name: "Sources",
      component: Sources,
      meta: {
        breadcrumb: [{ name: "Sources" }]
      }
    },
    {
      path: "/locations/:recordtype?/:mode?/:id?",
      name: "Locations",
      component: Locations,
      meta: {
        breadcrumb: [{ name: "Locations" }]
      }
    },
    {
      path: "/countries/:recordtype?/:mode?/:id?",
      name: "Countries",
      component: Countries,
      meta: {
        breadcrumb: [{ name: "Countries" }]
      }
    },
    {
      path: "/catalogueitems/:recordtype?/:mode?/:id?",
      name: "CatalogueItems",
      component: Catalogues,
      meta: {
        breadcrumb: [{ name: "Catalogue Items" }]
      }
    }
  ]
});

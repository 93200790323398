var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.mode == 'inventorytransfer' ? _vm.headers2 : _vm.headers,"items":_vm.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","show-select":"","search":_vm.search,"single-select":_vm.singleSelect,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter,"custom-sort":_vm.sort},scopedSlots:_vm._u([{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"label":"To Order","single-line":"","type":"number"},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})]}},{key:"item.quantity2",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"label":"To Order","single-line":"","type":"number"},model:{value:(item.quantity2),callback:function ($$v) {_vm.$set(item, "quantity2", $$v)},expression:"item.quantity2"}})]}},{key:"item.item",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.item.collection) + "/" + (item.item.recordtype) + "/view/" + (item.item._id)),
            }}},[_vm._v(_vm._s(item.item.displayname || item.item.name))])]}},{key:"item.customer",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.customer.collection) + "/" + (item.customer.recordtype) + "/view/" + (item.customer._id)),
            }}},[_vm._v(_vm._s(item.customer.displayname || item.customer.name))])]}},{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('exportData',{attrs:{"headers":_vm.mode == 'inventorytransfer' ? _vm.headers2 : _vm.headers,"items":_vm.items}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.locations,"label":_vm.mode == 'inventorytransfer'
                      ? 'Transfer From'
                      : 'PO Warehouse',"item-text":"name","item-value":"_id","outlined":"","clearable":"","hide-details":"","dense":""},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}})],1),_c('v-col',[_c('v-select',{ref:"destination",attrs:{"items":_vm.locations,"label":_vm.internal == 'false'
                      ? 'Warehouse#2'
                      : _vm.mode == 'inventorytransfer'
                      ? 'Transfer To'
                      : 'From Warehouse',"item-text":"name","item-value":"_id","outlined":"","clearable":"","hide-details":"","dense":""},model:{value:(_vm.destination),callback:function ($$v) {_vm.destination=$$v},expression:"destination"}})],1),_c('v-col',[_c('v-select',{ref:"buffer",attrs:{"items":_vm.locations,"label":"Warehouse#3","item-text":"name","item-value":"_id","outlined":"","clearable":"","hide-details":"","dense":""},model:{value:(_vm.buffer),callback:function ($$v) {_vm.buffer=$$v},expression:"buffer"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.vendors,"label":"Vendor","item-text":"name","item-value":"_id","outlined":"","dense":"","clearable":"","object-return":"","hide-details":""},model:{value:(_vm.vendor),callback:function ($$v) {_vm.vendor=$$v},expression:"vendor"}})],1),(_vm.size)?_c('v-col',[_c('v-switch',{attrs:{"label":"Heavy Items"},model:{value:(_vm.custombox),callback:function ($$v) {_vm.custombox=$$v},expression:"custombox"}})],1):_vm._e(),_c('v-col',[_c('v-btn',{attrs:{"block":"","tile":"","color":"primary"},on:{"click":function($event){return _vm.getData()}}},[_vm._v("Refresh")])],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('v-icon',[_vm._v(_vm._s(_vm.show ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1)],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}]},[(!_vm.loading)?_c('toolfooter',{key:_vm.field,attrs:{"items":_vm.items,"selected":_vm.selected,"field":_vm.field,"defvendor":_vm.vendor,"stockorder":"true","recordtype":_vm.mode,"location":_vm.location,"destination":_vm.internal == 'true' ? _vm.destination : null},on:{"update:field":function($event){_vm.field=$event}}}):_vm._e()],1)])],1)]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
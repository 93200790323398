 <template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          loading-text="Loading... Please wait"
          item-key="_id"
          :search="search"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
          v-model="selected"
          @current-items="getFiltered($event)"
        >
          <template v-slot:item.date="{ item }">
            <span>{{ new Date(item.date).toISOString().substr(0, 10) }}</span>
          </template>
          <template v-slot:item.duedate="{ item }">
            <span>{{
              item.duedate
                ? new Date(item.duedate).toISOString().substr(0, 10)
                : ""
            }}</span>
          </template>
          <template v-slot:item.transaction="{ item }">
            <router-link
              v-if="item.transaction"
              :to="{
                path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
              }"
              class="link"
              >{{
                item.transaction.displayname || item.transaction.name
              }}</router-link
            >
          </template>
          <template v-slot:item.createdfrom="{ item }">
            <router-link
              v-for="po in item.createdfrom"
              :key="po._id"
              :to="{
                path: `/${po.collection}/${po.recordtype}/view/${po._id}`,
              }"
              class="link"
              >{{ po.displayname || po.name }}</br></router-link
            >
          </template>
          <template v-slot:item.customer="{ item }">
            <router-link
              v-if="item.customer"
              :to="{
                path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
              }"
              class="link"
              >{{
                item.customer.displayname || item.customer.name
              }}</router-link
            >
          </template>
          <template v-slot:item.accountonhold="{ item }">
            <v-chip label :color="item.accountonhold ? 'red' : 'none'" dark>{{
              item.accountonhold ? "Yes" : "No"
            }}</v-chip>
          </template>
          <template slot="body.append">
            <tr>
              <th v-for="(col, index) in headers" :key="index">
                {{ col.summary ? calcTotal(col.value) : "" }}
              </th>
            </tr>
          </template>
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col>
                  <exportData :headers="headers" :items="items" />
                </v-col>
                <v-col>
                  <v-select
                    :items="companies"
                    v-model="company"
                    label="Company"
                    item-text="name"
                    item-value="_id"
                    class="mt-2 mb-2"
                    outlined
                    multiple
                    dense
                  ></v-select>
                </v-col>
                <v-col>
                  <v-menu
                    v-model="menuto"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    full-width
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        label="To"
                        placeholder
                        v-model="dateto"
                        append-icon="event"
                        readonly
                        v-on="on"
                        outlined
                        dense
                        hide-details
                        class="mt-2 mb-2"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="dateto"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-btn
                    @click="getData()"
                    block
                    tile
                    color="primary"
                    class="mt-2 mb-2"
                    >Refresh</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";
export default {
  props: ["customer"],
  data() {
    return {
      loading: false,
      filteredItems: [],
      selected: [],
      headers: [
        { text: "Enova Number", value: "number", sortable: false },
        { text: "Location", value: "location" },
        { text: "Date", value: "date" },
        { text: "Terms", value: "terms" },
        { text: "Due Date", value: "duedate" },
        { text: "Overdue Days", value: "overduedays" },
        { text: "Transaction", value: "transaction", sortable: false },
        { text: "Created from", value: "createdfrom", sortable: false },
        { text: "Amount", value: "amount" },
        { text: "Currency", value: "currency" },
        { text: "Amount PLN", value: "amountpln", summary: true },
        { text: "Customer", value: "customer", sortable: false },
        { text: "Category", value: "category", sortable: false },
        { text: "Account on Hold", value: "accountonhold", sortable: false },
        //{ text: "Balance", value: "balance" },
        { text: "Amount Due", value: "amountdue", summary: true },
        { text: "Amount Due PLN", value: "amountduepln", summary: true },
        { text: "Unapplied", value: "unapplied", summary: true },
        { text: "Unapplied PLN", value: "unappliedpln", summary: true },
        { text: "Sales Rep", value: "salesrep" },
        { text: "Internal Comments", value: "memo" },
      ],
      company: [],
      companies: [],
      items: [],
      rows: [100],
      search: "",
      show: false,
      menuto: false,
      dateto: null,
    };
  },
  async created() {
    this.loading = true;
    this.companies = await service("users", {
      action: "find",
      data: [{ recordtype: "company" }],
    });
    //await this.getData();
    this.loading = false;
  },
  methods: {
    getFiltered(e) {
      this.filteredItems = e;
    },
    calcTotal(key) {
      if (this.items.length > 0 || this.filteredItems.length > 0) {
        let sum = (this.filteredItems || this.items).reduce((a, b) => {
          if (
            !isNaN(parseFloat(a[key] || a)) &&
            !isNaN(parseFloat(b[key] || b))
          ) {
            return parseFloat(a[key] || a || 0) + parseFloat(b[key] || b || 0);
          } else return parseFloat(a || 0);
        }, 0);

        return sum && !isNaN(parseInt(sum)) ? sum.toFixed(2) : "";
      }
    },
    async getData() {
      this.loading = true;
      let result = await service(
        "orders",
        {
          action: "getOpenInvoices",
          company: this.company,
          customer: this.customer,
          dateto: this.dateto,
        },
        false,
        true
      );
      this.items = result.items;
      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
  },
  components: {
    exportData: exportData,
  },
};
</script>
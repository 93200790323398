<template>
  <div>
    <queryresult v-if="!$route.params.mode" queryid="field"/>
    <creator v-else/>
  </div>
</template>

<script>
import creator from './edit-create/View';
/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
    };
  },
  components: {
    creator,
  },
};
</script>
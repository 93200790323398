var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","search":_vm.search,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.date).toISOString().substr(0, 10)))])]}},{key:"item.transaction",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.transaction.collection) + "/" + (item.transaction.recordtype) + "/view/" + (item.transaction._id)),
            }}},[_vm._v(_vm._s(item.transaction.displayname || item.transaction.name))])]}},{key:"item.customer",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.customer.collection) + "/" + (item.customer.recordtype) + "/view/" + (item.customer._id)),
            }}},[_vm._v(_vm._s(item.customer.displayname || item.customer.name))])]}},{key:"item.accountonhold",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.accountonhold ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.accountonhold ? "Yes" : "No"))])]}},{key:"item.openinvoices",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.openinvoices ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.openinvoices ? "Yes" : "No"))])]}},{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('exportData',{attrs:{"headers":_vm.headers,"items":_vm.items}})],1),_c('v-spacer'),_c('v-col',[_c('v-select',{staticClass:"mt-2 mb-2",attrs:{"items":_vm.companies,"label":"Company","item-text":"name","item-value":"_id","outlined":"","multiple":"","dense":""},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})],1),_c('v-col',[_c('v-btn',{staticClass:"mt-2 mb-2",attrs:{"block":"","tile":"","color":"primary"},on:{"click":function($event){return _vm.getData()}}},[_vm._v("Refresh")])],1)],1)],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
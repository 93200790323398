var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.used != 'campaign')?_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.query.name || "Loading..."))]),_c('v-spacer'),(
        _vm.hasAccess(
          _vm.query && _vm.query.setup? _vm.query.setup.collection: null,
          _vm.$route.params.recordtype,
          'create'
        ) &&
        !_vm.loading &&
        _vm.query &&
        !_vm.used &&
        (!['report'].includes(_vm.$route.params.recordtype) ||
          _vm.query.setup.recordtype)
      )?_c('newrecord',{attrs:{"recordtype":_vm.query.setup.recordtype || _vm.$route.params.recordtype,"collection":_vm.query.setup.collection,"query":_vm.query}}):_vm._e(),(['report'].includes(_vm.$route.params.recordtype))?_c('v-btn',{staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.edit()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("edit")])]}}],null,false,436422706)},[_c('span',[_vm._v("Edit")])])],1):_vm._e()],1):_vm._e(),_c('v-progress-linear',{staticClass:"ma-0",attrs:{"indeterminate":_vm.loading,"height":"3"}}),_c('v-card',[(!_vm.loading)?_c('datatable',{attrs:{"query":_vm.query},on:{"emit":function($event){return _vm.catchEmit($event)}}}):_vm._e()],1),_c('v-snackbar',{attrs:{"timeout":6000,"top":"","absolute":"","right":""},model:{value:(_vm.msg.show),callback:function ($$v) {_vm.$set(_vm.msg, "show", $$v)},expression:"msg.show"}},[_vm._v(_vm._s(_vm.msg.message))]),(!_vm.used)?_c('v-card-actions',{staticClass:"fill-height align-end"},[_c('v-row',{staticStyle:{"margin":"0px -19px"},attrs:{"align":"center","justify":"start"}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"6"}},[(!_vm.loading)?_c('newrecord',{attrs:{"recordtype":_vm.$route.params.recordtype,"collection":_vm.query.setup.collection}}):_vm._e()],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
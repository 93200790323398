<template>
  <v-tabs vertical dark>
    <v-tooltip bottom>
      <span>Overview</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-information-outline</v-icon>
        </v-tab>
      </template>
    </v-tooltip>

    <v-tooltip bottom>
      <span>Related Transactions</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-format-list-bulleted</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Logs</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-math-log</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tab-item>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-card flat>
              <v-row>
                <v-col>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Customer:</v-list-item-subtitle>
                        <v-list-item-title>
                          <router-link
                            v-if="record.customer"
                            :to="`/users/${record.customer.recordtype}/view/${record.customer._id}`"
                          >{{record.customer.name}}</router-link>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Document Number:</v-list-item-subtitle>
                        <v-list-item-title>{{ record.name }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Date:</v-list-item-subtitle>
                        <v-list-item-title>{{ date }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Amount:</v-list-item-subtitle>
                        <v-list-item-title>{{ amount }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Tax Amount:</v-list-item-subtitle>
                        <v-list-item-title>{{ taxamount }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Gross Amount:</v-list-item-subtitle>
                        <v-list-item-title>{{ grossamount }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>

                <v-col>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Applied:</v-list-item-subtitle>
                        <v-list-item-title>{{ applied }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Unapplied:</v-list-item-subtitle>
                        <v-list-item-title>{{ unapplied }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card>
          </v-col>
          <v-col xs12 md7></v-col>
        </v-row>
      </v-container>
    </v-tab-item>
    <v-tab-item>
      <tool-relatedtransactions :record="record" />
    </v-tab-item>
    <v-tab-item>
      <tool-logs :record="record._id" />
    </v-tab-item>
  </v-tabs>
</template>
<script>
import service from "../../../api/index";
/* eslint-disable */
export default {
  props: ["record"],
  data() {
    return {
      loading: false,
      photosrc: ""
    };
  },
  computed: {
    date() {
      return this.record.date
        ? new Date(this.record.date).toISOString().substr(0, 10)
        : "";
    },
    customer() {
      //console.log("Customer", this.record.customer)
      return this.record.customer ? this.record.customer.name : "";
    },
    amount() {
      return this.record.amount
        ? `${this.record.currency.symbol} ${parseFloat(
            this.record.amount
          ).toFixed(2)}`
        : `${this.record.currency ? this.record.currency.symbol : ""} 0.00`;
    },
    grossamount() {
      return this.record.grossamount
        ? `${this.record.currency.symbol} ${parseFloat(
            this.record.grossamount
          ).toFixed(2)}`
        : `${this.record.currency ? this.record.currency.symbol : ""} 0.00`;
    },
    taxamount() {
      return this.record.taxamount
        ? `${this.record.currency.symbol} ${parseFloat(
            this.record.taxamount
          ).toFixed(2)}`
        : `${this.record.currency ? this.record.currency.symbol : ""} 0.00`;
    },

    applied() {
      return this.record.applied
        ? `${this.record.currency.symbol} ${parseFloat(
            this.record.applied
          ).toFixed(2)}`
        : `${this.record.currency ? this.record.currency.symbol : ""} 0.00`;
    },
    unapplied() {
      return this.record.unapplied
        ? `${this.record.currency.symbol} ${parseFloat(
            this.record.unapplied
          ).toFixed(2)}`
        : `${this.record.currency ? this.record.currency.symbol : ""} 0.00`;
    }
  }
};
</script>

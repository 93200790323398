<template>
  <div>
    <v-card v-if="!loading">
      <googlemap :addresses="items"/>
      <datatable :query="query"/>
    </v-card>
    <v-snackbar v-model="msg.show" :timeout="6000" top absolute right>{{ msg.message }}</v-snackbar>
  </div>
</template>

<script>
import getRecord from '../../../common/methods/getRecord';
import getQuery from '../../../common/methods/getQuery';
import getQueryResult from '../../../common/methods/getQueryResult';
/* eslint-disable */
export default {
  components: {},
  props: ['address', 'data'],
  data() {
    return {
      updatedCpunt: 0,
      loading: true,
      query: {},
      items: [],
      msg: false,
      rid: this.$route.params.id,
    };
  },
  async created() {
    try {
      this.record = await getRecord(this, this.rid, 'maps');
      this.query = await getQuery(this, this.record.setup.query);
      this.items = await getQueryResult(this.query);
      this.loading = false;
    } catch (error) {
      this.msg = { message: `${error.message}`, show: true };
    }
  },
  methods: {},
};
</script>

<style>
</style>

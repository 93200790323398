var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-tabs',{attrs:{"dark":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_vm._l((Object.values(_vm.tabs)),function(tab,index){return _c('v-tab',{key:index},[_c('v-badge',{attrs:{"color":"primary"}},[_c('span',[_vm._v(_vm._s(tab.name))]),_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_vm._v(_vm._s(tab.items.length))])])],1)}),_vm._l((Object.values(_vm.tabs)),function(tab,index){return _c('v-tab-item',{key:index},[_c('v-data-table',{attrs:{"headers":tab.headers,"items":tab.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","search":_vm.search,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter},scopedSlots:_vm._u([{key:"item.accountonholddate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.accountonholddate ? new Date(item.accountonholddate) .toISOString() .substr(0, 10) : ""))])]}},{key:"item.accountonholddateinternal",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.accountonholddateinternal ? new Date(item.accountonholddateinternal) .toISOString() .substr(0, 10) : ""))])]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                  path: ("/" + (item.customer.collection) + "/" + (item.customer.recordtype) + "/view/" + (item.customer._id)),
                }}},[_vm._v(_vm._s(item.customer.displayname || item.customer.name))])]}},{key:"item.accountonhold",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.accountonhold ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.accountonhold ? "Yes" : "No"))])]}},{key:"item.accountonholdinternal",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.accountonholdinternal ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.accountonholdinternal ? "Yes" : "No"))])]}}],null,true)})],1)})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
/* eslint-disable */
export default async (record, settings, options) => {
  //console.log(settings, options)
  let headers = [];

  let query = settings.recordtype == "query" || settings.recordtype == "report" ? true : false;
  let columns = query ? settings.setup.columns : settings.columns;
  if (settings.recordtype != "subrecords" && settings.recordtype != "sublist" && (settings.setup.collection.indexOf('_') < 0))
    headers.push({
      text: "View | Edit",
      sortable: false,
      align: "left",
      width: "80px"
    });
  if (columns && columns.length > 0) {

    columns.forEach((column, index) => {
      let hidden = false
      if (column.iftrue && column.iftrue.length && !hidden) {
        if (column.iftrue.some(field => { return !record[field] })) hidden = true;
      }
      if (column.iffalse && column.iffalse.length && !hidden) {
        if (column.iffalse.some(field => { return record[field] })) hidden = true;
      }
      if (!column.hidden && !hidden && (column.summary ? column.summary != "$timeline" : true)) {

        headers.push({
          text: column.name,
          value: query ? column.value || ("column#" + index) : column.field,
          field: column.field || column.source,
          subfield: column.subfield,
          parentfield: column.parentfield,
          align: "left",
          sortable: (query || column.sortable) && !column.value ? true : false,
          name: column.name,
          editable: column.editable,
          colgroup: column.group,
          setifempty: column.setifempty,
          status: column.status,
          mode: column.mode,
          summary: column.summary,
          function: column.function,
          sort: column.sort,
          width: column.width,
          resultgroup: column.resultgroup
        });
      }
    });
  } else {
    for (const stage of settings.stages.slice().reverse()) {
      if (stage.type == "$project") {
        Object.entries(stage.code).forEach(([key, value]) => {
          headers.push({
            text: key,
            value: key,
            field: key,
            align: "left",
            sortable: true
          });
        });
      }
    }
  }
  return headers;
};

 <template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          loading-text="Loading... Please wait"
          item-key="_id"
          v-model="selected"
          show-select
          :search="search"
          :single-select="singleSelect"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
          :custom-sort="sort"
        >
          <template v-slot:item.date="{ item }">
            <span>{{ new Date(item.date).toISOString().substr(0, 10) }}</span>
          </template>
          <template v-slot:item.transaction="{ item }">
            <router-link
              :to="{
                path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
              }"
              class="link"
              >{{
                item.transaction.displayname || item.transaction.name
              }}</router-link
            >
          </template>
          <template v-slot:item.customer="{ item }">
            <router-link
              v-if="item.customer"
              :to="{
                path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
              }"
              class="link"
              >{{
                item.customer.displayname || item.customer.name
              }}</router-link
            >
          </template>
          <template v-slot:item.file="{ item }">
            <a v-if="item.file" :href="`${item.file.url}`" class="link">{{
              item.file.displayname || item.file.name
            }}</a>
          </template>
          <template v-slot:item.file2="{ item }">
            <a v-if="item.file2" :href="`${item.file2.url}`" class="link">{{
              item.file2.displayname || item.file2.name
            }}</a>
          </template>
          <template v-slot:item.files="{ item }">
            <a
              v-for="file in item.files"
              :key="file._id"
              :href="`${file.url}`"
              class="link"
              >{{ file.displayname || file.name }}</a
            >
          </template>
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="text-right">
                  <v-btn icon @click="show = !show">
                    <v-icon>{{
                      show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-expand-transition>
                <div v-show="show">
                  <v-divider></v-divider>
                  <v-row>
                    <v-col>
                      <v-menu
                        v-model="menufrom"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            label="Date from"
                            placeholder
                            v-model="datefrom"
                            append-icon="event"
                            readonly
                            v-on="on"
                            outlined
                            dense
                            hide-details
                            class="mt-2 mb-2"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="datefrom"></v-date-picker>
                      </v-menu>
                      <v-menu
                        v-model="menuto"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            label="Date to"
                            placeholder
                            v-model="dateto"
                            append-icon="event"
                            readonly
                            v-on="on"
                            outlined
                            dense
                            hide-details
                            class="mt-2 mb-2"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="dateto"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="companies"
                        v-model="company"
                        label="Company"
                        item-text="name"
                        item-value="_id"
                        hide-details
                        class="mt-2 mb-2"
                        outlined
                        dense
                      ></v-select>
                      <v-select
                        :items="locations"
                        v-model="location"
                        label="Location"
                        item-text="name"
                        item-value="_id"
                        outlined
                        multiple
                        dense
                        hide-details
                        class="mb-2"
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-autocomplete
                        :items="customers"
                        v-model="customer"
                        label="Customer"
                        item-text="name"
                        item-value="_id"
                        outlined
                        dense
                        hide-details
                        class="mt-2 mb-2"
                      ></v-autocomplete>
                      <v-select
                        :items="recordtypes"
                        v-model="recordtype"
                        label="Record Type"
                        item-text="name"
                        item-value="_id"
                        outlined
                        dense
                        hide-details
                        multiple
                        class="mt-2 mb-2"
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="customtypes"
                        v-model="customtype"
                        label="Enova Type"
                        item-text="name"
                        item-value="_id"
                        outlined
                        dense
                        hide-details
                        multiple
                        class="mt-2 mb-2"
                      ></v-select>
                      <v-switch v-model="advanced" label="Advanced"></v-switch>
                    </v-col>
                    <v-col>
                      <v-btn
                        @click="getData()"
                        block
                        tile
                        color="primary"
                        class="mt-2 mb-2"
                        >Refresh</v-btn
                      >
                      <v-btn
                        @click="print()"
                        block
                        tile
                        color="primary"
                        class="mt-2 mb-2"
                        >Print Selected</v-btn
                      >
                    </v-col>
                    <v-col>
                      <ul>
                        <li v-for="(file, index) of files" :key="file">
                          <a :href="file" target="_blank">PDF#{{ index }}</a>
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                </div>
              </v-expand-transition>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import sort from "../../dataTable/sort";
export default {
  data() {
    return {
      loading: false,
      headers: [
        { text: "Location", value: "location", sortable: true },
        { text: "Type", value: "recordtype", sortable: true },
        { text: "Transaction", value: "transaction", sortable: true },
        { text: "Date", value: "date", sortable: true },
        { text: "Customer", value: "customer", sortable: true },
        { text: "IE529", value: "file", sortable: true },
        { text: "IE599", value: "file2", sortable: true },
        { text: "Files", value: "files", sortable: true },
      ],
      menuto: false,
      menufrom: false,
      datefrom: null,
      dateto: null,
      items: [],
      rows: [25, 50, 100],
      company: null,
      companies: [],
      customers: [],
      customer: null,
      locations: [],
      location: [],
      customtype: [],
      customtypes: [],
      search: "",
      show: true,
      singleSelect: false,
      selected: [],
      advanced: false,
      files: [],
      recordtype: ["invoice", "creditmemo", "prepayment"],
      recordtypes: [
        { _id: "invoice", name: "Invoice" },
        { _id: "creditmemo", name: "Credit Memo" },
        { _id: "prepayment", name: "Prepayment" },
        { _id: "itemreceipt", name: "Item Receipt" },
        { _id: "inventoryadjustment", name: "Inventory Adjustment" },
      ],
    };
  },
  async created() {
    this.loading = true;
    let companies = await service("users", {
      action: "find",
      data: [{ recordtype: "company" }],
    });
    let customers = service("users", {
      action: "find",
      data: [{ recordtype: { $in: ["customer", "company"] } }],
      limit: -1,
    });
    let locations = service("accounting", {
      action: "find",
      data: [{ recordtype: "location", company: this.company }],
    });
    let customtypes = service("lists", {
      action: "find",
      data: [{ recordtype: "customtype" }],
    });
    [companies, customers, locations, customtypes] = await Promise.all([
      companies,
      customers,
      locations,
      customtypes,
    ]);
    this.companies = companies;
    this.customers = customers;
    this.locations = locations;
    this.customtypes = customtypes;
    this.loading = false;
  },
  watch: {
    async company() {
      this.locations = await service("accounting", {
        action: "find",
        data: [{ recordtype: "location", company: this.company }],
      });
    },
  },
  methods: {
    filter(value, search, item) {
      return filter(value, search, item);
    },
    sort(items, sortBy, sortDesc) {
      return sort(items, sortBy, sortDesc);
    },
    async print() {
      this.loading = true;
      if (this.selected.length > 100) {
        alert("Max 100 Invoices");
        return false;
      }
      let rids = this.selected.map((item) => {
        return item._id;
      });
      try {
        let pdf = await service("transactions", {
          action: "printTransactions",
          rids: rids,
          full: this.advanced,
        });
        var file = pdf;

        var fileURL = URL.createObjectURL(file);
        console.log(fileURL);
        this.files.push(fileURL);
        window.open(fileURL);
      } catch (error) {
        alert(error.message);
      }
      this.loading = false;
    },
    async getData() {
      this.loading = true;
      this.items = [];
      this.selected = [];
      let result = await service(
        "transactions",
        {
          action: "getTransactions",
          datefrom: this.datefrom,
          dateto: this.dateto,
          company: this.company,
          customer: this.customer,
          location: this.location,
          recordtype: this.recordtype,
          customtype: this.customtype,
        },
        false,
        true
      );

      this.items = result.items;
      this.loading = false;
    },
  },
};
</script>
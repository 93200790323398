<template>
  <v-tabs vertical dark>
    <v-tooltip bottom>
      <span>Overview</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-information-outline</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Related Transactions</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-format-list-bulleted</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Statistics</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-chart-line</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Communications</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-email-open</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Logs</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-math-log</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tab-item>
      <v-container fluid class="py-0">
        <v-row row wrap>
          <v-col>
            <v-subheader>Main</v-subheader>

            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Enova Type:</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ record.customtype ? record.customtype.name : "" }}
                      </v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Enova Number</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ record.number }}
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Display Name:</v-list-item-subtitle>

                      <v-list-item-title>
                        {{ record.displayname || record.name }}
                      </v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Sales Rep:</v-list-item-subtitle>

                      <v-list-item-title>
                        {{ record.salesrep ? record.salesrep.name : "" }}
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="
                  (record.category && record.category.length) || record.website
                "
              ></v-divider>
              <v-list-item
                v-if="
                  (record.category && record.category.length) || record.website
                "
              >
                <v-list-item-content>
                  <v-row>
                    <v-col
                      class="py-0"
                      v-if="record.category && record.category.length"
                    >
                      <v-list-item-subtitle>Category:</v-list-item-subtitle>
                      <v-list-item-title>
                        {{
                          record.category && record.category.length
                            ? record.category
                                .map((type) => type.name)
                                .join(", ")
                            : ""
                        }}
                      </v-list-item-title>
                    </v-col>
                    <v-col class="py-0" v-if="record.website">
                      <v-list-item-subtitle>Website</v-list-item-subtitle>
                      <v-list-item-title>
                        <a v-if="record.website" :href="record.website">{{
                          record.website
                        }}</a>
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Type:</v-list-item-subtitle>
                      <v-list-item-title>
                        {{
                          record.isperson
                            ? "Individual Customer"
                            : "Business Customer"
                        }}
                      </v-list-item-title>
                    </v-col>
                    <v-col class="py-0" v-if="record.taxnumber">
                      <v-list-item-subtitle>Tax Number:</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ record.taxnumber }}
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle
                        >Primary Email:</v-list-item-subtitle
                      >

                      <v-list-item-title>
                        {{ record.email }}
                      </v-list-item-title>
                    </v-col>
                    <v-col class="py-0" v-if="record.email2">
                      <v-list-item-subtitle
                        >Secondary Email:</v-list-item-subtitle
                      >

                      <v-list-item-title>
                        {{ record.email2 }}
                      </v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Phone:</v-list-item-subtitle>

                      <v-list-item-title>
                        {{ record.phone }}
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </v-list>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col>
            <v-subheader>Details</v-subheader>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>First Sales:</v-list-item-subtitle>
                      <v-list-item-title>{{
                        record.datefirstsales
                          ? new Date(record.datefirstsales)
                              .toISOString()
                              .substr(0, 10)
                          : ""
                      }}</v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Last Sales:</v-list-item-subtitle>
                      <v-list-item-title>{{
                        record.datelastsales
                          ? new Date(record.datelastsales)
                              .toISOString()
                              .substr(0, 10)
                          : ""
                      }}</v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="primary">business</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle
                        >Billing Address:
                        {{ billingaddress.name }}</v-list-item-subtitle
                      >
                      <v-list-item-title>{{
                        billingaddress.address
                      }}</v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="primary">local_shipping</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle
                        >Shipping Address:
                        {{ shippingaddress.name }}</v-list-item-subtitle
                      >
                      <v-list-item-title>{{
                        shippingaddress.address
                      }}</v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="primary">comment</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <div class="note grey--text">{{ record.note }}</div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col>
            <field-map :addresses="addresses" />
          </v-col>
        </v-row>
      </v-container>
    </v-tab-item>
    <v-tab-item>
      <tool-relatedtransactions :record="record" />
    </v-tab-item>
    <v-tab-item>
      <statistics :customer="record" mode="purchase" />
    </v-tab-item>
    <v-tab-item>
      <tool-inbox :record="record" :customer="record._id" filter="record" />
    </v-tab-item>
    <v-tab-item>
      <tool-logs :record="record._id" />
    </v-tab-item>
  </v-tabs>
</template>
<script>
/* eslint-disable */
import widget from "../../../widgets/test1";
import statistics from "../../../components/tools/customerstatistics/View";
export default {
  props: ["record"],
  data() {
    return {
      loading: false,
      photosrc: "",
    };
  },
  watch: {
    record: {
      handler: async function (after, before) {},
      deep: true,
    },
  },
  async created() {},
  computed: {
    addresses() {
      let addresses = [];
      if (this.record.billingaddress) {
        this.record.billingaddress.image =
          "https://maps.gstatic.com/mapfiles/ms2/micons/red-dot.png";
        addresses.push(this.record.billingaddress);
      }
      if (this.record.shippingaddress) {
        this.record.shippingaddress.image =
          "https://maps.gstatic.com/mapfiles/ms2/micons/blue-dot.png";
        addresses.push(this.record.shippingaddress);
      }
      return addresses;
    },
    billingaddress() {
      let address = { name: "", address: "" };
      if (this.record.billingaddress) {
        address.name =
          this.record.billingaddress.addressee ||
          this.record.billingaddress.name;
        if (this.record.billingaddress.address)
          address.address += this.record.billingaddress.address;
        if (this.record.billingaddress.zip)
          address.address += `, ${this.record.billingaddress.zip}`;
        if (this.record.billingaddress.city)
          address.address += `, ${this.record.billingaddress.city}`;
        if (this.record.billingaddress.country)
          address.address += `, ${
            this.record.billingaddress.country.name ||
            this.record.billingaddress.country
          }`;
      }
      return address;
    },
    shippingaddress() {
      let address = { name: "", address: "" };
      if (this.record.shippingaddress) {
        address.name =
          this.record.shippingaddress.addressee ||
          this.record.shippingaddress.name;
        if (this.record.shippingaddress.address)
          address.address += this.record.shippingaddress.address;
        if (this.record.shippingaddress.zip)
          address.address += `, ${this.record.shippingaddress.zip}`;
        if (this.record.shippingaddress.city)
          address.address += `, ${this.record.shippingaddress.city}`;
        if (this.record.shippingaddress.country)
          address.address += `, ${
            this.record.shippingaddress.country.name ||
            this.record.shippingaddress.country
          }`;
      }
      return address;
    },
  },
  components: {
    widget,
    statistics,
  },
};
</script>
<style scoped>
.notes {
  font-size: 14px;
}
</style>

<template>
  <v-navigation-drawer v-model="toggle" :mini-variant.sync="mini" app>
    <v-list-item class="px-2 pt-2">
      <account />
      <v-list-item-title>{{ user }}</v-list-item-title>

      <v-btn icon @click.stop="mini = !mini">
        <v-icon>chevron_left</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item link to="/">
        <v-list-item-icon>
          <v-icon>dashboard</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-group
        v-for="item in items"
        :key="item.title"
        :prepend-icon="item.icon"
        v-model="item.active"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="subItem in item.subitems"
          :key="subItem.title"
          link
          :to="subItem.link"
        >
          <v-list-item-content>
            <v-list-item-title v-text="subItem.title"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon v-text="subItem.icon"></v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
      </v-list-group>
    </v-list>
    <template v-slot:append>
      <v-divider></v-divider>
      <p  class="mt-2 text-caption" style="text-align: center">
        <small>3C ERP 2024</small>
      </p>
    </template>
  </v-navigation-drawer>
</template>

<script>
import account from "./AccountMenu";
/* eslint-disable */
export default {
  data() {
    return {
      mini: true,
      group: null,
      items: [],
    };
  },
  watch: {
    "$store.state.user": async function () {
      if (this.$store.state.user)
        this.items = this.$store.state.user.role.setup.menu;
      else this.items = [];
    },
  },
  created() {
    if (this.$store.state.user)
      this.items = this.$store.state.user.role.setup.menu;
    else this.items = [];
  },
  components: {
    account,
  },
  computed: {
    toggle() {
      return this.$store.state.navigation;
    },
    user() {
      return this.$store.getters.userName;
    },
    jobtitle() {
      return this.$store.state.user.jobtitle;
    },
  },
  methods: {
    changeRoute(routeName, selectedIndex) {
      this.selectedIndex = selectedIndex;
      return this.$router.push({ name: routeName });
    },
  },
};
</script>

<style>
.toolbar {
  font-weight: bold;
  font-size: 14px;
}

.toolbar .text {
  padding-left: 15px;
  color: white;
  text-decoration: none;
}

.item-title {
  font-size: 17px;
  font-weight: 500;
}
.item-sub-title {
  font-size: 15px;
  font-weight: 500;
}

.active {
  font-weight: bold;
}
</style>

<template>
  <v-text-field
    type="number"
    step="1"
    @blur="emitChangedValue()"
    v-model="number"
    :label="label"
    placeholder
    :rules="[rules.required, rules.max]"
    :counter="$route.params.mode != 'view' ? max : false"
    :readonly="($route.params.mode == 'view' || record.archived) && !this.formSettings.show"
    outlined
    dense
  ></v-text-field>
</template>

<script>
import fieldCalculated from "../../common/methods/fieldCalculated";
/* eslint-disable */
export default {
  props: ["value", "record", "fieldSettings", "formSettings"],
  data() {
    return {
      number: parseInt(Number(this.value)) || null,
      required:
        this.formSettings.required ||
        this.fieldSettings.setup.required ||
        false,
      min: this.formSettings.min || this.fieldSettings.min,
      max: this.formSettings.max || this.fieldSettings.max,
      field: this.formSettings.field || this.formSettings.setup.field,
      label: this.formSettings.name || this.fieldSettings.name,

      rules: {
        required: value => (this.required ? !!value || "Required." : true),
        allowspaces: value =>
          ((value || "").indexOf(" ") < 0 && this.allowspaces) ||
          "No spaces are allowed",
        max: value =>
          this.max > 0
            ? (value || "").length <= this.max ||
              `A maximum of ${this.max} characters is allowed`
            : true,
        min: value =>
          this.min > 0
            ? (value || "").length >= this.min ||
              `A minimum of ${this.min} characters is allowed`
            : true
      },
      mode: this.$route.params.mode
    };
  },
  watch: {
    record: {
      handler: async function(after, before) {
        if (this.formSettings.show) this.field = "value";
        this.number = this.record[this.field];
      },
      deep: true
    }
  },
  created() {
    //console.log(this.required,this.fieldSettings,this.formSettings)
    // if (this.formSettings.default) {
    //   this.fieldSettings.setup.default = this.formSettings.default
    //     ? this.formSettings.default
    //     : this.fieldSettings.setup.default;
    // }
    // if (this.formSettings.filters) {
    //   this.formSettings.default = this.formSettings.default
    //     ? this.formSettings.default
    //     : this.formSettings.default;
    // }
    let field = fieldCalculated(this.fieldSettings, this.record);
    // if (field.default && !this.text && this.mode=="create") {
    //   this.number = field.default;
    //   this.record[field._id] = field.default;
    // }
  },
  methods: {
    // increment() {
    //   this.number = parseInt(this.number, 10) + 1;
    //   this.emitChangedValue();
    // },
    // decrement() {
    //   this.number = parseInt(this.number, 10) - 1;
    //   this.emitChangedValue();
    // },
    emitChangedValue() {
      this.number = parseInt(this.number);
      this.$emit("emit", {
        field: this.field,
        value: Number.isInteger(this.number) ? this.number : null
      });
    }
  }
};
</script>

<template>
  <v-card tile>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Analytics Management Tools</v-toolbar-title>
    </v-toolbar>
    <v-tabs dark v-model="active">
      <v-tab :key="1">Sales Orders Pending Fulfillment</v-tab>
      <v-tab :key="2">Open Orders Status</v-tab>
      <v-tab :key="3">Inventory Back Order</v-tab>
      <v-tab :key="4">Orders Pre-Paid Waiting for Payment</v-tab>
      <v-tab :key="5">Statistics</v-tab>
      <v-tab :key="6">Reports</v-tab>
      <v-tab :key="7"> <v-icon>mdi-close-outline</v-icon></v-tab>
      <!-- <v-tab :key="8">Statistics</v-tab> -->

      <v-tab-item :key="1">
        <orderstofulfill v-if="active == 0" />
      </v-tab-item>
      <v-tab-item :key="2">
        <openordersstatus v-if="active == 1" />
      </v-tab-item>
      <v-tab-item :key="3">
        <inventorybackorder v-if="active == 2" />
      </v-tab-item>
      <v-tab-item :key="4">
        <v-tabs dark v-model="active2">
          <v-tab :key="1">Brakes PL</v-tab>
          <v-tab-item :key="1">
            <queryresult
              used="widget"
              queryid="615ff967b0749a311ca924b6"
              v-if="active == 3"
              :filters="filtersBrakesPL"
            />
          </v-tab-item>
        </v-tabs>
      </v-tab-item>
      <v-tab-item :key="5">
        <statistics v-if="active == 4" />
      </v-tab-item>
      <v-tab-item :key="6">
        <reports v-if="active == 5" />
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import orderstofulfill from "../components/tools/orderstofulfill/View";
import openordersstatus from "../components/tools/openordersstatus/View";
import inventorybackorder from "../components/tools/inventorybackorder/View";
import reports from "../components/tools/reports/View";
import statistics from "../components/tools/statistics/ViewNEW";

/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
      active: 6,
      filtersBrakesPL: { location: "62de6196fa2fc522dc692c7e" },
    };
  },

  components: {
    orderstofulfill,
    openordersstatus,
    inventorybackorder,
    reports,
    statistics,
  },
};
</script>
<template>
  <v-container v-if="record.location._id == '5e0dbaba9e33df43f0b3a495'">
    <v-row>
      <v-col>
        <v-btn @click="csv" block tile color="primary">CSV</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: ["record"],
  data() {
    return {};
  },
  methods: {
    csv() {
      let format = "data:text/csv;charset=utf-8,\uFEFF";
      let kitPrice = 0;
      let kitQty = 1;
      let data = this.record.transactionlines
        .map((line) => {
          let createdfromline = this.record.records
            ? this.record.records[line.createdfromline._id]
            : line.createdfromline;
          let item = this.record.records
            ? this.record.records[line.item._id]
            : line.item;

          if (line.item.recordtype == "kititem") {
            kitQty = this.record.transactionlines.reduce((t, v) => {
              if (v.init == line._id) t += v.componentquantity || 0;
              return t;
            }, 0);
            kitPrice = (createdfromline.price / kitQty).toFixed(2);
          } else {
            return {
              1: "1",
              2: "INV_ITEM",
              3: `"${item.costomstranslate}"`,
              4: item.hscode,
              5: line.quantity || line.quantitytopack,
              6: "EA",
              7: line.kitcomponent ? kitPrice : createdfromline.price, //parseFloat(line.createdfromline.price * line.quantity),
              8: (
                createdfromline.currency._id || createdfromline.currency
              ).toUpperCase(),
              9: item.weight,
              10: null,
              11: item.coo ? item.coo._id || item.coo : null,
              12: null,
              13: null,
              //14: "Y",
            };
          }
        })
        .filter((line) => line);
      let content = data.reduce((total, line) => {
        if (line) return (total += `${Object.values(line).join(",")}\n`);
      }, "");

      const anchor = document.createElement("a");
      anchor.href = format + encodeURIComponent(content);

      anchor.download = `DHL CSV - ${this.record.name}`;
      anchor.setAttribute("style", "visibility:hidden");

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    },
  },
};
</script>
<template>
  <div>
    <v-checkbox
      v-if="checkbox"
      v-model="enabled"
      hide-details
      style="float: left"
    ></v-checkbox>
    <v-text-field
      type="number"
      :disabled="checkbox ? !enabled : false"
      @blur="emitChangedValue()"
      v-model="number"
      :label="label"
      placeholder
      :rules="[rules.required, rules.max]"
      :counter="$route.params.mode != 'view' ? max : false"
      :readonly="$route.params.mode == 'view' || record.archived"
      outlined
      dense
      suffix="%"
    ></v-text-field>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: ["value", "record", "fieldSettings", "formSettings"],
  data() {
    return {
      number:
        this.value || this.value == 0
          ? Number(this.value * 100).toFixed(
              this.fieldSettings.setup.precision || 2
            )
          : null,
      required:
        this.formSettings.required ||
        this.fieldSettings.setup.required ||
        false,
      min: this.formSettings.min || this.fieldSettings.min,
      max: this.formSettings.max || this.fieldSettings.max,
      field: this.formSettings.field || this.formSettings.setup.field,
      label: this.formSettings.name || this.fieldSettings.name,
      checkbox: this.formSettings.checkbox || this.fieldSettings.setup.checkbox,
      enabled: false,
      rules: {
        required: (value) => (this.required ? !!value || "Required." : true),
        allowspaces: (value) =>
          ((value || "").indexOf(" ") < 0 && this.allowspaces) ||
          "No spaces are allowed",
        max: (value) =>
          this.max > 0
            ? (value || "").length <= this.max ||
              `A maximum of ${this.max} characters is allowed`
            : true,
        min: (value) =>
          this.min > 0
            ? (value || "").length >= this.min ||
              `A minimum of ${this.min} characters is allowed`
            : true,
      },
    };
  },
  watch: {
    record: {
      handler: async function (after, before) {
        if (this.formSettings.show) this.field = "value";
        this.number = Number(this.record[this.field] * 100).toFixed(
          this.fieldSettings.setup.precision || 2
        );
      },
      deep: true,
    },
    enabled: function (val) {
      if (!val) this.number = null;
    },
  },
  methods: {
    emitChangedValue() {
      let percent = Number(parseFloat(this.number / 100));
      if (this.field == "discountrate") percent = Math.abs(percent) * -1;
      this.$emit("emit", {
        field: this.field,
        value: !isNaN(percent) ? percent : null,
      });
    },
  },
};
</script>

 <template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          loading-text="Loading... Please wait"
          item-key="bin_id"
          :search="search"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
          hide-default-footer
        >
          <template v-slot:item.item="{ item }">
            <router-link
              :to="{
                path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`,
              }"
              class="link"
              >{{ item.item.displayname || item.item.name }}</router-link
            >
          </template>
          <template v-slot:item.location="{ item }">
            <v-select
              dense
              :items="warehouses"
              v-model="item.location"
              item-text="name"
              item-value="_id"
              label="Warehouse"
              single-line
              outlined
              hide-details
            ></v-select>
          </template>
          <template v-slot:item.bin="{ item }">
            <v-text-field
              dense
              v-model="item.bin"
              label="Bin"
              single-line
              hide-details
              clearable
              outlined
              @change="update(item)"
            ></v-text-field>
          </template>
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <!-- <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col> -->
                <v-col>
                  <v-combobox
                    dense
                    v-model="item"
                    :items="filteritems"
                    label="Item"
                    outlined
                    return-object
                    item-text="name"
                    item-value="_id"
                    @keyup.enter="searchRecords"
                    hint="type item name and put enter to find"
                  ></v-combobox>
                </v-col>
                <!-- <v-col>
                  <v-text-field
                    dense
                    v-model="bin"
                    label="Bin"
                    outlined
                    return-object
                  ></v-text-field>
                </v-col> -->
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
              </v-row>
            </v-container>
          </template>
          <template v-slot:footer>
            <div style="position: relative; text-align: right; height: 50px">
              <v-fab-transition>
                <v-btn
                  small
                  absolute
                  top
                  left
                  fab
                  style="top: 5px; left: 15px"
                  @click="addRow()"
                >
                  <v-icon>add</v-icon>
                </v-btn>
              </v-fab-transition>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
export default {
  data() {
    return {
      loading: false,
      headers: [
        { text: "Location", value: "location", sortable: false },
        { text: "Item", value: "item", sortable: false },
        { text: "Bin", value: "bin", sortable: false },
        { text: "Priority", value: "priority", sortable: false },

        { text: "Qty. on Hand", value: "quantityonhand", sortable: false },
      ],
      items: [],
      filteritems: [],
      item: null,
      rows: [100],
      singleSelect: false,
      selected: [],
      search: "",
      show: false,
      autorecalc: true,
      bin: null,
      warehouses: [],
    };
  },
  async created() {
    this.loading = true;
    this.warehouses = await service(
      "accounting",
      {
        action: "find",
        data: [
          {
            recordtype: { $in: ["location"] },
            _id: {
              $in: ["61e14c6ae4a35a668517a8fe", "5e0dbaba9e33df43f0b3a495"],
            },
          },
        ],
      },
      false,
      true
    );
    //await this.getData();
    this.loading = false;
  },
  watch: {
    async bin(value) {
      if (value) await this.getData();
    },
    async item(value) {
      if (value._id) await this.getData();
    },
  },
  methods: {
    addRow() {
      if (this.item && this.item._id) {
        let priority = this.items.length + 1;
        this.items.push({
          item: this.item,
          location: this.$store.state.user.location,
          bin: "",
          priority: priority,
        });
      }
    },
    async update(item) {
      let result = await service(
        "items",
        {
          action: "getBins",
          update: true,
          item: item.item._id,
          bin: item.bin,
          location: item.location,
          priority: item.priority,
          bin_id: item.bin_id,
        },
        false,
        true
      );
      if (result && result.bin_id && !item.bin_id) item.bin_id = result.bin_id;
      else if (!bin && result) item.bin_id = result.bin_id;
    },
    async searchRecords() {
      this.loading = true;
      await this.getItems();
      this.loading = false;
    },
    async getItems() {
      if (this.item && !this.item._id) {
        this.filteritems = await service(
          "items",
          {
            action: "find",
            data: [
              {
                name: { $regex: `.*${this.item}.*`, $options: "i" },
                recordtype: { $in: ["inventoryitem"] },
              },
            ],
          },
          false,
          true
        );
      }
    },
    async getData() {
      this.loading = true;
      let result = await service(
        "items",
        {
          action: "getBins",
          item: this.item ? this.item._id : null,
          bin: this.bin,
          location: this.location,
        },
        false,
        true
      );
      this.selected = [];
      this.items = result.items;
      this.items.forEach((item) => {
        if (item.quantitycommitted > 0) this.selected.push(item);
      });
      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    async action() {
      let reallcateArray = [];
      let location = null;
      let item = null;
      let currentQty = 0;
      let newQty = 0;
      let error = false;
      this.items.forEach((select) => {
        if (select.newquantitycommitted != select.quantitycommitted) {
          select.newquantitycommitted = parseInt(
            select.newquantitycommitted || 0
          );
          newQty += parseInt(select.newquantitycommitted) || 0;
          currentQty += select.quantitycommitted || 0;
          if (
            location &&
            (select.location._id || select.location) != location
          ) {
            alert("You cannot select more locations");
            error = true;
          }
          location = select.location
            ? select.location._id || select.location
            : null;
          if (item && select.item._id != item) {
            alert("You cannot select more item");
            error = true;
          }
          item = select.item._id;
          reallcateArray.push(select);
        }
      });
      if (newQty > currentQty) {
        alert(
          "New Sum of Committed Quantity cannot be greater than " + currentQty
        );
        error = true;
      }
      if (!error) {
        await service("transactions", {
          action: "reallocateItems",
          data: reallcateArray,
        }).catch((error) => {
          alert(error);
        });
        this.loading = true;
        await this.getData();
        this.loading = false;
      }
    },
  },
};
</script>
<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col>
        <v-toolbar flat dark color="primary">
          <v-btn outlined class="mr-4" @click="setToday">Today</v-btn>
          <v-btn fab text small @click="prev">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab text small @click="next">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on }">
              <v-btn outlined v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-select
                :items="locations"
                v-model="location"
                label="Location"
                item-text="name"
                item-value="_id"
                outlined
                dense
                hide-details
                multiple
                clearable
                @change="getData()"
              ></v-select>
              <v-select
                :items="etatypes"
                v-model="etatype"
                label="ETA Type"
                item-text="name"
                item-value="_id"
                outlined
                dense
                hide-details
                class="mt-2 mb-2"
                @change="getData()"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                :items="vendors"
                v-model="vendor"
                label="Vendor"
                item-text="name"
                item-value="_id"
                outlined
                dense
                hide-details
                multiple
                clearable
              ></v-select>
              <v-select
                :items="deliverymethods"
                v-model="deliverymethod"
                label="Delivery Method"
                item-text="name"
                item-value="_id"
                outlined
                dense
                hide-details
                multiple
                clearable
                class="mt-2 mb-2"
                @change="getData()"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                :items="statuses"
                v-model="status"
                label="Status"
                item-text="name"
                item-value="_id"
                outlined
                dense
                hide-details
                multiple
                clearable
                @change="getData()"
              ></v-select>
            </v-col>
            <v-col>
              <v-btn @click="getData()" block tile color="primary"
                >Refresh</v-btn
              >
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-container>
        <v-sheet height="800">
          <v-calendar
            :weekdays="weekday"
            :type="type"
            v-model="focus"
            ref="calendar"
            :event-color="getEventColor"
            @click:event="showEvent"
            @change="updateRange"
            @click:more="viewDay"
            @click:date="viewDay"
            :events="
              items.filter(
                (item) => vendor.includes(item.customer._id) || !vendor.length
              )
            "
          >
            <template v-slot:day="{ date }">
              <v-btn
                small
                icon
                fab
                right
                top
                absolute
                style="top: 4px; right: 4px"
                @click="newDelivery(date)"
                ><v-icon small>mdi-plus</v-icon></v-btn
              >
            </template>
            <template v-slot:event="{ event }">
              <v-btn
                x-small
                tile
                text
                block
                class="ml-5"
                style="justify-content: left"
                :style="
                  event.quantityreceived
                    ? 'background-color: #ffffffb3; '
                    : 'color:white'
                "
              >
                <v-icon
                  v-if="event.icon"
                  color="white"
                  style="position: absolute; left: -24px"
                  left
                  >{{ event.icon }}</v-icon
                >
                {{ event.name }}
              </v-btn>
            </template>
          </v-calendar>
          <v-dialog
            v-model="selectedOpen"
            :activator="selectedElement"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <v-card>
              <v-toolbar color="primary" dark>
                <v-btn
                  icon
                  :to="`/deliveries/delivery/view/${selectedEvent._id}`"
                  target="_blank"
                >
                  <v-icon>edit</v-icon>
                </v-btn>

                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                <v-btn
                  @click="receive(selectedEvent)"
                  icon
                  :disabled="
                    !!selectedEvent.quantityscanned ||
                    selectedEvent.exportcustomsclearance
                  "
                >
                  <v-icon>mdi-truck-check</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="selectedOpen = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-title primary-title>
                <v-row>
                  <v-col>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <!-- <v-list-item-subtitle>ETA:</v-list-item-subtitle> -->
                          <field-date
                            @emit="catchEmit($event, selectedEvent)"
                            v-model="selectedEvent.eta"
                            :record="selectedEvent"
                            hide-details
                            :fieldSettings="{
                              name: 'ETA Customer',
                              field: 'eta',
                              setup: { name: 'ETA Customer', field: 'eta' },
                            }"
                            :formSettings="{
                              name: 'ETA Customer',
                              field: 'eta',
                              setup: { name: 'ETA Customer', field: 'eta' },
                            }"
                          />
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <!-- <v-list-item-subtitle>ETA:</v-list-item-subtitle> -->
                          <field-date
                            @emit="catchEmit($event, selectedEvent)"
                            v-model="selectedEvent.etd"
                            :record="selectedEvent"
                            hide-details
                            :fieldSettings="{
                              name: 'ETA Warehouse',
                              field: 'etd',
                              setup: { name: 'ETA Warehouse', field: 'etd' },
                            }"
                            :formSettings="{
                              name: 'ETA Warehouse',
                              field: 'etd',
                              setup: { name: 'ETA Warehouse', field: 'etd' },
                            }"
                          />
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <!-- <v-list-item-subtitle>ETA:</v-list-item-subtitle> -->
                          <field-date
                            @emit="catchEmit($event, selectedEvent)"
                            v-model="selectedEvent.rta"
                            :record="selectedEvent"
                            hide-details
                            :fieldSettings="{
                              name: 'RTA',
                              field: 'rta',
                              setup: { name: 'RTA', field: 'rta' },
                            }"
                            :formSettings="{
                              name: 'RTA',
                              field: 'rta',
                              setup: { name: 'RTA', field: 'rta' },
                            }"
                          />
                        </v-list-item-content>
                      </v-list-item>

                      <!-- <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle>Memo:</v-list-item-subtitle>
                          <v-list-item-title>{{
                            selectedEvent.memo
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item> -->
                    </v-list>
                  </v-col>
                  <v-col>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <!-- <v-list-item-subtitle>Color:</v-list-item-subtitle> -->
                          <field-color
                            @emit="catchEmit($event, selectedEvent)"
                            v-model="selectedEvent.color"
                            :record="selectedEvent"
                            :fieldSettings="{
                              name: 'color',
                              field: 'color',
                              setup: { name: 'color', field: 'color' },
                            }"
                            :formSettings="{
                              name: 'color',
                              field: 'color',
                              setup: { name: 'color', field: 'color' },
                            }"
                          />
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <field-textarea
                            @emit="catchEmit($event, selectedEvent)"
                            v-model="selectedEvent.memo"
                            :record="selectedEvent"
                            :fieldSettings="{
                              name: 'Memo',
                              field: 'memo',
                              setup: { name: 'Memo', field: 'memo' },
                            }"
                            :formSettings="{
                              name: 'Memo',
                              field: 'memo',
                              setup: { name: 'Memo', field: 'memo' },
                            }"
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle
                            >Requested Ship Date:</v-list-item-subtitle
                          >
                          <v-list-item-title>{{
                            selectedEvent.planneddateofshipment
                              ? new Date(selectedEvent.planneddateofshipment)
                                  .toISOString()
                                  .substr(0, 10)
                              : ""
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle
                            >Real Ship Date:</v-list-item-subtitle
                          >
                          <v-list-item-title>{{
                            selectedEvent.dateofshipment
                              ? new Date(selectedEvent.dateofshipment)
                                  .toISOString()
                                  .substr(0, 10)
                              : ""
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle
                            >Receipt Items Date:</v-list-item-subtitle
                          >
                          <v-list-item-title>{{
                            selectedEvent.receiptdate
                              ? new Date(selectedEvent.receiptdate)
                                  .toISOString()
                                  .substr(0, 10)
                              : ""
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle
                            >Sum Of Packages:</v-list-item-subtitle
                          >
                          <v-list-item-title>{{
                            selectedEvent.boxes || 0
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle
                            >Sum Of Palettes:</v-list-item-subtitle
                          >
                          <v-list-item-title>{{
                            selectedEvent.palletes || 0
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle
                            >Sum Of Metal Rack:</v-list-item-subtitle
                          >
                          <v-list-item-title>{{
                            selectedEvent.metalrack || 0
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle>Qty.:</v-list-item-subtitle>
                          <v-list-item-title>{{
                            selectedEvent.quantity
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle
                            >Qty. scanned:</v-list-item-subtitle
                          >
                          <v-list-item-title>{{
                            selectedEvent.quantityscanned
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle
                            >Qty. Received:</v-list-item-subtitle
                          >
                          <v-list-item-title>{{
                            selectedEvent.quantityreceived
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle>Tracking:</v-list-item-subtitle>
                          <v-list-item-title>{{
                            selectedEvent.trackingnumber
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle>UPS:</v-list-item-subtitle>
                          <v-list-item-title
                            ><a
                              href="https://www.ups.com/pl/en/services/tracking.page"
                              target="_blank"
                              >https://www.ups.com/pl/en/services/tracking.page</a
                            ></v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle>TNT:</v-list-item-subtitle>
                          <v-list-item-title
                            ><a
                              href="https://www.tnt.com/express/en_us/site/tracking.html"
                              target="_blank"
                              >https://www.tnt.com/express/en_us/site/tracking.html</a
                            ></v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle
                            >EMS/Pocztex:</v-list-item-subtitle
                          >
                          <v-list-item-title
                            ><a
                              href="https://www.pocztex.pl/sledzenie-przesylek/"
                              target="_blank"
                              >https://www.pocztex.pl/sledzenie-przesylek/</a
                            ></v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle
                            >Kuehne-Nagel:</v-list-item-subtitle
                          >
                          <v-list-item-title
                            ><a
                              href="https://onlineservices.kuehne-nagel.com/public-tracking/"
                              target="_blank"
                              >https://onlineservices.kuehne-nagel.com/public-tracking/</a
                            ></v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle>Raben:</v-list-item-subtitle>
                          <v-list-item-title
                            ><a
                              href="https://polska.raben-group.com/en/customer-zone-of-raben-logistics-polska"
                              target="_blank"
                              >https://polska.raben-group.com/en/customer-zone-of-raben-logistics-polska</a
                            ></v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-tabs dark v-model="active">
                <v-tab :key="1">Pending Sales Orders (per ETA)</v-tab>
                <v-tab :key="2">Items in Delivery</v-tab>

                <v-tab-item :key="1">
                  <v-container fluid>
                    <v-row>
                      <v-col>
                        <v-switch
                          v-model="allbo"
                          label="Show All BO"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-data-table
                    :headers="headers2"
                    :items="
                      itemsOpenSalesOrders.filter((item) =>
                        !allbo ? item.assigned : true
                      )
                    "
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    item-key="_id"
                    :search="search"
                    :footer-props="{ itemsPerPageOptions: rows2 }"
                    :custom-filter="filter"
                  >
                    <template v-slot:item.date="{ item }">
                      <span>{{
                        new Date(item.date).toISOString().substr(0, 10)
                      }}</span>
                    </template>
                    <template v-slot:item.eta="{ item }">
                      <span :class="!item.assigned ? 'yellow' : ''">{{
                        item.eta
                          ? new Date(item.eta).toISOString().substr(0, 10)
                          : ""
                      }}</span>
                    </template>
                    <template v-slot:item.transaction="{ item }">
                      <router-link
                        :to="{
                          path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                        }"
                        class="link"
                        >{{
                          item.transaction.displayname || item.transaction.name
                        }}</router-link
                      >
                    </template>
                    <template v-slot:item.correlatedtransaction="{ item }">
                      <router-link
                        v-if="item.correlatedtransaction"
                        :to="{
                          path: `/${item.correlatedtransaction.collection}/${item.correlatedtransaction.recordtype}/view/${item.correlatedtransaction._id}`,
                        }"
                        class="link"
                        >{{
                          item.correlatedtransaction.displayname ||
                          item.correlatedtransaction.name
                        }}</router-link
                      >
                    </template>
                    <template v-slot:item.customer="{ item }">
                      <router-link
                        :to="{
                          path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                        }"
                        class="link"
                        >{{
                          item.customer.displayname || item.customer.name
                        }}</router-link
                      >
                    </template>
                    <template v-slot:item.item="{ item }">
                      <router-link
                        :to="{
                          path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`,
                        }"
                        class="link"
                        >{{
                          item.item.displayname || item.item.name
                        }}</router-link
                      >
                    </template>
                  </v-data-table>
                  <exportData
                    :headers="headers2"
                    :items="itemsOpenSalesOrders"
                  />
                </v-tab-item>
                <v-tab-item :key="2">
                  <v-data-table
                    :headers="headers3"
                    :items="itemsInDelivery"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    item-key="_id"
                    :search="search"
                    :footer-props="{ itemsPerPageOptions: rows }"
                    :custom-filter="filter"
                    :item-class="rowClass"
                  >
                    <template v-slot:item.item="{ item }">
                      <router-link
                        :to="{
                          path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`,
                        }"
                        class="link"
                        >{{
                          item.item.displayname || item.item.name
                        }}</router-link
                      >
                    </template>
                  </v-data-table>
                  <exportData :headers="headers3" :items="itemsInDelivery" />
                </v-tab-item>
              </v-tabs>
            </v-card>
          </v-dialog>
        </v-sheet>
      </v-col>
    </v-row>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-text-field
            dense
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
            outlined
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="text-right">
          <v-btn icon @click="show = !show">
            <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-expand-transition>
        <div v-show="show">
          <v-row>
            <v-col>
              <v-data-table
                :headers="headers"
                :items="
                  items.filter(
                    (item) =>
                      vendor.includes(item.customer._id) || !vendor.length
                  )
                "
                :loading="loading"
                loading-text="Loading... Please wait"
                item-key="_id"
                :search="search"
                :footer-props="{ itemsPerPageOptions: rows }"
                :custom-filter="filter"
              >
                <template v-slot:item.receive="{ item }">
                  <v-btn
                    @click="receive(item)"
                    block
                    tile
                    color="primary"
                    class="mt-2 mb-2"
                    :disabled="
                      !!item.quantityscanned || item.exportcustomsclearance
                    "
                    >Delivery Check</v-btn
                  >
                </template>
                <template v-slot:item.exportcustomsclearance="{ item }">
                  <v-chip
                    label
                    :color="item.exportcustomsclearance ? 'red' : 'none'"
                    dark
                    >{{
                      item.exportcustomsclearance ? "Waiting" : "Done"
                    }}</v-chip
                  >
                </template>
                <template v-slot:item.date="{ item }">
                  <span>{{
                    new Date(item.date).toISOString().substr(0, 10)
                  }}</span>
                </template>
                <template v-slot:item.receiptdate="{ item }">
                  <span>{{
                    item.receiptdate
                      ? new Date(item.receiptdate).toISOString().substr(0, 10)
                      : ""
                  }}</span>
                </template>
                <template v-slot:item.start="{ item }">
                  <span>{{
                    new Date(item.start).toISOString().substr(0, 10)
                  }}</span>
                </template>
                <template v-slot:item.transaction="{ item }">
                  <router-link
                    :to="{
                      path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                    }"
                    class="link"
                    >{{
                      item.transaction.displayname || item.transaction.name
                    }}</router-link
                  >
                </template>
                <template v-slot:item.customer="{ item }">
                  <router-link
                    :to="{
                      path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                    }"
                    class="link"
                    >{{
                      item.customer.displayname || item.customer.name
                    }}</router-link
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </v-expand-transition>
    </v-container>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";
export default {
  data: () => ({
    loading: false,
    allbo: false,
    etatype: "eta",
    etatypes: [
      { _id: "eta", name: "ETA Customer" },
      { _id: "etd", name: "ETA Warehouse" },
    ],
    active: 0,
    weekday: [1, 2, 3, 4, 5, 6, 0],
    headers: [
      { text: "Location", value: "location", sortable: false },
      {
        text: "Customs Clearance",
        value: "exportcustomsclearance",
        sortable: false,
      },
      { text: "Delivery", value: "transaction", sortable: false },
      { text: "Vendor", value: "customer", sortable: false },
      { text: "Date", value: "date", sortable: false },
      { text: "ETA", value: "start", sortable: false },
      { text: "Memo", value: "memo", sortable: false },
      { text: "Qty.", value: "quantity", sortable: false },
      { text: "Scanned", value: "quantityscanned", sortable: false },
      { text: "Received", value: "quantityreceived", sortable: false },
      { text: "Receipt Items Date", value: "receiptdate", sortable: false },
      { text: "Action", value: "receive", sortable: false },
    ],
    headers2: [
      { text: "Location", value: "location", sortable: false },
      { text: "Sales Order", value: "transaction", sortable: false },
      { text: "Date", value: "date", sortable: false },
      { text: "Customer", value: "customer", sortable: false },
      { text: "Shipping Address", value: "shipaddress", sortable: false },
      { text: "Item", value: "item", sortable: false },
      { text: "Qty.", value: "quantity", sortable: false },
      { text: "BO", value: "quantitybackorder", sortable: false },
      { text: "Qty. Committed", value: "quantitycommitted", sortable: false },
      { text: "ETA", value: "eta", sortable: false },
      { text: "ETA Memo", value: "etamemo", sortable: false },
      { text: "Service", value: "additionalservice", sortable: false },
      { text: "Terms", value: "terms", sortable: false },
      { text: "Sales Rep", value: "salesrep", sortable: false },
      {
        text: "Correlated SO",
        value: "correlatedtransaction",
        sortable: false,
      },
    ],
    headers3: [
      { text: "Item", value: "item", sortable: false },
      { text: "Qty. in Transit", value: "quantity", sortable: false },
      { text: "BO", value: "quantitybackorder", sortable: false },
      { text: "Qty. Assigned", value: "assigned", sortable: false },
    ],
    itemsOpenSalesOrders: [],
    itemsInDelivery: [],
    type: "month",
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    vendors: [],
    vendor: [],
    locations: [],
    location: [],
    deliverymethods: [],
    deliverymethod: [],
    statuses: [
      { name: "Received", _id: "received" },
      { name: "Scanned Only", _id: "scanned" },
      { name: "Pending", _id: "pending" },
      { name: "Awaiting", _id: "awaiting" },
      { name: "Hidden", _id: "hidden" },
    ],
    status: [],
    start: null,
    end: null,
    today: null,
    focus: null,
    items: [],
    rows: [-1],
    rows2: [-1],
    search: "",
    show: false,
    typeToLabel: {
      month: "Month",
      day: "Day",
    },
    locationsFilter: [],
  }),
  async created() {
    this.loading = true;
    if (this.$store.state.user.location)
      this.locationsFilter.push(this.$store.state.user.location);
    if (
      this.$store.state.user.forlocations &&
      this.$store.state.user.forlocations.length
    )
      this.locationsFilter.push(...this.$store.state.user.forlocations);
    this.locations = await service("accounting", {
      action: "find",
      data: [
        {
          recordtype: "location",
          hidden: { $ne: true },
          inactive: { $ne: true },
          _id: this.locationsFilter.length
            ? { $in: this.locationsFilter }
            : { $exists: true },
        },
      ],
    });
    this.location = this.locationsFilter;
    this.deliverymethods = await service("lists", {
      action: "find",
      data: [
        {
          recordtype: "deliverymethod",
          hidden: { $ne: true },
          inactive: { $ne: true },
        },
      ],
    });
    await this.getData();
    this.loading = false;
  },

  mounted() {
    this.today = this.today || new Date().toISOString().substr(0, 10);
    this.focus = this.focus || new Date().toISOString().substr(0, 10);
    // console.log(this.today, this.focus);
    this.$refs.calendar.checkChange();
  },
  methods: {
    rowClass(item) {
      if (
        item.quantity > item.assigned &&
        item.quantitybackorder > item.assigned
      )
        return "yellow";
      if (item.quantity < item.assigned) return "red";
    },
    async receive(item) {
      this.$router.push({
        name: "Deliveries",
        params: {
          recordtype: "deliverycheck",
          mode: "create",
          delivery: item._id,
        },
      });
    },
    async getData() {
      this.loading = true;
      let result = await service(
        "orders",
        {
          action: "getDeliveries",
          location: this.location,
          deliverymethod: this.deliverymethod,
          status: this.status,
          etatype: this.etatype,
        },
        false,
        true
      );
      result.items
        .filter((item) => item.date)
        .forEach((item) => {
          this.vendors.push(item.customer);
          item.date = new Date(item.date).toISOString().substr(0, 10);
          item.start = new Date(
            item[this.etatype] || item["eta"] || item["etd"] || item["date"]
          )
            .toISOString()
            .substr(0, 10);
          item.name = item.transaction.name;
          // item.name = `${item.quantityreceived ? "✔	" : ""} ${
          //   item.transaction.name
          // }`;
          // item.name = `${item.exportcustomsclearance ? "🗎	" : ""} ${item.name}`;
          if (item.exportcustomsclearance)
            item.icon = "mdi-clipboard-text-clock-outline";
          if (item.quantityreceived) item.icon = "mdi-checkbox-outline";

          item.color = item.color || "#464d69";
          //console.log(item, item.name);
        });
      this.items = result.items;
      this.loading = false;
    },
    async catchEmit(e, item) {
      try {
        let result = await service("orders", {
          action: "setField",
          collection: item.collection,
          rid: item._id,
          field: e.field,
          value: e.value,
        });
        this.items.forEach((event) => {
          if (event._id == item._id) {
            event[e.field] = e.value;
            if (e.field == this.etatype)
              event["start"] = new Date(event[e.field])
                .toISOString()
                .substr(0, 10);
          }
        });
      } catch (error) {
        alert(error.message);
      }
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    getEventColor(event) {
      return event.color;
    },
    async showEvent({ nativeEvent, event }) {
      this.loading = true;
      try {
        const open = async () => {
          this.selectedEvent = event;
          this.selectedElement = nativeEvent.target;

          let items = service("deliveries", {
            action: "getPendingSalesOrders",
            delivery: event._id,
          });
          let delivery = service("deliveries", {
            action: "loadRecord",
            rid: event._id,
            collection: event.collection,
          });
          [items, delivery] = await Promise.all([items, delivery]);
          this.itemsOpenSalesOrders = items.items;
          this.itemsInDelivery = delivery.deliveryrecords.reduce(
            (total, del) => {
              let exist = total.find(
                (e) => del.item && e.item && e.item._id == del.item._id
              );
              if (exist) exist.quantity += del.quantity;
              else total.push(del);
              return total;
            },
            []
          );
          this.itemsInDelivery.forEach((del) => {
            let boDetails = this.itemsOpenSalesOrders.reduce(
              (total, so) => {
                if (so.item && del.item && so.item._id == del.item._id) {
                  total.bo += so.quantitybackorder;

                  if (
                    so.eta &&
                    del.eta &&
                    new Date(so.eta).toISOString().substr(0, 10) ==
                      new Date(del.eta).toISOString().substr(0, 10)
                  ) {
                    total.assigned += so.quantitybackorder;
                    so.assigned = true;
                  }
                }
                return total;
              },
              { bo: 0, assigned: 0 }
            );
            del.quantitybackorder = boDetails.bo;
            del.assigned = boDetails.assigned;
          });
          this.loading = false;
          setTimeout(() => (this.selectedOpen = true), 10);
        };

        if (this.selectedOpen) {
          this.selectedOpen = false;
          setTimeout(open, 10);
        } else {
          open();
        }
        nativeEvent.stopPropagation();
      } catch (error) {
        this.loading = false;
        alert(error.message);
      }
    },
    updateRange({ start, end }) {
      // You could load events from an outside source (like database) now that we have the start and end dates on the calendar
      //console.log("Update");
      this.start = start;
      this.end = end;
    },
    nth(d) {
      return d > 3 && d < 21
        ? "th"
        : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
    },
    newDelivery(date) {
      this.$router.push({
        name: "Deliveries",
        params: {
          recordtype: "delivery",
          mode: "create",
          date: date,
          status: "pendingapproval",
        },
      });
    },
  },
  computed: {
    title() {
      const { start, end } = this;

      if (!start || !end) {
        return "";
      }
      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? "" : endYear;

      const startDay = start.day + this.nth(start.day);
      const endDay = end.day + this.nth(end.day);

      switch (this.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
        case "4day":
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
        case "day":
          return `${startMonth} ${startDay} ${startYear}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },
  },
  components: {
    exportData,
  },
};
</script>
<style scoped>
.yellow {
  background-color: rgb(255, 255, 144);
}
.red {
  background-color: rgb(255, 106, 106);
}
</style>
<style>
.v-calendar .v-event {
  border-radius: 0px !important;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading-text":"Loading... Please wait","loading":_vm.loading,"item-key":"_id","show-select":"","search":_vm.search,"single-select":_vm.singleSelect,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.date).toISOString().substr(0, 10)))])]}},{key:"item.transaction",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.transaction.collection) + "/" + (item.transaction.recordtype) + "/view/" + (item.transaction._id)),
            }}},[_vm._v(_vm._s(item.transaction.displayname || item.transaction.name))])]}},{key:"item.customer",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.customer.collection) + "/" + (item.customer.recordtype) + "/view/" + (item.customer._id)),
            }}},[_vm._v(_vm._s(item.customer.displayname || item.customer.name))])]}},{key:"item.delivery",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.delivery.collection) + "/" + (item.delivery.recordtype) + "/view/" + (item.delivery._id)),
            }}},[_vm._v(_vm._s(item.delivery.displayname || item.delivery.name))])]}},{key:"item.childs",fn:function(ref){
            var item = ref.item;
return _vm._l((item.childs),function(po){return _c('router-link',{key:po._id,staticClass:"link",attrs:{"to":{
              path: ("/" + (po.collection) + "/" + (po.recordtype) + "/view/" + (po._id)),
            }}},[_vm._v(_vm._s(po.displayname || po.name))])})}},{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col',[_c('v-btn',{attrs:{"block":"","tile":"","color":"primary","disabled":_vm.loading ||
                    !_vm.selected.length ||
                    _vm.$route.params.mode == 'edit'},on:{"click":_vm.action}},[_vm._v("Receive Selected")])],1),_c('v-col',[_c('v-btn',{attrs:{"block":"","tile":"","color":"primary","disabled":_vm.loading},on:{"click":function($event){return _vm.getData()}}},[_vm._v("Refresh")])],1),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('v-icon',[_vm._v(_vm._s(_vm.show ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1)],1)],1)]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_vm._v("Total:")]),_vm._l((_vm.headers),function(col,index){return _c('th',{key:index},[_vm._v(" "+_vm._s(_vm.calcTotal(col.value))+" ")])})],2)])],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card tile>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Orders Managment Tools</v-toolbar-title>
    </v-toolbar>
    <v-tabs dark v-model="active">
      <v-tab :key="1">Simple Order</v-tab>
      <v-tab :key="2">Sales Orders to Approve</v-tab>
      <v-tab :key="3">Orders to Fulfill</v-tab>
      <v-tab :key="4">Ready To Pack</v-tab>
      <v-tab :key="5">Orders To Invoice</v-tab>
      <v-tab :key="6">Invoices</v-tab>
      <!-- <v-tab :key="4">Export Customs Clearance (v.Beta)</v-tab> -->
      <v-tab :key="7">Returns</v-tab>
      <v-tab :key="8"> <v-icon>mdi-close-outline</v-icon></v-tab>
      <!-- <v-tab :key="8">Statistics</v-tab> -->
         <v-tab-item :key="1">
        <quickorder v-if="active == 0" />
      </v-tab-item>
      <v-tab-item :key="2">
        <orderstoapprove v-if="active == 1" />
      </v-tab-item>
      <v-tab-item :key="3">
        <orderstofulfill v-if="active == 2" />
      </v-tab-item>
      <v-tab-item :key="4">
        <readytopack v-if="active == 3" />
      </v-tab-item>
      <v-tab-item :key="5">
        <orderstoinvoice v-if="active == 4" />
      </v-tab-item>
         <v-tab-item :key="6">
        <invoices v-if="active == 5" />
      </v-tab-item>
      <!-- <v-tab-item :key="4">
        <exportcustomsclearance v-if="active == 3" />
      </v-tab-item> -->
      <v-tab-item :key="7">
        <returnrequest v-if="active == 6" />
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import orderstoapprove from "../components/tools/orderstoapprove/View";
import orderstoinvoice from "../components/tools/orderstoinvoice/View";
import readytopack from "../components/tools/readytopack/View";
import exportcustomsclearance from "../components/tools/exportcustomsclearance/View";
import returnrequest from "../components/tools/returnrequest/View";
import orderstofulfill from "../components/tools/orderstofulfillment/View";
import quickorder from "../components/tools/quickorder/View";
import invoices from "../components/tools/invoices/View";
/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
      active: 7,
    };
  },

  components: {
    orderstoapprove,
    orderstoinvoice,
    readytopack,
    exportcustomsclearance,
    returnrequest,
    orderstofulfill,
    quickorder,
    invoices
  },
};
</script>
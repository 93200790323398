<template>
  <div>
    <v-btn tile icon @click="pdf()" v-if="query.pdfform">
      <v-tooltip bottom>
        <span>Print</span>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-printer</v-icon>
        </template>
      </v-tooltip>
    </v-btn>
    <v-btn
      v-if="recordtype && collection"
      tile
      color="primary"
      @click="newRecord()"
    >
      New
      <v-icon right dark>mdi-plus-box-outline</v-icon>
    </v-btn>
  </div>
</template>
<script>
import exportFromJSON from "export-from-json";
export default {
  props: ["recordtype", "collection", "query"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    pdf(pdfform = "") {
      if (pdfform) pdfform = `/?pdfform=${pdfform}`;
      else pdfform = "";
      let pdf = `http://localhost:8080/api/pdf/queries/${this.query._id}/${(
        this.query.name || this.query.recordtype
      )
        .replaceAll("#", "%23")
        .replaceAll("/", "%2F")}${pdfform}`;
      window.open(pdf, "_blank");
    },
    newRecord() {
      if (this.recordtype && this.collection) {
        this.$router.push({
          path: `/${this.collection}/${this.recordtype}/create/`,
        });
      }
    },
  },
};
</script>
import service from "../../../api/index";
import store from '../../../store/index'
export default async (item) => {
    let locationsFilter = []
    if (store.state.user.location)
        locationsFilter.push(store.state.user.location);
    if (
        store.state.user.forlocations &&
        store.state.user.forlocations.length
    )
        locationsFilter.push(...store.state.user.forlocations);

    if (store.state.user.role._id == "role_peddersuk")
        locationsFilter.push(
            "5e0dbaba9e33df43f0b3a495",
            "66e04fcba16b2e1e7d873be8",
            "627cee8a0f148d1cb7e46aa2"

        );
    if (store.state.user.role._id == "role_brakespl")
        locationsFilter.push(
            "5e0dbaba9e33df43f0b3a495",
            "66e04fcba16b2e1e7d873be8"
        );

    let items = [];
    let critery = { parentrecordtype: "bill", status: 'open', hidden: { $ne: true }, location: locationsFilter.length ? { $in: locationsFilter } : { $exists: true } };
    if (item) critery.item = item;
    let results = await service("transactions_lines", { action: "find", data: [critery], sort: { eta: 1 } }, false, true);

    let rows = {};
    for (let line of results) {
        let row = {
            _id: line._id,
            delivery: {},
            vendor: {},
            quantity: line.quantity || 0,
            // quantityscanned: line.quantityscanned || 0,
            quantityreceived: line.quantityreceived || 0,
            memo: line.memo,
            location: {}
        };
        if (!rows[line.delivery + line.location]) {
            rows[line.delivery + line.location] = row;
            if (!row.quantityreceived && row.quantity) items.push(row);
        }
        else {
            row = rows[line.delivery + line.location];
            if (line.delivery) {
                row.quantity += line.quantity || 0;
                row.quantityscanned += line.quantityscanned || 0;
                row.quantityreceived += line.quantityreceived || 0;
            }
        }
        row.delivery = await service("deliveries", { action: "findOne", data: [{ _id: line.delivery }] }, false, true);
        row.eta = new Date(row.delivery.eta || null).toISOString().substr(0, 10);
        row.date = new Date(row.delivery.date || null).toISOString().substr(0, 10);
        row.memo = row.delivery.memo;
        row.planneddateofshipment = new Date(row.delivery.planneddateofshipment || null).toISOString().substr(0, 10);
        if (line.location)
            row.location = await service("accounting", { action: "findOne", data: [{ _id: line.location }] }, false, true);
        row.item = await service("items", { action: "findOne", data: [{ _id: line.item }] }, false, true);
        if (line.customer)
            row.vendor = await service("users", { action: "findOne", data: [{ _id: line.customer }] }, false, true);
        if (row.delivery.deliverymethod) {
            row.deliverymethod = await service("lists", { action: "findOne", data: [{ _id: row.delivery.deliverymethod }] }, false, true);
            row.deliverymethod = row.deliverymethod.name;
        }

    }
    return items;
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","search":_vm.search,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter},on:{"current-items":function($event){return _vm.getFiltered($event)}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.date).toISOString().substr(0, 10)))])]}},{key:"item.duedate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.duedate ? new Date(item.duedate).toISOString().substr(0, 10) : ""))])]}},{key:"item.transaction",fn:function(ref){
var item = ref.item;
return [(item.transaction)?_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.transaction.collection) + "/" + (item.transaction.recordtype) + "/view/" + (item.transaction._id)),
            }}},[_vm._v(_vm._s(item.transaction.displayname || item.transaction.name))]):_vm._e()]}},{key:"item.createdfrom",fn:function(ref){
            var item = ref.item;
return _vm._l((item.createdfrom),function(po){return _c('router-link',{key:po._id,staticClass:"link",attrs:{"to":{
              path: ("/" + (po.collection) + "/" + (po.recordtype) + "/view/" + (po._id)),
            }}},[_vm._v(_vm._s(po.displayname || po.name)),_c('br')])})}},{key:"item.customer",fn:function(ref){
            var item = ref.item;
return [(item.customer)?_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.customer.collection) + "/" + (item.customer.recordtype) + "/view/" + (item.customer._id)),
            }}},[_vm._v(_vm._s(item.customer.displayname || item.customer.name))]):_vm._e()]}},{key:"item.accountonhold",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.accountonhold ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.accountonhold ? "Yes" : "No"))])]}},{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('exportData',{attrs:{"headers":_vm.headers,"items":_vm.items}})],1),_c('v-col',[_c('v-select',{staticClass:"mt-2 mb-2",attrs:{"items":_vm.companies,"label":"Company","item-text":"name","item-value":"_id","outlined":"","multiple":"","dense":""},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","full-width":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"mt-2 mb-2",attrs:{"label":"To","placeholder":"","append-icon":"event","readonly":"","outlined":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.dateto),callback:function ($$v) {_vm.dateto=$$v},expression:"dateto"}},on))]}}]),model:{value:(_vm.menuto),callback:function ($$v) {_vm.menuto=$$v},expression:"menuto"}},[_c('v-date-picker',{model:{value:(_vm.dateto),callback:function ($$v) {_vm.dateto=$$v},expression:"dateto"}})],1)],1),_c('v-col',[_c('v-btn',{staticClass:"mt-2 mb-2",attrs:{"block":"","tile":"","color":"primary"},on:{"click":function($event){return _vm.getData()}}},[_vm._v("Refresh")])],1)],1)],1)]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"body.append"},[_c('tr',_vm._l((_vm.headers),function(col,index){return _c('th',{key:index},[_vm._v(" "+_vm._s(col.summary ? _vm.calcTotal(col.value) : "")+" ")])}),0)])],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card>
    <v-container fluid grid-list-xl>
      <v-layout row wrap>
        <v-flex md6>
          <v-text-field v-model="map.name" label="Map Name" :rules="[rules.required]">
          </v-text-field>
        </v-flex>

        <v-flex md6>
          <v-select
            v-model="map.setup.query"
            hint="Select query"
            :items="queries"
            item-text="name"
            item-value="type"
            label="Select"
            persistent-hint
            single-line
            :rules="[rules.required]"
          ></v-select>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
/* eslint-disable */
export default {
  props: ['map'],
  data() {
    return {
      queries: [{ name: 'Addresses', type: 'address' }],
      rules: {
        required: value => !!value || 'Required.',
      },
    };
  },
};
</script>

import { render, staticRenderFns } from "./itemfulfillment.vue?vue&type=template&id=119c1e38&scoped=true"
import script from "./itemfulfillment.vue?vue&type=script&lang=js"
export * from "./itemfulfillment.vue?vue&type=script&lang=js"
import style0 from "./itemfulfillment.vue?vue&type=style&index=0&id=119c1e38&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "119c1e38",
  null
  
)

export default component.exports
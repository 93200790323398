 <template>
  <div>
    <v-row>
      <v-col>
        <v-tabs dark v-model="active">
          <v-tab
            v-for="(tab, index) of Object.values(tabs).filter(
              (tab) =>
                (this.locationsFilter.includes(tab.location) ||
                  !this.locationsFilter.length) &&
                !tab.hidden
            )"
            :key="index"
          >
            <v-badge color="primary">
              <span>{{ tab.name }}</span>
              <span slot="badge">{{ tab.items.length }}</span>
            </v-badge>
          </v-tab>

          <v-tab-item
            v-for="(tab, index) of Object.values(tabs).filter(
              (tab) =>
                (this.locationsFilter.includes(tab.location) ||
                  !this.locationsFilter.length) &&
                !tab.hidden
            )"
            :key="index"
          >
            <v-data-table
              :headers="tab.headers"
              :items="tab.items"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              v-model="selected"
              show-select
              :search="search"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
              :item-class="rowClass"
              group-by="Category"
            >
              <template v-slot:item.remainder="{ item }">
                <v-chip label :color="item.remainder ? 'red' : 'none'" dark>{{
                  item.remainder
                }}</v-chip>
              </template>
              <template v-slot:item.memo="{ item }">
                <field-textarea
                  v-if="tab.headers.find((h) => h.value == 'memo').editable"
                  class="mt-2"
                  @emit="catchEmit($event, item)"
                  v-model="item.memo"
                  :record="item"
                  :fieldSettings="{
                    name: 'Internal Comments',
                    field: 'memo',
                    setup: { name: 'Internal Comments', field: 'memo' },
                  }"
                  :formSettings="{
                    name: 'Internal Comments',
                    field: 'memo',
                    setup: { name: 'Internal Comments', field: 'memo' },
                  }"
                />
                <span v-else>{{ item.memo }}</span>
              </template>
              <template v-slot:item.date="{ item }">
                <span>{{
                  new Date(item.date).toISOString().substr(0, 10)
                }}</span>
              </template>
              <template v-slot:item.created="{ item }">
                <span>{{
                  item.created
                    ? new Date(
                        new Date(item.created).getTime() -
                          new Date(item.created).getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substr(11, 5)
                    : ""
                }}</span>
              </template>
              <template v-slot:item.location="{ item }">
                <span>{{
                  item.location.displayname || item.location.name
                }}</span>
              </template>
              <template v-slot:item.transaction="{ item }">
                <router-link
                  :to="{
                    path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                  }"
                  class="link"
                  >{{
                    item.transaction.displayname || item.transaction.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                  }"
                  class="link"
                  >{{
                    item.customer.displayname || item.customer.name
                  }}</router-link
                >
              </template>

              <template v-slot:item.accountonhold="{ item }">
                <v-chip
                  label
                  :color="item.accountonhold ? 'red' : 'none'"
                  dark
                  >{{ item.accountonhold ? "Yes" : "No" }}</v-chip
                >
              </template>
              <template v-slot:item.sendtogether="{ item }">
                <v-chip
                  label
                  :color="item.sendtogether ? 'red' : 'none'"
                  dark
                  >{{ item.sendtogether ? "Yes" : "No" }}</v-chip
                >
              </template>
              <template v-slot:item.warning="{ item }">
                <v-tooltip
                  bottom
                  v-if="
                    item.customer.additionalinstructions &&
                    item.customer.additionalinstructions.length > 0
                  "
                >
                  <span
                    v-for="instruction of item.customer.additionalinstructions"
                    :key="instruction._id"
                    >{{ instructions[instruction._id || instruction] }}</span
                  >
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-alert</v-icon>
                  </template>
                </v-tooltip>
              </template>
              <template v-slot:item.paymentstatus="{ item }">
                <span class="paymentstatus">
                  {{ item.paymentstatus || "Unpaid"
                  }}{{
                    item.paymentdescription
                      ? " - " + item.paymentdescription
                      : ""
                  }}
                </span>
              </template>
              <template v-slot:top>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-btn
                        v-if="tab.name == 'Individual Customers'"
                        @click="closeOrder"
                        block
                        tile
                        color="primary"
                        >Close Orders ({{ selected.length }})</v-btn
                      >
                    </v-col>
                    <v-col>
                      <v-btn
                        :disabled="loading || !selected.length"
                        @click="action"
                        block
                        tile
                        color="primary"
                        >Approve Orders ({{ selected.length }})</v-btn
                      >
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="text-right">
                      <v-btn icon @click="getData()">
                        <v-icon>mdi-reload</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";

export default {
  data() {
    return {
      loading: false,
      active: 0,
      headers: [
        { text: "Warehouse", value: "location", sortable: false },
        { text: "Warning", value: "warning", sortable: false },
        { text: "Date", value: "date", sortable: false },
        { text: "Created", value: "created", sortable: false },
        { text: "Account on Hold", value: "accountonhold", sortable: false },
        { text: "Order Number", value: "transaction", sortable: false },
        { text: "Customer", value: "customer", sortable: false },
        { text: "Delivery", value: "shippingaddress", sortable: false },
        { text: "Internal Comments", value: "memo", sortable: false },
        { text: "Amount", value: "amount", sortable: false },
        { text: "Currency", value: "currency", sortable: false },
        { text: "Send Together", value: "sendtogether", sortable: false },
        { text: "Payment Terms", value: "terms", sortable: false },
        { text: "Payment Method", value: "paymentmethod" },
        { text: "Balance", value: "balance" },
        { text: "Customer Comment", value: "comment", sortable: false },
        { text: "Sales Rep", value: "salesrep", sortable: false },
      ],
      headersUK: [
        { text: "Warehouse", value: "location", sortable: false },
        { text: "Warning", value: "warning", sortable: false },
        { text: "Date", value: "date", sortable: false },
        { text: "Created", value: "created", sortable: false },
        //{ text: "Account on Hold", value: "accountonhold", sortable: false },
        { text: "Order Number", value: "transaction", sortable: false },
        { text: "Customer", value: "customer", sortable: false },
        { text: "Delivery", value: "shippingaddress", sortable: false },
        { text: "Internal Comments", value: "memo", sortable: false },
        { text: "Amount", value: "amount", sortable: false },
        //{ text: "Currency", value: "currency", sortable: false },
        //{ text: "Send Together", value: "sendtogether", sortable: false },
        //{ text: "Payment Method", value: "paymentmethod" },
        { text: "Payment Status", value: "paymentstatus" },
        { text: "Payment Terms", value: "terms", sortable: false },
        { text: "Customer Comment", value: "comment", sortable: false },
        { text: "Sales Rep", value: "salesrep", sortable: false },
      ],
      icheaders: [
        { text: "Warehouse", value: "location", sortable: false },
        { text: "Warning", value: "warning", sortable: false },
        { text: "Date", value: "date", sortable: false },
        { text: "Created", value: "created", sortable: false },
        { text: "Days Open", value: "daysopen", sortable: false },
        { text: "Account on Hold", value: "accountonhold", sortable: false },
        { text: "Order Number", value: "transaction", sortable: false },
        { text: "Customer", value: "customer", sortable: false },
        { text: "Delivery", value: "shippingaddress", sortable: false },
        {
          text: "Internal Comments",
          value: "memo",
          sortable: false,
          width: "300px",
          editable: true,
        },
        { text: "Amount", value: "amount", sortable: false },
        { text: "Currency", value: "currency", sortable: false },
        { text: "Send Together", value: "sendtogether", sortable: false },
        { text: "Payment Terms", value: "terms", sortable: false },
        { text: "Payment Method", value: "paymentmethod" },
        { text: "Balance", value: "balance" },
        { text: "Source", value: "source", sortable: false },
        { text: "Customer Comment", value: "comment", sortable: false },
        { text: "Reminder", value: "remainder", sortable: false },
      ],
      items: [],
      tabs: {},
      rows: [100],
      singleSelect: false,
      selected: [],
      search: "",
      show: false,
      instructions: {},
      locationsFilter: [],
    };
  },

  async created() {
    this.loading = true;
    this.tabs = {
      PL: {
        name: "Warehouse PL",
        location: "5e0dbaba9e33df43f0b3a495",
        items: [],
        headers: this.headers,
      },
      NL: {
        name: "Warehouse NL",
        location: "66e04fcba16b2e1e7d873be8",
        items: [],
        headers: this.headers,
      },
      UK: {
        name: "Warehouse UK (Birmingham)",
        location: "5faa7485371aa739b8a08843",
        items: [],
        headers: this.headers,
        hidden: true,
      },
      IC: {
        name: "Individual Customers",
        location: "5e0dbaba9e33df43f0b3a495",
        items: [],
        headers: this.icheaders,
      },
      PeddersUK: {
        name: "Warehouse UK",
        location: "61e14c6ae4a35a668517a8fe",
        items: [],
        headers: this.headersUK,
      },
      BrakesPL: {
        name: "Brakes PL",
        location: "62de6196fa2fc522dc692c7e",
        items: [],
        headers: this.headers,
      },
    };
    this.instructions = await service("lists", {
      action: "find",
      data: [{ recordtype: "additionalinstruction" }],
    }).then((response) => {
      let ids = {};
      response.map((v) => {
        ids[v._id] = v.name;
      });
      return ids;
    });
    if (this.$store.state.user.location)
      this.locationsFilter.push(this.$store.state.user.location);
    if (
      this.$store.state.user.forlocations &&
      this.$store.state.user.forlocations.length
    )
      this.locationsFilter.push(...this.$store.state.user.forlocations);
    await this.getData();
    this.loading = false;
  },
  methods: {
    rowClass(item) {
      if (
        ((item.paymentstatusid && item.paymentstatusid != "fullypaid") ||
          !item.paymentstatusid) &&
        item.terms == "Prepaid"
      ) {
        return "unpaid";
      }
    },
    async catchEmit(e, item) {
      try {
        let result = await service("orders", {
          action: "setField",
          collection: "transactions",
          rid: item._id,
          field: e.field,
          value: e.value,
        });
      } catch (error) {
        alert(error.message);
      }
    },
    async getData() {
      this.loading = true;
      this.tabs["PL"].items = [];
      this.tabs["UK"].items = [];
      this.tabs["IC"].items = [];
      this.tabs["PeddersUK"].items = [];
      this.tabs["BrakesPL"].items = [];
      this.tabs["NL"].items = [];
      let result = await service(
        "orders",
        {
          action: "getOrdersToApprove",
        },
        false,
        true
      );
      result.items.forEach((item) => {
        item.Category = item.source;
        item.shippingaddress = item.shipaddressee || item.shipaddress;
        if (
          item.companyid &&
          item.companyid == "maincompany" &&
          !item.customer.isperson
        ) {
          this.tabs["PL"].items.push(item);
        }
        if (
          item.companyid == "ozpartsuklimited" &&
          item.location &&
          !["61e14c6ae4a35a668517a8fe", "620bd994628dd8bb034ce62f"].includes(
            item.location._id
          ) &&
          !item.customer.isperson
        ) {
          this.tabs["UK"].items.push(item);
        }
        if (
          item.companyid == "ozpartsuklimited" &&
          item.location &&
          ["61e14c6ae4a35a668517a8fe", "620bd994628dd8bb034ce62f"].includes(
            item.location._id
          )
        ) {
          this.tabs["PeddersUK"].items.push(item);
        }
        if (
          !["brakespl"].includes(item.companyid) &&
          item.customer.isperson &&
          item.location &&
          !["61e14c6ae4a35a668517a8fe", "620bd994628dd8bb034ce62f"].includes(
            item.location._id
          )
        ) {
          this.tabs["IC"].items.push(item);
        }
        if (item.companyid == "brakespl") {
          this.tabs["BrakesPL"].items.push(item);
        }
        if (
          item.companyid &&
          item.companyid == "65cf227de8d07217f255104f" && // ozparts BV
          !item.customer.isperson
        ) {
          this.tabs["NL"].items.push(item);
        }
      });
      this.items = result.items;
      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    async action() {
      this.loading = true;
      let orders = [];
      this.selected.forEach((so) => {
        if (!orders.includes(so._id)) orders.push(so._id);
      });
      await service("transactions", {
        action: "approveOrder",
        status: "open",
        orders: orders,
      }).catch((error) => {
        alert(error.message);
      });
      this.selected = [];
      await this.getData();
      this.loading = false;
    },
    async closeOrder() {
      this.loading = true;
      let orders = [];
      this.selected.forEach((so) => {
        orders.push(so._id);
      });
      await service("transactions", {
        action: "closeOrder",
        status: "closed",
        orders: orders,
      }).catch((error) => {
        alert(error.message);
      });
      await this.getData();
      this.loading = false;
    },
  },
};
</script>
<style>
.otherlocation {
  background: #ececec;
}
</style>
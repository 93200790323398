 <template>
  <v-card>
    <v-container v-if="items.length">
      <v-row>
        <v-col>
          <component
            :is="type"
            :option="options"
            :chartData="toChartData(type, items)"
            :key="timeunit"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            :items="
              headers.filter((header) => {
                if (['barchart', 'linechart'].includes(type)) {
                  return (
                    fieldsObj[header.field] &&
                    fieldsObj[header.field].setup.type == 'field-date'
                  );
                } else {
                  return (
                    fieldsObj[header.field] &&
                    !['field-int', 'field-currency', 'field-date'].includes(
                      fieldsObj[header.field].setup.type
                    )
                  );
                }
              })
            "
            v-model="xaxis"
            label="X axis"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details
            class="mt-2 mb-2"
            dense
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="
              headers.filter((header) => {
                return (
                  fieldsObj[header.field] &&
                  header.summary &&
                  ['field-int', 'field-currency'].includes(
                    fieldsObj[header.field].setup.type
                  )
                );
              })
            "
            v-model="yaxis"
            label="Y axis"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details
            class="mt-2 mb-2"
            dense
            multiple
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="types"
            v-model="type"
            label="Chart Type"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details
            class="mt-2 mb-2"
            dense
          ></v-select>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="text-right">
          <v-btn icon @click="show = !show">
            <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-expand-transition>
        <div v-show="show">
          <v-container>
            <v-row>
              <v-col>
                <v-select
                  disabled
                  v-if="['barchart', 'linechart'].includes(type)"
                  :items="timeunits"
                  v-model="timeunit"
                  label="Time Unit"
                  item-text="text"
                  item-value="value"
                  outlined
                  clearable
                  hide-details
                  class="mt-2 mb-2"
                  dense
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  :items="
                    headers.filter((header) => {
                      return (
                        fieldsObj[header.field] &&
                        ![
                          'field-int',
                          'field-currency',
                          'field-date',
                          'field-percent',
                        ].includes(fieldsObj[header.field].setup.type)
                      );
                    })
                  "
                  v-model="datasets"
                  label="Data Sets"
                  item-text="text"
                  item-value="value"
                  outlined
                  clearable
                  hide-details
                  class="mt-2 mb-2"
                  dense
                ></v-select>
              </v-col>
              <v-col> </v-col>
            </v-row>
          </v-container>
        </div>
      </v-expand-transition>
    </v-container>
  </v-card>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import piechart from "../../charts/pie";
import barchart from "../../charts/bar";
import linechart from "../../charts/line";
import doughnutchart from "../../charts/doughnut";
import colors from "../../charts/colors";
import filter from "../../dataTable/filter";
import getFields from "../../../common/methods/getFields";
export default {
  props: ["items", "headers", "xaxis", "yaxis", "dateunit"],
  data() {
    return {
      show: false,
      active: 0,
      loading: false,
      fieldsObj: {},
      datasets: null,
      data: {},
      chartData: {},
      types: [
        { value: "barchart", text: "Bar" },
        { value: "linechart", text: "Line" },
        //{ value: "doughnutchart", text: "Doughnut" },
      ],
      type: "barchart",
      timeunits: [
        //{ text: "Day", value: "day" },
        { text: "Week", value: "week" },
        { text: "Month", value: "month" },
        { text: "Quarter", value: "quarter" },
        { text: "Year", value: "year" },
      ],
      timeunit: "month",
    };
  },
  async created() {
    this.timeunit = this.dateunit.replace("$", "");
    this.fieldsObj = await getFields(this, {});
  },
  computed: {
    options: function () {
      return {
        responsive: true,
        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                unit: this.timeunit,
                round: this.timeunit,
                displayFormats: {
                  day: "YY-MM",
                  quarter: "YYYY-Q",
                  year: "YYYY",
                  month: "YYYY-MM",
                  week: "YYYY-WW",
                },
                tooltipFormat: "YYYY",
              },
              distribution: "linear",
              stacked: this.type == "barchart" ? true : false,
            },
          ],
          yAxes: [
            {
              stacked: this.type == "barchart" ? true : false,
            },
          ],
        },
      };
    },
  },
  watch: {
    items() {
      this.loading = true;
      this.xaxis = this.headers.find((header) => {
        return (
          this.fieldsObj[header.field] &&
          this.fieldsObj[header.field].setup.type == "field-date"
        );
      }).value;
      this.yaxis = this.yaxis || [
        this.headers.find((header) => {
          return (
            this.fieldsObj[header.field] &&
            ["field-int", "field-currency"].includes(
              this.fieldsObj[header.field].setup.type
            )
          );
        }).value,
      ];
      console.log(this.xaxis, this.yaxis, this.datasets);
      this.loading = false;
    },
    yaxis() {
      if (this.yaxis.length > 1) this.datasets = null;
    },
    datasets() {
      if (this.datasets) this.yaxis = [this.yaxis[0]];
    },
  },
  methods: {
    toChartData(type, items) {
      let chartData = { labels: [], datasets: [] };
      console.log(this.xaxis, this.yaxis, this.datasets);
      items.forEach((item) => {
        let xaxis = Array.isArray(item[this.xaxis])
          ? item[this.xaxis]
              .map((val) => (val ? val.name || val : ""))
              .toString()
          : item[this.xaxis]
          ? item[this.xaxis].name || item[this.xaxis]
          : "";
        if (this.yaxis && this.yaxis.length > 1) {
        }
        this.yaxis.forEach((yaxis) => {
          let yaxisLabel = this.headers.find((header) => {
            return header.value == yaxis;
          }).name;
          let datasets = Array.isArray(item[this.datasets])
            ? item[this.datasets]
                .map((val) => (val ? val.name || val : ""))
                .toString()
            : item[this.datasets]
            ? item[this.datasets]
              ? item[this.datasets].name || item[this.datasets].toString()
              : ""
            : yaxisLabel || "Value";
          if (item[this.xaxis]) {
            chartData.labels[xaxis] = 0;
            chartData.datasets[datasets] = chartData.datasets[datasets] || {
              label: datasets,
              data: {},
              fill: false,
              tension: 0,
            };
            chartData.datasets[datasets].data[xaxis] =
              chartData.datasets[datasets].data[xaxis] || 0;
            chartData.datasets[datasets].data[xaxis] += parseFloat(
              item[yaxis] || 0
            );
          } // else console.log(item[xaxis], xaxis, items);
        });
      });

      chartData.datasets = Object.values(chartData.datasets);
      chartData.datasets.forEach((dataset, index) => {
        dataset.backgroundColor = ["barchart", "linechart"].includes(type)
          ? colors[index]
          : colors;
        dataset.borderColor = ["barchart", "linechart"].includes(type)
          ? dataset.backgroundColor
          : "";
        dataset.data = Object.values(
          Object.assign({}, chartData.labels, dataset.data)
        );
      });

      chartData.labels = Object.keys(chartData.labels).map((label) => {
        console.log(this.timeunit);
        if (this.timeunit == "quarter") {
          let tmp = label.split("-Q");
          label = `${tmp[0]}-${tmp[1] * 3}`;
        }
        if (new Date(label) !== "Invalid Date" && !isNaN(new Date(label)))
          return new Date(label);
        else return label;
      });
      chartData.labels = chartData.labels.sort((a, b) => {
        return a - b;
      });
      console.log(chartData);
      return chartData;
    },
    adjust(color, amount) {
      return (
        "#" +
        color
          .replace(/^#/, "")
          .replace(/../g, (color) =>
            (
              "0" +
              Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(
                16
              )
            ).substr(-2)
          )
      );
    },
  },
  components: { piechart, barchart, linechart, doughnutchart },
};
</script>

import service from "../../api/index";
/* eslint-disable */
export default async (query, options = [], last) => {
  let collection = query.setup.collection;
  //let steps = [...query.stages];
  //console.log(query.recordtype)
  const data = {
    action: query.recordtype == "report" ? "aggregate2" : "aggregate",
    data: {
      query: query,
      options: options,
      last: last
    }
  };
  try {
    let resposne = await service(collection, data, false, true)
    return resposne.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

<template>
  <v-container fluid grid-list-xs>
    <v-layout wrap>
      <v-flex xs12 class="mb-3">
        <v-sheet height="64">
          <v-toolbar flat color="white">
            <v-btn outlined class="mr-4" @click="setToday">Today</v-btn>
            <v-btn fab text small @click="prev">
              <v-icon small>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn fab text small @click="next">
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on }">
                <v-btn outlined v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 days</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="800">
          <v-calendar
            :weekdays="weekday"
            v-model="focus"
            ref="calendar"
            :type="type"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            @click:event="showEvent"
            @change="updateRange"
            @click:more="viewDay"
            @click:date="addActivity"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            full-width
            offset-x
          >
            <v-card color="grey lighten-4" min-width="350px" flat>
              <v-toolbar color="primary" dark>
                <v-btn icon @click="openActivity(selectedEvent)">
                  <v-icon>edit</v-icon>
                </v-btn>
                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- <v-btn icon>
                  <v-icon>favorite</v-icon>
                </v-btn>
                <v-btn icon>
                  <v-icon>more_vert</v-icon>
                </v-btn> -->
              </v-toolbar>
              <v-card-title primary-title>
                <span v-html="selectedEvent.note"></span>
              </v-card-title>
              <!-- <v-card-actions>
                <v-btn flat color="secondary" @click="selectedOpen = false"
                  >Cancel</v-btn
                >
              </v-card-actions> -->
            </v-card>
          </v-menu>
        </v-sheet>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
/* eslint-disable */
import getEvents from "./getEvents";
export default {
  props: ["calendar"],
  data: () => ({
    type: "month",
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    weekday: [1, 2, 3, 4, 5, 6, 0],
    start: null,
    end: null,
    today: null,
    focus: null,
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    events: [],
  }),
  async created() {
    this.events = await getEvents(this.calendar);
    //console.log(this.events);
  },
  mounted() {
    this.today = this.today || new Date().toISOString().substr(0, 10);
    this.focus = this.focus || new Date().toISOString().substr(0, 10);
    // console.log(this.today, this.focus);
    this.$refs.calendar.checkChange();
  },
  methods: {
    getEventColor(event) {
      return event.color || "#464d69";
    },
    openActivity(event) {
      this.$router.push({
        path: `/${event.collection}/${event.recordtype}/edit/${event._id}`,
      });
    },
    addActivity(event) {
      console.log(event)
      var response = confirm("Add new Event - Are you sure?");
      if (response) {
        this.$router.push({
          name: "Activities",
          params: {
            recordtype: "event",
            mode: "create",
            calendar: this.calendar._id,
            date: event.date,
          },
        });
      }
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      //console.log("prev");
      this.$refs.calendar.prev();
    },
    next() {
      //console.log("next");
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      // You could load events from an outside source (like database) now that we have the start and end dates on the calendar
      //console.log("Update");
      this.start = start;
      this.end = end;
    },
    nth(d) {
      return d > 3 && d < 21
        ? "th"
        : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
    },
  },
  computed: {
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? "" : endYear;

      const startDay = start.day + this.nth(start.day);
      const endDay = end.day + this.nth(end.day);

      switch (this.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
        case "4day":
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
        case "day":
          return `${startMonth} ${startDay} ${startYear}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },
  },
};
</script>


 <template>
  <div>
    <v-row>
      <v-col>
        <v-tabs dark v-model="active">
          <v-tab v-for="(tab, index) of tabs" :key="index">{{
            tab.currency
          }}</v-tab>
          <v-tab-item v-for="(tab, index) of tabs" :key="index">
            <v-data-table
              :headers="headers"
              :items="tab.items"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              :search="search"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
              :custom-sort="sort"
            ></v-data-table>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import sort from "../../dataTable/sort";
export default {
  props: ["record"],
  data() {
    return {
      loading: false,
      headers: [
        { text: "Price Level", value: "pricelevel" },
        { text: "Customer", value: "customer" },
        { text: "MOQ", value: "moq" },
        { text: "Price", value: "price" },
        { text: "Currency", value: "currency" },
      ],
      tabs: [],
      rows: [100],
      search: "",
      show: false,
    };
  },
  async created() {
    this.loading = true;
    await this.getData();
    this.loading = false;
  },
  methods: {
    async getData() {
      let result = await service(
        "items",
        {
          action: "getPricing",
          rid: this.record._id,
          recordtype: this.record.recordtype,
        },
        false,
        true
      );
      if (this.record.recordtype == "customer") {
        this.headers[1].text = "Item";
        this.headers[1].value = "item";
      }
      this.tabs = result.items;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    sort(items, sortBy, sortDesc) {
      return sort(items, sortBy, sortDesc);
    },
  },
};
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tabs',{attrs:{"vertical":"","dark":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-tab',_vm._g({attrs:{"ripple":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-information-outline")])],1)]}}])},[_c('span',[_vm._v("Overview")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-tab',_vm._g({attrs:{"ripple":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("assignment_turned_in")])],1)]}}])},[_c('span',[_vm._v("Receive")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-tab',_vm._g({attrs:{"ripple":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-format-list-bulleted")])],1)]}}])},[_c('span',[_vm._v("Related Transactions")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-tab',_vm._g({attrs:{"ripple":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-email-open")])],1)]}}])},[_c('span',[_vm._v("Communications")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-tab',_vm._g({attrs:{"ripple":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-math-log")])],1)]}}])},[_c('span',[_vm._v("Logs")])]),_c('v-tab-item',[_c('v-container',{attrs:{"fluid":""}},[(_vm.record.location._id == '61e14c6ae4a35a668517a8fe')?_c('v-row',[_c('v-col',[(_vm.record.location._id == '61e14c6ae4a35a668517a8fe')?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Send Email Notify After Receipt!")]):_vm._e()],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Quantity:")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.record.quantity || 0))])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Quantity Scanned:")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.record.quantityscanned || 0))])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Quantity Received:")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.record.quantityreceived || 0))])],1)],1)],1)],1)],1)],1),_c('v-col',[_c('v-row',[_c('v-col',[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Item Receipt Date:")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.record.receiptdate ? new Date(_vm.record.receiptdate) .toISOString() .substr(0, 10) : ""))])],1)],1)],1)],1)],1)],1)],1)],1)],1),_c('v-tab-item',[_c('tool-receivedelivery',{attrs:{"record":_vm.record._id}})],1),_c('v-tab-item',[_c('tool-relatedtransactions',{attrs:{"record":_vm.record}})],1),_c('v-tab-item',[_c('tool-inbox',{attrs:{"record":_vm.record,"customer":_vm.record._id,"filter":"record"}})],1),_c('v-tab-item',[_c('tool-logs',{attrs:{"record":_vm.record._id}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
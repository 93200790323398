var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-combobox',{attrs:{"search-input":_vm.text2,"label":_vm.label,"items":_vm.items,"placeholder":"","multiple":_vm.multiple && _vm.$route.params.mode != 'view',"hint":_vm.multiple && _vm.$route.params.mode == 'edit'? 'type value and put enter to confirm' : '',"rules":[_vm.rules.required, _vm.rules.max],"counter":_vm.$route.params.mode != 'view' ? _vm.max : false,"readonly":(_vm.$route.params.mode == 'view' ||
      this.formSettings.disabled ||
      this.fieldSettings.disabled == true ||
      (this.formSettings.setifempty && _vm.value) ||
      _vm.record.archived ||
      _vm.loading) &&
    !this.formSettings.show,"disabled":(this.formSettings.disabled || this.fieldSettings.disabled == true) &&
    !this.formSettings.show,"outlined":"","dense":""},on:{"input":function($event){return _vm.emitChangedValue()},"blur":function($event){return _vm.emitChangedValue()},"focus":function($event){return _vm.updateOptions()},"update:searchInput":function($event){_vm.text2=$event},"update:search-input":function($event){_vm.text2=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [(
        _vm.record.location &&
        _vm.fieldSettings &&
        _vm.fieldSettings.setup &&
        _vm.fieldSettings.setup.collection
      )?_c('v-dialog',{attrs:{"scrollable":"","max-width":"450px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-format-list-bulleted ")])]}}],null,false,138984980)},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.record.item.name)+" - Bins")]),_c('v-divider'),_c('v-card-text',{staticClass:"px-0",staticStyle:{"height":"300px"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":"","headers":_vm.headersBins,"items":_vm.itemsObj.filter(function (bin) { return _vm.record.location ? bin.location == _vm.record.location._id : true; }
              )},scopedSlots:_vm._u([{key:"item.bin",fn:function(ref){
              var item = ref.item;
return [(!item.quanityonhand)?_c('v-text-field',{key:item._id,attrs:{"dense":"","label":"Bin","single-line":"","hide-details":"","outlined":""},on:{"change":function($event){return _vm.addBin(item)}},model:{value:(item.bin),callback:function ($$v) {_vm.$set(item, "bin", $$v)},expression:"item.bin"}}):_c('span',[_vm._v(_vm._s(item.bin))])]}},{key:"item.priority",fn:function(ref){
              var item = ref.item;
return [_c('v-text-field',{attrs:{"dense":"","label":"Priority","single-line":"","hide-details":"","outlined":"","disabled":!item.bin},on:{"change":function($event){return _vm.updateField(
                    'priority',
                    'items_location',
                    item._id,
                    item.priority
                  )}},model:{value:(item.priority),callback:function ($$v) {_vm.$set(item, "priority", $$v)},expression:"item.priority"}})]}},{key:"footer",fn:function(){return [_c('div',{staticStyle:{"position":"relative","text-align":"right","height":"50px"}},[_c('v-fab-transition',[_c('v-btn',{staticStyle:{"top":"5px","left":"15px"},attrs:{"small":"","absolute":"","top":"","left":"","fab":""},on:{"click":function($event){return _vm.addRow()}}},[_c('v-icon',[_vm._v("add")])],1)],1)],1)]},proxy:true}],null,false,3594914141)})],1),_c('v-divider')],1)],1):_vm._e()]},proxy:true}]),model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-tabs',{attrs:{"dark":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_vm._l((Object.values(_vm.tabs).filter(
            function (tab) { return this$1.locationsFilter.includes(tab.location) ||
              !this$1.locationsFilter.length; }
          )),function(tab,index){return _c('v-tab',{key:index},[_c('v-badge',{attrs:{"color":"primary"}},[_c('span',[_vm._v(_vm._s(tab.name))]),_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_vm._v(_vm._s(tab.items.length))])])],1)}),_vm._l((Object.values(_vm.tabs).filter(
            function (tab) { return this$1.locationsFilter.includes(tab.location) ||
              !this$1.locationsFilter.length; }
          )),function(tab,index){return _c('v-tab-item',{key:index},[_c('v-data-table',{attrs:{"headers":tab.headers,"items":tab.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","search":_vm.search,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter,"custom-sort":_vm.sort,"id":"inventorybackorder"},on:{"current-items":_vm.getFiltered},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.date).toISOString().substr(0, 10)))])]}},{key:"item.eta",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.eta ? new Date(item.eta).toISOString().substr(0, 10) : ""))])]}},{key:"item.transaction",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                  path: ("/" + (item.transaction.collection) + "/" + (item.transaction.recordtype) + "/view/" + (item.transaction._id)),
                }}},[_vm._v(_vm._s(item.transaction.displayname || item.transaction.name))])]}},{key:"item.correlatedsoeta",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.correlatedsoeta ? new Date(item.correlatedsoeta).toISOString().substr(0, 10) : ""))])]}},{key:"item.correlatedso",fn:function(ref){
                var item = ref.item;
return [(item.correlatedso)?_c('router-link',{staticClass:"link",attrs:{"to":{
                  path: ("/" + (item.correlatedso.collection) + "/" + (item.correlatedso.recordtype) + "/view/" + (item.correlatedso._id)),
                }}},[_vm._v(_vm._s(item.correlatedso.displayname || item.correlatedso.name))]):_vm._e()]}},{key:"item.item",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                  path: ("/" + (item.item.collection) + "/" + (item.item.recordtype) + "/view/" + (item.item._id)),
                }}},[_vm._v(_vm._s(item.item.displayname || item.item.name))])]}},{key:"item.customer",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                  path: ("/" + (item.customer.collection) + "/" + (item.customer.recordtype) + "/view/" + (item.customer._id)),
                }}},[_vm._v(_vm._s(item.customer.displayname || item.customer.name))])]}},{key:"item.fleetorder",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.fleetorder ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.fleetorder ? "Yes" : "No"))])]}},{key:"item.stockorder",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.stockorder ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.stockorder ? "Yes" : "No"))])]}},{key:"item.dropship",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.dropship ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.dropship ? "Yes" : "No"))])]}},{key:"item.deliveries",fn:function(ref){
                var item = ref.item;
return _vm._l((item.deliveries),function(po){return _c('span',{key:po._id},[_vm._v(" "+_vm._s(po)+" "),_c('br')])})}},{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('exportData',{attrs:{"headers":_vm.headers,"items":tab.items}})],1),_c('v-spacer'),_c('v-col',[_c('v-select',{attrs:{"dense":"","items":_vm.customers,"label":"Customer","outlined":"","item-text":"name","item-value":"_id","hide-details":"","clearable":""},model:{value:(_vm.customer),callback:function ($$v) {_vm.customer=$$v},expression:"customer"}})],1),_c('v-col',[_c('v-select',{attrs:{"dense":"","items":_vm.manufacturers,"item-text":"name","item-value":"_id","label":"Manufacturer","outlined":"","return-object":"","hide-details":"","clearable":"","multiple":""},model:{value:(_vm.manufacturer),callback:function ($$v) {_vm.manufacturer=$$v},expression:"manufacturer"}})],1),_c('v-col',[_c('v-select',{attrs:{"dense":"","items":_vm.services.filter(function (service) { return service.forlocations.includes(tab.location); }
                        ),"label":"Services","outlined":"","item-text":"name","item-value":"_id","hide-details":"","clearable":"","multiple":""},model:{value:(_vm.service),callback:function ($$v) {_vm.service=$$v},expression:"service"}})],1)],1),_vm._l((_vm.manufacturer),function(m){return _c('v-row',{key:m._id},[_c('v-col'),_c('v-col',[_c('v-subheader',[_vm._v(_vm._s(m.name))]),_c('pre',{staticClass:"pa-4",staticStyle:{"text-wrap":"balance"}},[_vm._v(_vm._s(m.detaildescription))])],1)],1)})],2)]},proxy:true}],null,true)})],1)})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <v-dialog
      v-model="open"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          v-if="['edit', 'create'].includes($route.params.mode)"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Editor
        </v-btn>
      </template>
      <v-card style="height: 100%">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="open = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Template Editor</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="exportHtml()"> Save </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container fluid style="display: flex; height: 100%">
          <EmailEditor
            ref="editor"
            v-on:load="editorLoaded"
            :options="options"
          />
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { EmailEditor } from "vue-email-editor";
import juice from "juice";
export default {
  props: ["record"],
  data() {
    return {
      open: false,
      loading: false,
      options: {
        fonts: {
          showDefaultFonts: false,
        },
      },
    };
  },
  components: {
    EmailEditor,
  },
  methods: {
    editorLoaded() {
      console.log(this.record.temp);
      if (this.record.temp) this.$refs.editor.loadDesign(this.record.temp);
    },
    saveDesign() {
      this.$refs.editor.saveDesign((design) => {
        console.log("saveDesign", design);
        this.record.temp = design;
      });
      this.exportHtml();
      this.open = false;
    },
    async exportHtml() {
      console.log(this.record);
      //this.saveDesign();
      this.loading = true;
      await this.$refs.editor.exportHtml(async (data) => {
        console.log("save", data);
        this.record.temp = data.design;
        this.record.text = juice(data.html);
        this.loading = false;
      });
      this.open = false;
    },
  },
};
</script>
<style scoped>
.unlayer-editor {
  flex: 1;
}
</style>
<template>
  <v-select
    v-model="country"
    :items="countries"
    item-text="name"
    item-value="code"
    :label="label"
    multiple
    placeholder
    :rules="[rules.required]"
    :readonly="$route.params.mode=='view' || record.archived"
    outlined
    dense
  ></v-select>
</template>

<script>
import { getData } from 'country-list';
/* eslint-disable */
export default {
  props: ['value','record', 'fieldSettings', 'formSettings'],
  data() {
    return {
      countries: getData(),
      country: this.value,
      required: this.formSettings.required || this.fieldSettings.setup.required || false,
      field: this.formSettings.field || this.formSettings.setup.field,
      label: this.formSettings.name || this.fieldSettings.name,
      rules: {
        required: value => (this.required ? !!value || "Required." : true)
      },
    };
  },
  watch: {
    country(value) {
      console.log(value)
      this.$emit('emit', { field: this.field, value: value });
    },
  },
};
</script>

<template>
  <v-container
    v-if="
      record.shippingmethod &&
      record.shippingmethod.shippingcarrier &&
      '62f391d56ee485ecc36bf44d' ==
        (record.shippingmethod.shippingcarrier._id ||
          record.shippingmethod.shippingcarrier)
    "
  >
    <v-overlay :value="loading">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>

    <v-row>
      <v-col>
        <v-list two-line>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="primary">local_shipping</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-text-field
                dense
                label="Name"
                v-model="Shipment.receiver.company_name"
                maxlength="35"
                counter
              ></v-text-field>
              <v-text-field
                dense
                label="Attention Name"
                v-model="Shipment.receiver.name"
                maxlength="35"
                counter
              ></v-text-field>
              <!-- <v-list-item-title>{{
                Shipment.ShipTo.Name
              }}</v-list-item-title> -->

              <v-text-field
                v-if="Service.multiplePackages"
                dense
                label="Street"
                v-model="Shipment.receiver.address.street"
                maxlength="35"
                counter
              ></v-text-field>
              <v-text-field
                v-if="Service.multiplePackages"
                dense
                label="Building Number"
                v-model="Shipment.receiver.address.building_number"
                maxlength="35"
                counter
              ></v-text-field>
              <!-- <v-list-item-title>{{
                Shipment.ShipTo.address.AddressLine
              }}</v-list-item-title> -->
              <v-row v-if="Service.multiplePackages">
                <v-col>
                  <v-text-field
                    dense
                    label="City"
                    v-model="Shipment.receiver.address.city"
                    maxlength="30"
                    counter
                  ></v-text-field>
                </v-col>
                <v-col>
                  <!-- <v-list-item-title>{{
                Shipment.ShipTo.Address.City
              }}</v-list-item-title> -->
                  <v-text-field
                    dense
                    label="Postal Code"
                    v-model="Shipment.receiver.address.post_code"
                    maxlength="9"
                    counter
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-text-field
                v-if="Service.multiplePackages"
                dense
                label="Country Code"
                v-model="Shipment.receiver.address.country_code"
              ></v-text-field>
              <!-- <v-list-item-title>{{
                Shipment.ShipTo.Address.CountryCode
              }}</v-list-item-title> -->
              <v-text-field
                v-if="!Service.multiplePackages"
                dense
                label="Target Point"
                v-model="Shipment.custom_attributes.target_point"
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col>
        <v-list two-line>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="primary">mdi-account-card-details-outline</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-select
                v-model="Account"
                :items="AccountNumbers"
                item-text="name"
                item-value="_id"
                label="Account Number"
                placeholder
                outlined
                return-object
                hide-details
                dense
              ></v-select>
              <!-- <v-list-item-title>{{ AccountNumber.number }}</v-list-item-title> -->
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="primary">mdi-truck-fast</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <!-- <v-list-item-subtitle>Service:</v-list-item-subtitle> -->
              <v-select
                v-model="Service"
                :items="services"
                item-text="Description"
                item-value="Code"
                label="Service"
                return-object
                placeholder
                outlined
                hide-details
                dense
              ></v-select>
              <!-- <v-list-item-title>{{ Service.Description }}</v-list-item-title> -->
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="!Service.multiplePackages">
            <v-list-item-action>
              <v-icon color="primary">mdi-package-variant-closed</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <!-- <v-list-item-subtitle>Packaging Type:</v-list-item-subtitle>
              <v-list-item-title>{{
                PackagingType.Description
              }}</v-list-item-title> -->
              <v-select
                v-model="PackagingType"
                :items="PackagingTypes"
                item-text="Description"
                item-value="Code"
                label="Box Size"
                placeholder
                outlined
                hide-details
                dense
              ></v-select>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="primary">mdi-barcode-scan</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <!-- <v-list-item-subtitle>Label Format:</v-list-item-subtitle> -->
              <v-select
                v-model="LabelImageFormat"
                :items="LabelImageFormats"
                item-text="Description"
                item-value="Code"
                label="Label Format"
                placeholder
                return-object
                outlined
                hide-details
                dense
              ></v-select>
              <!-- <v-list-item-title>{{
                LabelImageFormat.Description
              }}</v-list-item-title> -->
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col>
        <v-list two-line>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="primary">mdi-weight-kilogram</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-subtitle>Weight (Packages):</v-list-item-subtitle>
              <v-list-item-title>
                {{
                  this.record.trackingnumbers.reduce((total, tracking) => {
                    return (total += parseInt(tracking.weight));
                  }, 0)
                }}
                kg
                {{
                  this.record.trackingnumbers.length > 1
                    ? `(${this.record.trackingnumbers.length})`
                    : ""
                }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="record.codamount">
            <v-list-item-action>
              <v-icon color="primary">mdi-cash-multiple</v-icon>
            </v-list-item-action>

            <v-list-item-content v-if="record.recordtype != 'salesorder'">
              <v-list-item-subtitle>COD:</v-list-item-subtitle>
              <v-list-item-title>{{ record.codamount }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content v-else>
              <v-text-field
                dense
                error
                label="COD"
                v-model="record.codamount"
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="primary">mdi-message-reply-text-outline</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <!-- <v-list-item-subtitle>Reference Number:</v-list-item-subtitle> -->
              <v-text-field
                label="Reference Number"
                dense
                v-model="ReferenceNumber"
              ></v-text-field>
              <!-- <v-list-item-title>{{ AccountNumber }}</v-list-item-title> -->
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="primary">mdi-message-reply-text</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <!-- <v-list-item-subtitle>Shipment Description:</v-list-item-subtitle> -->
              <v-text-field
                label="Shipment Description"
                dense
                v-model="Description"
              ></v-text-field>
              <!-- <v-list-item-title>{{ AccountNumber }}</v-list-item-title> -->
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="primary">phone</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <!-- <v-list-item-subtitle>Phone:</v-list-item-subtitle> -->
              <v-text-field
                dense
                label="Phone"
                v-model="Shipment.receiver.phone"
                maxlength="15"
                counter
              ></v-text-field>
              <!-- <v-list-item-title>{{
                Shipment.ShipTo.Phone.Number
              }}</v-list-item-title> -->
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="primary">mail</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <!-- <v-list-item-subtitle>E-Mail:</v-list-item-subtitle> -->
              <v-text-field
                dense
                label="Email"
                v-model="Shipment.receiver.email"
                maxlength="50"
                counter
              ></v-text-field>
              <!-- <v-list-item-title>{{
                Shipment.ShipTo.EMailAddress
              }}</v-list-item-title> -->
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-alert type="warning" v-if="warning">{{ warning }}</v-alert>
    <v-row>
      <v-col>
        <v-btn @click="shipment" block tile color="primary" :disabled="arranged"
          >Arrange Shipment</v-btn
        >
      </v-col>
      <!-- :disabled="!shipped || !this.record.trackingnumbers.length" -->
      <v-col>
        <v-btn
          @click="
            dispatchOrders(
              record.trackingnumbers
                .filter((tracking) => tracking.shipment_id)
                .map((tracking) => tracking.shipment_id)
            )
          "
          block
          tile
          color="primary"
          >Dispatch Order</v-btn
        >
      </v-col>
      <v-col>
        <v-btn
          :disabled="
            !this.record.trackingnumbers ||
            (this.record.trackingnumbers && !this.record.trackingnumbers[0]) ||
            (this.record.trackingnumbers &&
              this.record.trackingnumbers[0] &&
              !this.record.trackingnumbers[0].shipment_id)
          "
          @click="
            print(
              record.trackingnumbers
                .map((tracking) => tracking.shipment_id)
                .reduce((t, e) => {
                  if (!t.includes(e)) t.push(e);
                  return t;
                }, [])
            )
          "
          block
          tile
          color="primary"
          >Print Label</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
/* eslint-disable */
//import service from "../../../api/index";
import getRecord from "../../../common/methods/getRecord";
export default {
  props: ["record"],
  data() {
    return {
      loading: false,
      AccountNumbers: [
        {
          _id: "16224",
          name: "16224",
          token:
            "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJzQlpXVzFNZzVlQnpDYU1XU3JvTlBjRWFveFpXcW9Ua2FuZVB3X291LWxvIn0.eyJleHAiOjE5NzczOTcxNzksImlhdCI6MTY2MjAzNzE3OSwianRpIjoiMTViNjZjMDYtZjgyOC00NzFjLWFkM2UtOTMzZTY0ODlmODc1IiwiaXNzIjoiaHR0cHM6Ly9sb2dpbi5pbnBvc3QucGwvYXV0aC9yZWFsbXMvZXh0ZXJuYWwiLCJzdWIiOiJmOjEyNDc1MDUxLTFjMDMtNGU1OS1iYTBjLTJiNDU2OTVlZjUzNTo1TkhCWXlOdnVqdGgyZXR6VVo0M2x3IiwidHlwIjoiQmVhcmVyIiwiYXpwIjoic2hpcHgiLCJzZXNzaW9uX3N0YXRlIjoiZTY2ZmVhMDctNGRmZC00MjlmLWE4ZjMtNDYyNDAzZTMzYjM0IiwiYWNyIjoiMSIsInNjb3BlIjoib3BlbmlkIGFwaTphcGlwb2ludHMgYXBpOnNoaXB4IiwiYWxsb3dlZF9yZWZlcnJlcnMiOiIiLCJ1dWlkIjoiOGU3MjcyZGQtMDgxMC00NjU4LWI5ZGYtMWU2YTAzOWIwNzQzIiwiZW1haWwiOiJza2xlcEBicmFrZXMucGwifQ.b6BJOD-QMgjklCrj5EnjOuAEOBbx-Zbeys0dIPOhsGbgwD_C_0aerbVIPObvq4QoOJZNR4NlKhicrIV4SmphC6A6yQYVPpjjqu4Mw1kvaNXHyXoBwWdZNzlCSZUu1uLxBeTroZQ-pOXlfIXtfbBODF9riToq-tTnAgH4bmQnakwEZ9VxG26axMPtX-pp-d26UkQxy5B_kxu7TJb-5B7lmrLsfOcq1YJpJWvpVkeZ7ysQSXs9jmLSsPj05spZ7w5BPFQuBLqTpHSUt77o0kG4yvmXo9NOl64KsSOi6xRWh-Hv7LcN_iZO4yaKu25gVPJ4PAy39lEsK4Em7uFArNkmqQ",
        },
        {
          _id: "78845",
          name: "78845",
          token:
            "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJzQlpXVzFNZzVlQnpDYU1XU3JvTlBjRWFveFpXcW9Ua2FuZVB3X291LWxvIn0.eyJleHAiOjIwMTEyNTU5MzUsImlhdCI6MTY5NTg5NTkzNSwianRpIjoiMDcxMzlkYWEtZjM0Ni00Njk3LThjNmUtYTlhODVkZWIzNzVkIiwiaXNzIjoiaHR0cHM6Ly9sb2dpbi5pbnBvc3QucGwvYXV0aC9yZWFsbXMvZXh0ZXJuYWwiLCJzdWIiOiJmOjEyNDc1MDUxLTFjMDMtNGU1OS1iYTBjLTJiNDU2OTVlZjUzNTotcFIyOHh4Mm9TaWs3eTBKUS1BQkVDblA5MjNGdFRFckJwa014dEZQb0JrIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoic2hpcHgiLCJzZXNzaW9uX3N0YXRlIjoiZGIyY2U2YjgtY2YwNy00N2IxLTgzMWEtNzNhNGFmZjU5NGUwIiwic2NvcGUiOiJvcGVuaWQgYXBpOmFwaXBvaW50cyBhcGk6c2hpcHgiLCJzaWQiOiJkYjJjZTZiOC1jZjA3LTQ3YjEtODMxYS03M2E0YWZmNTk0ZTAiLCJhbGxvd2VkX3JlZmVycmVycyI6IiIsInV1aWQiOiIzNjA2NDk0Ny0wYjhiLTQ4MjQtODU5OC00YjVjMzhlMzA5NDAiLCJlbWFpbCI6InByb2N1cmVtZW50QG96cGFydHMuZXUifQ.Qy0bDNmVx7Al0-ki6dsngpzO_NcJygjQ5Wgwm1M9Cfo29o1Gg3PhlBC4MaN3k5QJS6JMnAzzcRJ_eu89xVWxWMgPzpwMcjMshz7ieGWJtE4_UBPr6zStiRnUWZHRRF_3aXgF0Ub3RXZ7zzuobE6BpspYLrAfn8AX1-r6pImpq-G6Thx6pboYBsEz3z4ZtonVUDobSjPZihdXPS8bQ1WBzg1Bz-xNPcFLMjBSWBMgqh-GiVCgIghQrC2dSwsqXlyoOwIwvTexnDLQaNLuaQW-587rknt7xPmmJAFWimHh9ndXtFujiR8YQkdFtNwxLtxgo8ubNemo_3xE-7wkBFhQVQ",
        },
      ],
      ReceiverAccountNumber: "",
      ReceiverPostalCode: "",
      Account: {
        _id: "78845",
        name: "78845",
        token:
          "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJzQlpXVzFNZzVlQnpDYU1XU3JvTlBjRWFveFpXcW9Ua2FuZVB3X291LWxvIn0.eyJleHAiOjIwMTEyNTU5MzUsImlhdCI6MTY5NTg5NTkzNSwianRpIjoiMDcxMzlkYWEtZjM0Ni00Njk3LThjNmUtYTlhODVkZWIzNzVkIiwiaXNzIjoiaHR0cHM6Ly9sb2dpbi5pbnBvc3QucGwvYXV0aC9yZWFsbXMvZXh0ZXJuYWwiLCJzdWIiOiJmOjEyNDc1MDUxLTFjMDMtNGU1OS1iYTBjLTJiNDU2OTVlZjUzNTotcFIyOHh4Mm9TaWs3eTBKUS1BQkVDblA5MjNGdFRFckJwa014dEZQb0JrIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoic2hpcHgiLCJzZXNzaW9uX3N0YXRlIjoiZGIyY2U2YjgtY2YwNy00N2IxLTgzMWEtNzNhNGFmZjU5NGUwIiwic2NvcGUiOiJvcGVuaWQgYXBpOmFwaXBvaW50cyBhcGk6c2hpcHgiLCJzaWQiOiJkYjJjZTZiOC1jZjA3LTQ3YjEtODMxYS03M2E0YWZmNTk0ZTAiLCJhbGxvd2VkX3JlZmVycmVycyI6IiIsInV1aWQiOiIzNjA2NDk0Ny0wYjhiLTQ4MjQtODU5OC00YjVjMzhlMzA5NDAiLCJlbWFpbCI6InByb2N1cmVtZW50QG96cGFydHMuZXUifQ.Qy0bDNmVx7Al0-ki6dsngpzO_NcJygjQ5Wgwm1M9Cfo29o1Gg3PhlBC4MaN3k5QJS6JMnAzzcRJ_eu89xVWxWMgPzpwMcjMshz7ieGWJtE4_UBPr6zStiRnUWZHRRF_3aXgF0Ub3RXZ7zzuobE6BpspYLrAfn8AX1-r6pImpq-G6Thx6pboYBsEz3z4ZtonVUDobSjPZihdXPS8bQ1WBzg1Bz-xNPcFLMjBSWBMgqh-GiVCgIghQrC2dSwsqXlyoOwIwvTexnDLQaNLuaQW-587rknt7xPmmJAFWimHh9ndXtFujiR8YQkdFtNwxLtxgo8ubNemo_3xE-7wkBFhQVQ",
      },
      warning: "",
      ReferenceNumber: "",
      Description: "Auto parts",
      Service: {
        Code: "inpost_courier_standard",
        Description: "Przesyłka Kurierska - Standardowa",
        multiplePackages: true,
      },
      services: [
        {
          Code: "inpost_locker_standard",
          Description: "Przesyłka Paczkomat® - standardowa",
          multiplePackages: false,
        },
        {
          Code: "inpost_locker_allegro",
          Description: "Przesyłka Paczkomat® - Allegro Paczkomat® InPost",
          multiplePackages: false,
        },
        {
          Code: "inpost_courier_standard",
          Description: "Przesyłka Kurierska - Standardowa",
          multiplePackages: true,
        },
      ],
      PackagingType: { Code: "small", Description: "Small (8x38x64)" },
      PackagingTypes: [
        { Code: "small", Description: "Small (8x38x64 max:25kg)" },
        { Code: "medium", Description: "Medium (19x38x64 max:25kg)" },
        { Code: "large", Description: "Large (41x38x64 max:25kg)" },
      ],
      LabelImageFormat:
        this.record.recordtype != "salesorder"
          ? {
              Code: "Zpl",
              Description: "ZPL",
            }
          : { Code: "Pdf", Description: "PDF" },

      LabelImageFormats: [
        { Code: "Pdf", Description: "PDF" },
        { Code: "Zpl", Description: "ZPL" },
        { Code: "Epl", Description: "EPL" },
      ],
      headers: {
        "Content-Type": "application/json",
      },

      receiver: {
        name: this.record.shipaddressee || this.record.shipname,
        company_name: this.record.shipname || this.record.shipaddressee,
        email: this.record.email
          ? this.record.email
          : this.record.customer
          ? this.record.customer.email
          : "",
        phone: this.record.shipphone,
        address: {
          street: this.record.shipaddress,
          building_number: this.record.shipaddress2 || this.record.shipaddress,
          city: this.record.shipcity,
          post_code: this.record.shipzip,
          country_code: this.record.shipcountry
            ? this.record.shipcountry._id
            : this.record.shipcountry,
        },
      },
      custom_attributes: {
        sending_method: "dispatch_order",
        target_point: this.record.point || this.record.pointname,
      },
    };
  },
  watch: {
    Account(value) {
      this.headers.Authorization = value.token;
    },
    "record.trackingnumbers"(v) {
      if (v.length) {
        let min = 1;
        this.record.trackingnumbers.forEach((pck) => {
          let minsize = Math.min(pck.boxlength, pck.boxheight, pck.boxheight);
          if (min < minsize) min = minsize;
        });
        if (min <= 41) this.PackagingType = "large";
        if (min <= 19) this.PackagingType = "medium";
        if (min <= 8) this.PackagingType = "small";
      }
    },
  },
  async created() {
    this.record.location = await getRecord(
      this,
      this.record.location._id,
      this.record.location.collection,
      this.record.location.recordtype
    );
    if (
      this.record.shippingmethod &&
      this.record.shippingmethod.shippingcarrier
    ) {
      this.headers.Authorization = this.Account.token;
    }
    this.ReferenceNumber = this.record.name;

    if (this.record.email && this.record.email.includes("allegro")) {
      if (this.record.point || this.record.pointname) {
        this.Service = {
          Code: "inpost_locker_allegro",
          Description: "Przesyłka Paczkomat® - Allegro Paczkomat® InPost",
          multiplePackages: false,
        };
      } else {
        this.Service = {
          Code: "inpost_courier_allegro",
          Description: "Przesyłka kurierska - Allegro Kurier24 InPost.",
          multiplePackages: true,
        };
      }
    } else {
      if (this.record.point || this.record.pointname) {
        this.Service = {
          Code: "inpost_locker_standard",
          Description: "Przesyłka Paczkomat® - standardowa",
          multiplePackages: false,
        };
      }
    }
    if (
      this.record.recordtype == "salesorder" &&
      this.record.shippingmethod &&
      this.record.shippingmethod.codrequired
    ) {
      this.record.codamount = this.record.grossamount;
    }
  },
  mounted() {
    //window.addEventListener("message", this.receiveMessage);
  },
  computed: {
    shipped: function () {
      return (
        !this.record.trackingnumbers.length ||
        this.record.trackingnumbers.some((tracking) => {
          return tracking.name;
        })
      );
    },
    arranged: function () {
      return (
        !this.record.trackingnumbers.length ||
        this.record.trackingnumbers.every((tracking) => {
          return tracking.shipment_id;
        })
      );
    },
    Shipment: function () {
      let reciver = { ...this.receiver };
      let custom_attributes = { ...this.custom_attributes };
      // jeżeli paczkomat
      if (!this.Service.multiplePackages) {
        delete reciver.address;
      } else {
        delete custom_attributes.target_point;
      }
      return {
        service: this.Service.Code,
        sender: {
          company_name: this.record.location.shippingaddress.name,
          name: this.record.location.shippingaddress.addressee,
          email: this.record.company.email,
          phone: "538 337 688", //this.record.location.shippingaddress.phone,
          address: {
            street: `${this.record.location.shippingaddress.address} ${
              this.record.location.shippingaddress.address2 || ""
            }`,
            building_number: "46-48",
            city: this.record.location.shippingaddress.city,
            post_code: this.record.location.shippingaddress.zip,
            country_code: this.record.location.shippingaddress.country,
          },
        },
        receiver: reciver,
        custom_attributes: custom_attributes,
        parcels: [],
      };
    },
  },
  methods: {
    async print(shipment, label, format) {
      for (let shipment_id of shipment) {
        if (shipment_id) {
          this.getStatus(shipment_id);
          await fetch(
            `https://api-shipx-pl.easypack24.net/v1/shipments/${shipment_id}/label?format=${this.LabelImageFormat.Code}&type=A6`,
            {
              method: "GET",
              headers: this.headers,
            }
          ).then(async (response) => {
            let responseData = {};
            responseData = await response.blob();
            let label = await responseData.text();

            label = label.replace(/\nf100\r/g, "");
            label = label.replace(/\nOD\r/g, "");
            if (response.ok) {
              if (["Zpl", "Epl"].includes(this.LabelImageFormat.Code)) {
                let myWindow = window.open(
                  "http://127.0.0.1:4349/listPrinters?loc=pl_PL&app=www&name=labelWindow",
                  "myWindow",
                  "width=480, height=360"
                );
                setTimeout(function () {
                  myWindow.postMessage(
                    window.btoa(unescape(encodeURIComponent(label))),
                    "*"
                  );
                }, 1000);

                setTimeout(function () {
                  myWindow.close();
                }, 5000);
                // var myHeaders = new Headers();
                // myHeaders.append(
                //   "Content-Type",
                //   "application/x-www-form-urlencoded; charset=UTF-8"
                // );
                // let request = {
                //   Interface: "USB",
                //   USB: "",
                //   COM: "",
                //   BaudRate: "",
                //   LPT: "",
                //   IP: "",
                //   Port: "",
                //   Data: "",
                // };
                // request.Data = window.btoa(unescape(encodeURIComponent(label)));
                // console.log(request);
                // const formData = new URLSearchParams();
                // formData.append(
                //   "EncodeData",
                //   window.btoa(unescape(encodeURIComponent(JSON.stringify(request))))
                // );

                // await fetch(`http://localhost:8087/Send/`, {
                //   method: "POST",
                //   headers: myHeaders,
                //   body: formData,
                // });
              } else {
                var fileURL = URL.createObjectURL(responseData);

                window.open(fileURL);

                let tracking_number = this.record.trackingnumbers.find(
                  (number) => number.shipment_id == shipment_id
                );
                if (tracking_number) {
                  var aDom = document.createElement("a");
                  aDom.setAttribute("style", "display:none");
                  aDom.setAttribute("href", fileURL);
                  aDom.setAttribute("download", `${tracking_number.name}.pdf`);
                  document.body.appendChild(aDom);
                  aDom.click();
                  URL.revokeObjectURL(fileURL);
                  document.body.removeChild(aDom);
                }
              }

              return responseData;
            } else {
              console.log("nieok", responseData);
              throw responseData;
            }
          });
        }
      }
    },
    async receiveMessage(event) {
      console.log(event);
    },
    async getStatus(shipment) {
      return await fetch(
        `https://api-shipx-pl.easypack24.net/v1/shipments/${shipment}`,
        {
          method: "GET",
          headers: this.headers,
        }
      ).then(async (response) => {
        let responseData = {};
        responseData = await response.json();
        if (!this.Service.multiplePackages) {
          let tracking_number = this.record.trackingnumbers.find(
            (number) => number.shipment_id == shipment
          );
          if (tracking_number && !tracking_number.name)
            tracking_number.name = responseData.tracking_number;
        } else {
          responseData.parcels.forEach((parcel) => {
            let tracking_number = this.record.trackingnumbers.find(
              (number) => number.parcel_id == parcel.id
            );
            if (tracking_number && !tracking_number.name)
              tracking_number.name = parcel.tracking_number;
          });
        }
      });
    },
    async dispatchOrders(shipment) {
      try {
        this.loading = true;
        let request = {
          shipments: shipment,
          comment: "zlecenia odbioru",
          name: `Odbiór ${this.record.name}`,
          phone: this.record.location.shippingaddress.phone,
          email: this.record.company.email,

          address: {
            street: `${this.record.location.shippingaddress.address} ${
              this.record.location.shippingaddress.address2 || ""
            }`,
            building_number: "46-48",
            city: this.record.location.shippingaddress.city,
            post_code: this.record.location.shippingaddress.zip,
            country_code: this.record.location.shippingaddress.country,
          },
        };
        return await fetch(
          `https://api-shipx-pl.easypack24.net/v1/organizations/${this.Account._id}/dispatch_orders`,
          {
            method: "POST",
            headers: this.headers,
            body: JSON.stringify(request),
          }
        ).then(async (response) => {
          let responseData = {};
          responseData = await response.json();

          if (response.ok) {
            console.log(responseData);
            responseData.shipments.forEach((tracking_number, index) => {
              this.record.trackingnumbers[index].name =
                tracking_number.tracking_number;
            });

            this.loading = false;
            return responseData;
          } else {
            console.log("nieok");
            throw responseData;
          }
        });
      } catch (error) {
        console.log("error", error);
        this.warning = error.message;
        this.warning += JSON.stringify(error.details);
      }
      this.loading = false;
    },
    async shipment() {
      var response = confirm(
        `Arrange Shipment for ${this.record["trackingnumbers"].length} Packages - Are you sure?`
      );
      if (
        response &&
        (!this.record.codamount || this.record["trackingnumbers"].length == 1)
      ) {
        try {
          this.Shipment.parcels = [];
          let index = 0;
          for (let number of this.record["trackingnumbers"]) {
            if (!number.shipment_id) {
              // jeżeli paczkomat
              if (!this.Service.multiplePackages) this.Shipment.parcels = [];
              this.warning = "";
              this.loading = true;

              let pck = {
                id: index,
                dimensions: {
                  length: `${number.boxlength * 10 || 100}` || "100",
                  width: `${number.boxwidth * 10 || 100}` || "100",
                  height: `${number.boxheight * 10 || 100}` || "100",
                  unit: "mm",
                },
                weight: {
                  amount: number.weight,
                  unit: "kg",
                },
                is_non_standard: false,
              };
              // jeżeli paczkomaty
              if (!this.Service.multiplePackages) {
                pck.id = this.PackagingType;
                pck.template = this.PackagingType;
              }
              this.Shipment.parcels.push(pck);

              if (this.record.codamount) {
                this.Shipment["cod"] = {
                  amount: this.record.codamount,
                  currency: "PLN",
                };
                this.Shipment["insurance"] = {
                  amount: this.record.codamount,
                  currency: "PLN",
                };
              }
              // jeżeli paczkomaty
              if (!this.Service.multiplePackages) {
                let request = { ...this.Shipment };
                //console.log(request);
                await fetch(
                  `https://api-shipx-pl.easypack24.net/v1/organizations/${this.Account._id}/shipments`,
                  {
                    method: "POST",
                    headers: this.headers,
                    body: JSON.stringify(request),
                  }
                ).then(async (response) => {
                  let responseData = {};
                  responseData = await response.json();

                  if (response.ok) {
                    //console.log(responseData);

                    number.name = responseData.tracking_number;
                    number.shipment_id = responseData.id;
                    number.parcel_id =
                      responseData.parcels && responseData.parcels[0]
                        ? responseData.parcels[0].id
                        : null;
                    //this.print(responseData.id);

                    this.loading = false;
                    return responseData;
                  } else {
                    console.log("nieok");
                    throw responseData;
                  }
                });
              }
            } else console.log(number);
            index++;
          }
          if (this.Service.multiplePackages) {
            let request = { ...this.Shipment };
            //console.log(request);
            await fetch(
              `https://api-shipx-pl.easypack24.net/v1/organizations/${this.Account._id}/shipments`,
              {
                method: "POST",
                headers: this.headers,
                body: JSON.stringify(request),
              }
            ).then(async (response) => {
              let responseData = {};
              responseData = await response.json();

              if (response.ok) {
                console.log(responseData);

                for (let number in this.record["trackingnumbers"]) {
                  if (!number.shipment_id) {
                    console.log(number, responseData.id);
                    this.record["trackingnumbers"][number].name =
                      responseData.tracking_number;
                    this.record["trackingnumbers"][number].shipment_id =
                      responseData.id;
                    this.record["trackingnumbers"][number].parcel_id =
                      responseData.parcels && responseData.parcels[number]
                        ? responseData.parcels[number].id
                        : null;
                  }
                }

                //this.print(responseData.id);

                this.loading = false;
                return responseData;
              } else {
                console.log("nieok");
                throw responseData;
              }
            });
          }

          // automatycznie zlecenie odbioru
          // this.dispatchOrders(
          //   this.record.trackingnumbers
          //     .filter((tracking) => tracking.shipment_id)
          //     .map((tracking) => tracking.shipment_id)
          // );
        } catch (error) {
          console.log("error", error);
          this.warning = error.message;
          this.warning += JSON.stringify(error.details);
          this.loading = false;
        }
      } else {
        if (this.record.codamount || this.record["trackingnumbers"].length > 1)
          alert("WYSYŁKA POBRANIOWA, nie można rozbić na kilka paczek");
      }
      this.loading = false;
    },
  },
};
</script>>
<template>
  <div>
    <queryresult v-if="!$route.params.mode || $route.params.mode == 'view'" :queryid="queryid" />
    <creator v-else />
  </div>
</template>

<script>
import creator from "./edit-create/View";
/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
      queryid: this.$route.params.id || "query"
    };
  },
  watch: {
    "$route.params": function(params) {
      this.queryid = params.id || params.recordtype || "query";
    }
  },
  components: {
    creator
  }
};
</script>
 
import service from "../../api/index";
/* eslint-disable */
export default async (context, rid, collection, recordtype) => {
  const sure = confirm("Are you sure you want to delete this record?");
  if (sure) {
    let data = {
      action: "deleteRecord",
      rid: rid
    };
    try {
      let resposne = await service(collection, data);
      
      context.msg = { message: `${resposne.message}`, show: true };
      context.$router.push({
        path: `/${collection}/${recordtype}/`
      });
    } catch (error) {
      console.log(error);
      context.msg = { message: `${error.message || error.errmsg}`, show: true };
    }
  }
};

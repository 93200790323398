 <template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          loading-text="Loading... Please wait"
          item-key="_id"
          :search="search"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
        >
          <template v-slot:item.date="{ item }">
            <span>{{ new Date(item.date).toISOString().substr(0, 10) }}</span>
          </template>
          <template v-slot:item.transaction="{ item }">
            <router-link
              :to="{
                path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
              }"
              class="link"
              >{{
                item.transaction.displayname || item.transaction.name
              }}</router-link
            >
          </template>
          <template v-slot:top>
            <v-divider></v-divider>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col>
                  <exportData :headers="headers" :items="items" />
                </v-col>
                <v-col>
                  <v-btn @click="pdf()" block tile color="primary">PDF</v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="text-right">
                  <v-btn icon @click="show = !show">
                    <v-icon>{{
                      show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-expand-transition>
                <div v-show="show">
                  <v-divider></v-divider>
                  <v-row>
                    <v-col>
                      <v-select
                        :items="currencies"
                        v-model="currency"
                        label="Currency"
                        item-text="name"
                        item-value="_id"
                        outlined
                        dense
                      ></v-select>
                      <v-btn @click="getData()" block tile color="primary"
                        >Generate</v-btn
                      >
                    </v-col>
                    <v-col>
                      <v-select
                        :items="companies"
                        v-model="company"
                        label="Company"
                        item-text="name"
                        item-value="_id"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <!-- <v-col>
                      <v-select
                        :items="locations"
                        v-model="location"
                        label="Location"
                        item-text="name"
                        item-value="_id"
                        outlined
                        dense
                      ></v-select>
                    </v-col> -->
                    <v-col>
                      <v-menu
                        v-model="menuto"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            label="Date to"
                            placeholder
                            v-model="dateto"
                            append-icon="event"
                            readonly
                            v-on="on"
                            outlined
                            dense
                            hide-details
                            clearable
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="dateto"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="pdfforms"
                        v-model="pdfform"
                        label="PDF Form"
                        item-text="name"
                        item-value="_id"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col>
                      <!-- <v-switch v-model="open" label="Only Open"></v-switch> -->
                    </v-col>
                  </v-row>
                </div>
              </v-expand-transition>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";
export default {
  props: ["customer"],
  data() {
    return {
      loading: false,
      headers: [
        { text: "Location", value: "location", sortable: false },
        { text: "Date", value: "date", sortable: false },
        { text: "Type", value: "type", sortable: false },
        { text: "Number", value: "transaction", sortable: false },
        { text: "Due Date", value: "duedate", sortable: false },
        { text: "Gross Amount", value: "grossamount", sortable: false },
        { text: "Currency", value: "currency", sortable: false },
        { text: "Charges", value: "charges", sortable: false },
        { text: "Payment", value: "payment", sortable: false },
        { text: "Amount", value: "amount", sortable: false },
        { text: "Remaining", value: "amountdue", sortable: false },
        { text: "Balance", value: "balance", sortable: false },
      ],
      items: [],
      rows: [10],
      currencies: [],
      currency: null,
      locations: [],
      location: null,
      companies: [],
      company: null,
      search: "",
      show: true,
      currency: "eur",
      open: true,
      dateto: null,
      menuto: false,
      pdfforms: [],
      pdfform: null,
    };
  },
  async created() {
    this.loading = true;

    this.currencies = service("accounting", {
      action: "find",
      data: [{ recordtype: "currency" }],
    });
    this.locations = service("accounting", {
      action: "find",
      data: [{ recordtype: "location" }],
    });
    this.companies = service("users", {
      action: "find",
      data: [{ recordtype: "company" }],
    });
    this.pdfforms = service("forms", {
      action: "find",
      data: [{ recordtype: "pdfform", "setup.recordtype": "customer" }],
    });
    const [currencies, locations, pdfforms, companies] = await Promise.all([
      this.currencies,
      this.locations,
      this.pdfforms,
      this.companies,
    ]);
    this.currencies = currencies;
    this.currency = this.customer.currency ? this.customer.currency._id : null;
    this.locations = locations;
    this.pdfforms = pdfforms;
    this.companies = companies;
    this.company = this.customer.location
      ? this.customer.location.company
      : null;
    this.loading = false;
  },
  watch: {
    // lists: {
    //   handler: async function(after, before) {},
    //   deep: true
    // }
  },
  methods: {
    filter(value, search, item) {
      return filter(value, search, item);
    },
    async pdf() {
      this.loading = true;
      let pdf = await service("users", {
        action: "printTransactions",
        rid: this.customer._id,
        pdfform: this.pdfform,
        options: {
          open: this.open,
          dateto: this.dateto,
          currency: this.currency,
          company: this.company,
        },
      });
      var file = pdf;

      var fileURL = URL.createObjectURL(file);
      console.log(fileURL);
      //this.files.push(fileURL);
      window.open(fileURL);
      this.loading = false;
    },
    async getData() {
      this.loading = true;
      this.items = [];
      let newresult = await service(
        "financial",
        {
          action: "getStatement",
          customer: this.customer._id,
          currency: this.currency,
          location: this.location,
          company: this.company,
          dateto: this.dateto,
          open: this.open,
        },
        false,
        false
      );
      //console.log(newresult);
      this.items = newresult.items;
      this.loading = false;
    },
  },
  components: {
    exportData,
  },
};
</script>
<template>
  <div
    v-if="
      $store.state.user && $store.state.user.role._id != 'role_warehouse_uk' && access($route.params.mode)
    "
  >
    <queryresult
      v-if="!$route.params.mode"
      :queryid="$route.params.recordtype"
    />
    <record collection="items" v-if="$route.params.mode" />
  </div>
  <v-alert v-else type="error">Access Denied</v-alert>
</template>

<script>
/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    access(mode) {
      if (
        this.$store.state.user._id == "5e22ea444bd8da318844a0a1" &&
        ["edit"].includes(mode)
      )
        return false;
      else return true;
    },
  },
};
</script>
<template>
  <div
    v-if="
      ($store.state.user &&
        $store.state.user.role._id != 'role_warehouse_uk') ||
      $route.params.recordtype == 'deliverycheck'
    "
  >
    <queryresult
      v-if="!$route.params.mode"
      :queryid="$route.params.recordtype"
    />
    <record collection="deliveries" v-else />
  </div>
  <v-alert v-else type="error">Access Denied</v-alert>
</template>
<script>
/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
    };
  },
};
</script>
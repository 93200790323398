<template>
  <div>
    <v-stepper v-model="e1">
      <v-stepper-header>
        <template v-for="(tab, index) in form.tabs">
          <v-stepper-step
            :key="`${index}-step`"
            :complete="e1 > index + 1"
            :step="index + 1"
            editable
          >{{ tab.name }}</v-stepper-step>

          <v-divider :key="index + 1"></v-divider>
        </template>
        <v-btn
          fab
          dark
          small
          right
          color="primary"
          style="top: 8px;"
          @click="newTab(form.tabs.length);"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content
          v-for="(tab, index) in form.tabs"
          :key="`${index}-content`"
          :step="index + 1"
        >
          <sections :tab="tab" :tabindex="index"/>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import sections from './sections';
/* eslint-disable */
export default {
  props: ["form"],
  data() {
    return {
      e1: 1,
      steps: 2
    };
  },
  components: {
    sections
  },
  watch: {
    form: {
      handler: function(after, before) {
        this.form.tabs.forEach((tab, index) => {
          if (!tab.sections && !tab.table) {
            if (this.form.tabs.length > 1) this.$delete(this.form.tabs, index);
          } // else console.log(tab);
        });
      },
      deep: true
    }
  },
  methods: {
    newTab(i) {
      if (i == 0)
        this.form.tabs.unshift({
          name: "Tab Name",
          table: { query: "", filters: "" },
          sections: [{ name: "Section Label", fields: [] }]
        });
      else
        this.form.tabs.splice(i, 0, {
          name: "Tab Name",
          table: { query: "", filters: "" },
          sections: [{ name: "Section Label", fields: [] }]
        });
    }
  }
};
</script>

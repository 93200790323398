var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-tabs',{attrs:{"dark":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_vm._l((Object.values(_vm.tabs).filter(
            function (tab) { return this$1.locationsFilter.includes(tab.location) ||
              !this$1.locationsFilter.length; }
          )),function(tab,index){return _c('v-tab',{key:index},[_c('v-badge',{attrs:{"color":"primary"}},[_c('span',[_vm._v(_vm._s(tab.name))]),_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_vm._v(_vm._s(tab.items.length))])])],1)}),_vm._l((Object.values(_vm.tabs).filter(
            function (tab) { return this$1.locationsFilter.includes(tab.location) ||
              !this$1.locationsFilter.length; }
          )),function(tab,index){return _c('v-tab-item',{key:index},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":tab.items.filter(function (item) {
                return (
                  (_vm.fleetorder ? !!item.fleetorder : !item.fleetorder) &&
                  (_vm.dropship ? !!item.dropship : !item.dropship)
                );
              }),"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","show-select":"","search":_vm.search,"single-select":_vm.singleSelect,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter,"item-class":_vm.rowClass},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.date).toISOString().substr(0, 10)))])]}},{key:"item.transaction",fn:function(ref){
              var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                  path: ("/" + (item.transaction.collection) + "/" + (item.transaction.recordtype) + "/view/" + (item.transaction._id)),
                }}},[_vm._v(_vm._s(item.transaction.displayname || item.transaction.name))])]}},{key:"item.customer",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                  path: ("/" + (item.customer.collection) + "/" + (item.customer.recordtype) + "/view/" + (item.customer._id)),
                }}},[_vm._v(_vm._s(item.customer.displayname || item.customer.name))])]}},{key:"item.item",fn:function(ref){
                var item = ref.item;
return [_c('span',[_c('router-link',{staticClass:"link",attrs:{"to":{
                    path: ("/" + (item.item.collection) + "/" + (item.item.recordtype) + "/view/" + (item.item._id)),
                  }}},[_vm._v(_vm._s(item.item.displayname || item.item.name))]),_c('br'),_vm._v(" "+_vm._s(item.item.custombox ? "no A/F" : "")+_vm._s(item.item.tobuild ? "Item To Build" : "")+" ")],1)]}},{key:"item.additionalservice",fn:function(ref){
                  var item = ref.item;
return _vm._l((item.additionalservice),function(service){return _c('span',{key:service._id},[_vm._v(_vm._s(service.displayname || service.name)+" "),_c('br')])})}},{key:"item.manufacturer",fn:function(ref){
                  var item = ref.item;
return [_c('small',[_vm._v(_vm._s(item.manufacturer)+" "),_c('br')])]}},{key:"item.dropship",fn:function(ref){
                  var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.dropship ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.dropship ? "Yes" : "No"))])]}},{key:"item.fleetorder",fn:function(ref){
                  var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.fleetorder ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.fleetorder ? "Yes" : "No"))])]}},{key:"item.destination",fn:function(ref){
                  var item = ref.item;
return _vm._l((item.destination),function(loc){return _c('v-tooltip',{key:loc.location,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [(item.destination)?_c('v-chip',_vm._g({attrs:{"small":"","label":"","color":item.destination &&
                      (loc.quantityavailable || 0) +
                        ((loc.quantityonorder || 0) +
                          (loc.quantityonspecialorder || 0)) -
                        (loc.quantitybackorder || 0)
                        ? 'green'
                        : 'grey',"dark":""}},on),[_vm._v(_vm._s((loc.quantityavailable || 0) + ((loc.quantityonorder || 0) + (loc.quantityonspecialorder || 0)) - (loc.quantitybackorder || 0)))]):_vm._e()]}}],null,true)},[(item.destination)?_c('pre',{staticClass:"text-caption"},[_vm._v(_vm._s({
                      Location: loc.location || "",
                      Available: loc.quantityavailable || 0,
                      OnOrder:
                        (loc.quantityonorder || 0) +
                        (loc.quantityonspecialorder || 0),
                      BO: loc.quantitybackorder || 0,
                    }))]):_vm._e()])})}},{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('exportData',{attrs:{"headers":_vm.headers,"items":_vm.items}})],1),_c('v-col',[_c('v-switch',{attrs:{"label":"Only Open"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}})],1),_c('v-col',[_c('v-switch',{attrs:{"label":"Fleet Order"},model:{value:(_vm.fleetorder),callback:function ($$v) {_vm.fleetorder=$$v},expression:"fleetorder"}})],1),_c('v-col',[_c('v-switch',{attrs:{"label":"Drop Ship"},model:{value:(_vm.dropship),callback:function ($$v) {_vm.dropship=$$v},expression:"dropship"}})],1),_c('v-col',[_c('v-btn',{staticClass:"mt-2 mb-2",attrs:{"block":"","tile":"","color":"primary"},on:{"click":function($event){return _vm.getData()}}},[_vm._v("Refresh")])],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('v-icon',[_vm._v(_vm._s(_vm.show ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1)],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}]},[_c('v-divider'),(!_vm.loading)?_c('toolfooter',{attrs:{"items":_vm.items,"selected":_vm.selected,"dropship":_vm.dropship,"fleetorder":_vm.fleetorder,"field":"openbackorder"}}):_vm._e()],1)])],1)]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
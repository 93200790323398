<template>
  <v-btn
    tile
    :loading="loading"
    :disabled="record.receiptprinted || printed"
    class="mt-4"
    color="primary"
    @click="print"
    >{{ receiptnumber || record.receiptnumber || "Print Paragon" }}</v-btn
  >
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
export default {
  props: ["record"],
  data() {
    return {
      loading: false,
      printed: false,
      receiptnumber: null,
    };
  },
  watch: {},
  async created() {},
  mounted() {
    //window.addEventListener("message", this.receiveMessage);
  },
  computed: {},
  methods: {
    async print() {
      this.loading = true;
      try {
        let grossamount = this.record.grossamount * this.record.exchangerate;
        let vm = this;
        const http = new XMLHttpRequest();
        const url = "http://127.0.0.1:3050/paragon";

        //prepayment
        let prepaymentamount = 0;
        if (this.record["prepayments"] && this.record["prepayments"].length) {
          this.record["prepayments"].forEach((p) => {
            prepaymentamount += p.grossamount * p.exchangerate;
          });
        }
        grossamount+=prepaymentamount;

        let params = {
          // lines: [
          //   { na: "Towar 1", il: 1.0, vt: 0, pr: 12356 },
          //   { na: "Towar 2", il: 1.0, vt: 0, pr: 34567 },
          // ],
          // taxidinfo: {
          //   ni: "584-222-98-89",
          //   dw: true,
          //   ds: "NIP NABYWCY",
          // },
          summary: {
            to: parseInt(Math.round(grossamount * 100)),
          },
          extralines: [{ id: 39, na: this.record.name }],
        };
        let tmp = 0;
        let tmpTax = { taxpct: 0.23 };

  

        let discount = this.record["transactionlines"].reduce((t, v) => {
          if (v.grossamount < 0) t += v.grossamount * v.exchangerate;
          return t;
        }, 0);
        params.lines = [];
        this.record["transactionlines"].forEach((line) => {
          let linegrossamount = line.grossamount * line.exchangerate;
        
          if (linegrossamount > 0) {
            // if(prepaymentamount){
            //   if (linegrossamount > Math.abs(discount)) {
            //     linegrossamount += prepaymentamount;
            //     prepaymentamount = 0;
            //   } else {
            //     prepaymentamount += linegrossamount;
            //     linegrossamount = 0;
            //   }
            // }
   
            if (discount) {
              if (linegrossamount > Math.abs(discount)) {
                linegrossamount += discount;
                discount = 0;
              } else {
                discount += linegrossamount;
                linegrossamount = 0;
              }
            }

            let grossprice = Number(
              (linegrossamount / line.quantity).toFixed(2)
            );
            tmp += parseInt(Math.round(grossprice * 100)) * line.quantity;
            if (grossprice) {
              grossprice = parseInt(Math.round(grossprice * 100));
              params.lines.push({
                na: line.displayname,
                il: line.quantity,
                vtp: (line.tax.taxpct * 100).toFixed(2).replace(".", ","),
                pr: grossprice,
              });
            }
          }
        });
        if (this.record["transactionshippinglines"]) {
          this.record["transactionshippinglines"].forEach((line) => {
            let linegrossamount = line.grossamount * line.exchangerate;
            let grossprice = Number(
              (linegrossamount / line.quantity).toFixed(2)
            );
            tmp += grossprice * 100 * line.quantity;
            if (grossprice)
              params.lines.push({
                na: line.displayname,
                il: line.quantity,

                vtp: (line.tax.taxpct * 100).toFixed(2).replace(".", ","),
                pr: parseInt(Math.round(grossprice * 100)),
              });
          });
        }
        if (params.summary.to != tmp) {
          if (
            this.record["transactionshippinglines"] &&
            this.record["transactionshippinglines"].length &&
            this.record["transactionshippinglines"][0].amount
          ) {
            if (
              params.lines[params.lines.length - 1].pr >=
              params.summary.to - tmp
            ) {
              params.lines[params.lines.length - 1].pr +=
                params.summary.to - tmp;
            } else {
              let q = Math.ceil(
                Math.abs(params.summary.to - tmp) /
                  params.lines[params.lines.length - 2].il
              );
              let buffer = q * params.lines[params.lines.length - 2].il;
              params.lines[params.lines.length - 2].pr -= buffer;
              params.lines[params.lines.length - 1].pr +=
                buffer + (params.summary.to - tmp);
            }
          } else {
            if (params.summary.to - tmp < 0) {
              let q = Math.ceil(
                Math.abs(params.summary.to - tmp) /
                  params.lines[params.lines.length - 1].il
              );

              let buffer = q; // * params.lines[params.lines.length - 1].il;
              params.lines[params.lines.length - 1].pr -= buffer;
              tmp -= params.lines[params.lines.length - 1].il * buffer;
            }
            if (params.summary.to - tmp > 0) {
              params.lines.push({
                na: "Transport",
                il: 1,
                vtp: (tmpTax.taxpct * 100).toFixed(2).replace(".", ","),
                pr: parseInt(params.summary.to - tmp),
              });
            }
          }
        }
        console.log(params);
        //console.log(params.summary.to, tmp, params.summary.to - tmp);
        await http.open("POST", url, true);

        //Send the proper header information along with the request
        http.setRequestHeader("Content-type", "application/json");

        http.onreadystatechange = function () {
          //Call a function when the state changes.

          if (http.readyState == 4 && http.status == 200) {
            let res = JSON.parse(http.responseText);
            let data = {
              action: "updateOne",
              data: [
                { _id: vm.record._id },
                {
                  $set: {
                    receiptnumber: res.hn,
                    receiptprinted: true,
                  },
                },
              ],
            };

            service("transactions", data);
            vm.record.receiptnumber = res.hn;
            vm.record.receiptprinted = true;
            vm.printed = true;
            vm.receiptnumber = res.hn;
            vm.loading = false;
          }
        };
        http.send(JSON.stringify(params));
      } catch (err) {
        alert(err);
        this.loading = false;
      }
    },
  },
};
</script>>
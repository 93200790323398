var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-tabs',{attrs:{"dark":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_vm._l((_vm.locations),function(location,index){return _c('v-tab',{key:index},[_c('v-badge',{attrs:{"color":"primary"}},[_c('span',[_vm._v("Warehouse "+_vm._s(location.name))]),_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_vm._v(_vm._s(_vm.items.filter(function (item) { return item.location._id == location._id; }).length))])])],1)}),_vm._l((_vm.locations),function(location,index){return _c('v-tab-item',{key:index},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items.filter(function (item) { return item.location._id == location._id; }),"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","search":_vm.search,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter},scopedSlots:_vm._u([{key:"item.location",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                  path: ("/" + (item.location.collection) + "/" + (item.location.recordtype) + "/view/" + (item.location._id)),
                }}},[_vm._v(_vm._s(item.location.displayname || item.location.name))])]}},{key:"item.delivery",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                  path: ("/" + (item.delivery.collection) + "/" + (item.delivery.recordtype) + "/view/" + (item.delivery._id)),
                }}},[_vm._v(_vm._s(item.delivery.displayname || item.delivery.name))])]}},{key:"item.vendor",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                  path: ("/" + (item.vendor.collection) + "/" + (item.vendor.recordtype) + "/view/" + (item.vendor._id)),
                }}},[_vm._v(_vm._s(item.vendor.displayname || item.vendor.name))])]}},{key:"item.item",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                  path: ("/" + (item.item.collection) + "/" + (item.item.recordtype) + "/view/" + (item.item._id)),
                }}},[_vm._v(_vm._s(item.item.displayname || item.item.name))])]}},{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col')],1)],1)]},proxy:true}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',_vm._l((_vm.headers),function(col,index){return _c('th',{key:index},[_vm._v(" "+_vm._s(col.summary ? _vm.calcTotal( col.value, _vm.items.filter( function (item) { return item.location._id == location._id; } ) ) : "")+" ")])}),0)])],2)],1)})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
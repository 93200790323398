var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(new Date(item.date).toISOString().substr(0, 10)))]),(item.enddate)?_c('small',{class:new Date(item.enddate) < new Date() ? 'overude' : ''},[_vm._v(_vm._s(new Date(item.enddate).toISOString().substr(0, 10)))]):_vm._e()]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [_c('pre',[_vm._v(_vm._s(item.note))]),(item.taskstatustext)?_c('v-chip',{staticClass:"mt-2",attrs:{"x-small":"","label":"","color":item.color}},[_vm._v(_vm._s(item.taskstatustext || ""))]):_vm._e()]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.eventtypetext || item.typename))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.create(item.recordtype, 'view', item._id)}}},[_vm._v("launch")]),_vm._v("| "),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.create(item.recordtype, 'edit', item._id)}}},[_vm._v("edit")])]}},{key:"footer",fn:function(){return [_c('v-divider'),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col',[_c('v-btn',{attrs:{"block":"","tile":"","color":"primary"},on:{"click":function($event){return _vm.create('note')}}},[_vm._v(" New Note "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-notebook")])],1)],1),_c('v-col',[_c('v-btn',{attrs:{"block":"","tile":"","color":"primary"},on:{"click":function($event){return _vm.create('task')}}},[_vm._v(" New Task "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("event_note")])],1)],1),_c('v-col',[_c('v-btn',{attrs:{"block":"","tile":"","color":"primary"},on:{"click":function($event){return _vm.create('event')}}},[_vm._v(" New Event "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("event")])],1)],1)],1)],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
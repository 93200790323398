 <template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-data-table
          :headers="newheaders"
          :items="items"
          :loading="loading"
          loading-text="Loading... Please wait"
          item-key="_id"
          :search="search"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
        >
          <template v-slot:item.detaildescription="{ item }">
            <v-dialog v-model="item.dialog1" mx-width="800px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark v-on="on">Show</v-btn>
              </template>
              <v-card>
                <v-card-text v-html="item.detaildescription"></v-card-text>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:item.application="{ item }">
            <v-dialog v-model="item.dialog2" mx-width="800px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark v-on="on">Show</v-btn>
              </template>
              <v-card>
                <v-card-text v-html="item.application"></v-card-text>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col>
                  <exportData :headers="newheaders" :items="items" />
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="text-right">
                  <v-btn icon @click="show = !show">
                    <v-icon>{{
                      show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-expand-transition>
                <div v-show="show">
                  <v-divider></v-divider>
                  <v-row>
                    <v-col>
                      <v-select
                        :items="currencies"
                        v-model="currency"
                        label="Currency"
                        item-text="name"
                        item-value="_id"
                        outlined
                        dense
                      ></v-select>
                      <v-btn @click="getData()" block tile color="primary"
                        >Generate</v-btn
                      >
                    </v-col>
                    <v-col>
                      <v-select
                        :items="pricegroups"
                        v-model="pricegroup"
                        label="Price Groups"
                        item-text="name"
                        item-value="_id"
                        outlined
                        multiple
                        dense
                      ></v-select>
                    </v-col>
                    <!-- <v-col>
                      <v-select
                        :items="locations"
                        v-model="location"
                        label="Location"
                        item-text="name"
                        item-value="_id"
                        outlined
                        dense
                      ></v-select>
                    </v-col>-->
                  </v-row>
                </div>
              </v-expand-transition>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportDataNEW";
export default {
  props: ["customer"],
  data() {
    return {
      loading: false,
      headers: [
        //{ text: "Hidden", value: "hidden", sortable: false },
        { text: "Item", value: "item", sortable: false },
        { text: "ID", value: "number", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "RRP", value: "baseprice", sortable: false },
        { text: "Weight", value: "weight", sortable: false },
        { text: "Barcode", value: "barcode", sortable: false },
        { text: "Photos", value: "photos", sortable: false },
        { text: "Stock Category", value: "stockcategory", sortable: false },
        { text: "URL", value: "urlcomponent", sortable: false },
        {
          text: "Detail Description",
          value: "detaildescription",
          sortable: false,
        },
        {
          text: "Applications",
          value: "application",
          sortable: false,
        },
        // {
        //   text: "Related Items",
        //   value: "relateditems",
        //   sortable: false,
        // },
      ],
      newheaders: [],
      items: [],
      rows: [10],
      currencies: [],
      currency: null,
      pricegroups: [],
      pricegroup: null,
      locations: [],
      location: null,
      search: "",
      show: true,
      currency: "eur",
    };
  },
  async created() {
    this.loading = true;

    this.currencies = service("accounting", {
      action: "find",
      data: [{ recordtype: "currency" }],
    });
    this.pricegroups = service("accounting", {
      action: "find",
      data: [{ recordtype: "pricegroup" }],
    });
    this.locations = service("accounting", {
      action: "find",
      data: [{ recordtype: "location" }],
    });
    const [currencies, pricegroups, locations] = await Promise.all([
      this.currencies,
      this.pricegroups,
      this.locations,
    ]);
    this.currencies = currencies;
    this.pricegroups = pricegroups;
    this.locations = locations;
    this.loading = false;
  },
  watch: {
    // lists: {
    //   handler: async function(after, before) {},
    //   deep: true
    // }
  },
  methods: {
    filter(value, search, item) {
      return filter(value, search, item);
    },
    async getData() {
      try {
        this.loading = true;
        this.items = [];
        let result = await service(
          "items",
          {
            action: "getItemsData",
            customer: this.customer,
            pricegroup: this.pricegroup,
            currency: this.currency,
            location: this.location,
          },
          false,
          true
        );
        //console.log(result);
        this.items = result.items;
        this.newheaders = [...this.headers];
        result.headers.forEach((label) => {
          this.newheaders.push({ text: label, value: label });
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        alert(error.message);
      }
    },
  },
  components: {
    exportData,
  },
};
</script>
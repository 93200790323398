var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","search":_vm.search,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.date).toISOString().substr(0, 10)))])]}},{key:"item.transaction",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.transaction.collection) + "/" + (item.transaction.recordtype) + "/view/" + (item.transaction._id)),
            }}},[_vm._v(_vm._s(item.transaction.displayname || item.transaction.name))])]}},{key:"top",fn:function(){return [_c('v-divider'),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('exportData',{attrs:{"headers":_vm.headers,"items":_vm.items}})],1),_c('v-col',[_c('v-btn',{attrs:{"block":"","tile":"","color":"primary"},on:{"click":function($event){return _vm.pdf()}}},[_vm._v("PDF")])],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('v-icon',[_vm._v(_vm._s(_vm.show ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1)],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}]},[_c('v-divider'),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.currencies,"label":"Currency","item-text":"name","item-value":"_id","outlined":"","dense":""},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}}),_c('v-btn',{attrs:{"block":"","tile":"","color":"primary"},on:{"click":function($event){return _vm.getData()}}},[_vm._v("Generate")])],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.companies,"label":"Company","item-text":"name","item-value":"_id","outlined":"","dense":""},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Date to","placeholder":"","append-icon":"event","readonly":"","outlined":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.dateto),callback:function ($$v) {_vm.dateto=$$v},expression:"dateto"}},on))]}}]),model:{value:(_vm.menuto),callback:function ($$v) {_vm.menuto=$$v},expression:"menuto"}},[_c('v-date-picker',{model:{value:(_vm.dateto),callback:function ($$v) {_vm.dateto=$$v},expression:"dateto"}})],1)],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.pdfforms,"label":"PDF Form","item-text":"name","item-value":"_id","outlined":"","dense":""},model:{value:(_vm.pdfform),callback:function ($$v) {_vm.pdfform=$$v},expression:"pdfform"}})],1),_c('v-col')],1)],1)])],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
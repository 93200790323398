<template>
  <v-card>
    <v-container fluid grid-list-xl>
      <v-layout row wrap>
        <v-flex md6>
          <v-text-field v-model="field.name" label="Field Name" :rules="[rules.required]">
          </v-text-field>
        </v-flex>

        <v-flex md6>
          <v-select
            v-model="field.setup.type"
            hint="Select Field Type"
            :items="fieldtypes"
            item-text="name"
            item-value="type"
            label="Select"
            persistent-hint
            single-line
            :rules="[rules.required]"
          ></v-select>
          <v-select
            v-if="field.setup.type=='field-select'"
            v-model="field.setup.collection"
            hint="Select Field Type"
            :items="collections"
            item-text="name"
            item-value="type"
            label="Select"
            persistent-hint
            single-line
            :rules="[rules.required]"
          ></v-select>
          <v-textarea
            v-if="field.setup.type=='field-select'"
            :id="`filters`"
            label="Filters Object [JSON]"
            hint="validation"
            :value="JSON.stringify(field.setup.filters, undefined, 4)"
            rows="10"
            @input="prettyJSON(`filters`);"
          ></v-textarea>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
/* eslint-disable */
export default {
  props: ["rid", "mode", "field"],
  data() {
    return {
      fieldtypes: [
        { name: "Text", type: "field-text" },
        { name: "Email", type: "field-email" },
        { name: "Country", type: "field-country" },
        { name: "Date", type: "field-date" },
        { name: "File", type: "field-file" },
        { name: "Switch", type: "field-boolean" },
        { name: "Select", type: "field-select" },
        { name: "TextArea", type: "field-textarea" }
      ],
      collections: [
        { name: "Queries", type: "queries" },
        { name: "Users", type: "users" },
        { name: "Files", type: "files" },
        { name: "Addresses", type: "addresses" }
      ],
      rules: {
        required: value => !!value || "Required."
      }
    };
  },
  methods: {
    prettyJSON(id) {
      try {
        var ugly = document.getElementById(id).value;
        var obj = JSON.parse(ugly);
        console.log(obj);
        this.field.setup.filters = obj;
      } catch (error) {
        // this.stages[i].error = error.message;
      }
    }
  }
};
</script>

<template>
  <v-card tile>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Procurement Tools</v-toolbar-title>
    </v-toolbar>
    <v-tabs dark v-model="active">
      <v-tab :key="1">BackOrder Items</v-tab>
      <!-- <v-tab :key="2">Dropship Orders</v-tab> -->
      <v-tab :key="2">Special BackOrder</v-tab>
      <v-tab :key="3">Fleet Orders</v-tab>
      <v-tab :key="4">Open Purchase Orders</v-tab>
      <v-tab :key="5">PO Planner</v-tab>
      <v-tab :key="6">Open Special Orders</v-tab>
      <v-tab :key="7">PO Assistant</v-tab>
      <v-tab :key="8">Stock Request</v-tab>
      <v-tab :key="9">ETA Requests (BETA)</v-tab>
      <v-tab :key="10"><v-icon>mdi-close-outline</v-icon></v-tab>
      <v-tab-item :key="1">
        <backorderitems v-if="active == 0" />
      </v-tab-item>
      <!-- <v-tab-item :key="2">
        <dropshiporders v-if="active==1" />
      </v-tab-item>-->
      <v-tab-item :key="2">
        <additionalservice v-if="active == 1" />
      </v-tab-item>
      <v-tab-item :key="3">
        <fleetorders v-if="active == 2" />
      </v-tab-item>
      <v-tab-item :key="4">
        <openpurchaseorders v-if="active == 3" />
      </v-tab-item>
      <v-tab-item :key="5">
        <poplanner v-if="active == 4" />
      </v-tab-item>
      <v-tab-item :key="6">
        <openadditionalservice v-if="active == 5" />
      </v-tab-item>
      <v-tab-item :key="7">
        <poassistant v-if="active == 6" />
      </v-tab-item>
      <v-tab-item :key="8">
        <openstockrequest v-if="active == 7" />
      </v-tab-item>
      <v-tab-item :key="9">
        <borequests v-if="active == 8" />
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import backorderitems from "../components/tools/backorderitems/View";
import dropshiporders from "../components/tools/dropshiporders/View";
import fleetorders from "../components/tools/fleetorders/View";
import additionalservice from "../components/tools/additionalservice/View";
import deliveriescalendar from "../components/tools/deliveriescalendar/View";
import openpurchaseorders from "../components/tools/openpurchaseorders/View";
import inventorystatus from "../components/tools/inventorystatus/View";
import intrastat from "../components/tools/intrastat/View";
import poplanner from "../components/tools/poplanner/View";
import openadditionalservice from "../components/tools/openadditionalservice/View";
import poassistant from "../components/tools/poassistant/View";
import abcxyz from "../components/tools/abcxyz/View";
import inventoryvaluation from "../components/tools/inventoryvaluation/View";
import openstockrequest from "../components/tools/openstockrequest/View";
import borequests from "../components/tools/requests/View";
/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
      active: 9,
    };
  },
  components: {
    dropshiporders,
    fleetorders,
    additionalservice,
    deliveriescalendar,
    backorderitems,
    openpurchaseorders,
    inventorystatus,
    intrastat,
    poplanner,
    openadditionalservice,
    poassistant,
    abcxyz,
    inventoryvaluation,
    openstockrequest,
    borequests,
  },
};
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.advanced ? _vm.headersadvanced : _vm.headers,"items":_vm.filtereditems.length ? _vm.filtereditems : _vm.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","search":_vm.search,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter},scopedSlots:_vm._u([{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.customer.collection) + "/" + (item.customer.recordtype) + "/view/" + (item.customer._id)),
            }}},[_vm._v(_vm._s(item.customer.displayname || item.customer.name))])]}},{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('exportData',{attrs:{"headers":_vm.advanced ? _vm.headersadvanced : _vm.headers,"items":_vm.filtereditems.length ? _vm.filtereditems : _vm.items}})],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"mb-2",attrs:{"label":"Date from","placeholder":"","append-icon":"event","readonly":"","outlined":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.datefrom),callback:function ($$v) {_vm.datefrom=$$v},expression:"datefrom"}},on))]}}]),model:{value:(_vm.menufrom),callback:function ($$v) {_vm.menufrom=$$v},expression:"menufrom"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1},on:{"change":function($event){_vm.update = true}},model:{value:(_vm.datefrom),callback:function ($$v) {_vm.datefrom=$$v},expression:"datefrom"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"mt-2 mb-2",attrs:{"label":"Date to","placeholder":"","append-icon":"event","readonly":"","outlined":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.dateto),callback:function ($$v) {_vm.dateto=$$v},expression:"dateto"}},on))]}}]),model:{value:(_vm.menuto),callback:function ($$v) {_vm.menuto=$$v},expression:"menuto"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1},on:{"change":function($event){_vm.update = true}},model:{value:(_vm.dateto),callback:function ($$v) {_vm.dateto=$$v},expression:"dateto"}})],1)],1),_c('v-col',[_c('v-select',{staticClass:"mb-2",attrs:{"items":_vm.companies,"label":"Company","item-text":"name","item-value":"_id","outlined":"","clearable":"","multiple":"","hide-details":"","dense":""},on:{"change":function($event){_vm.update = true}},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}}),_c('v-select',{attrs:{"dense":"","items":_vm.customers,"label":"Vendors","outlined":"","item-text":"name","item-value":"_id","hide-details":"","multiple":"","clearable":""},model:{value:(_vm.customer),callback:function ($$v) {_vm.customer=$$v},expression:"customer"}}),_c('v-btn',{staticClass:"mt-2",attrs:{"block":"","tile":"","color":"primary","disabled":!_vm.update,"loading":_vm.loading},on:{"click":function($event){return _vm.getData()}}},[_vm._v("Refresh")])],1),_c('v-spacer'),_c('v-col',[_c('v-switch',{attrs:{"label":"Vendor Currency"},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}}),_c('v-switch',{attrs:{"label":"Exclude Fleet Orders"},on:{"change":function($event){_vm.update = true}},model:{value:(_vm.excludefleetorder),callback:function ($$v) {_vm.excludefleetorder=$$v},expression:"excludefleetorder"}}),_c('v-switch',{attrs:{"label":"Advanced"},model:{value:(_vm.advanced),callback:function ($$v) {_vm.advanced=$$v},expression:"advanced"}})],1)],1)],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
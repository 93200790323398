// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import Vue from "vue";
import Vuetify from "vuetify";
import "@mdi/font/css/materialdesignicons.css";
import App from "./App";
import router from "./router/index";
import store from "./store/index";
import "vuetify/dist/vuetify.min.css";


import VueVectorMap from 'vuevectormap'

import "jsvectormap/dist/maps/world.js";
import "jsvectormap/dist/css/jsvectormap.css";

Vue.use(VueVectorMap)

import { setupComponents } from "./config/setup-components";
Vue.config.productionTip = false;
Vue.use(Vuetify);
setupComponents(Vue);

/* eslint-disable */
const theme = {
  primary: "#464d69",
  secondary: "#b0bec5",
  accent: "#8c9eff",
  error: "#b71c1c"
};

router.beforeEach((to, from, next) => {
  if (
    ((from.params.mode == "edit" || from.params.mode == "create") &&
      (to.params.id != from.params.id)) || (from.params.tool == "simpleorder" && to.params.mode != "view")
  ) {
    if (!window.confirm("Leave without saving?")) {
      return;
    }
  }
  next();
})
new Vue({
  el: "#app",
  router,
  store,
  components: { App },
  template: "<App/>",
  vuetify: new Vuetify({
    icons: { iconfont: "mdi" },
    theme: {
      themes: {
        light: {
          primary: "#464d69",
          secondary: "#b0bec5",
          accent: "#8c9eff",
          error: "#b71c1c"
        }
      }
    }
  }),
  render: h => h(App)
}).$mount("#app");

 <template>
  <v-container class="mb-12" style="position: relative">
    <v-select
      :items="maps"
      v-model="importmap"
      label="Import Map"
      item-text="name"
      item-value="_id"
      return-object
      outlined
      clearable
      dense
    ></v-select>
    <v-data-table
      :headers="headers"
      :items="settings.map"
      loading-text="Loading... Please wait"
      hide-default-footer
      hide-default-header
      item-key="index"
      :footer-props="{ itemsPerPageOptions: rows }"
    >
      <template v-slot:item.col="{ item }">
        <v-select
          class="mt-4"
          :items="settings.csvheaders"
          v-model="item.col"
          label="CSV Column"
          outlined
          dense
          @change="item.value = null"
        ></v-select>
      </template>
      <template v-slot:item.value="{ item }">
        <div class="mt-4">
          <v-component
            v-if="item.field && !item.col"
            :is="fieldsObj[item.field].setup.type"
            :record="item"
            :value="item.value"
            :fieldSettings="fieldsObj[item.field]"
            :formSettings="{ ...item, show: true, name: 'Value' }"
            @emit="catchEmitFilter($event, item)"
          ></v-component>
        </div>
      </template>
      <template v-slot:item.subrecord="{ item }">
        <v-autocomplete
          class="mt-4"
          :items="subrecords"
          v-model="item.subrecord"
          label="Subrecord"
          item-text="name"
          item-value="field"
          outlined
          :hide-details="item.subrecord ? true : false"
          dense
          clearable
        ></v-autocomplete>
        <v-switch
          v-if="item.subrecord"
          v-model="item.overwrite"
          label="Overiwrite (Uwaga! może wyczyścić rekord)"
        ></v-switch>
      </template>
      <template v-slot:item.field="{ item }">
        <v-autocomplete
          class="mt-4"
          :items="item.subrecord ? columns[item.subrecord] : fields"
          v-model="item.field"
          label="Field"
          item-text="name"
          item-value="field"
          outlined
          dense
          @change="item.value = null"
        ></v-autocomplete>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon @click="delMapLine(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <v-fab-transition>
      <v-btn color="pink" dark absolute bottom right fab @click="addMapLine">
        <v-icon>add</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-container>
</template>
<script>
/* eslint-disable */
import getFields from "../../common/methods/getFields";
import service from "../../api/index";
export default {
  props: ["settings"],
  data() {
    return {
      fields: [],
      fieldsObj: {},
      subrecords: [],
      columns: {},
      maps: [],
      importmap: null,
      headers: [
        { text: "CSV Column", value: "col", sortable: false },
        { text: "Value", value: "value", sortable: false },
        { text: "Subrecords", value: "subrecord", sortable: false },
        { text: "Field", value: "field", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      rows: [100],
    };
  },
  watch: {
    async "settings.recordtype"() {
      await this.getForms(this, this.settings.recordtype);
      this.fieldsObj = await getFields(this, {
        recordtype: this.settings.recordtype,
      });
    },
    importmap(value) {
      this.settings.map = value.setup || this.settings.map;
      this.settings.importmapname = this.importmap.name;
      this.settings.importmap = this.importmap._id;
    },
  },

  async created() {
    this.maps = await service("imports_maps", {
      action: "find",
      data: [],
      limit: -1,
    }).then((response) => {
      return response;
    });
  },
  methods: {
    catchEmitFilter(e, props) {
      props.value = e.value ? e.value._id || e.value : e.value;
      console.log(props);
    },
    async getField(filter) {
      let fields = this.fields;
      if (
        filter.source &&
        fields[filter.source] &&
        fields[filter.source].setup &&
        fields[filter.source].setup.collection
      ) {
        fields = await getFields(this, fields[filter.source]);
      }
      return fields[filter.field];
    },
    delMapLine(item) {
      const index = this.settings.map.indexOf(item);
      confirm("Are you sure you want to delete this line?") &&
        this.settings.map.splice(index, 1);
    },
    addMapLine() {
      let idx = this.settings.map.length;
      if (idx <= 100) {
        this.settings.map.push({
          index: idx,
          value: null,
          subrecord: null,
          field: null,
        });
      } else {
        alert("to many columns");
      }
    },
    async getForms() {
      let form = await service("forms", {
        action: "getForm",
        rid: this.settings.recordtype,
      });
      console.log(form);
      form.tabs.forEach((tab) => {
        tab.sections.forEach((section) => {
          (section.columns || []).forEach((column) => {
            column.forEach((field) => {
              this.fields.push(field);
            });
          });
          (section.fields || []).forEach((field) => {
            this.fields.push(field);
          });
          if (section.sublist) {
            this.subrecords.push({
              field: section.sublist.field,
              name: section.sublist.name,
            });
            (section.sublist.columns || []).forEach((column) => {
              this.columns[section.sublist.field] = this.columns[
                section.sublist.field
              ]
                ? this.columns[section.sublist.field]
                : [{ field: "_id", name: "Uniq. Line ID" }];
              this.columns[section.sublist.field].push(column);
            });
          }
        });
      });
      console.log(this.columns);
    },
    async getFields() {
      if (this.settings.recordtype) {
        let fields = await getFields(this, {
          recordtype: this.settings.recordtype,
        });

        Object.entries(fields).forEach(([key, value]) => {
          if (value.recordtype == "field") this.fields.push(value);
          if (value.recordtype == "subrecords") this.subrecords.push(value);
        });
      }
    },
  },
};
</script>
 <template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          loading-text="Loading... Please wait"
          item-key="_id"
          :search="search"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
          id="openordersstatus"
        >
          <template v-slot:item.salesorder="{ item }">
            <router-link
              :to="{
                path: `/${item.salesorder.collection}/${item.salesorder.recordtype}/view/${item.salesorder._id}`,
              }"
              class="link"
              >{{
                item.salesorder.displayname || item.salesorder.name
              }}</router-link
            >
          </template>
          <template v-slot:item.customer="{ item }">
            <router-link
              :to="{
                path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
              }"
              class="link"
              >{{
                item.customer.displayname || item.customer.name
              }}</router-link
            >
          </template>
          <template v-slot:item.item="{ item }">
            <router-link
              :to="{
                path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`,
              }"
              class="link"
              >{{ item.item.displayname || item.item.name }}</router-link
            >
          </template>
          <template v-slot:item.sendtogether="{ item }">
            <v-chip label :color="item.sendtogether ? 'red' : 'none'" dark>{{
              item.sendtogether ? "Yes" : "No"
            }}</v-chip>
          </template>
          <template v-slot:item.fleetorder="{ item }">
            <v-chip label :color="item.fleetorder ? 'red' : 'none'" dark>{{
              item.fleetorder ? "Yes" : "No"
            }}</v-chip>
          </template>
          <template v-slot:item.dropship="{ item }">
            <v-chip label :color="item.dropship ? 'red' : 'none'" dark>{{
              item.dropship ? "Yes" : "No"
            }}</v-chip>
          </template>
          <template v-slot:item.quantitycommitted="{ item }">
            <v-chip
              label
              :color="item.quantitycommitted ? 'green' : 'none'"
              dark
              >{{ item.quantitycommitted }}</v-chip
            >
          </template>
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col>
                  <exportData :headers="headers" :items="items" />
                </v-col>
                <v-col>
                  <v-btn
                    @click="getData()"
                    block
                    tile
                    color="primary"
                    class="mt-2 mb-2"
                    >Refresh</v-btn
                  >
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="text-right">
                  <v-btn icon @click="show = !show">
                    <v-icon>{{
                      show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-expand-transition>
                <div v-show="show">
                  <v-divider></v-divider>
                  <v-row>
                    <v-col>
                      <v-list>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-subtitle
                              >Committed Amount:</v-list-item-subtitle
                            >
                            <v-list-item-title>{{
                              committedamount
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-subtitle
                              >Backorder Amount:</v-list-item-subtitle
                            >
                            <v-list-item-title>{{
                              backorderamount
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col>
                      <v-list>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-subtitle
                              >Committed Weight:</v-list-item-subtitle
                            >
                            <v-list-item-title
                              >{{ committedweight }} kg</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-subtitle
                              >Backorder Weight:</v-list-item-subtitle
                            >
                            <v-list-item-title
                              >{{ backorderweight }} kg</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </div>
              </v-expand-transition>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";
export default {
  props: ["customer", "item", "customheaders"],
  data() {
    return {
      loading: false,
      headers: [
        { text: "Location", value: "location" },
        // { text: "Customer", value: "customer", sortable: false },
        { text: "Item", value: "item", sortable: false },
        { text: "Qty. Open", value: "quantityopen" },
        { text: "Unit Price", value: "price" },
        { text: "Total Price", value: "amount" },
        { text: "Sales Order", value: "salesorder", sortable: false },
        { text: "Send Together", value: "sendtogether" },
        { text: "PO#", value: "referencenumber", sortable: false },
        { text: "Date of Order", value: "date" },
        { text: "Committed", value: "quantitycommitted" },
        { text: "Service", value: "additionalservice" },
        { text: "ETA", value: "eta" },
        { text: "ETA Memo", value: "etamemo" },
        { text: "Fleet Order", value: "fleetorder" },
        { text: "Dropship", value: "dropship" },
        { text: "Shipping Address", value: "shippingaddress" },
        { text: "Ship Date", value: "planneddateofshipment" },
        { text: "Customer Comment", value: "comment", sortable: false },
        { text: "Internal Comments", value: "memomain" },
        { text: "Payment Status", value: "paymentstatus" },
      ],
      items: [],
      rows: [-1, 100],
      search: "",
      show: true,
      committedamount: 0,
      backorderamount: 0,
      committedweight: 0,
      backorderweight: 0,
      balance: 0,
      weight: 0,
      amount: 0,
    };
  },
  async created() {
    this.loading = true;
    this.headers = this.customheaders || this.headers;
    await this.getData();
    this.loading = false;
    let rows = document.querySelectorAll("#openordersstatus tbody tr");
    this.items.forEach((item, index) => {
      if ((item.fleetorder || item.drophsip) && rows[index]) {
        rows[index].classList.add("specialorder");
      }
      if (item.kitcomponent && rows[index]) {
        rows[index].classList.add("kitcomponent");
      }
    });
  },

  methods: {
    async getData() {
      this.loading = true;
      let result = await service(
        "report",
        {
          action: "getOpenOrdersStatus",
          customer: this.customer,
          pricegroup: ["5e0dbaba9e33df43f0b3a4cc","6399d0fe44c2c4c12435dbee"],
          item: this.item,
        },
        false,
        true
      );
      if (!this.customer && !this.item) {
        this.headers = [
          { text: "Location", value: "location" },
          { text: "Customer", value: "customer", sortable: false },
          { text: "Item", value: "item", sortable: false },
          { text: "Qty. Open", value: "quantityopen" },
          { text: "Unit Price", value: "price" },
          { text: "Total Price", value: "amount" },
          { text: "Sales Order", value: "salesorder", sortable: false },
          { text: "Send Together", value: "sendtogether" },
          { text: "PO#", value: "referencenumber", sortable: false },
          { text: "Date of Order", value: "date" },
          { text: "Committed", value: "quantitycommitted" },
          { text: "Service", value: "additionalservice" },
          { text: "ETA", value: "eta" },
          { text: "ETA Memo", value: "etamemo" },
          { text: "Fleet Order", value: "fleetorder" },
          { text: "Dropship", value: "dropship" },
          { text: "Shipping Address", value: "shippingaddress" },
          { text: "Ship Date", value: "planneddateofshipment" },
          { text: "Customer Comment", value: "comment", sortable: false },
          { text: "Internal Comments", value: "memomain" },
          { text: "Payment Status", value: "paymentstatus" },
        ];
      }
      this.items = result.items;
      this.committedamount = result.committedamount;
      this.backorderamount = result.backorderamount;
      this.committedweight = result.committedweight;
      this.backorderweight = result.backorderweight;
      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
  },
  components: {
    exportData,
  },
};
</script>

<style>
.specialorder {
  background: #f9ffa3;
}
.kitcomponent {
  background: #ececec;
}
</style>
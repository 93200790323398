<template>
  <v-card tile>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Purchase Management Tools</v-toolbar-title>
    </v-toolbar>
    <v-tabs dark v-model="active">
      <v-tab :key="1">Fleet Orders</v-tab>
      <v-tab :key="2">Special Services</v-tab>
      <v-tab :key="3">Deliveries</v-tab>
      <v-tab :key="4">Stock Request</v-tab>
      <v-tab :key="9"><v-icon>mdi-close-outline</v-icon></v-tab>

      <v-tab-item :key="1">
        <fleetorderslist v-if="active==0" />
      </v-tab-item>
      <v-tab-item :key="2">
        <additionlservicelist v-if="active==1" />
      </v-tab-item>
      <v-tab-item :key="3">
        <deliveriescalendar v-if="active==2" />
      </v-tab-item>
      <v-tab-item :key="4">
        <openstockrequest v-if="active==3" />
      </v-tab-item>
      <!-- <v-tab-item :key="8">
        <statistics v-if="active==7" />
      </v-tab-item>-->
    </v-tabs>
  </v-card>
</template>

<script>
import fleetorderslist from "../components/tools/fleetorderslist/View";
import additionlservicelist from "../components/tools/additionlservicelist/View";
import deliveriescalendar from "../components/tools/deliveriescalendar/View";
import openstockrequest from "../components/tools/openstockrequest/View";

/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
      active: 4,
    };
  },
  components: {
    fleetorderslist,
    additionlservicelist,
    deliveriescalendar,
    openstockrequest,
  },
};
</script>
 <template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          loading-text="Loading... Please wait"
          item-key="_id"
          v-model="selected"
          show-select
          :search="search"
          :single-select="singleSelect"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
          :key="v"
        >
          <template v-slot:item.paymentdate="{ item }">
            <span>{{
              new Date(item.paymentdate).toISOString().substr(0, 10)
            }}</span>
          </template>
          <template v-slot:item.transaction="{ item }">
            <router-link
              :to="{
                path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
              }"
              class="link"
              >{{
                item.transaction.displayname || item.transaction.name
              }}</router-link
            >
          </template>
          <template v-slot:item.item="{ item }">
            <router-link
              :to="{
                path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`,
              }"
              class="link"
              >{{ item.item.displayname || item.item.name }}</router-link
            >
          </template>
          <template v-slot:item.amount="{ item }">
            <v-text-field
              dense
              v-model="item.amount"
              label="Assign"
              single-line
              hide-details
              outlined
              @change="refresh(item)"
            ></v-text-field>
          </template>
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-btn @click="applyall()" color="primary">Auto Apply</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
export default {
  props: ["record"],
  data() {
    return {
      loading: false,
      headers: [
        { text: "Location", value: "location" },
        { text: "Date", value: "paymentdate" },
        { text: "Transaction", value: "transaction" },
        { text: "Assigned", value: "amount" },
        { text: "Tax", value: "taxname" },
        { text: "Applied", value: "applied" },
        { text: "Remaining", value: "remaining" },
      ],
      items: [],
      rows: [100],
      singleSelect: false,
      selected: [],
      search: "",
      show: false,
      v: new Date().toString(),
    };
  },
  async created() {
    this.loading = true;
    await this.getData();

    this.items.forEach((item) => {
      this.record["prepayments"].forEach((line, index) => {
        if (item._id == line.createdfromline._id) {
          //console.log(item.amount,Math.abs(line.grossamount))
          //item.amount = Math.abs(line.grossamount);
          // this.selected.push(item);
        }
      });
    });

    this.loading = false;
  },
  watch: {
    "record.company": function (newVal = {}, oldVal = {}) {
      if (newVal._id != oldVal._id) this.getData();
    },
    selected: {
      handler: async function (val) {
        this.refresh();
      },
      deep: true,
    },
  },
  methods: {
    applyall() {
      let remaining = Math.abs(this.record.grossamount || this.record.amount);
      //this.selected = [];
      let inSel = this.selected.length > 0 ? true : false;
      this.items.forEach((line) => {
        let apply = remaining > line.remaining ? line.remaining : remaining;

        if (
          apply > 0 &&
          (inSel ? (this.selected.indexOf(line) >= 0 ? true : false) : true)
        ) {
          line.amount = apply.toFixed(2);
          remaining -= apply;
          if (!(this.selected.indexOf(line) >= 0)) this.selected.push(line);
        } else line.amount = null;
      });
      this.v = new Date().toString();
      this.refresh();
    },
    refresh() {
      if (item && item.amount > 0 && this.selected.indexOf(item) < 0)
        this.selected.push(item);
      let newArray = [];
      let shift = 0;
      this.selected.forEach((line) => {
        let amount = line.amount / (1 + line.tax.taxpct);
        let taxamount = amount * line.tax.taxpct;
        newArray.push({
          appliedto: line.transaction,
          grossamount: line.amount,
          tax: line.tax,
          taxamount: parseFloat(Number(taxamount).toFixed(2)),
          amount: parseFloat(Number(amount).toFixed(2)),
          item: line.item,
          paymentdate: line.paymentdate,
          createdfromline: line,
          createdfromtransaction: line.transaction,
        });
      });
      this.record["prepayments"] = this.record["prepayments"].filter(
        (line, index) => {
          //line.deleted = true;
          return line.created && line.created != "new";
        }
      );
      this.record["prepayments"].push(...newArray);
    },
    async getData() {
      this.loading = true;
      let result = await service(
        "orders",
        {
          action: "getPrepayments",
          rid: [],
          customer: this.record.customer
            ? this.record.customer._id || this.record.customer
            : null,
          company: this.record.company
            ? this.record.company._id || this.record.company
            : null,
        },
        false,
        true
      );
      this.items = result.items;
      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
  },
};
</script>
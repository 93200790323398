<template>
  <div>
   <iframe src="https://3cerp.eu/levam/#/" width="100%" height="840px"></iframe>
  </div>
</template>
<script>
/* eslint-disable */

export default {
  data() {
    return {
      loading: true,
      loaded: false,
    };
  },
  updated() {
    this.loaded = true;
  },
  mounted() {
    this.loading = false;
  },
};
</script>
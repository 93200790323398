<template>
  <v-card tile>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Orders Management Tools</v-toolbar-title>
    </v-toolbar>
    <v-tabs dark v-model="active">
      <v-tab :key="1">Sales Orders to Approve</v-tab>
      <v-tab :key="2">Ready To Pack</v-tab>
      <v-tab :key="3">Orders To Invoice</v-tab>
      <v-tab :key="4">Export Customs Clearance (v.Beta)</v-tab>
      <v-tab :key="5">Returns</v-tab>
      <v-tab :key="6">Active Promotions</v-tab>
      <v-tab :key="9"><v-icon>mdi-close-outline</v-icon></v-tab>
      <!-- <v-tab :key="8">Statistics</v-tab> -->
      <v-tab-item :key="1">
        <orderstoapprove v-if="active == 0" />
      </v-tab-item>
      <v-tab-item :key="2">
        <readytopack v-if="active == 1" />
      </v-tab-item>
      <v-tab-item :key="3">
        <orderstoinvoice v-if="active == 2" />
      </v-tab-item>
      <v-tab-item :key="4">
        <exportcustomsclearance v-if="active == 3" />
      </v-tab-item>
      <v-tab-item :key="5">
        <returnrequest v-if="active == 4" />
      </v-tab-item>
      <v-tab-item :key="6">
        <promocode></promocode>
        <queryresult
         
          queryid="6284a4f823577c2ba0ea775c"
          v-if="active == 5"
        />
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import orderstoapprove from "../components/tools/orderstoapprove/View";
import orderstoinvoice from "../components/tools/orderstoinvoice/View";
import readytopack from "../components/tools/readytopack/View";
import exportcustomsclearance from "../components/tools/exportcustomsclearance/View";
import returnrequest from "../components/tools/returnrequest/View";
import promocode from "../components/tools/promocode/View";
/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
      active: 6,
    };
  },

  components: {
    orderstoapprove,
    orderstoinvoice,
    readytopack,
    exportcustomsclearance,
    returnrequest,
    promocode,
  },
};
</script>
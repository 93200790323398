import service from "../../api/index";
/* eslint-disable */
export default async (record, parent, triger, subrecord) => {
  let data = {
    action: "recalcRecord",
    record: record,
    parent: parent,
    triger: triger,
    subrecord: subrecord
  };
  try {
    return await service(record.collection, data, false, true);
  } catch (error) {
    throw error;
  }

};

<template>
  <v-row>
    <v-col> </v-col>
    <v-spacer></v-spacer>
    <v-col>
      <v-container fluid>
        <v-btn
          v-if="!['stockcheck'].includes(record.recordtype)"
          :disabled="record.archived"
          @click="selectall"
          block
          tile
          x-large
          color="primary"
          >Fill the Correrct Qty. with the Expected Qty. value</v-btn
        >
      </v-container>
    </v-col>
  </v-row>
</template>
  <script>
/* eslint-disable */

export default {
  props: ["record", "selected"],
  data() {
    return {
      mode: "barcode",
      items: [],
      scan: "",
    };
  },
  watch: {},
  created() {},
  computed: {},
  methods: {
    selectall() {
      let lines = this.record["deliveryrecords"];
      if (this.selected && this.selected.length) lines = this.selected;
      for (let line of lines) {
        line.correctedquantity = line.quantity;
      }
    },
  },
};
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{attrs:{"error":_vm.required && (!_vm.recordSelected || (_vm.multiple && !_vm.recordSelected.length)),"append-icon":"mdi-magnify","hint":"type name and put enter to find","item-text":_vm.displayfield,"item-value":"_id","label":_vm.label,"items":_vm.records,"multiple":_vm.multiple,"rules":[_vm.rules.required],"return-object":_vm.recordObj,"readonly":(_vm.$route.params.mode == 'view' ||
      this.formSettings.disabled ||
      this.fieldSettings.disabled == true ||
      _vm.record.archived ||
      _vm.loading) &&
    !this.formSettings.show,"disabled":(this.formSettings.disabled || this.fieldSettings.disabled == true) &&
    !this.formSettings.show,"search-input":_vm.search,"clearable":_vm.$route.params.mode == 'view' || _vm.record.archived ? false : true,"outlined":"","loading":_vm.loading,"hide-no-data":"","dense":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchEntriesDebounced(_vm.search, true)}},scopedSlots:_vm._u([(_vm.$route.params.mode == 'view')?{key:"selection",fn:function(ref){
    var item = ref.item;
    var index = ref.index;
return [(item._id && item.collection && item.recordtype)?_c('a',{key:index,staticClass:"link d-block",attrs:{"color":"primary","href":("/" + (item.collection) + "/" + (item.recordtype) + "/view/" + (item._id)),"target":"_blank"}},[_vm._v(_vm._s(item.displayname || item.name))]):_c('span',[_vm._v(_vm._s(item.displayname || item.name))])]}}:null],null,true),model:{value:(_vm.recordSelected),callback:function ($$v) {_vm.recordSelected=$$v},expression:"recordSelected"}})}
var staticRenderFns = []

export { render, staticRenderFns }
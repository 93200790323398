<template>
  <div id="map">
    <!-- suspend mount children until google or map value is valid. -->
    <template v-if="!!this.google && !!this.map">
      <slot/>
    </template>
  </div>
</template>
<script>
import GoogleMapsApiLoader from 'google-maps-api-loader';
/* eslint-disable */
export default {
  props: {
    config: Object,
    apikey: String,
  },
  data() {
    return {
      google: null,
      map: null,
    };
  },
  mounted() {
    GoogleMapsApiLoader({
      apiKey: this.apikey,
    }).then(google => {
      this.google = google;
      this.initMap();
    });
  },
  methods: {
    initMap() {
      const mapContainer = this.$el;
      const { Map } = this.google.maps;

      this.map = new Map(mapContainer, this.config);
      this.map.setMapOnAll(null);
    },
  },
};
</script>

<style>
#map {
  min-height: 400px;
  height: 100%;
  width: 100%;
}
</style>

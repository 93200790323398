import service from "../../api/index";
export default (context, record) => {
  let fields = {};
  let recordtype = false;
  //console.log(record);
  if (record && (record.recordtype == "itemline" || record.recordtype == "expenses" || record.recordtype == "payment" || record.recordtype == "itemvendor"))
    recordtype = record.parentrecordtype;
  else recordtype = record ? record.recordtype : false;
  if (record && record.setup && (record.recordtype == "report" || record.recordtype == "field")) recordtype = record.setup.collection;
  let data = {
    action: "getFields",
    recordtype: recordtype
  };
  return service("fields", data)
    .catch(error => {
      throw error;
    })
    .then(response => {
      response.forEach(field => {
        fields[field._id] = field;
      });
      return fields;
    })

};

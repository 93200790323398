 <template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          loading-text="Loading... Please wait"
          item-key="_id"
          :search="search"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
        >
          <template v-slot:item.item="{ item }">
            <router-link
              :to="{
                path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`,
              }"
              class="link"
              >{{ item.item.displayname || item.item.name }}</router-link
            >
          </template>
          <template v-slot:item.location="{ item }">
            <router-link
              :to="{
                path: `/${item.location.collection}/${item.location.recordtype}/view/${item.location._id}`,
              }"
              class="link"
              >{{
                item.location.displayname || item.location.name
              }}</router-link
            >
          </template>
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col>
                  <exportData :headers="headers" :items="items" />
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="text-right">
                  <v-btn icon @click="show = !show">
                    <v-icon>{{
                      show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-expand-transition>
                <div v-show="show">
                  <v-divider></v-divider>
                  <v-row>
                    <v-col>
                      <v-select
                        :items="currencies"
                        v-model="currency"
                        label="Currency"
                        item-text="name"
                        item-value="_id"
                        outlined
                        dense
                      ></v-select>
                      <v-btn @click="getData()" block tile color="primary"
                        >Generate</v-btn
                      >
                    </v-col>
                    <v-col>
                      <v-select
                        :items="pricelists"
                        v-model="pricelist"
                        label="Price List"
                        item-text="name"
                        item-value="_id"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col v-if="!customer">
                      <v-autocomplete
                        :items="customers"
                        v-model="customer"
                        label="Customer"
                        item-text="name"
                        item-value="_id"
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </div>
              </v-expand-transition>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";
export default {
  props: ["customer"],
  data() {
    return {
      loading: false,
      headers: [
        // { text: "Location", value: "location", sortable: false },
        { text: "Item", value: "item", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Price Level", value: "pricelevel", sortable: false },
        { text: "MOQ", value: "moq", sortable: false },
        { text: "Currency", value: "currency", sortable: false },
        { text: "Price", value: "unitprice", sortable: false },
        { text: "RRP", value: "baseprice", sortable: false },
      ],
      items: [],
      rows: [10],
      currencies: [],
      currency: null,
      pricelists: [],
      pricelist: null,
      customers: [],
      search: "",
      show: true,
      currency: "eur",
    };
  },
  async created() {
    this.loading = true;

    this.currencies = service("accounting", {
      action: "find",
      data: [{ recordtype: "currency" }],
    });
    this.pricelists = service("lists", {
      action: "find",
      data: [{ recordtype: "pricelist" }],
    });
    this.customers = service("users", {
      action: "find",
      data: [{ recordtype: "customer" }],
    });
    const [currencies, pricelists, customers] = await Promise.all([
      this.currencies,
      this.pricelists,
      this.customers,
    ]);
    this.currencies = currencies;
    this.pricelists = pricelists;
    this.customers = customers;
    this.loading = false;
  },
  watch: {
    // lists: {
    //   handler: async function(after, before) {},
    //   deep: true
    // }
  },
  methods: {
    filter(value, search, item) {
      return filter(value, search, item);
    },
    async getData() {
      this.loading = true;
      this.items = [];
      let newresult = await service(
        "pricing",
        {
          action: "getPricelist",
          customer: this.customer,
          pricelist: this.pricelist,
          currency: this.currency,
          //location: this.location,
        },
        false,
        true
      );

      this.items.push(...newresult.pricelist);
      this.loading = false;
    },
  },
  components: {
    exportData,
  },
};
</script>
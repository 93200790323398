 <template>
  <div>
    <v-row>
      <v-col>
        <v-tabs dark v-model="active">
          <v-tab :key="1" v-if="itemsPL.length">
            <v-badge color="primary">
              <span>Warehouse PL</span>
              <span slot="badge">{{ itemsPL.length }}</span>
            </v-badge>
          </v-tab>
          <v-tab :key="2" v-if="itemsUK.length">
            <v-badge color="primary">
              <span>Warehouse UK</span>
              <span slot="badge">{{ itemsUK.length }}</span>
            </v-badge>
          </v-tab>
          <v-tab-item :key="1" v-if="itemsPL.length">
            <v-data-table
              :headers="headers"
              :items="itemsPL"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              :search="search"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
              :custom-sort="sort"
            >
              <template v-slot:item.location="{ item }">
                <span>{{
                  item.location.displayname || item.location.name
                }}</span>
              </template>
              <template v-slot:item.destination="{ item }">
                <span>{{
                  item.destination.displayname || item.destination.name
                }}</span>
              </template>
              <template v-slot:item.date="{ item }">
                <span>{{
                  new Date(item.date).toISOString().substr(0, 10)
                }}</span>
              </template>
              <template v-slot:item.created="{ item }">
                <span>{{
                  item.created
                    ? new Date(
                        new Date(item.created).getTime() -
                          new Date(item.created).getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substr(11, 5)
                    : ""
                }}</span>
              </template>
              <template v-slot:item.print="{ item }">
                <v-btn
                  @click="printPDF(item)"
                  block
                  tile
                  color="primary"
                  class="mt-2 mb-2"
                  >Print</v-btn
                >
              </template>
              <template v-slot:item.status="{ item }">
                <v-select
                  :items="statuslist"
                  v-model="item.status"
                  label="Status"
                  item-text="name"
                  item-value="_id"
                  outlined
                  dense
                  hide-details
                  class="mt-2 mb-2"
                ></v-select>
              </template>
              <template v-slot:item.transaction="{ item }">
                <router-link
                  :to="{
                    path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                  }"
                  class="link"
                  >{{
                    item.transaction.displayname || item.transaction.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.receive="{ item }">
                <v-btn
                  @click="receive(item)"
                  block
                  tile
                  color="primary"
                  class="mt-2 mb-2"
                  >Receive</v-btn
                >
              </template>
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                  }"
                  class="link"
                  >{{
                    item.customer.displayname || item.customer.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.createdfrom="{ item }">
                <router-link
                  v-for="(so, index) in item.more
                    ? item.createdfrom
                    : item.createdfrom.slice(0, 3)"
                  :key="index"
                  :to="{
                    path: `/${so.collection}/${so.recordtype}/view/${so._id}`,
                  }"
                  class="link"
                >
                  {{ so.displayname || so.name }}
                  <span v-if="index + 1 < item.createdfrom.length">,</span>
                </router-link>
                <span
                  v-if="item.createdfrom.length > 3"
                  @click="$set(item, 'more', !item.more)"
                  >(more...)</span
                >
              </template>
              <template v-slot:top>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-btn
                        @click="getData()"
                        block
                        tile
                        color="primary"
                        class="mb-2"
                        >Refresh</v-btn
                      >
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="text-right">
                      <span class="caption grey--text font-weight-light">
                        <v-icon class="mr-2" small>mdi-clock</v-icon>Last
                        Updated: TBA
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item :key="2" v-if="itemsUK.length">
            <v-data-table
              :headers="headers"
              :items="itemsUK"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              :search="search"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
              :custom-sort="sort"
            >
              <template v-slot:item.destination="{ item }">
                <span>{{
                  item.destination.displayname || item.destination.name
                }}</span>
              </template>
              <template v-slot:item.location="{ item }">
                <span>{{
                  item.location.displayname || item.location.name
                }}</span>
              </template>
              <template v-slot:item.date="{ item }">
                <span>{{
                  new Date(item.date).toISOString().substr(0, 10)
                }}</span>
              </template>
              <template v-slot:item.created="{ item }">
                <span>{{
                  item.created
                    ? new Date(
                        new Date(item.created).getTime() -
                          new Date(item.created).getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substr(11, 5)
                    : ""
                }}</span>
              </template>
              <template v-slot:item.print="{ item }">
                <v-btn
                  @click="printPDF(item)"
                  block
                  tile
                  color="primary"
                  class="mt-2 mb-2"
                  >Print</v-btn
                >
              </template>
              <template v-slot:item.status="{ item }">
                <v-select
                  :items="statuslist"
                  v-model="item.status"
                  label="Status"
                  item-text="name"
                  item-value="_id"
                  outlined
                  dense
                  hide-details
                  class="mt-2 mb-2"
                ></v-select>
              </template>
              <template v-slot:item.transaction="{ item }">
                <router-link
                  :to="{
                    path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                  }"
                  class="link"
                  >{{
                    item.transaction.displayname || item.transaction.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.receive="{ item }">
                <v-btn
                  @click="receive(item)"
                  block
                  tile
                  color="primary"
                  class="mt-2 mb-2"
                  >Receive</v-btn
                >
              </template>
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                  }"
                  class="link"
                  >{{
                    item.customer.displayname || item.customer.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.createdfrom="{ item }">
                <router-link
                  v-for="(so, index) in item.more
                    ? item.createdfrom
                    : item.createdfrom.slice(0, 3)"
                  :key="index"
                  :to="{
                    path: `/${so.collection}/${so.recordtype}/view/${so._id}`,
                  }"
                  class="link"
                >
                  {{ so.displayname || so.name }}
                  <span v-if="index + 1 < item.createdfrom.length">,</span>
                </router-link>
                <span
                  v-if="item.createdfrom.length > 3"
                  @click="$set(item, 'more', !item.more)"
                  >(more...)</span
                >
              </template>
              <template v-slot:top>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-btn
                        @click="getData()"
                        block
                        tile
                        color="primary"
                        class="mb-2"
                        >Refresh</v-btn
                      >
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="text-right">
                      <span class="caption grey--text font-weight-light">
                        <v-icon class="mr-2" small>mdi-clock</v-icon>Last
                        Updated: TBA
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import sort from "../../dataTable/sort";
export default {
  data() {
    return {
      loading: false,
      headers: [
        { text: "From", value: "location" },
        { text: "To", value: "destination" },
        { text: "ETD", value: "shippingdate" },
        { text: "ETA", value: "eta" },
        { text: "Shipment #", value: "displayname" },
        //{ text: "Created", value: "created" },
        //{ text: "Created From", value: "createdfrom" },
        //{ text: "Customer", value: "customer" },
        { text: "Shipping Address", value: "shippingaddress" },
        //{ text: "Shipping Method", value: "shippingmethod" },
        { text: "Inventory Transfer", value: "transaction" },
        { text: "Receive", value: "receive" },
        //{ text: "Status", value: "status" },
        { text: "Warehouse Comments", value: "memo" },
        { text: "# of Boxes", value: "boxes" },
        { text: "Tracking", value: "trackingnumbers" },
        { text: "Print", value: "print" },
      ],
      items: [],
      itemsPL: [],
      itemsUK: [],
      rows: [100],
      search: "",
      show: false,
      statuslist: [],
    };
  },
  async created() {
    this.loading = true;
    await this.getData();
    this.loading = false;
  },
  methods: {
    async getData() {
      this.loading = true;
      let result = await service(
        "orders",
        {
          action: "getPendingTransfers",
        },
        false,
        true
      );
      this.itemsPL = [];
      this.itemsUK = [];
      result.items.forEach((item) => {
        item.shippingaddress =
          item.shippingaddress || item.shipaddressee || item.shipaddress;
        if (
          item.destination &&
          item.destination._id == "5e0dbaba9e33df43f0b3a495"
        ) {
          this.itemsPL.push(item);
        }
        if (
          item.destination &&
          ["5e0dbaba9e33df43f0b3a496", "5faa7485371aa739b8a08843"].includes(
            item.destination._id
          )
        ) {
          this.itemsUK.push(item);
        }
      });
      this.items = result.items;
      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    sort(items, sortBy, sortDesc) {
      return sort(items, sortBy, sortDesc);
    },
    async printPDF(item) {
      let pdf = `http://localhost:8080/api/pdf/transactions/${item._id}/?pdfform=pdfpickingticket`;
      window.open(pdf, "_blank");
    },
    async receive(item) {
      this.$router.push({
        name: "Transactions",
        params: {
          recordtype: item.transaction.recordtype,
          mode: "create",
          createdfrom: item.transaction,
        },
      });
    },
    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
  },
};
</script>
 <template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          loading-text="Loading... Please wait"
          item-key="_id"
          :search="search"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
        >
          <template v-slot:item.transaction="{ item }">
            <router-link
              :to="{
                path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
              }"
              class="link"
              >{{
                item.transaction.displayname || item.transaction.name
              }}</router-link
            >
          </template>
          <template v-slot:item.childs="{ item }">
            <router-link
              v-if="item.childs"
              :to="{
                path: `/${item.childs.collection}/${item.childs.recordtype}/view/${item.childs._id}`,
              }"
              class="link"
              >{{ item.childs.displayname || item.childs.name }}</router-link
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
export default {
  props: ["customer"],
  data() {
    return {
      loading: false,
      headers: [
        { text: "Transaction", value: "transaction" },
        { text: "Invoice", value: "childs" },
        { text: "Tracking", value: "name" },
        { text: "Weight", value: "weight" },
        { text: "Status", value: "status" },
      ],
      items: [],
      rows: [100],
      search: "",
      show: false,
    };
  },
  async created() {
    this.loading = true;
    await this.getData();
    this.loading = false;
  },
  methods: {
    async getData() {
      let result = await service(
        "orders",
        {
          action: "getTracking",
          customer: this.customer,
        },
        false,
        true
      );
      this.items = result.items;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
  },
};
</script>
<template>
  <v-card tile>
    <v-toolbar dark color="primary">
      <v-toolbar-title>CEO Tools</v-toolbar-title>
    </v-toolbar>
    <v-tabs dark v-model="active">
      <v-tab :key="1">Reports</v-tab>
      <v-tab :key="2">Sales Report</v-tab>
      <v-tab :key="3">Price Lists</v-tab>
      <v-tab :key="4">Deliveries</v-tab>
      <v-tab :key="5">Map</v-tab>
      <v-tab :key="6">Statistics</v-tab>
      <v-tab :key="7"><v-icon>mdi-close-outline</v-icon></v-tab>
      <v-tab-item :key="1">
        <reports v-if="active == 0" />
      </v-tab-item>
      <v-tab-item :key="2">
        <salesreport v-if="active == 1" />
      </v-tab-item>
       <v-tab-item :key="3">
        <pricelist v-if="active == 2" />
      </v-tab-item>
      <v-tab-item :key="4">
        <deliveriescalendar v-if="active == 3" />
      </v-tab-item>
      <v-tab-item :key="5">
        <googlemap v-if="active == 4" />
      </v-tab-item>
      <v-tab-item :key="6">
        <statistics v-if="active == 5" />
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import reports from "../components/tools/reports/View";
import salesreport from "../components/tools/salesreport/View";
import googlemap from "../components/tools/map/View";
import statistics from "../components/tools/statistics/ViewNEW";
import deliveriescalendar from "../components/tools/deliveriescalendar/View";
import pricelist from "../components/tools/pricelist/View";
/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
      active: 6,
    };
  },
  components: {
    salesreport,
    googlemap,
    reports,
    statistics,
    deliveriescalendar,
    pricelist
  },
};
</script>
var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-tabs',{attrs:{"dark":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_vm._l((Object.values(_vm.tabs).filter(
            function (tab) { return (this$1.locationsFilter.includes(tab.location) ||
                !this$1.locationsFilter.length) &&
              !tab.hidden; }
          )),function(tab,index){return _c('v-tab',{key:index},[_c('v-badge',{attrs:{"color":"primary"}},[_c('span',[_vm._v(_vm._s(tab.name))]),_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_vm._v(_vm._s(tab.items.length))])])],1)}),_vm._l((Object.values(_vm.tabs).filter(
            function (tab) { return (this$1.locationsFilter.includes(tab.location) ||
                !this$1.locationsFilter.length) &&
              !tab.hidden; }
          )),function(tab,index){return _c('v-tab-item',{key:index},[_c('v-data-table',{attrs:{"headers":tab.headers,"items":tab.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","show-select":"","search":_vm.search,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter,"item-class":_vm.rowClass,"group-by":"Category"},scopedSlots:_vm._u([{key:"item.remainder",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.remainder ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.remainder))])]}},{key:"item.memo",fn:function(ref){
          var item = ref.item;
return [(tab.headers.find(function (h) { return h.value == 'memo'; }).editable)?_c('field-textarea',{staticClass:"mt-2",attrs:{"record":item,"fieldSettings":{
                  name: 'Internal Comments',
                  field: 'memo',
                  setup: { name: 'Internal Comments', field: 'memo' },
                },"formSettings":{
                  name: 'Internal Comments',
                  field: 'memo',
                  setup: { name: 'Internal Comments', field: 'memo' },
                }},on:{"emit":function($event){return _vm.catchEmit($event, item)}},model:{value:(item.memo),callback:function ($$v) {_vm.$set(item, "memo", $$v)},expression:"item.memo"}}):_c('span',[_vm._v(_vm._s(item.memo))])]}},{key:"item.date",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.date).toISOString().substr(0, 10)))])]}},{key:"item.created",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.created ? new Date( new Date(item.created).getTime() - new Date(item.created).getTimezoneOffset() * 60000 ) .toISOString() .substr(11, 5) : ""))])]}},{key:"item.location",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.location.displayname || item.location.name))])]}},{key:"item.transaction",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                  path: ("/" + (item.transaction.collection) + "/" + (item.transaction.recordtype) + "/view/" + (item.transaction._id)),
                }}},[_vm._v(_vm._s(item.transaction.displayname || item.transaction.name))])]}},{key:"item.customer",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                  path: ("/" + (item.customer.collection) + "/" + (item.customer.recordtype) + "/view/" + (item.customer._id)),
                }}},[_vm._v(_vm._s(item.customer.displayname || item.customer.name))])]}},{key:"item.accountonhold",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.accountonhold ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.accountonhold ? "Yes" : "No"))])]}},{key:"item.sendtogether",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.sendtogether ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.sendtogether ? "Yes" : "No"))])]}},{key:"item.warning",fn:function(ref){
                var item = ref.item;
return [(
                  item.customer.additionalinstructions &&
                  item.customer.additionalinstructions.length > 0
                )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-alert")])]}}],null,true)},_vm._l((item.customer.additionalinstructions),function(instruction){return _c('span',{key:instruction._id},[_vm._v(_vm._s(_vm.instructions[instruction._id || instruction]))])}),0):_vm._e()]}},{key:"item.paymentstatus",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"paymentstatus"},[_vm._v(" "+_vm._s(item.paymentstatus || "Unpaid")+_vm._s(item.paymentdescription ? " - " + item.paymentdescription : "")+" ")])]}},{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col',[(tab.name == 'Individual Customers')?_c('v-btn',{attrs:{"block":"","tile":"","color":"primary"},on:{"click":_vm.closeOrder}},[_vm._v("Close Orders ("+_vm._s(_vm.selected.length)+")")]):_vm._e()],1),_c('v-col',[_c('v-btn',{attrs:{"disabled":_vm.loading || !_vm.selected.length,"block":"","tile":"","color":"primary"},on:{"click":_vm.action}},[_vm._v("Approve Orders ("+_vm._s(_vm.selected.length)+")")])],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.getData()}}},[_c('v-icon',[_vm._v("mdi-reload")])],1)],1)],1)],1)]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
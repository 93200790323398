<template>
  <v-tabs vertical dark v-if="$route.params.mode == 'view'">
    <v-tooltip bottom>
      <span>Overview</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-information-outline</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <!-- <v-tooltip bottom>
        <span>Related Transactions</span>
        <template v-slot:activator="{ on }">
          <v-tab ripple v-on="on">
            <v-icon left>mdi-format-list-bulleted</v-icon>
          </v-tab>
        </template>
      </v-tooltip> -->
    <v-tooltip bottom>
      <span>Statistics</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-chart-line</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Logs</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-math-log</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tab-item>
      <v-container fluid>
        <v-row>
          <v-col></v-col>
          <v-col>
            <vuevectormap
              class="ma-2"
              map="world"
              ref="map"
              width="750px"
              height="400px"
              :regionsSelectable="true"
              :regionsSelectableOne="true"
              :selectedRegions="[this.record._id]"
              :focusOn="{ region: this.record._id, animate: true }"
            >
            </vuevectormap>
          </v-col>
        </v-row>
      </v-container>
    </v-tab-item>
    <!-- <v-tab-item>
        <tool-relatedtransactions :record="record" />
      </v-tab-item> -->
    <v-tab-item>
      <statistics :billcountry="record._id" mode="sales" />
    </v-tab-item>
    <v-tab-item>
      <tool-logs :record="record._id" />
    </v-tab-item>
  </v-tabs>
</template>
  <script>
/* eslint-disable */
import widget from "../../../widgets/test1";
import statistics from "../../../components/tools/statistics/ViewNEW.vue";
export default {
  props: ["record"],
  data() {
    return {
      loading: false,
      photosrc: "",
    };
  },

  components: {
    widget,
    statistics,
  },
};
</script>
  <style scoped>
.notes {
  font-size: 14px;
}
</style>
  
<template>
  <v-menu bottom right>
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon>mdi-file-download</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-subheader>Export</v-subheader>
      <v-list-item @click="exportData('csv', headers)">
        <v-list-item-icon>
          <v-icon>mdi-file-delimited</v-icon>
        </v-list-item-icon>
        <v-list-item-title
          >CSV - Export as comma-separated values</v-list-item-title
        >
      </v-list-item>

      <v-list-item @click="exportData('xls', headers)">
        <v-list-item-icon>
          <v-icon>mdi-file-table-box-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title
          >XLS - Export as Microsoft Excel format</v-list-item-title
        >
      </v-list-item>
      <v-list-item
        v-if="customexport"
        @click="exportData('xls', customexport.headers, true)"
      >
        <v-list-item-icon>
          <v-icon>mdi-file-table-box-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ customexport.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
/* eslint-disable */
import exportFromJSON from "export-from-json";
import getFields from "../../common/methods/getFields";
export default {
  props: ["items", "headers", "name", "customexport"],
  data() {
    return {
      loading: false,
      fields: {},
    };
  },
  methods: {
    async exportData(exportType, headers, newXLS) {
      this.loading = true;
      this.fields = await getFields(this);
      //console.log(this.fields)
      let data = [];
      //console.log(this.items,this.headers)
      this.items.forEach((item) => {
        let row = {};
        //if (item._id) row["id"] = item._id;
        headers.forEach((header) => {
          if (header.value) {
            row[header.text] = this.getValue(
              item[header.value],
              this.fields[header.field || header.value],
              header.function
            );
          }
        });
        data.push(row);
      });
      const fileName = `3C-${this.name || "export"}`;
      if (newXLS) this.generateXLSFromJSON(data, fileName);
      else await exportFromJSON({ data, fileName, exportType });
      this.loading = false;
    },
    getValue(value, field = null, fn) {
      if (value) {
        //console.log(value,typeof value)
        if (value.name || value.displayname)
          return value.displayname || value.name;
        if (
          value instanceof Date ||
          (field && field.setup.type == "field-date")
        ) {
          try {
            if (!fn) {
              return value
                ? new Date(typeof value === "number" ? value : `${value}`)
                    .toISOString()
                    .substr(0, 10)
                : "";
            } else return value;
          } catch (err) {
            return `${value}`;
          }
        }
        if (
          typeof value === "boolean" ||
          (field && field.setup.type == "field-date")
        )
          return value ? "YES" : "NO";
        if (
          typeof value === "number" ||
          (field &&
            ["field-currency", "field-percent"].includes(field.setup.type) &&
            !isNaN(value))
        )
          return parseFloat(value)
            .toFixed(
              (field && field.setup.precision) ||
                (field && field.setup.type == "field-percent" ? 3 : 2)
            )
            .replace(".", ",");

        if (Array.isArray(value)) {
          let newvalue = "";
          value.forEach((v, i) => {
            v = this.getValue(v);
            newvalue += i ? `, ${v}` : v;
          });
          return newvalue;
        }
        if (typeof value == "string") return value.replace(/\n/g, " ");
        else return value;
      }
      return "";
    },
    generateXLSFromJSON(jsonData, fileName) {
      let xmlHeader = `<?xml version="1.0"?>
    <?mso-application progid="Excel.Sheet"?>
    <Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet"
        xmlns:o="urn:schemas-microsoft-com:office:office"
        xmlns:x="urn:schemas-microsoft-com:office:excel"
        xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet"
        xmlns:html="http://www.w3.org/TR/REC-html40">`;

      let xmlStyles = `
        <Styles>
            <Style ss:ID="Default" ss:Name="Normal">
                <Alignment ss:Vertical="Bottom"/>
            </Style>
            <Style ss:ID="Bordered">
                <Borders>
                    <Border ss:Position="Bottom" ss:LineStyle="Continuous" ss:Weight="1"/>
                    <Border ss:Position="Left" ss:LineStyle="Continuous" ss:Weight="1"/>
                    <Border ss:Position="Right" ss:LineStyle="Continuous" ss:Weight="1"/>
                    <Border ss:Position="Top" ss:LineStyle="Continuous" ss:Weight="1"/>
                </Borders>
            </Style>
        </Styles>`;

      let xmlWorksheet = '<Worksheet ss:Name="Sheet1"><Table>';

      // Generowanie nagłówków
      xmlWorksheet += "<Row>";
      Object.keys(jsonData[0]).forEach((key) => {
        xmlWorksheet += `<Cell ss:StyleID="Bordered"><Data ss:Type="String">${key}</Data></Cell>`;
      });
      xmlWorksheet += "</Row>";

      // Generowanie danych
      jsonData.forEach((row) => {
        xmlWorksheet += "<Row>";
        Object.values(row).forEach((value) => {
          const dataType = typeof value === "number" ? "Number" : "String";
          xmlWorksheet += `<Cell ss:StyleID="Bordered"><Data ss:Type="${dataType}">${value}</Data></Cell>`;
        });
        xmlWorksheet += "</Row>";
      });

      xmlWorksheet += "</Table></Worksheet>";
      let xmlFooter = "</Workbook>";

      let xlsContent = xmlHeader + xmlStyles + xmlWorksheet + xmlFooter;

      // Tworzenie pliku i zapisanie
      const blob = new Blob([xlsContent], { type: "application/vnd.ms-excel" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${fileName}.xls`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

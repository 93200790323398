<template>
  <div>
    <queryresult v-if="!$route.params.mode" :queryid="$route.params.recordtype || 'activities'" />
    <viewrecord v-if="$route.params.mode=='view' && $route.params.recordtype=='calendar' " />
    <record collection="activities" v-else-if="$route.params.mode" />
  </div>
</template>

<script>
import viewrecord from "./view-results/View";
/* eslint-disable */

export default {
  data() {
    return {
      loading: true
    };
  },
  components: {
    viewrecord
  }
};
</script>
 <template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          loading-text="Loading... Please wait"
          item-key="_id"
          :search="search"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
        >
          <template v-slot:item.date="{ item }">
            <span>{{ new Date(item.date).toISOString().substr(0, 10) }}</span>
          </template>
          <template v-slot:item.item="{ item }">
            <router-link
              :to="{
                path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`,
              }"
              class="link"
              >{{ item.item.displayname || item.item.name }}</router-link
            >
          </template>
          <template v-slot:item.transaction="{ item }">
            <router-link
              :to="{
                path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
              }"
              class="link"
              >{{
                item.transaction.displayname || item.transaction.name
              }}</router-link
            >
          </template>
          <template v-slot:item.customer="{ item }">
            <router-link
              :to="{
                path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
              }"
              class="link"
              >{{
                item.customer.displayname || item.customer.name
              }}</router-link
            >
          </template>
          <template v-slot:footer>
            <v-divider></v-divider>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col>
                  <exportData :headers="headers" :items="items" />
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="text-right">
                  <v-btn icon @click="show = !show">
                    <v-icon>{{
                      show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-expand-transition>
                <div v-show="show">
                  <v-divider></v-divider>
                  <v-row>
                    <v-col>
                      <v-menu
                        v-model="menufrom"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            label="Date from"
                            placeholder
                            v-model="datefrom"
                            append-icon="event"
                            readonly
                            v-on="on"
                            outlined
                            dense
                            hide-details
                            class="mt-2 mb-2"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="datefrom"></v-date-picker>
                      </v-menu>
                      <v-menu
                        v-model="menuto"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            label="Date to"
                            placeholder
                            v-model="dateto"
                            append-icon="event"
                            readonly
                            v-on="on"
                            outlined
                            dense
                            hide-details
                            class="mt-2 mb-2"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="dateto"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col>
                      <v-combobox
                        dense
                        v-model="customer"
                        :items="customers"
                        label="Customer"
                        outlined
                        return-object
                        item-text="name"
                        item-value="_id"
                        multiple
                        hide-details
                        class="mt-2 mb-2"
                        @keyup.enter="searchRecords('customer')"
                      ></v-combobox>
                      <v-combobox
                        dense
                        v-model="location"
                        :items="locations"
                        label="Location"
                        outlined
                        return-object
                        item-text="name"
                        item-value="_id"
                        multiple
                        hide-details
                        class="mt-2 mb-2"
                        @keyup.enter="searchRecords('location')"
                      ></v-combobox>
                    </v-col>
                    <v-col>
                      <v-combobox
                        dense
                        v-model="country"
                        :items="countries"
                        label="Country"
                        outlined
                        return-object
                        item-text="name"
                        item-value="_id"
                        multiple
                        hide-details
                        class="mt-2 mb-2"
                        @keyup.enter="searchRecords('country')"
                      ></v-combobox>
                      <v-combobox
                        dense
                        v-model="source"
                        :items="sources"
                        label="Source"
                        outlined
                        return-object
                        item-text="name"
                        item-value="_id"
                        multiple
                        hide-details
                        class="mt-2 mb-2"
                        @keyup.enter="searchRecords('source')"
                      ></v-combobox>
                    </v-col>
                    <v-col>
                      <v-combobox
                        dense
                        v-model="salesrep"
                        :items="salesreps"
                        label="Sales Rep"
                        outlined
                        return-object
                        item-text="name"
                        item-value="_id"
                        multiple
                        hide-details
                        class="mt-2 mb-2"
                        @keyup.enter="searchRecords('salesrep')"
                      ></v-combobox>
                      <v-combobox
                        dense
                        v-model="item"
                        :items="filteritems"
                        label="Item"
                        outlined
                        return-object
                        item-text="name"
                        item-value="_id"
                        multiple
                        hide-details
                        class="mt-2 mb-2"
                        @keyup.enter="searchRecords('item')"
                      ></v-combobox>
                    </v-col>
                    <v-col>
                      <v-combobox
                        dense
                        v-model="pricegroup"
                        :items="pricegroups"
                        label="Price Group"
                        outlined
                        return-object
                        item-text="name"
                        item-value="_id"
                        multiple
                        hide-details
                        class="mt-2 mb-2"
                        @keyup.enter="searchRecords('pricegroup')"
                      ></v-combobox>
                    </v-col>
                    <v-col>
                      <v-btn
                        @click="getData()"
                        block
                        tile
                        color="primary"
                        class="mt-2 mb-2"
                        >Refresh</v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </v-expand-transition>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";
export default {
  data() {
    return {
      loading: false,
      headers: [
        { text: "Location", value: "location" },
        { text: "Date", value: "date" },
        { text: "Type", value: "recordtype" },
        { text: "Transaction", value: "transaction", sortable: false },
        { text: "Item", value: "item", sortable: false },
        { text: "Manufacturer", value: "manufacturer" },
        { text: "Customer", value: "customer", sortable: false },
        { text: "Sold", value: "quantity" },
        // { text: "On Order", value: "quantityonorder" },
        // { text: "Back Ordered", value: "quantitybackorder" },
        // { text: "Committed", value: "quantitycommitted" },
        { text: "Profit", value: "profit" },
        { text: "Cost", value: "cost" },
        { text: "Amount", value: "amount" },
        { text: "Memo", value: "memo" },
        { text: "Country", value: "billcountry" },
      ],
      menuto: false,
      menufrom: false,
      datefrom: new Date(new Date().setDate(0)).toISOString().substr(0, 10),
      dateto: new Date(new Date().setDate(30)).toISOString().substr(0, 10),
      items: [],
      rows: [10],
      customers: [],
      customer: null,
      countries: [],
      country: null,
      sources: [],
      source: null,
      locations: [],
      location: null,
      pricegroups: [],
      pricegroup: null,
      filteritems: [],
      item: null,
      salesreps: [],
      salesrep: null,
      search: "",
      show: true,
    };
  },
  async created() {
    this.loading = true;
    this.loading = false;
  },
  methods: {
    filter(value, search, item) {
      return filter(value, search, item);
    },
    async searchRecords(field) {
      this.loading = true;
      await this.getItems(field);
      this.loading = false;
    },
    async getItems(field) {
      if (field == "item") {
        this.filteritems = await service("items", {
          action: "find",
          data: [
            {
              name: { $regex: `.*${this.item}.*`, $options: "i" },
              recordtype: { $in: ["inventoryitem", "kititem"] },
            },
          ],
        }, false, true);
        this.item = this.item.filter((v) => v._id);
      }
      if (field == "customer") {
        this.customers = await service("users", {
          action: "find",
          data: [
            {
              name: { $regex: `.*${this.customer}.*`, $options: "i" },
              recordtype: { $in: ["customer"] },
            },
          ],
        }, false, true);
        this.customer = this.customer.filter((v) => v._id);
      }
      if (field == "location") {
        this.locations = await service("accounting", {
          action: "find",
          data: [
            {
              name: { $regex: `.*${this.location}.*`, $options: "i" },
              recordtype: { $in: ["location"] },
            },
          ],
        }, false, true);
        this.location = this.location.filter((v) => v._id);
      }
      if (field == "country") {
        this.countries = await service("countries", {
          action: "find",
          data: [
            {
              name: { $regex: `.*${this.country}.*`, $options: "i" },
            },
          ],
        }, false, true);
        this.country = this.country.filter((v) => v._id);
      }
      if (field == "source") {
        this.sources = await service("sources", {
          action: "find",
          data: [
            {
              name: { $regex: `.*${this.source}.*`, $options: "i" },
            },
          ],
        }, false, true);
        this.source = this.source.filter((v) => v._id);
      }
      if (field == "salesrep") {
        this.salesreps = await service("users", {
          action: "find",
          data: [
            {
              name: { $regex: `.*${this.salesrep}.*`, $options: "i" },
              recordtype: { $in: ["employee"] },
            },
          ],
        }, false, true);
        this.salesrep = this.salesrep.filter((v) => v._id);
      }
      if (field == "pricegroup") {
        this.pricegroups = await service("accounting", {
          action: "find",
          data: [
            {
              name: { $regex: `.*${this.pricegroup}.*`, $options: "i" },
              recordtype: { $in: ["pricegroup"] },
            },
          ],
        }, false, true);
        this.pricegroup = this.pricegroup.filter((v) => v._id);
      }
    },
    async getData() {
      this.loading = true;
      this.items = [];
      let result = await service(
        "transactions",
        {
          action: "getSalesReport",
          datefrom: this.datefrom,
          dateto: this.dateto,
          customer:
            this.customer && this.customer.length > 0
              ? this.customer.map((v) => v._id)
              : null,
          location:
            this.location && this.location.length > 0
              ? this.location.map((v) => v._id)
              : null,
          country:
            this.country && this.country.length > 0
              ? this.country.map((v) => v._id)
              : null,
          source:
            this.source && this.source.length > 0
              ? this.source.map((v) => v._id)
              : null,
          salesrep:
            this.salesrep && this.salesrep.length > 0
              ? this.salesrep.map((v) => v._id)
              : null,
          pricegroup:
            this.pricegroup && this.pricegroup.length > 0
              ? this.pricegroup.map((v) => v._id)
              : null,
          item:
            this.item && this.item.length > 0
              ? this.item.map((v) => v._id)
              : null,
        },
        false,
        true
      );

      this.items = result.items;
      console.log(this.items);
      this.loading = false;
    },
  },
  components: {
    exportData,
  },
};
</script>
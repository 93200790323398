var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.newheaders,"items":_vm.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","search":_vm.search,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter},scopedSlots:_vm._u([{key:"item.detaildescription",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"mx-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","dark":""}},on),[_vm._v("Show")])]}}],null,true),model:{value:(item.dialog1),callback:function ($$v) {_vm.$set(item, "dialog1", $$v)},expression:"item.dialog1"}},[_c('v-card',[_c('v-card-text',{domProps:{"innerHTML":_vm._s(item.detaildescription)}})],1)],1)]}},{key:"item.application",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"mx-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","dark":""}},on),[_vm._v("Show")])]}}],null,true),model:{value:(item.dialog2),callback:function ($$v) {_vm.$set(item, "dialog2", $$v)},expression:"item.dialog2"}},[_c('v-card',[_c('v-card-text',{domProps:{"innerHTML":_vm._s(item.application)}})],1)],1)]}},{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('exportData',{attrs:{"headers":_vm.newheaders,"items":_vm.items}})],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('v-icon',[_vm._v(_vm._s(_vm.show ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1)],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}]},[_c('v-divider'),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.currencies,"label":"Currency","item-text":"name","item-value":"_id","outlined":"","dense":""},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}}),_c('v-btn',{attrs:{"block":"","tile":"","color":"primary"},on:{"click":function($event){return _vm.getData()}}},[_vm._v("Generate")])],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.pricegroups,"label":"Price Groups","item-text":"name","item-value":"_id","outlined":"","multiple":"","dense":""},model:{value:(_vm.pricegroup),callback:function ($$v) {_vm.pricegroup=$$v},expression:"pricegroup"}})],1)],1)],1)])],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
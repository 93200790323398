<template>
  <v-card tile>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Procurement Tools</v-toolbar-title>
    </v-toolbar>
    <v-tabs dark v-model="active">
      <v-tab :key="1">Ready To Pack</v-tab>
      <v-tab :key="2">Deliveries</v-tab>
      <v-tab :key="3">Special BackOrder</v-tab>
      <v-tab :key="4">Fleet Orders</v-tab>
      <v-tab :key="5">Open Purchase Orders</v-tab>
      <v-tab :key="6">Open Special Orders</v-tab>
      <v-tab :key="7">Intrastat</v-tab>
      <v-tab :key="8">Reports</v-tab>
      <v-tab :key="9"><v-icon>mdi-close-outline</v-icon></v-tab>

      <v-tab-item :key="1">
        <readytopack v-if="active == 0" />
      </v-tab-item>
      <v-tab-item :key="2">
        <deliveriescalendar v-if="active == 1" />
      </v-tab-item>
      <v-tab-item :key="3">
        <additionalservice v-if="active == 2" />
      </v-tab-item>
      <v-tab-item :key="4">
        <fleetorders v-if="active == 3" />
      </v-tab-item>
      <v-tab-item :key="5">
        <openpurchaseorders v-if="active == 4" />
      </v-tab-item>
      <v-tab-item :key="6">
        <openadditionalservice v-if="active == 5" />
      </v-tab-item>
      <v-tab-item :key="7">
        <intrastat v-if="active == 6" />
      </v-tab-item>
      <v-tab-item :key="8">
        <reports v-if="active == 7" />
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import fleetorders from "../components/tools/fleetorders/View";
import additionalservice from "../components/tools/additionalservice/View";
import deliveriescalendar from "../components/tools/deliveriescalendar/View";
import openpurchaseorders from "../components/tools/openpurchaseorders/View";
import openadditionalservice from "../components/tools/openadditionalservice/View";
import readytopack from "../components/tools/readytopack/View";
import reports from "../components/tools/reports/View";
import intrastat from "../components/tools/intrastat/View";
/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
      active: 1,
    };
  },
  components: {
    fleetorders,
    additionalservice,
    deliveriescalendar,
    openpurchaseorders,
    openadditionalservice,
    reports,
    readytopack,
    intrastat,
  },
};
</script>
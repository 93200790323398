import service from '../../api/index';
/* eslint-disable */
export default async (calendar) => {
  let events = {};
  const collection = 'activities';
  let data = {
    action: 'getEvents',
    calendar: calendar
  };
  console.log(data)
  return service(collection, data, false, true)
    .catch(error => {
      throw error;
    })
    .then(response => {
      return response;
    })
};

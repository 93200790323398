<template>
  <v-stepper v-model="step">
    <v-progress-linear
      :indeterminate="loading"
      height="3"
      class="ma-0"
    ></v-progress-linear>
    <v-stepper-header>
      <v-stepper-step :complete="step > 1" step="1"
        >Import Settings</v-stepper-step
      >

      <v-divider></v-divider>

      <v-stepper-step :complete="step > 2" step="2">Map Fields</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3">Summary</v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-form v-model="valid" lazy-validation ref="form">
          <importsettings :settings="settings" />
          <v-btn color="primary" @click="nextStep(2)">Continue</v-btn>
        </v-form>
      </v-stepper-content>

      <v-stepper-content step="2">
        <mapfield :settings="settings" />
        <v-btn color="primary" @click="step = 3">Continue</v-btn>
        <v-btn text @click="step = 1">Cancel</v-btn>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-container
          class="mb-12"
          color="grey lighten-1"
          height="200px"
        ></v-container>
        <v-switch
          v-model="settings.savemap"
          label="Save/Save as Map"
        ></v-switch>
        <v-text-field
          v-model="settings.importmapname"
          label="Import Map Name"
          outlined
          :disabled="!settings.savemap"
        ></v-text-field>

        <v-btn color="primary" @click="importCSV" :loading="loading"
          >Import CSV</v-btn
        >
        <v-btn text @click="step = 2" :loading="loading">Cancel</v-btn>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
/* eslint-disable */
import importCSV from "../../common/methods/importCSV";
import mapfield from "./map";
import importsettings from "./settings";

export default {
  data() {
    return {
      loading: false,
      valid: false,
      step: 1,

      settings: {
        recordtype: null,
        collection: null,
        key: null,
        savemap: false,
        importMode: "add",
        importmap: null,
        separator: ";",
        name: "",
        importmapname: "",
        json: [],
        csv: "",
        csvheaders: [],
        map: [{ index: 0, value: null, subrecord: null, field: null }],
      },
    };
  },
  methods: {
    async importCSV() {
      this.loading = true;
      console.log(this.settings)
      await importCSV(this.settings)
        .catch((error) => {
          alert(error.message);
          this.loading = false;
        })
        .then((response) => {
          alert(response.message);
          this.$router.push({ path: "/imports/import/" });
        });
      this.loading = false;
    },
    nextStep(step) {
      if (this.$refs.form.validate()) this.step = step;
    },
  },
  components: {
    mapfield,
    importsettings,
  },
};
</script>
<template>
  <v-tabs vertical dark>
    <v-tooltip bottom>
      <span>Overview</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-information-outline</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Communications</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-email-open</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Logs</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-math-log</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tab-item>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <v-list>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon color="primary">mdi-email-send-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-subtitle>Recipients:</v-list-item-subtitle>
                      <v-list-item-title>{{ recipient }}</v-list-item-title>
                      <v-list-item-subtitle v-if="sent"
                        >Sent:</v-list-item-subtitle
                      >
                      <v-list-item-title v-if="sent">{{
                        sent
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col>
                <v-list>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon color="primary">mdi-email-open-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-subtitle>Opened:</v-list-item-subtitle>
                      <v-list-item-title>{{ opens }}</v-list-item-title>
                      <v-list-item-subtitle v-if="sent"
                        >Open Rate:</v-list-item-subtitle
                      >
                      <v-list-item-title v-if="sent"
                        >{{ ((opens / sent) * 100).toFixed(2) }}%</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col>
                <v-list>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon color="primary">mdi-email-check-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-subtitle>Uniq Click:</v-list-item-subtitle>
                      <v-list-item-title>{{ uniqclicks }}</v-list-item-title>
                      <v-list-item-subtitle v-if="sent"
                        >Click Rate:</v-list-item-subtitle
                      >
                      <v-list-item-title v-if="sent"
                        >{{ ((uniqclicks / sent) * 100).toFixed(2) }}%</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col>
                <v-list>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon color="primary">mdi-email-off-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-subtitle>Unsubscribed:</v-list-item-subtitle>
                      <v-list-item-title>{{ unsub }}</v-list-item-title>
                      <v-list-item-subtitle v-if="sent"
                        >Unsubscribed Rate:</v-list-item-subtitle
                      >
                      <v-list-item-title v-if="sent"
                        >{{ ((unsub / sent) * 100).toFixed(2) }}%</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <!-- <v-col v-html="record.emailtemplate.text">
                
              </v-col> -->
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col></v-col>
              <v-spacer></v-spacer>
              <v-col>
                <v-btn
                  @click="sendEmail"
                  block
                  tile
                  color="primary"
                  v-if="this.$route.params.mode == 'view'"
                  ><v-icon left>mdi-email-send</v-icon>Start Mailing</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-tab-item>
    <v-tab-item>
      <tool-inbox :record="record" :customer="record._id" filter="campaign" />
    </v-tab-item>
    <v-tab-item>
      <tool-logs :record="record._id" />
    </v-tab-item>
  </v-tabs>
</template>
<script>
/* eslint-disable */
import { sendEmail } from "../../../common/methods/email";
import piechart from "../../charts/pie";
import barchart from "../../charts/bar";
import linechart from "../../charts/line";
import colors from "../../charts/colors";
import doughnutchart from "../../charts/doughnut";
export default {
  props: ["record"],
  data() {
    return {
      loading: false,
      photosrc: "",
    };
  },
  methods: {
    async sendEmail() {
      var response = confirm("Send Newsletter - Are you sure?");
      if (response) {
        sendEmail(this, this.record);
        alert("Sending in progress...");
      }
    },
  },
  computed: {
    sent() {
      return this.record["campaignreceivers"].reduce((total, line) => {
        return total + (line.sentdate ? 1 : 0);
      }, 0);
    },
    recipient() {
      return this.record["campaignreceivers"].length;
    },
    opens() {
      return this.record["campaignreceivers"].reduce((total, line) => {
        return total + (line.confirmed ? 1 : 0);
      }, 0);
    },
    clicks() {
      return this.record["campaignreceivers"].reduce((total, line) => {
        return total + line.clickcounter || 0;
      }, 0);
    },
    uniqclicks() {
      return this.record["campaignreceivers"].reduce((total, line) => {
        return total + (line.clickcounter ? 1 : 0);
      }, 0);
    },
    unsub() {
      return this.record["campaignreceivers"].reduce((total, line) => {
        return total + (line.disallownews ? 1 : 0);
      }, 0);
    },
  },
  components: { piechart, barchart, linechart, doughnutchart },
};
</script>

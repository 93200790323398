 <template>
  <div>
    <v-row>
      <v-col>
        <v-tabs dark v-model="active">
          <v-tab v-for="(tab, index) of Object.values(tabs)" :key="index">
            <v-badge color="primary">
              <span>{{ tab.name }}</span>
              <span slot="badge">{{ tab.items.length }}</span>
            </v-badge>
          </v-tab>
          <v-tab-item v-for="(tab, index) of Object.values(tabs)" :key="index">
            <v-data-table
              :headers="tab.headers"
              :items="tab.items"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              :search="search"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
            >
              <template v-slot:item.accountonholddate="{ item }">
                <span>{{
                  item.accountonholddate
                    ? new Date(item.accountonholddate)
                        .toISOString()
                        .substr(0, 10)
                    : ""
                }}</span>
              </template>
              <template v-slot:item.accountonholddateinternal="{ item }">
                <span>{{
                  item.accountonholddateinternal
                    ? new Date(item.accountonholddateinternal)
                        .toISOString()
                        .substr(0, 10)
                    : ""
                }}</span>
              </template>
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                  }"
                  class="link"
                  >{{
                    item.customer.displayname || item.customer.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.accountonhold="{ item }">
                <v-chip
                  label
                  :color="item.accountonhold ? 'red' : 'none'"
                  dark
                  >{{ item.accountonhold ? "Yes" : "No" }}</v-chip
                >
              </template>
              <template v-slot:item.accountonholdinternal="{ item }">
                <v-chip
                  label
                  :color="item.accountonholdinternal ? 'red' : 'none'"
                  dark
                  >{{ item.accountonholdinternal ? "Yes" : "No" }}</v-chip
                >
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
export default {
  props: ["customer"],
  data() {
    return {
      active: 0,
      loading: false,

      items: [],
      rows: [100],
      search: "",
      show: false,
      tabs: {
        customer: {
          name: "Customer Limit",
          items: [],
          headers: [
            { text: "Customer", value: "customer", sortable: false },
            {
              text: "Account on Hold",
              value: "accountonhold",
              sortable: false,
            },
            { text: "Date", value: "accountonholddate" },
            { text: "Balance", value: "balance" },
            { text: "Credit Limit", value: "creditlimit" },
            { text: "Currency", value: "currency" },
            { text: "Sales Rep", value: "salesrep" },
            { text: "Category", value: "category" },
            // { text: "Unapplied Payments", value: "unapplied" }
          ],
        },
        internal: {
          name: "Internal Limit",
          items: [],
          headers: [
            { text: "Customer", value: "customer", sortable: false },
            {
              text: "Account on Hold",
              value: "accountonholdinternal",
              sortable: false,
            },
            { text: "Date", value: "accountonholddateinternal" },
            { text: "Balance", value: "balance" },
            { text: "Credit Limit", value: "creditlimitinternal" },
            { text: "Currency", value: "currency" },
            { text: "Sales Rep", value: "salesrep" },
            { text: "Category", value: "category" },
            // { text: "Unapplied Payments", value: "unapplied" }
          ],
        },
      },
    };
  },
  async created() {
    this.loading = true;
    await this.getData();
    this.loading = false;
  },
  methods: {
    async getData() {
      this.tabs.customer.items = [];
      this.tabs.internal.items = [];
      let result = await service(
        "customers",
        {
          action: "getCustomersOnHold",
        },
        false,
        true
      );
      this.items = result.items;
      this.items.forEach((line) => {
        if (line.accountonhold) this.tabs.customer.items.push(line);
        if (line.accountonholdinternal) this.tabs.internal.items.push(line);
      });
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
  },
};
</script>
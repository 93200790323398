var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"loading-text":"Loading... Please wait"},on:{"current-items":_vm.getFiltered},scopedSlots:_vm._u([{key:"item.billcountry",fn:function(ref){
var item = ref.item;
return [(item.billcountry)?_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/countries/country/view/" + (item.billcountry)),
            }}},[_vm._v(_vm._s(item.billcountry))]):_vm._e()]}},{key:"item.manufacturergroup",fn:function(ref){
            var item = ref.item;
return [(item.manufacturergroup && item.manufacturergroup._id)?_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.manufacturergroup.collection) + "/" + (item.manufacturergroup.recordtype) + "/view/" + (item.manufacturergroup._id)),
            }}},[_vm._v(_vm._s(item.manufacturergroup.name))]):_vm._e()]}},{key:"item.lastsales",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("pl-PL", { style: "currency", currency: "PLN", }).format(item.lastsales))+" ")]}},{key:"item.sales",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("pl-PL", { style: "currency", currency: "PLN", }).format(item.sales))+" ")]}},{key:"item.salestarget",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("pl-PL", { style: "currency", currency: "PLN", }).format(item.salestarget))+" ")]}},{key:"item.forecast",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("pl-PL", { style: "currency", currency: "PLN", }).format(item.forecast))+" ")]}},{key:"item.lastpercent",fn:function(ref){
            var item = ref.item;
return [(item.lastpercent)?_c('v-chip',{attrs:{"label":"","color":parseFloat(item.lastpercent) > 0 ? 'green' : 'red',"dark":""}},[_vm._v(_vm._s(item.lastpercent))]):_vm._e()]}},{key:"item.percent",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":parseFloat(item.percent) > 0 ? 'green' : 'red',"dark":""}},[_vm._v(_vm._s(item.percent))])]}},{key:"item.percent2",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":parseFloat(item.percent2) > 0 ? 'green' : 'red',"dark":""}},[_vm._v(_vm._s(item.percent2))])]}},{key:"footer",fn:function(){return [_c('v-divider'),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('exportData',{attrs:{"headers":_vm.headers,"items":_vm.items}})],1),_c('v-spacer')],1)],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-tabs vertical dark>
    <v-tooltip bottom>
      <span>Overview</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-information-outline</v-icon>
        </v-tab>
      </template>
    </v-tooltip>

    <v-tooltip bottom>
      <span>Related Transactions</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-format-list-bulleted</v-icon>
        </v-tab>
      </template>
    </v-tooltip>

    <v-tooltip bottom>
      <span>Communications</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-email-open</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Logs</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-math-log</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom v-if="record.baselinker_order_id">
      <span>Baselinker</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-chart-bubble</v-icon>
        </v-tab>
      </template>
    </v-tooltip>

    <v-tab-item>
      <v-container fluid class="py-0">
        <v-alert
          v-if="duplicatebaselinker"
          dense
          icon="mdi-chart-bubble"
          type="error"
        >
          Duplicate Order!
        </v-alert>
        <v-alert type="error" dense v-if="purchaseorderrequired"
          >Purchase Order Required!</v-alert
        >
        <v-alert
          type="error"
          dense
          v-if="locationalert && $route.params.mode != 'view'"
          >Processing order via chosen location is not possible!</v-alert
        >
        <v-alert
          type="warning"
          dense
          v-for="instruction of record.customer
            ? record.customer.additionalinstructions
            : []"
          :key="instruction._id"
          >{{ instructions[instruction._id || instruction] }}</v-alert
        >
        <v-alert type="error" dense v-if="stockrequestrequired"
          >Stock Request Required!</v-alert
        >
        <v-alert
          v-if="record.weight > 150"
          type="warning"
          dense
          icon="mdi-weight-kilogram"
        >
          Over 150kg - individual quotation on freight cost
        </v-alert>
        <v-alert
          v-if="
            record.dropship &&
            record.shipcountry &&
            (record.shipcountry._id || record.shipcountry) == 'GB'
          "
          type="warning"
          dense
          icon="mdi-weight-kilogram"
        >
          Please verify that dropship does not to Northern Ireland.
        </v-alert>
        <v-alert
          type="info"
          dense
          v-if="record.customer && record.customer.shippinginstructions"
        >
          <pre>{{ record.customer.shippinginstructions }}</pre>
        </v-alert>
        <v-alert
          type="error"
          dense
          v-if="
            record.validtaxnumber == false &&
            this.record.billcountry &&
            !this.record.billcountry.exportcustomsclearance &&
            this.record.customer &&
            !this.record.customer.isperson
          "
          >Invalid VAT Number</v-alert
        >
        <v-alert
          type="error"
          dense
          v-if="record.customer && record.customer.accountonhold"
          >Account On Hold!</v-alert
        >
        <v-alert
          type="success"
          dense
          v-for="(wz, index) of openif"
          :key="index"
          icon="mdi-package-up"
          >Packaging in progress:
          {{ wz.map((r) => r.name).join(", ") }}
          ({{ wz[0].shippingmethod }} - {{ wz[0].shipname }})
        </v-alert>
        <v-row row wrap>
          <!-- <v-col>
            <v-subheader>Main</v-subheader>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Date:</v-list-item-subtitle>
                      <v-list-item-title>{{ date }}</v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Created By:</v-list-item-subtitle>
                      <v-list-item-title>{{
                        record.createdby
                          ? record.createdby.name
                          : record.customer.name
                      }}</v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </v-list>
          </v-col>
          <v-divider vertical></v-divider> -->

          <v-col>
            <v-subheader>Main</v-subheader>

            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0" style="max-width: 50%">
                      <v-list-item-subtitle>Customer:</v-list-item-subtitle>

                      <v-list-item-title>
                        <router-link
                          v-if="record.customer"
                          :to="`/users/${record.customer.recordtype}/view/${record.customer._id}`"
                          >{{ record.customer.name }}</router-link
                        >
                      </v-list-item-title>
                    </v-col>
                    <v-col
                      class="py-0"
                      v-if="
                        record.salesrep ||
                        (record.customer && record.customer.salesrep)
                      "
                    >
                      <v-list-item-subtitle>Sales Rep:</v-list-item-subtitle>

                      <v-list-item-title
                        v-if="record.location._id == '62de6196fa2fc522dc692c7e'"
                      >
                        {{
                          record.salesrep
                            ? record.salesrep.name
                            : record.customer.salesrep
                            ? record.customer.salesrep.name
                            : ""
                        }}
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        {{
                          record.customer.salesrep
                            ? record.customer.salesrep.name
                            : record.salesrep
                            ? record.salesrep.name
                            : ""
                        }}
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Date:</v-list-item-subtitle>
                      <v-list-item-title>{{ date }}</v-list-item-title>
                    </v-col>
                    <v-col
                      class="py-0"
                      v-if="
                        record.createdby &&
                        record.createdby._id != record.customer._id
                      "
                    >
                      <v-list-item-subtitle>Created By:</v-list-item-subtitle>
                      <v-list-item-title>{{
                        record.createdby
                          ? record.createdby.name
                          : record.customer.name
                      }}</v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="primary">business</v-icon>
                </v-list-item-action>

                <v-list-item-content class="py-1">
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-title
                        >{{ billingaddress.company
                        }}{{ billingaddress.name }}</v-list-item-title
                      >
                      <v-list-item-subtitle
                        >{{ billingaddress.address }}<br />{{
                          billingaddress.country
                        }}</v-list-item-subtitle
                      ></v-col
                    >
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider inset></v-divider>
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="primary">local_shipping</v-icon>
                </v-list-item-action>

                <v-list-item-content class="py-1">
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-title
                        >{{ shippingaddress.company
                        }}{{ shippingaddress.name }}</v-list-item-title
                      >
                      <v-list-item-subtitle
                        >{{ shippingaddress.address }}<br />{{
                          shippingaddress.country
                        }}</v-list-item-subtitle
                      >
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                v-if="
                  (record.createdfrom && record.createdfrom.length) ||
                  (correlatedSO && correlatedSO.length)
                "
              >
                <v-list-item-content>
                  <v-row>
                    <v-col
                      class="py-0"
                      v-if="record.createdfrom && record.createdfrom.length"
                    >
                      <v-list-item-subtitle>Created From:</v-list-item-subtitle>
                      <v-list-item-title>
                        <router-link
                          v-for="createdfrom of record.createdfrom"
                          :key="createdfrom._id"
                          :to="`/transactions/${createdfrom.recordtype}/view/${createdfrom._id}`"
                        >
                          {{ createdfrom.name }}
                          <br />
                        </router-link>
                      </v-list-item-title>
                    </v-col>
                    <v-col
                      class="py-0"
                      v-if="correlatedSO && correlatedSO.length"
                    >
                      <v-list-item-subtitle
                        >Correlated SO:</v-list-item-subtitle
                      >
                      <v-list-item-title>
                        <router-link
                          v-for="correlated of correlatedSO"
                          :key="correlated._id"
                          :to="`/transactions/${correlated.recordtype}/view/${correlated._id}`"
                        >
                          {{ correlated.name }}
                          <br />
                        </router-link>
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col>
            <v-subheader>Summary</v-subheader>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Net. Amount:</v-list-item-subtitle>
                      <v-list-item-title>{{ amount }}</v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Items Amount:</v-list-item-subtitle>
                      <v-list-item-title>{{ itemsamount }}</v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Tax Amount:</v-list-item-subtitle>
                      <v-list-item-title>{{ taxamount }}</v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle
                        >Shipping Cost:</v-list-item-subtitle
                      >
                      <v-list-item-title>{{
                        shippingamount
                      }}</v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Gross Amount:</v-list-item-subtitle>
                      <v-list-item-title>
                        <b>{{ grossamount }}</b>
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle
                        >Estimated Cost:</v-list-item-subtitle
                      >
                      <v-list-item-title>{{ cost }}</v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle
                        >Estimated Profit:</v-list-item-subtitle
                      >
                      <v-list-item-title>{{ profit }}</v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                v-if="
                  weightcommitted ||
                  (record.margeinto && record.margeinto.length)
                "
              >
                <v-list-item-icon
                  ><v-icon> mdi-weight-kilogram</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0" v-if="weightcommitted">
                      <v-list-item-subtitle
                        >Committed / Total Weight:</v-list-item-subtitle
                      >
                      <v-list-item-title
                        >{{ weightcommitted }} / {{ weight }}</v-list-item-title
                      >
                    </v-col>
                    <v-col
                      class="py-0"
                      v-if="record.margeinto && record.margeinto.length"
                    >
                      <v-list-item-subtitle>Marge Into:</v-list-item-subtitle>
                      <v-list-item-title>
                        <router-link
                          v-for="createdfrom of record.margeinto"
                          :key="createdfrom._id"
                          :to="`/transactions/${createdfrom.recordtype}/view/${createdfrom._id}`"
                        >
                          {{ createdfrom.name }}
                          <br />
                        </router-link>
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col>
            <field-map :addresses="addresses" />
          </v-col>
        </v-row>
      </v-container>
    </v-tab-item>
    <v-tab-item>
      <tool-relatedtransactions :record="record" />
    </v-tab-item>
    <v-tab-item>
      <tool-inbox
        v-if="record.customer"
        :record="record"
        :customer="record.customer._id"
      />
    </v-tab-item>
    <v-tab-item>
      <tool-logs :record="record._id" />
    </v-tab-item>
    <v-tab-item v-if="record.baselinker_order_id">
      <baselinker :record="record" />
    </v-tab-item>
  </v-tabs>
</template>
<script>
import baselinker from "../../../components/tools/baselinker/ViewSO";
import service from "../../../api/index";
/* eslint-disable */
export default {
  props: ["record"],
  data() {
    return {
      loading: false,
      photosrc: "",
      mode: this.$route.params.mode,
      instructions: {},
      duplicatebaselinker: false,
      openif: {},
    };
  },
  async created() {
    this.instructions = await service("lists", {
      action: "find",
      data: [{ recordtype: "additionalinstruction" }],
    }).then((response) => {
      let ids = {};
      response.map((v) => {
        ids[v._id] = v.name;
      });
      return ids;
    });
    if (this.record.customer && this.record.customer.salesrep)
      this.record.customer.salesrep = await service("users", {
        action: "findOne",
        data: [{ _id: this.record.customer.salesrep }],
      });

    if (this.record.baselinker_order_id) {
      return await service("transactions", {
        action: "findOne",
        data: [
          {
            _id: { $lt: this.record._id },
            recordtype: "salesorder",
            company: "brakespl",
            baselinker_order_id: this.record.baselinker_order_id,
          },
        ],
      })
        .catch((err) => false)
        .then((res) => {
          this.duplicatebaselinker = res ? true : false;
        });
    } else this.duplicatebaselinker = false;

    if (
      this.record.location &&
      this.record.location._id != "61e14c6ae4a35a668517a8fe"
    )
      this.openif = await this.getopenif();
  },
  methods: {
    async getopenif() {
      console.log("openif");
      return await service("transactions", {
        action: "find",
        data: [
          {
            recordtype: "itemfulfillment",
            status: { $nin: ["shipped", "packed"] },
            customer: this.record.customer._id,
          },
          {
            projection: {
              _id: true,
              name: true,
              shippingmethod: true,
              shipname: true,
            },
          },
        ],
      })
        .then(async (res) => {
          for (let line of res) {
            line.shippingmethod = await service("items", {
              action: "findOne",
              data: [
                {
                  _id: line.shippingmethod,
                },
                {
                  projection: {
                    _id: true,
                    name: true,
                  },
                },
              ],
            });
          }
          return res;
        })
        .then(async (res) => {
          return res.reduce((t, l) => {
            t[`${l.shipname}-${l.shippingmethod._id}`] =
              t[`${l.shipname}-${l.shippingmethod._id}`] || [];

            t[`${l.shipname}-${l.shippingmethod._id}`].push({
              name: l.name,
              shippingmethod: l.shippingmethod.name,
              shipname: l.shipname,
            });
            return t;
          }, {});
        });
    },
    formatCurrency(value, currency = "PLN") {
      return new Intl.NumberFormat(currency !== "PLN" ? "en-EN" : "pl-PL", {
        style: "currency",
        currency: currency,
      }).format(value || 0);
    },
  },
  computed: {
    locationalert() {
      if (this.record.customer && this.record.customer.location) {
        let availablelocations = [
          undefined,
          null,
          false,
          this.record.customer.location._id || this.record.customer.location,
        ];
        if (this.record.customer.forlocations)
          this.record.customer.forlocations.forEach((loc) => {
            availablelocations.push(loc._id);
          });
        return !availablelocations.includes(
          this.record.location
            ? this.record.location._id || this.record.location
            : undefined
        );
      } else return true;
    },
    purchaseorderrequired() {
      let status = false;
      if (this.record.location) {
        (this.record["transactionlines"] || []).forEach((line) => {
          if (
            (line.additionalservice || []).every(
              (service) =>
                ![
                  "60a4cca26b2a2dad7647a375",
                  "607fe8eef72336561da2c0a9",
                  "6048c9d6092da96f1bb5b081",
                ].includes(service._id)
            ) &&
            !line.eta &&
            line.specialbackorder &&
            line.quantitybackorder &&
            (this.record.location.destination || line.correlatedlocation)
          )
            status = true;
        });
        if (this.record.location && status) {
          this.record["childs"].forEach((line) => {
            if (line.recordtype == "purchaseorder") status = false;
          });
        }
      }
      return status;
    },
    stockrequestrequired() {
      let status = false;
      (this.record["transactionlines"] || []).forEach((line) => {
        (line.additionalservice || []).forEach((service) => {
          if (
            service.stockrequest &&
            (!line.specialorders || !line.specialorders.length) &&
            (line.quantity || 0) - (line.quantityfulfilled || 0)
          )
            status = true;
        });
      });
      return status;
    },
    date() {
      return this.record.date
        ? new Date(this.record.date).toISOString().substr(0, 10)
        : "";
    },
    customer() {
      return this.record.customer
        ? `<router-link to:'/users/${this.record.customer.recordtype}/view/${this.record.customer._id}'>${this.record.customer.name}</router-link>`
        : "";
    },
    amount() {
      return this.formatCurrency(
        this.record.amount,
        this.record.currency ? this.record.currency.iso : "PLN"
      );
    },
    grossamount() {
      return this.formatCurrency(
        this.record.grossamount,
        this.record.currency ? this.record.currency.iso : "PLN"
      );
    },
    taxamount() {
      return this.formatCurrency(
        this.record.taxamount,
        this.record.currency ? this.record.currency.iso : "PLN"
      );
    },
    shippingamount() {
      return this.formatCurrency(
        this.record.shippingcost,
        this.record.currency ? this.record.currency.iso : "PLN"
      );
    },

    itemsamount() {
      return this.formatCurrency(
        this.record.itemsamount,
        this.record.currency ? this.record.currency.iso : "PLN"
      );
    },

    cost() {
      return this.formatCurrency(this.record.cost);
    },

    profit() {
      return this.formatCurrency(this.record.profit);
    },
    weight() {
      return this.record.weight
        ? `${parseFloat(this.record.weight).toFixed(2)} kg`
        : `0.00 kg`;
    },
    weightcommitted() {
      let weight = 0;
      (this.record["transactionlines"] || []).forEach((line) => {
        weight += (line.quantitycommitted || 0) * (line.weight || 0);
      });
      return weight ? `${parseFloat(weight).toFixed(2)} kg` : `0.00 kg`;
    },
    addresses() {
      let addresses = [];
      if (this.record.billingaddress) {
        this.record.billingaddress.image =
          "https://maps.gstatic.com/mapfiles/ms2/micons/red-dot.png";
        addresses.push(this.record.billingaddress);
      }
      if (this.record.shippingaddress) {
        this.record.shippingaddress.image =
          "https://maps.gstatic.com/mapfiles/ms2/micons/blue-dot.png";
        addresses.push(this.record.shippingaddress);
      }
      return addresses;
    },
    billingaddress() {
      let address = { name: "", address: "", country: "" };
      if (
        this.record.billname &&
        this.record.billname != this.record.billaddressee &&
        this.record.billname != this.record.billaddress
      )
        address.company = `${this.record.billname}, `;
      address.name = this.record.billaddressee || "";
      if (this.record.billaddress) address.address += this.record.billaddress;
      if (this.record.billaddress2)
        address.address += ` ${this.record.billaddress2}`;
      if (this.record.billcity) address.country += `${this.record.billcity}`;
      if (this.record.billzip) address.country += `, ${this.record.billzip}`;
      if (this.record.billcountry)
        address.country += `, ${
          this.record.billcountry.name || this.record.billcountry
        }`;
      return address;
    },

    shippingaddress() {
      let address = { name: "", address: "", country: "" };
      if (
        this.record.shipname &&
        this.record.shipname != this.record.shipaddressee &&
        this.record.shipname != this.record.shipaddress
      )
        address.company = `${this.record.shipname}, `;
      address.name = this.record.shipaddressee || "";
      if (this.record.shipaddress) address.address += this.record.shipaddress;
      if (this.record.shipaddress2)
        address.address += `, ${this.record.shipaddress2}`;
      if (this.record.shipcity) address.country += `${this.record.shipcity}`;
      if (this.record.shipzip) address.country += `, ${this.record.shipzip}`;
      if (this.record.shipcountry)
        address.country += `, ${
          this.record.shipcountry.name || this.record.shipcountry
        }`;
      return address;
    },
    correlatedSO() {
      return [
        ...(this.record.childs || []),
        ...(this.record.correlated || []),
      ].filter((rec) => {
        return rec.recordtype == "salesorder";
      });
    },
  },
  components: {
    baselinker,
  },
};
</script>

<template>
  <v-tabs vertical dark>
    <v-tooltip bottom>
      <span>Overview</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-information-outline</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Receive</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>assignment_turned_in</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Logs</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-math-log</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tab-item>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle
                        >Quantity Expected:</v-list-item-subtitle
                      >
                      <v-list-item-title>{{
                        quantitytopack
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle
                        >Quantity Scanned:</v-list-item-subtitle
                      >
                      <v-list-item-title>{{
                        record.quantity
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-container>
    </v-tab-item>
    <v-tab-item>
      <tool-receivedelivery :record="record.delivery._id" />
    </v-tab-item>
    <v-tab-item>
      <tool-logs :record="record._id" />
    </v-tab-item>
  </v-tabs>
</template>
<script>
/* eslint-disable */
export default {
  props: ["record"],
  data() {
    return {
      loading: false,
      photosrc: "",
    };
  },
  computed: {
    date() {
      return new Date(this.record.date).toISOString().substr(0, 10);
    },
    quantitytopack() {
      let qtytopack = 0;
      this.record.deliveryrecords.forEach((line) => {
        qtytopack += parseInt(line.quantitytopack || 0);
      });
      return qtytopack;
    },
  },
};
</script>

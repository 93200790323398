<template>
  <div>
    <queryresult
      v-if="!$route.params.mode"
      :queryid="$route.params.recordtype"
    />
    <record collection="campaigns" v-else />
  </div>
</template>

<script>
/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
    };
  },
};
</script>
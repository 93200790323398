<template>
  <v-tabs vertical dark>
    <v-tooltip bottom>
      <span>Overview</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-information-outline</v-icon>
        </v-tab>
      </template>
    </v-tooltip>

    <v-tooltip bottom>
      <span>Related Transactions</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-format-list-bulleted</v-icon>
        </v-tab>
      </template>
    </v-tooltip>

    <v-tooltip bottom>
      <span>Communications</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-email-open</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Logs</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-math-log</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tab-item>
      <v-container fluid class="py-0">
        <v-row row wrap>
          <v-col>
            <v-subheader>Main</v-subheader>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Customer:</v-list-item-subtitle>

                      <v-list-item-title>
                        <router-link
                          v-if="record.customer"
                          :to="`/users/${record.customer.recordtype}/view/${record.customer._id}`"
                          >{{ record.customer.name }}</router-link
                        >
                      </v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Sales Rep:</v-list-item-subtitle>

                      <v-list-item-title>
                        {{
                          record.salesrep
                            ? record.salesrep.name
                            : record.customer.salesrep
                            ? record.customer.salesrep.name
                            : ""
                        }}
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle
                        >Document Number:</v-list-item-subtitle
                      >
                      <v-list-item-title>{{
                        record.name || "To Be Generate"
                      }}</v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Date:</v-list-item-subtitle>
                      <v-list-item-title>{{ date }}</v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Created:</v-list-item-subtitle>
                      <v-list-item-title>{{
                        record.created
                          ? new Date(record.created).toISOString().substr(0, 10)
                          : ""
                      }}</v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>By:</v-list-item-subtitle>
                      <v-list-item-title>{{
                        record.createdby
                          ? record.createdby.name
                          : record.customer.name
                      }}</v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="
                  (record.createdfrom && record.createdfrom.length) ||
                  (correlatedSO && correlatedSO.length)
                "
              ></v-divider>
              <v-list-item
                v-if="
                  (record.createdfrom && record.createdfrom.length) ||
                  (correlatedSO && correlatedSO.length)
                "
              >
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Created From:</v-list-item-subtitle>
                      <v-list-item-title>
                        <router-link
                          v-for="createdfrom of record.createdfrom"
                          :key="createdfrom._id"
                          :to="`/transactions/${createdfrom.recordtype}/view/${createdfrom._id}`"
                        >
                          {{ createdfrom.displayname || createdfrom.name }}
                          <br />
                        </router-link>
                      </v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle
                        >Correlated WZ:</v-list-item-subtitle
                      >
                      <v-list-item-title>
                        <router-link
                          v-for="correlated of correlatedSO"
                          :key="correlated._id"
                          :to="`/transactions/${correlated.recordtype}/view/${correlated._id}`"
                        >
                          {{ correlated.displayname || correlated.name }}
                          <br />
                        </router-link>
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col>
            <v-subheader>Details</v-subheader>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <pre>{{ record.memo }}</pre>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col>
            <v-subheader>Summary</v-subheader>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Quantity:</v-list-item-subtitle>
                      <v-list-item-title>{{
                        record.quantity
                      }}</v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Net. Amount:</v-list-item-subtitle>
                      <v-list-item-title>{{ amount }}</v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Weight:</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ weight }}
                      </v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Tax Amount:</v-list-item-subtitle>
                      <v-list-item-title>{{ taxamount }}</v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0"> </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Gross Amount:</v-list-item-subtitle>
                      <v-list-item-title>
                        <b>{{ grossamount }}</b>
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </v-tab-item>
    <v-tab-item>
      <tool-relatedtransactions :record="record" />
    </v-tab-item>
    <v-tab-item>
      <tool-inbox :record="record" :customer="record._id" filter="record" />
    </v-tab-item>
    <v-tab-item>
      <tool-logs :record="record._id" />
    </v-tab-item>
  </v-tabs>
</template>
<script>
import service from "../../../api/index";
/* eslint-disable */
export default {
  props: ["record"],
  data() {
    return {
      loading: false,
      photosrc: "",
    };
  },
  methods: {
    formatCurrency(value, currency = "PLN") {
      return new Intl.NumberFormat(currency !== "PLN" ? "en-EN" : "pl-PL", {
        style: "currency",
        currency: currency,
      }).format(value || 0);
    },
  },
  computed: {
    date() {
      return this.record.date
        ? new Date(this.record.date).toISOString().substr(0, 10)
        : "";
    },
    customer() {
      return this.record.customer
        ? `<router-link to:'/users/${this.record.customer.recordtype}/view/${this.record.customer._id}'>${this.record.customer.name}</router-link>`
        : "";
    },

    amount() {
      return this.formatCurrency(this.record.amount, this.record.currency ? this.record.currency.iso : "PLN");
    },
    grossamount() {
      return this.formatCurrency(
        this.record.grossamount,
        this.record.currency ? this.record.currency.iso : "PLN"
      );
    },
    taxamount() {
      return this.formatCurrency(
        this.record.taxamount,
        this.record.currency ? this.record.currency.iso : "PLN"
      );
    },
    shippingamount() {
      return this.formatCurrency(
        this.record.shippingcost,
        this.record.currency ? this.record.currency.iso : "PLN"
      );
    },

    itemsamount() {
      return this.formatCurrency(
        this.record.itemsamount,
        this.record.currency ? this.record.currency.iso : "PLN"
      );
    },
    weight() {
      return this.record.weight
        ? `${parseFloat(this.record.weight).toFixed(2)} kg`
        : `0.00 kg`;
    },
    correlatedSO() {
      return [
        ...(this.record.childs || []),
        ...(this.record.correlated || []),
      ].filter((rec) => {
        return rec.recordtype == "itemfulfillment";
      });
    },
  },
};
</script>

<template>
  <v-card>
    <v-row>
      <v-col>
        <v-card-text>
          <v-progress-linear v-model="progress" color="primary" height="25">
            <template v-slot:default="{ value }">
              <strong>{{ Math.ceil(value) }}%</strong>
            </template>
          </v-progress-linear>
          <v-row>
            <v-col>
              <v-switch
                v-model="onhandonly"
                dense
                hide-details
                @change="sortPerField()"
                inset
                label="Only On Hand"
              ></v-switch>
            </v-col>
            <v-col>
              <v-text-field
                label="Item"
                placeholder="Item"
                outlined
                dense
                hide-details
                v-model="searchTree"
                class="mt-2 mb-2"
                @change="search = searchTree"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-menu
                v-model="menufrom"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="Date from"
                    placeholder
                    v-model="datefrom"
                    append-icon="event"
                    readonly
                    v-on="on"
                    outlined
                    dense
                    hide-details
                    class="mt-2 mb-2"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="datefrom"
                  :first-day-of-week="1"
                  @change="sortPerField()"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-treeview
            v-model="stockcheck.stockchecks"
            :load-children="fetch"
            :items="items"
            selected-color="primary"
            open-on-click
            selectable
            :search="search"
            :filter="filterTree"
            item-key="_id"
            return-object
            expand-icon="mdi-chevron-down"
            on-icon="mdi-map-marker-right"
            off-icon="mdi-archive-marker-outline"
            indeterminate-icon="mdi-archive-marker-outline"
          >
            <template v-slot:label="{ item }">
              <v-simple-table>
                <template v-slot:default v-if="item._id == 1">
                  <thead>
                    <tr>
                      <th class="text-left">
                        <v-icon
                          @click.stop="
                            sort.bin = sort.bin * -1;
                            sortPerField();
                          "
                          >mdi-sort</v-icon
                        >
                        Name
                      </th>
                      <th class="text-left" width="200px">
                        <v-select
                          @click.stop=""
                          dense
                          :items="brands"
                          @change="sortPerField()"
                          v-model="brand"
                          label="Brand"
                          multiple
                          hide-details
                        ></v-select>
                      </th>
                      <th class="text-left" width="80px">Count (on hand)</th>
                      <th class="text-left" width="200px">Last Check</th>
                    </tr>
                  </thead>
                </template>

                <template v-slot:default v-else>
                  <thead v-if="!item.item">
                    <tr>
                      <th class="text-left">
                        {{ item.name || item.item.name || "asd" }}
                        <span><b>{{ item.pallet ? "(Pallete)" : "" }}</b></span>
                      </th>
                      <th class="text-left" width="200px">
                        {{
                          Array.isArray(item.manufacturergroup)
                            ? item.manufacturergroup.join(", ")
                            : "asd"
                        }}
                      </th>
                      <th class="text-left" width="80px">
                        {{ item.children ? item.children.length : "" }} ({{
                          item.quantityonhand
                        }})
                      </th>
                      <th class="text-left" width="200px">
                        {{
                          item.laststockcheck
                            ? new Date(item.laststockcheck)
                                .toISOString()
                                .substr(0, 10)
                            : ""
                        }}
                      </th>
                    </tr>
                  </thead>
                </template>
              </v-simple-table>

              <v-simple-table v-if="item.item">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>
                        <router-link
                          :to="{
                            path: `/items/inventoryitem/view/${item.item._id}`,
                          }"
                          class="link"
                          >{{ item.item.name }}</router-link
                        >
                      </th>
                      <th class="text-left" width="200px">
                        {{ item.manufacturergroup }}
                      </th>
                      <th width="80px">{{ item.quantityonhand }}</th>
                      <th class="text-left" width="200px">
                        <span class="d-block">{{
                          item.laststockcheck
                            ? new Date(item.laststockcheck)
                                .toISOString()
                                .substr(0, 10)
                            : ""
                        }}</span>
                        <small>{{ item.stockchecktype }}</small>
                      </th>
                    </tr>
                  </thead>
                </template>
              </v-simple-table>
            </template>
          </v-treeview>
        </v-card-text>
      </v-col>

      <v-divider vertical></v-divider>

      <v-col cols="12" md="6">
        <v-card-text>
          <v-row>
            <v-col>
              <v-select
                outlined
                dense
                :items="employees"
                v-model="stockcheck.employee"
                disabled
                label="Who Checked"
              ></v-select
            ></v-col>
            <v-col>
              <v-select
                outlined
                dense
                label="Location"
                v-model="stockcheck.location"
                disabled
              ></v-select
            ></v-col>
          </v-row>
          <v-combobox
            dense
            label="Description"
            v-model="stockcheck.description"
            :items="descriptions"
            outlined
          ></v-combobox>
          <v-data-table
            :headers="headers"
            v-model="stockcheck.stockchecks"
            show-select
            :items="stockcheck.stockchecks"
            :loading="loading"
            loading-text="Loading... Please wait"
            item-key="_id"
            :search="search"
            :footer-props="{ itemsPerPageOptions: rows }"
            :custom-filter="filter"
          >
            <template v-slot:item.item="{ item }">
              <router-link
                :to="{
                  path: `/items/item/view/${item.item._id}`,
                }"
                class="link"
                >{{ item.item.name }}</router-link
              >
            </template>
          </v-data-table>
          <v-btn
            class="mt-4"
            tile
            block
            color="primary"
            :disabled="!stockcheck.stockchecks.length"
            @click="createStockCheck"
          >
            Create Stock Check</v-btn
          >
        </v-card-text>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <!-- <v-card-actions>
      <v-btn text @click="tree = []"> Reset </v-btn>

      <v-spacer></v-spacer>

      <v-btn class="white--text" color="green darken-1" depressed>
        Save
        <v-icon right> mdi-content-save </v-icon>
      </v-btn>
    </v-card-actions> -->
  </v-card>
</template>
  <script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";

export default {
  data: () => ({
    stockcheck: {
      mode: "create",
      recordtype: "stockcheck",
      collection: "transactions",
      location: "5e0dbaba9e33df43f0b3a495",
      stockchecks: [],
      employee: null,
      description: "",
    },
    descriptions: [
      "Annual Stock Check",
      "Weekly Stock Check",
      "Daily Stock Check",
      "Verifcation",
    ],
    menufrom: false,
    datefrom: new Date(new Date().getFullYear(), 0, 2)
      .toISOString()
      .substr(0, 10),
    brands: [],
    brand: [],
    checked: 0,
    onhandonly: true,
    bins: 0,
    databin: [],
    isLoading: false,
    tree: [],
    sort: {
      bin: 1,
      laststockcheck: 1,
    },
    sectors: [],
    items: [
      {
        _id: 1,
        name: "All Locations (bins)",
        children: [],
      },
    ],
    selected: [],
    headers: [
      { text: "Item", value: "item" },
      { text: "Bin", value: "bin" },
      { text: "on Hand", value: "quantityonhand" },
    ],
    rows: [10],
    searchTree: "",
    search: "",
    pallet: [
      "A3-1-1",
      "A3-1-2",
      "A3-1-3",
      "A3-2-1",
      "A3-2-2",
      "A3-2-3",
      "A3-3-1",
      "A3-3-2",
      "A3-3-3",
      "A3-4-1",
      "A3-4-2",
      "A3-4-3",
      "A3-5-1",
      "A3-5-2",
      "A3-5-3",
      "A3-6-1",
      "A3-6-2",
      "A3-6-3",
      "A4-1-1",
      "A4-1-2",
      "A4-1-3",
      "A4-2-1",
      "A4-2-2",
      "A4-2-3",
      "A4-3-1",
      "A4-3-2",
      "A4-3-3",
      "A4-4-1",
      "A4-4-2",
      "A4-4-3",
      "A4-5-1",
      "A4-5-2",
      "A4-5-3",
      "A4-6-1",
      "A4-6-2",
      "A4-6-3",
      "A5-1-1",
      "A5-1-2",
      "A5-1-3",
      "A5-2-1",
      "A5-2-2",
      "A5-2-3",
      "A5-3-1",
      "A5-3-2",
      "A5-3-3",
      "A5-4-1",
      "A5-4-2",
      "A5-4-3",
      "A5-5-1",
      "A5-5-2",
      "A5-5-3",
      "A5-6-1",
      "A5-6-2",
      "A5-6-3",
      "A6-1-1",
      "A6-1-2",
      "A6-1-3",
      "A6-2-1",
      "A6-2-2",
      "A6-2-3",
      "A6-3-1",
      "A6-3-2",
      "A6-3-3",
      "A6-4-1",
      "A6-4-2",
      "A6-4-3",
      "A6-5-1",
      "A6-5-2",
      "A6-5-3",
      "A6-6-1",
      "A6-6-2",
      "A6-6-3",
      "A7-1-1",
      "A7-1-2",
      "A7-1-3",
      "A7-2-1",
      "A7-2-2",
      "A7-2-3",
      "A7-3-1",
      "A7-3-2",
      "A7-3-3",
      "A7-4-1",
      "A7-4-2",
      "A7-4-3",
      "A7-5-1",
      "A7-5-2",
      "A7-5-3",
      "A7-6-1",
      "A7-6-2",
      "A7-6-3",
      "AA1-2-1",
      "AA1-2-2",
      "AA1-2-3",
      "AA1-3-1",
      "AA1-3-2",
      "AA1-3-3",
      "AA2-2-1",
      "AA2-2-2",
      "AA2-2-3",
      "AA2-3-1",
      "AA2-3-2",
      "AA2-3-3",
      "AA3-2-1",
      "AA3-2-2",
      "AA3-2-3",
      "AA3-3-1",
      "AA3-3-2",
      "AA3-3-3",
      "AA3-4-1",
      "AA3-4-2",
      "AA3-4-3",
      "AA4-1-1",
      "AA4-1-2",
      "AA4-2-1",
      "AA4-2-2",
      "AA4-3-1",
      "AA4-3-2",
      "AA4-4-1",
      "AA4-4-2",
      "AA4-5-1",
      "AA4-5-2",
      "AA5-1-1",
      "AA5-1-2",
      "AA5-2-1",
      "AA5-2-2",
      "AA5-3-1",
      "AA5-3-2",
      "AA5-4-1",
      "AA5-4-2",
      "AA5-5-1",
      "AA5-5-2",
      "AA6-1-1",
      "AA6-1-2",
      "AA6-2-1",
      "AA6-2-2",
      "AA6-3-1",
      "AA6-3-2",
      "AA6-4-1",
      "AA6-4-2",
      "AA6-5-1",
      "AA6-5-2",
      "AA7-1-1",
      "AA7-1-2",
      "AA7-2-1",
      "AA7-2-2",
      "AA7-3-1",
      "AA7-3-2",
      "AA7-4-1",
      "AA7-4-2",
      "AA7-5-1",
      "AA7-5-2",
      "AA8-1-1",
      "AA8-1-2",
      "AA8-2-1",
      "AA8-2-2",
      "AA8-3-1",
      "AA8-3-2",
      "AA8-4-1",
      "AA8-4-2",
      "AA8-5-1",
      "AA8-5-2",
      "AA9-1-1",
      "AA9-1-2",
      "AA9-2-1",
      "AA9-2-2",
      "AA9-3-1",
      "AA9-3-2",
      "AA9-4-1",
      "AA9-4-2",
      "AA9-5-1",
      "AA9-5-2",
      "AG1-1-1",
      "AG1-1-2",
      "AG1-1-3",
      "AG1-2-1",
      "AG1-2-2",
      "AG1-2-3",
      "AG1-3-1",
      "AG1-3-2",
      "AG1-3-3",
      "AG1-4-1",
      "AG1-4-2",
      "AG1-4-3",
      "AG1-5-1",
      "AG1-5-2",
      "AG1-5-3",
      "AG2-1-1",
      "AG2-1-2",
      "AG2-1-3",
      "AG2-2-1",
      "AG2-2-2",
      "AG2-2-3",
      "AG2-3-1",
      "AG2-3-2",
      "AG2-3-3",
      "AG2-4-1",
      "AG2-4-2",
      "AG2-4-3",
      "AG2-5-1",
      "AG2-5-2",
      "AG2-5-3",
      "AG3-1-1",
      "AG3-1-2",
      "AG3-1-3",
      "AG3-2-1",
      "AG3-2-2",
      "AG3-2-3",
      "AG3-3-1",
      "AG3-3-2",
      "AG3-3-3",
      "AG3-4-1",
      "AG3-4-2",
      "AG3-4-3",
      "AG3-5-1",
      "AG3-5-2",
      "AG3-5-3",
      "AG4-1-1",
      "AG4-1-2",
      "AG4-1-3",
      "AG4-2-1",
      "AG4-2-2",
      "AG4-2-3",
      "AG4-3-1",
      "AG4-3-2",
      "AG4-3-3",
      "AG4-4-1",
      "AG4-4-2",
      "AG4-4-3",
      "AG4-5-1",
      "AG4-5-2",
      "AG4-5-3",
      "AG5-1-1",
      "AG5-2-1",
      "AG5-3-1",
      "AG5-4-1",
      "AG6-1-1",
      "AG6-1-2",
      "AG6-2-1",
      "AG6-2-2",
      "AG7-1-1",
      "AG7-1-2",
      "AG7-1-3",
      "AG7-2-1",
      "AG7-2-2",
      "AG7-2-3",
      "AG7-3-1",
      "AG7-3-2",
      "AG7-3-3",
      "AG7-4-1",
      "AG7-4-2",
      "AG7-4-3",
      "AG8-1-1",
      "AG8-1-2",
      "AG8-1-3",
      "AG8-2-1",
      "AG8-2-2",
      "AG8-2-3",
      "AG8-3-1",
      "AG8-3-2",
      "AG8-3-3",
      "AG8-4-1",
      "AG8-4-2",
      "AG8-4-3",
      "AH1-1-1",
      "AH1-1-2",
      "AH1-1-3",
      "AH1-2-1",
      "AH1-2-2",
      "AH1-2-3",
      "AH1-3-1",
      "AH1-3-2",
      "AH1-3-3",
      "AH1-4-1",
      "AH1-4-2",
      "AH1-4-3",
      "AH2-1-1",
      "AH2-1-2",
      "AH2-1-3",
      "AH2-2-1",
      "AH2-2-2",
      "AH2-2-3",
      "AH2-3-1",
      "AH2-3-2",
      "AH2-3-3",
      "AH2-4-1",
      "AH2-4-2",
      "AH2-4-3",
      "AH3-1-1",
      "AH3-1-2",
      "AH3-1-3",
      "AH3-2-1",
      "AH3-2-2",
      "AH3-2-3",
      "AH3-3-1",
      "AH3-3-2",
      "AH3-3-3",
      "AH3-4-1",
      "AH3-4-2",
      "AH3-4-3",
      "AH4-1-1",
      "AH4-1-2",
      "AH4-1-3",
      "AH4-2-1",
      "AH4-2-2",
      "AH4-2-3",
      "AH4-3-1",
      "AH4-3-2",
      "AH4-3-3",
      "AH4-4-1",
      "AH4-4-2",
      "AH4-4-3",
      "AH5-1-1",
      "AH5-1-2",
      "AH5-2-1",
      "AH5-2-2",
      "AH5-3-1",
      "AH5-3-2",
      "AH5-4-1",
      "AH5-4-2",
      "AH5-5-1",
      "AH5-5-2",
      "AH6-1-1",
      "AH6-1-2",
      "AH6-1-3",
      "AH6-2-1",
      "AH6-2-2",
      "AH6-2-3",
      "AH7-1-1",
      "AH7-1-2",
      "AH7-2-1",
      "AH7-2-2",
      "AH7-3-1",
      "AH7-3-2",
      "AH7-4-1",
      "AH7-4-2",
      "AH8-1-1",
      "AH8-1-2",
      "AH8-1-3",
      "AH8-2-1",
      "AH8-2-2",
      "AH8-2-3",
      "AH8-3-1",
      "AH8-3-2",
      "AH8-3-3",
      "AH8-4-1",
      "AH8-4-2",
      "AH8-4-3",
      "AI1-1-1",
      "AI1-1-2",
      "AI1-1-3",
      "AI1-2-1",
      "AI1-2-2",
      "AI1-2-3",
      "AI1-3-1",
      "AI1-3-2",
      "AI1-3-3",
      "AI1-4-1",
      "AI1-4-2",
      "AI1-4-3",
      "AI1-5-1",
      "AI1-5-2",
      "AI1-5-3",
      "AI2-1-1",
      "AI2-1-2",
      "AI2-1-3",
      "AI2-2-1",
      "AI2-2-2",
      "AI2-2-3",
      "AI2-3-1",
      "AI2-3-2",
      "AI2-3-3",
      "AI2-4-1",
      "AI2-4-2",
      "AI2-4-3",
      "AI2-5-1",
      "AI2-5-2",
      "AI2-5-3",
      "AI3-1-1",
      "AI3-1-2",
      "AI3-1-3",
      "AI3-2-1",
      "AI3-2-2",
      "AI3-2-3",
      "AI3-3-1",
      "AI3-3-2",
      "AI3-3-3",
      "AI3-4-1",
      "AI3-4-2",
      "AI3-4-3",
      "AI3-5-1",
      "AI3-5-2",
      "AI3-5-3",
      "AI4-1-1",
      "AI4-1-2",
      "AI4-1-3",
      "AI4-2-1",
      "AI4-2-2",
      "AI4-2-3",
      "AI4-3-1",
      "AI4-3-2",
      "AI4-3-3",
      "AI4-4-1",
      "AI4-4-2",
      "AI4-4-3",
      "AI4-5-1",
      "AI4-5-2",
      "AI4-5-3",
      "AI5-1-1",
      "AI5-2-1",
      "AI5-3-1",
      "AI5-4-1",
      "AI6-1-1",
      "AI6-1-2",
      "AI6-1-3",
      "AI6-2-1",
      "AI6-2-2",
      "AI6-2-3",
      "AI7-1-1",
      "AI7-1-2",
      "AI7-2-1",
      "AI7-2-2",
      "AI7-3-1",
      "AI7-3-2",
      "AI7-4-1",
      "AI7-4-2",
      "AI8-1-1",
      "AI8-1-2",
      "AI8-1-3",
      "AI8-2-1",
      "AI8-2-2",
      "AI8-2-3",
      "AI8-3-1",
      "AI8-3-2",
      "AI8-3-3",
      "AI8-4-1",
      "AI8-4-2",
      "AI8-4-3",
      "AJ1-1-1",
      "AJ1-1-2",
      "AJ1-1-3",
      "AJ1-2-1",
      "AJ1-2-2",
      "AJ1-2-3",
      "AJ1-3-1",
      "AJ1-3-2",
      "AJ1-3-3",
      "AJ1-4-1",
      "AJ1-4-2",
      "AJ1-4-3",
      "AJ1-5-1",
      "AJ1-5-2",
      "AJ1-5-3",
      "AJ2-1-1",
      "AJ2-1-2",
      "AJ2-1-3",
      "AJ2-2-1",
      "AJ2-2-2",
      "AJ2-2-3",
      "AJ2-3-1",
      "AJ2-3-2",
      "AJ2-3-3",
      "AJ2-4-1",
      "AJ2-4-2",
      "AJ2-4-3",
      "AJ2-5-1",
      "AJ2-5-2",
      "AJ2-5-3",
      "AJ3-1-1",
      "AJ3-1-2",
      "AJ3-1-3",
      "AJ3-2-1",
      "AJ3-2-2",
      "AJ3-2-3",
      "AJ3-3-1",
      "AJ3-3-2",
      "AJ3-3-3",
      "AJ3-4-1",
      "AJ3-4-2",
      "AJ3-4-3",
      "AJ3-5-1",
      "AJ3-5-2",
      "AJ3-5-3",
      "AJ4-1-1",
      "AJ4-1-2",
      "AJ4-1-3",
      "AJ4-2-1",
      "AJ4-2-2",
      "AJ4-2-3",
      "AJ4-3-1",
      "AJ4-3-2",
      "AJ4-3-3",
      "AJ4-4-1",
      "AJ4-4-2",
      "AJ4-4-3",
      "AJ4-5-1",
      "AJ4-5-2",
      "AJ4-5-3",
      "AJ5-1-1",
      "AJ5-1-2",
      "AJ5-2-1",
      "AJ5-2-2",
      "AJ5-3-1",
      "AJ5-3-2",
      "AJ5-4-1",
      "AJ5-4-2",
      "AJ5-5-1",
      "AJ5-5-2",
      "AJ5-6-1",
      "AJ5-6-2",
      "AJ5-7-1",
      "AJ5-7-2",
      "AJ6-1-1",
      "AJ6-1-2",
      "AJ6-1-3",
      "AJ6-2-1",
      "AJ6-2-2",
      "AJ6-2-3",
      "AJ7-1-1",
      "AJ7-1-2",
      "AJ7-2-1",
      "AJ7-2-2",
      "AJ7-3-1",
      "AJ7-3-2",
      "AJ7-4-1",
      "AJ7-4-2",
      "AJ8-1-1",
      "AJ8-1-2",
      "AJ8-1-3",
      "AJ8-2-1",
      "AJ8-2-2",
      "AJ8-2-3",
      "AJ8-3-1",
      "AJ8-3-2",
      "AJ8-3-3",
      "AJ8-4-1",
      "AJ8-4-2",
      "AJ8-4-3",
      "AK1-1-1",
      "AK1-1-2",
      "AK1-1-3",
      "AK1-2-1",
      "AK1-2-2",
      "AK1-2-3",
      "AK1-3-1",
      "AK1-3-2",
      "AK1-3-3",
      "AK1-4-1",
      "AK1-4-2",
      "AK1-4-3",
      "AK1-5-1",
      "AK1-5-2",
      "AK1-5-3",
      "AK2-1-1",
      "AK2-1-2",
      "AK2-1-3",
      "AK2-2-1",
      "AK2-2-2",
      "AK2-2-3",
      "AK2-3-1",
      "AK2-3-2",
      "AK2-3-3",
      "AK2-4-1",
      "AK2-4-2",
      "AK2-4-3",
      "AK2-5-1",
      "AK2-5-2",
      "AK2-5-3",
      "AK3-1-1",
      "AK3-1-2",
      "AK3-1-3",
      "AK3-2-1",
      "AK3-2-2",
      "AK3-2-3",
      "AK3-3-1",
      "AK3-3-2",
      "AK3-3-3",
      "AK3-4-1",
      "AK3-4-2",
      "AK3-4-3",
      "AK3-5-1",
      "AK3-5-2",
      "AK3-5-3",
      "AK4-1-1",
      "AK4-1-2",
      "AK4-1-3",
      "AK4-2-1",
      "AK4-2-2",
      "AK4-2-3",
      "AK4-3-1",
      "AK4-3-2",
      "AK4-3-3",
      "AK4-4-1",
      "AK4-4-2",
      "AK4-4-3",
      "AK4-5-1",
      "AK4-5-2",
      "AK4-5-3",
      "AK6-1-1",
      "AK6-1-2",
      "AK6-1-3",
      "AK6-2-1",
      "AK6-2-2",
      "AK6-2-3",
      "AK7-1-1",
      "AK7-1-2",
      "AK7-2-1",
      "AK7-2-2",
      "AK7-3-1",
      "AK7-3-2",
      "AK7-4-1",
      "AK7-4-2",
      "AK8-1-1",
      "AK8-1-2",
      "AK8-1-3",
      "AK8-2-1",
      "AK8-2-2",
      "AK8-2-3",
      "AK8-3-1",
      "AK8-3-2",
      "AK8-3-3",
      "AK8-4-1",
      "AK8-4-2",
      "AK8-4-3",
      "B1-1-1",
      "B1-1-2",
      "B1-2-1",
      "B1-2-2",
      "B1-3-1",
      "B1-3-2",
      "B1-4-1",
      "B1-4-2",
      "B1-5-1",
      "B1-5-2",
      "B2-1-1",
      "B2-1-2",
      "B2-2-1",
      "B2-2-2",
      "B2-3-1",
      "B2-3-2",
      "B2-4-1",
      "B2-4-2",
      "B2-5-1",
      "B2-5-2",
      "B3-1-1",
      "B4-1-1",
      "B4-1-2",
      "B4-2-1",
      "B4-2-2",
      "B4-3-1",
      "B4-3-2",
      "B4-4-1",
      "B4-4-2",
      "B4-5-1",
      "B4-5-2",
      "B5-1-1",
      "B5-1-2",
      "B5-2-1",
      "B5-2-2",
      "B5-3-1",
      "B5-3-2",
      "B5-4-1",
      "B5-4-2",
      "B5-5-1",
      "B5-5-2",
      "C1-1-1",
      "C1-1-2",
      "C1-2-1",
      "C1-2-2",
      "C1-3-1",
      "C1-3-2",
      "C1-4-1",
      "C1-4-2",
      "C2-1-1",
      "C2-1-2",
      "C2-2-1",
      "C2-2-2",
      "C2-3-1",
      "C2-3-2",
      "C2-4-1",
      "C2-4-2",
      "C3-1-1",
      "C3-1-2",
      "C3-2-1",
      "C3-2-2",
      "C3-3-1",
      "C3-3-2",
      "C3-4-1",
      "C3-4-2",
      "F1-1-1",
      "F1-1-2",
      "F1-1-3",
      "F1-2-1",
      "F1-2-2",
      "F1-2-3",
      "F1-3-1",
      "F1-3-2",
      "F1-3-3",
      "F1-4-1",
      "F1-4-2",
      "F1-4-3",
      "F1-5-1",
      "F1-5-2",
      "F1-5-3",
      "F1-6-1",
      "F1-6-2",
      "F1-6-3",
      "F2-1-1",
      "F2-1-2",
      "F2-1-3",
      "F2-2-1",
      "F2-2-2",
      "F2-2-3",
      "F2-3-1",
      "F2-3-2",
      "F2-3-3",
      "F2-4-1",
      "F2-4-2",
      "F2-4-3",
      "F2-5-1",
      "F2-5-2",
      "F2-5-3",
      "F2-6-1",
      "F2-6-2",
      "F2-6-3",
      "F3-1-1",
      "F3-1-2",
      "F3-1-3",
      "F3-2-1",
      "F3-2-2",
      "F3-2-3",
      "F3-3-1",
      "F3-3-2",
      "F3-3-3",
      "F3-4-1",
      "F3-4-2",
      "F3-4-3",
      "F3-5-1",
      "F3-5-2",
      "F3-5-3",
      "F3-6-1",
      "F3-6-2",
      "F3-6-3",
      "F4-1-1",
      "F4-1-2",
      "F4-1-3",
      "F4-2-1",
      "F4-2-2",
      "F4-2-3",
      "F4-3-1",
      "F4-3-2",
      "F4-3-3",
      "F4-4-1",
      "F4-4-2",
      "F4-4-3",
      "F4-5-1",
      "F4-5-2",
      "F4-5-3",
      "F4-6-1",
      "F4-6-2",
      "F4-6-3",
      "X1",
      "X10",
      "X2",
      "X3",
      "X4",
      "X5",
      "X6",
      "X7",
      "X8",
      "X9",
      "X10",
      "X11",
      "X12",
      "X13",
      "X14",
    ],
  }),

  computed: {
    progress() {
      return (this.checked / this.bins) * 100;
    },
  },

  watch: {
    "stockcheck.stockchecks"(v, ov) {
      if (v.length > ov.length) {
        let tmp = v.map((l) => l.added || l.item._id);
        let tmp2 = v.map((l) => l._id);
        let tmp3 = [];

        this.databin.forEach((line) => {
          if (
            line.item &&
            tmp.includes(line.item._id) &&
            !tmp2.includes(line._id)
          )
            tmp3.push({ ...line, added: true });
        });
        if (tmp3.length) {
          this.stockcheck.stockchecks.push(...tmp3);
        }
      }
    },
    databin(val) {
      console.log("databin watch");
      let tmp = Object.values(
        this.databin
          .filter((v) => {
            if (this.onhandonly && !v.quantityonhand) return false;
            if (this.brand.length && !this.brand.includes(v.manufacturergroup))
              return false;
            return true;
          })
          .reduce((t, v) => {
            //sector
            t[v.sector] = t[v.sector] || {
              name: v.sector,
              _id: v.sector,
              laststockcheck: v.laststockcheck
                ? new Date(v.laststockcheck)
                : v.quantityonhand
                ? null
                : undefined,
              manufacturergroup: [],
              quantityonhand: 0,
              pallet: false,
              children: {},
            };
            if (this.pallet.includes(v.bin)) t[v.sector].pallet = true;
            //bin
            if (v.sector != v.bin) {
              t[v.sector].children[v.bin] = t[v.sector].children[v.bin] || {
                name: v.bin,
                _id: v.bin,
                laststockcheck: v.laststockcheck
                  ? new Date(v.laststockcheck)
                  : v.quantityonhand
                  ? null
                  : undefined,
                manufacturergroup: [],
                quantityonhand: 0,
                children: [],
              };
            }

            if (!v.laststockcheck && v.quantityonhand)
              t[v.sector].laststockcheck = null;
            else if (
              v.quantityonhand &&
              ((t[v.sector].laststockcheck &&
                new Date(v.laststockcheck) <
                  new Date(t[v.sector].laststockcheck)) ||
                t[v.sector].laststockcheck === undefined)
            )
              t[v.sector].laststockcheck = new Date(v.laststockcheck);
            //kryterium daty
            if (
              this.datefrom &&
              new Date(this.datefrom) > t[v.sector].laststockcheck
            )
              t[v.sector].laststockcheck = null;

            if (v.sector != v.bin) {
              if (!v.laststockcheck && v.quantityonhand)
                t[v.sector].children[v.bin].laststockcheck = null;
              else if (
                v.quantityonhand &&
                ((t[v.sector].children[v.bin].laststockcheck &&
                  new Date(v.laststockcheck) <
                    new Date(t[v.sector].children[v.bin].laststockcheck)) ||
                  t[v.sector].children[v.bin].laststockcheck === undefined)
              )
                t[v.sector].children[v.bin].laststockcheck = new Date(
                  v.laststockcheck
                );

              //kryterium daty
              if (
                this.datefrom &&
                new Date(this.datefrom) >
                  t[v.sector].children[v.bin].laststockcheck
              )
                t[v.sector].children[v.bin].laststockcheck = null;
            }

            if (v.sector != v.bin) {
              t[v.sector].children[v.bin].children.push(v);
            } else {
              t[v.sector].children[v._id] = v;
            }

            //manufacturergroup
            if (!t[v.sector].manufacturergroup.includes(v.manufacturergroup))
              t[v.sector].manufacturergroup.push(v.manufacturergroup);
            if (
              t[v.sector].children[v.bin] &&
              !t[v.sector].children[v.bin].manufacturergroup.includes(
                v.manufacturergroup
              )
            )
              t[v.sector].children[v.bin].manufacturergroup.push(
                v.manufacturergroup
              );

            // quantity
            t[v.sector].quantityonhand += v.quantityonhand || 0;
            if (v.sector != v.bin && t[v.sector].children[v.bin]) {
              t[v.sector].children[v.bin].quantityonhand +=
                v.quantityonhand || 0;
            }

            //fill brands
            if (!this.brands.includes(v.manufacturergroup))
              this.brands.push(v.manufacturergroup);

            return t;
          }, {})
      );
      this.checked = 0;
      this.bins = 0;
      this.items[0].children = tmp.map((l) => {
        this.bins++;
        if (l.laststockcheck) this.checked++;
        l.children = Object.values(l.children);
        return l;
      });
    },
  },

  methods: {
    createStockCheck() {
      console.log(this.stockcheck);
      this.$router.push({
        name: "Transactions",
        params: this.stockcheck,
      });
    },
    filterTree(item, search, textKey) {
      if (item[textKey]) return item[textKey].includes(search);
      else if (item.item) return item.item[textKey].includes(search);
    },
    // filterData() {
    //   this.databin = this.databin.filter((v) => {
    //     if (this.onhandonly && !v.quantityonhand) return false;
    //     if (this.brand.length && !this.brand.includes(v.manufacturergroup))
    //       return false;
    //     return true;
    //   });
    //   this.sortPerField();
    // },
    sortPerField() {
      console.log("sort");
      this.databin = this.databin.sort((a, b) => {
        //prorytet sektor
        if (a.bin < b.bin) {
          return -1 * this.sort.bin;
        }
        if (a.bin > b.bin) {
          return 1 * this.sort.bin;
        }
        if (a.laststockcheck < b.laststockcheck) {
          return -1 * this.sort.laststockcheck;
        }
        if (a.laststockcheck > b.laststockcheck) {
          return 1 * this.sort.laststockcheck;
        }

        return 0;
      });
    },
    sortBy(arr, field) {
      return arr.sort((a, b) => {
        if (a[field] < b[field]) {
          return -1;
        }
        if (a[field] > b[field]) {
          return 1;
        }
        return 0;
      });
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    async fetch() {
      this.loading = true;
      let result = await service(
        "items",
        {
          action: "getStockCheck",
          location: "5e0dbaba9e33df43f0b3a495",
          planner: true,
        },
        false,
        false
      );

      this.databin = result.items;
      this.sortPerField();

      this.loading = false;
    },
  },
};
</script>
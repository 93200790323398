<template>
  <v-card tile>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Other Management Tools</v-toolbar-title>
    </v-toolbar>
    <v-tabs dark v-model="active">
      <v-tab :key="1">Estimated Time Of Arrival</v-tab>
      <v-tab :key="2">Customers On Hold</v-tab>
      <v-tab :key="3">Shipment Alert</v-tab>
      <v-tab :key="4">Reallocate Items</v-tab>
      <v-tab :key="5">Prepaid Invoices - Not Shipped Yet</v-tab>
      <!-- <v-tab :key="6">Shipping Cost - Controlling</v-tab> -->
    <v-tab :key="6"><v-icon>mdi-close-outline</v-icon></v-tab>

      <v-tab-item :key="1">
        <estimatedtimeofarrival v-if="active == 0" />
      </v-tab-item>
      <v-tab-item :key="2">
        <customersonhold v-if="active == 1" />
      </v-tab-item>
      <v-tab-item :key="3">
        <planneddateofshippment v-if="active == 2" />
      </v-tab-item>
      <v-tab-item :key="4">
        <reallocateitems v-if="active == 3" />
      </v-tab-item>
      <v-tab-item :key="5">
        <queryresult queryid="5f2be7f654fc0b1f78c04951" v-if="active == 4" />
      </v-tab-item>
      <!-- <v-tab-item :key="6">
        <v-tabs dark v-model="active2">
          <v-tab :key="1">Packages</v-tab>
          <v-tab :key="2">Palettes</v-tab>
          <v-tab-item :key="1">
            <queryresult
              queryid="615ffdcd2fba8b4f5c290c0d"
              v-if="active == 5"
            />
          </v-tab-item>
          <v-tab-item :key="1">
            <queryresult
              queryid="61a4904e31275c355ca1786a"
              v-if="active == 5"
            />
          </v-tab-item>
        </v-tabs>
      </v-tab-item> -->
      <!-- <v-tab-item :key="8">
        <statistics v-if="active==7" />
      </v-tab-item>-->
    </v-tabs>
  </v-card>
</template>

<script>
import estimatedtimeofarrival from "../components/tools/estimatedtimeofarrival/View";
import planneddateofshippment from "../components/tools/planneddateofshippment/View";
import customersonhold from "../components/tools/customersonhold/View";
import reallocateitems from "../components/tools/reallocateitems/View";

/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
      active: 5,
      active2: 0,
    };
  },
  components: {
    estimatedtimeofarrival,
    planneddateofshippment,
    customersonhold,
    reallocateitems,
  },
};
</script>
<template>
  <v-tabs vertical dark>
    <v-tooltip bottom>
      <span>Overview</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-information-outline</v-icon>
        </v-tab>
      </template>
    </v-tooltip>

    <v-tooltip bottom>
      <span>Related Transactions</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-format-list-bulleted</v-icon>
        </v-tab>
      </template>
    </v-tooltip>

    <v-tooltip bottom>
      <span>Communications</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-email-open</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Logs</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-math-log</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tab-item>
      <v-container fluid>
        <fiscalPrinter
          :record="record"
          v-if="
            $route.params.mode == 'view' &&
            record.company._id == 'brakespl' &&
            record.customtype &&
            record.customtype._id == '5e0dbaba9e33df43f0b3a4e4'
          "
        />
        <v-row>
          <v-col>
            <v-chip
              v-if="overdue"
              label
              color="red"
              text-color="white"
              class="ma-2"
            >
              <v-icon left>mdi-fire</v-icon>Overdue
            </v-chip>
            <v-chip
              v-if="noprofit"
              label
              color="red"
              text-color="white"
              class="ma-2"
            >
              <v-icon left>mdi-trending-down</v-icon>No Profit
            </v-chip>
            <v-chip
              v-if="record.validtaxnumber == false"
              label
              color="red"
              text-color="white"
              class="ma-2"
            >
              <v-icon left>mdi-alert-circle</v-icon>Invalid VAT Number
            </v-chip>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card flat>
              <v-row>
                <v-col>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Customer:</v-list-item-subtitle>
                        <v-list-item-title>
                          <router-link
                            v-if="record.customer"
                            :to="`/users/${record.customer.recordtype}/view/${record.customer._id}`"
                            >{{ record.customer.name }}</router-link
                          >
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle
                          >Document Number:</v-list-item-subtitle
                        >
                        <v-list-item-title>{{
                          record.name || "To Be Generate"
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle
                          >Created From:</v-list-item-subtitle
                        >
                        <v-list-item-title>
                          <router-link
                            v-for="createdfrom of record.createdfrom"
                            :key="createdfrom._id"
                            :to="`/transactions/${createdfrom.recordtype}/view/${createdfrom._id}`"
                            >{{ createdfrom.name }}</router-link
                          >
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Date:</v-list-item-subtitle>
                        <v-list-item-title>{{ date }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Amount:</v-list-item-subtitle>
                        <v-list-item-title>{{ amount }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Tax Amount:</v-list-item-subtitle>
                        <v-list-item-title>{{ taxamount }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle
                          >Gross Amount:</v-list-item-subtitle
                        >
                        <v-list-item-title>{{ grossamount }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Quantity:</v-list-item-subtitle>
                        <v-list-item-title>{{
                          record.quantity
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          Due Date:
                          <v-icon left v-if="overdue" color="red"
                            >mdi-fire</v-icon
                          >
                        </v-list-item-subtitle>
                        <v-list-item-title>{{ duedate }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Paid:</v-list-item-subtitle>
                        <v-list-item-title>{{ paid }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Unapplied:</v-list-item-subtitle>
                        <v-list-item-title>{{ unapplied }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Weight:</v-list-item-subtitle>
                        <v-list-item-title>{{ weight }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
              <v-divider></v-divider>

              <v-list>
                <!-- <v-divider inset></v-divider> -->
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">business</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      billingaddress.name
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      >Billing Address:
                      {{ billingaddress.address }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">local_shipping</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      shippingaddress.name
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      >Shipping Address:
                      {{ shippingaddress.address }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col>
            <field-map :addresses="addresses" />
          </v-col>
        </v-row>
      </v-container>
    </v-tab-item>
    <v-tab-item>
      <tool-relatedtransactions :record="record" />
    </v-tab-item>
    <v-tab-item>
      <tool-inbox :record="record" :customer="record.customer._id" />
    </v-tab-item>
    <v-tab-item>
      <tool-logs :record="record._id" />
    </v-tab-item>
  </v-tabs>
</template>
<script>
import service from "../../../api/index";
import fiscalPrinter from "../../tools/fiscalprinter/View.vue";
/* eslint-disable */
export default {
  props: ["record"],
  data() {
    return {
      loading: false,
      photosrc: "",
    };
  },
  // watch: {
  //   "record.amount": {
  //     handler: async function(after, before) {
  //       console.log(after)
  //     },
  //     deep: true
  //   }
  // },
  computed: {
    date() {
      return this.record.date
        ? new Date(this.record.date).toISOString().substr(0, 10)
        : "";
    },
    weight() {
      return this.record.weight
        ? `${parseFloat(this.record.weight).toFixed(2)} kg`
        : `0.00 kg`;
    },
    customer() {
      //console.log("Customer", this.record.customer)
      return this.record.customer ? this.record.customer.name : "";
    },
    duedate() {
      return this.record.duedate
        ? new Date(this.record.duedate).toISOString().substr(0, 10)
        : null;
    },
    amount() {
      return this.record.amount
        ? `${this.record.currency.symbol} ${parseFloat(
            this.record.amount
          ).toFixed(2)}`
        : `${this.record.currency ? this.record.currency.symbol : ""} 0.00`;
    },
    grossamount() {
      return this.record.grossamount
        ? `${this.record.currency.symbol} ${parseFloat(
            this.record.grossamount
          ).toFixed(2)}`
        : `${this.record.currency ? this.record.currency.symbol : ""} 0.00`;
    },
    taxamount() {
      return this.record.taxamount
        ? `${this.record.currency.symbol} ${parseFloat(
            this.record.taxamount
          ).toFixed(2)}`
        : `${this.record.currency ? this.record.currency.symbol : ""} 0.00`;
    },
    paid() {
      return this.record.paid
        ? `${this.record.currency.symbol} ${parseFloat(
            this.record.paid
          ).toFixed(2)}`
        : `${this.record.currency ? this.record.currency.symbol : ""} 0.00`;
    },
    amountdue() {
      return this.record.amountdue
        ? `${this.record.currency.symbol} ${parseFloat(
            this.record.amountdue
          ).toFixed(2)}`
        : `${this.record.currency ? this.record.currency.symbol : ""} 0.00`;
    },
    addresses() {
      let addresses = [];
      if (this.record.billingaddress)
        addresses.push(this.record.billingaddress);
      if (this.record.shippingaddress)
        addresses.push(this.record.shippingaddress);
      return addresses;
    },
    billingaddress() {
      let address = { name: "", address: "" };
      address.name = this.record.billingaddress
        ? this.record.billingaddress.addressee
        : "" || "";
      if (this.record.billaddress) address.address += this.record.billaddress;
      if (this.record.billaddress2) address.address += this.record.billaddress2;
      if (this.record.billzip) address.address += `, ${this.record.billzip}`;
      if (this.record.billcity) address.address += `, ${this.record.billcity}`;
      if (this.record.billcountry)
        address.address += `, ${
          this.record.billcountry.name || this.record.billcountry
        }`;
      return address;
    },

    shippingaddress() {
      let address = { name: "", address: "" };
      address.name = this.record.shippingaddress
        ? this.record.shippingaddress.addressee
        : "" || "";
      if (this.record.shipaddress) address.address += this.record.shipaddress;
      if (this.record.shipaddress2) address.address += this.record.shipaddress2;
      if (this.record.shipzip) address.address += `, ${this.record.shipzip}`;
      if (this.record.shipcity) address.address += `, ${this.record.shipcity}`;
      if (this.record.shipcountry)
        address.address += `, ${
          this.record.shipcountry.name || this.record.shipcountry
        }`;
      return address;
    },
    overdue() {
      let overdue = false;
      if (this.record.duedate < new Date() && this.record.amountdue > 0)
        overdue = true;
      return overdue;
    },
    noprofit() {
      let noprofit = false;
      if (this.record.profit < 0) noprofit = true;
      return noprofit;
    },
    unapplied() {
      return this.record.unapplied
        ? `${this.record.currency.symbol} ${parseFloat(
            this.record.unapplied
          ).toFixed(2)}`
        : `${this.record.currency ? this.record.currency.symbol : ""} 0.00`;
    },
  },
  components: {
    fiscalPrinter,
  },
};
</script>
const apiURL = 'https://api.nbp.pl/api/exchangerates/rates/A/';
const urlParams = '/?format=json';
/* eslint-disable */
export function getRate(currency) {
  return fetch(apiURL + currency + urlParams)
    .then(resp => resp.json())
    .then(resp => {
      return {
        effectivedate: new Date(resp.rates[0].effectiveDate),
        rate: resp.rates[0].mid,
        memo: resp.rates[0].no,
        recordtype: 'exchangerate',
        currency: currency,
      };
    });
}

<template>
  <v-form v-model="valid" lazy-validation ref="form">
    <v-toolbar dark color="primary">
      <v-toolbar-title>Map Creator</v-toolbar-title>
    </v-toolbar>
    <v-progress-linear :indeterminate="loading" height="3" class="ma-0"></v-progress-linear>

    <setupa v-if="!loading" :map="map" class="mb-1"/>

    <v-container fluid grid-list-xl>
      <v-layout row wrap align-center>
        <v-flex md6>
          <v-btn
            v-if="$route.params.id"
            @click="saveRecord(rid);"
            :disabled="!valid"
            color="primary"
          >Save</v-btn>
          <v-btn @click="saveRecord();" :disabled="!valid" color="primary">Save As</v-btn>
        </v-flex>

        <v-flex md6>
          <v-btn v-if="$route.params.id" @click="deleteRecord(rid);" color="primary">Delete</v-btn>
        </v-flex>
      </v-layout>
    </v-container>

    <v-snackbar v-model="msg.show" :timeout="6000" top absolute right>{{ msg.message }}</v-snackbar>
  </v-form>
</template>

<script>

import setupa from './setup';
import saveRecord from '../../../common/methods/saveRecord';
import deleteRecord from '../../../common/methods/deleteRecord';
import getRecord from '../../../common/methods/getRecord';
/* eslint-disable */
export default {
  data() {
    return {
      valid: false,
      loading: true,
      msg: false,
      mode: this.$route.params.mode,
      rid: this.$route.params.id,
      map: {
        name: "Map Name",
        setup: { query: "" }
      }
    };
  },
  components: {
    setupa
  },
  methods: {
    saveRecord(rid) {
      saveRecord(this, this.map, "maps", "map", rid);
    },
    deleteRecord(rid) {
      deleteRecord(this, rid, "maps", "map");
    }
  },
  async created() {
    try {
      this.map = await getRecord(this, this.rid, "maps", "map");
      this.loading = false;
    } catch (error) {
      this.msg = { message: `${error.message}`, show: true };
    }
  }
};
</script>

<template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          :loading="loading"
          loading-text="Loading... Please wait"
          :footer-props="{itemsPerPageOptions:rows}"
        >
          <template v-slot:item.created="{ item }">
            <span>{{new Date(item.created).toISOString().substr(0, 10)}}</span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon class="mr-2" @click="create(item.recordtype,'view',item._id)">launch</v-icon>
            <v-icon class="mr-2" @click="create(item.recordtype,'edit',item._id)">edit</v-icon>
          </template>
          <template v-slot:footer>
            <v-divider></v-divider>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import service from "../../../api/index";

export default {
  props: ["customer", "record"],
  data() {
    return {
      active: 0,
      loading: true,
      search: "",
      show: true,
      items: [],
      rows: [-1, 25, 50],
      rid: null,
      headers: [
        { text: "Action", value: "action" },
        //{ text: "Created", value: "created" },
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Collection", value: "reportcollection" },
        { text: "Owner", value: "employee" },
        { text: "Frequency", value: "frequency" },
      ],
      employee: this.$store.state.user ? this.$store.state.user._id : null,
    };
  },
  async created() {
    this.loading = true;
    console.log(this.employee);
    let items = await service("queries", {
      action: "getReports",
      employee: this.employee,
    });
    this.items = items.items;
    this.loading = false;
  },
  watch: {},
  methods: {
    async create(recordtype, mode = "create", rid, customer = this.record) {
      this.$router.push({
        name: "Queries",
        params: {
          recordtype: recordtype,
          mode: mode,
          id: rid,
          customer: customer,
        },
      });
    },
  },
};
</script>
<template>
  <div v-if="companies.length">
    <v-tabs v-model="tab" centered dark icons-and-text>
      <v-tab v-for="(company, index) in companies" :key="index">
        {{ company.name }}
      </v-tab>
    </v-tabs>
    <organization-chart
      :datasource="org"
      :zoomin-limit="1"
      :zoomout-limit="0.5"
    >
      <template slot-scope="{ nodeData }">
        <v-card tile>
          <v-list-item two-line>
            <!-- <v-list-item-avatar
              v-if="nodeData.type != 'department'"
              color="primary"
            >
              <img v-if="nodeData.logo" :src="nodeData.logo" />
              <span v-else class="white--text text-h5"
                >{{ nodeData.firstname ? nodeData.firstname[0] : ""
                }}{{ nodeData.lastname ? nodeData.lastname[0] : "" }}</span
              >
            </v-list-item-avatar> -->

            <v-list-item-content>
              <v-list-item-title>{{ nodeData.name }}</v-list-item-title>
              <v-list-item-subtitle>{{
                nodeData.supervisor || nodeData.jobtitle
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            v-if="!['department', 'company'].includes(nodeData.type)"
          >
            <v-list-item-content>
              <v-list-item-subtitle
                class="text-caption"
                v-if="nodeData.phone && nodeData.phone != '-'"
                >{{ nodeData.phone }}</v-list-item-subtitle
              >
              <v-list-item-subtitle
                class="text-caption"
                v-if="nodeData.email && nodeData.email != '-'"
                >{{ nodeData.email }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-card>

        <!-- feel free to customize the internal structure of node -->
      </template>
    </organization-chart>
  </div>
</template>
 
<script>
/* eslint-disable */
import service from "../../../api/index";
import getQueryResult from "../../../common/methods/getQueryResult";
import getQuery from "../../../common/methods/getQuery";
import OrganizationChart from "vue-organization-chart";
import "./orgchart.css";

export default {
  components: {
    OrganizationChart,
  },
  data() {
    return {
      tab: 0,
      org: {},
      companies: [],
      company: null,
      users: [],
      departments: [],
    };
  },
  watch: {
    async tab(v) {
      this.company = this.companies[v];
      if (this.company && this.company.photo && this.company.photo._id) {
        this.company.photo = await service("files", {
          action: "findOne",
          data: [
            {
              _id: this.company.photo,
            },
          ],
        });
      }

      let departmensObj = await service("classification", {
        action: "find",
        data: [
          {
            recordtype: "department",
          },
        ],
      });
      departmensObj = departmensObj.reduce((t, d) => {
        t[d._id] = d;
        return t;
      }, {});

      this.users = await service("users", {
        action: "find",
        data: [
          {
            recordtype: "employee",
            hiredcompany: this.company._id,
            hiredstatus: "Active",
          },
        ],
      });
      let departmens = {};
      this.users.forEach((u) => {
        if (u.department) {
          departmens[u.department] = departmens[u.department] || {
            name: departmensObj[u.department].name,
            type: "department",
            supervisor: departmensObj[u.department].manager,
            children: [],
          };
        } else if(u.supervisor){
          departmens[u.name] = {
            _id: u._id,
            name: u.name,
            firstname: u.firstname,
            lastname: u.lastname,
            phone: u.phone,
            email: u.email,
            jobtitle: u.jobtitle,
          };
        }
      });
      console.log(departmens);
      this.users.forEach((u, index) => {
        if (u.department) {
          if (
            !u.supervisor ||
            u.supervisor ==
              (this.company.employee || "5e0dbabb9e33df43f0b3d17c")
          ) {
            departmens[u.department].children.push({
              _id: u._id,
              name: u.name,
              firstname: u.firstname,
              lastname: u.lastname,
              phone: u.phone,
              email: u.email,
              jobtitle: u.jobtitle,
              children: [],
            });

            // this.users.splice(index, 1);
          }
        }
      });
      this.users.forEach((u, index) => {
        if (u.department) {
          let supervisor = departmens[u.department].children.find(
            (s) => s._id == u.supervisor
          );

          if (supervisor) {
            supervisor.children.push({
              _id: u._id,
              name: u.name,
              firstname: u.firstname,
              lastname: u.lastname,
              phone: u.phone,
              email: u.email,
              jobtitle: u.jobtitle,
              children: [],
            });
            // this.users.splice(index, 1);
            console.log(supervisor);
          }
        }
      });

      this.departmens = Object.values(departmens);
      console.log(this.departmens);

      this.org = {
        type: "company",
        name: this.company.name,
        logo: this.company.photo ? this.company.photo.url : null,
        supervisor: "Przemek Wiśniewski",
        children: this.departmens,
      };
      console.log(this.org);
    },
  },
  async created() {
    this.companies = await service("users", {
      action: "find",
      data: [
        {
          recordtype: "company",
          _id: {
            $in: [
              "maincompany",
              "brakespl",
              "ozpartsuklimited",
              "6450a00c794d806289670493",
              "61d2a0cbfd3fd1eeebd8d708",
            ],
          },
        },
      ],
    });
    this.tab = 2;
  },
};
</script> 


<template>
  <div v-if="record.shippingmethod" :key="record.shippingmethod._id">
    <v-container
      v-if="
        record.shippingmethod &&
        record.shippingmethod.shippingcarrier &&
        '5e0dbaba9e33df43f0b3a4d7' ==
          (record.shippingmethod.shippingcarrier._id ||
            record.shippingmethod.shippingcarrier)
      "
    >
      <v-overlay :value="loading">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>

      <v-row>
        <v-col>
          <v-list two-line>
            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">local_shipping</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-text-field
                  dense
                  label="Company Name"
                  v-model="Shipment.receiver.address.companyName"
                  maxlength="35"
                  counter
                ></v-text-field>
                <v-text-field
                  dense
                  label="Name"
                  v-model="Shipment.receiver.address.name1"
                  maxlength="35"
                  counter
                ></v-text-field>

                <v-text-field
                  dense
                  label="Address Line #1"
                  v-model="Shipment.receiver.address.street"
                  maxlength="35"
                  counter
                ></v-text-field>

                <v-text-field
                  dense
                  label="Address Line #2"
                  v-model="Shipment.receiver.address.addressLine2"
                  maxlength="35"
                  counter
                ></v-text-field>

                <v-row>
                  <v-col>
                    <v-text-field
                      dense
                      label="City"
                      v-model="Shipment.receiver.address.city"
                      maxlength="30"
                      counter
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      dense
                      label="Zip Code"
                      v-model="Shipment.receiver.address.zipCode"
                      maxlength="9"
                      counter
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-text-field
                  dense
                  label="Country Code"
                  v-model="Shipment.receiver.address.country"
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col>
          <v-list two-line>
            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary"
                  >mdi-account-card-details-outline</v-icon
                >
              </v-list-item-action>

              <v-list-item-content>
                <v-select
                  v-model="Account"
                  :items="AccountNumbers"
                  item-text="name"
                  item-value="_id"
                  label="Account Number"
                  placeholder
                  outlined
                  hide-details
                  dense
                ></v-select>
                <!-- <v-list-item-title>{{ AccountNumber.number }}</v-list-item-title> -->
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">mdi-truck-fast</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-select
                  v-model="Service"
                  :items="services"
                  item-text="Description"
                  item-value="Code"
                  label="Service"
                  placeholder
                  outlined
                  hide-details
                  dense
                ></v-select>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">mdi-barcode-scan</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <!-- <v-list-item-subtitle>Label Format:</v-list-item-subtitle> -->
                <v-select
                  v-model="LabelImageFormat"
                  :items="LabelImageFormats"
                  item-text="Description"
                  item-value="Code"
                  label="Label Format"
                  placeholder
                  outlined
                  hide-details
                  dense
                ></v-select>
                <!-- <v-list-item-title>{{
                  LabelImageFormat.Description
                }}</v-list-item-title> -->
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-switch v-model="exportclearance" label="Export"></v-switch>
              </v-list-item-action>

              <v-list-item-content v-if="exportclearance">
                <!-- <v-list-item-subtitle>Label Format:</v-list-item-subtitle> -->
                <v-text-field
                  dense
                  label="Declarated Value"
                  v-model="declaratedValue"
                ></v-text-field>
                <v-select
                  v-model="declaratedCurrency"
                  :items="currencies"
                  label="Currency"
                  placeholder
                  outlined
                  hide-details
                  dense
                ></v-select>

                <!-- <v-list-item-title>{{
                  LabelImageFormat.Description
                }}</v-list-item-title> -->
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col>
          <v-list two-line>
            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">mdi-weight-kilogram</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-subtitle>Weight (Packages):</v-list-item-subtitle>
                <v-list-item-title>
                  {{
                    this.record.trackingnumbers.reduce((total, tracking) => {
                      return (total += parseInt(tracking.weight));
                    }, 0)
                  }}
                  kg
                  {{
                    this.record.trackingnumbers.length > 1
                      ? `(${this.record.trackingnumbers.length})`
                      : ""
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="record.codamount">
              <v-list-item-action>
                <v-icon color="primary">mdi-cash-multiple</v-icon>
              </v-list-item-action>

              <v-list-item-content v-if="record.recordtype != 'salesorder'">
                <v-list-item-subtitle>COD:</v-list-item-subtitle>
                <v-list-item-title>{{ record.codamount }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content v-else>
                <v-text-field
                  dense
                  type="number"
                  error
                  label="COD"
                  v-model="record.codamount"
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
            <v-row
              ><v-col class="py-0">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary"
                      >mdi-message-reply-text-outline</v-icon
                    >
                  </v-list-item-action>

                  <v-list-item-content>
                    <!-- <v-list-item-subtitle>Reference Number:</v-list-item-subtitle> -->
                    <v-text-field
                      label="Reference Number"
                      dense
                      v-model="ReferenceNumber"
                      hide-details
                    ></v-text-field>
                    <!-- <v-list-item-title>{{ AccountNumber }}</v-list-item-title> -->
                  </v-list-item-content>
                </v-list-item> </v-col
              ><v-col class="py-0">
                <v-list-item>
                  <v-list-item-content>
                    <!-- <v-list-item-subtitle>Shipment Description:</v-list-item-subtitle> -->
                    <v-text-field
                      label="Shipment Description"
                      dense
                      v-model="Description"
                      hide-details
                    ></v-text-field>
                    <!-- <v-list-item-title>{{ AccountNumber }}</v-list-item-title> -->
                  </v-list-item-content>
                </v-list-item>
              </v-col></v-row
            >

            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">phone</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <!-- <v-list-item-subtitle>Phone:</v-list-item-subtitle> -->
                <v-text-field
                  dense
                  label="Phone"
                  v-model="Shipment.receiver.contact.phone1"
                  maxlength="15"
                  counter
                ></v-text-field>
                <!-- <v-list-item-title>{{
                  Shipment.ShipTo.Phone.Number
                }}</v-list-item-title> -->
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">mail</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <!-- <v-list-item-subtitle>E-Mail:</v-list-item-subtitle> -->
                <v-text-field
                  dense
                  label="Email"
                  v-model="Shipment.receiver.contact.email"
                  maxlength="50"
                  counter
                ></v-text-field>
                <!-- <v-list-item-title>{{
                  Shipment.ShipTo.EMailAddress
                }}</v-list-item-title> -->
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-alert type="warning" v-if="warning">{{ warning }}</v-alert>
      <v-row>
        <v-col>
          <v-btn
            @click="shipment"
            block
            tile
            color="primary"
            :disabled="shipped || confirm"
            >Arrange Shipment</v-btn
          >
          <v-alert
            dense
            border="left"
            type="warning"
            v-model="confirm"
            transition="scale-transition"
            dismissible
          >
            <small
              >Please check custom product instruction before you arrange
              shipment</small
            >
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
  <script>
/* eslint-disable */
//import service from "../../../api/index";
import getRecord from "../../../common/methods/getRecord";
export default {
  props: ["record"],
  data() {
    return {
      exportclearance: false,
      declaratedValue: 0,
      declaratedCurrency: "EUR",
      currencies: ["EUR", "PLN", "GBP", "USD"],
      confirm: false,
      loading: false,
      AccountNumbers: [{ name: "50033", _id: "50033" }],
      Account: "50033",
      location: null,
      warning: "",
      ReferenceNumber: "",
      Description: "Auto parts",
      Service: "101",
      services: [
        { Code: "101", Description: "DPD Classic (max: 31kg)" },
        { Code: "109", Description: "DPD COD B2B (max: 31kg)" },
        { Code: "302", Description: "IE2 (International Express)" },
      ],

      LabelImageFormat: this.record.recordtype != "salesorder" ? "ZPL" : "PDF",
      LabelImageFormats: [
        {
          Code: "EPL",
          Description: "EPL",
        },
        {
          Code: "ZPL",
          Description: "ZPL",
        },
        {
          Code: "PDF",
          Description: "PDF",
        },
      ],
      headers: {
        "Content-Type": "application/json",
      },
      tmp: null,
    };
  },
  watch: {
    async "record.shippingmethod"(v) {
      if (v) {
        this.Service = this.record.shippingmethod.carrierservice || "101";
      }
    },
  },
  async created() {
    this.record.location = await getRecord(
      this,
      this.record.location._id,
      this.record.location.collection,
      this.record.location.recordtype
    );
    this.location = this.record.location;
    if (
      this.record.shippingmethod &&
      this.record.shippingmethod.shippingcarrier
    ) {
      this.Service = this.record.shippingmethod.carrierservice || "101";
    }
    this.ReferenceNumber = this.record.name;

    this.confirm = this.record.transactionlines.some((l) => l.memo);

    if (
      this.record.recordtype == "salesorder" &&
      ((this.record.shippingmethod && this.record.shippingmethod.codrequired) ||
        (this.record.terms && this.record.terms.codrequired))
    ) {
      this.record.codamount = this.record.grossamount;
    }
  },

  computed: {
    shipped: function () {
      return (
        !this.record.trackingnumbers.length ||
        this.record.trackingnumbers.some((tracking) => {
          return tracking.name;
        })
      );
    },
    Shipment: function () {
      console.log("default shippment");

      if (this.location) {
        let senderaddress = {
          companyName: this.location.shippingaddress.name || "",
          name1: this.location.shippingaddress.name || "",
          street: this.location.shippingaddress.address || "",
          addressLine2: this.location.shippingaddress.address2 || "",
          country: this.location.shippingaddress.country || "",
          zipCode: this.location.shippingaddress.zip || "",
          city: this.location.shippingaddress.city || "",
        };

        let shipment = this.tmp || {
          customerReferenceNumbers: [this.ReferenceNumber],
          shipmentInfos: {
            productCode: this.Service,
            //weight: "9999",
          },

          sender: {
            customerInfos: {
              customerAccountNumber: this.Account,
              customerID: this.Account,
            },
            address: senderaddress,
            contact: {
              phone1: this.location.shippingaddress.phone,
              email: this.location.shippingaddress.email,
            },
          },
          receiver: {
            //   customerInfos: {
            //     customerAccountNumber: "53453577",
            //   },
            address: {
              companyName:
                this.record.shipaddressee || this.record.shipname || "",
              name1: this.record.shipaddressee || this.record.shipname || "",
              street: this.record.shipaddress || "",
              addressLine2: this.record.shipaddress2 || "",
              country: this.record.shipcountry
                ? this.record.shipcountry._id
                : "",
              zipCode: this.record.shipzip || "",
              city: this.record.shipcity || "",
            },
            contact: {
              phone1: this.record.shipphone,
              email: this.record.email
                ? this.record.email
                : this.record.customer
                ? this.record.customer.email
                : "",
            },
          },
          numberOfParcels: 0,
          parcel: [],
        };

        if (this.exportclearance) {
          shipment.international = {
            customsAmount: {
              amount: this.declaratedValue,
              currency: this.declaratedCurrency,
            },
          };
        }

        console.log(shipment);
        if (!this.tmp) this.tmp = shipment;
        return shipment;
      }
      return null;
    },
  },
  methods: {
    async print(label, format, tracking_number) {
      // Dekodowanie Base64 do ciągu bajtów
      //const bytes = atob(label.base64Data);
      //console.log(label.base64Data);

      // Konwersja ciągu bajtów do TypedArray
      // const bytesArray = new Uint8Array(bytes.length);
      // for (let i = 0; i < bytes.length; i++) {
      //   bytesArray[i] = bytes.charCodeAt(i);
      // }

      // Użycie TextDecoder do dekodowania TypedArray do ciągu UTF-8
      // const decoder = new TextDecoder("utf-8");
      // let utf8 = decoder.decode(bytesArray);
      // console.log(utf8);

      // utf8 = utf8.replace("ZB", "");
      // utf8 = utf8.replace("I8,B,001", "I8,A,001");

      // utf8 = this.convertToASCII(utf8);
      // console.log(utf8);
      // let base64 = this.utf8ToBase64(utf8);
      if (["ZPL", "EPL"].includes(format)) {
        let myWindow = window.open(
          "http://127.0.0.1:4349/listPrinters?loc=pl_PL&app=www&name=labelWindow",
          "myWindow",
          "width=480, height=360"
        );

        setTimeout(function () {
          myWindow.postMessage(label.base64Data, "*");
        }, 1000);
        setTimeout(function () {
          myWindow.close();
        }, 5000);
      }

      if (["PDF"].includes(format)) {
        // Tworzymy blob z danymi Base64
        const binaryData = atob(label.base64Data);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }
        const blob = new Blob([arrayBuffer], {
          type: label["media-type"],
        });

        // Tworzymy URL obiektu blob
        const fileURL = URL.createObjectURL(blob);
        // Otwieramy plik PDF w nowym oknie przeglądarki
        window.open(fileURL, "_blank");

        if (tracking_number) {
          var aDom = document.createElement("a");
          aDom.setAttribute("style", "display:none");
          aDom.setAttribute("href", fileURL);
          aDom.setAttribute("download", `${tracking_number}.pdf`);
          document.body.appendChild(aDom);
          aDom.click();
          URL.revokeObjectURL(fileURL);
          document.body.removeChild(aDom);
        }
      }
    },
    convertToASCII(text) {
      const map = {
        ą: "a",
        ć: "c",
        ę: "e",
        ł: "l",
        ń: "n",
        ó: "o",
        ś: "s",
        ż: "z",
        ź: "z",
        Ą: "A",
        Ć: "C",
        Ę: "E",
        Ł: "L",
        Ń: "N",
        Ó: "O",
        Ś: "S",
        Ż: "Z",
        Ź: "Z",
      };

      return text.replace(/[ąćęłńóśżźĄĆĘŁŃÓŚŻŹ]/g, (match) => map[match]);
    },
    utf8ToBase64(str) {
      // Konwersja tekstu UTF-8 na ciąg, który może być zakodowany przez btoa
      const encodedUriComponent = encodeURIComponent(str);
      // Zamiana każdego wystąpienia "%XX" na odpowiedni znak
      const binaryStr = encodedUriComponent.replace(
        /%([0-9A-F]{2})/g,
        function (match, p1) {
          return String.fromCharCode("0x" + p1);
        }
      );

      // Kodowanie do Base64
      const base64 = btoa(binaryStr);

      return base64;
    },
    async shipment() {
      var response = confirm("Arrange Shipment - Are you sure?");
      if (response)
        try {
          this.Shipment.parcel = [];
          this.warning = "";
          this.loading = true;
          (this.record["trackingnumbers"] || []).forEach((number, index) => {
            let pck = {
              parcelInfos: {
                weight: `${number.weight * 1000}`,
                dimensions: {
                  length: parseInt(number.boxlength || 10),
                  height: parseInt(number.boxwidth || 10),
                  width: parseInt(number.boxheight || 10),
                },
              },
            };
            if (this.record.codamount && !index) {
              pck.cod = {
                amount: {
                  amount: parseFloat(this.record.codamount),
                  currency: "PLN",
                },
              };
            }
            if (!index && this.exportclearance) {
              pck.insurance = {
                insuranceAmount: {
                  amount: this.declaratedValue,
                  currency: this.declaratedCurrency,
                },
              };
            }
            this.Shipment.parcel.push(pck);
          });
          this.Shipment.numberOfParcels = `${this.Shipment.parcel.length}`;
          let request = [this.Shipment];

          let result = await fetch(
            `https://3cerp.cloud/api/dpd/shipment?LabelPrintFormat=${this.LabelImageFormat}&LabelPaperFormat=A6`,
            {
              method: "POST",
              headers: this.headers,
              body: JSON.stringify(request),
            }
          ).then(async (response) => {
            let responseData = {};
            responseData = await response.json();
            if (response.ok) {
              console.log(responseData);
              return responseData.data;
            } else throw responseData;
          });

          if (result.parcelIds) {
            result.parcelIds.forEach((pckg, index) => {
              console.log(pckg);
              this.record.trackingnumbers[index].name = pckg;
            });
          }
          if (result.label)
            this.print(
              result.label,
              this.LabelImageFormat,
              this.record.trackingnumbers[0].name
            );
        } catch (error) {
          this.warning = error.error ? error.error.message : error.message;
          console.log(error);
        }
      this.loading = false;
    },
  },
};
</script>
<template>
  <div v-if="($store.state.user && $store.state.user.role._id!='role_warehouse_uk') || ($route.params.recordtype=='itemfulfillment' || $route.params.recordtype=='inventorytransfer')">
    <queryresult v-if="!$route.params.mode" :queryid="$route.params.recordtype" />
    <record collection="transactions" :key="this.$route.params.id" v-else />
  </div>
  <v-alert v-else type="error">Access Denied</v-alert>
</template>

<script>
/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
    };
  },
};
</script>
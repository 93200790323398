<template>
  <v-card>
    <component :is="$route.params.tool" />
  </v-card>
</template>

<script>
import deliveriescalendar from "../../components/tools/deliveriescalendar/View.vue";
import quickorder from "../../components/tools/quickorder/View.vue";
import quickorder2 from "../../components/tools/quickorder/View2.vue";
import abcxyz from "../../components/tools/abcxyz/View.vue";
import statistics from "../../components/tools/statistics/ViewNEW";
import levam from "../../components/tools/levam/View.vue";
import borequests from "../../components/tools/requests/View.vue";
import org from "../../components/tools/org/View.vue";
/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
    };
  },
  components: {
    deliveriescalendar,
    simpleorder: quickorder,
    simpleorderbrakespl: quickorder2,
    abcxyz: abcxyz,
    statistics: statistics,
    levam: levam,
    etarequests: borequests,
    org: org,
  },
};
</script>
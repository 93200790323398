var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.loading)?_c('googlemap',{key:_vm.selected.length,attrs:{"addresses":_vm.selected.length ? _vm.selected : _vm.items},on:{"update:addresses":function($event){return _vm.$set(_vm.selected, "length ? selected : items", $event)}}}):_vm._e(),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","show-select":"","search":_vm.search,"single-select":_vm.singleSelect,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter},on:{"current-items":_vm.getFiltered},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.user.collection) + "/" + (item.user.recordtype) + "/view/" + (item.user._id)),
            }}},[_vm._v(_vm._s(item.user.displayname || item.user.name))])]}},{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.name.collection) + "/" + (item.name.recordtype) + "/view/" + (item.name._id)),
            }}},[_vm._v(_vm._s(item.name.displayname || item.name.name))])]}},{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('exportData',{attrs:{"headers":_vm.headers,"items":_vm.items}})],1),_c('v-col',[_c('v-autocomplete',{staticClass:"mb-2",attrs:{"dense":"","items":_vm.countries,"label":"Country","outlined":"","item-text":"name","item-value":"_id","multiple":"","hide-details":"","clearable":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchRecords.apply(null, arguments)}},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_c('v-autocomplete',{attrs:{"dense":"","items":_vm.salesreps,"label":"Sales Rep","outlined":"","item-text":"name","item-value":"_id","multiple":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchRecords.apply(null, arguments)}},model:{value:(_vm.salesrep),callback:function ($$v) {_vm.salesrep=$$v},expression:"salesrep"}})],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Created After","placeholder":"","append-icon":"event","readonly":"","outlined":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.createdafter),callback:function ($$v) {_vm.createdafter=$$v},expression:"createdafter"}},on))]}}]),model:{value:(_vm.datapicker),callback:function ($$v) {_vm.datapicker=$$v},expression:"datapicker"}},[_c('v-date-picker',{model:{value:(_vm.createdafter),callback:function ($$v) {_vm.createdafter=$$v},expression:"createdafter"}})],1),_c('v-switch',{attrs:{"label":"Only B2B"},model:{value:(_vm.iscompany),callback:function ($$v) {_vm.iscompany=$$v},expression:"iscompany"}})],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"mb-2",attrs:{"label":"Transactions datefrom","placeholder":"","append-icon":"event","readonly":"","outlined":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.datefrom),callback:function ($$v) {_vm.datefrom=$$v},expression:"datefrom"}},on))]}}]),model:{value:(_vm.datapicker2),callback:function ($$v) {_vm.datapicker2=$$v},expression:"datapicker2"}},[_c('v-date-picker',{model:{value:(_vm.datefrom),callback:function ($$v) {_vm.datefrom=$$v},expression:"datefrom"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Transactions date to","placeholder":"","append-icon":"event","readonly":"","outlined":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.dateto),callback:function ($$v) {_vm.dateto=$$v},expression:"dateto"}},on))]}}]),model:{value:(_vm.datapicker3),callback:function ($$v) {_vm.datapicker3=$$v},expression:"datapicker3"}},[_c('v-date-picker',{model:{value:(_vm.dateto),callback:function ($$v) {_vm.dateto=$$v},expression:"dateto"}})],1)],1),_c('v-col',[_c('v-text-field',{staticClass:"mb-2",attrs:{"dense":"","type":"number","placeholder":"","label":"Min. Amount","hide-details":"","outlined":""},model:{value:(_vm.minamt),callback:function ($$v) {_vm.minamt=$$v},expression:"minamt"}}),_c('v-text-field',{staticClass:"mt-2 mb-2",attrs:{"dense":"","type":"number","placeholder":"","label":"Max. Amount","hide-details":"","outlined":""},model:{value:(_vm.maxamt),callback:function ($$v) {_vm.maxamt=$$v},expression:"maxamt"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"dense":"","type":"number","placeholder":"","label":"Highlight Amount","hide-details":"","outlined":""},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}})],1),_c('v-spacer'),_c('v-col',[_c('v-btn',{staticClass:"mt-2 mb-2",attrs:{"block":"","tile":"","color":"primary"},on:{"click":function($event){return _vm.getData()}}},[_vm._v("Refresh")])],1)],1)],1)]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-text-field
    @blur="emitChangedValue()"
    v-model="email"
    :label="label"
    prepend-inner-icon="email"
    placeholder
    :rules="[rules.required, rules.email]"
    :readonly="$route.params.mode == 'view' || record.archived"
    outlined
    dense
  ></v-text-field>
</template>

<script>
import fieldCalculated from "../../common/methods/fieldCalculated";
/* eslint-disable */
export default {
  props: ["value", "record", "fieldSettings", "formSettings"],
  data() {
    return {
      email: this.value,
      required:
        this.formSettings.required ||
        this.fieldSettings.setup.required ||
        false,
      field: this.formSettings.field || this.formSettings.setup.field,
      label: this.formSettings.name || this.fieldSettings.name,
      rules: {
        required: (value) => (this.required ? !!value || "Required." : true),
        email: (value) =>
          value ? /.+@.+/.test(value) || "E-mail must be valid" : true,
      },
      mode: this.$route.params.mode,
    };
  },
  created() {
    // if (this.formSettings.default) {
    //   this.fieldSettings.setup.default = this.formSettings.default
    //     ? this.formSettings.default
    //     : this.fieldSettings.default;
    // }
    // if (this.formSettings.filters) {
    //   this.formSettings.default = this.formSettings.default ? this.formSettings.default : this.formSettings.default;
    // }
    let field = fieldCalculated(this.fieldSettings, this.record);
    // if (field.default && !this.text && this.mode=="create") {
    //   this.email = field.default;
    //   this.record[field._id] = field.default;
    // }
  },
  watch: {
    record: {
      handler: async function (after, before) {
        this.email = this.record[this.field];
      },
      deep: true,
    },
  },
  methods: {
    emitChangedValue() {
      this.$emit("emit", { field: this.field, value: this.email });
    },
  },
};
</script>

<script>
/* eslint-disable */
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  props: ["data", "option", "chartData"],
  mixins: [reactiveProp],
  data: () => ({
    options: {
      legend: {
        display: true,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        labels: {
          render: () => "",
        },
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            console.log(tooltipItem, data);

            return `${
              data.datasets[tooltipItem.datasetIndex].label
            }: ${new Intl.NumberFormat("en-EN", {}).format(tooltipItem.value)}`;
          },
        },
      },
    },
  }),

  mounted() {
    this.options = Object.assign(this.options, this.option);
    this.options.legend.display =
      this.chartData.datasets.length > 10 ? false : true;
    this.renderChart(this.chartData, this.options);
  },
};
</script>
<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-btn @click="newpromocode()" tile color="primary"
          >Create New Promocode</v-btn
        >
        <div v-if="promocode">
          <v-alert type="success" dense icon="mdi-gift"
            >{{ promocode }}
          </v-alert>
          <small
            >Expiration date:
            {{ new Date(record.enddate).toISOString().substr(0, 10) }}</small
          >
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
  <script>
/* eslint-disable */
import saveRecord from "../../../common/methods/saveRecord";

export default {
  data() {
    return {
      loading: false,
      promocode: null,
      record: {
        recordtype: "promotion",
        collection: "promotions",
        created: "new",
        mininventoryamount: 0,
        allowbackorder: true,
        discountrate: -0.025,
        promocode: this.generujUnikatowyKod(6),
        moq: 1,
      },
    };
  },
  async created() {
    this.loading = true;
  },

  methods: {
    filter(value, search, item) {
      return filter(value, search, item);
    },
    generujUnikatowyKod(dlugosc) {
      const znaki = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let kod = "";

      for (let i = 0; i < dlugosc; i++) {
        const losowyIndeks = Math.floor(Math.random() * znaki.length);
        kod += znaki.charAt(losowyIndeks);
      }

      return kod;
    },
    async newpromocode() {
      this.loading = true;
      let now = new Date();
      var enddate = new Date(now);
      enddate.setDate(now.getDate() + 7);

      this.record.date = now;
      this.record.enddate = enddate;

      this.record.promocode = this.generujUnikatowyKod(6);

      this.record.name = `Promocode (${this.record.promocode})`;

      await saveRecord(this.record, false, "save", "promotions").then((res) => {
        this.promocode = this.record.promocode;
      });
      this.loading = false;
    },
  },
};
</script>
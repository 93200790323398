 <template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          loading-text="Loading... Please wait"
          item-key="_id"
          v-model="selected"
          show-select
          :search="search"
          :single-select="singleSelect"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
          :item-class="rowClass"
        >
          <template v-slot:item.transaction="{ item }">
            <router-link
              :to="{
                path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
              }"
              class="link"
              >{{
                item.transaction.displayname || item.transaction.name
              }}</router-link
            >
          </template>
          <template v-slot:item.customer="{ item }">
            <router-link
              :to="{
                path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
              }"
              class="link"
              >{{
                item.customer.displayname || item.customer.name
              }}</router-link
            >
          </template>
          <template v-slot:item.item="{ item }">
            <router-link
              :to="{
                path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`,
              }"
              class="link"
              >{{ item.item.displayname || item.item.name }}</router-link
            >
          </template>

          <template v-slot:item.accountonhold="{ item }">
            <v-chip label :color="item.accountonhold ? 'red' : 'none'" dark>{{
              item.accountonhold ? "Yes" : "No"
            }}</v-chip>
          </template>
          <template v-slot:item.quantitytopack="{ item }">
            <v-chip v-if="item.quantitytopack" label color="yellow" dark>{{
              item.quantitytopack
            }}</v-chip>
          </template>
          <template v-slot:item.newquantitycommitted="{ item }">
            <v-text-field
              type="number"
              dense
              v-model="item.newquantitycommitted"
              label="Qty. Committed"
              single-line
              hide-details
              outlined
              :disabled="
                !!(
                  item.quantityreceived &&
                  item.quantityreceived == item.quantitycommitted &&
                  !item.quantitybackorder
                )
              "
              :min="item.quantityreceived"
              :max="item.quantity"
            ></v-text-field>
          </template>
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-combobox
                    dense
                    v-model="item"
                    :items="filteritems"
                    label="Item"
                    outlined
                    return-object
                    item-text="name"
                    item-value="_id"
                    @keyup.enter="searchRecords"
                  ></v-combobox>
                </v-col>
                <v-col>
                  <v-switch v-model="autorecalc" label="Auto Mode"></v-switch>
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                  <v-btn @click="action" block tile color="primary"
                    >Reallocate</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
export default {
  data() {
    return {
      loading: false,
      headers: [
        { text: "Location", value: "location", sortable: false },
        { text: "Date", value: "date", sortable: false },
        { text: "Sales Order", value: "transaction", sortable: false },
        { text: "Customer", value: "customer", sortable: false },
        { text: "Shipping Address", value: "shipaddress", sortable: false },
        { text: "Item", value: "item", sortable: false },
        { text: "Quantity", value: "quantity", sortable: false },
        { text: "Committed", value: "quantitycommitted", sortable: false },
        { text: "To Pack", value: "quantitytopack", sortable: false },
        {
          text: "Special BO Committed",
          value: "quantityreceived",
          sortable: false,
        },

        {
          text: "Correct",
          value: "newquantitycommitted",
          sortable: false,
          width: "100px",
        },
        { text: "Account on Hold", value: "accountonhold", sortable: false },
        { text: "Qty. on Hand", value: "quantityonhand", sortable: false },
        { text: "Service", value: "additionalservice", sortable: false },
        { text: "ETA", value: "eta", sortable: false },
        { text: "ETA Memo", value: "etamemo", sortable: false },
        {
          text: "Planned Date Of Shipment",
          value: "planneddateofshipment",
          sortable: false,
        },
      ],
      items: [],
      filteritems: [],
      item: null,
      rows: [100],
      singleSelect: false,
      selected: [],
      search: "",
      show: false,
      autorecalc: true,
    };
  },
  async created() {
    this.loading = true;
    //await this.getData();
    this.loading = false;
  },
  watch: {
    selected: {
      handler: async function (val) {
        this.recalcCommit();
      },
      deep: true,
    },
    async item(value) {
      if (value._id) await this.getData();
    },
  },
  methods: {
    rowClass(item) {
      if (item.quantityreceived) {
        item.isSelectable = false;
        return "specialbackorder";
      }
    },
    recalcCommit() {
      let remaining = 0;
      this.items.forEach((item) => {
        if (!this.selected.includes(item)) {
          item.newquantitycommitted = item.quantityreceived || 0;
          remaining += item.quantitycommitted - (item.quantityreceived || 0);
        } else remaining += item.quantitycommitted;
      });
      if (this.selected[0] && this.autorecalc) {
        this.selected.forEach((item, index) => {
          if (remaining >= item.quantity) {
            item.newquantitycommitted = item.newquantitycommitted
              ? item.newquantitycommitted
              : item.quantity;
            remaining -= item.newquantitycommitted;
          } else {
            item.newquantitycommitted = item.newquantitycommitted
              ? item.newquantitycommitted
              : remaining;
            remaining -= item.newquantitycommitted;
          }
        });
      }
    },
    async searchRecords() {
      this.loading = true;
      await this.getItems();
      this.loading = false;
    },
    async getItems() {
      if (this.item && !this.item._id) {
        this.filteritems = await service("items", {
          action: "find",
          data: [
            {
              name: { $regex: `.*${this.item}.*`, $options: "i" },
              recordtype: { $in: ["inventoryitem", "kititem"] },
            },
          ],
        });
      }
    },
    async getData() {
      this.loading = true;
      let result = await service(
        "orders",
        {
          action: "getCommittedItems",
          item: this.item._id,
        },
        false,
        true
      );
      this.selected = [];
      this.items = result.items;
      this.items.forEach((item) => {
        if (item.quantitycommitted > 0) this.selected.push(item);
      });
      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    async action() {
      let reallcateArray = [];
      let location = null;
      let item = null;
      let currentQty = 0;
      let newQty = 0;
      let error = false;
      this.items.forEach((select) => {
        if (select.newquantitycommitted != select.quantitycommitted) {
          select.newquantitycommitted = parseInt(
            select.newquantitycommitted || 0
          );
          newQty += parseInt(select.newquantitycommitted) || 0;
          currentQty += select.quantitycommitted || 0;
          if (
            location &&
            (select.location._id || select.location) != location
          ) {
            alert("You cannot select more locations");
            error = true;
          }
          location = select.location
            ? select.location._id || select.location
            : null;
          if (item && select.item._id != item) {
            alert("You cannot select more item");
            error = true;
          }
          item = select.item._id;
          reallcateArray.push(select);
        }
      });
      if (newQty > currentQty) {
        alert(
          "New Sum of Committed Quantity cannot be greater than " + currentQty
        );
        error = true;
      }
      if (!error) {
        await service("transactions", {
          action: "reallocateItems",
          data: reallcateArray,
        }).catch((error) => {
          alert(error);
        });
        this.loading = true;
        await this.getData();
        this.loading = false;
      }
    },
  },
};
</script>

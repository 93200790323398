<template>
  <v-combobox
    @input="emitChangedValue()"
    @blur="emitChangedValue()"
    @focus="updateOptions()"
    v-model="text"
    :search-input.sync="text2"
    :label="label"
    :items="items"
    placeholder
    :multiple="multiple && $route.params.mode != 'view'"
    :hint="multiple && $route.params.mode == 'edit'? 'type value and put enter to confirm' : ''"
    :rules="[rules.required, rules.max]"
    :counter="$route.params.mode != 'view' ? max : false"
    :readonly="
      ($route.params.mode == 'view' ||
        this.formSettings.disabled ||
        this.fieldSettings.disabled == true ||
        (this.formSettings.setifempty && value) ||
        record.archived ||
        loading) &&
      !this.formSettings.show
    "
    :disabled="
      (this.formSettings.disabled || this.fieldSettings.disabled == true) &&
      !this.formSettings.show
    "
    outlined
    dense
  >
    <template v-slot:prepend>
      <v-dialog
        v-if="
          record.location &&
          fieldSettings &&
          fieldSettings.setup &&
          fieldSettings.setup.collection
        "
        scrollable
        max-width="450px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on"> mdi-format-list-bulleted </v-icon>
        </template>
        <v-card>
          <v-card-title>{{ record.item.name }} - Bins</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 300px" class="px-0">
            <v-data-table
              hide-default-footer
              :headers="headersBins"
              :items="
                itemsObj.filter((bin) =>
                  record.location ? bin.location == record.location._id : true
                )
              "
              class="elevation-0"
            >
              <template v-slot:item.bin="{ item }">
                <v-text-field
                  v-if="!item.quanityonhand"
                  dense
                  v-model="item.bin"
                  label="Bin"
                  single-line
                  hide-details
                  outlined
                  :key="item._id"
                  @change="addBin(item)"
                ></v-text-field>
                <span v-else>{{ item.bin }}</span>
              </template>
              <template v-slot:item.priority="{ item }">
                <v-text-field
                  dense
                  v-model="item.priority"
                  label="Priority"
                  single-line
                  hide-details
                  outlined
                  :disabled="!item.bin"
                  @change="
                    updateField(
                      'priority',
                      'items_location',
                      item._id,
                      item.priority
                    )
                  "
                ></v-text-field>
              </template>
              <template v-slot:footer>
                <div
                  style="position: relative; text-align: right; height: 50px"
                >
                  <v-fab-transition>
                    <v-btn
                      small
                      absolute
                      top
                      left
                      fab
                      style="top: 5px; left: 15px"
                      @click="addRow()"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </v-fab-transition>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </template>
  </v-combobox>
</template>

<script>
import service from "../../api/index";
import fieldCalculated from "../../common/methods/fieldCalculated";
/* eslint-disable */
export default {
  props: ["value", "record", "fieldSettings", "formSettings"],
  data() {
    return {
      loading: false,
      text2: null,
      text: this.value,
      required:
        this.formSettings.required ||
        this.fieldSettings.setup.required ||
        false,
      min: this.formSettings.min || this.fieldSettings.min,
      max: this.formSettings.max || this.fieldSettings.max,
      field: this.formSettings.field || this.formSettings.setup.field,
      label: this.formSettings.name || this.fieldSettings.name,
      multiple:
        this.formSettings.multiple ||
        this.fieldSettings.setup.multiple ||
        ["$in", "$nin"].includes(this.formSettings.operator),
      items: [],
      itemsObj: [],
      rules: {
        required: (value) => (this.required ? !!value || "Required." : true),
        allowspaces: (value) =>
          ((value || "").indexOf(" ") < 0 && this.allowspaces) ||
          "No spaces are allowed",
        max: (value) =>
          this.max > 0
            ? (value || "").length <= this.max ||
              `A maximum of ${this.max} characters is allowed`
            : true,
        min: (value) =>
          this.min > 0
            ? (value || "").length >= this.min ||
              `A minimum of ${this.min} characters is allowed`
            : true,
      },
      mode: this.$route.params.mode,
      headersBins: [
        //{ text: "Location", value: "location" },
        { text: "Bin", value: "bin" },
        { text: "Priority", value: "priority" },
        { text: "On Hand", value: "quanityonhand" },
      ],
    };
  },
  watch: {
    text2(v) {
      if (!this.multiple) this.text = v;
    },
    record: {
      handler: async function (after, before) {
        // this.items = await this.getData(this.fieldSettings, this.formSettings);
        this.text = this.record[this.field];
        if (this.text && this.multiple && !Array.isArray(this.text)) this.text = [this.text];
      },
      deep: true,
    },
  },
  async created() {
    //this.items = await this.getData(this.fieldSettings, this.formSettings);
    //console.log(this.formSettings)
    // if (this.formSettings.default) {
    //   this.fieldSettings.default = this.formSettings.default
    //     ? this.formSettings.default
    //     : this.fieldSettings.setup.default;
    // }
    // if (this.formSettings.filters) {
    //   this.formSettings.default = this.formSettings.default
    //     ? this.formSettings.default
    //     : this.formSettings.default;
    // }
    let field = await fieldCalculated(this.fieldSettings, this.record);
    // if (field.default && !this.text && this.mode == "create") {
    //   this.text = field.default;
    //   this.record[field._id] = field.default;
    // }
  },
  methods: {
    async updateOptions() {
      this.items = await this.getData(this.fieldSettings, this.formSettings);
    },
    addRow() {
      if (this.record.item && this.record.item._id) {
        let priority = this.items.length + 1;
        this.itemsObj.push({
          _id: null,
          created: "new",
          item: this.record.item._id,
          location: this.record.location._id,
          bin: "",
          priority: priority,
        });
      }
    },
    async addBin(item) {
      if (
        item.bin &&
        this.record.item &&
        this.record.location &&
        this.record.item._id &&
        this.record.location._id
      ) {
        let result = await service(
          "items",
          {
            action: "getBins",
            update: true,
            item: this.record.item._id,
            bin: item.bin,
            location: this.record.location._id,
            priority: item.priority,
            bin_id: item._id,
          },
          false,
          false
        );
        if (result && result.bin_id) item._id = result.bin_id;
        console.log(item._id);
      }
    },
    async updateField(field, collection, rid, value) {
      let result = await service("record", {
        action: "setField",
        collection: collection,
        rid: rid,
        field: field,
        value: value,
      });
    },
    emitChangedValue() {
      if (this.record[this.field] != this.text)
        this.$emit("emit", { field: this.field, value: this.text });
    },
    async getData(source, form) {
      this.loading = true;
      source.setup.filters = source.setup.filters || {};

      let field = await fieldCalculated(source, this.record);
      let parameters = [
        field.filters,
        { name: true, displayname: true, recordtype: true, collection: true },
      ];
      let data = {
        action: "find",
        data: parameters,
      };
      if (field.setup.collection) {
        return service(field.setup.collection, data)
          .catch((error) => {
            throw error;
          })
          .then((response) => {
            this.itemsObj = response;
            let options = response.map((obj) => {
              if (field.setup.field) return obj[field.setup.field];
              else return obj.displayname || obj.name || obj[field._id];
            });
            options = options.filter(
              (item, index) =>
                item != undefined && options.indexOf(item) == index
            );
            this.loading = false;
            return options;
          })
          .catch((error) => {
            this.loading = false;
          });
      } else {
        this.loading = false;
        return this.text;
      }
    },
  },
};
</script>

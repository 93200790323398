<script>
import { Pie, mixins } from "vue-chartjs";
import colors from "./colors";
const { reactiveProp } = mixins;
export default {
  props: ["data", "chartData"],
  mixins: [reactiveProp],
  extends: Pie,
  data: () => ({
    options: {
      legend: false,
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            var total = dataset.data.reduce(function(
              previousValue,
              currentValue,
              currentIndex,
              array
            ) {
              return parseFloat(previousValue) + parseFloat(currentValue);
            });
            var currentValue = dataset.data[tooltipItem.index];
            var precentage = Math.floor((currentValue / total) * 100 + 0.5);
            return `${data["labels"][tooltipItem["index"]]}: ${precentage}%`;
          }
        }
      }
    }
  }),

  mounted() {
    this.renderChart(this.chartData, this.options);
  }
};
</script>
<template>
  <v-card tile>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Other Tools</v-toolbar-title>
    </v-toolbar>
    <v-tabs dark v-model="active">
      <v-tab :key="1">Deliveries</v-tab>
      <v-tab :key="2">Inventory Status</v-tab>
      <v-tab :key="3">Intrastat</v-tab>
      <v-tab :key="4">ABC-XYZ</v-tab>
      <v-tab :key="5">Inventory Valuation</v-tab>
      <v-tab :key="6">Purchase Reports</v-tab>
      <v-tab :key="7">Costs Report</v-tab>
      <v-tab :key="8">Reports</v-tab>
      <v-tab :key="9"><v-icon>mdi-close-outline</v-icon></v-tab>
      <v-tab-item :key="1">
        <deliveriescalendar v-if="active==0" />
      </v-tab-item>
      <v-tab-item :key="2">
        <inventorystatus v-if="active==1" />
      </v-tab-item>
      <v-tab-item :key="3">
        <intrastat v-if="active==2" />
      </v-tab-item>
      <v-tab-item :key="4">
        <abcxyz v-if="active==3" />
      </v-tab-item>
      <v-tab-item :key="5">
        <inventoryvaluation v-if="active==4" />
      </v-tab-item>
       <v-tab-item :key="6">
        <purchasereports v-if="active==5" />
      </v-tab-item>
      <v-tab-item :key="7">
        <costdetails v-if="active == 6" />
      </v-tab-item>
      <v-tab-item :key="8">
        <reports v-if="active==7" />
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import backorderitems from "../components/tools/backorderitems/View";
import dropshiporders from "../components/tools/dropshiporders/View";
import fleetorders from "../components/tools/fleetorders/View";
import additionalservice from "../components/tools/additionalservice/View";
import deliveriescalendar from "../components/tools/deliveriescalendar/View";
import openpurchaseorders from "../components/tools/openpurchaseorders/View";
import inventorystatus from "../components/tools/inventorystatus/View";
import intrastat from "../components/tools/intrastat/View";
import poplanner from "../components/tools/poplanner/View";
import openadditionalservice from "../components/tools/openadditionalservice/View";
import poassistant from "../components/tools/poassistant/View";
import abcxyz from "../components/tools/abcxyz/View";
import inventoryvaluation from "../components/tools/inventoryvaluation/View";
import reports from "../components/tools/reports/View";
import purchasereports from "../components/tools/purchasereports/View";
import statistics from "../components/tools/statistics/View";
import costdetails from "../components/tools/costdetails/View";
/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
      active: 8
    };
  },
  components: {
    dropshiporders,
    fleetorders,
    additionalservice,
    deliveriescalendar,
    backorderitems,
    openpurchaseorders,
    inventorystatus,
    intrastat,
    poplanner,
    openadditionalservice,
    poassistant,
    abcxyz,
    inventoryvaluation,
    reports,
    purchasereports,
    statistics,
    costdetails
  }
};
</script>
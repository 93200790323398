 <template>
  <div>
    <v-row>
      <v-col>
        <v-tabs dark v-model="active">
          <v-tab :key="1">
            <v-badge color="primary">
              <span>PL</span>
              <span slot="badge">{{ itemsPL.length }}</span>
            </v-badge>
          </v-tab>
          <v-tab :key="2">
            <v-badge color="primary">
              <span>NL</span>
              <span slot="badge">{{ itemsNL.length }}</span>
            </v-badge>
          </v-tab>
          <v-tab :key="3">
            <v-badge color="primary">
              <span>UK</span>
              <span slot="badge">{{ itemsUK.length }}</span>
            </v-badge>
          </v-tab>
          <v-tab :key="4">
            <v-badge color="primary">
              <span>IC</span>
              <span slot="badge">{{ itemsIC.length }}</span>
            </v-badge>
          </v-tab>
          <v-tab-item :key="1">
            <v-data-table
              :headers="headers"
              :items="itemsPL"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              :search="search"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
              :custom-sort="sort"
            >
              <template v-slot:top>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <exportData :headers="headers" :items="itemsPL" />
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-switch v-model="showall" label="Show All"></v-switch>
                    </v-col>
                    <v-col>
                      <v-menu
                        v-model="menufrom[active]"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            label="Date from"
                            placeholder
                            v-model="datefrom"
                            append-icon="event"
                            readonly
                            v-on="on"
                            outlined
                            dense
                            hide-details
                            clearable
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="datefrom"
                          :first-day-of-week="1"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template v-slot:item.memo="{ item }">
                <v-textarea
                  type="text"
                  dense
                  v-model="item.memo"
                  hide-details
                  class="mt-2 mb-2"
                  @click:clear="action(item)"
                  @change="action(item)"
                ></v-textarea>
              </template>
              <template v-slot:item.date="{ item }">
                <span>{{
                  new Date(item.date).toISOString().substr(0, 10)
                }}</span>
              </template>
              <template v-slot:item.planneddateofshipment="{ item }">
                <span>{{
                  item.planneddateofshipment
                    ? new Date(item.planneddateofshipment)
                        .toISOString()
                        .substr(0, 10)
                    : ""
                }}</span>
              </template>
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                  }"
                  class="link"
                  >{{
                    item.customer.displayname || item.customer.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.file="{ item }">
                <a
                  v-if="item.file"
                  :key="item.file._id"
                  :href="item.file.url"
                  target="_blank"
                  class="link d-block"
                  >{{ item.file.displayname || item.file.name }}</a
                >
              </template>
              <template v-slot:item.file2="{ item }">
                <a
                  v-if="item.file2"
                  :key="item.file2._id"
                  :href="item.file2.url"
                  target="_blank"
                  class="link d-block"
                  >{{ item.file2.displayname || item.file2.name }}</a
                >
              </template>
              <template v-slot:item.files="{ item }">
                <a
                  v-for="item in item.files"
                  :key="item._id"
                  :href="item.url"
                  target="_blank"
                  class="link d-block"
                  >{{ item.displayname || item.name }}</a
                >
              </template>
              <template v-slot:item.transaction="{ item }">
                <router-link
                  :to="{
                    path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                  }"
                  class="link"
                  >{{
                    item.transaction.displayname || item.transaction.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.altpdfform="{ item }">
                <a
                  v-if="item.altpdfform"
                  :href="`http://localhost:8080/api/pdf/${item.transaction.collection}/${item.transaction._id}/?pdfform=${item.altpdfform._id}`"
                  target="_blank"
                  class="link"
                  >{{ item.altpdfform.displayname || item.altpdfform.name }}</a
                >
              </template>
              <template v-slot:item.accountonhold="{ item }">
                <v-chip
                  label
                  :color="item.accountonhold ? 'red' : 'none'"
                  dark
                  >{{ item.accountonhold ? "Yes" : "No" }}</v-chip
                >
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item :key="2">
            <v-data-table
              :headers="headers"
              :items="itemsNL"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              :search="search"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
              :custom-sort="sort"
            >
              <template v-slot:top>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <exportData :headers="headers" :items="itemsNL" />
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-switch v-model="showall" label="Show All"></v-switch>
                    </v-col>
                    <v-col>
                      <v-menu
                        v-model="menufrom[active]"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            label="Date from"
                            placeholder
                            v-model="datefrom"
                            append-icon="event"
                            readonly
                            v-on="on"
                            outlined
                            dense
                            hide-details
                            clearable
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="datefrom"
                          :first-day-of-week="1"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template v-slot:item.memo="{ item }">
                <v-textarea
                  type="text"
                  dense
                  v-model="item.memo"
                  hide-details
                  class="mt-2 mb-2"
                  @click:clear="action(item)"
                  @change="action(item)"
                ></v-textarea>
              </template>
              <template v-slot:item.date="{ item }">
                <span>{{
                  new Date(item.date).toISOString().substr(0, 10)
                }}</span>
              </template>
              <template v-slot:item.planneddateofshipment="{ item }">
                <span>{{
                  item.planneddateofshipment
                    ? new Date(item.planneddateofshipment)
                        .toISOString()
                        .substr(0, 10)
                    : ""
                }}</span>
              </template>
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                  }"
                  class="link"
                  >{{
                    item.customer.displayname || item.customer.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.file="{ item }">
                <a
                  v-if="item.file"
                  :key="item.file._id"
                  :href="item.file.url"
                  target="_blank"
                  class="link d-block"
                  >{{ item.file.displayname || item.file.name }}</a
                >
              </template>
              <template v-slot:item.file2="{ item }">
                <a
                  v-if="item.file2"
                  :key="item.file2._id"
                  :href="item.file2.url"
                  target="_blank"
                  class="link d-block"
                  >{{ item.file2.displayname || item.file2.name }}</a
                >
              </template>
              <template v-slot:item.files="{ item }">
                <a
                  v-for="item in item.files"
                  :key="item._id"
                  :href="item.url"
                  target="_blank"
                  class="link d-block"
                  >{{ item.displayname || item.name }}</a
                >
              </template>
              <template v-slot:item.transaction="{ item }">
                <router-link
                  :to="{
                    path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                  }"
                  class="link"
                  >{{
                    item.transaction.displayname || item.transaction.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.altpdfform="{ item }">
                <a
                  v-if="item.altpdfform"
                  :href="`http://localhost:8080/api/pdf/${item.transaction.collection}/${item.transaction._id}/?pdfform=${item.altpdfform._id}`"
                  target="_blank"
                  class="link"
                  >{{ item.altpdfform.displayname || item.altpdfform.name }}</a
                >
              </template>
              <template v-slot:item.accountonhold="{ item }">
                <v-chip
                  label
                  :color="item.accountonhold ? 'red' : 'none'"
                  dark
                  >{{ item.accountonhold ? "Yes" : "No" }}</v-chip
                >
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item :key="3">
            <v-data-table
              :headers="headers"
              :items="itemsUK"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              :search="search"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
              :custom-sort="sort"
            >
              <template v-slot:top>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <exportData :headers="headers" :items="itemsUK" />
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-switch v-model="showall" label="Show All"></v-switch>
                    </v-col>
                    <v-col>
                      <v-menu
                        v-model="menufrom[active]"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            label="Date from"
                            placeholder
                            v-model="datefrom"
                            append-icon="event"
                            readonly
                            v-on="on"
                            outlined
                            dense
                            hide-details
                            clearable
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="datefrom"
                          :first-day-of-week="1"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template v-slot:item.memo="{ item }">
                <v-textarea
                  type="text"
                  dense
                  v-model="item.memo"
                  hide-details
                  class="mt-2 mb-2"
                  @click:clear="action(item)"
                  @change="action(item)"
                ></v-textarea>
              </template>
              <template v-slot:item.date="{ item }">
                <span>{{
                  new Date(item.date).toISOString().substr(0, 10)
                }}</span>
              </template>
              <template v-slot:item.planneddateofshipment="{ item }">
                <span>{{
                  item.planneddateofshipment
                    ? new Date(item.planneddateofshipment)
                        .toISOString()
                        .substr(0, 10)
                    : ""
                }}</span>
              </template>
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                  }"
                  class="link"
                  >{{
                    item.customer.displayname || item.customer.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.file="{ item }">
                <a
                  v-if="item.file"
                  :key="item.file._id"
                  :href="item.file.url"
                  target="_blank"
                  class="link d-block"
                  >{{ item.file.displayname || item.file.name }}</a
                >
              </template>
              <template v-slot:item.file2="{ item }">
                <a
                  v-if="item.file2"
                  :key="item.file2._id"
                  :href="item.file2.url"
                  target="_blank"
                  class="link d-block"
                  >{{ item.file2.displayname || item.file2.name }}</a
                >
              </template>
              <template v-slot:item.files="{ item }">
                <a
                  v-for="item in item.files"
                  :key="item._id"
                  :href="item.url"
                  target="_blank"
                  class="link d-block"
                  >{{ item.displayname || item.name }}</a
                >
              </template>
              <template v-slot:item.transaction="{ item }">
                <router-link
                  :to="{
                    path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                  }"
                  class="link"
                  >{{
                    item.transaction.displayname || item.transaction.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.altpdfform="{ item }">
                <a
                  v-if="item.altpdfform"
                  :href="`http://localhost:8080/api/pdf/${item.transaction.collection}/${item.transaction._id}/?pdfform=${item.altpdfform._id}`"
                  target="_blank"
                  class="link"
                  >{{ item.altpdfform.displayname || item.altpdfform.name }}</a
                >
              </template>
              <template v-slot:item.accountonhold="{ item }">
                <v-chip
                  label
                  :color="item.accountonhold ? 'red' : 'none'"
                  dark
                  >{{ item.accountonhold ? "Yes" : "No" }}</v-chip
                >
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item :key="4">
            <v-data-table
              :headers="headers"
              :items="itemsIC"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              :search="search"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
              :custom-sort="sort"
            >
              <template v-slot:top>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <exportData :headers="headers" :items="itemsIC" />
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-switch v-model="showall" label="Show All"></v-switch>
                    </v-col>
                    <v-col>
                      <v-menu
                        v-model="menufrom[active]"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            label="Date from"
                            placeholder
                            v-model="datefrom"
                            append-icon="event"
                            readonly
                            v-on="on"
                            outlined
                            dense
                            hide-details
                            clearable
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="datefrom"
                          :first-day-of-week="1"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template v-slot:item.memo="{ item }">
                <v-textarea
                  type="text"
                  dense
                  v-model="item.memo"
                  hide-details
                  class="mt-2 mb-2"
                  @click:clear="action(item)"
                  @change="action(item)"
                ></v-textarea>
              </template>
              <template v-slot:item.date="{ item }">
                <span>{{
                  new Date(item.date).toISOString().substr(0, 10)
                }}</span>
              </template>
              <template v-slot:item.planneddateofshipment="{ item }">
                <span>{{
                  item.planneddateofshipment
                    ? new Date(item.planneddateofshipment)
                        .toISOString()
                        .substr(0, 10)
                    : ""
                }}</span>
              </template>
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                  }"
                  class="link"
                  >{{
                    item.customer.displayname || item.customer.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.file="{ item }">
                <a
                  v-if="item.file"
                  :key="item.file._id"
                  :href="item.file.url"
                  target="_blank"
                  class="link d-block"
                  >{{ item.file.displayname || item.file.name }}</a
                >
              </template>
              <template v-slot:item.file2="{ item }">
                <a
                  v-if="item.file2"
                  :key="item.file2._id"
                  :href="item.file2.url"
                  target="_blank"
                  class="link d-block"
                  >{{ item.file2.displayname || item.file2.name }}</a
                >
              </template>
              <template v-slot:item.files="{ item }">
                <a
                  v-for="item in item.files"
                  :key="item._id"
                  :href="item.url"
                  target="_blank"
                  class="link d-block"
                  >{{ item.displayname || item.name }}</a
                >
              </template>
              <template v-slot:item.transaction="{ item }">
                <router-link
                  :to="{
                    path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                  }"
                  class="link"
                  >{{
                    item.transaction.displayname || item.transaction.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.altpdfform="{ item }">
                <a
                  v-if="item.altpdfform"
                  :href="`http://localhost:8080/api/pdf/${item.transaction.collection}/${item.transaction._id}/?pdfform=${item.altpdfform._id}`"
                  target="_blank"
                  class="link"
                  >{{ item.altpdfform.displayname || item.altpdfform.name }}</a
                >
              </template>
              <template v-slot:item.accountonhold="{ item }">
                <v-chip
                  label
                  :color="item.accountonhold ? 'red' : 'none'"
                  dark
                  >{{ item.accountonhold ? "Yes" : "No" }}</v-chip
                >
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import sort from "../../dataTable/sort";
import exportData from "../../dataTable/exportData";
export default {
  props: ["customer"],
  data() {
    return {
      loading: false,
      active: 0,
      headers: [
        { text: "Customer", value: "customer" },
        { text: "Bill Country", value: "billcountry" },
        { text: "Shipping Address", value: "shipaddress" },
        { text: "Invoice Date", value: "date" },
        { text: "Shipping Date", value: "shippingdate" },
        { text: "Invoice", value: "transaction" },
        { text: "Shipping Method", value: "shippingmethod" },
        { text: "Custom Clearance", value: "altpdfform" },
        { text: "Tracking Number", value: "trackingnumbers", width: "200px" },
        { text: "MRN", value: "mrn" },
        { text: "IE529", value: "file" },
        { text: "IE599", value: "file2" },
        //{ text: "Files", value: "files" },
        { text: "Internal Comments", value: "memo", width: "200px" },
        // { text: "Balance", value: "balance" },
        // { text: "Unapplied Payments", value: "unapplied" }
      ],
      items: [],
      itemsPL: [],
      itemsNL: [],
      itemsUK: [],
      itemsIC: [],
      rows: [100],
      search: "",
      menufrom: false,
      datefrom: new Date(new Date().getFullYear(), 0, 2)
        .toISOString()
        .substr(0, 10),
      showall: false,
      show: false,
    };
  },
  async created() {
    this.loading = true;
    await this.getData();
    this.loading = false;
  },
  watch: {
    async datefrom() {
      await this.getData();
    },
    async showall(value) {
      if (value) this.rows = [-1];
      else this.rows = [100];
      await this.getData();
    },
  },
  methods: {
    async action(item) {
      try {
        let result = await service("orders", {
          action: "setField",
          collection: item.collection,
          rid: item._id,
          field: "memo",
          value: item.memo,
        });
      } catch {
        alert(error.message);
      }
    },
    async getData() {
      this.loading = true;
      this.items = [];
      this.itemsPL = [];
      this.itemsNL = [];
      this.itemsUK = [];
      this.itemsIC = [];
      let result = await service(
        "transactions",
        {
          action: "getExportCustomsClearance",
          datefrom: this.datefrom,
          showall: this.showall,
        },
        false,
        true
      );
      result.items.forEach((item) => {
        item.info = "";
        if (item.customer.isperson) {
          item.info = "IC-";
        }
        // if (item.customer.location != item.location) {
        //   if (item.location == "5e0dbaba9e33df43f0b3a495") {
        //     item.info += "UK-";
        //   }
        //   if (
        //     ["5e0dbaba9e33df43f0b3a496", "5faa7485371aa739b8a08843"].includes(
        //       item.location
        //     )
        //   ) {
        //     item.info += "PL-";
        //   }
        // }
        if (
          [
            "5e0dbaba9e33df43f0b3a495",
            "5e395c6024f636543c9cf62b",
            "614453a75b1166fc8bdf3dda",
            "6144536b5b1166fc8bdf3dce",
            "614453eb5b1166fc8bdf3ddf",
            "627cee8a0f148d1cb7e46aa2",
          ].includes(item.location)
        ) {
          item.info += "PL";
          if (!item.customer.isperson) this.itemsPL.push(item);
          else this.itemsIC.push(item);
        }
        if (["66e04fcba16b2e1e7d873be8"].includes(item.location)) {
          item.info += "NL";
          if (!item.customer.isperson) this.itemsNL.push(item);
          else this.itemsIC.push(item);
        }
        if (
          ["5e0dbaba9e33df43f0b3a496", "5faa7485371aa739b8a08843"].includes(
            item.location
          )
        ) {
          item.info += "UK";
          if (!item.customer.isperson) this.itemsUK.push(item);
          else this.itemsIC.push(item);
        }
      });
      this.items = this.itemsPL.concat(this.itemsUK.concat(this.itemsIC));
      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    sort(items, sortBy, sortDesc) {
      return sort(items, sortBy, sortDesc);
    },
  },
  components: {
    exportData,
  },
};
</script>
<template>
  <tr
    fluid
    grid-list-xs
    v-if="$route.params.mode != 'view' && !editedRecord.archived"
  >
    <td
      :colspan="columns.length + (showselect ? 1 : 0)"
      style="position: relative"
    >
      <v-progress-linear
        :indeterminate="loading"
        absolute
        bottom
      ></v-progress-linear>

      <v-row style="max-width: 90vw" class="py-4" v-if="!columnsGroup.length">
        <div v-for="(field, index) in columns" :key="index">
          <v-col
            class="py-0"
            :xs="12"
            :md="4"
            :lg="3"
            v-if="
              fields[field.field] &&
              ((fields[field.field].setup.editable != false &&
                field.editable !== false) ||
                field.editable == true) &&
              (field.status && parent.status
                ? field.status.includes(parent.status._id)
                : true)
            "
          >
            <v-component
              :key="fields[field.field].update"
              @emit="catchEmit"
              :is="fields[field.field].setup.type"
              v-model="editedRecord[field.field]"
              :record="editedRecord"
              :fieldSettings="fields[field.field]"
              :formSettings="field"
              :parent="parent"
            />
          </v-col>
        </div>
      </v-row>
      <v-row style="max-width: 95vw" class="py-4" v-else>
        <div v-for="(group, index) in columnsGroup" :key="index">
          <v-col class="py-0">
            <v-subheader class="mb-5">{{ group.group }}</v-subheader>
            <div v-for="(field, fieldIndex) of group.fields" :key="fieldIndex">
              <v-component
                v-if="fields[field.field]"
                :key="fields[field.field].update"
                @emit="catchEmit"
                :is="fields[field.field].setup.type"
                v-model="editedRecord[field.field]"
                :record="editedRecord"
                :fieldSettings="fields[field.field]"
                :formSettings="field"
                :parent="parent"
              />
            </div>
          </v-col>
          <v-divider
            vertical
            v-if="index + 1 < columnsGroup.length"
          ></v-divider>
        </div>
      </v-row>
      <v-flex class="text-xs-right">
        <v-btn
          dark
          color="green"
          class="ma-2"
          tile
          :loading="loading"
          :disabled="loading || editedRecord.error"
          @click="saveRecord"
        >
          <v-icon left dark> mdi-content-save-check-outline</v-icon>Save</v-btn
        >
        <v-btn
          v-if="record._id"
          dark
          color="red"
          :loading="loading"
          :disabled="loading"
          class="ma-2"
          tile
          @click="deleteRecord"
          >Delete</v-btn
        ><v-btn
          v-if="record._id && options.duplicate"
          color="primary"
          :loading="loading"
          :disabled="loading"
          class="ma-2"
          tile
          @click="duplicateRecord"
          >Duplicate</v-btn
        >
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="loading"
          class="ma-2"
          tile
          @click="cancelRecord"
          >Cancel</v-btn
        >
      </v-flex>
    </td>
    <v-snackbar
      v-model="msg.show"
      :color="msg.type"
      :timeout="6000"
      top
      absolute
      right
      >{{ msg.message }}</v-snackbar
    >
  </tr>
</template>

<script>
import getFields from "../../common/methods/getFields";
import recalcRecord from "../../common/validations/recalcRecord";
/* eslint-disable */
export default {
  props: ["options", "record", "parent", "columns", "index", "showselect"],
  data() {
    return {
      loading: true,
      fields: {},
      editedRecord: { ...this.record },
      validRecord: {},
      changeCount: 0,
      msg: false,
      columnsGroup: [],
    };
  },
  created() {
    this.getFields(this.parent);
    if (this.editedRecord._id == null || !this.editedRecord._id) {
      this.editedRecord.created = "new";
    }
    this.columnsGroup = this.columns.reduce((groups, col) => {
      if (col.colgroup) {
        console.log(col);
        groups[col.colgroup] = groups[col.colgroup] || {
          group: col.colgroup,
          fields: [],
        };
        groups[col.colgroup].fields.push(col);
      }
      return groups;
    }, {});
    this.columnsGroup = Object.values(this.columnsGroup);
  },
  watch: {
    editedRecord: {
      handler: async function (after, before) {
        if (this.editedRecord.created == "new") {
          this.$emit("emit", {
            action: "init",
            index: this.index,
            record: this.editedRecord,
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    async catchEmit(e) {
      this.editedRecord[e.field] = e.value;
      try {
        this.loading = true;
        if (
          this.fields[e.field] &&
          ![
            "field-boolean",
            "field-text",
            "field-textarea",
            "field-date",
          ].includes(this.fields[e.field].setup.type)
        )
          this.editedRecord = await recalcRecord(
            this.editedRecord,
            this.parent,
            e.field
          );
        // if (
        //   this.editedRecord[e.field] == null ||
        //   (this.editedRecord[e.field].name == "" &&
        //     this.editedRecord[e.field]._id == null)
        // ) {
        //   delete this.editedRecord[e.field];
        // }

        this.editedRecord.error = false;
        this.loading = false;
      } catch (error) {
        this.msg = { message: `${error.message}`, show: true, type: "error" };
        this.loading = false;
        this.editedRecord.error = true;
      }
    },

    async saveRecord() {
      try {
        this.loading = true;
        //this.editedRecord = await recalcRecord(this.editedRecord, this.parent);

        console.log("EditRecord", this.editedRecord);
        this.$emit("emit", {
          action: "save",
          index: this.index,
          record: this.editedRecord,
        });
        this.editedRecord.error = false;
        this.loading = false;
      } catch (error) {
        alert(error.message);
        this.editedRecord.error = true;
        console.log(this.editedRecord);
      }
    },
    async duplicateRecord() {
      try {
        this.loading = true;
        this.$emit("emit", {
          action: "duplicate",
          record: { ...this.editedRecord, created: "new", _id: null },
        });
        this.editedRecord.error = false;
        this.loading = false;
      } catch (error) {
        alert(error.message);
        this.editedRecord.error = true;
      }
    },
    async deleteRecord() {
      this.editedRecord.deleted = true;
      console.log(this.editedRecord);
      try {
        this.editedRecord = await recalcRecord(
          this.editedRecord,
          this.parent,
          "deleted"
        );
      } catch (error) {
        this.editedRecord.deleted = false;
        alert(error.message);
      }
      this.$emit("emit", {
        action: "delete",
        index: this.index,
        record: this.editedRecord,
      });
    },
    cancelRecord() {
      this.$emit("emit", {
        action: this.editedRecord._id ? "cancel" : "delete",
        index: this.index,
        record: this.editedRecord,
      });
    },
    async getFields(record) {
      try {
        this.fields = await getFields(this, record);
        this.loading = false;
      } catch (error) {
        this.msg = { message: `${error.message}`, show: true, type: "error" };
      }
    },
  },
};
</script>

<style scoped>
.row > div {
  display: contents;
}
</style>
>
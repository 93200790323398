<template>
  <div>
    <v-row v-for="widgets of dashboard" :key="widgets">
      <v-lazy tag="v-col">
        <component :is="widgets" />
      </v-lazy>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import procurementtools from "../../widgets/procurementtools";
import procurementtools2 from "../../widgets/procurementtools2";
import procurementtools3 from "../../widgets/procurementtools3";
import procurementtools4 from "../../widgets/procurementtools4";
import salestools from "../../widgets/salestools";
import salestools2 from "../../widgets/salestools2";
import salestools3 from "../../widgets/salestools3";
import salestools4 from "../../widgets/salestools4";
import salestools5 from "../../widgets/salestools5";
import warehousetools from "../../widgets/warehousetools";
import warehousetoolsuk from "../../widgets/warehousetoolsuk";
import accountingtools from "../../widgets/accountingtools";
import ceotools from "../../widgets/ceotools";
import widget1 from "../../widgets/test1";
import widget2 from "../../widgets/test2";
import widget3 from "../../widgets/test3";
import peddersuk from "../../widgets/peddersuk";
import peddersuk2 from "../../widgets/peddersuk2";
import peddersuk3 from "../../widgets/peddersuk3";
import peddersuk4 from "../../widgets/peddersuk4";
import brakespl from "../../widgets/brakespl";
import brakespl2 from "../../widgets/brakespl2";
import brakespl3 from "../../widgets/brakespl3";
import brakespl4 from "../../widgets/brakespl4";
import notes from "../../widgets/notes";
import Vendortools from "../../widgets/vendortools.vue";
import kamtools from "../../widgets/kamtools.vue";
/* eslint-disable */
export default {
  name: "hello",
  data() {
    return {
      fab: false,
      msg: "Welcome to Your Dashboard",
      dashboard: [],
    };
  },
  watch: {
    "$store.state.user": async function () {
      if (this.$store.state.user)
        this.dashboard = this.$store.state.user.role.setup.dashboard;
      else this.dashboard = [];
    },
  },
  created() {
    if (this.$store.state.user)
      this.dashboard = this.$store.state.user.role.setup.dashboard;
    else this.dashboard = [];
  },
  components: {
    procurementtools,
    procurementtools2,
    procurementtools3,
    procurementtools4,
    salestools,
    salestools2,
    salestools3,
    salestools4,
    salestools5,
    warehousetools,
    warehousetoolsuk,
    accountingtools,
    ceotools,
    widget1,
    widget2,
    peddersuk,
    peddersuk2,
    peddersuk3,
    peddersuk4,
    // widget3,
    brakespl,
    brakespl2,
    brakespl3,
    brakespl4,
    notes,
    Vendortools,
    kamtools
  },
};
</script>

<style>
/* This is for documentation purposes and will not be needed in your application */
.v-speed-dial {
  position: absolute;
}

.v-btn--floating {
  position: relative;
}
</style>

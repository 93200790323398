<template>
  <v-select
    v-model="recordSelected"
    :items="records"
    item-text="displayname"
    item-value="_id"
    :label="label"
    multiple
    placeholder
    :rules="[rules.required]"
    :return-object="recordObj"
    :readonly="$route.params.mode == 'view' || (this.formSettings.disabled || this.fieldSettings.disabled == true) || record.archived"
    :disabled="this.formSettings.disabled || this.fieldSettings.disabled == true"
    outlined
    dense
  ></v-select>
</template>

<script>
import service from "../../api/index";
import fieldCalculated from "../../common/methods/fieldCalculated";
/* eslint-disable */
export default {
  props: ["value", "record", "fieldSettings", "formSettings"],
  data() {
    return {
      records: [],
      recordSelected: this.value || false,
      recordObj: true,
      required:
        this.formSettings.required ||
        this.fieldSettings.setup.required ||
        false,
      field: this.formSettings.field || this.formSettings.setup.field,
      label: this.formSettings.name || this.fieldSettings.name,
      rules: {
        required: value => (this.required ? !!value._id || "Required." : true)
      },
      mode: this.$route.params.mode
    };
  },
  watch: {
    async recordSelected(value) {
      if (this.record[this.field] != value){
        console.log("emit",value)
        this.$emit("emit", { field: this.field, value: value });
      }
      this.records = await this.getData(this.fieldSettings, this.formSettings);
    },
    record: {
      handler: async function(after, before) {
        this.records = await this.getData(
          this.fieldSettings,
          this.formSettings
        );
        if (this.record[this.field])
          this.recordSelected = this.record[this.field];
      },
      deep: true
    }
  },
  async created() {
    console.log(this.record[this.field])
    this.records = await this.getData(this.fieldSettings, this.formSettings);
  },
  methods: {
    getData(source, form) {
      //console.log(source,form)
      source.setup.filters = source.setup.filters || {};
      // if (form.setup) {
      //   Object.assign(source.setup.filters, form.filters || {});
      //   source.setup.default = form.default
      //     ? form.default
      //     : source.setup.default;
      // }
      // if (form.filters) {
      //   Object.assign(source.setup.filters, form.filters || {});
      //   source.setup.default = form.default
      //     ? form.default
      //     : source.setup.default;
      // }
      let field = fieldCalculated(source, this.record);

      // if (field.default && !this.recordSelected && this.mode=="create") {
      //   //console.log("change")
      //   this.recordSelected = field.default;
      //   this.record[field._id] = field.default;
      // }
      let parameters = [
        source.filters,
        { name: true, displayname: true, recordtype: true, collection: true }
      ];
      let data = {
        action: "find",
        data: parameters
      };
      //console.log("Select", data);
      return service(field.setup.collection, data)
        .catch(error => {
          throw error;
        })
        .then(response => {
          //console.log("select", response);
          let options = response.map(obj => {
            if (this.recordObj) {
              obj.displayname = obj.displayname || obj.name;
              return obj;
            } else
              return { displayname: obj.displayname || obj.name, _id: obj._id };
          });

          return options;
        })
        .catch(error => {});
    }
  }
};
</script>

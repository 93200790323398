function sort(items, sortBy = [], sortDesc) {
    items.sort((a, b) => {
        if (sortBy && sortBy[0]) {
            if (!sortDesc[0]) {

                if (
                    (b[sortBy] ? b[sortBy].name || b[sortBy] : "") >
                    (a[sortBy] ? a[sortBy].name || a[sortBy] : "")
                )
                    return -1;
                else return 1;
            } else {
                if (
                    (a[sortBy] ? a[sortBy].name || a[sortBy] : "") >
                    (b[sortBy] ? b[sortBy].name || b[sortBy] : "")
                )
                    return -1;
                else return 1;
            }
        }
    });

    return items;
}
export default sort;
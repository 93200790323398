
<template>
    <orgchart />
  </template>
  
  <script>
  import orgchart from "./OrgChart";
  /* eslint-disable */
  export default {
    data() {
      return {
        loading: true
      };
    },
    components: {
      orgchart
    }
  };
  </script>
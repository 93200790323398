<template>
  <div>
    <template v-if="!$route.meta.allowAnonymous">
      <v-app id="app">
        <toolbar @toggleNavigationBar="drawer = !drawer" />
        <navigation :toggle="drawer" />
        <v-main>
          <!--<breadcrumbs />-->
          <v-container fluid grid-list-xl>
            <router-view :key="$route.fullPath"></router-view>
          </v-container>
        </v-main>
      </v-app>
    </template>
    <template v-else>
      <transition>
        <keep-alive>
          <router-view :key="$route.fullPath"></router-view>
        </keep-alive>
      </transition>
    </template>
  </div>
</template>

<script>
/* eslint-disable */
import store from "./store/index";

export default {
  name: "App",
  data() {
    return {
      drawer: false,
    };
  },
  watch: {
    $route: {
      handler: (to, from) => {
        if (from) store.state.lastPage = from.path;
        document.title = to.meta.title || "3C ERP";
      },
      immediate: true,
    },
  },
  async created() {
    await this.$store.dispatch("verifyToken", this.$store.state.user);
    if (!this.$store.state.user.logged && this.$route.name !== "Login") {
      this.$store.state.lastPage = this.$route.path;
      this.$router.push({ name: "Login" });
    }
  },
  updated: async function () {
    await this.$store.dispatch("verifyToken", this.$store.state.user);
    if (!this.$store.state.user.logged && this.$route.name !== "Login") {
      this.$store.state.lastPage = this.$route.path;
      this.$router.push({ name: "Login" });
    }
  },
};
</script>

<style>
.theme--dark.v-tabs > .v-tabs-bar {
  background-color: #424242 !important;
}
.row {
  margin: 0 -12px !important;
}
.v-textarea textarea {
  line-height: 1rem !important;
}
.v-input--switch {
  display: inline-block !important;
}
.v-main {
  background-color: #f4f5fa;
}
.v-chip.v-chip--label {
  border-radius: unset !important;
}
pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
</style>

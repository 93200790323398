import service from '../../api/index';
/* eslint-disable */
export default (context, rid = 'query', filters) => {
  let query = {};
  const collection = 'queries';
  let data = {
    action: 'getQuery',
    rid:  rid,
  };
  return service(collection, data)
    .catch(error => {
      throw error;
    })
    .then(response => {
      query = response;
      if (filters) query.stages.unshift({ type: '$match', code: filters });
      return query;
    })
};

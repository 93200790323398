<template>
  <v-container fluid grid-list-xl>
    <v-layout row wrap>
      <v-flex md4>
        <v-text-field v-model="query.receiverto" outlined label="To"></v-text-field>
      </v-flex>
      <v-flex md4>
        <v-select
          v-model="query.emailfrom"
          :items="emails"
          item-text="name"
          item-value="_id"
          label="From"
          outlined
          clearable
        ></v-select>
      </v-flex>
      <v-flex md4>
        <v-select
          v-model="query.emailtemplate"
          :items="templates"
          item-text="name"
          item-value="_id"
          label="Template"
          outlined
          clearable
        ></v-select>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex md4>
        <v-text-field v-model="query.receivercc" outlined label="CC"></v-text-field>
      </v-flex>
      <v-flex md4>
        <v-text-field v-model="query.receiverbcc" outlined label="BCC"></v-text-field>
      </v-flex>
      <v-flex md4>
        <v-select
          v-model="query.frequency"
          :items="frequencies"
          item-text="name"
          item-value="_id"
          label="Frequency"
          outlined
          clearable
        ></v-select>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
export default {
  props: ["rid", "mode", "query"],
  data() {
    return {
      rules: {
        required: value => !!value || "Required."
      },
      frequencies: [],
      templates: [],
      emails: []
    };
  },
  async created() {
    this.frequencies = await service("lists", {
      action: "find",
      data: [
        {
          recordtype: { $in: ["frequency"] }
        }
      ]
    });
    this.emails = await service("emails", {
      action: "find",
      data: [
        {
          recordtype: { $in: ["emailbox"] }
        }
      ]
    });
    this.templates = await service("emails", {
      action: "find",
      data: [
        {
          type: { $in: ["query", "report"] },
          recordtype: { $in: ["emailtemplate"] }
        }
      ]
    });
  }
};
</script>

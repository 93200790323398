var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","search":_vm.search,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter,"custom-sort":_vm.sort},scopedSlots:_vm._u([{key:"item.referencenumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.referencenumber || "").split(",").join(", "))+" ")]}},{key:"item.kitcomponent",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.kitcomponent ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.kitcomponent ? "Yes" : "No"))])]}},{key:"item.item",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.item.collection) + "/" + (item.item.recordtype) + "/view/" + (item.item._id)),
            }}},[_vm._v(_vm._s(item.item.displayname || item.item.name))])]}},{key:"item.customer",fn:function(ref){
            var item = ref.item;
return [(item.customer)?_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.customer.collection) + "/" + (item.customer.recordtype) + "/view/" + (item.customer._id)),
            }}},[_vm._v(_vm._s(item.customer.displayname || item.customer.name))]):_vm._e()]}},{key:"item.date",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.date).toISOString().substr(0, 10)))])]}},{key:"item.transaction",fn:function(ref){
            var item = ref.item;
return [(item.transaction)?_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.transaction.collection) + "/" + (item.transaction.recordtype) + "/view/" + (item.transaction._id)),
            }}},[_vm._v(_vm._s(item.transaction.displayname || item.transaction.name))]):_vm._e()]}},{key:"item.createdfromtransaction",fn:function(ref){
            var item = ref.item;
return [(item.createdfromtransaction)?_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.createdfromtransaction.collection) + "/" + (item.createdfromtransaction.recordtype) + "/view/" + (item.createdfromtransaction._id)),
            }}},[_vm._v(_vm._s(item.createdfromtransaction.displayname || item.createdfromtransaction.name))]):_vm._e()]}},{key:"top",fn:function(){
            var ref;
return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('exportData',{attrs:{"headers":_vm.headers,"items":_vm.items}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.locations,"label":"Location","item-text":"name","item-value":"_id","outlined":"","clearable":"","multiple":"","dense":""},on:{"change":function($event){return _vm.getData()}},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.recordtypes,"label":"Record Type","item-text":"name","item-value":"_id","outlined":"","clearable":"","multiple":"","dense":""},on:{"change":function($event){return _vm.getData()}},model:{value:(_vm.recordtype),callback:function ($$v) {_vm.recordtype=$$v},expression:"recordtype"}})],1),(
                  _vm.recordtype &&
                  (ref = ['purchaseorder', 'salesorder']).includes.apply(ref, _vm.recordtype)
                )?_c('v-col',[_c('v-switch',{attrs:{"label":"Only Open"},model:{value:(_vm.onlyopen),callback:function ($$v) {_vm.onlyopen=$$v},expression:"onlyopen"}})],1):_vm._e(),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Date from","placeholder":"","append-icon":"event","readonly":"","outlined":"","dense":"","hide-details":"","clearable":""},on:{"click:clear":function($event){return _vm.getData('datefrom')}},model:{value:(_vm.datefrom),callback:function ($$v) {_vm.datefrom=$$v},expression:"datefrom"}},on))]}}]),model:{value:(_vm.menufrom),callback:function ($$v) {_vm.menufrom=$$v},expression:"menufrom"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1},on:{"change":function($event){return _vm.getData()}},model:{value:(_vm.datefrom),callback:function ($$v) {_vm.datefrom=$$v},expression:"datefrom"}})],1)],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Date to","placeholder":"","append-icon":"event","readonly":"","outlined":"","dense":"","hide-details":"","clearable":""},on:{"click:clear":function($event){return _vm.getData('dateto')}},model:{value:(_vm.dateto),callback:function ($$v) {_vm.dateto=$$v},expression:"dateto"}},on))]}}]),model:{value:(_vm.menuto),callback:function ($$v) {_vm.menuto=$$v},expression:"menuto"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1},on:{"change":function($event){return _vm.getData()}},model:{value:(_vm.dateto),callback:function ($$v) {_vm.dateto=$$v},expression:"dateto"}})],1)],1),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.getData()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)],1)],1)]},proxy:true}])},[_c('template',{slot:"body.append"},[_c('tr',_vm._l((_vm.headers),function(col,index){return _c('th',{key:index},[_vm._v(" "+_vm._s(col.summary ? _vm.calcTotal(col.value) : "")+" ")])}),0)])],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
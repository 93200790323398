 <template>
  <v-container fluid class="py-0">
    <v-alert type="error" dense v-if="purchaseorderrequired"
      >Purchase Order Required!</v-alert
    >

    <v-alert
      type="warning"
      dense
      v-for="instruction of record.customer
        ? record.customer.additionalinstructions
        : []"
      :key="instruction._id"
      >{{ instructions[instruction._id || instruction] }}</v-alert
    >
    <v-alert type="error" dense v-if="stockrequestrequired"
      >Stock Request Required!</v-alert
    >
    <v-alert
      v-if="record.weight > 150"
      type="warning"
      dense
      icon="mdi-weight-kilogram"
    >
      Over 150kg - individual quotation on freight cost
    </v-alert>

    <v-alert
      type="info"
      dense
      v-if="record.customer && record.customer.shippinginstructions"
    >
      <pre>{{ record.customer.shippinginstructions }}</pre>
    </v-alert>
    <v-alert
      type="error"
      dense
      v-if="
        record.validtaxnumber == false &&
        this.record.billcountry &&
        !this.record.billcountry.exportcustomsclearance &&
        this.record.customer &&
        !this.record.customer.isperson
      "
      >Invalid VAT Number</v-alert
    >
    <v-alert
      type="error"
      dense
      v-if="record.customer && record.customer.accountonhold"
      >Account On Hold!</v-alert
    >
    <v-row row wrap>
      <!-- <v-col>
            <v-subheader>Main</v-subheader>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Date:</v-list-item-subtitle>
                      <v-list-item-title>{{ date }}</v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Created By:</v-list-item-subtitle>
                      <v-list-item-title>{{
                        record.createdby
                          ? record.createdby.name
                          : record.customer.name
                      }}</v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </v-list>
          </v-col>
          <v-divider vertical></v-divider> -->

      <v-col>
        <v-subheader>Main</v-subheader>

        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col class="py-0" style="max-width: 50%">
                  <v-list-item-subtitle>Customer:</v-list-item-subtitle>

                  <v-list-item-title>
                    {{ order.customer }}
                  </v-list-item-title>
                </v-col>
                <v-col class="py-0" v-if="order.source">
                  <v-list-item-subtitle>Source:</v-list-item-subtitle>

                  <v-list-item-title>
                    {{ order.source }}
                  </v-list-item-title>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col class="py-0">
                  <v-list-item-subtitle>Date:</v-list-item-subtitle>
                  <v-list-item-title>{{ order.date }}</v-list-item-title>
                </v-col>
                <!-- <v-col
                  class="py-0"
                  v-if="
                    record.createdby &&
                    record.createdby._id != record.customer._id
                  "
                >
                  <v-list-item-subtitle>Created By:</v-list-item-subtitle>
                  <v-list-item-title>{{
                    record.createdby
                      ? record.createdby.name
                      : record.customer.name
                  }}</v-list-item-title>
                </v-col> -->
              </v-row>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="primary">business</v-icon>
            </v-list-item-action>

            <v-list-item-content class="py-1">
              <v-row>
                <v-col class="py-0">
                  <v-list-item-title
                    >{{ order.invoice_company
                    }}{{ order.invoice_fullname }}</v-list-item-title
                  >
                  <v-list-item-subtitle
                    >{{ order.invoice_address }}, {{ order.invoice_city }}
                    {{ order.invoice_postcode }}<br />{{
                      order.invoice_country
                    }}</v-list-item-subtitle
                  ></v-col
                >
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-divider inset></v-divider>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="primary">local_shipping</v-icon>
            </v-list-item-action>

            <v-list-item-content class="py-1">
              <v-row>
                <v-col class="py-0">
                  <v-list-item-title
                    >{{ order.delivery_company
                    }}{{ order.delivery_fullname }}</v-list-item-title
                  >
                  <v-list-item-subtitle
                    >{{ order.delivery_address }}, {{ order.delivery_city }}
                    {{ order.delivery_postcode }}<br />{{
                      order.delivery_country
                    }}<br />{{
                      order.delivery_pointname || order.delivery_pointid
                    }}</v-list-item-subtitle
                  >
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col>
        <v-subheader>Details</v-subheader>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col class="py-0">
                  <v-list-item-subtitle>Phone:</v-list-item-subtitle>
                  <v-list-item-title>{{ order.phone }}</v-list-item-title>
                </v-col>
                <v-col class="py-0">
                  <v-list-item-subtitle>Email:</v-list-item-subtitle>
                  <v-list-item-title>{{ order.email }}</v-list-item-title>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col class="py-0">
                  <v-list-item-subtitle>Shipping Method:</v-list-item-subtitle>
                  <v-list-item-title>{{
                    order.delivery_method
                  }}</v-list-item-title>
                </v-col>
                <v-col class="py-0">
                  <v-list-item-subtitle>Payment Method:</v-list-item-subtitle>
                  <v-list-item-title>{{
                    order.payment_method
                  }}</v-list-item-title>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col class="py-0">
                  <v-list-item-subtitle>Quantity:</v-list-item-subtitle>
                  <v-list-item-title>{{ quantity }}</v-list-item-title>
                </v-col>
                <v-col class="py-0">
                  <v-list-item-subtitle>Shipping Cost:</v-list-item-subtitle>
                  <v-list-item-title>{{
                    order.delivery_price
                  }}</v-list-item-title>
                </v-col>
                <v-col class="py-0">
                  <v-list-item-subtitle>Gross Amount:</v-list-item-subtitle>
                  <v-list-item-title>
                    <b>{{ grossamount }}</b>
                  </v-list-item-title>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </v-list>
      </v-col>
      <v-col v-if="order.validation">
        <v-subheader>Sync Status</v-subheader>
        <v-select
          :items="statuses"
          v-model="order.order_status_id"
          label="Status"
          item-text="name"
          item-value="id"
          outlined
          dense
          hide-details
          class="mt-3 mb-3"
          @change="setStatus(order)"
        ></v-select>
        <v-alert
          type="error"
          dense
          v-for="(error, index) of order.validation.details"
          :key="index"
          ><small
            >Field: {{ error.field }} | 3C Value: {{ error.value }} | BL Value:
            {{ error.baselinker_value }}</small
          ><v-btn
            small
            dense
            style="float: right"
            @click="setField(order, null, error.baselinker_field, error.value)"
            >set</v-btn
          ></v-alert
        >
        <v-divider></v-divider>
        <v-alert
          type="error"
          dense
          v-for="(error, index) of order.validation.products"
          :key="index"
          ><small
            >Field: {{ error.field }} | 3C Value:{{ error.value }} | BL Value:
            {{ error.baselinker_value }}</small
          ><v-btn
            small
            dense
            style="float: right"
            @click="
              setField(order, error.index, error.baselinker_field, error.value)
            "
            >set</v-btn
          ></v-alert
        >
      </v-col>
    </v-row>
  </v-container>
</template>
   
<script>
/* eslint-disable */
import service from "../../../api/index";

export default {
  props: ["record"],
  data() {
    return {
      loading: false,
      order: {},
      statuses: [],
    };
  },
  async created() {
    this.loading = true;
    await this.getStatuses();
    await this.getData();
    this.loading = false;
  },
  watch: {},
  computed: {
    grossamount() {
      return this.order.products.reduce((total, l) => {
        return (total += l.price_brutto * l.quantity);
      }, 0).toFixed(2);
    },
    quantity() {
      return this.order.products.reduce((total, l) => {
        return (total += l.quantity);
      }, 0);
    },
  },

  methods: {
    rowClass(item) {
      if (
        ((item.paymentstatusid && item.paymentstatusid != "fullypaid") ||
          !item.paymentstatusid) &&
        item.terms == "Prepaid"
      ) {
        return "unpaid";
      }
    },
    getFiltered(e) {
      this.filteredItems = e;
    },

    async getStatuses() {
      try {
        this.statuses = await service(
          "baselinker",
          {
            action: "baselinker_getStatuses",
          },
          false,
          true
        );
      } catch (err) {
        alert(error);
      }
    },
    async setField(order, product, field, value) {
      this.loading = true;
      try {
        let result = await service(
          "baselinker",
          {
            action: "baselinker_setField",
            order: order.order_id,
            product: product,
            field: field,
            value: value,
          },
          false,
          true
        );
      } catch (err) {
        item.warring = err;
      }
      await this.getData();
      this.loading = false;
    },
    async setStatus(order) {
      this.loading = true;
      try {
        let result = await service(
          "baselinker",
          {
            action: "baselinker_setStatus",
            order: order.order_id,
            status: order.order_status_id,
          },
          false,
          true
        );
      } catch (err) {
        item.warring = err;
      }
      await this.getData();
      this.loading = false;
    },
    async getData() {
      this.loading = true;

      this.order = await service(
        "baselinker",
        {
          action: "baselinker_getOrder",
          order: this.record.baselinker_order_id,
          order2: this.record._id,
        },
        false,
        true
      );

      this.loading = false;
    },
  },
};
</script>
<style>
.otherlocation {
  background: #ececec;
}
</style>
<template>
  <v-app-bar app color="white">
    <v-app-bar-nav-icon @click="toggleNavigationBar"></v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <v-spacer></v-spacer>
    <globalsearch/>
    
  </v-app-bar>
</template>

<script>
import shortcuts from "./Shortcuts";
import globalsearch from "./globalSearch";
/* eslint-disable */
export default {
  data() {
    return {};
  },
  components: {
    shortcuts,
    globalsearch
  },
  methods: {
    toggleNavigationBar() {
      console.log("click");
      this.$store.dispatch("changeNav");
    }
  }
};
</script>

<template>
  <v-app id="login" class="secondary">
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-12" v-if="mode == 'resetpassword'">
            <v-toolbar dark color="primary">
              <v-toolbar-title>3C ERP</v-toolbar-title>
            </v-toolbar>
            <v-progress-linear
              :indeterminate="loading"
              height="3"
              class="ma-0"
            ></v-progress-linear>
            <v-card-text>
              <v-form
                v-model="valid"
                lazy-validation
                @keyup.native.enter="login"
              >
                <v-text-field
                  :type="hidePassword ? 'password' : 'text'"
                  :append-icon="hidePassword ? 'visibility_off' : 'visibility'"
                  name="password"
                  label="Password"
                  id="password"
                  :rules="[rules.required, rules.min]"
                  v-model="password"
                  @click:append="hidePassword = !hidePassword"
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                block
                color="primary"
                @click="resetPassword"
                :loading="loading"
                :disabled="!valid"
                >Set Password</v-btn
              >
            </v-card-actions>
          </v-card>
          <v-card class="elevation-12" v-else>
            <v-toolbar dark color="primary">
              <v-toolbar-title>3C ERP</v-toolbar-title>
            </v-toolbar>
            <v-progress-linear
              :indeterminate="loading"
              height="3"
              class="ma-0"
            ></v-progress-linear>
            <v-card-text>
              <v-form
                v-model="valid"
                lazy-validation
                @keyup.native.enter="login"
              >
                <v-text-field
                  append-icon="person"
                  name="login"
                  label="Email"
                  type="email"
                  v-model="userEmail"
                  :rules="[rules.required, rules.email]"
                />
                <v-text-field
                  v-if="!forgot"
                  :type="hidePassword ? 'password' : 'text'"
                  :append-icon="hidePassword ? 'visibility_off' : 'visibility'"
                  name="password"
                  label="Password"
                  id="password"
                  :rules="[rules.required, rules.min]"
                  v-model="password"
                  @click:append="hidePassword = !hidePassword"
                />
                <v-text-field
                  v-if="auth2fa"
                  type="text"
                  name="auth2fa"
                  label="2FA Code"
                  id="auth2fa"
                  :rules="[rules.required]"
                  v-model="code2fa"
                />
              </v-form>
              <v-btn
                text
                class="red--text text-caption ma-0 pa-0 float-right mt-n4"
                x-small
                @click="forgot = !forgot"
              >
                {{ !forgot ? "Forgot password?" : "Back to login" }}
              </v-btn>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="forgot"
                block
                color="primary"
                @click="reset"
                :loading="loading"
                :disabled="!valid"
                >Reset Password</v-btn
              >
              <v-btn
                v-else
                block
                color="primary"
                @click="login"
                :loading="loading"
                :disabled="!valid"
                >Login</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-snackbar v-model="msg.show" :timeout="6000" top absolute>{{
      msg.message
    }}</v-snackbar>
  </v-app>
</template>

<script>
import service from "../../api/index";
/* eslint-disable */
export default {
  data() {
    return {
      user: this.$route.query.u,
      access: this.$route.query.a,
      auth2fa: false,
      forgot: false,
      code2fa: "",
      mode: this.$route.params.mode,
      valid: false,
      loading: false,
      userEmail: "",
      password: "",
      hidePassword: true,
      error: false,
      msg: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (value) => value.length >= 6 || "Min 6 characters",
        email: (value) => /.+@.+/.test(value) || "E-mail must be valid",
      },
    };
  },

  methods: {
    reset() {
      this.loading = true;
      let data = {
        action: "login",
        email: this.userEmail,
        resetpassword: true,
      };
      service("users_access", data)
        .catch((error) => {
          throw error;
        })
        .then((response) => {
          this.msg = { message: `${response.message}`, show: true };
          this.loading = false;
          this.forgot = false;
        })
        .catch((error) => {
          this.msg = { message: `${error.message}`, show: true };
          this.loading = false;
        });
      this.loading = false;
    },
    login() {
      this.loading = true;
      let data = {
        action: "login",
        email: this.userEmail,
        password: this.password,
        code2fa: this.code2fa,
      };
      service("users_access", data)
        .catch((error) => {
          throw error;
        })
        .then((response) => {
          if (response.token && response._id) {
            this.$store.dispatch("setUser", response);
            this.$router.push({
              path: this.$store.state.lastPage.includes("/login")
                ? "/"
                : this.$store.state.lastPage || "/",
            });
            this.$store.state.lastPage = "";
          } else {
            this.msg = { message: `${response.message}`, show: true };
            if (response.auth2fa) {
              this.$store.dispatch("setToken", response.token);
              this.auth2fa = true;
            }
            this.mode = "null";
          }
          this.loading = false;
        })
        .catch((error) => {
          this.msg = { message: `${error.message}`, show: true };
          this.loading = false;
        });
      this.loading = false;
    },
    resetPassword() {
      this.loading = true;
      let data = {
        action: "setPassword",
        user: this.user,
        access: this.access,
        password: this.password,
      };
      service("users_access", data)
        .catch((error) => {
          throw error;
        })
        .then((response) => {
          this.msg = { message: `${response.message}`, show: true };
          this.mode = null;
        })
        .catch((error) => {
          this.msg = { message: `${error.message}`, show: true };
          this.loading = false;
        });
      this.loading = false;
    },
  },
};
</script>

<style scoped>
#login {
  background-image: url("logistics.jpg");
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
</style>

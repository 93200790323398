<template>
  <v-card v-if="!loading">
    <google-map
      :config="mapConfig"
      :key="updatedCpunt"
      apikey="AIzaSyAeBjRkfSsywTZQIjapMQRcYsDgldO99Ok"
    >
      <GoogleMapMarkers :markers="mapMarkers"/>
    </google-map>
    <!-- <v-card-title>
      <div>
        <span class="grey--text">{{ mapMarkers[0].addressee }}</span>
        <br>
        <span v-if="mapMarkers[0].address">{{ mapMarkers[0].address }}</span>
        <br>
        <span v-if="mapMarkers[0].zip">{{ mapMarkers[0].zip }},</span>
        <span v-if="mapMarkers[0].city">{{ mapMarkers[0].city }},</span>
        <span>{{ mapMarkers[0].country }}</span>
      </div>
    </v-card-title>-->
  </v-card>
</template>

<script>
/* eslint-disable */
import { getName } from "country-list";
import GoogleMap from "../../components/maps/GoogleMap";
import GoogleMapMarkers from "../../components/maps/GoogleMapMarkers";
import service from "../../api";

export default {
  components: {
    GoogleMap,
    GoogleMapMarkers
  },
  props: ["addresses", "data"],
  data() {
    return {
      updatedCpunt: 0,
      loading: true,
      mapConfig: {
        zoom: 5,
        center: {
          lat: 0,
          lng: 0
        }
      },
      mapMarkers: []
    };
  },
  async created() {
    await this.setMakers();
  },
  watch: {
    addresses: {
      handler: async function(after, before) {
        await this.setMakers();
      },
      deep: true
    },
  },
  methods: {
    async setMakers() {
      this.loading = true;
      //console.log("setMakers",this.addresses);
      for (let address of this.addresses) {
        if (!address.latitude || !address.longitude) {
          let location = await this.geocode(address.geohint || `${address.address},${address.zip} ${address.city}, ${address.country} `);
          //console.log(location)
          Object.assign(address, location);
        }
        //console.log(address)
        this.mapMarkers.push(address);
        this.mapConfig.center.lat = parseFloat(address.latitude);
        this.mapConfig.center.lng = parseFloat(address.longitude);
        
      }
      this.loading = false;
    },

    geocode(hint) {
      return fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${hint}&key=` +
          "AIzaSyAeBjRkfSsywTZQIjapMQRcYsDgldO99Ok"
      )
        .then(async response => {
          let response_data = await response.json();

          if (response.ok) {
            return response_data;
          } else {
            throw response_data;
          }
        })
        .then(response => {
          return {
            latitude: response.results[0].geometry.location.lat,
            longitude: response.results[0].geometry.location.lng
          };
        });
    }
  }
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}
</style>

<script>
import { Line, mixins } from "vue-chartjs";
import colors from "./colors";
const { reactiveProp } = mixins;
export default {
  props: ["data", "option", "chartData"],
  mixins: [reactiveProp],
  extends: Line,
  data: () => ({
    options: {
      legend: {
        display: true,
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-1",
          },
        ],
      },
    },
  }),
  mounted() {
    this.options = Object.assign(this.options, this.option);
    this.options.legend.display =
      this.chartData.datasets.length > 10 ? false : true;
    this.renderChart(this.chartData, this.options);
  },
};
</script>
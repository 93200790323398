<template>
  <div>
    <queryresult v-if="!$route.params.mode" queryid="map"/>
    <creator v-if="$route.params.mode=='edit' || $route.params.mode=='create'"/>
    <googlemap v-if="$route.params.mode=='view'"/>
  </div>
</template>

<script>
import creator from './edit-create/View';
import googlemap from './view-results/View';
/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
    };
  },
  components: {
    creator,
    googlemap,
  },
};
</script>
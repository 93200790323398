var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading-text":"Loading... Please wait","loading":_vm.loading,"item-key":"_id","search":_vm.search,"single-select":_vm.singleSelect,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.date ? new Date(item.date).toISOString().substr(0, 10) : ""))])]}},{key:"item.isperson",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.isperson ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.isperson ? "Yes" : "No"))])]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [(item.customer)?_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.customer.collection) + "/" + (item.customer.recordtype) + "/view/" + (item.customer._id)),
            }}},[_vm._v(_vm._s(item.customer.displayname || item.customer.name))]):_vm._e()]}},{key:"item.item",fn:function(ref){
            var item = ref.item;
return [(item.item)?_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.item.collection) + "/" + (item.item.recordtype) + "/view/" + (item.item._id)),
            }}},[_vm._v(_vm._s(item.item.displayname || item.item.name))]):_vm._e()]}},{key:"item.transaction",fn:function(ref){
            var item = ref.item;
return [(item.transaction)?_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.transaction.collection) + "/" + (item.transaction.recordtype) + "/view/" + (item.transaction._id)),
            }}},[_vm._v(_vm._s(item.transaction.displayname || item.transaction.name))]):_vm._e()]}},{key:"item.correlated",fn:function(ref){
            var item = ref.item;
return _vm._l((item.correlated),function(so,index){return _c('router-link',{key:index,staticClass:"link",attrs:{"to":{
              path: ("/" + (so.collection) + "/" + (so.recordtype) + "/view/" + (so._id)),
            }}},[_vm._v(" "+_vm._s(so.displayname || so.name)+" "),(index + 1 < item.correlated.length)?_c('span',[_vm._v(",")]):_vm._e()])})}},{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('exportData',{attrs:{"headers":_vm.headers,"items":_vm.items}})],1),_c('v-spacer'),_c('v-col',[_c('v-select',{staticClass:"mt-2 mb-2",attrs:{"items":_vm.companies,"label":"Company","item-text":"name","item-value":"_id","outlined":"","multiple":"","dense":""},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"mt-2 mb-2",attrs:{"label":"Date from","placeholder":"","append-icon":"event","readonly":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.datefrom),callback:function ($$v) {_vm.datefrom=$$v},expression:"datefrom"}},on))]}}]),model:{value:(_vm.menufrom),callback:function ($$v) {_vm.menufrom=$$v},expression:"menufrom"}},[_c('v-date-picker',{model:{value:(_vm.datefrom),callback:function ($$v) {_vm.datefrom=$$v},expression:"datefrom"}})],1)],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"mt-2 mb-2",attrs:{"label":"Date To","placeholder":"","append-icon":"event","readonly":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.dateto),callback:function ($$v) {_vm.dateto=$$v},expression:"dateto"}},on))]}}]),model:{value:(_vm.menuto),callback:function ($$v) {_vm.menuto=$$v},expression:"menuto"}},[_c('v-date-picker',{model:{value:(_vm.dateto),callback:function ($$v) {_vm.dateto=$$v},expression:"dateto"}})],1)],1),_c('v-col',[_c('v-btn',{staticClass:"mt-2 mb-2",attrs:{"block":"","tile":"","color":"primary"},on:{"click":function($event){return _vm.getData()}}},[_vm._v("Refresh")])],1)],1)],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-autocomplete',{attrs:{"items":_vm.records,"item-text":"displayname","item-value":"_id","label":_vm.label,"placeholder":"","multiple":_vm.multiple,"rules":[_vm.rules.required],"return-object":_vm.recordObj,"small-chips":"","readonly":(_vm.$route.params.mode == 'view' ||
        this.formSettings.disabled ||
        this.fieldSettings.disabled == true ||
        _vm.record.archived ||
        _vm.loading) &&
      !this.formSettings.show,"disabled":(this.formSettings.disabled || this.fieldSettings.disabled == true) &&
      !this.formSettings.show,"outlined":"","clearable":_vm.$route.params.mode == 'view' || _vm.record.archived ? false : true,"loading":_vm.loading,"dense":"","prepend-icon":_vm.$route.params.mode == 'view' || !this.fieldSettings.setup.add
        ? ''
        : 'add_box'},on:{"click:prepend":function($event){return _vm.openDialog()}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected,"small":"","pill":"","label":"","outlined":""},on:{"click":function($event){return _vm.openDialog(data.item)}}},'v-chip',data.attrs,false),[_c('v-avatar',{attrs:{"left":""}},[_vm._v(" "+_vm._s(data.item.quantity)+" ")]),_vm._v(" "+_vm._s(data.item.bin || data.item.box)+" ")],1)]}}]),model:{value:(_vm.recordSelected),callback:function ($$v) {_vm.recordSelected=$$v},expression:"recordSelected"}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.tmp)?_c('v-card',{key:_vm.tmp._id},[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.fieldSettings.name))])],1),_c('v-card-text',{staticClass:"mt-6"},_vm._l((_vm.fieldSettings.setup.fields),function(field){return _c(_vm.fields[field.field]
              ? _vm.fields[field.field].setup.type
              : 'field-text',{key:field.field,tag:"component",attrs:{"record":Object.assign({}, _vm.record, _vm.tmp),"fieldSettings":_vm.fields[field.field],"formSettings":field,"id":field.field},on:{"emit":function($event){return _vm.catchEmit($event, {})}},model:{value:(_vm.tmp[field.field]),callback:function ($$v) {_vm.$set(_vm.tmp, field.field, $$v)},expression:"tmp[field.field]"}})}),1),_c('v-card-actions',[_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"primary","tile":"","block":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" cancel ")])],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary","tile":"","block":""},on:{"click":function($event){return _vm.saveSubrecord(_vm.tmp)}}},[_vm._v(" Save ")])],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
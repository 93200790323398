var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","full-width":"","min-width":"290px","readonly":(_vm.$route.params.mode == 'view' ||
      this.formSettings.disabled ||
      this.fieldSettings.disabled == true ||
      _vm.record.archived) &&
    !this.formSettings.show,"disabled":(_vm.$route.params.mode == 'view' ||
      this.formSettings.disabled ||
      this.fieldSettings.disabled == true ||
      _vm.record.archived) &&
    !this.formSettings.show},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.label,"placeholder":"","append-icon":"event","rules":[_vm.rules.required],"readonly":"","outlined":"","dense":"","clearable":"","persistent-hint":"","hint":_vm.record.delivery && ['salesorder'].includes(_vm.record.parentrecordtype)
          ? _vm.record.delivery.name
          : ''},on:{"click:clear":function($event){return _vm.emitChangedValue(true)}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-date-picker',{attrs:{"allowed-dates":_vm.allowedDates,"events":_vm.allowedEvents,"first-day-of-week":1},on:{"input":function($event){return _vm.emitChangedValue()},"click:date":_vm.dateClick},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('v-divider'),(_vm.record.delieriesTransitObj)?_c('v-select',{staticClass:"mx-4 py-4",attrs:{"dense":"","item-text":"displayname","item-value":"_id","items":_vm.record.delieriesTransitObj,"label":"Delivery","return-object":"","hint":_vm.record.delivery && ['salesorder'].includes(_vm.record.parentrecordtype)
          ? _vm.record.delivery.name
          : '',"persistent-hint":"","clearable":""},on:{"change":_vm.setDelivery,"click:clear":function($event){return _vm.setDelivery($event, true)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.displayname)+" ")]}},{key:"item",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.displayname)+" ("+_vm._s(item.quantity)+") ")]}}],null,false,1407537904),model:{value:(_vm.delivery),callback:function ($$v) {_vm.delivery=$$v},expression:"delivery"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
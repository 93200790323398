var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.record.company)?_c('div',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","show-select":"","search":_vm.search,"single-select":_vm.singleSelect,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter},scopedSlots:_vm._u([{key:"item.createdfrom",fn:function(ref){
var item = ref.item;
return _vm._l((item.createdfrom),function(po){return _c('router-link',{key:po._id,staticClass:"link",attrs:{"to":{
              path: ("/" + (po.collection) + "/" + (po.recordtype) + "/view/" + (po._id)),
            }}},[_vm._v(_vm._s(po.displayname || po.name))])})}},{key:"item.date",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.date).toISOString().substr(0, 10)))])]}},{key:"item.transaction",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.transaction.collection) + "/" + (item.transaction.recordtype) + "/view/" + (item.transaction._id)),
            }}},[_vm._v(_vm._s(item.transaction.displayname || item.transaction.name))])]}},{key:"item.customer",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.customer.collection) + "/" + (item.customer.recordtype) + "/view/" + (item.customer._id)),
            }}},[_vm._v(_vm._s(item.customer.displayname || item.customer.name))])]}},{key:"item.expensescategory",fn:function(ref){
            var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.expensescategory,"label":"Expenses","item-text":"name","item-value":"_id","single-line":"","hide-details":"","outlined":"","dense":"","return-object":""},on:{"change":function($event){return _vm.refresh()}},model:{value:(item.expensescategory),callback:function ($$v) {_vm.$set(item, "expensescategory", $$v)},expression:"item.expensescategory"}})]}},{key:"item.assignmentmethod",fn:function(ref){
            var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.assignmentmethod,"label":"Method","item-text":"name","item-value":"_id","single-line":"","hide-details":"","outlined":"","dense":"","return-object":""},on:{"change":function($event){return _vm.refresh()}},model:{value:(item.assignmentmethod),callback:function ($$v) {_vm.$set(item, "assignmentmethod", $$v)},expression:"item.assignmentmethod"}})]}},{key:"item.amount",fn:function(ref){
            var item = ref.item;
return [_c('v-text-field',{attrs:{"dense":"","label":"Assign","single-line":"","hide-details":"","outlined":""},on:{"change":function($event){return _vm.refresh()}},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}})]}},{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true}],null,false,1690746287),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
const colors = ["#93aecd",
"#ebffb2",
"#cab1f9",
"#c3e9a1",
"#f8bcfd",
"#7fd9ab",
"#ffa2c9",
"#5ec19b",
"#f996ac",
"#4cddd8",
"#f99799",
"#3bdbe8",
"#faad85",
"#1abee8",
"#f9c787",
"#73b8fb",
"#d5c177",
"#75c7ff",
"#dca16b",
"#42dcfc",
"#e5997f",
"#33d2f4",
"#d0a373",
"#6cd6ff",
"#fff7b1",
"#ce9ad5",
"#bdffca",
"#ffb8de",
"#a7c279",
"#dfcfff",
"#98b486",
"#e4979a",
"#6be0ff",
"#ffc1bd",
"#32bbd0",
"#ffd3b6",
"#59b7dc",
"#ffedc8",
"#70b7c2",
"#cba395",
"#b9ffec",
"#baa4bf",
"#60bbab",
"#bbdaff",
"#b6ab87",
"#a6e7ff",
"#e9e3c6",
"#89b4ae",
"#e4ffe2",
"#a6d2d6"]
export default colors
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","search":_vm.search,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.date ? new Date( new Date(item.date).getTime() - new Date(item.date).getTimezoneOffset() * 60000 ) .toISOString() .substr(0, 10) + " " + new Date( new Date(item.date).getTime() - new Date(item.date).getTimezoneOffset() * 60000 ) .toISOString() .substr(11, 5) : ""))])]}},{key:"item.subrecord",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.subrecord ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.subrecord ? "Yes" : "No"))])]}},{key:"item.oldvalue",fn:function(ref){
var item = ref.item;
return [(item.oldvalue === true || item.oldvalue === false)?_c('v-chip',{attrs:{"label":"","color":item.oldvalue ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.oldvalue ? "Yes" : "No"))]):_c('span',[_vm._v(_vm._s(item.oldvalue))])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [(item.value === true || item.value === false)?_c('v-chip',{attrs:{"label":"","color":item.value ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.value ? "Yes" : "No"))]):_c('span',[_vm._v(_vm._s(item.value))])]}},{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col')],1)],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
 <template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          loading-text="Loading... Please wait"
          :loading="loading"
          item-key="_id"
          :search="search"
          :single-select="singleSelect"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
        >
          <template v-slot:item.isperson="{ item }">
            <v-chip label :color="item.isperson ? 'red' : 'none'" dark>{{
              item.isperson ? "Yes" : "No"
            }}</v-chip>
          </template>
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col>
                  <exportData :headers="headers" :items="items" />
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                  <v-menu
                    v-model="menufrom"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        label="Date from"
                        placeholder
                        v-model="datefrom"
                        append-icon="event"
                        readonly
                        v-on="on"
                        outlined
                        dense
                        hide-details
                        class="mt-2 mb-2"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="datefrom"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-menu
                    v-model="menuto"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        label="Date To"
                        placeholder
                        v-model="dateto"
                        append-icon="event"
                        readonly
                        v-on="on"
                        outlined
                        dense
                        hide-details
                        class="mt-2 mb-2"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="dateto"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-btn
                    @click="getData()"
                    block
                    tile
                    color="primary"
                    class="mt-2 mb-2"
                    >Refresh</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";
export default {
  data() {
    return {
      loading: false,
      headers: [
        { text: "Ozparts NIP", value: "pdfform" },
        { text: "Customer", value: "customer" },
        { text: "Individual", value: "isperson" },
        { text: "Tax Number", value: "taxnumber" },
        { text: "Warehouse", value: "location" },
        { text: "Document Name", value: "name" },
        { text: "Type", value: "recordtype" },
        { text: "Created From", value: "createdfrom" },
        { text: "Created From Date", value: "createdfromdate" },
        { text: "Date", value: "date" },
        { text: "Ship Date", value: "shippingdate" },
        { text: "Item", value: "item" },
        { text: "Qty.", value: "quantity" },
        { text: "HS Code", value: "hscode" },
        { text: "Customs Translate", value: "costomstranslate" },
        { text: "Amount", value: "amount" },
        { text: "Unit Weight", value: "weight" },
        { text: "Total Weight", value: "totalweight" },
        { text: "Tax", value: "tax" },
        { text: "Country", value: "country" },
        { text: "CoO", value: "coo" },
      ],
      items: [],
      rows: [100],
      singleSelect: false,
      selected: [],
      search: "",
      datefrom: new Date(
        new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)
      )
        .toISOString()
        .substr(0, 10),
      dateto: new Date(new Date().setDate(0)).toISOString().substr(0, 10),
      menufrom: false,
      menuto: false,
    };
  },
  async created() {
    this.loading = true;
    await this.getData();
    this.loading = false;
  },

  methods: {
    async getData() {
      let result = await service(
        "orders",
        {
          action: "getIntrastat",
          datefrom: this.datefrom,
          dateto: this.dateto,
        },
        false,
        true
      );
      this.items = result.items;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
  },
  components: {
    exportData,
  },
};
</script>
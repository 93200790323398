var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-switch',{staticClass:"pa-0",attrs:{"inset":"","rules":[_vm.rules.required],"readonly":_vm.$route.params.mode == 'view' && !this.formSettings.show,"disabled":(_vm.$route.params.mode == 'view' ||
       this.formSettings.disabled ||
       _vm.disabled ||
       this.fieldSettings.disabled == true) &&
     !this.formSettings.show,"dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.label)+" "+_vm._s(_vm.hint)+" "),(
         _vm.formSettings &&
         _vm.fieldSettings &&
         (_vm.formSettings.help || _vm.fieldSettings.setup.help)
       )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
       var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","right":""}},on),[_vm._v("mdi-help-circle-outline")])]}}],null,false,1462046735)},[_c('pre',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.formSettings.help || _vm.fieldSettings.setup.help))])]):_vm._e()]},proxy:true}]),model:{value:(_vm.switchValue),callback:function ($$v) {_vm.switchValue=$$v},expression:"switchValue"}})}
var staticRenderFns = []

export { render, staticRenderFns }
import store from '../../store/index'
export default (field, record) => {

  if (field.setup.filters) {
    field.filters = {};

    Object.entries(field.setup.filters).forEach(([key, value]) => {
      if (value.toString().indexOf("this.") >= 0) {
        let filterField = value.split(".");
        if (filterField[2] && record[filterField[2]] && !filterField[3]) {
          if (['forlocations', 'incountries', 'forpricegroups'].includes(key)) {
            delete field.filters[key];
            field.filters['$and'] = field.filters['$and'] || [];
            let tmp = {};
            tmp['$or'] = [{}, {}];
            if ('incountries' == key) {
              tmp['$or'][0]['$or'] = [{}, {}]
              tmp['$or'][0]['$or'][0]['excludecountries'] = true;
              tmp['$or'][0]['$or'][0][key] = { $ne: record[filterField[2]]._id || record[filterField[2]] };
              tmp['$or'][0]['$or'][1]['excludecountries'] = { $ne: true };
              tmp['$or'][0]['$or'][1][key] = record[filterField[2]]._id || record[filterField[2]];
            }
            else tmp['$or'][0][key] = record[filterField[2]]._id || record[filterField[2]];
            tmp['$or'][1]['$or'] = [{}, {}];
            tmp['$or'][1]['$or'][0][key] = { $eq: [] };
            tmp['$or'][1]['$or'][1][key] = { $exists: false };
            field.filters['$and'].push(tmp)
          } else {
            field.filters[key] = record[filterField[2]]._id || record[filterField[2]];
          }
        } else delete field.filters[key];


        if (record[filterField[2]])
          if (filterField[3] && record[filterField[2]][filterField[3]]) {
            if (['forlocations', 'incountries', 'forpricegroups'].includes(key)) {
              delete field.filters[key];
              field.filters['$and'] = field.filters['$and'] || [];
              let tmp = {};
              tmp['$or'] = [{}, {}];
              tmp['$or'][0][key] = record[filterField[2]][filterField[3]]._id || record[filterField[2]][filterField[3]];
              tmp['$or'][1]['$or'] = [{}, {}];
              tmp['$or'][1]['$or'][0][key] = { $eq: [] };
              tmp['$or'][1]['$or'][1][key] = { $exists: false };
              field.filters['$and'].push(tmp)
            } else {
              field.filters[key] = record[filterField[2]][filterField[3]]._id || record[filterField[2]][filterField[3]];
            }
          } //else delete field.filters[key];


        // if (filterField[1] && record[filterField[1]] && !filterField[2]) {
        //   field.filters[key] = record[filterField[1]]._id || record[filterField[1]];
        // } else delete field.filters[key];

      } else {
        if (Array.isArray(value)) field.filters[key] = { $in: value };
        else field.filters[key] = value;
      }
    });
    if (field._id == 'location' && store.state.user.location) {
      field.filters._id = field.filters._id || { $in: [] }
      field.filters._id['$in'].push(store.state.user.location)
      if (store.state.user.forlocations && store.state.user.forlocations.length)
        field.filters._id['$in'].push(...store.state.user.forlocations)
    }
    if (field._id == 'company' && store.state.user.company) {
      field.filters._id = field.filters._id || { $in: [] }
      field.filters._id['$in'].push(store.state.user.company)
      if (store.state.user.forcompanies && store.state.user.forcompanies.length)
        field.filters._id['$in'].push(...store.state.user.forcompanies)
    }
    if (field._id == 'source' && store.state.user.source && store.state.user.source != '3cerp') {
      field.filters._id = field.filters._id || { $in: [] }
      field.filters._id['$in'].push(store.state.user.source)
      if (store.state.user.forsources && store.state.user.forsources.length)
        field.filters._id['$in'].push(...store.state.user.forsources)
    }
  }
  // if (!record[field._id] && field.setup.default) {
  //   if (field.setup.default.indexOf("this.") >= 0) {
  //     let defaultValue = field.setup.default.split(".");
  //     if (defaultValue[2] && record[defaultValue[2]])
  //       field.default = record[defaultValue[2]];

  //     if (defaultValue[3] && record[defaultValue[2]]) {
  //       if (field.setup.collection) {
  //         field.default = {
  //           _id: record[defaultValue[2]][defaultValue[3]]
  //         };
  //       } else {
  //         field.default = record[defaultValue[2]][defaultValue[3]];
  //       }
  //     }
  //   } else {
  //     field.default = field.setup.default;
  //   }
  // }
  //console.log(field._id, field.filters);
  return field;
};

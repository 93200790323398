import service from "../../api/index";
/* eslint-disable */
export default async (record, rid, trigger, collection, step = 0) => {

  //if (!rid) delete record._id;

  let data = {
    action: "saveRecord",
    rid: rid,
    record: record,
    trigger: trigger,
    step: step
  };
  try {
    let resposne = await service(record.collection || collection, data);
    return resposne;
  } catch (error) {
    console.log(error);
    throw error;

  }

};

<template>
  <v-breadcrumbs :items="items" class="breadcrumbs" divider="/">
    <template slot="item" slot-scope="props">
      <router-link :to="{ name: props.item.name }" class="link">
        {{
        props.item.name
        }}
      </router-link>
    </template>
  </v-breadcrumbs>
</template>

<script>
/* eslint-disable */
export default {
  data() {
    return {
      items: [],
    };
  },

  watch: {
    $route() {
      this.updateBreadcrumbs();
    },
  },

  mounted() {
    this.$route.meta.breadcrumb.push({ name: this.$route.params.recordtype });
    this.updateBreadcrumbs();
  },
  methods: {
    updateBreadcrumbs() {
      this.items = this.$route.meta.breadcrumb;
    },
  },
};
</script>

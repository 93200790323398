var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{attrs:{"error":_vm.required && (!_vm.recordSelected || (_vm.multiple && !_vm.recordSelected.length)),"items":_vm.records,"item-text":_vm.displayfield,"item-value":"_id","label":_vm.label,"placeholder":"","multiple":_vm.multiple,"rules":[_vm.rules.required],"return-object":_vm.recordObj,"readonly":(_vm.$route.params.mode == 'view' ||
      this.formSettings.disabled ||
      this.fieldSettings.disabled == true ||
      _vm.record.archived ||
      _vm.loading) &&
    !this.formSettings.show,"disabled":(this.formSettings.disabled ||
      this.fieldSettings.disabled == true ||
      _vm.disabled) &&
    !this.formSettings.show,"persistent-hint":"","hint":_vm.hint,"outlined":"","clearable":_vm.$route.params.mode == 'view' || _vm.record.archived ? false : true,"loading":_vm.loading,"dense":"","prepend-icon":_vm.$route.params.mode == 'view' || !this.fieldSettings.setup.add
      ? ''
      : 'add_box',"autocomplete":"new-password"},on:{"focus":function($event){_vm.active = true},"blur":function($event){_vm.active = false},"change":function($event){_vm.active = false},"click:prepend":_vm.add},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [(
        _vm.formSettings &&
        _vm.fieldSettings &&
        (_vm.formSettings.help || _vm.fieldSettings.setup.help)
      )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v("mdi-help-circle-outline")])]}}],null,false,1069809913)},[_c('pre',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.formSettings.help || _vm.fieldSettings.setup.help))])]):_vm._e()]},proxy:true}]),model:{value:(_vm.recordSelected),callback:function ($$v) {_vm.recordSelected=$$v},expression:"recordSelected"}})}
var staticRenderFns = []

export { render, staticRenderFns }
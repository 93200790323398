var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","search":_vm.search,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter,"id":"openordersstatus"},scopedSlots:_vm._u([{key:"item.salesorder",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.salesorder.collection) + "/" + (item.salesorder.recordtype) + "/view/" + (item.salesorder._id)),
            }}},[_vm._v(_vm._s(item.salesorder.displayname || item.salesorder.name))])]}},{key:"item.customer",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.customer.collection) + "/" + (item.customer.recordtype) + "/view/" + (item.customer._id)),
            }}},[_vm._v(_vm._s(item.customer.displayname || item.customer.name))])]}},{key:"item.item",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.item.collection) + "/" + (item.item.recordtype) + "/view/" + (item.item._id)),
            }}},[_vm._v(_vm._s(item.item.displayname || item.item.name))])]}},{key:"item.sendtogether",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.sendtogether ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.sendtogether ? "Yes" : "No"))])]}},{key:"item.fleetorder",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.fleetorder ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.fleetorder ? "Yes" : "No"))])]}},{key:"item.dropship",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.dropship ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.dropship ? "Yes" : "No"))])]}},{key:"item.quantitycommitted",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.quantitycommitted ? 'green' : 'none',"dark":""}},[_vm._v(_vm._s(item.quantitycommitted))])]}},{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('exportData',{attrs:{"headers":_vm.headers,"items":_vm.items}})],1),_c('v-col',[_c('v-btn',{staticClass:"mt-2 mb-2",attrs:{"block":"","tile":"","color":"primary"},on:{"click":function($event){return _vm.getData()}}},[_vm._v("Refresh")])],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('v-icon',[_vm._v(_vm._s(_vm.show ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1)],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}]},[_c('v-divider'),_c('v-row',[_c('v-col',[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Committed Amount:")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.committedamount))])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Backorder Amount:")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.backorderamount))])],1)],1)],1)],1),_c('v-col',[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Committed Weight:")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.committedweight)+" kg")])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Backorder Weight:")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.backorderweight)+" kg")])],1)],1)],1)],1)],1)],1)])],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
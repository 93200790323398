<template>
  <v-form v-model="valid" lazy-validation ref="form">
    <v-toolbar dark color="primary">
      <v-toolbar-title>Forms Creator</v-toolbar-title>
    </v-toolbar>
    <v-progress-linear :indeterminate="loading" height="3" class="ma-0"></v-progress-linear>

    <setupa v-if="!loading" :form="form" class="mb-1"/>
    <tabs v-if="!loading" :form="form"/>

    <v-container fluid grid-list-xl>
      <v-layout row wrap align-center>
        <v-flex md6>
          <v-btn
            v-if="$route.params.id"
            @click="saveRecord(rid);"
            :disabled="!valid"
            color="primary"
          >Save</v-btn>
          <v-btn @click="saveRecord();" :disabled="!valid" color="primary">Save As</v-btn>
        </v-flex>

        <v-flex md6>
          <v-btn v-if="$route.params.id" @click="deleteRecord(rid);" color="primary">Delete</v-btn>
        </v-flex>
      </v-layout>
    </v-container>

    <v-snackbar v-model="msg.show" :timeout="6000" top absolute right>{{ msg.message }}</v-snackbar>
  </v-form>
</template>

<script>

import tabs from './tabs';
import setupa from './setup';
import saveRecord from '../../../common/methods/saveRecord';
import deleteRecord from '../../../common/methods/deleteRecord';
import getRecord from '../../../common/methods/getRecord';
/* eslint-disable */
export default {
  data() {
    return {
      valid: false,
      loading: true,
      msg: false,
      e1: 1,
      steps: 2,
      mode: this.$route.params.mode,
      rid: this.$route.params.id,
      form: {
        tabs: [
          {
            name: 'Tab Name',
            table: { query: '', filters: {} },
            sections: [{ name: 'Section Label', fields: [] }],
          },
        ],
        setup: { type: '' },
      },
    };
  },
  components: {
    tabs,
    setupa,
  },
  methods: {
    saveRecord(rid) {
      saveRecord(this, this.form, 'forms', 'form', rid);
    },
    deleteRecord(rid) {
      deleteRecord(this, rid, 'forms', 'form');
    },
  },
  watch: {
    '$route.params': {
      handler: async function(after, before) {
        if (this.$route.params.mode == 'view') {
          this.$router.push({
            path: `/${this.form.setup.collection}/${this.form.setup.recordtype}/create`,
          });
        }
      },
      deep: true,
    },
  },
  async created() {
    try {
      this.form = await getRecord(this, this.rid, 'forms', 'form');
      if (this.$route.params.mode == 'view') {
        this.$router.push({
          path: `/${this.form.setup.collection}/${this.form.setup.recordtype}/create`,
        });
      }
      this.loading = false;
    } catch (error) {
      this.msg = { message: `${error.message}`, show: true };
    }
  },
};
</script>

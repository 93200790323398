 <template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          loading-text="Loading... Please wait"
          :loading="loading"
          item-key="_id"
          v-model="selected"
          show-select
          :search="search"
          :single-select="singleSelect"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
        >
          <template v-slot:item.date="{ item }">
            <span>{{ new Date(item.date).toISOString().substr(0, 10) }}</span>
          </template>
          <template v-slot:item.transaction="{ item }">
            <router-link
              :to="{
                path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
              }"
              class="link"
              >{{
                item.transaction.displayname || item.transaction.name
              }}</router-link
            >
          </template>
          <template v-slot:item.customer="{ item }">
            <router-link
              :to="{
                path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
              }"
              class="link"
              >{{
                item.customer.displayname || item.customer.name
              }}</router-link
            >
          </template>
          <template v-slot:item.deliverymethod="{ item }">
            <span>{{ item.deliverymethod }}</span>
          </template>

          <template v-slot:item.correlated="{ item }">
            <router-link
              v-for="po in item.correlated"
              :key="po._id"
              :to="{
                path: `/${po.collection}/${po.recordtype}/view/${po._id}`,
              }"
              class="link"
              >{{ po.displayname || po.name }}</router-link
            >
          </template>
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                  <v-btn @click="action" block tile color="primary"
                    >Purchase Order</v-btn
                  >
                </v-col>
                <v-col class="text-right">
                  <v-btn icon @click="show = !show">
                    <v-icon>{{
                      show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-expand-transition>
                <div v-show="show">
                  <v-divider></v-divider>
                  <v-row>
                    <v-col>
                      <v-select
                        :items="vendors"
                        v-model="vendor"
                        label="Vendor"
                        item-text="displayname"
                        item-value="_id"
                        return-object
                        outlined
                        dense
                      ></v-select>
                      <v-select
                        :items="deliverymethods"
                        v-model="deliverymethod"
                        label="Delivery Method"
                        item-text="displayname"
                        item-value="_id"
                        return-object
                        outlined
                        dense
                      ></v-select>
                    </v-col>

                    <v-col>
                      <v-textarea
                        outlined
                        label="Message"
                        v-model="message"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </div>
              </v-expand-transition>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
export default {
  data() {
    return {
      loading: false,
      headers: [
        { text: "Location", value: "location", sortable: false },
        { text: "Date", value: "date", sortable: false },
        { text: "Sales Order", value: "transaction", sortable: false },
        { text: "Customer", value: "customer", sortable: false },
        { text: "Delivery Method", value: "deliverymethod", sortable: false },
        { text: "Quantity", value: "quantity", sortable: false },
        { text: "Ordered", value: "quantityonorder", sortable: false },
        { text: "Memo", value: "memo", sortable: false },
        { text: "Created PO", value: "correlated", sortable: false },
      ],
      items: [],
      rows: [100],
      singleSelect: false,
      selected: [],
      search: "",
      show: false,
      vendor: null,
      vendors: [],
      message: "",
      countries: [],
      address: {},
      deliverymethod: null,
      deliverymethods: [],
    };
  },
  async created() {
    this.loading = true;
    await this.getData();
    let so = [];
    this.items.forEach((line) => {
      so.push(line._id);
    });
    let items = await service("transactions_lines", {
      action: "find",
      data: [{ transaction: { $in: so } }],
    }, false, true).then((response) => {
      let ids = [];
      response.forEach((v) => {
        ids.push(v.item);
      });
      return ids;
    });
    let vendors = await service("items_vendor", {
      action: "find",
      data: [{ item: { $in: items } }],
    }, false, true).then((response) => {
      let ids = [];
      response.forEach((v) => {
        ids.push(v.customer);
      });
      return ids;
    });

    this.vendors = await service("users", {
      action: "find",
      data: [{ _id: { $in: vendors } }],
    }, false, true).then((response) => {
      response.forEach((v) => {
        v.displayname = v.displayname || v.name;
      });
      return response;
    });

    this.deliverymethods = await service("lists", {
      action: "find",
      data: [{ recordtype: "deliverymethod" }],
    }, false, true).then((response) => {
      response.forEach((v) => {
        v.displayname = v.displayname || v.name;
      });
      return response;
    });

    this.countries = await service("countries", {
      action: "find",
      data: [{}],
    }, false, true).then((response) => {
      response.forEach((v) => {
        v.displayname = v.displayname || v.name;
      });
      return response;
    });
    this.loading = false;
  },

  methods: {
    async getData() {
      let result = await service(
        "orders",
        {
          action: "getFleetOrders",
        },
        false,
        true
      );
      this.items = result.items;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },

    action() {
      let location = null;
      let salesorder = null;
      let error = false;
      let createdfrom = [];
      this.selected.forEach((select) => {
        if (location && select.location._id != location) {
          alert("You cannot select more locations");
          error = true;
        }
        location = select.location._id;
        if (salesorder && select.transaction._id != salesorder) {
          alert("You cannot select more Sales Oreder");
          error = true;
        }
        salesorder = select.transaction._id;
        createdfrom.push(select._id);
      });
      if (!error)
        this.$router.push({
          name: "Transactions",
          params: {
            recordtype: "purchaseorder",
            mode: "create",
            createdfrom: createdfrom,
            location: location,
            message: this.message,
            customer: this.vendor,
            shipaddressee: this.address.shipaddressee,
            shipaddress: this.address.shipaddress,
            shipcity: this.address.shipcity,
            shipzip: this.address.shipzip,
            shipcountry: this.address.shipcountry,
            shipphone: this.address.shipphone,
            deliverymethod: this.deliverymethod,
          },
        });
    },
  },
};
</script>
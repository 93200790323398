var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.query.setup.collection)?_c('v-data-table',{attrs:{"headers":_vm.advanced ? _vm.advancedheaders : _vm.headers,"items":_vm.query.setup.columns,"loading-text":"Loading... Please wait","hide-default-footer":"","item-key":"index","footer-props":{ itemsPerPageOptions: _vm.rows },"item-class":_vm.rowClass},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"mt-4 mb-2",attrs:{"label":"Label","outlined":"","hide-details":"","dense":"","rules":[_vm.rules.required],"clearable":""},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}),_c('v-select',{attrs:{"items":_vm.sorts,"label":"Sort","item-text":"name","item-value":"_id","outlined":"","dense":"","clearable":""},model:{value:(item.sort),callback:function ($$v) {_vm.$set(item, "sort", $$v)},expression:"item.sort"}})]}},{key:"item.field",fn:function(ref){
var item = ref.item;
return [_c('v-autocomplete',{staticClass:"mt-4 mb-2",attrs:{"items":_vm.getFilteredFields(
          item.sourcesubrecord || item.source || item.subrecord
        ),"label":"Field","item-text":"name","item-value":"_id","outlined":"","dense":"","hide-details":"","rules":[_vm.rules.required],"clearable":""},model:{value:(item.field),callback:function ($$v) {_vm.$set(item, "field", $$v)},expression:"item.field"}}),(item.field && item.field.indexOf('formula') == 0)?_c('v-textarea',{attrs:{"label":"Formula","outlined":"","hide-details":"","dense":"","clearable":""},model:{value:(item.formula),callback:function ($$v) {_vm.$set(item, "formula", $$v)},expression:"item.formula"}}):_vm._e(),_c('v-switch',{staticClass:"mt-0 mb-0",attrs:{"label":"Hidden","outlined":"","hide-details":"","dense":"","clearable":""},model:{value:(item.hidden),callback:function ($$v) {_vm.$set(item, "hidden", $$v)},expression:"item.hidden"}})]}},{key:"item.subrecord",fn:function(ref){
        var item = ref.item;
return [_c('v-autocomplete',{staticClass:"mt-4",attrs:{"items":_vm.getFilteredSubrecords(),"label":"Subrecords / Lists","item-text":"name","item-value":"_id","outlined":"","dense":"","clearable":""},model:{value:(item.subrecord),callback:function ($$v) {_vm.$set(item, "subrecord", $$v)},expression:"item.subrecord"}})]}},{key:"item.source",fn:function(ref){
        var item = ref.item;
return [_c('v-autocomplete',{staticClass:"mt-4 mb-2",attrs:{"items":_vm.getFilteredFields(item.subrecord, true),"label":"Source Field","item-text":"name","item-value":"_id","hide-details":"","outlined":"","dense":"","clearable":""},model:{value:(item.source),callback:function ($$v) {_vm.$set(item, "source", $$v)},expression:"item.source"}}),_c('v-autocomplete',{attrs:{"items":_vm.getFilteredSubrecords(item.source),"label":"Subrecords / Lists","item-text":"name","item-value":"_id","outlined":"","dense":"","disabled":!item.source,"clearable":""},model:{value:(item.sourcesubrecord),callback:function ($$v) {_vm.$set(item, "sourcesubrecord", $$v)},expression:"item.sourcesubrecord"}})]}},{key:"item.function",fn:function(ref){
        var item = ref.item;
return [_c('v-select',{staticClass:"mt-4 mb-2",attrs:{"items":_vm.functions,"label":"Function","item-text":"name","item-value":"_id","outlined":"","hide-details":"","dense":"","clearable":""},model:{value:(item.function),callback:function ($$v) {_vm.$set(item, "function", $$v)},expression:"item.function"}}),_c('v-select',{attrs:{"items":_vm.summaries,"label":"Summary","item-text":"name","item-value":"_id","outlined":"","dense":"","clearable":""},model:{value:(item.summary),callback:function ($$v) {_vm.$set(item, "summary", $$v)},expression:"item.summary"}})]}},{key:"item.hidden",fn:function(ref){
        var item = ref.item;
return [(item.field && _vm.fieldsObj[item.field].setup.array)?_c('v-switch',{staticClass:"mt-0 mb-2",attrs:{"label":"Group Array","outlined":"","dense":""},model:{value:(item.group),callback:function ($$v) {_vm.$set(item, "group", $$v)},expression:"item.group"}}):_vm._e(),_c('br'),(item.field && item.field.indexOf('formula') == 0)?_c('v-switch',{staticClass:"mt-0 mb-2",attrs:{"label":"Execute after summary","outlined":"","dense":""},model:{value:(item.aftersummary),callback:function ($$v) {_vm.$set(item, "aftersummary", $$v)},expression:"item.aftersummary"}}):_vm._e()]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"block":"","icon":"","tile":"","color":"primary"},on:{"click":function($event){return _vm.upColumn(item)}}},[_c('v-icon',[_vm._v("mdi-arrow-up-bold")])],1),_c('v-btn',{attrs:{"block":"","icon":"","tile":""},on:{"click":function($event){return _vm.delColumn(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-btn',{attrs:{"block":"","icon":"","tile":"","color":"primary"},on:{"click":function($event){return _vm.downColumn(item)}}},[_c('v-icon',[_vm._v("mdi-arrow-down-bold")])],1)]}},{key:"footer",fn:function(){return [_c('v-container',{staticStyle:{"position":"relative"}},[_c('v-row',[_c('v-col',[_c('v-fab-transition',[_c('v-btn',{staticStyle:{"margin-left":"-20px"},attrs:{"color":"primary","dark":"","absolute":"","top":"","small":"","left":"","fab":""},on:{"click":_vm.addColumn}},[_c('v-icon',[_vm._v("add")])],1)],1)],1),_c('v-switch',{attrs:{"label":"Advanced"},model:{value:(_vm.advanced),callback:function ($$v) {_vm.advanced=$$v},expression:"advanced"}})],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.groupby,"label":"Group By","item-text":"name","item-value":"_id","outlined":"","dense":"","clearable":""},model:{value:(_vm.query.setup.groupby),callback:function ($$v) {_vm.$set(_vm.query.setup, "groupby", $$v)},expression:"query.setup.groupby"}})],1),_c('v-col',[_c('v-switch',{staticClass:"my-0",attrs:{"label":"Collapse All"},model:{value:(_vm.query.setup.collapse),callback:function ($$v) {_vm.$set(_vm.query.setup, "collapse", $$v)},expression:"query.setup.collapse"}})],1)],1)],1)]},proxy:true}],null,false,2267791388)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
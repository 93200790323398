 <template>
  <v-container fluid>
    <v-row v-if="!loading">
      <v-col>
        <v-select
          :items="locations"
          v-model="location"
          label="Location"
          item-text="name"
          item-value="_id"
          outlined
          clearable
          multiple
          hide-details
          class="mt-2 mb-0"
          dense
        ></v-select>
        <v-switch
          v-model="intercompany"
          label="Inter-Company Transactions"
        ></v-switch>
      </v-col>
      <v-col>
        <v-menu
          v-model="menufrom"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Date from"
              placeholder
              v-model="datefrom"
              append-icon="event"
              readonly
              v-on="on"
              outlined
              dense
              hide-details
              clearable
              class="mt-2 mb-2"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="datefrom"
            :first-day-of-week="1"
          ></v-date-picker>
        </v-menu>
        <v-menu
          v-model="menuto"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Date to"
              placeholder
              v-model="dateto"
              append-icon="event"
              readonly
              v-on="on"
              outlined
              dense
              hide-details
              clearable
              class="mt-2 mb-2"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateto"
            :first-day-of-week="1"
          ></v-date-picker>
        </v-menu>
        <v-select
          :items="periods"
          v-model="period"
          label="Periods"
          item-text="name"
          item-value="_id"
          class="mt-2 mb-2"
          outlined
          clearable
          dense
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          :items="modes"
          v-model="mode"
          label="Mode"
          item-text="name"
          item-value="_id"
          class="mt-2 mb-2"
          outlined
          clearable
          dense
        ></v-select>
      </v-col>
      <v-col>
        <v-btn @click="getData()" block tile color="primary" class="mt-2 mb-2"
          >Refresh</v-btn
        >
      </v-col>
      <v-col class="text-right" v-if="false">
        <v-btn icon @click="show = !show">
          <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-progress-linear indeterminate v-else></v-progress-linear>
    <v-row>
      <v-col class="ma-0 pa-0">
        <v-expand-transition>
          <div v-show="show">
            <v-divider></v-divider>
            <v-tabs dark v-model="active">
              <v-tab :key="1">Sales Backordered</v-tab>
              <v-tab-item :key="1">
                <queryresult
                  used="item"
                  queryid="603de82294e10a2a5cff7657"
                  :filters="filters"
                  v-if="active == 0"
                />
              </v-tab-item>
            </v-tabs>
          </div>
        </v-expand-transition>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <div v-if="!loading" ref="reportview" id="printarea" fluid>
      <v-row>
        <v-col md="8">
          <v-subheader>Overivew</v-subheader>
          <v-data-table
            :headers="headers2"
            :items="items.sales"
            :loading="loading"
            loading-text="Loading... Please wait"
            item-key="name"
            hide-default-footer
          >
            <template v-slot:item.amount="{ item }">
              <span>
                {{
                  new Intl.NumberFormat(currency ? "en-EN" : "pl-PL", {
                    style: "currency",
                    currency: currency || "PLN",
                  }).format(item.amount)
                }}
              </span>
            </template>
            <template v-slot:item.compareamount="{ item }">
              <span>
                {{
                  new Intl.NumberFormat(currency ? "en-EN" : "pl-PL", {
                    style: "currency",
                    currency: currency || "PLN",
                  }).format(item.compareamount)
                }}
              </span>
            </template>
            <template v-slot:item.procquantity="{ item }">
              <v-chip
                label
                v-if="item.comparequantity"
                :color="
                  (item.quantity / item.comparequantity) * 100 - 100 < 0
                    ? 'red'
                    : 'green'
                "
                dark
                >{{
                  Math.round((item.quantity / item.comparequantity) * 100) -
                  100
                }}%</v-chip
              >
            </template>
            <template v-slot:item.procamount="{ item }">
              <v-chip
                label
                v-if="item.compareamount"
                :color="
                  (item.amount / item.compareamount) * 100 - 100 < 0
                    ? 'red'
                    : 'green'
                "
                dark
                >{{
                  Math.round((item.amount / item.compareamount) * 100) - 100
                }}%</v-chip
              >
            </template>
          </v-data-table>
        </v-col>
        <v-col md="4">
          <v-subheader>Top Customers</v-subheader>
          <v-data-table
            :headers="headers"
            :items="items.customers"
            :loading="loading"
            loading-text="Loading... Please wait"
            item-key="_id"
            :search="search"
            :footer-props="{ itemsPerPageOptions: rows }"
            :custom-filter="filter"
          ></v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="items.bysalesrep">
          <v-subheader>{{ items.bysalesrep.title }}</v-subheader>
          <doughnutchart
            :chartData="toChartData('doughnut', items.bysalesrep)"
          />
        </v-col>
        <v-col v-if="items.bycountry">
          <v-subheader>{{ items.bycountry.title }}</v-subheader>
          <doughnutchart
            :chartData="toChartData('doughnut', items.bycountry)"
          />
        </v-col>
        <v-col v-if="items.bycustomer">
          <v-subheader>{{ items.bycustomer.title }}</v-subheader>
          <doughnutchart
            :chartData="toChartData('doughnut', items.bycustomer)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="items.bytransactiontype">
          <v-subheader>{{ items.bytransactiontype.title }}</v-subheader>
          <barchart :chartData="toChartData('bar', items.bytransactiontype)" />
        </v-col>
        <v-col v-if="items.bycustomer">
          <v-subheader>{{ items.bycustomer.title }}</v-subheader>
          <barchart
            :chartData="toChartData('bar', items.bycustomer)"
            :option="stacked"
          />
        </v-col>
      </v-row>
      <v-row v-if="mode == 'sales'">
        <v-col v-if="items.byordered">
          <v-subheader>{{ items.byordered.title }}</v-subheader>
          <barchart :chartData="toChartData('bar', items.byordered)" />
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="items.bysalesrep">
          <v-subheader>{{ items.bysalesrep.title }}</v-subheader>
          <barchart
            :chartData="toChartData('bar', items.bysalesrep)"
            :option="stacked"
          />
        </v-col>

        <v-col v-if="items.bycountry">
          <v-subheader>{{ items.bycountry.title }}</v-subheader>
          <barchart
            :chartData="toChartData('bar', items.bycountry)"
            :option="stacked"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import piechart from "../../charts/pie";
import barchart from "../../charts/bar";
import linechart from "../../charts/line";
import colors from "../../charts/colors";
import doughnutchart from "../../charts/doughnut";

import filter from "../../dataTable/filter";
export default {
  props: ["item"],
  data() {
    return {
      loading: true,
      datefrom: new Date(new Date().getFullYear() - 1, 0, 2)
        .toISOString()
        .substr(0, 10),
      dateto: null,
      search: null,
      intercompany: false,
      items: {},
      initdate: null,
      data: null,
      rows: [5],
      location: [],
      locations: [],
      currency: false,
      show: false,
      filters: { item: this.item },
      mode: "sales",
      modes: [
        { name: "Sales Report", _id: "sales" },
        { name: "Orders Report", _id: "orders" },
        { name: "Purchase Report", _id: "purchase" },
      ],
      period: "quarterly",
      periods: [
        { name: "Weekly", _id: "weekly" },
        { name: "Monthly", _id: "monthly" },
        { name: "Quarterly", _id: "quarterly" },
        { name: "Yearly", _id: "yearly" },
      ],
      headers: [
        { text: "Customer", value: "customer" },
        { text: "Sold", value: "quantity" },
        { text: "Last Sold", value: "date" },
      ],
      headers2: [
        { text: "Period", value: "name" },
        { text: "Sold", value: "quantity" },
        { text: "Sold", value: "comparequantity" },
        { text: "%", value: "procquantity" },
        { text: "Amount", value: "amount" },
        { text: "Amount", value: "compareamount" },
        { text: "%", value: "procamount" },
      ],
      menufrom: false,
      menuto: false,
      stacked: {
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
      },
      locationsFilter: [],
    };
  },
  async created() {
    this.loading = true;
    if (this.$store.state.user.location)
      this.locationsFilter.push(this.$store.state.user.location);
    if (
      this.$store.state.user.forlocations &&
      this.$store.state.user.forlocations.length
    )
      this.locationsFilter.push(...this.$store.state.user.forlocations);
    this.locations = await service("accounting", {
      action: "find",
      data: [
        {
          recordtype: "location",
          _id: this.locationsFilter.length
            ? { $in: this.locationsFilter }
            : { $exists: true },
        },
      ],
    });
    this.location = this.locationsFilter;
    await this.getData();
    this.loading = false;
  },
  watch: {
    async period() {
      await this.getData();
    },
    async location() {
      await this.getData();
    },
    async mode() {
      await this.getData();
    },
  },
  methods: {
    toChartData(type, data) {
      let chartData = JSON.parse(JSON.stringify(data));
      if (type == "pie" || type == "doughnut") {
        chartData.datasets = chartData.datasets2;
        chartData.labels = chartData.labels2;
        chartData.datasets.map((data) => {
          data.backgroundColor = colors;
        });
      } else {
        chartData.datasets.map((data, index) => {
          data.backgroundColor = colors[index];
        });
      }
      return chartData;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    async getData() {
      this.loading = true;
      let data = await service(
        "report",
        {
          action: "getStatistics",
          location: this.location,
          datefrom: this.datefrom,
          dateto: this.dateto,
          item: this.item,
          period: this.period,
          mode: this.mode,
          intercompany: this.intercompany,
        },
        false,
        true
      );
      //this.data = data.data;
      this.items = data.items;
      this.loading = false;
    },
  },
  components: { piechart, barchart, linechart, doughnutchart },
};
</script>
<style scoped>
#printarea {
  background-color: #ffffff !important;
}
#printarea .row {
  page-break-inside: avoid;
}
</style>
 <template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dialog" fullscreen hide-overlay v-if="!overlay">
      <v-card tile>
        <v-toolbar flat dark>
          <v-icon @click="close">mdi-arrow-left</v-icon>
          <v-toolbar-title>Message</v-toolbar-title>
          <div class="flex-grow-1"></div>
          <v-icon>mdi-send</v-icon>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-container>
              <v-text-field
                v-model="email.from"
                label="From"
                outlined
                readonly
                dense
              ></v-text-field>
              <v-row
                ><v-col>
                  <v-combobox
                    v-model="email.to"
                    label="To"
                    multiple
                    outlined
                    readonly
                    dense
                  ></v-combobox> </v-col
                ><v-col
                  ><v-text-field
                    v-model="email.cc"
                    label="CC"
                    outlined
                    readonly
                    dense
                  ></v-text-field></v-col
              ></v-row>
              <v-text-field
                v-model="email.subject"
                label="Subject"
                outlined
                readonly
                dense
              ></v-text-field>
              <!-- <v-text-field v-model="email.attachments" :items="email.attachments" label="Attachments"  outlined readonly dense></v-text-field> -->

              <div class="mt-2" v-html="email.text"></div>
              <v-chip
                label
                v-for="(attachement, index) of email.attachments"
                :key="index"
                @click="
                  pdf(email._id, attachement.filename, index, attachement)
                "
              >
                <v-icon left> mdi-attachment</v-icon>
                {{ attachement.filename }}
              </v-chip>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <!-- <v-btn @click="test">test</v-btn> -->
          <div class="flex-grow-1"></div>
          <writeemail
            :email="email"
            :record="record"
            label="Reply"
            v-if="record"
          ></writeemail>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable */
import { getEmail } from "../../common/methods/email";
import writeemail from "./write";
export default {
  props: ["uid", "box", "emailaddress", "record"],
  data() {
    return {
      overlay: false,
      dialog: false,
      lastuid: null,
      email: {
        subject: "",
        to: [],
        date: "",
        from: "",
      },
    };
  },
  watch: {
    uid: async function (val) {
      if (val) {
        this.dialog = true;
        this.overlay = true;
        if (val != this.lastuid)
          this.email = await getEmail(this, this.emailaddress, val, this.box);
        this.overlay = false;
        this.lastuid = val;
      }
      console.log(this.email);
    },
    dialog: async function (val) {
      this.$emit("emit", { reademail: false });
      console.log("dialog", val);
    },
  },
  created() {
    console.log(this.email);
  },
  methods: {
    test() {
      let pdfAsDataUri =
        "data:application/pdf;base64," + this.email.attachments[0].data.buffer;
      window.open(pdfAsDataUri);
    },
    close() {
      this.dialog = false;
    },
    send() {
      alert("Send Email");
    },
    pdf(email, filename, index, content) {
      if (content.contentType) {
        var fileURL = URL.createObjectURL(
          new Blob([content.content.buffer], { type: content.contentType })
        );
        console.log(fileURL);
        window.open(fileURL);
      } else {
        let pdf = `http://localhost:8080/api/attachments/${email}/${index}/${filename
          .replaceAll("#", "%23")
          .replaceAll("/", "%2F")}`;
        window.open(pdf, "_blank");
      }
    },
  },
  components: {
    writeemail: writeemail,
  },
};
</script>
<style  scoped>
.v-dialog__container {
  display: unset;
}
</style>
<template>
  <v-card tile>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Additional Tools (BETA)</v-toolbar-title>
    </v-toolbar>
    <v-tabs dark v-model="active">
      <v-tab :key="1">Transport claims (BETA)</v-tab>
      <v-tab :key="2">Distributors by country (BETA)</v-tab>
      <v-tab :key="3">Shipping Methods (BETA)</v-tab>
      <v-tab :key="4">Stock Clearance (BETA)</v-tab>
      <v-tab :key="5">ETA Requests (BETA)</v-tab>
      <v-tab :key="9"><v-icon>mdi-close-outline</v-icon></v-tab>

      <v-tab-item :key="1">
        <queryresult
          used="widget"
          queryid="transportclaim"
          v-if="active == 0"
        />
      </v-tab-item>
      <v-tab-item :key="2">
        <queryresult
          used="widget"
          queryid="64e35c4b082c781e68905686"
          v-if="active == 1"
        />
      </v-tab-item>
      <v-tab-item :key="3">
        <shippingMethods v-if="active == 2" />
      </v-tab-item>
      <v-tab-item :key="4">
        <stockClearance v-if="active == 3" />
      </v-tab-item>
      <v-tab-item :key="5">
        <borequests v-if="active == 4" />
      </v-tab-item>
      <!-- <v-tab-item :key="8">
          <statistics v-if="active==7" />
        </v-tab-item>-->
    </v-tabs>
  </v-card>
</template>
  
  <script>
import shippingMethods from "../components/tools/shippingMethods/View";
import stockClearance from "../components/tools/stockclearance/View";
import borequests from "../components/tools/requests/View";
/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
      active: 5,
    };
  },
  components: {
    shippingMethods,
    stockClearance,
    borequests
  },
};
</script>
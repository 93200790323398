<template>
  <v-switch
    class="pa-0"
    inset
    v-model="switchValue"
    :rules="[rules.required]"
    :readonly="$route.params.mode == 'view' && !this.formSettings.show"
    :disabled="
      ($route.params.mode == 'view' ||
        this.formSettings.disabled ||
        disabled ||
        this.fieldSettings.disabled == true) &&
      !this.formSettings.show
    "
    dense
  >
    <template v-slot:label>
      {{ label }}
      {{ hint }}
      <v-tooltip
        bottom
        v-if="
          formSettings &&
          fieldSettings &&
          (formSettings.help || fieldSettings.setup.help)
        "
      >
        <template v-slot:activator="{ on }">
          <v-icon small right v-on="on">mdi-help-circle-outline</v-icon>
        </template>
        <pre class="text-caption">
 {{ formSettings.help || fieldSettings.setup.help }}</pre
        >
      </v-tooltip>
    </template>
  </v-switch>
</template>
<script>
import fieldCalculated from "../../common/methods/fieldCalculated";
/* eslint-disable */
export default {
  props: ["value", "record", "fieldSettings", "formSettings"],
  data() {
    return {
      switchValue: this.value || false,
      required:
        this.formSettings.required ||
        this.fieldSettings.setup.required ||
        false,
      field: this.formSettings.field || this.formSettings.setup.field,
      label: this.formSettings.name || this.fieldSettings.name,
      rules: {
        required: (value) => (this.required ? !!value || "Required." : true),
      },
      mode: this.$route.params.mode,
    };
  },
  computed: {
    hint() {
      if (this.field == "dropship") {
        if (this.record.dropshipavailable) return `(possible!)`;
      }
      return "";
    },
    disabled() {
      if (this.field == "dropship") {
        if (
          this.record.collection == "transactions" &&
          this.record.recordtype != "purchaseorder"
        )
          return true;
        if (
          this.record.recordtype != "purchaseorder" &&
          !(
            !this.record.status ||
            (this.record.status &&
              (this.record.status._id || this.record.status) ==
                "pendingapproval")
          )
        )
          return true;

        if (
          this.record.item &&
          (this.record.item.custombox || this.record.item.tobuild)
        )
          return true;

        if (this.record.dropshipavailable === false) return true;
      }
    },
  },
  created() {
    // if (this.formSettings.default) {
    //   this.fieldSettings.setup.default = this.formSettings.default
    //     ? this.formSettings.default
    //     : this.fieldSettings.setup.default;
    // }
    // if (this.formSettings.filters) {
    //   this.formSettings.default = this.formSettings.default
    //     ? this.formSettings.default
    //     : this.formSettings.default;
    // }
    let field = fieldCalculated(this.fieldSettings, this.record);
    if (
      this.record.created == "new" &&
      this.record[this.field] == undefined &&
      this.fieldSettings.setup
    )
      this.switchValue = this.fieldSettings.setup.default || false;
    // if (field.default && !this.text && this.mode == "create") {
    //   this.switchValue = field.default;
    //   this.record[field._id] = field.default;
    // }
  },
  watch: {
    switchValue(value) {
      this.$emit("emit", { field: this.field, value: value });
    },
    record: {
      handler: async function (after, before) {
        if (this.formSettings.show) this.field = "value";
        if (this.record[this.field] != null)
          this.switchValue = this.record[this.field];
        else this.switchValue = false;
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.v-input--dense {
  padding: 8px 0px;
  margin: 0;
}
</style>


 
  <template>
  <div>
    <googlemap
      :addresses.sync="selected.length ? selected : items"
      v-if="!loading"
      :key="selected.length"
    />
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          loading-text="Loading... Please wait"
          item-key="_id"
          v-model="selected"
          show-select
          :search="search"
          :single-select="singleSelect"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
          @current-items="getFiltered"
        >
          <template v-slot:item.user="{ item }">
            <router-link
              :to="{
                path: `/${item.user.collection}/${item.user.recordtype}/view/${item.user._id}`,
              }"
              class="link"
              >{{ item.user.displayname || item.user.name }}</router-link
            >
          </template>
          <template v-slot:item.name="{ item }">
            <router-link
              :to="{
                path: `/${item.name.collection}/${item.name.recordtype}/view/${item.name._id}`,
              }"
              class="link"
              >{{ item.name.displayname || item.name.name }}</router-link
            >
          </template>
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col>
                  <exportData :headers="headers" :items="items" />
                </v-col>
                <v-col>
                  <v-autocomplete
                    dense
                    v-model="country"
                    :items="countries"
                    label="Country"
                    outlined
                    item-text="name"
                    item-value="_id"
                    multiple
                    @keyup.enter="searchRecords"
                    hide-details
                    clearable
                    class="mb-2"
                  ></v-autocomplete>
                  <v-autocomplete
                    dense
                    v-model="salesrep"
                    :items="salesreps"
                    label="Sales Rep"
                    outlined
                    item-text="name"
                    item-value="_id"
                    multiple
                    @keyup.enter="searchRecords"
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <!-- <v-col>
                  <v-autocomplete
                    dense
                    v-model="category"
                    :items="categories"
                    label="Category"
                    outlined
                    item-text="name"
                    item-value="_id"
                    multiple
                    @keyup.enter="searchRecords"
                  ></v-autocomplete>
                </v-col> -->
                <v-col>
                  <v-menu
                    v-model="datapicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        label="Created After"
                        placeholder
                        v-model="createdafter"
                        append-icon="event"
                        readonly
                        v-on="on"
                        outlined
                        dense
                        hide-details
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="createdafter"></v-date-picker>
                  </v-menu>
                  <v-switch v-model="iscompany" label="Only B2B"></v-switch>
                </v-col>
                <v-col>
                  <v-menu
                    v-model="datapicker2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        label="Transactions datefrom"
                        placeholder
                        v-model="datefrom"
                        append-icon="event"
                        readonly
                        v-on="on"
                        outlined
                        dense
                        hide-details
                        clearable
                        class="mb-2"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="datefrom"></v-date-picker>
                  </v-menu>
                  <v-menu
                    v-model="datapicker3"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        label="Transactions date to"
                        placeholder
                        v-model="dateto"
                        append-icon="event"
                        readonly
                        v-on="on"
                        outlined
                        dense
                        hide-details
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="dateto"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-text-field
                    dense
                    type="number"
                    placeholder
                    v-model="minamt"
                    label="Min. Amount"
                    hide-details
                    outlined
                    class="mb-2"
                  ></v-text-field>
                  <v-text-field
                    dense
                    type="number"
                    placeholder
                    v-model="maxamt"
                    label="Max. Amount"
                    hide-details
                    outlined
                    class="mt-2 mb-2"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    dense
                    type="number"
                    placeholder
                    v-model="amount"
                    label="Highlight Amount"
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>

                <v-spacer></v-spacer>
                <v-col>
                  <v-btn
                    @click="getData()"
                    block
                    tile
                    color="primary"
                    class="mt-2 mb-2"
                    >Refresh</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";
export default {
  data() {
    return {
      loading: false,
      headers: [
        { text: "Customer", value: "user", sortable: false },
        {
          text: "Date of Last Invoice",
          value: "datelastsales",
          sortable: false,
        },
        { text: "Total Amount", value: "amount", sortable: false },
        { text: "Label", value: "name", sortable: false },
        { text: "Addressee", value: "addressee", sortable: false },
        { text: "Address", value: "address", sortable: false },
        { text: "Address con.", value: "address2", sortable: false },
        { text: "City", value: "city", sortable: false },
        { text: "Zip", value: "zip", sortable: false },
        { text: "Country", value: "country", sortable: false },
        { text: "Sales Rep", value: "salesrep", sortable: false },
        { text: "Created", value: "created", sortable: false },
      ],
      items: [],
      salesreps: [],
      salesrep: [],
      countries: [],
      country: [],
      categories: [],
      category: [],
      createdafter: "",
      datefrom: new Date(new Date().getFullYear() - 1, 0, 2)
        .toISOString()
        .substr(0, 10),
      dateto: "",
      iscompany: true,
      item: null,
      rows: [100],
      singleSelect: false,
      selected: [],
      search: "",
      show: false,
      filteredItems: [],
      amount: null,
      maxamt: null,
      minamt: null,
    };
  },
  async created() {
    this.loading = true;
    await this.getItems();
    await this.getData();
    this.loading = false;
  },
  watch: {
    // async country(value) {
    //   this.loading = true;
    //   await this.getData();
    //   this.loading = false;
    // },
    // async category(value) {
    //   this.loading = true;
    //   await this.getData();
    //   this.loading = false;
    // },
    // async createdafter(value) {
    //   this.loading = true;
    //   await this.getData();
    //   this.loading = false;
    // },
    // async iscompany(value) {
    //   this.loading = true;
    //   await this.getData();
    //   this.loading = false;
    // },
    // async datefrom(value) {
    //   this.loading = true;
    //   await this.getData();
    //   this.loading = false;
    // },
    // async dateto(value) {
    //   this.loading = true;
    //   await this.getData();
    //   this.loading = false;
    // },
    // async maxamt(value) {
    //   this.loading = true;
    //   await this.getData();
    //   this.loading = false;
    // },
    // async minamt(value) {
    //   this.loading = true;
    //   await this.getData();
    //   this.loading = false;
    // },
    // async amount(value) {
    //   this.loading = true;
    //   await this.getData();
    //   this.loading = false;
    // },
  },
  methods: {
    getFiltered(e) {
      this.filteredItems = e;
    },
    async searchRecords() {
      this.loading = true;
      await this.getItems();
      this.loading = false;
    },
    async getItems() {
      this.countries = await service("countries", {
        action: "find",
        data: [{}],
        limit: 250,
      });
      this.categories = await service("classification", {
        action: "find",
        data: [{ recordtype: "customercategory" }],
      });
      this.salesreps = await service("users", {
        action: "find",
        data: [{ recordtype: "employee" }],
      });
    },
    async getData() {
      this.loading = true;
      let result = await service(
        "orders",
        {
          action: "getAddresses",
          country: this.country,
          category: this.category,
          salesrep: this.salesrep,
          created: this.createdafter,
          datefrom: this.datefrom,
          dateto: this.dateto,
          amount: this.amount,
          maxamt: this.maxamt,
          minamt: this.minamt,
          isperson: !this.iscompany,
        },
        false,
        true
      );
      this.items = result.items;
      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
  },
  components: {
    exportData,
  },
};
</script>
 <template>
  <div>
    <v-row>
      <v-col>
        <v-tabs dark v-model="active">
          <v-tab v-for="(location, index) in locations" :key="index">
            <v-badge color="primary">
              <span>Warehouse {{ location.name }}</span>
              <span slot="badge">{{
                items.filter((item) => item.location._id == location._id).length
              }}</span>
            </v-badge>
          </v-tab>
          <v-tab-item v-for="(location, index) in locations" :key="index">
            <v-data-table
              :headers="headers"
              :items="items.filter((item) => item.location._id == location._id)"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              :search="search"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
            >
              <template v-slot:item.location="{ item }">
                <router-link
                  :to="{
                    path: `/${item.location.collection}/${item.location.recordtype}/view/${item.location._id}`,
                  }"
                  class="link"
                  >{{
                    item.location.displayname || item.location.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.delivery="{ item }">
                <router-link
                  :to="{
                    path: `/${item.delivery.collection}/${item.delivery.recordtype}/view/${item.delivery._id}`,
                  }"
                  class="link"
                  >{{
                    item.delivery.displayname || item.delivery.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.vendor="{ item }">
                <router-link
                  :to="{
                    path: `/${item.vendor.collection}/${item.vendor.recordtype}/view/${item.vendor._id}`,
                  }"
                  class="link"
                  >{{
                    item.vendor.displayname || item.vendor.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.item="{ item }">
                <router-link
                  :to="{
                    path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`,
                  }"
                  class="link"
                  >{{ item.item.displayname || item.item.name }}</router-link
                >
              </template>

              <template v-slot:top>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col></v-col>
                  </v-row>
                </v-container>
              </template>
              <template slot="body.append">
                <tr>
                  <th v-for="(col, index) in headers" :key="index">
                    {{
                      col.summary
                        ? calcTotal(
                            col.value,
                            items.filter(
                              (item) => item.location._id == location._id
                            )
                          )
                        : ""
                    }}
                  </th>
                </tr>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import getItemsInDeliveries from "./getItemsInDeliveries";
import filter from "../../dataTable/filter";
export default {
  props: ["item"],
  data() {
    return {
      active: 0,
      headers: [
        //{ text: "Location", value: "location", sortable: false },
        { text: "Date", value: "date", sortable: false },
        { text: "Delivery", value: "delivery", sortable: false },
        { text: "Vendor", value: "vendor", sortable: false },
        { text: "Requested Ship Date", value: "planneddateofshipment" },
        { text: "Delivery Method", value: "deliverymethod" },
        { text: "ETA", value: "eta", sortable: false },
        { text: "Item", value: "item", sortable: false },
        { text: "Quantity", value: "quantity", sortable: false, summary: true },
        {
          text: "Received",
          value: "quantityreceived",
          sortable: false,
          summary: true,
        },
        { text: "Memo", value: "memo", sortable: false },
      ],
      items: [],
      rows: [100],
      locations: [
        { name: "PL", _id: "5e0dbaba9e33df43f0b3a495" },
        { name: "UK (Birmingham)", _id: "5faa7485371aa739b8a08843" },
        { name: "UK", _id: "61e14c6ae4a35a668517a8fe" },
        { name: "Brakes PL", _id: "62de6196fa2fc522dc692c7e" },
      ],
      search: "",
      loading: false,
      locationsFilter: [],
    };
  },
  async created() {
    this.loading = true;
    this.items = await getItemsInDeliveries(this.item);
    if (this.$store.state.user.location)
      this.locationsFilter.push(this.$store.state.user.location);
    if (
      this.$store.state.user.forlocations &&
      this.$store.state.user.forlocations.length
    )
      this.locationsFilter.push(...this.$store.state.user.forlocations);

    if (this.$store.state.user.role._id == "role_peddersuk")
      this.locationsFilter.push(
        "5e0dbaba9e33df43f0b3a495",
        "627cee8a0f148d1cb7e46aa2"
      );
    if (this.$store.state.user.role._id == "role_brakespl")
      this.locationsFilter.push("5e0dbaba9e33df43f0b3a495");

    if (this.items.length) {
      this.items.forEach((item) => {
        if (
          !this.locations.find((location) => location._id == item.location._id)
        )
          this.locations.push(item.location);
      });
    }
    this.locations = this.locations.filter(
      (loc) =>
        this.locationsFilter.some((l) => loc._id.includes(l)) ||
        !this.locationsFilter.length
    );
    this.loading = false;
  },

  methods: {
    calcTotal(key, items) {
      if (items.length > 0) {
        let sum = items.reduce((a, b) => {
          if (
            !isNaN(parseFloat(a[key] || a)) &&
            !isNaN(parseFloat(b[key] || b))
          ) {
            return parseFloat(a[key] || a || 0) + parseFloat(b[key] || b || 0);
          } else return parseFloat(a || 0);
        }, 0);

        return sum && !isNaN(parseInt(sum)) ? sum.toFixed(2) : "";
      }
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
  },
};
</script>
<template>
  <v-row>
    <v-col :md="2" :sm="6" :xs="12">
      <v-card>
        <v-card-text>
          <pie-chart />
        </v-card-text>
        <v-card-text class="pt-0">
          <div class="title font-weight-light mb-2">Chart #1</div>
          <div class="subheading font-weight-light grey--text">Last Campaign Performance</div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col :md="4" :sm="6" :xs="12">
      <v-card>
        <v-card-text>
          <bar-chart />
        </v-card-text>
        <v-card-text class="pt-0">
          <div class="title font-weight-light mb-2">Chart #2</div>
          <div class="subheading font-weight-light grey--text">Last Campaign Performance</div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col :md="3" :sm="6" :xs="12">
      <v-card>
        <v-card-text>
          <line-chart />
        </v-card-text>
        <v-card-text class="pt-0">
          <div class="title font-weight-light mb-2">Chart #3</div>
          <div class="subheading font-weight-light grey--text">Last Campaign Performance</div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col :md="3" :sm="6" :xs="12">
      <v-card>
        <v-card-text>
          <pie-chart />
        </v-card-text>
        <v-card-text class="pt-0">
          <div class="title font-weight-light mb-2">Chart #1</div>
          <div class="subheading font-weight-light grey--text">Last Campaign Performance</div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import PieChart from "../components/charts/pie";
import BarChart from "../components/charts/bar";
import LineChart from "../components/charts/line";
export default {
  data: () => ({
    date: new Date().toISOString().substr(0, 10)
  }),
  components: { PieChart, BarChart, LineChart }
};
</script>
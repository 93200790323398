<template>
  <v-card tile>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Procurement</v-toolbar-title>
    </v-toolbar>
    <v-tabs dark v-model="active">
      <v-tab :key="1">ETA Requests (BETA)</v-tab>
      <v-tab :key="9"><v-icon>mdi-close-outline</v-icon></v-tab>

      <v-tab-item :key="1">
        <borequests v-if="active == 0" />
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>
  
  <script>
import borequests from "../components/tools/requests/View";
/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
      active: 0,
    };
  },
  components: {
    borequests,
  },
};
</script>
import service from '../../api/index';
export default (conf) => {
  let data = {
    action: 'importRecords',
    conf: conf,
  };
  return service(false, data, "json", true)
    .catch(error => {
      throw error;
    })
    .then(response => {
      return response;
    })
};

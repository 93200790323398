 <template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          loading-text="Loading... Please wait"
          item-key="_id"
          :search="search"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
          :custom-sort="sort"
        >
          <template v-slot:item.referencenumber="{ item }">
            {{ (item.referencenumber || "").split(",").join(", ") }}
          </template>
          <template v-slot:item.kitcomponent="{ item }">
            <v-chip label :color="item.kitcomponent ? 'red' : 'none'" dark>{{
              item.kitcomponent ? "Yes" : "No"
            }}</v-chip>
          </template>
          <template v-slot:item.item="{ item }">
            <router-link
              :to="{
                path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`,
              }"
              class="link"
              >{{ item.item.displayname || item.item.name }}</router-link
            >
          </template>
          <template v-slot:item.customer="{ item }">
            <router-link
              v-if="item.customer"
              :to="{
                path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
              }"
              class="link"
              >{{
                item.customer.displayname || item.customer.name
              }}</router-link
            >
          </template>
          <template v-slot:item.date="{ item }">
            <span>{{ new Date(item.date).toISOString().substr(0, 10) }}</span>
          </template>
          <template v-slot:item.transaction="{ item }">
            <router-link
              v-if="item.transaction"
              :to="{
                path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
              }"
              class="link"
              >{{
                item.transaction.displayname || item.transaction.name
              }}</router-link
            >
          </template>
          <template v-slot:item.createdfromtransaction="{ item }">
            <router-link
              v-if="item.createdfromtransaction"
              :to="{
                path: `/${item.createdfromtransaction.collection}/${item.createdfromtransaction.recordtype}/view/${item.createdfromtransaction._id}`,
              }"
              class="link"
              >{{
                item.createdfromtransaction.displayname ||
                item.createdfromtransaction.name
              }}</router-link
            >
          </template>
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col>
                  <exportData :headers="headers" :items="items" />
                </v-col>
                <v-col>
                  <v-select
                    :items="locations"
                    v-model="location"
                    label="Location"
                    item-text="name"
                    item-value="_id"
                    outlined
                    clearable
                    multiple
                    dense
                    @change="getData()"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-select
                    :items="recordtypes"
                    v-model="recordtype"
                    label="Record Type"
                    item-text="name"
                    item-value="_id"
                    outlined
                    clearable
                    multiple
                    dense
                    @change="getData()"
                  ></v-select>
                </v-col>
                <v-col
                  v-if="
                    recordtype &&
                    ['purchaseorder', 'salesorder'].includes(...recordtype)
                  "
                >
                  <v-switch v-model="onlyopen" label="Only Open"></v-switch>
                  <!-- <v-select
                    :items="statuses"
                    v-model="status"
                    label="Status"
                    item-text="name"
                    item-value="_id"
                    class="mt-2 mb-2"
                    outlined
                    clearable
                    multiple
                    dense
                  ></v-select> -->
                </v-col>
                <v-col>
                  <v-menu
                    v-model="menufrom"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        label="Date from"
                        placeholder
                        v-model="datefrom"
                        append-icon="event"
                        readonly
                        v-on="on"
                        outlined
                        dense
                        hide-details
                        clearable
                        @click:clear="getData('datefrom')"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="datefrom"
                      :first-day-of-week="1"
                      @change="getData()"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-menu
                    v-model="menuto"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        label="Date to"
                        placeholder
                        v-model="dateto"
                        append-icon="event"
                        readonly
                        v-on="on"
                        outlined
                        dense
                        hide-details
                        clearable
                        @click:clear="getData('dateto')"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateto"
                      :first-day-of-week="1"
                      @change="getData()"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="text-right">
                  <v-btn @click="getData()" icon
                    ><v-icon>mdi-refresh</v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template slot="body.append">
            <tr>
              <th v-for="(col, index) in headers" :key="index">
                {{ col.summary ? calcTotal(col.value) : "" }}
              </th>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";
import sort from "../../dataTable/sort";
export default {
  props: ["record", "customheaders"],
  data() {
    return {
      loading: false,
      onlyopen: false,
      headers: [
        { text: "Location", value: "location" },
        { text: "Date", value: "date" },
        { text: "Customer", value: "customer" },
        { text: "Transaction", value: "transaction" },
        { text: "Reference Number", value: "referencenumber" },
        { text: "Delivery Method", value: "deliverymethod" },
        { text: "Shipp Address", value: "shippingaddress" },
        { text: "Type", value: "recordtype" },
        { text: "Status", value: "status" },
        { text: "Currency", value: "currency" },
        { text: "Amount", value: "amount", summary: true },
        { text: "Gross Amount", value: "grossamount", summary: true },
        { text: "Quantity", value: "quantity", summary: true },
        {
          text: "Quantity Committed",
          value: "quantitycommitted",
          summary: true,
        },
        { text: "Quantity Received", value: "quantityreceived", summary: true },
        { text: "Quantity Billed", value: "quantitybilled", summary: true },
        {
          text: "Inventory Valuation",
          value: "inventoryvaluation",
        },
        { text: "Memo", value: "memo" },
      ],
      menuto: false,
      menufrom: false,
      items: [],
      rows: [50, 100, -1],
      search: "",
      currency: "eur",
      datefrom: null,
      dateto: null,
      recordtype: null,
      recordtypes: [],
      location: [],
      locations: [],
      status: [],
      statutes: [],
      total: {},
      locationsFilter: [],
    };
  },
  async created() {
    this.loading = true;
    this.headers = this.customheaders || this.headers;
    if (this.$store.state.user.location)
      this.locationsFilter.push(this.$store.state.user.location);
    if (
      this.$store.state.user.forlocations &&
      this.$store.state.user.forlocations.length
    )
      this.locationsFilter.push(...this.$store.state.user.forlocations);
    if (this.$store.state.user.role._id == "role_peddersuk")
      this.locationsFilter.push(
        "5e0dbaba9e33df43f0b3a495",
        "5faa7485371aa739b8a08843"
      );

    this.recordtypes = await service("recordtypes", {
      action: "find",
      data: [
        {
          //recordtype: "recordtype",
          _id: {
            $in: [
              "invoice",
              "creditmemo",
              "customerpayment",
              "customerrefund",
              "purchaseorder",
              "salesorder",
              "bill",
              "estimate",
              "itemreceipt",
              "itemfulfillment",
              "inventoryadjustment",
              "inventorytransfer",
            ],
          },
        },
      ],
    });
    this.statuses = await service("classification", {
      action: "find",
      data: [
        {
          recordtype: "transactionstatus",
        },
      ],
    });
    this.locations = await service("accounting", {
      action: "find",
      data: [
        {
          recordtype: "location",
          _id: this.locationsFilter.length
            ? { $in: this.locationsFilter }
            : { $exists: true },
        },
      ],
    });
    await this.getData();
    if (
      this.record.collection == "items" &&
      this.record.recordtype == "inventoryitem"
    )
      this.headers.push({ text: "Kit comp.", value: "kitcomponent" });
    this.loading = false;
  },
  methods: {
    filterRows(items) {
      if (items && this.onlyopen) {
        return items.filter((line) => {
          console.log(
            line.quantity,
            line.quantityreceived,
            line.parentrecordtype || line.recordtype
          );
          if (
            (line.parentrecordtype || line.recordtype) == "Purchase Order" &&
            line.status != "Closed" &&
            line.quantity > line.quantityreceived
          )
            return true;
          if (
            (line.parentrecordtype || line.recordtype) == "Sales Order" &&
            line.status != "Closed" &&
            line.quantity > line.quantitybilled
          )
            return true;
          return false;
        });
      }
      return items;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    sort(items, sortBy, sortDesc) {
      return sort(items, sortBy, sortDesc);
    },
    calcTotal(key) {
      if (this.items.length > 0) {
        let sum = this.items.reduce((a, b) => {
          if (
            !isNaN(parseFloat(a[key] || a)) &&
            !isNaN(parseFloat(b[key] || b))
          ) {
            return parseFloat(a[key] || a || 0) + parseFloat(b[key] || b || 0);
          } else return parseFloat(a || 0);
        }, 0);

        return sum && !isNaN(parseInt(sum)) ? sum.toFixed(2) : "";
      }
    },
    async getData(clear) {
      this.loading = true;
      if (clear) this[clear] = null;
      this.items = [];
      let newresult = await service(
        "pricing",
        {
          action: "getRelatedTransactions",
          rid: this.record._id,
          collection: this.record.collection,
          datefrom: this.datefrom,
          dateto: this.dateto,
          recordtype: this.recordtype,
          location: this.location,
          status: this.status,
        },
        false,
        true
      );
      this.items = newresult.data;
      this.items = this.filterRows(this.items);
      this.loading = false;
    },
  },
  components: {
    exportData,
  },
};
</script>
 <template>
  <v-container>
    <!-- <v-row>
      <v-col>
        <v-select
          :items="recordTypes"
          v-model="record.type"
          label="Record Type"
          item-text="name"
          item-value="_id"
          outlined
          required
          dense
        ></v-select>
      </v-col>
    </v-row> -->
    <v-row>
      <!-- <v-col>
        <v-text-field
          v-model="record.subject"
          label="Subject"
          outlined
          required
          dense
        ></v-text-field>
      </v-col> -->
      <v-col><emaileditor :record="record"></emaileditor></v-col>
    </v-row>
    <v-row>
      <v-col>
        <small>Template</small>
        <yimo-vue-editor v-model="record.text"></yimo-vue-editor>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col>
        <small>Signature</small>
        <yimo-vue-editor v-model="record.signature"></yimo-vue-editor>
      </v-col>
    </v-row> -->
  </v-container>
</template>
<script>
import { getEmail, sendEmail } from "../../common/methods/email";
import { E, instance as YimoVueEditor } from "yimo-vue-editor";
import emaileditor from "../tools/emaileditor/View";
import service from "../../api/index";
import Vue from "vue";
Vue.use(YimoVueEditor, {
  name: "v-editor-app",
  config: {
    lang: E.langs.en,
  },
});
export default {
  props: ["record"],
  data() {
    return {
      recordTypes: [
        {
          _id: "estimate",
          name: "Estimate",
          collection: "transactions",
        },
        {
          _id: "salesorder",
          name: "Sales Order",
          collection: "transactions",
        },
        {
          _id: "itemfulfillment",
          name: "Item Fulfillment",
          collection: "transactions",
        },
        {
          _id: "inventorytransfer",
          name: "Inventory Transfer",
          collection: "transactions",
        },
        {
          _id: "invoice",
          name: "Invoice",
          collection: "transactions",
        },
        {
          _id: "purchaseorder",
          name: "Purchase Order",
          collection: "transactions",
        },
        {
          _id: "creditmemo",
          name: "Credit Memo",
          collection: "transactions",
        },
        { _id: "customer", name: "Customer", collection: "users" },
        { _id: "vendor", name: "Vendor", collection: "users" },
        { _id: "customerpayment", name: "Payment", collection: "transactions" },
        { _id: "report", name: "Report", collection: "queries" },
        { _id: "delivery", name: "Delivery", collection: "deliveries" },
      ],
    };
  },
  components: {
    YimoVueEditor,
    emaileditor,
  },
};
</script>
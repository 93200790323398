
<template>
  <v-card tile>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Purchase Management Tools</v-toolbar-title>
    </v-toolbar>
    <v-tabs dark v-model="active">
      <v-tab :key="1">Deliveries</v-tab>
      <v-tab :key="2">BackOrder Items</v-tab>
      <v-tab :key="3">Special BackOrder</v-tab>
      <v-tab :key="4">Open Purchase Orders</v-tab>

      <v-tab :key="5"><v-icon>mdi-close-outline</v-icon></v-tab>

      <v-tab-item :key="1">
        <deliveriescalendar v-if="active == 0" />
      </v-tab-item>
      <v-tab-item :key="2">
        <backorderitems v-if="active == 1" />
      </v-tab-item>
      <v-tab-item :key="3">
        <additionalservice v-if="active == 2" />
      </v-tab-item>
      <v-tab-item :key="4">
        <openpurchaseorders v-if="active == 3" />
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import fleetorderslist from "../components/tools/fleetorderslist/View";
import additionlservicelist from "../components/tools/additionlservicelist/View";
import deliveriescalendar from "../components/tools/deliveriescalendar/View";
import openstockrequest from "../components/tools/openstockrequest/View";
import backorderitems from "../components/tools/backorderitems/View";
import dropshiporders from "../components/tools/dropshiporders/View";
import fleetorders from "../components/tools/fleetorders/View";
import additionalservice from "../components/tools/additionalservice/View";
import openpurchaseorders from "../components/tools/openpurchaseorders/View";
/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
      active: 4,
    };
  },
  components: {
    fleetorderslist,
    additionlservicelist,
    deliveriescalendar,
    openstockrequest,
    dropshiporders,
    fleetorders,
    additionalservice,
    backorderitems,
    openpurchaseorders,
  },
};
</script>
<template>
  <div>
    <queryresult v-if="!$route.params.mode" queryid="exchangerate"/>
    <record collection="exchangerates" v-else/>
    <button class="btn" @click="update('eur');">Update EUR</button>
    <v-snackbar v-model="msg.show" :timeout="6000" top absolute right>{{ msg.message }}</v-snackbar>
  </div>
</template>

<script>
import { getRate } from './service-nbp';
import service from '../../api/index';
/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
      msg: false,
    };
  },
  methods: {
    async update(currency) {
      const rate = await getRate(currency);

      let data = {
        action: 'insertOne',
        data: [rate],
      };
      console.log(data);
      service('exchangerates', data)
        .catch(error => {
          throw error;
        })
        .then(response => {
          this.msg = { message: 'Exchange rate was saved ', show: true };
        })
        .catch(error => {
          this.msg = { message: `${error.message}`, show: true };
        });
    },
  },
};
</script>


<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    full-width
    min-width="290px"
    :readonly="$route.params.mode == 'view' || (this.formSettings.disabled || this.fieldSettings.disabled == true)"
    :disabled="$route.params.mode == 'view' || this.formSettings.disabled || this.fieldSettings.disabled == true"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :label="label"
        placeholder
        v-model="color"
        append-icon="event"
        :rules="[rules.required]"
        readonly
        v-on="on"
        outlined
        dense
      ></v-text-field>
    </template>
    <v-color-picker v-model="color" :swatches="swatches" show-swatches @input="emitChangedValue()"></v-color-picker>
  </v-menu>
</template>

<script>
import fieldCalculated from "../../common/methods/fieldCalculated";
/* eslint-disable */
export default {
  props: ["value", "record", "fieldSettings", "formSettings"],
  data() {
    return {
      color: this.value,
      required:
        this.formSettings.required ||
        this.fieldSettings.setup.required ||
        false,
      field: this.formSettings.field || this.formSettings.setup.field,
      label: this.formSettings.name || this.fieldSettings.name,
      menu: false,
      rules: {
        required: value => (this.required ? !!value || "Required." : true),
        allowspaces: value =>
          ((value || "").indexOf(" ") < 0 && this.allowspaces) ||
          "No spaces are allowed"
      },
      mode: this.$route.params.mode,
      swatches: [
        ["#FF0000", "#AA0000", "#550000"],
        ["#FFFF00", "#AAAA00", "#555500"],
        ["#00FF00", "#00AA00", "#005500"],
        ["#00FFFF", "#00AAAA", "#005555"],
        ["#0000FF", "#0000AA", "#000055"]
      ]
    };
  },
  watch: {
    color(value) {
      if (value && value.hex) this.color = value.hex;
    },
    record: {
      handler: async function(after, before) {
        if (this.record[this.field] != this.color)
          this.color = this.record[this.field];
      },
      deep: true
    }
  },
  created() {},
  methods: {
    emitChangedValue() {
      if (this.color && this.color.hex) this.color = this.color.hex;
      this.$emit("emit", {
        field: this.field,
        value: this.color
      });
    }
  }
};
</script>

<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    full-width
    min-width="290px"
    :readonly="$route.params.mode == 'view' || (this.formSettings.disabled || this.fieldSettings.disabled == true) || record.archived"
    :disabled="$route.params.mode == 'view' || this.formSettings.disabled || this.fieldSettings.disabled == true"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :label="label"
        placeholder
        v-model="time"
        prepend-inner-icon="event"
        :rules="[rules.required]"
        readonly
        v-on="on"
        outlined
        dense
      ></v-text-field>
    </template>
    <v-time-picker v-model="time" @input="emitChangedValue('ASD')"></v-time-picker>
  </v-menu>
</template>

<script>
/* eslint-disable */

export default {
  props: ["value", "record", "fieldSettings", "formSettings"],
  data() {
    return {
      time: this.value,
      required:
        this.formSettings.required ||
        this.fieldSettings.setup.required ||
        false,
      field: this.formSettings.field || this.formSettings.setup.field,
      label: this.formSettings.name || this.fieldSettings.name,
      menu: false,
      modal: false,
      rules: {
        required: value => (this.required ? !!value || "Required." : true)
      }
    };
  },
  methods: {
    emitChangedValue(value) {
      console.log("change", value, this.time);
      this.$emit("emit", {
        field: this.field,
        value: this.time ? this.time : null
      });
    }
  },
  created() {
    this.time = this.value
      ? this.value
      : null;
  },
  watch: {

    time(value) {
      console.log("change time", value);
    },

    record: {
      handler: async function(after, before) {
        //console.log(this.record[this.field]);
        if (this.record[this.field] != new Date(this.time)) {
          this.date = this.record[this.field]
            ? this.record[this.field]
            : null;
      
        }
      },
      deep: true
    }
  }
};
</script>
<style>
.v-menu__content .v-date-picker-title {
  height: 70px;
}
.v-menu__content .v-picker {
  border-radius: 0 !important;
  box-shadow: none;
}
</style>
<template>
  <v-card v-if="!loading" :key="updatedCpunt">
    <google-map :config="mapConfig" apikey="AIzaSyAeBjRkfSsywTZQIjapMQRcYsDgldO99Ok">
      <GoogleMapMarkers :markers="mapMarkers" />
    </google-map>
  </v-card>
</template>

<script>
import GoogleMap from "../../components/maps/GoogleMap";
import GoogleMapMarkers from "../../components/maps/GoogleMapMarkers";
import getRecord from "../../common/methods/getRecord";
/* eslint-disable */
export default {
  components: {
    GoogleMap,
    GoogleMapMarkers
  },
  props: ["addresses"],
  data() {
    return {
      updatedCpunt: 0,
      loading: true,
      mapConfig: {
        zoom: 2,
        center: {
          lat: 0,
          lng: 0
        }
      },
      mapMarkers: []
    };
  },
  async created() {
    this.addresses.forEach(async address => {
      let addressRecord =
        address.latitude && address.longitude
          ? address
          : await getRecord(this, address._id, "addresses", "address");
      address.latitude = addressRecord.latitude;
      address.longitude = addressRecord.longitude;
      if (address.latitude && address.longitude) {
        this.mapMarkers.push(address);

        this.mapConfig.center.lat = parseFloat(address.latitude);
        this.mapConfig.center.lng = parseFloat(address.longitude);
      }
      this.updatedCpunt++;
    });
    this.loading = false;
    console.log(this.addresses);
  },
  methods: {}
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}
</style>

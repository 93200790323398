 <template>
  <v-container fluid>
    <v-row>
      <v-col v-for="(note, index) of notes" :key="index" >
        <v-textarea
          :loading="loading"
          @change="save"
          v-model="notes[index]"
          persistent-hint
          :hint="index == notes.length - 1 ? (saved ? 'Saved' : 'Unsaved') : ''"
          :append-outer-icon="index == notes.length - 1 ? 'mdi-plus' : ''"
          @click:append-outer="addCol"
          outlined
          :append-icon="notes[index] ? 'mdi-close' : ''"
          @click:append="delCol(index)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import YimoVueEditor from "yimo-vue-editor";
import service from "../../../api/index";
export default {
  data() {
    return {
      loading: false,
      saved: true,
      modified: false,
      notes: [],
    };
  },
  watch: {
    notes(v) {
      if (this.modified) this.saved = false;
      this.modified = true;
    },
    saved(v) {
      console.log(v);
      if (v) {
        window.onbeforeunload = null;
      } else {
        window.onbeforeunload = function () {
          return "Are you sure you want to close the window?";
        };
      }
    },
  },
  async created() {
    await this.getData();
    // window.removeEventListener("beforeunload", () => undefined);
  },
  methods: {
    addCol() {
      this.notes.push("");
      this.saved = false;
    },
    delCol(e) {
      var response = confirm("Delete - Are you sure?");
      if (response) {
        this.notes.splice(e, 1);
        this.saved = false;
        this.save();
      } else{
        this.notes[e]="asd"
      }
    },
    async save() {
      this.loading = true;
      let data = {
        action: "updateOne",
        data: [
          { _id: this.$store.state.user._id },
          {
            $set: {
              note: this.notes.join("\n//END//\n"),
            },
          },
        ],
      };
      await service("users", data);
      this.saved = true;

      this.loading = false;
    },
    async getData() {
      this.loading = true;
      let data = {
        action: "findOne",
        data: [{ _id: this.$store.state.user._id }],
      };
      //console.log("Select", collection,data);
      return service("users", data)
        .catch((error) => {
          throw error;
        })
        .then((response) => {
          this.notes = (response.note || "").split("\n//END//\n");
          console.log(this.notes);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
  components: {
    YimoVueEditor,
  },
};
</script>
<style  scoped>
.v-dialog__container {
  display: unset;
}
</style>
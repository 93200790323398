<script>
import { Doughnut, mixins } from "vue-chartjs";
import colors from "./colors";
import "chartjs-plugin-labels";
const { reactiveProp } = mixins;
export default {
  props: ["option", "chartData"],
  mixins: [reactiveProp],
  extends: Doughnut,
  data: () => ({
    options: {
      legend: false,
      responsive: true,
      maintainAspectRatio: false,

      plugins: {
        labels: [
          {
            render: (data) => (data.percentage >= 5 ? data.label : ""),
            position: "outside",
          },
          {
            render: "percentage",
            overlap: false,
            fontSize: 16,
            fontColor: "#FFF",
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            var total = dataset.data.reduce(function (
              previousValue,
              currentValue,
              currentIndex,
              array
            ) {
              return parseFloat(previousValue) + parseFloat(currentValue);
            });
            var currentValue = dataset.data[tooltipItem.index];
            var precentage = Math.floor((currentValue / total) * 100 + 0.5);
            return `${data["labels"][tooltipItem["index"]]}: ${precentage}%`;
          },
        },
      },
    },
  }),
  methods: {
    sortData() {
      let vue = this;
      let arrayOfObj = this.chartData.labels.map(function (d, i) {
        return {
          label: d,
          data: vue.chartData.datasets[0].data[i] || 0,
          backgroundColor: vue.chartData.datasets[0].backgroundColor[i],
        };
      });

      let sortedArrayOfObj = arrayOfObj.sort(function (a, b) {
        return b.data - a.data;
      });
      let newArrayLabel = [];
      let newArrayData = [];
      let newArrayColor = [];
      //this.chartData.labels = [];
      this.chartData.datasets[0].data = [];
      this.chartData.datasets[0].backgroundColor = [];
      sortedArrayOfObj.forEach(function (d) {
        newArrayLabel.push(d.label);
        newArrayData.push(d.data);
        newArrayColor.push(d.backgroundColor);
      });

      if (
        JSON.stringify(this.chartData.labels) != JSON.stringify(newArrayLabel)
      )
        this.chartData.labels = newArrayLabel;
      this.chartData.datasets[0].data = newArrayData;
      this.chartData.datasets[0].backgroundColor = newArrayColor;
    },
  },
  watch: {
    "chartData.labels"(v) {
      if (this.chartData.datasets[0]) {
        this.sortData();
        this.renderChart(this.chartData, this.options);
      }
    },
  },
  mounted() {
    this.options = Object.assign(this.options, this.option);
    if (this.chartData.datasets[0]) {
      this.sortData();
      this.renderChart(this.chartData, this.options);
    }
  },
};
</script>
 <template>
  <div>
    <v-row>
      <v-col>
        <v-tabs dark v-model="active2">
          <v-tab :key="1">
            <span>Sales Orders</span>
          </v-tab>
          <v-tab :key="2">
            <span>Customers</span>
          </v-tab>
          <v-tab-item :key="1">
            <v-tabs dark v-model="active">
              <v-tab
                :key="1"
                v-if="
                  !locationsFilter.length ||
                  locationsFilter.includes('5e0dbaba9e33df43f0b3a495')
                "
              >
                <v-badge color="primary">
                  <span>PL</span>
                  <span slot="badge">{{ itemsPL.length }}</span>
                </v-badge>
              </v-tab>
              <v-tab
                :key="2"
                v-if="
                  !locationsFilter.length ||
                  locationsFilter.includes('66e04fcba16b2e1e7d873be8')
                "
              >
                <v-badge color="primary">
                  <span>NL</span>
                  <span slot="badge">{{ itemsNL.length }}</span>
                </v-badge>
              </v-tab>
              <v-tab
                :key="3"
                v-if="
                  !locationsFilter.length ||
                  ['61e14c6ae4a35a668517a8fe'].some((loc) =>
                    locationsFilter.includes(loc)
                  )
                "
              >
                <v-badge color="primary">
                  <span>UK</span>
                  <span slot="badge">{{ itemsUK.length }}</span>
                </v-badge>
              </v-tab>

              <v-tab
                :key="4"
                v-if="
                  !locationsFilter.length ||
                  ['62de6196fa2fc522dc692c7e'].some((loc) =>
                    locationsFilter.includes(loc)
                  )
                "
              >
                <v-badge color="primary">
                  <span>Brakes PL</span>
                  <span slot="badge">{{ itemsBRAKESPL.length }}</span>
                </v-badge>
              </v-tab>
              <v-tab-item
                :key="1"
                v-if="
                  !locationsFilter.length ||
                  locationsFilter.includes('5e0dbaba9e33df43f0b3a495')
                "
              >
                <v-data-table
                  :headers="headers"
                  :items="itemsPL"
                  :loading="loading"
                  loading-text="Loading... Please wait"
                  item-key="_id"
                  :search="search"
                  :footer-props="{ itemsPerPageOptions: rows }"
                  :custom-filter="filter"
                >
                  <template v-slot:top>
                    <v-container fluid>
                      <v-row>
                        <v-col>
                          <v-text-field
                            dense
                            v-model="search"
                            append-icon="search"
                            label="Search"
                            single-line
                            hide-details
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <exportData :headers="headers" :items="itemsPL" />
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                  <template v-slot:item.memo="{ item }">
                    <v-textarea
                      type="text"
                      dense
                      v-model="item.memo"
                      hide-details
                      class="mt-2 mb-2"
                      @click:clear="action(item, 'memo')"
                      @change="action(item, 'memo')"
                    ></v-textarea>
                  </template>
                  <template v-slot:item.date="{ item }">
                    <span>{{
                      new Date(item.date).toISOString().substr(0, 10)
                    }}</span>
                  </template>
                  <template v-slot:item.planneddateofshipment="{ item }">
                    <span>{{
                      item.planneddateofshipment
                        ? new Date(item.planneddateofshipment)
                            .toISOString()
                            .substr(0, 10)
                        : ""
                    }}</span>
                  </template>
                  <template v-slot:item.customer="{ item }">
                    <router-link
                      :to="{
                        path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                      }"
                      class="link"
                      >{{
                        item.customer.displayname || item.customer.name
                      }}</router-link
                    >
                  </template>
                  <template v-slot:item.transaction="{ item }">
                    <router-link
                      :to="{
                        path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                      }"
                      class="link"
                      >{{
                        item.transaction.displayname || item.transaction.name
                      }}</router-link
                    >
                  </template>
                  <template v-slot:item.accountonhold="{ item }">
                    <v-chip
                      label
                      :color="item.accountonhold ? 'red' : 'none'"
                      dark
                      >{{ item.accountonhold ? "Yes" : "No" }}</v-chip
                    >
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab-item
                :key="2"
                v-if="
                  !locationsFilter.length ||
                  locationsFilter.includes('66e04fcba16b2e1e7d873be8')
                "
              >
                <v-data-table
                  :headers="headers"
                  :items="itemsNL"
                  :loading="loading"
                  loading-text="Loading... Please wait"
                  item-key="_id"
                  :search="search"
                  :footer-props="{ itemsPerPageOptions: rows }"
                  :custom-filter="filter"
                >
                  <template v-slot:top>
                    <v-container fluid>
                      <v-row>
                        <v-col>
                          <v-text-field
                            dense
                            v-model="search"
                            append-icon="search"
                            label="Search"
                            single-line
                            hide-details
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <exportData :headers="headers" :items="itemsNL" />
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                  <template v-slot:item.memo="{ item }">
                    <v-textarea
                      type="text"
                      dense
                      v-model="item.memo"
                      hide-details
                      class="mt-2 mb-2"
                      @click:clear="action(item, 'memo')"
                      @change="action(item, 'memo')"
                    ></v-textarea>
                  </template>
                  <template v-slot:item.date="{ item }">
                    <span>{{
                      new Date(item.date).toISOString().substr(0, 10)
                    }}</span>
                  </template>
                  <template v-slot:item.planneddateofshipment="{ item }">
                    <span>{{
                      item.planneddateofshipment
                        ? new Date(item.planneddateofshipment)
                            .toISOString()
                            .substr(0, 10)
                        : ""
                    }}</span>
                  </template>
                  <template v-slot:item.customer="{ item }">
                    <router-link
                      :to="{
                        path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                      }"
                      class="link"
                      >{{
                        item.customer.displayname || item.customer.name
                      }}</router-link
                    >
                  </template>
                  <template v-slot:item.transaction="{ item }">
                    <router-link
                      :to="{
                        path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                      }"
                      class="link"
                      >{{
                        item.transaction.displayname || item.transaction.name
                      }}</router-link
                    >
                  </template>
                  <template v-slot:item.accountonhold="{ item }">
                    <v-chip
                      label
                      :color="item.accountonhold ? 'red' : 'none'"
                      dark
                      >{{ item.accountonhold ? "Yes" : "No" }}</v-chip
                    >
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab-item
                :key="3"
                v-if="
                  !locationsFilter.length ||
                  ['61e14c6ae4a35a668517a8fe'].some((loc) =>
                    locationsFilter.includes(loc)
                  )
                "
              >
                <v-data-table
                  :headers="headers"
                  :items="itemsUK"
                  :loading="loading"
                  loading-text="Loading... Please wait"
                  item-key="_id"
                  :search="search"
                  :footer-props="{ itemsPerPageOptions: rows }"
                  :custom-filter="filter"
                >
                  <template v-slot:top>
                    <v-container fluid>
                      <v-row>
                        <v-col>
                          <v-text-field
                            dense
                            v-model="search"
                            append-icon="search"
                            label="Search"
                            single-line
                            hide-details
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <exportData :headers="headers" :items="itemsUK" />
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                  <template v-slot:item.memo="{ item }">
                    <v-textarea
                      type="text"
                      dense
                      v-model="item.memo"
                      hide-details
                      class="mt-2 mb-2"
                      @click:clear="action(item, 'memo')"
                      @change="action(item, 'memo')"
                    ></v-textarea>
                  </template>
                  <template v-slot:item.date="{ item }">
                    <span>{{
                      new Date(item.date).toISOString().substr(0, 10)
                    }}</span>
                  </template>
                  <template v-slot:item.planneddateofshipment="{ item }">
                    <span>{{
                      item.planneddateofshipment
                        ? new Date(item.planneddateofshipment)
                            .toISOString()
                            .substr(0, 10)
                        : ""
                    }}</span>
                  </template>
                  <template v-slot:item.customer="{ item }">
                    <router-link
                      :to="{
                        path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                      }"
                      class="link"
                      >{{
                        item.customer.displayname || item.customer.name
                      }}</router-link
                    >
                  </template>
                  <template v-slot:item.transaction="{ item }">
                    <router-link
                      :to="{
                        path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                      }"
                      class="link"
                      >{{
                        item.transaction.displayname || item.transaction.name
                      }}</router-link
                    >
                  </template>
                  <template v-slot:item.accountonhold="{ item }">
                    <v-chip
                      label
                      :color="item.accountonhold ? 'red' : 'none'"
                      dark
                      >{{ item.accountonhold ? "Yes" : "No" }}</v-chip
                    >
                  </template>
                </v-data-table>
              </v-tab-item>

              <v-tab-item
                :key="4"
                v-if="
                  !locationsFilter.length ||
                  ['62de6196fa2fc522dc692c7e'].some((loc) =>
                    locationsFilter.includes(loc)
                  )
                "
              >
                <v-data-table
                  :headers="headers"
                  :items="itemsBRAKESPL"
                  :loading="loading"
                  loading-text="Loading... Please wait"
                  item-key="_id"
                  :search="search"
                  :footer-props="{ itemsPerPageOptions: rows }"
                  :custom-filter="filter"
                >
                  <template v-slot:top>
                    <v-container fluid>
                      <v-row>
                        <v-col>
                          <v-text-field
                            dense
                            v-model="search"
                            append-icon="search"
                            label="Search"
                            single-line
                            hide-details
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <exportData
                            :headers="headers"
                            :items="itemsBRAKESPL"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                  <template v-slot:item.memo="{ item }">
                    <v-textarea
                      type="text"
                      dense
                      v-model="item.memo"
                      hide-details
                      class="mt-2 mb-2"
                      @click:clear="action(item, 'memo')"
                      @change="action(item, 'memo')"
                    ></v-textarea>
                  </template>
                  <template v-slot:item.date="{ item }">
                    <span>{{
                      new Date(item.date).toISOString().substr(0, 10)
                    }}</span>
                  </template>
                  <template v-slot:item.planneddateofshipment="{ item }">
                    <span>{{
                      item.planneddateofshipment
                        ? new Date(item.planneddateofshipment)
                            .toISOString()
                            .substr(0, 10)
                        : ""
                    }}</span>
                  </template>
                  <template v-slot:item.customer="{ item }">
                    <router-link
                      :to="{
                        path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                      }"
                      class="link"
                      >{{
                        item.customer.displayname || item.customer.name
                      }}</router-link
                    >
                  </template>
                  <template v-slot:item.transaction="{ item }">
                    <router-link
                      :to="{
                        path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                      }"
                      class="link"
                      >{{
                        item.transaction.displayname || item.transaction.name
                      }}</router-link
                    >
                  </template>
                  <template v-slot:item.accountonhold="{ item }">
                    <v-chip :color="item.accountonhold ? 'red' : 'none'" dark>{{
                      item.accountonhold ? "Yes" : "No"
                    }}</v-chip>
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs>
          </v-tab-item>
          <v-tab-item :key="2">
            <v-data-table
              :headers="headersCustomer"
              :items="itemsCustomer"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              :search="search"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
            >
              <template v-slot:item.alertdescription="{ item }">
                <v-textarea
                  type="text"
                  dense
                  v-model="item.alertdescription"
                  hide-details
                  class="mt-2 mb-2"
                  @click:clear="action(item, 'alertdescription')"
                  @change="action(item, 'alertdescription')"
                ></v-textarea>
              </template>
              <template v-slot:item.date="{ item }">
                <span>{{
                  new Date(item.date).toISOString().substr(0, 10)
                }}</span>
              </template>
              <template v-slot:item.alertdate="{ item }">
                <span>{{
                  item.alertdate
                    ? new Date(item.alertdate).toISOString().substr(0, 10)
                    : ""
                }}</span>
              </template>
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                  }"
                  class="link"
                  >{{
                    item.customer.displayname || item.customer.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.transaction="{ item }">
                <router-link
                  :to="{
                    path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                  }"
                  class="link"
                  >{{
                    item.transaction.displayname || item.transaction.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.accountonhold="{ item }">
                <v-chip
                  label
                  :color="item.accountonhold ? 'red' : 'none'"
                  dark
                  >{{ item.accountonhold ? "Yes" : "No" }}</v-chip
                >
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";
export default {
  props: ["customer"],
  data() {
    return {
      loading: false,
      active: 0,
      headers: [
        { text: "Date", value: "date", sortable: false },
        { text: "Customer", value: "customer", sortable: false },
        { text: "Account on Hold", value: "accountonhold", sortable: false },
        { text: "SO", value: "transaction", sortable: false },
        { text: "Planned Date Of Shipment", value: "planneddateofshipment" },
        { text: "Sales Rep", value: "salesrep", sortable: false },
        { text: "Category", value: "category", sortable: false },
        { text: "Internal Comments", value: "memo", sortable: false },
        // { text: "Balance", value: "balance" },
        // { text: "Unapplied Payments", value: "unapplied" }
      ],
      headersCustomer: [
        { text: "Customer", value: "customer", sortable: false },
        { text: "Account on Hold", value: "accountonhold", sortable: false },
        { text: "Alert Date", value: "alertdate" },
        { text: "Sales Rep", value: "salesrep", sortable: false },
        { text: "Category", value: "category", sortable: false },
        {
          text: "Alert Description",
          value: "alertdescription",
          sortable: false,
        },
        // { text: "Balance", value: "balance" },
        // { text: "Unapplied Payments", value: "unapplied" }
      ],
      items: [],
      itemsPL: [],
      itemsNL: [],
      itemsUK: [],
      itemsBRAKESPL: [],
      itemsCustomer: [],
      rows: [100],
      search: "",
      show: false,
      locationsFilter: [],
    };
  },
  async created() {
    this.loading = true;
    if (this.$store.state.user.location)
      this.locationsFilter.push(this.$store.state.user.location);
    if (
      this.$store.state.user.forlocations &&
      this.$store.state.user.forlocations.length
    )
      this.locationsFilter.push(...this.$store.state.user.forlocations);
    await this.getData();
    await this.getDataCustomer();
    this.loading = false;
  },
  methods: {
    async action(item, field) {
      try {
        let result = await service("orders", {
          action: "setField",
          collection: item.collection,
          rid: item._id,
          field: field,
          value: item[field],
        });
      } catch {
        alert(error.message);
      }
    },
    async getDataCustomer() {
      this.loading = true;
      this.itemsCustomer = [];

      let result = await service(
        "customers",
        {
          action: "getPlannedDateOfCustomer",
        },
        false,
        true
      );

      this.itemsCustomer = result.items;
      this.loading = false;
    },
    async getData() {
      this.loading = true;
      this.items = [];
      this.itemsPL = [];
      this.itemsUK = [];
      this.itemsBRAKESPL = [];
      let result = await service(
        "customers",
        {
          action: "getPlannedDateOfShippment",
        },
        false,
        true
      );
      result.items.forEach((item) => {
        if (item.location == "5e0dbaba9e33df43f0b3a495") {
          this.itemsPL.push(item);
        }
        if (item.location == "66e04fcba16b2e1e7d873be8") {
          this.itemsNL.push(item);
        }
        if (["61e14c6ae4a35a668517a8fe"].includes(item.location)) {
          this.itemsUK.push(item);
        }
        if (item.location == "62de6196fa2fc522dc692c7e") {
          this.itemsBRAKESPL.push(item);
        }
      });
      this.items = this.itemsPL.concat(this.itemsUK.concat(this.itemsBRAKESPL.concat(this.itemsNL)));
      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
  },
  components: {
    exportData,
  },
};
</script>
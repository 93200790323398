<template>
  <v-card tile>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Accounting Management Tools</v-toolbar-title>
    </v-toolbar>
    <v-tabs dark v-model="active">
      <v-tab :key="1">Inventory Valuation</v-tab>
      <v-tab :key="2">Open Payments</v-tab>
      <v-tab :key="3">Statement</v-tab>
      <v-tab :key="4">Costs Report</v-tab>
      <v-tab :key="5">Print Transactions</v-tab>
      <v-tab :key="6">Reports</v-tab>
      <v-tab :key="7"><v-icon>mdi-close-outline</v-icon></v-tab>
      <!-- <v-tab :key="5">Sales Report</v-tab> -->
      <v-tab-item :key="1">
        <inventoryvaluation v-if="active == 0" />
      </v-tab-item>
      <v-tab-item :key="2">
        <openpayments v-if="active == 1" />
      </v-tab-item>
      <v-tab-item :key="3">
        <openinvoices v-if="active == 2" />
      </v-tab-item>
      <v-tab-item :key="4">
        <costdetails v-if="active == 3" />
      </v-tab-item>
      <v-tab-item :key="5">
        <printtransactions v-if="active == 4" />
      </v-tab-item>
      <v-tab-item :key="6">
        <reports v-if="active == 5" />
      </v-tab-item>
    </v-tabs>
    <addtools />
  </v-card>
</template>

<script>
import addtools from "./accountingtools2.vue";
import inventoryvaluation from "../components/tools/inventoryvaluation/View";
import openpayments from "../components/tools/openpayments/View";
import openinvoices from "../components/tools/openinvoices/View";
import printtransactions from "../components/tools/printtransactions/View";
import reports from "../components/tools/reports/View";
import costdetails from "../components/tools/costdetails/View";
/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
      active: 6,
    };
  },
  components: {
    addtools,
    inventoryvaluation,
    openpayments,
    openinvoices,
    printtransactions,
    reports,
    costdetails,
  },
};
</script>
<template>
  <div>
    <v-card v-if="!loading">
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{record.name || "Loading..."}}</v-toolbar-title>
      </v-toolbar>
      <v-progress-linear :indeterminate="loading" height="3" class="ma-0"></v-progress-linear>
      <calendar :calendar="record"/>
    </v-card>
    <v-snackbar v-model="msg.show" :timeout="6000" top absolute right>{{ msg.message }}</v-snackbar>
  </div>
</template>

<script>
import getRecord from "../../../common/methods/getRecord";
import getQuery from "../../../common/methods/getQuery";
import getQueryResult from "../../../common/methods/getQueryResult";
/* eslint-disable */
export default {
  components: {},
  data() {
    return {
      updatedCpunt: 0,
      loading: true,
      record: {},
      activities: [],
      msg: false,
      rid: this.$route.params.id
    };
  },
  async created() {
    try {
      console.log("activities", this.rid)
      this.record = await getRecord(this, this.rid, "activities");
      // this.query = await getQuery(this, "activities", { calendar: this.rid });
      // this.activities = await getQueryResult(this.query);
      this.loading = false;
    } catch (error) {
      this.msg = { message: `${error.message}`, show: true };
    }
  },
};
</script>

<style>
</style>

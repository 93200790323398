<template>
  <v-card>
    <v-container fluid grid-list-xl>
      <v-layout row wrap>
        <v-flex md6>
          <v-text-field v-model="form.name" label="Form Name" :rules="[rules.required]">
          </v-text-field>
        </v-flex>

        <v-flex md6>
          <v-select
            v-model="form.setup.recordtype"
            hint="Select Record Type"
            :items="recordtypes"
            item-text="name"
            item-value="type"
            label="Select"
            persistent-hint
            single-line
            :rules="[rules.required]"
          ></v-select>
          <v-select
            v-model="form.setup.collection"
            hint="Select Collection"
            :items="collections"
            item-text="name"
            item-value="type"
            label="Select"
            persistent-hint
            single-line
            :rules="[rules.required]"
          ></v-select>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
/* eslint-disable */
export default {
  props: ["rid", "mode", "form"],
  data() {
    return {
      recordtypes: [
        { name: "Employee", type: "employee" },
        { name: "Customer", type: "customer" },
        { name: "Address", type: "address" }
      ],
      collections: [
        { name: "Queries", type: "queries" },
        { name: "Users", type: "users" },
        { name: "Addresses", type: "addresses" }
      ],
      rules: {
        required: value => !!value || "Required."
      }
    };
  }
};
</script>

 <template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          loading-text="Loading... Please wait"
          item-key="_id"
          :search="search"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
        >
          <template v-slot:item.date="{ item }">
            <span>{{
              item.date
                ? new Date(
                    new Date(item.date).getTime() -
                      new Date(item.date).getTimezoneOffset() * 60000
                  )
                    .toISOString()
                    .substr(0, 10) +
                  " " +
                  new Date(
                    new Date(item.date).getTime() -
                      new Date(item.date).getTimezoneOffset() * 60000
                  )
                    .toISOString()
                    .substr(11, 5)
                : ""
            }}</span>
          </template>
          <template v-slot:item.subrecord="{ item }">
            <v-chip label :color="item.subrecord ? 'red' : 'none'" dark>{{
              item.subrecord ? "Yes" : "No"
            }}</v-chip>
          </template>
          <template v-slot:item.oldvalue="{ item }">
            <v-chip
              label
              :color="item.oldvalue ? 'red' : 'none'"
              dark
              v-if="item.oldvalue === true || item.oldvalue === false"
              >{{ item.oldvalue ? "Yes" : "No" }}</v-chip
            >
            <span v-else>{{ item.oldvalue }}</span>
          </template>
          <template v-slot:item.value="{ item }">
            <v-chip
              label
              :color="item.value ? 'red' : 'none'"
              dark
              v-if="item.value === true || item.value === false"
              >{{ item.value ? "Yes" : "No" }}</v-chip
            >
            <span v-else>{{ item.value }}</span>
          </template>
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col cols="2">
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col> </v-col>
              </v-row>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
export default {
  props: ["record"],
  data() {
    return {
      loading: false,
      headers: [
        { text: "Date", value: "date" },
        { text: "User", value: "user" },
        { text: "Sublist/record", value: "parentfield" },
        { text: "Field", value: "field" },
        { text: "Old Value", value: "oldvalue" },
        { text: "New Value", value: "value" },
      ],
      items: [],
      rows: [100],
      search: "",
      show: false,
    };
  },
  async created() {
    this.loading = true;
    await this.getData();
    this.loading = false;
  },
  methods: {
    async getData() {
      let result = await service(
        "orders",
        {
          action: "getLogs",
          record: this.record,
        },
        false,
        true
      );
      this.items = result.items;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
  },
};
</script>
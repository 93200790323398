var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[(_vm.items.length)?_c('v-container',[_c('v-row',[_c('v-col',[_c(_vm.type,{key:_vm.timeunit,tag:"component",attrs:{"option":_vm.options,"chartData":_vm.toChartData(_vm.type, _vm.items)}})],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{staticClass:"mt-2 mb-2",attrs:{"items":_vm.headers.filter(function (header) {
              if (['barchart', 'linechart'].includes(_vm.type)) {
                return (
                  _vm.fieldsObj[header.field] &&
                  _vm.fieldsObj[header.field].setup.type == 'field-date'
                );
              } else {
                return (
                  _vm.fieldsObj[header.field] &&
                  !['field-int', 'field-currency', 'field-date'].includes(
                    _vm.fieldsObj[header.field].setup.type
                  )
                );
              }
            }),"label":"X axis","item-text":"text","item-value":"value","outlined":"","clearable":"","hide-details":"","dense":""},model:{value:(_vm.xaxis),callback:function ($$v) {_vm.xaxis=$$v},expression:"xaxis"}})],1),_c('v-col',[_c('v-select',{staticClass:"mt-2 mb-2",attrs:{"items":_vm.headers.filter(function (header) {
              return (
                _vm.fieldsObj[header.field] &&
                header.summary &&
                ['field-int', 'field-currency'].includes(
                  _vm.fieldsObj[header.field].setup.type
                )
              );
            }),"label":"Y axis","item-text":"text","item-value":"value","outlined":"","clearable":"","hide-details":"","dense":"","multiple":""},model:{value:(_vm.yaxis),callback:function ($$v) {_vm.yaxis=$$v},expression:"yaxis"}})],1),_c('v-col',[_c('v-select',{staticClass:"mt-2 mb-2",attrs:{"items":_vm.types,"label":"Chart Type","item-text":"text","item-value":"value","outlined":"","clearable":"","hide-details":"","dense":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('v-icon',[_vm._v(_vm._s(_vm.show ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1)],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}]},[_c('v-container',[_c('v-row',[_c('v-col',[(['barchart', 'linechart'].includes(_vm.type))?_c('v-select',{staticClass:"mt-2 mb-2",attrs:{"disabled":"","items":_vm.timeunits,"label":"Time Unit","item-text":"text","item-value":"value","outlined":"","clearable":"","hide-details":"","dense":""},model:{value:(_vm.timeunit),callback:function ($$v) {_vm.timeunit=$$v},expression:"timeunit"}}):_vm._e()],1),_c('v-col',[_c('v-select',{staticClass:"mt-2 mb-2",attrs:{"items":_vm.headers.filter(function (header) {
                    return (
                      _vm.fieldsObj[header.field] &&
                      ![
                        'field-int',
                        'field-currency',
                        'field-date',
                        'field-percent' ].includes(_vm.fieldsObj[header.field].setup.type)
                    );
                  }),"label":"Data Sets","item-text":"text","item-value":"value","outlined":"","clearable":"","hide-details":"","dense":""},model:{value:(_vm.datasets),callback:function ($$v) {_vm.datasets=$$v},expression:"datasets"}})],1),_c('v-col')],1)],1)],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-select
    v-model="country"
    :items="countries"
    item-text="name"
    item-value="code"
    :label="label"
    placeholder
    :return-object="recordObj"
    :rules="[rules.required]"
    :readonly="$route.params.mode=='view'|| record.archived"
    outlined
    dense
  ></v-select>
</template>

<script>
import { getData } from "country-list";
/* eslint-disable */
export default {
  props: ["value", "record", "fieldSettings", "formSettings"],
  data() {
    return {
      countries: getData(),
      country: this.value || null,
      recordObj: true,
      required:
        this.formSettings.required ||
        this.fieldSettings.setup.required ||
        false,
      field: this.formSettings.field || this.formSettings.setup.field,
      label: this.formSettings.name || this.fieldSettings.name,
      rules: {
        required: value => (this.required ? !!value || "Required." : true)
      }
    };
  },

  watch: {
    country(value) {
      this.$emit("emit", { field: this.field, value: value });
    },
    record: {
      handler: async function(after, before) {
        console.log(this.record[this.field], this.country);
        if (this.record[this.field].code != this.country.code)
          if (this.record[this.field]) this.country = this.record[this.field];
          else this.country = false;
      },
      deep: true
    }
  }
};
</script>

 <template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          loading-text="Loading... Please wait"
          :loading="loading"
          item-key="_id"
          v-model="selected"
          show-select
          :search="search"
          :single-select="singleSelect"
          :footer-props="{itemsPerPageOptions:rows}"
          :custom-filter="filter"
        >
          <template v-slot:item.location="{ item }">
            <router-link
              :to="{path: `/${item.location.collection}/${item.location.recordtype}/view/${item.location._id}`}"
              class="link"
            >{{ item.location.displayname||item.location.name }}</router-link>
          </template>
          <template v-slot:item.salesorder="{ item }">
            <router-link
              :to="{path: `/${item.salesorder.collection}/${item.salesorder.recordtype}/view/${item.salesorder._id}`}"
              class="link"
            >{{ item.salesorder.displayname||item.salesorder.name }}</router-link>
          </template>
          <template v-slot:item.customer="{ item }">
            <router-link
              :to="{path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`}"
              class="link"
            >{{ item.customer.displayname||item.customer.name }}</router-link>
          </template>
          <template v-slot:item.deliverymethod="{ item }">
            <span>{{item.deliverymethod? item.deliverymethod.displayname||item.deliverymethod.name : "" }}</span>
          </template>
          <template v-slot:item.item="{ item }">
            <router-link
              :to="{path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`}"
              class="link"
            >{{ item.item.displayname||item.item.name }}</router-link>
          </template>
          <template v-slot:item.po="{ item }">
            <router-link
              v-for="po in item.po"
              :key="po._id"
              :to="{path: `/${po.collection}/${po.recordtype}/view/${po._id}`}"
              class="link"
            >{{ po.displayname||po.name }}</router-link>
          </template>
          <template v-slot:footer>
            <v-divider></v-divider>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="text-right">
                  <v-btn icon @click="show = !show">
                    <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-expand-transition>
                <div v-show="show">
                  <v-divider></v-divider>
                  <v-row>
                    <v-col>
                      <v-list>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-subtitle>Quantity:</v-list-item-subtitle>
                            <v-list-item-title>{{ quantity }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-subtitle>Weight:</v-list-item-subtitle>
                            <v-list-item-title>{{ weight }} kg</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-subtitle>Amount:</v-list-item-subtitle>
                            <v-list-item-title>{{ amount }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="vendors"
                        v-model="vendor"
                        label="Vendor"
                        item-text="displayname"
                        item-value="_id"
                        return-object
                        outlined
                        dense
                      ></v-select>
                      <v-select
                        :items="deliverymethods"
                        v-model="deliverymethod"
                        label="Delivery Method"
                        item-text="displayname"
                        item-value="_id"
                        return-object
                        outlined
                        dense
                      ></v-select>
                      <v-btn @click="action" block  tile color="primary">Purchase Order</v-btn>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="address.shipaddressee"
                        label="Addressee"
                        outlined
                        dense
                      ></v-text-field>
                      <v-text-field v-model="address.shipaddress" label="Address" outlined dense></v-text-field>
                      <v-text-field v-model="address.shipcity" label="City" outlined dense></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field v-model="address.shipzip" label="Zip" outlined dense></v-text-field>
                      <v-autocomplete
                        :items="countries"
                        v-model="address.shipcountry"
                        label="Counrty"
                        item-text="displayname"
                        item-value="_id"
                        return-object
                        outlined
                        dense
                      ></v-autocomplete>
                      <v-text-field v-model="address.shipphone" label="Phone" outlined dense></v-text-field>
                    </v-col>

                    <v-col>
                      <v-textarea outlined label="Message" v-model="message"></v-textarea>
                    </v-col>
                  </v-row>
                </div>
              </v-expand-transition>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import getDropshipOrders from "./getDropshipOrders";
export default {
  data() {
    return {
      loading: false,
      headers: [
        { text: "Location", value: "location", sortable: false },
        { text: "Date", value: "date", sortable: false },
        { text: "Sales Order", value: "salesorder", sortable: false },
        { text: "Customer", value: "customer", sortable: false },
        { text: "Item", value: "item", sortable: false },
        { text: "Available", value: "available", sortable: false },
        { text: "Group", value: "stockgroup", sortable: false },
        { text: "Category", value: "stockcategory", sortable: false },
        { text: "Quantity", value: "quantity", sortable: false },
        { text: "Ordered", value: "quantityonorder", sortable: false },
        { text: "Delivery Method", value: "deliverymethod", sortable: false },
        { text: "Memo", value: "memo", sortable: false },
        { text: "Created PO", value: "po", sortable: false }
      ],
      items: [],
      rows: [100],
      singleSelect: false,
      selected: [],
      search: "",
      show: false,
      vendor: null,
      vendors: [],
      message:
        "Please send this directly to our customer to the address below. Please use your forwarder for that shipment. Once shipped please send us tracking number and invoice. Thank you.",
      countries: [],
      address: {},
      deliverymethod: null,
      deliverymethods: [],
      quantity: 0,
      weight: 0,
      amount: 0
    };
  },
  async created() {
    this.loading = true;
    this.items = await getDropshipOrders();
    let items = [];
    this.items.forEach(line => {
      items.push(line.item._id);
    });
    let vendors = await service("items_vendor", {
      action: "find",
      data: [{ item: { $in: items } }]
    }, false, true).then(response => {
      let ids = [];
      response.forEach(v => {
        ids.push(v.customer);
      });
      return ids;
    });

    this.vendors = await service("users", {
      action: "find",
      data: [{ _id: { $in: vendors } }]
    }, false, true).then(response => {
      response.forEach(v => {
        v.displayname = v.displayname || v.name;
      });
      return response;
    });

    this.deliverymethods = await service("lists", {
      action: "find",
      data: [{ recordtype: "deliverymethod" }]
    }, false, true).then(response => {
      response.forEach(v => {
        v.displayname = v.displayname || v.name;
      });
      return response;
    });

    this.countries = await service("countries", {
      action: "find",
      data: [{}]
    }, false, true).then(response => {
      response.forEach(v => {
        v.displayname = v.displayname || v.name;
      });
      return response;
    });
    this.loading = false;
  },
  watch: {
    async vendor() {
      await this.recalctotal();
    },
    async deliverymethod() {
      await this.recalctotal();
    },
    async selected(value) {
      if (value[0]) {
        let so = value[0].salesorder;
        this.address.shipaddressee = so.shipaddressee;
        this.address.shipaddress = so.shipaddress;
        this.address.shipcity = so.shipcity;
        this.address.shipzip = so.shipzip;
        this.address.shipcountry = so.shipcountry;
        this.address.shipphone = so.shipphone;
        this.deliverymethod = so.deliverymethod;
      } else this.address = {};
      await this.recalctotal();
    }
  },
  methods: {
    filter(value, search, item) {
      return filter(value, search, item);
    },
    async recalctotal() {
      let weight = 0;
      let amount = 0;
      let quantity = 0;
      let currency = "";
      for (let [index, line] of this.selected.entries()) {
        console.log(line);
        weight += line.quantity * line.weight || 0;
        quantity += line.quantity;
        if (this.vendor) {
          let critery = {
            customer: this.vendor._id,
            item: line.item._id
          };
          if (currency) critery.currency = currency;
          if (this.deliverymethod) critery.deliverymethod = this.deliverymethod;
          console.log(critery);
          let vendorline = await service("items_vendor", {
            action: "findOne",
            data: [critery]
          }, false, true).catch(error => {
            this.selected.splice(index, 1);
            alert(
              `${line.item.displayname ||
                line.item.name} nie ma ceny dla powyższych ustawień`
            );
          });
          if (vendorline) {
            if (!currency) currency = vendorline.currency;
            amount += line.quantity * vendorline.purchaseprice;
          }
        }
      }
      this.weight = weight.toFixed(2);
      this.amount = amount.toFixed(2) + " " + currency.toUpperCase();
      this.quantity = quantity;
    },
    action() {
      let lines = [];
      let location = null;
      let salesorder = null;
      let error = false;
      this.selected.forEach(select => {
        if (location && select.location._id != location) {
          alert("You cannot select more locations");
          error = true;
        }
        location = select.location._id;
        if (salesorder && select.salesorder._id != salesorder) {
          alert("You cannot select more Sales Oreder");
          error = true;
        }
        salesorder = select.salesorder._id;
        let line = {
          item: select.item,
          createdfromline: select._id,
          createdfromtransaction: select.salesorder._id,
          quantity: select.quantity
        };
        lines.push(line);
      });
      if (!error)
        this.$router.push({
          name: "Transactions",
          params: {
            recordtype: "purchaseorder",
            mode: "create",
            transactionlines: lines,
            location: location,
            message: this.message,
            customer: this.vendor,
            shipaddressee: this.address.shipaddressee,
            shipaddress: this.address.shipaddress,
            shipcity: this.address.shipcity,
            shipzip: this.address.shipzip,
            shipcountry: this.address.shipcountry,
            shipphone: this.address.shipphone,
            deliverymethod: this.deliverymethod
          }
        });
    }
  }
};
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-tabs',{attrs:{"dark":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('v-tab',{key:1},[_vm._v("Sent Emails")]),(
            ['users', 'transactions'].includes(
              _vm.record.collection
            )
          )?_c('v-tab',{key:2},[_vm._v("Notes")]):_vm._e(),_c('v-tab',{key:3},[_vm._v("Inbox")]),_c('v-tab-item',{key:1},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.sentemails,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","search":_vm.search,"footer-props":{ itemsPerPageOptions: _vm.rows }},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
          var item = ref.item;
return [(item.created)?_c('span',[_vm._v(_vm._s(item.date ? new Date( new Date(item.date).getTime() - new Date(item.date).getTimezoneOffset() * 60000 ) .toISOString() .substr(0, 10) + " " + new Date( new Date(item.date).getTime() - new Date(item.date).getTimezoneOffset() * 60000 ) .toISOString() .substr(11, 5) : ""))]):_c('span',[_vm._v(_vm._s(item.date))])]}},{key:"header.text",fn:function(ref){
          var header = ref.header;
return undefined}},{key:"item.text",fn:function(ref){
          var item = ref.item;
return undefined}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mail")])]}},{key:"item.to",fn:function(ref){
          var item = ref.item;
return [_vm._v(_vm._s(item.to.toString()))]}},{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('reademail',{attrs:{"uid":_vm.uid,"record":_vm.record,"emailaddress":_vm.email,"box":_vm.box},on:{"emit":_vm.catchEmit}})],1)],1)],1)]},proxy:true}])}),(_vm.record)?_c('writeemail',{attrs:{"record":_vm.record,"label":"Compose Email"}}):_vm._e()],1),(
            ['users', 'transactions'].includes(
              _vm.record.collection
            )
          )?_c('v-tab-item',{key:2},[_c('tool-activities',{attrs:{"record":_vm.record}})],1):_vm._e(),_c('v-tab-item',{key:3},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"header.text",fn:function(ref){
          var header = ref.header;
return undefined}},{key:"item.text",fn:function(ref){
          var item = ref.item;
return undefined}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mail")])]}},{key:"top",fn:function(){return [_c('reademail',{attrs:{"uid":_vm.uid,"emailaddress":_vm.email,"box":_vm.box},on:{"emit":_vm.catchEmit}})]},proxy:true},{key:"footer",fn:function(){return [_c('v-divider'),_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('exportData',{attrs:{"headers":_vm.headers,"items":_vm.items}})],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('v-icon',[_vm._v(_vm._s(_vm.show ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1)],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}]},[_c('v-divider'),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.emails,"label":"Email","item-text":"name","item-value":"_id","outlined":"","required":"","dense":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-col',[(_vm.record)?_c('writeemail',{attrs:{"record":_vm.record,"label":"Compose"}}):_vm._e()],1)],1)],1)])],1)]},proxy:true}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-layout row wrap>
    <v-flex md6 >
    <v-date-picker
      v-model="date"
      full-width
      landscape
      class="mt-3"
    ></v-date-picker>
    </v-flex>
    <v-flex md6 class="my-2 px-1">

    </v-flex>
  </v-layout>
</template>

<script>

export default {
  data: () => ({
    date: new Date().toISOString().substr(0, 10)
  
  }),


};
</script>
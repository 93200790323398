 <template>
  <div>
    <v-row>
      <v-col>
        <v-tabs dark v-model="active">
          <v-tab v-for="(tab, index) of Object.values(tabs)" :key="index">
            <v-badge color="primary">
              <span>{{ tab.name }}</span>
              <span slot="badge">{{ tab.items.length }}</span>
            </v-badge>
          </v-tab>

          <v-tab-item v-for="(tab, index) of Object.values(tabs)" :key="index">
            <v-data-table
              :headers="tab.headers"
              :items="tab.items"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              v-model="selected"
              :show-select="false"
              :search="search"
              :single-select="singleSelect"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
              :custom-sort="sort"
              @current-items="getFiltered"
              :item-class="rowClass"
            >
              <template v-slot:item.payment_done="{ item }">
                <v-chip label :color="item.payment_done ? 'green' : 'red'" dark>{{
                  item.payment_done ? "Paid" : "Unpaid"
                }}</v-chip>
              </template>
              <template v-slot:item.customer="{ item }">
                <div>
                  <div v-if="item.transaction">
                    <router-link
                      :to="{
                        path: `/users/customer/view/${item.transaction.customer._id}`,
                      }"
                      class="link"
                      >{{
                        item.transaction.customer.displayname ||
                        item.transaction.customer.name
                      }}</router-link
                    >
                  </div>
                  <div v-else>{{ item.customer }}</div>

                  <p v-if="item.email">{{ item.email }}</p>
                  <v-text-field
                    v-else
                    placeholder="email"
                    class="mt-0 mb-4"
                    flat
                    error
                    dense
                    hide-details
                    v-model="item.email"
                    @change="setField(item, 'email')"
                  />
                  <div>{{ item.source }}</div>
                  <div>{{ item.date }}</div>
                </div>
              </template>
              <template v-slot:item.order_status_id="{ item }">
                <div>
                  <v-alert
                    dense
                    type="error"
                    v-if="item.validation && item.validation.length"
                    ><div v-for="(dif, index) of item.validation" :key="index">
                      {{ dif.field }}
                    </div></v-alert
                  >
                  <v-alert
                    dense
                    :type="item.transaction ? 'success' : 'warning'"
                    v-else
                  >
                    {{ !item.transaction ? "Not Connected" : "OK" }}
                  </v-alert>
                  <v-select
                    :items="statuses"
                    v-model="item.order_status_id"
                    label="Status"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    hide-details
                    class="mt-2 mb-2"
                    @change="setStatus(item)"
                  ></v-select>
                </div>
              </template>
              <template v-slot:item.order_id="{ item }">
                <div>
                  <p>
                    <a :href="item.order_page" target="_blank" class="link">{{
                      item.order_id
                    }}</a>
                  </p>
                  <div>{{ item.payment_method }}</div>
                  <div>{{ item.delivery_method }}</div>
                </div>
              </template>

              <template v-slot:item.transaction="{ item }">
                <div v-if="item.transaction">
                  <router-link
                    :to="{
                      path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                    }"
                    class="link"
                    >{{
                      item.transaction.displayname || item.transaction.name
                    }}</router-link
                  >
                  <p>{{ item.transaction.status }}</p>
                </div>
                <v-btn
                  tile
                  v-else
                  color="primary"
                  text
                  @click="create(item)"
                  :disabled="loading2"
                  >Create an Order</v-btn
                >
              </template>
              <template v-slot:item.transactions="{ item }">
                <router-link
                  v-for="transaction of item.transactions"
                  :key="transaction._id"
                  :to="{
                    path: `/${transaction.collection}/${transaction.recordtype}/view/${transaction._id}`,
                  }"
                  class="link"
                  >{{
                    transaction.displayname || transaction.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.products="{ item }">
                <div v-for="(product, index) of item.products" :key="index">
                  <router-link
                    v-if="product.item"
                    :to="{
                      path: `/items/${product.item.recordtype}/view/${product.item._id}`,
                    }"
                    class="link"
                    >{{ product.quantity }}x
                    {{
                      product.item.displayname || product.item.name
                    }}</router-link
                  >
                  <div v-else>{{ product.quantity }}x {{ product.name }}</div>
                </div>
              </template>
              <template v-slot:item.admin_comments="{ item }">
                <div class="my-3">
                  {{ item.user_comments }}
                  <v-textarea
                    outlined
                    hide-details
                    dense
                    label="Internal Comment"
                    class="mt-2"
                    @change="setField(item, 'admin_comments')"
                    v-model="item.admin_comments"
                    :value="item.admin_comments"
                  />
                </div>
              </template>
              <!-- <template v-slot:item.quantitycommitted="{ item }">
               <v-chip v-if="item.transaction" :color="item.transaction.quantitycommitted==(item.transaction.quantity-item.transaction.quantitytopack)?'green':'yellow'">{{item.transaction.quantitycommitted}}</v-chip>
              </template> -->
              <template v-slot:top>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <exportData :headers="headers" :items="items" />
                    </v-col>
                    <v-col> </v-col>
                    <v-col
                      ><v-btn
                        @click="getData()"
                        block
                        tile
                        color="primary"
                        :disabled="loading"
                        >Refresh</v-btn
                      ></v-col
                    >
                    <v-spacer></v-spacer>
                    <!-- <v-col>
                  <v-btn @click="action" block tile color="primary">Ready To Pack</v-btn>
                    </v-col>-->
                    <v-col class="text-right" v-if="false">
                      <v-btn icon @click="show = !show">
                        <v-icon>{{
                          show ? "mdi-chevron-up" : "mdi-chevron-down"
                        }}</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs>
      </v-col></v-row
    >
  </div>
</template>
   
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";
import sort from "../../dataTable/sort";
export default {
  data() {
    return {
      loading: false,
      loading2: false,
      headers: [
        { text: "Customer/Source/Date", value: "customer" },
        { text: "Order/Payment/Shipping", value: "order_id" },
        { text: "Payment Status", value: "payment_done" },

        { text: "Products", value: "products" },
        //{ text: "Comment", value: "user_comments" },
        { text: "Comments", value: "admin_comments", width: "400px" },
        { text: "Baselinker Status", value: "order_status_id" },
        { text: "Sales Order", value: "transaction" },

        //{ text: "Qty. Committed", value: "quantitycommitted" },
        { text: "Transactions", value: "transactions" },
      ],

      menu: false,
      items: [],
      itemsPL: [],
      itemsUK: [],
      itemsIC: [],
      rows: [-1, 50, 100],
      singleSelect: false,
      selected: [],
      search: "",
      statuses: [],
      date: new Date().toISOString().substr(0, 10),
      locationsFilter: [],
      tabs: {},
    };
  },
  async created() {
    this.loading = true;
    await this.getStatuses();
    this.tabs = {
      All: {
        name: "Wszystkie",
        items: [],
        headers: this.headers,
      },
    };

    await this.getData();

    this.loading = false;
  },
  watch: {},

  methods: {
    rowClass(item) {
      if (
        ((item.paymentstatusid && item.paymentstatusid != "fullypaid") ||
          !item.paymentstatusid) &&
        item.terms == "Prepaid"
      ) {
        return "unpaid";
      }
    },
    getFiltered(e) {
      this.filteredItems = e;
    },
    async create(item) {
      try {
        this.loading2 = true;
        let result = await service(
          "baselinker",
          {
            action: "baselinker_createOrder",
            order: item.order_id,
          },
          false,
          false
        );
        this.$router.push({
          path: `/transactions/salesorder/view/${result}`,
        });
        await this.getData();
      } catch (err) {
        alert(err.message);
      }
      this.loading2 = false;
    },
    async getStatuses() {
      try {
        this.statuses = await service(
          "baselinker",
          {
            action: "baselinker_getStatuses",
          },
          false,
          true
        );
      } catch (err) {
        alert(error);
      }
    },
    async setField(order, field) {
      //this.loading = true;
      try {
        let result = await service(
          "baselinker",
          {
            action: "baselinker_setField",
            order: order.order_id,
            field: field,
            value: order[field],
          },
          false,
          true
        );
      } catch (err) {
        item.warring = err;
      }
      //await this.getData();
      //this.loading = false;
    },
    async setStatus(order) {
      //this.loading = true;
      try {
        let result = await service(
          "baselinker",
          {
            action: "baselinker_setStatus",
            order: order.order_id,
            status: order.order_status_id,
          },
          false,
          true
        );
      } catch (err) {
        item.warring = err;
      }
      //await this.getData();
      //this.loading = false;
    },
    async getData() {
      this.loading = true;
      this.items = [];
      this.tabs["All"].items = [];
      let result = await service(
        "baselinker",
        {
          action: "getBaselinkerOrders",
        },
        false,
        true
      );
      result.items.forEach((item) => {
        item.shippingaddress =
          item.shippingaddress || item.shipaddressee || item.shipaddress;
      });

      result.items.forEach((item) => {
        this.tabs["All"].items.push(item);
      });
      //console.log(result.items)
      this.items = result.items;

      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    sort(items, sortBy, sortDesc) {
      return sort(items, sortBy, sortDesc);
    },
  },
  components: {
    exportData,
  },
};
</script>
<style>
.otherlocation {
  background: #ececec;
}
</style>
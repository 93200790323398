<template>
  <v-menu bottom right>
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon>mdi-file-export</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="exportData('csv')">
        <v-list-item-icon>
          <v-icon>mdi-file-document-box-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>CSV</v-list-item-title>
      </v-list-item>
    </v-list>
    <v-divider />
    <v-list>
      <v-list-item @click="exportData('xls')">
        <v-list-item-icon>
          <v-icon>mdi-file-table-box-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>XLS</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import exportFromJSON from "export-from-json";
import getFields from "../../common/methods/getFields";
export default {
  props: ["items", "headers"],
  data() {
    return {
      loading: false,
      fields: {},
    };
  },
  methods: {
    async exportData(exportType) {
      this.loading = true;

      this.fields = await getFields(this);
      console.log(this.headers)
      let data = [];
      let headerRow = [];
      this.headers.forEach((header) => {
        headerRow.push(header.text);
      });
      this.items.forEach((item) => {
        let row = {};
        //if (item._id) row["id"] = item._id;
        this.headers.forEach((header) => {
          if (header.value) {
            row[header.text] = this.getValue(
              item[header.value],
              this.fields[header.field || header.value],
              header.function
            );
          }
        });
        data.push(row);
      });

      if (exportType == "csv") {
        this.downloadCSV(data, this.headers, "export.csv");
      } else {
        this.downloadXLS(data, this.headers, "export.xls");
      }

      this.loading = false;
    },
    downloadCSV(data, header, name) {
      const replacer = (key, value) =>
        value === null || value === "undefined" ? "" : value; // specify how you want to handle null values here
      let csv = data.map((row) =>
        header
          .map((value) =>
            JSON.stringify(row[value.text], replacer).replace(/\\"/g, '""')
          )
          .join(",")
      );
      csv.unshift(header.map((header) => header.text).join(","));
      csv = csv.join("\r\n");
      csv = "sep=,\r\n" + csv;
      const BOM = "\ufeff";
      const content = true ? BOM + csv : csv;
      this.downloadFile(csv, "csv", name);
    },
    downloadXLS(data, header, name) {
        console.log(this.fields)
      const columns = data.map((row) =>
        header.map(
          (value) =>
            `<Cell ss:StyleID="s__text"><Data ss:Type="String">${row[value.text].replace(
              /[\u00A0-\u9999<>\&]/gim,
              function (i) {
                return "&#" + i.charCodeAt(0) + ";";
              }
            )}</Data></Cell>`
        )
      );

      let rows = columns.map((row) => row.join(""));

      let tr = `
<Worksheet ss:Name="CustomItemSearchResults">
<Table>
      <Row><Cell ss:StyleID="header"><Data ss:Type="String">${header
        .map((header) => header.text)
        .join(
          '</Data></Cell><Cell ss:StyleID="header"><Data ss:Type="String">'
        )}</Data></Cell></Row>
      <Row>${rows.join("</Row><Row>")}</Row>
    </Table>
</Worksheet>


    
  `;
      let xls = `
  <?xml version="1.0" encoding="utf-8"?>
 <Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet"
 xmlns:o="urn:schemas-microsoft-com:office:office"
 xmlns:x="urn:schemas-microsoft-com:office:excel"
 xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet"
 xmlns:html="http://www.w3.org/TR/REC-html40">
<DocumentProperties xmlns="urn:schemas-microsoft-com:office:office">
<Author>3C ERP</Author>
<LastAuthor>3C ERP</LastAuthor>
<Company>3C ERP</Company>
</DocumentProperties>
<Styles>
<Style ss:ID="company">
<Alignment ss:Horizontal="Center"/>
<Font ss:Bold="1"/>
</Style> <Style ss:ID="error">
<Alignment ss:Horizontal="Center"/>
<Interior ss:Color="#d04040" ss:Pattern="Solid"/>
<Font ss:Bold="1"/>
</Style> <Style ss:ID="header">
<Alignment ss:Horizontal="Center"/>
<Font ss:Size="7" ss:Bold="1"/>
<Interior ss:Color="#d0d0d0" ss:Pattern="Solid"/>
</Style> <Style ss:ID="Default" ss:Name="Normal">
<Alignment ss:Vertical="Bottom"/>
<Borders/>
<Font ss:FontName="Arial" ss:Size="8"/>
<Interior/>
<NumberFormat/>
<Protection/>
</Style>
<Style ss:ID="s__TIMEOFDAY"><NumberFormat ss:Format="Medium Time"/></Style>
<Style ss:ID="s__DATETIME"><NumberFormat ss:Format="General Date"/></Style>
<Style ss:ID="s__DATETIMETZ"><NumberFormat ss:Format="General Date"/></Style>
<Style ss:ID="s__DATE"><NumberFormat ss:Format="Short Date"/>
</Style><Style ss:ID="s__text"></Style><Style ss:ID="s__currency"><NumberFormat ss:Format="Currency"/></Style>
<Style ss:ID="s__percent"><NumberFormat ss:Format="Percent"/></Style>
<Style ss:ID="s1_b_text"><Alignment ss:Indent="1"/><Font ss:FontName="Verdana" x:Family="Swiss" ss:Size="8" ss:Color="#000000" ss:Bold="1"/></Style>
<Style ss:ID="s_b_text"><Font ss:FontName="Verdana" x:Family="Swiss" ss:Size="8" ss:Color="#000000" ss:Bold="1"/></Style>
<Style ss:ID="s2__text"><Alignment ss:Indent="2"/></Style>
<Style ss:ID="s_b_currency"><Font ss:FontName="Verdana" x:Family="Swiss" ss:Size="8" ss:Color="#000000" ss:Bold="1"/><NumberFormat ss:Format="Currency"/></Style>
<Style ss:ID="s_currency_nosymbol"><Font ss:FontName="Verdana" x:Family="Swiss" ss:Size="8" ss:Color="#000000" /><NumberFormat ss:Format="#,##0.00_);[Red]\(#,##0.00\)"/></Style>
<Style ss:ID="s1__text"><Alignment ss:Indent="1"/></Style>
<Style ss:ID="s_b_currency_X"><Font ss:FontName="Verdana" x:Family="Swiss" ss:Size="8" ss:Color="#000000" ss:Bold="1"/><Interior ss:Color="#f0e0e0" ss:Pattern="Solid"/><NumberFormat ss:Format="Currency"/></Style>
<Style ss:ID="s__currency_pl_PL"><Alignment ss:Vertical="Center" ss:Horizontal="Right"/><NumberFormat ss:Format="&quot;Zl&quot;#,##0.00_);(&quot;Zl&quot;#,##0.00)"/></Style>
<Style ss:ID="s__currency_en_GB"><Alignment ss:Vertical="Center" ss:Horizontal="Right"/><NumberFormat ss:Format="&quot;£&quot;#,##0.00_);(&quot;£&quot;#,##0.00)"/></Style>
<Style ss:ID="s__currency_en_AU"><Alignment ss:Vertical="Center" ss:Horizontal="Right"/><NumberFormat ss:Format="&quot;$A&quot;#,##0.00_);(&quot;$A&quot;#,##0.00)"/></Style>
<Style ss:ID="s__currency_fr_FR_EURO"><Alignment ss:Vertical="Center" ss:Horizontal="Right"/><NumberFormat ss:Format="&quot;€&quot;#,##0.00_);(&quot;€&quot;#,##0.00)"/></Style>
</Styles>
${tr}
</Workbook>
  `;
      this.downloadFile(xls, "xls", name);
    },
    getValue(value, field = null, fn) {
      if (value) {
        //console.log(value,typeof value)
        if (value.name || value.displayname)
          return value.displayname || value.name;
        if (
          value instanceof Date ||
          (field && field.setup.type == "field-date")
        ) {
          if (!fn) return new Date(`${value}`).toISOString().substr(0, 10);
          else return value;
        }
        if (
          typeof value === "boolean" ||
          (field && field.setup.type == "field-date")
        )
          return value ? "YES" : "NO";
        if (
          (typeof value === "number" ||
            (field && field.setup.type == "field-currency") ||
            !isNaN(value)) &&
          !isNaN(value)
        )
          return parseFloat(value)
            .toFixed((field && field.setup.precision) || 2)
            .replace(".", ",");
        if (Array.isArray(value)) {
          let newvalue = "";
          value.forEach((v, i) => {
            v = this.getValue(v);
            newvalue += i ? `, ${v}` : v;
          });
          return newvalue;
        }
        return value.replace(/\\"/g, '""');
      }
      return "";
    },

    generateDataURI(content, type) {
      switch (type) {
        case "txt": {
          return "data:text/plain;charset=utf-8," + encodeURIComponent(content);
        }
        case "json": {
          return (
            "data:application/json;charset=utf-8," + encodeURIComponent(content)
          );
        }
        case "csv": {
          return (
            "data:text/csv;charset=utf-8,\uFEFF" + encodeURIComponent(content)
          );
        }
        case "xls": {
          return (
            "data:application/vnd.ms-excel;charset=utf-8," +
            encodeURIComponent(content)
          );
        }
        default: {
          return "";
        }
      }
    },

    downloadFile(content, type, fileName = "download") {
      console.log(content, type);
      const dataURI = this.generateDataURI(content, type);

      const anchor = document.createElement("a");
      anchor.href = dataURI;

      anchor.download = fileName;
      anchor.setAttribute("style", "visibility:hidden");

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    },
  },
};
</script>


import service from '../../api/index';
/* eslint-disable */
export function getInbox(context, rid, customer) {
  let data = {
    action: 'getInbox',
    rid: rid,
    customer
  };
  return service(false, data)
    .catch(error => {
      throw error;
    })
    .then(response => {
      return response.emails;
    })
    .catch(error => {
      context.msg = { message: `${error.message}`, show: true };
      //context.$router.push({ path: `/${collection}/${recordtype}/` });
    });
}
export function getEmail(context, rid, uid, box) {
  let data = {
    action: 'getEmail',
    rid: rid,
    uid: uid,
    box: box,
  };
  return service(false, data)
    .catch(error => {
      throw error;
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      context.msg = { message: `${error.message}`, show: true };
      //context.$router.push({ path: `/${collection}/${recordtype}/` });
    });
}

export function sendEmail(context, email) {
  let data = {
    action: 'sendEmail',
    conf: email,
  };
  return service(false, data)
    .catch(error => {
      throw error;
    })
    .then(response => {
      //if (response.message) context.msg = { message: response.message, show: true };
      return response;
    })
    .catch(error => {
      context.msg = { message: `${error.message}`, show: true };
      //context.$router.push({ path: `/${collection}/${recordtype}/` });
    });
}

<template>
  <div>
    <v-autocomplete
      v-model="recordSelected"
      :items="records"
      item-text="displayname"
      item-value="_id"
      :label="label"
      placeholder
      :multiple="multiple"
      :rules="[rules.required]"
      :return-object="recordObj"
      small-chips
      :readonly="
        ($route.params.mode == 'view' ||
          this.formSettings.disabled ||
          this.fieldSettings.disabled == true ||
          record.archived ||
          loading) &&
        !this.formSettings.show
      "
      :disabled="
        (this.formSettings.disabled || this.fieldSettings.disabled == true) &&
        !this.formSettings.show
      "
      outlined
      :clearable="
        $route.params.mode == 'view' || record.archived ? false : true
      "
      :loading="loading"
      dense
      :prepend-icon="
        $route.params.mode == 'view' || !this.fieldSettings.setup.add
          ? ''
          : 'add_box'
      "
      @click:prepend="openDialog()"
    >
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          @click="openDialog(data.item)"
          small
          pill
          label
          outlined
        >
          <v-avatar left> {{ data.item.quantity }} </v-avatar>
          {{ data.item.bin || data.item.box }}
        </v-chip>
      </template>
    </v-autocomplete>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card v-if="tmp" :key="tmp._id">
        <v-toolbar dark>
          <v-toolbar-title> {{ fieldSettings.name }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-6">
          <component
            v-for="field in fieldSettings.setup.fields"
            @emit="catchEmit($event, {})"
            :is="
              fields[field.field]
                ? fields[field.field].setup.type
                : 'field-text'
            "
            v-model="tmp[field.field]"
            :record="{ ...record, ...tmp }"
            :fieldSettings="fields[field.field]"
            :formSettings="field"
            :key="field.field"
            :id="field.field"
          />
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col
              ><v-btn color="primary" tile @click="dialog = false" block>
                cancel
              </v-btn></v-col
            >
            <v-col>
              <v-btn color="primary" tile @click="saveSubrecord(tmp)" block>
                Save
              </v-btn></v-col
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import service from "../../api/index";
import fieldCalculated from "../../common/methods/fieldCalculated";
import getFields from "../../common/methods/getFields";
import recalcRecord from "../../common/validations/recalcRecord";
/* eslint-disable */
export default {
  props: ["value", "record", "fieldSettings", "formSettings", "parent"],
  data() {
    return {
      tmp: {},
      dialog: false,
      fields: {},
      records: this.value || null,
      recordSelected: this.value || null,
      recordObj: true,
      required:
        this.formSettings.required ||
        this.fieldSettings.setup.required ||
        false,
      multiple:
        this.formSettings.multiple ||
        this.fieldSettings.setup.multiple ||
        ["$in", "$nin"].includes(this.formSettings.operator),
      field: this.formSettings.field || this.formSettings.setup.field,
      label: this.formSettings.name || this.fieldSettings.name,
      rules: {
        required: (value) => (this.required ? !!value || "Required." : true),
      },
      mode: this.$route.params.mode,
      loading: false,
      displayfield:
        this.fieldSettings && this.fieldSettings.setup
          ? this.fieldSettings.setup.displayfield || "displayname"
          : "displayname",
    };
  },
  watch: {
    dialog(v) {
      if (!v) this.tmp = {};
    },
    async recordSelected(value) {
      this.records = this.records.map((rec) => {
        if (
          (this.multiple && value.find((val) => val._id == rec._id)) ||
          (!this.multiple && value._id == rec._id)
        )
          rec.deleted = false;
        else rec.deleted = true;
        return rec;
      });
      if (this.formSettings.show) this.field = "value";
      if (this.record[this.field] != value)
        this.$emit("emit", { field: this.field, value: this.records || null });
    },
    // record: {
    //   handler: async function (after, before) {
    //     this.records = await this.getData(
    //       this.fieldSettings,
    //       this.formSettings
    //     );
    //     if (this.formSettings.show) this.field = "value";
    //     if (this.record[this.field])
    //       this.recordSelected = this.record[this.field];
    //     else this.recordSelected = null;
    //   },
    //   deep: true,
    // },
  },
  async created() {
    if (this.recordSelected && this.recordSelected.length && this.multiple)
      this.recordSelected = this.recordSelected.filter((val) => !val.deleted);
    if (this.fieldSettings && this.fieldSettings.setup.fields)
      this.fields = await getFields(this, this.record);
    //this.records = await this.getData(this.fieldSettings, this.formSettings);
  },
  methods: {
    openDialog(obj = {}) {
      // let newtmp = await recalcRecord(obj, false);
      this.tmp = obj; // Object.assign(this.tmp, newtmp);
      this.fieldSettings.setup.fields.forEach((field) => {
        if (!this.tmp[field.field]) this.tmp[field.field] = null;
      });
      this.tmp.new = true;
      this.dialog = true;
    },
    async saveSubrecord() {
      this.tmp.displayname =
        this.tmp[this.displayfield] || this.tmp.displayname || this.tmp.name;
      this.tmp.new = true;
      if (this.fieldSettings && this.fieldSettings._id == "transaction_bin")
        this.tmp.displayname = `${this.tmp.bin} (${this.tmp.quantity})`;
      if (this.fieldSettings && this.fieldSettings._id == "transaction_box") {
        this.tmp.displayname = `${this.tmp.box} (${this.tmp.quantity})`;
        this.tmp.name = `${this.tmp.box} (${this.tmp.quantity})`;
      }

      if (!this.tmp._id) {
        this.tmp = await recalcRecord(this.tmp, false);
        if (this.multiple) {
          //this.record[this.field].push({ ...this.tmp });
  
          if (!this.recordSelected) this.recordSelected = [{ ...this.tmp }];
          else this.recordSelected.push({ ...this.tmp });
          if (!this.records.find((v) => v._id == this.tmp._id))
            this.records.push({ ...this.tmp });
        } else {
          //this.record[this.field] = { ...this.tmp };
          this.recordSelected = { ...this.tmp };
          this.records.push({ ...this.tmp });
        }
      } else
        this.recordSelected = this.recordSelected.map((rec) => {
          rec.new = true;
          if (rec._id == this.tmp._id) return this.tmp;
          else return rec;
        });
      this.tmp = {};

      this.dialog = false;
    },
    async catchEmit(e, props) {
      this.tmp[e.field] = e.value;
    },
    async getData(source, form) {
      this.loading = true;

      if (source._id == "field") {
        return await this.getForms(
          this.parent
            ? this.parent.recordtype
            : this.record.type
            ? this.record.type._id || this.record.type
            : this.record.recordtype
        );
      } else {
        source.setup.filters = source.setup.filters || {};
        let field = await fieldCalculated(source, this.record);
        let parameters = [
          field.filters,
          {
            projection: {
              name: true,
              displayname: true,
              recordtype: true,
              collection: true,
            },
          },
        ];
        this.fieldSettings.setup.fields.forEach(
          (field) => (parameters[1].projection[field.field] = true)
        );
        let data = {
          action: "find",
          data: parameters,
        };
        return service(field.setup.collection, data)
          .catch((error) => {
            throw error;
          })
          .then((response) => {
            //console.log("select", response);
            let options = response.map((obj) => {
              if (this.recordObj) {
                obj.displayname =
                  obj[this.displayfield] || obj.displayname || obj.name;
                return obj;
              } else
                return {
                  displayname:
                    obj[this.displayfield] || obj.displayname || obj.name,
                  _id: obj._id,
                };
            });
            let filtervalue = this.recordSelected;
            if (!Array.isArray(filtervalue)) filtervalue = [filtervalue];
            filtervalue.forEach((val) => {
              if (val) options.push();
            });
            //console.log(options);
            this.loading = false;
            return options;
          })
          .catch((error) => {
            this.loading = false;
          });
      }
      this.loading = false;
    },
    async getForms(recordtype) {
      console.log(recordtype);
      this.loading = true;
      let fieldsObj = await getFields(this, this.record);
      let fields = [];
      let form = await service("forms", {
        action: "getForm",
        rid: recordtype,
      });
      form.tabs.forEach((tab) => {
        tab.sections.forEach((section) => {
          (section.columns || []).forEach((column) => {
            column.forEach((field) => {
              if (
                ["field-text", "field-textarea", "field-boolean"].includes(
                  fieldsObj[field.field].setup.type
                )
              )
                fields.push({
                  _id: field.field,
                  displayname:
                    obj[this.displayfield] || field.displayname || field.name,
                });
            });
          });
          (section.fields || []).forEach((field) => {
            if (
              ["field-text", "field-textarea"].includes(
                fieldsObj[field.field].setup.type
              )
            )
              fields.push({
                _id: field.field,
                displayname:
                  obj[this.displayfield] || field.displayname || field.name,
              });
          });
          //   if (section.sublist) {
          //     this.subrecords.push({
          //       field: section.sublist.field,
          //       name: section.sublist.name,
          //     });
          //     (section.sublist.columns || []).forEach((column) => {
          //       this.columns[section.sublist.field] = this.columns[
          //         section.sublist.field
          //       ]
          //         ? this.columns[section.sublist.field]
          //         : [];
          //       this.columns[section.sublist.field].push(column);
          //     });
          //   }
        });
      });
      this.loading = false;
      return fields;
    },
  },
};
</script>
<style scoped>
.v-avatar {
  border-radius: 0;
}
</style>
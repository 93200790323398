<template>
  <v-combobox
    v-if="fieldSettings.setup.items"
    :items="fieldSettings.setup.items"
    :error="required && !text"
    @input="emitChangedValue()"
    @blur="emitChangedValue()"
    v-model="text"
    :search-input.sync="text"
    :label="label"
    placeholder
    :rules="[rules.required, rules.max]"
    :counter="$route.params.mode != 'view' ? max : false"
    :readonly="$route.params.mode == 'view' && !this.formSettings.show"
    outlined
    dense
    autocomplete="new-password"
  ></v-combobox>
  <v-text-field
    v-else
    :error="required && !text"
    @blur="emitChangedValue()"
    v-model="text"
    :label="label"
    placeholder
    :rules="[rules.required, rules.max]"
    :counter="$route.params.mode != 'view' ? max : false"
    :readonly="$route.params.mode == 'view' && !this.formSettings.show"
    outlined
    dense
    autocomplete="new-password"
    :append-outer-icon="link ? 'mdi-web' : ''"
    @click:append-outer="gotourl"
  >
  </v-text-field>
</template>

<script>
import fieldCalculated from "../../common/methods/fieldCalculated";
/* eslint-disable */
export default {
  props: ["value", "record", "fieldSettings", "formSettings"],
  data() {
    return {
      text: this.value,
      required:
        this.formSettings.required ||
        this.fieldSettings.setup.required ||
        false,
      min: this.formSettings.min || this.fieldSettings.min,
      max: this.formSettings.max || this.fieldSettings.max,
      field: this.formSettings.field || this.formSettings.setup.field,
      label: this.formSettings.name || this.fieldSettings.name,

      rules: {
        required: (value) => (this.required ? !!value || "Required." : true),
        allowspaces: (value) =>
          ((value || "").indexOf(" ") < 0 && this.allowspaces) ||
          "No spaces are allowed",
        max: (value) =>
          this.max > 0
            ? (value || "").length <= this.max ||
              `A maximum of ${this.max} characters is allowed`
            : true,
        min: (value) =>
          this.min > 0
            ? (value || "").length >= this.min ||
              `A minimum of ${this.min} characters is allowed`
            : true,
      },
      mode: this.$route.params.mode,
    };
  },
  watch: {
    record: {
      handler: async function (after, before) {
        if (this.formSettings.show) this.field = "value";
        this.text = this.record[this.field];
      },
      deep: true,
    },
  },
  computed: {
    link() {
      return this.text && (this.text.includes("http") || this.text.includes("www"))
        ? true
        : false;
    },
  },
  async created() {
    //console.log(this.formSettings)
    // if (this.formSettings.default) {
    //   this.fieldSettings.default = this.formSettings.default
    //     ? this.formSettings.default
    //     : this.fieldSettings.setup.default;
    // }
    // if (this.formSettings.filters) {
    //   this.formSettings.default = this.formSettings.default
    //     ? this.formSettings.default
    //     : this.formSettings.default;
    // }
    let field = await fieldCalculated(this.fieldSettings, this.record);
    // if (field.default && !this.text && this.mode == "create") {
    //   this.text = field.default;
    //   this.record[field._id] = field.default;
    // }
  },
  methods: {
    gotourl() {
      window.open(this.text, "_blank");
    },
    emitChangedValue() {
      this.$emit("emit", { field: this.field, value: this.text });
    },
  },
};
</script>

<script>
/* eslint-disable */
export default {
  props: {
    markers: Array,
  },
  computed: {
    map() {
      return this.$parent.map;
    },
    google() {
      return this.$parent.google;
    },
  },
  mounted() {
    this.markers.forEach(async (marker) => {
      const infowindow = new google.maps.InfoWindow({
        content: `<v-card>
        <h2><a href='/${marker.user.collection}/${
          marker.user.recordtype
        }/view/${marker.user._id}'>${marker.user.name}</a></h2>
        <br>
        <p>
        Created: ${
          marker.user.created
            ? new Date(marker.user.created).toISOString().substr(0, 10)
            : ""
        }<br>
        Webiste: ${marker.user.website || ""}<br>
        Email: ${marker.user.email || ""}<br>
        Last Invoice: ${marker.datelastsales || ""}
        </p>
        <p>${marker.user.memo || ""}</p>
        </v-card>`,
      });
      const { Marker, LatLng } = this.google.maps;
      let image = marker.image;
      new Marker({
        title: marker.user.name,
        position: new LatLng(marker.latitude, marker.longitude),
        map: this.map,
        icon: image,
        optimized: true 
      }).addListener("click", function () {
        this.map.setZoom(12);
        //this.active = true;
        this.map.setCenter(this.getPosition());
        infowindow.setPosition(this.getPosition());
        if (marker.user.name) infowindow.open(this.map);
      });
    });
  },
  render() {
    return null;
  },
};
</script>

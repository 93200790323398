import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import service from '../api/index';
/* eslint-disable */
Vue.use(Vuex);

const state = {
  navigation: true,
  globalNotifications: [],
  lastPage: '',
  user: {
    logged: false,
    token: null,
  },
  query: {},
};

const mutations = {
  SET_LOGGED(state, payload) {
    state.user.logged = payload;
  },
  SET_USER(state, payload) {
    state.user = payload;
  },
  CHANGE_NAV(state) {
    state.navigation = !state.navigation;
  },
  SET_QUERY(state, payload) {
    state.query[payload.field] = payload.value;
  },
  SET_TOKEN(state, payload) {
    state.user.token = payload;
  },
};

const actions = {
  setLogged(context, logged) {
    context.commit('SET_LOGGED', logged);
  },
  setUser(context, token) {
    context.commit('SET_USER', token);
  },
  changeNav(context) {
    context.commit('CHANGE_NAV');
  },
  setQuery(context, value) {
    context.commit('SET_QUERY', value);
  },
  setToken(context, value) {
    context.commit('SET_TOKEN', value);
  },
  async verifyToken(context, user) {
    if (user.token) {
      const data = {
        action: 'verifyToken',
        token: user.token,
      };
      await service('users', data)
        .catch(error => {
          throw error;
        })
        .then(() => {
          context.commit('SET_USER', user);
          context.commit('SET_LOGGED', true);
        })
        .catch(() => {
          context.commit('SET_LOGGED', false);
          context.commit('SET_USER', {});
        });
    } else {
      context.commit('SET_LOGGED', false);
      context.commit('SET_USER', {});
    }
  },
};

const getters = {
  logged(state) {
    return state.user.logged;
  },
  userName(state) {
    return `${state.user.name}`;
  },
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  plugins: [createPersistedState()],
});
/* eslint-enable */

 <template>
  <div>
    <v-row>
      <v-col>
        <v-tabs dark v-model="active" v-if="!loading">
          <v-tab v-for="(tab, index) of Object.values(tabs)" :key="index">
            <v-badge color="primary">
              <span>Warehouse {{ tab.name }}</span>
              <span slot="badge">{{ tab.items.length }}</span>
            </v-badge></v-tab
          >
        </v-tabs>
        <v-tabs-items v-model="active" touchless>
          <v-tab-item v-for="(tab, index) of Object.values(tabs)" :key="index">
            <v-data-table
              :headers="headers"
              :items="tab.items"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              :search="search"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
              id="openordersstatus"
              v-model="selected"
              @current-items="getFiltered($event)"
              :show-select="showselect"
              :item-class="rowClass"
              group-by="number"
              @item-selected="selectKit"
            >
              <template
                v-slot:[`group.header`]="{
                  group,
                  headers,
                  isOpen,
                  toggle,
                  items,
                }"
              >
                <td
                  :colspan="headers.length"
                  class="pa-0"
                  style="height: unset"
                >
                  <v-subheader
                    style="background: #424242; color: white; height: unset"
                  >
                    <v-btn
                      :ref="group"
                      :data-open="isOpen"
                      @click="toggle"
                      small
                      icon
                    >
                      <v-icon v-if="isOpen">mdi-minus</v-icon>
                      <v-icon v-else>mdi-plus</v-icon>
                    </v-btn>
                    {{ items[0].transaction }}
                  </v-subheader>
                  <!-- <pre class="ml-10">{{ items[0].memomain }}</pre> -->
                </td>
              </template>
              <template v-slot:item.salesorder="{ item }">
                <router-link
                  :to="{
                    path: `/${item.salesorder.collection}/${item.salesorder.recordtype}/view/${item.salesorder._id}`,
                  }"
                  class="link"
                  >{{
                    item.salesorder.displayname || item.salesorder.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.correlatedtransaction="{ item }">
                <router-link
                  v-if="item.correlatedtransaction"
                  :to="{
                    path: `/${item.correlatedtransaction.collection}/${item.correlatedtransaction.recordtype}/view/${item.correlatedtransaction._id}`,
                  }"
                  class="link"
                  >{{
                    item.correlatedtransaction.displayname ||
                    item.correlatedtransaction.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                  }"
                  class="link"
                  >{{
                    item.customer.displayname || item.customer.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.item="{ item }">
                <router-link
                  :to="{
                    path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`,
                  }"
                  class="link"
                  >{{ item.item.displayname || item.item.name }}</router-link
                >
              </template>
              <template v-slot:item.sendtogether="{ item }">
                <v-chip
                  label
                  :color="item.sendtogether ? 'red' : 'none'"
                  dark
                  >{{ item.sendtogether ? "Yes" : "No" }}</v-chip
                >
              </template>
              <template v-slot:item.fleetorder="{ item }">
                <v-chip label :color="item.fleetorder ? 'red' : 'none'" dark>{{
                  item.fleetorder ? "Yes" : "No"
                }}</v-chip>
              </template>
              <template v-slot:item.stockorder="{ item }">
                <v-chip labels :color="item.stockorder ? 'red' : 'none'" dark>{{
                  item.stockorder ? "Yes" : "No"
                }}</v-chip>
              </template>
              <template v-slot:item.dropship="{ item }">
                <v-chip label :color="item.dropship ? 'red' : 'none'" dark>{{
                  item.dropship ? "Yes" : "No"
                }}</v-chip>
              </template>
              <template v-slot:item.paymentstatus="{ item }">
                {{ item.paymentstatus
                }}{{
                  item.paymentdescription ? " - " + item.paymentdescription : ""
                }}
              </template>
              <template v-slot:item.quantitycommitted="{ item }">
                <v-chip
                  label
                  :color="
                    item.quantitycommitted
                      ? item.quantitytopack == item.quantitycommitted ||
                        (item.quantitytopack && item.kitcomponent)
                        ? 'yellow'
                        : 'green'
                      : 'none'
                  "
                  dark
                  >{{ item.quantitycommitted }}</v-chip
                >
              </template>
              <template v-slot:top>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <exportData :headers="exportheaders" :items="tab.items" />
                    </v-col>
                    <v-col>
                      <v-btn @click="selectAllCommitted()" block tile
                        >All Committed</v-btn
                      >
                    </v-col>
                    <v-col>
                      <v-select
                        :items="shippingaddresses"
                        v-model="shippingaddress"
                        label="Shipping Address"
                        item-text="name"
                        item-value="_id"
                        outlined
                        dense
                        hide-details
                        single-line
                        clearable
                        @change="selectAllCommitted()"
                      ></v-select>
                      <!-- <v-btn
                        @click="getData()"
                        block
                        tile
                        color="primary"
                        class="mt-2 mb-2"
                        >Refresh</v-btn
                      > -->
                    </v-col>
                    <v-col>
                      <v-btn
                        @click="action()"
                        block
                        tile
                        color="primary"
                        :disabled="!selected.length"
                        >Ready To Pack</v-btn
                      >
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="text-right">
                      <v-btn icon @click="getData()">
                        <v-icon>mdi-reload</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-expand-transition>
                    <div v-show="show">
                      <v-row>
                        <v-col>
                          <v-subheader>Committed</v-subheader>
                          <v-list>
                            <v-list-item>
                              <v-list-item-content>
                                <v-row>
                                  <v-col class="py-0">
                                    <v-list-item-subtitle
                                      >Amount:</v-list-item-subtitle
                                    >
                                    <v-list-item-title>{{
                                      committedamount
                                    }}</v-list-item-title>
                                  </v-col>
                                  <v-col class="py-0">
                                    <v-list-item-subtitle
                                      >Weight:</v-list-item-subtitle
                                    >
                                    <v-list-item-title
                                      >{{
                                        committedweight
                                      }}kg</v-list-item-title
                                    >
                                  </v-col>
                                </v-row>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col>
                          <v-subheader>Backordered</v-subheader>
                          <v-list>
                            <v-list-item>
                              <v-list-item-content>
                                <v-row>
                                  <v-col class="py-0">
                                    <v-list-item-subtitle
                                      >Amount:</v-list-item-subtitle
                                    >
                                    <v-list-item-title>{{
                                      backorderamount
                                    }}</v-list-item-title>
                                  </v-col>
                                  <v-col class="py-0">
                                    <v-list-item-subtitle
                                      >Weight:</v-list-item-subtitle
                                    >
                                    <v-list-item-title
                                      >{{
                                        backorderweight
                                      }}
                                      kg</v-list-item-title
                                    >
                                  </v-col>
                                </v-row>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col>
                          <v-subheader>Selected</v-subheader>
                          <v-list>
                            <v-list-item>
                              <v-list-item-content>
                                <v-row>
                                  <v-col class="py-0">
                                    <v-list-item-subtitle
                                      >Amount:</v-list-item-subtitle
                                    >
                                    <v-list-item-title>{{
                                      selectamount
                                    }}</v-list-item-title>
                                  </v-col>
                                  <v-col class="py-0">
                                    <v-list-item-subtitle
                                      >Weight:</v-list-item-subtitle
                                    >
                                    <v-list-item-title
                                      >{{
                                        selectweight.toFixed(2)
                                      }}kg</v-list-item-title
                                    >
                                  </v-col>
                                </v-row>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-col>
                        <v-divider
                          vertical
                          v-if="selected.length && customer"
                        ></v-divider>
                        <v-col v-if="selected.length && customer">
                          <v-subheader>Shipping Cost</v-subheader>
                          <v-select
                            :items="shippingmethods"
                            v-model="shippingmethod"
                            label="Shipping Method"
                            item-text="name"
                            item-value="_id"
                            outlined
                            dense
                            hide-details
                            clearable
                            class="mt-2 mb-2"
                            @change="shippingCost()"
                          >
                            <template v-slot:item="{ item, index }">
                              <div class="my-1">
                                <p class="my-0 v-list-item__title">
                                  {{ item.name }}
                                </p>
                                <small>{{ item.description }}</small>
                              </div>
                            </template>
                          </v-select>
                          <v-list v-if="shippingmethod">
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-subtitle
                                  >Est. Shipping Cost:</v-list-item-subtitle
                                >
                                <v-list-item-title>{{
                                  shippingcost
                                }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                    </div>
                  </v-expand-transition>
                  <v-row><v-subheader>Open Orders</v-subheader> </v-row>
                </v-container>
              </template>
              <template slot="body.append">
                <tr>
                  <th v-if="showselect"></th>
                  <th v-for="(col, index) in headers" :key="index">
                    {{ col.summary ? calcTotal(col.value) : "" }}
                  </th>
                </tr>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";
export default {
  props: ["customer", "item", "customheaders"],
  data() {
    return {
      active: 0,
      showselect: true,
      loading: false,
      tabs: {},
      locationsFilter: [],
      headers: [
        //{ text: "Location", value: "location" },
        // { text: "Customer", value: "customer", sortable: false },
        { text: "Item", value: "item", sortable: false },
        {
          text: "Custom Product Information",
          value: "itemmemo",
          sortable: false,
        },
        { text: "Qty. Open", value: "quantityopen", summary: true },
        { text: "Unit Price", value: "price" },
        { text: "Total Price", value: "amount" },
        { text: "Sales Order", value: "salesorder", sortable: false },
        { text: "Reference Number", value: "referencenumber" },
        { text: "Shipping Method", value: "shippingmethod", sortable: false },
        { text: "Send Together", value: "sendtogether" },
        { text: "Date of Order", value: "date" },
        { text: "Committed", value: "quantitycommitted" },
        { text: "Service", value: "additionalservice" },
        { text: "ETA", value: "eta" },
        { text: "ETA Memo", value: "etamemo" },
        { text: "ETA Internal", value: "etamemo2", width: "180px" },
        { text: "Fleet Order", value: "fleetorder" },
        { text: "Dropship", value: "dropship" },
        { text: "Shipping Address", value: "shippingaddress" },
        { text: "Ship Date", value: "planneddateofshipment" },
        { text: "Customer Comment", value: "comment", sortable: false },
        //{ text: "Internal Comments", value: "memomain", width: "180px" },
        { text: "Payment Status", value: "paymentstatus" },
        { text: "SO#", value: "correlatedtransaction", sortable: false },
        { text: "SO# Committed", value: "correlatedquantitycommitted" },
        { text: "SO# ETA", value: "correlatedeta" },
        { text: "PO#", value: "specialorders", sortable: false },
      ],
      exportheaders: [
        { text: "Location", value: "location" },
        { text: "Customer", value: "customer", sortable: false },
        { text: "Country", value: "billcountry", sortable: false },
        { text: "Kit Component?", value: "kitcomponent", sortable: false },
        { text: "Item", value: "item", sortable: false },
        {
          text: "Custom Product Information",
          value: "itemmemo",
          sortable: false,
        },
        { text: "Qty. Open", value: "quantityopen" },
        { text: "Unit Price", value: "price" },
        { text: "Total Price", value: "amount" },
        { text: "Sales Order", value: "salesorder", sortable: false },
        { text: "Reference Number", value: "referencenumber" },
        { text: "Shipping Method", value: "shippingmethod", sortable: false },
        { text: "Send Together", value: "sendtogether" },
        { text: "Date of Order", value: "date" },
        { text: "Committed", value: "quantitycommitted" },
        { text: "Service", value: "additionalservice" },
        { text: "ETA", value: "eta" },
        { text: "ETA Memo", value: "etamemo" },
        { text: "ETA Internal", value: "etamemo2" },
        { text: "Fleet Order", value: "fleetorder" },
        { text: "Dropship", value: "dropship" },
        { text: "Shipping Address", value: "shippingaddress" },
        { text: "Ship Date", value: "planneddateofshipment" },
        { text: "Customer Comment", value: "comment", sortable: false },
        { text: "Internal Comments", value: "memomain" },
        { text: "Payment Status", value: "paymentstatus" },
        { text: "SO#", value: "correlatedtransaction", sortable: false },
        { text: "SO# Committed", value: "correlatedquantitycommitted" },
        { text: "SO# ETA", value: "correlatedeta" },
        { text: "PO#", value: "specialorders", sortable: false },
        { text: "Sales Rep", value: "salesrep" },
      ],
      items: [],
      rows: [-1, 100],
      search: "",
      show: true,
      committedamount: 0,
      backorderamount: 0,
      committedweight: 0,
      backorderweight: 0,
      selectamount: 0,
      selectweight: 0,
      balance: 0,
      weight: 0,
      amount: 0,
      singleSelect: false,
      selected: [],
      filteredItems: [],
      shippingaddress: null,
      shippingaddresses: [],
      shipcountries: [],
      locations: [],
      shippingmethods: [],
      shippingmethod: null,
      shippingcost: 0,
      currency: "EUR",
    };
  },

  async created() {
    this.loading = true;
    this.headers = this.customheaders || this.headers;
    this.tabs = {
      PL: {
        name: "PL",
        items: [],
        menu: false,
        location: ["5e0dbaba9e33df43f0b3a495"],
      },
      "NL": {
        name: "NL",
        items: [],
        menu: false,
        location: ["66e04fcba16b2e1e7d873be8"],
      },
      UK: {
        name: "UK",
        items: [],
        menu: false,
        location: ["61e14c6ae4a35a668517a8fe"],
      },
    };
    if (this.$store.state.user.location)
      this.locationsFilter.push(this.$store.state.user.location);
    if (
      this.$store.state.user.forlocations &&
      this.$store.state.user.forlocations.length
    )
      this.locationsFilter.push(...this.$store.state.user.forlocations);
    Object.values(this.tabs).forEach((loc) => {
      if (
        this.locationsFilter.some((l) => loc.location.includes(l)) ||
        !this.locationsFilter.length
      )
        console.log(loc.name);
      else delete this.tabs[loc.name];
    });
    await this.getData();

    this.loading = false;
  },
  watch: {
    async selected(value) {
      let rows = document.querySelectorAll("#openordersstatus tbody tr");
      this.selectamount = 0;
      this.selectweight = 0;
      this.locations = [];
      this.shipcountries = [];
      value.forEach((item, index) => {
        if (!item.kitcomponent) {
          this.selectamount += parseFloat(item.amount);
          this.selectweight += parseFloat(item.item.weight * item.quantityopen);
          this.locations.push(item.warehouse);
          this.shipcountries.push(item.shipcountry);
        }
      });
      this.selectamount = this.selectamount.toFixed(2);
      this.selectamount = this.formatCurrency(
        this.selectamount,
        this.currency.iso
      );
      // this.filteredItems.forEach((item, index) => {
      //   if ((item.fleetorder || item.drophsip) && rows[index]) {
      //     rows[index].classList.add("specialorder");
      //   }
      //   if (item.kitcomponent && rows[index]) {
      //     rows[index].classList.add("kitcomponent");
      //   }
      // });
      console.log(this.locations, this.shipcountries);
      this.shippingmethods = await service("items", {
        action: "find",
        data: [
          {
            recordtype: "shippingitem",
            hidden: { $ne: true },
            incountries: { $in: this.shipcountries },
            forlocations: { $in: this.locations },
          },
        ],
      });
      if (this.shippingmethod) this.shippingCost();
    },
  },
  updated: function () {
    // let rows = document.querySelectorAll("#openordersstatus tbody tr");
    // this.filteredItems.forEach((item, index) => {
    //   if ((item.fleetorder || item.drophsip) && rows[index]) {
    //     rows[index].classList.add("specialorder");
    //   }
    //   if (item.kitcomponent && rows[index]) {
    //     rows[index].classList.add("kitcomponent");
    //   }
    // });
  },
  methods: {
    selectKit(item) {
      if (item.item) {
        if (item.value) {
          this.selected.push(item.item);
          this.items.forEach((line) => {
            if (line.init == item.item._id) {
              this.selected.push(line);
            }
          });
        } else {
          this.selected = this.selected.filter(
            (line) => line.init != item.item._id && line._id != item.item._id
          );
        }
      }
    },
    formatCurrency(value, currency = "PLN") {
      console.log(currency);
      return new Intl.NumberFormat(currency !== "PLN" ? "en-EN" : "pl-PL", {
        style: "currency",
        currency: currency,
      }).format(value || 0);
    },
    async shippingCost() {
      this.shippingcost = await service(
        "items",
        {
          action: "getShippingCost",
          weight: this.selectweight,
          shippingmethod: this.shippingmethod,
          currency: this.currency,
          location: this.locations[0],
          customer: this.customer,
          transactionlines: this.selected,
        },
        false,
        true
      );
    },
    rowClass(item) {
      if (item.kitcomponent) {
        return "kitcomponent";
      }
      if (item.reservation) {
        return "reservation";
      }
      if (item.fleetorder || item.drophsip) {
        return "specialorder";
      }
    },
    calcTotal(key) {
      let items = Object.values(this.tabs);
      let selected = items[this.active].items;
      if (selected.length > 0) {
        let sum = selected.reduce((a, b) => {
          if (
            !isNaN(parseFloat(a[key] || a)) &&
            !isNaN(parseFloat(b[key] || b))
          ) {
            return parseFloat(a[key] || a || 0) + parseFloat(b[key] || b || 0);
          } else return parseFloat(a || 0);
        }, 0);

        return sum && !isNaN(parseInt(sum)) ? sum.toFixed(2) : "";
      }
    },
    selectAllCommitted() {
      let items = Object.values(this.tabs);
      this.selected = items[this.active].items.filter(
        (select) =>
          !select.reservation &&
          select.quantitycommitted &&
          select.quantitytopack != select.quantitycommitted &&
          select.quatityreadytopack &&
          (this.shippingaddress
            ? this.shippingaddress == select.shippingaddress
              ? true
              : false
            : true)
      );
    },
    getFiltered(e) {
      this.filteredItems = e;
    },
    async getData() {
      this.loading = true;
      let result = await service(
        "report",
        {
          action: "getOpenOrdersStatus",
          customer: this.customer,
          item: this.item,
        },
        false,
        true
      );
      if (!this.customer && !this.item) {
        this.headers = [
          { text: "Location", value: "location" },
          { text: "Customer", value: "customer", sortable: false },
          { text: "Country", value: "billcountry", sortable: false },
          { text: "Item", value: "item", sortable: false },
          { text: "Qty. Open", value: "quantityopen" },
          { text: "Unit Price", value: "price" },
          { text: "Total Price", value: "amount" },
          { text: "Sales Order", value: "salesorder", sortable: false },
          { text: "Shipping Method", value: "shippingmethod", sortable: false },
          { text: "Send Together", value: "sendtogether" },
          { text: "SO#", value: "correlatedtransaction", sortable: false },
          { text: "PO#", value: "referencenumber", sortable: false },
          { text: "Date of Order", value: "date" },
          { text: "Committed", value: "quantitycommitted" },
          { text: "Service", value: "additionalservice" },
          { text: "ETA", value: "eta" },
          { text: "ETA Memo", value: "etamemo" },
          { text: "Fleet Order", value: "fleetorder" },
          { text: "Dropship", value: "dropship" },
          { text: "Shipping Address", value: "shippingaddress" },
          { text: "Ship Date", value: "planneddateofshipment" },
          { text: "Customer Comment", value: "comment", sortable: false },
          { text: "Internal Comments", value: "memomain" },
          { text: "Payment Status", value: "paymentstatus" },
          { text: "Sales Rep", value: "salesrep" },
        ];
      }
      this.items = result.items;
      result.items.forEach((item) => {
        if (
          !this.shippingaddresses.includes(item.shippingaddress) &&
          item.quantitycommitted
        )
          this.shippingaddresses.push(item.shippingaddress);
        this.currency = item.currency;
      });
      Object.values(this.tabs).forEach((loc) => {
        loc.items = [];
      });
      result.items.forEach((item) => {
        this.tabs[item.location] = this.tabs[item.location] || {
          name: item.location,
          items: [],
          menu: false,
        };
        this.tabs[item.location].items.push(item);
      });
      this.committedamount = this.formatCurrency(
        result.committedamount,
        this.currency.iso
      );
      this.backorderamount = this.formatCurrency(
        result.backorderamount,
        this.currency.iso
      );
      this.committedweight = result.committedweight;
      this.backorderweight = result.backorderweight;
      this.loading = false;
    },
    action() {
      let lines = [];
      let location = null;
      let shippingaddress = null;
      let error = false;
      let createdfrom = [];
      let customer = null;

      this.selected.forEach((select) => {
        if (location && select.location != location) {
          alert("You cannot select more locations");
          error = true;
        }
        location = select.location;
        if (
          customer &&
          (select.customer._id || select.customer) != (customer._id || customer)
        ) {
          alert("You cannot select more Customers");
          error = true;
        }
        customer = select.customer;

        if (shippingaddress && select.shippingaddress != shippingaddress) {
          alert("You cannot select more Shipping Address");
          error = true;
        }
        shippingaddress = select.shippingaddress;
        if (createdfrom.indexOf(select.salesorder._id) < 0) {
          createdfrom.push(select.salesorder._id);
        }
        lines.push(select._id);
      });
      if (!error)
        this.$router.push({
          name: "Transactions",
          params: {
            recordtype: "itemfulfillment",
            mode: "create",
            createdfrom: createdfrom,
            filters: lines,
            location: location,
            message: this.message,
            customer: customer,
            packinginstructions: this.packinginstruction,
            shippingmethod: this.shippingmethod,
            codamount: this.codamount,
          },
        });
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
  },
  components: {
    exportData,
  },
};
</script>
<style>
.specialorder {
  background: #b5b5b5;
}
.kitcomponent {
  background: #ececec;
}
.reservation {
  background: #89daff;
}
tr.kitcomponent .v-simple-checkbox {
  display: none;
}
</style>
<template>
  <v-card tile>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Logistics</v-toolbar-title>
    </v-toolbar>
    <v-tabs dark v-model="active">
      <!-- <v-tab :key="1">Transfer PL to UK</v-tab>
      <v-tab :key="2">Order from AU_CN to PL</v-tab> -->
      <v-tab :key="1">PO Assistent</v-tab>
      <v-tab :key="2">ABC-XYZ</v-tab>
      <v-tab :key="3">Stock Request</v-tab>
      <v-tab :key="4">Reports</v-tab>
      <v-tab :key="5">Stock Checks</v-tab>
      <v-tab :key="6">Shipping Methods (BETA)</v-tab>
      <v-tab :key="7">ETA Requests (BETA)</v-tab>
      <v-tab :key="9"><v-icon>mdi-close-outline</v-icon></v-tab>
      <!-- <v-tab-item :key="1">
        <transferfromwarehouse
          location="61e14c6ae4a35a668517a8fe"
          destination="5e0dbaba9e33df43f0b3a495"
          buffer="627cee8a0f148d1cb7e46aa2"
          internal="true"
          mode="purchaseorder"
          v-if="active == 0"
        />
      </v-tab-item>

      <v-tab-item :key="2">
        <transferfromwarehouse
          location="627cee8a0f148d1cb7e46aa2"
          destination="61e14c6ae4a35a668517a8fe"
          buffer="5e0dbaba9e33df43f0b3a495"
          internal="false"
          mode="purchaseorder"
          v-if="active == 1"
        />
      </v-tab-item> -->
      <v-tab-item :key="1">
        <poassistant v-if="active == 0" />
      </v-tab-item>
      <v-tab-item :key="2">
        <abcxyz v-if="active == 1" />
      </v-tab-item>
      <v-tab-item :key="3">
        <openstockrequest v-if="active == 2" />
      </v-tab-item>
      <v-tab-item :key="4">
        <reports v-if="active == 3" />
      </v-tab-item>
      <v-tab-item :key="5">
        <stockcheck v-if="active == 4" />
      </v-tab-item>
      <v-tab-item :key="6">
        <shippingMethods v-if="active == 5" />
      </v-tab-item>
      <v-tab-item :key="7">
        <borequests v-if="active == 6" />
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import transferfromwarehouse from "../components/tools/transferfromwarehouse/View";
import reports from "../components/tools/reports/View";
import openstockrequest from "../components/tools/openstockrequest/View";
import poassistant from "../components/tools/poassistant/View";
import abcxyz from "../components/tools/abcxyz/View";
import stockcheck from "../components/tools/stockcheck/View";
import shippingMethods from "../components/tools/shippingMethods/View";
import borequests from "../components/tools/requests/View";
/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
      active: 9,
    };
  },
  components: {
    transferfromwarehouse,
    openstockrequest,
    reports,
    poassistant,
    abcxyz,
    stockcheck,
    shippingMethods,
    borequests
  },
};
</script>
 <template>
  <v-container fluid id="reportview">
    <v-row v-if="!loading">
      <v-col>
        <v-select
          :items="companies"
          v-model="company"
          label="Company"
          item-text="name"
          item-value="_id"
          outlined
          clearable
          multiple
          hide-details
          class="mt-2 mb-2"
          dense
        ></v-select>
        <v-switch
          v-model="intercompany"
          dense
          hide-details
          label="Inter-Company Transactions"
        ></v-switch>
      </v-col>
      <v-col>
        <v-menu
          v-model="menufrom"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Date from"
              placeholder
              v-model="datefrom"
              append-icon="event"
              readonly
              v-on="on"
              outlined
              dense
              hide-details
              clearable
              class="mt-2 mb-2"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="datefrom"
            :first-day-of-week="1"
          ></v-date-picker>
        </v-menu>
        <v-menu
          v-model="menuto"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Date to"
              placeholder
              v-model="dateto"
              append-icon="event"
              readonly
              v-on="on"
              outlined
              dense
              hide-details
              clearable
              class="mt-2 mb-2"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateto"
            :first-day-of-week="1"
          ></v-date-picker>
        </v-menu>
        <v-select
          :items="periods"
          v-model="period"
          label="Periods"
          item-text="name"
          item-value="_id"
          class="mt-2 mb-2"
          outlined
          clearable
          dense
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          :items="manufacturers"
          v-model="manufacturer"
          label="Manufacturers"
          item-text="name"
          item-value="_id"
          class="mt-2 mb-2"
          outlined
          clearable
          multiple
          dense
          hide-details
        ></v-select>
        <!-- <v-switch v-model="quarterly" label="Quarterly"></v-switch> -->
      </v-col>
      <v-col>
        <v-autocomplete
          :items="countries"
          v-model="country"
          label="Country"
          item-text="name"
          item-value="_id"
          class="mt-2 mb-2"
          outlined
          clearable
          hide-details
          multiple
          dense
        >
          <template v-slot:append-outer>
            <v-slide-x-reverse-transition mode="out-in">
              <v-icon
                :key="`icon-${excludecountry}`"
                :color="excludecountry ? 'info' : 'success'"
                @click="excludecountry = !excludecountry"
                v-text="excludecountry ? 'mdi-minus-circle' : 'mdi-plus-circle'"
              ></v-icon>
            </v-slide-x-reverse-transition>
          </template>
        </v-autocomplete>
        <v-select
          :items="modes"
          v-model="mode"
          label="Mode"
          item-text="name"
          item-value="_id"
          class="mt-2 mb-2"
          outlined
          clearable
          hide-details
          dense
        ></v-select>
        <v-switch
          v-model="compare"
          dense
          hide-details
          label="Compare"
        ></v-switch>
      </v-col>
      <v-col>
        <v-btn @click="getData()" block tile color="primary" class="mt-2 mb-2"
          >Refresh</v-btn
        >
      </v-col>
      <v-col>
        <v-btn
          @click="printReport()"
          block
          tile
          color="primary"
          class="mt-2 mb-2"
          >Print</v-btn
        >
      </v-col>
      <v-col class="text-right">
        <v-btn icon @click="show = !show">
          <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-progress-linear indeterminate v-else></v-progress-linear>
    <v-row>
      <v-col class="ma-0 pa-0">
        <v-expand-transition>
          <div v-show="show">
            <v-divider></v-divider>
            <v-tabs dark v-model="active" v-if="show">
              <v-tab :key="1">Sales by Item Summary</v-tab>
              <v-tab :key="2">Sales Backordered</v-tab>
              <v-tab :key="3">Order Summary</v-tab>
              <v-tab-item :key="1">
                <queryresult
                  used="customer"
                  queryid="5fae502af38e251d30d28ec8"
                  v-if="active == 0"
                />
              </v-tab-item>
              <v-tab-item :key="2">
                <queryresult
                  used="customer"
                  queryid="603de82294e10a2a5cff7657"
                  :filters="filters"
                  v-if="active == 1"
                />
              </v-tab-item>
              <v-tab-item :key="3">
                <queryresult
                  used="customer"
                  queryid="607d8360f032760dd4870949"
                  :filters="filters"
                  v-if="active == 2"
                />
              </v-tab-item>
            </v-tabs>
          </div>
        </v-expand-transition>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-container v-if="!loading" ref="reportview" id="printarea" fluid>
      <v-row>
        <v-col v-if="items.bymanufacturer" md="6">
          <v-card flat>
            <v-card-title>Total Sales by Manufacturer</v-card-title>
            <doughnutchart
              :chartData="toChartData('doughnut', items.bymanufacturer)"
            />
          </v-card>
        </v-col>
        <v-col v-if="items.bymanufacturer" md="6">
          <v-card flat>
            <barchart
              :chartData="toChartData('bar', items.bymanufacturer)"
              :option="stacked"
            />
          </v-card>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col md="6">
          <v-card flat>
            <v-card-title>Total Sales Overview</v-card-title>
            <v-data-table
              :headers="headers2"
              :items="items.sales"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="name"
              hide-default-footer
            >
              <template v-slot:item.amount="{ item }">
                <span>
                  {{
                    new Intl.NumberFormat(currency ? "en-EN" : "pl-PL", {
                      style: "currency",
                      currency: currency || "PLN",
                    }).format(item.amount)
                  }}
                </span>
              </template>
              <template v-slot:item.compareamount="{ item }">
                <span>
                  {{
                    new Intl.NumberFormat(currency ? "en-EN" : "pl-PL", {
                      style: "currency",
                      currency: currency || "PLN",
                    }).format(item.compareamount)
                  }}
                </span>
              </template>
              <template v-slot:item.procquantity="{ item }">
                <v-chip
                  label
                  v-if="item.avgquantity"
                  :color="
                    (item.quantity / item.avgquantity) * 100 - 100 < 0
                      ? 'red'
                      : 'green'
                  "
                  dark
                  >{{
                    Math.round((item.quantity / item.avgquantity) * 100) - 100
                  }}%</v-chip
                >
              </template>
              <template v-slot:item.procamount="{ item }">
                <v-chip
                  label
                  v-if="item.avgamount"
                  :color="
                    (item.amount / item.avgamount) * 100 - 100 < 0
                      ? 'red'
                      : 'green'
                  "
                  dark
                  >{{
                    Math.round((item.amount / item.avgamount) * 100) - 100
                  }}%</v-chip
                >
              </template>
              <template v-slot:item.proccompareamount="{ item }">
                <v-chip
                  label
                  v-if="item.compareamount"
                  :color="
                    (item.amount / item.compareamount) * 100 - 100 < 0
                      ? 'red'
                      : 'green'
                  "
                  dark
                  >{{
                    Math.round((item.amount / item.compareamount) * 100) - 100
                  }}%</v-chip
                >
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col md="6">
          <v-card flat>
            <v-card-title>Top Items</v-card-title>
            <v-data-table
              :headers="headers"
              :items="items.items"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              :search="search"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
            ></v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="mode == 'orders'">
        <v-col v-if="items.byordered">
          <h3>{{ items.byordered.title }}</h3>
          <barchart :chartData="toChartData('bar', items.byordered)" />
        </v-col>
      </v-row>
      <v-row
        v-for="manufacturer of items.bymanufacturer.datasets"
        :key="manufacturer.label"
      >
        <v-col v-if="items.bymanufacturer" md="6">
          <v-card flat>
            <barchart
              :chartData="
                toChartData('bar', items.bymanufacturer, manufacturer.label)
              "
            />
          </v-card>
        </v-col>

        <v-col md="6">
          <v-card flat>
            <v-card-title>{{ manufacturer.label }} Sales Overview</v-card-title>
            <v-data-table
              :headers="headers2"
              :items="items.salesbymanufacturer[manufacturer.label]"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="name"
              hide-default-footer
            >
              <template v-slot:item.amount="{ item }">
                <span>
                  {{
                    new Intl.NumberFormat(currency ? "en-EN" : "pl-PL", {
                      style: "currency",
                      currency: currency || "PLN",
                    }).format(item.amount)
                  }}
                </span>
              </template>
              <template v-slot:item.compareamount="{ item }">
                <span>
                  {{
                    new Intl.NumberFormat(currency ? "en-EN" : "pl-PL", {
                      style: "currency",
                      currency: currency || "PLN",
                    }).format(item.compareamount)
                  }}
                </span>
              </template>
              <template v-slot:item.procquantity="{ item }">
                <v-chip
                  label
                  v-if="item.avgquantity"
                  :color="
                    (item.quantity / item.avgquantity) * 100 - 100 < 0
                      ? 'red'
                      : 'green'
                  "
                  dark
                  >{{
                    Math.round((item.quantity / item.avgquantity) * 100) - 100
                  }}%</v-chip
                >
              </template>
              <template v-slot:item.procamount="{ item }">
                <v-chip
                  label
                  v-if="item.avgamount"
                  :color="
                    (item.amount / item.avgamount) * 100 - 100 < 0
                      ? 'red'
                      : 'green'
                  "
                  dark
                  >{{
                    Math.round((item.amount / item.avgamount) * 100) - 100
                  }}%</v-chip
                >
              </template>
              <template v-slot:item.proccompareamount="{ item }">
                <v-chip
                  label
                  v-if="item.compareamount"
                  :color="
                    (item.amount / item.compareamount) * 100 - 100 < 0
                      ? 'red'
                      : 'green'
                  "
                  dark
                  >{{
                    Math.round((item.amount / item.compareamount) * 100) - 100
                  }}%</v-chip
                >
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="items.bysalesrep">
          <h3>{{ items.bysalesrep.title }}</h3>
          <doughnutchart
            :chartData="toChartData('doughnut', items.bysalesrep)"
          />
        </v-col>
        <v-col v-if="items.bysalesrep">
          <h3>{{ items.bysalesrep.title }}</h3>
          <barchart
            :chartData="toChartData('bar', items.bysalesrep)"
            :option="stacked"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="items.bycountry">
          <h3>{{ items.bycountry.title }}</h3>
          <barchart
            :chartData="toChartData('bar', items.bycountry)"
            :option="stacked"
          />
        </v-col>
        <v-col v-if="items.bycountry">
          <h3>{{ items.bycountry.title }}</h3>
          <doughnutchart
            :chartData="toChartData('doughnut', items.bycountry)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="items.bytransactiontype">
          <h3>{{ items.bytransactiontype.title }}</h3>
          <barchart :chartData="toChartData('bar', items.bytransactiontype)" />
        </v-col>
        <v-col>
          <v-data-table
            :headers="headers4"
            :items="items.customers"
            :loading="loading"
            loading-text="Loading... Please wait"
            item-key="_id"
            :search="search"
            :footer-props="{ itemsPerPageOptions: rows }"
            :custom-filter="filter"
          ></v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import piechart from "../../charts/pie";
import barchart from "../../charts/bar";
import linechart from "../../charts/line";
import doughnutchart from "../../charts/doughnut";
import colors from "../../charts/colors";
import filter from "../../dataTable/filter";
export default {
  props: ["customer", "manufacturergroup"],
  data() {
    return {
      active: 0,
      loading: true,
      filters: {},
      datefrom: new Date(new Date().getFullYear() - 1, 0, 2)
        .toISOString()
        .substr(0, 10),
      dateto: null,
      search: null,
      compare: true,
      items: {},
      initdate: null,
      company: null,
      intercompany: false,
      companies: [],
      data: null,
      rows: [5],
      manufacturers: [],
      manufacturer: [],
      excludecountry: false,
      countries: [],
      country: [],
      show: false,
      currency: false,
      toPrint: "test",
      mode: "sales",
      modes: [
        { name: "Sales Report", _id: "sales" },
        { name: "Orders Report", _id: "orders" },
        { name: "Purchase Report", _id: "purchase" },
      ],
      periods: [
        { name: "Weekly", _id: "weekly" },
        { name: "Monthly", _id: "monthly" },
        { name: "Quarterly", _id: "quarterly" },
        { name: "Yearly", _id: "yearly" },
      ],
      period: "quarterly",
      headers: [
        { text: "Item", value: "item" },
        { text: "Manufacturer", value: "manufacturergroup" },
        { text: "Makes", value: "makegroups" },
        { text: "Sold", value: "quantity" },
        { text: "Last Sold", value: "date" },
      ],
      headers2: [
        { text: "Period", value: "name" },
        //{ text: "Sold", value: "quantity" },
        { text: "This Year", value: "amount" },
        { text: "Last Year", value: "compareamount" },
        { text: "%", value: "proccompareamount" },
      ],
      headers4: [
        { text: "Customer", value: "customer" },
        { text: "Amount", value: "amount" },
        { text: "Last Invoice", value: "date" },
      ],
      menufrom: false,
      menuto: false,
      stacked: {
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
      },
    };
  },
  async created() {
    this.companies = await service("users", {
      action: "find",
      data: [{ recordtype: "company" }],
    });
    this.countries = await service("countries", {
      action: "find",
      data: [{}],
    });
    this.manufacturers = await service("classification", {
      action: "find",
      data: [{ recordtype: "manufacturergroup" }],
    }).then((res) => {
      if (this.manufacturergroup)
        this.manufacturer.push(this.manufacturergroup);
      else
        res.forEach((manufacturer) => this.manufacturer.push(manufacturer._id));
      return res;
    });
    await this.getData();
  },
  watch: {
    async period() {
      await this.getData();
    },
    async mode() {
      await this.getData();
    },
  },
  methods: {
    adjust(color, amount) {
      return (
        "#" +
        color
          .replace(/^#/, "")
          .replace(/../g, (color) =>
            (
              "0" +
              Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(
                16
              )
            ).substr(-2)
          )
      );
    },
    toChartData(type, data, filter) {
      let chartData = JSON.parse(JSON.stringify(data));
      if (type == "pie" || type == "doughnut") {
        chartData.datasets = chartData.datasets2;
        chartData.labels = chartData.labels2;
        chartData.datasets.map((data) => {
          data.backgroundColor = colors;
        });
      } else {
        chartData.datasets.map((data, index) => {
          data.backgroundColor = colors[index];
        });
        if (filter) {
          let tmpLabels = [];
          let tmpDatasets = {};
          if (this.compare) {
            chartData.labels.forEach((label) => {
              if (!tmpLabels.includes(label.split("-")[1]))
                tmpLabels.push(label.split("-")[1]);
              if (!tmpLabels.includes(label.split("-")[0]))
                tmpDatasets[label.split("-")[0]] = {
                  label: label.split("-")[0],
                  data: [],
                  fill: false,
                };
            });
          }
          chartData.datasets = chartData.datasets.filter((data) => {
            console.log(filter, data.label);
            return data.label == filter;
          });
          if (this.compare) {
            Object.keys(tmpDatasets).forEach((set, index) => {
              console.log(set, index);
              tmpDatasets[set].backgroundColor = this.adjust(
                chartData.datasets[0].backgroundColor,
                index * -50
              );
            });
            chartData.datasets[0].data.forEach((data, index) => {
              tmpDatasets[chartData.labels[index].split("-")[0]].data.push(
                data
              );
            });
            chartData.labels = tmpLabels;
            chartData.datasets = Object.values(tmpDatasets);
          }
        }
      }
      return chartData;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    async getData() {
      this.loading = true;
      let data = await service(
        "report",
        {
          action: "getStatistics",
          datefrom: this.datefrom,
          dateto: this.dateto,
          customer: this.customer,
          period: this.period,
          compare: this.compare,
          manufacturer: this.manufacturer,
          mode: this.mode,
          intercompany: this.intercompany,
          company: this.company,
          country: this.country,
          excludecountry: this.excludecountry,
        },
        false,
        true
      );
      //this.data = data.data;
      this.items = data.items;
      console.log(this.items);
      this.loading = false;
    },
    printReport() {
      document.querySelector(".v-application--wrap").style.display = "none";
      document.querySelector("#app").appendChild(this.$refs.reportview);
      window.focus();
      window.print();
      document.querySelector("#reportview").appendChild(this.$refs.reportview);
      document.querySelector(".v-application--wrap").style.display = "";
    },
  },
  components: { piechart, barchart, linechart, doughnutchart },
};
</script>
<style scoped>
#printarea {
  background-color: #ffffff !important;
}
#printarea .row {
  page-break-inside: avoid;
}
</style>
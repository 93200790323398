import service from "../../api/index";
/* eslint-disable */
export default (context, rid, collection, recordtype) => {
  let data = {
    action: "loadRecord",
    rid: rid
  };
  return service(collection, data)
    .catch(error => {
      throw error;
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      context.msg = { message: `${error.message}`, show: true };
      context.$router.push({ path: `/${collection}/${recordtype}/` });
    });
};

 <template>
  <div v-if="record.company">
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          loading-text="Loading... Please wait"
          item-key="_id"
          v-model="selected"
          show-select
          :search="search"
          :single-select="singleSelect"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
        >
          <template v-slot:item.createdfrom="{ item }">
            <router-link
              v-for="po in item.createdfrom"
              :key="po._id"
              :to="{
                path: `/${po.collection}/${po.recordtype}/view/${po._id}`,
              }"
              class="link"
              >{{ po.displayname || po.name }}</router-link
            >
          </template>
          <template v-slot:item.date="{ item }">
            <span>{{ new Date(item.date).toISOString().substr(0, 10) }}</span>
          </template>
          <template v-slot:item.transaction="{ item }">
            <router-link
              :to="{
                path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
              }"
              class="link"
              >{{
                item.transaction.displayname || item.transaction.name
              }}</router-link
            >
          </template>
          <template v-slot:item.customer="{ item }">
            <router-link
              :to="{
                path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
              }"
              class="link"
              >{{
                item.customer.displayname || item.customer.name
              }}</router-link
            >
          </template>
          <template v-slot:item.expensescategory="{ item }">
            <v-select
              :items="expensescategory"
              v-model="item.expensescategory"
              label="Expenses"
              item-text="name"
              item-value="_id"
              single-line
              hide-details
              outlined
              dense
              return-object
              @change="refresh()"
            ></v-select>
          </template>
          <template v-slot:item.assignmentmethod="{ item }">
            <v-select
              :items="assignmentmethod"
              v-model="item.assignmentmethod"
              label="Method"
              item-text="name"
              item-value="_id"
              single-line
              hide-details
              outlined
              dense
              return-object
              @change="refresh()"
            ></v-select>
          </template>
          <template v-slot:item.amount="{ item }">
            <v-text-field
              dense
              v-model="item.amount"
              label="Assign"
              single-line
              hide-details
              outlined
              @change="refresh()"
            ></v-text-field>
          </template>
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
export default {
  props: ["record"],
  data() {
    return {
      loading: false,

      headers: [
        { text: "Location", value: "location" },
        { text: "Date", value: "date" },
        { text: "Type", value: "recordtype", sortable: false },
        { text: "Vendor", value: "customer" },
        { text: "Transaction", value: "transaction" },
        { text: "Created From", value: "createdfrom" },
        { text: "Expenses", value: "expensescategory" },
        { text: "Method", value: "assignmentmethod" },
        { text: "Assigned", value: "amount" },
      ],
      expensescategory: [
        {
          _id: "deliverycost",
          name: "Delivery Cost",
          collection: "classification",
          recordtype: "expensescategory",
        },
        {
          _id: "importdutycost",
          name: "Import Duty Cost",
          collection: "classification",
          recordtype: "expensescategory",
        },
        {
          _id: "importdutydelivery",
          name: "Delivery Cost (Import)",
          collection: "classification",
          recordtype: "expensescategory",
        },
      ],
      assignmentmethod: [
        { _id: "perweight", name: "Per Weight" },
        { _id: "pervalue", name: "Per Value" },
      ],
      items: [],
      rows: [25, 50, 100],
      singleSelect: false,
      selected: [],
      search: "",
      show: false,
    };
  },
  async created() {
    this.loading = true;
    console.log("create");
    await this.getData();

    this.items.forEach((item) => {
      this.record["transactionappliedto"].forEach((line, index) => {
        if (item._id == line.appliedto._id) {
          item.amount = line.amount;
          item.grossamount = line.amount;
          item.taxamount = 0;
          item.assignmentmethod = line.assignmentmethod;
          item.expensescategory = line.expensescategory;
          this.selected.push(item);
        }
      });
    });

    this.loading = false;
  },
  watch: {
    "record.company": function (newVal = {}, oldVal = {}) {
      if (newVal._id != oldVal._id) this.getData();
    },
    selected: {
      handler: async function (val) {
        this.refresh();
      },
      deep: true,
    },
  },
  methods: {
    refresh() {
      let newArray = [];
      let shift = 0;
      this.selected.forEach((line) => {
        line.assignmentmethod =
          line.assignmentmethod || this.record.assignmentmethod;
        line.expensescategory =
          line.expensescategory || this.record.expensescategory;
        newArray.push({
          appliedto: line.transaction,
          amount: line.amount,
          grossamount: line.amount,
          taxamount: 0,
          assignmentmethod: line.assignmentmethod,
          expensescategory: line.expensescategory,
        });
      });
      this.record["transactionappliedto"] = this.record[
        "transactionappliedto"
      ].filter((line, index) => {
        line.deleted = true;
        return line.created && line.created != "new";
      });
      this.record["transactionappliedto"].push(...newArray);
    },
    async getData() {
      this.loading = true;
      let result = await service(
        "orders",
        {
          action: "getItemReceipts",
          company: this.record.company
            ? this.record.company._id || this.record.company
            : null,
          rid: this.record._id,
        },
        false,
        true
      );
      console.log(result);
      this.items = result.items;
      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
  },
};
</script>
<template>
  <div class="text-xs-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on }">
        <v-list-item-avatar v-on="on">
          <img v-if="!loading" src="..\..\assets\3CERP.png" />
          <v-progress-circular
            v-else
            :size="50"
            color="primary"
            indeterminate
          />
        </v-list-item-avatar>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-avatar v-if="!loading" color="#363636" size="48">
                <span class="white--text text-h5">{{ initials }}</span>
              </v-avatar>
              <v-progress-circular
                v-else
                :size="50"
                color="primary"
                indeterminate
              />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ user }}</v-list-item-title>
              <v-list-item-subtitle class="text-overline">{{ jobtitle }}</v-list-item-subtitle>
            </v-list-item-content>

            <!-- <v-list-item-action>
              <v-btn icon @click="profil">
                <v-icon>mdi-account-box</v-icon>
              </v-btn>
            </v-list-item-action> -->
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn block text @click="logout">
            <v-icon>mdi-logout</v-icon>
            Log Out
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import service from "../../api/index";
/* eslint-disable */
export default {
  data: () => ({
    loading: true,
    fav: true,
    menu: false,
    message: false,
    hints: true,
    photosrc: "",
  }),

  async created() {
    await this.getAvatar();
  },
  watch: {
    "$store.state.user": async function () {
      this.items = await this.getAvatar();
      this.loading = false;
    },
  },
  methods: {
    profil() {
      console.log(this.$store.state.user);
      this.$router.push({
        path: `/${this.$store.state.user.collection}/${this.$store.state.user.recordtype}/view/${this.$store.state.user._id}`,
      });
    },
    getAvatar() {
      this.loading = true;

      let parameters = [{ _id: this.$store.state.user.photo }];
      let data = {
        action: "findOne",
        data: parameters,
      };
      return service("files", data)
        .catch((error) => {
          this.loading = false;
          throw error;
        })
        .then((response) => {
          this.photosrc = response.url;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    logout() {
      this.$store.dispatch("verifyToken", false);
      this.$router.push({ name: "Login" });
      this.menu = false;
    },
  },
  computed: {
    user() {
      return this.$store.getters.userName;
    },
    jobtitle() {
      return (
        this.$store.state.user.jobtitle || this.$store.state.user.recordtype
      );
    },
    initials() {
      return `${this.$store.state.user.name[0]}${
        this.$store.state.user.lastname
          ? this.$store.state.user.lastname[0]
          : this.$store.state.user.name[1]
      }`;
    },
  },
};
</script>

 <template>
  <v-container fluid id="reportview">
    <v-row v-if="!loading">
      <v-col>
        <v-select
          :items="companies"
          v-model="company"
          label="Company"
          item-text="name"
          item-value="_id"
          outlined
          clearable
          multiple
          hide-details
          class="mt-2 mb-2"
          dense
        ></v-select>
        <v-select
          :items="currencies"
          v-model="currency"
          label="Currency"
          item-text="name"
          item-value="_id"
          outlined
          clearable
          dense
        ></v-select>
        <!-- <v-switch v-model="intercompany" label="Inter-Company Transactions"></v-switch> -->
      </v-col>
      <v-col>
        <v-menu
          v-model="menufrom"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Date from"
              placeholder
              v-model="datefrom"
              append-icon="event"
              readonly
              v-on="on"
              outlined
              dense
              hide-details
              clearable
              class="mt-2 mb-2"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="datefrom"
            :first-day-of-week="1"
          ></v-date-picker>
        </v-menu>
        <v-menu
          v-model="menuto"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Date to"
              placeholder
              v-model="dateto"
              append-icon="event"
              readonly
              v-on="on"
              outlined
              dense
              hide-details
              clearable
              class="mt-2 mb-2"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateto"
            :first-day-of-week="1"
          ></v-date-picker>
        </v-menu>
        <v-select
          :items="periods"
          v-model="period"
          label="Periods"
          item-text="name"
          item-value="_id"
          class="mt-2 mb-0"
          outlined
          clearable
          hide-details
          dense
        ></v-select>
        <v-switch v-model="compare" label="Compare"></v-switch>
      </v-col>

      <v-col>
        <v-select
          :items="manufacturers"
          v-model="manufacturer"
          label="Manufacturers"
          item-text="name"
          item-value="_id"
          class="mt-2 mb-2"
          outlined
          clearable
          multiple
          dense
        ></v-select>
      </v-col>
      <!-- <v-col> -->
      <!-- <v-switch v-model="compare" label="Compare"></v-switch> -->
      <!-- <v-switch v-model="currency" label="Original Currency"></v-switch> -->
      <!-- <v-switch v-model="quarterly" label="Quarterly"></v-switch> -->
      <!-- </v-col> -->
      <v-col>
        <v-btn @click="getData()" block tile color="primary" class="mt-2 mb-2"
          >Refresh</v-btn
        >
      </v-col>
      <v-col>
        <v-btn
          @click="printReport()"
          block
          tile
          color="primary"
          class="mt-2 mb-2"
          >Print</v-btn
        >
      </v-col>
      <v-col class="text-right">
        <v-btn color="primary" block class="mt-2 mb-2" @click="show = !show">
          Reports
          <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-progress-linear indeterminate v-else></v-progress-linear>
    <v-row>
      <v-col class="ma-0 pa-0">
        <v-expand-transition>
          <div v-show="show">
            <v-divider></v-divider>
            <v-tabs dark v-model="active">
              <v-tab :key="1">Ozparts - Sales by item</v-tab>
              <v-tab-item :key="1">
                <queryresult
                  used="customer"
                  queryid="5e79df6d210ccd11f4c965aa"
                  :filters="filters"
                  v-if="active == 0"
                />
              </v-tab-item>
            </v-tabs>
          </div>
        </v-expand-transition>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <div v-if="!loading" ref="reportview" id="printarea" fluid>
      <v-row class="first" style="display: none; padding: 20%"
        ><v-col class="text-center"
          ><img src="https://3cerp.eu/api/file/ozparts_logo_standard.jpg" />
          <h1>{{ customer.name }}</h1>
          <h3>{{ datefrom }} - {{ dateto }}</h3>
        </v-col></v-row
      >
      <v-row>
        <v-col v-if="items.bymanufacturer" md="4">
          <v-subheader>Total Sales by Manufacturer</v-subheader>
          <doughnutchart
            :chartData="toChartData('doughnut', items.bymanufacturer)"
          />
        </v-col>
        <v-col v-if="items.bymanufacturer" md="8">
          <barchart
            :chartData="toChartData('bar', items.bymanufacturer)"
            :option="stacked"
          />
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col md="6">
          <v-subheader>Total Sales Overview</v-subheader>
          <v-data-table
            :headers="headers2"
            :items="items.sales"
            :loading="loading"
            loading-text="Loading... Please wait"
            item-key="name"
            hide-default-footer
          >
            <template v-slot:item.amount="{ item }">
              <span>
                {{
                  new Intl.NumberFormat(currency ? "en-EN" : "pl-PL", {
                    style: "currency",
                    currency: currency || "PLN",
                  }).format(item.amount)
                }}
              </span>
            </template>
            <template v-slot:item.compareamount="{ item }">
              <span>
                {{
                  new Intl.NumberFormat(currency ? "en-EN" : "pl-PL", {
                    style: "currency",
                    currency: currency || "PLN",
                  }).format(item.compareamount)
                }}
              </span>
            </template>
            <template v-slot:item.procquantity="{ item }">
              <v-chip
                label
                v-if="item.avgquantity"
                :color="
                  (item.quantity / item.avgquantity) * 100 - 100 < 0
                    ? 'red'
                    : 'green'
                "
                dark
                >{{
                  Math.round((item.quantity / item.avgquantity) * 100) - 100
                }}%</v-chip
              >
            </template>
            <template v-slot:item.procamount="{ item }">
              <v-chip
                label
                v-if="item.avgamount"
                :color="
                  (item.amount / item.avgamount) * 100 - 100 < 0
                    ? 'red'
                    : 'green'
                "
                dark
                >{{
                  Math.round((item.amount / item.avgamount) * 100) - 100
                }}%</v-chip
              >
            </template>
            <template v-slot:item.proccompareamount="{ item }">
              <v-chip
                label
                v-if="item.compareamount"
                :color="
                  (item.amount / item.compareamount) * 100 - 100 < 0
                    ? 'red'
                    : 'green'
                "
                dark
                >{{
                  Math.round((item.amount / item.compareamount) * 100) - 100
                }}%</v-chip
              >
            </template>
          </v-data-table>
        </v-col>
        <v-col md="6">
          <v-subheader>Top Items</v-subheader>
          <v-data-table
            :headers="headers"
            :items="items.items"
            :loading="loading"
            loading-text="Loading... Please wait"
            item-key="_id"
            :search="search"
            :footer-props="{ itemsPerPageOptions: rows }"
            :custom-filter="filter"
          ></v-data-table>
        </v-col>
      </v-row>
      <v-row v-if="mode == 'orders'">
        <v-col v-if="items.byordered">
          <v-subheader>{{ items.byordered.title }}</v-subheader>
          <barchart :chartData="toChartData('bar', items.byordered)" />
        </v-col>
      </v-row>
      <v-row
        v-for="manufacturer of items.bymanufacturer.datasets"
        :key="manufacturer.label"
      >
        <v-col v-if="items.bymanufacturer" md="6">
          <barchart
            :chartData="
              toChartData('bar', items.bymanufacturer, manufacturer.label)
            "
          />
        </v-col>

        <v-col md="6">
          <v-card flat>
            <v-subheader>{{ manufacturer.label }} Sales Overview</v-subheader>
            <v-data-table
              :headers="headers2"
              :items="items.salesbymanufacturer[manufacturer.label]"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="name"
              hide-default-footer
            >
              <template v-slot:item.amount="{ item }">
                <span>
                  {{
                    new Intl.NumberFormat(currency ? "en-EN" : "pl-PL", {
                      style: "currency",
                      currency: currency || "PLN",
                    }).format(item.amount)
                  }}
                </span>
              </template>
              <template v-slot:item.compareamount="{ item }">
                <span>
                  {{
                    new Intl.NumberFormat(currency ? "en-EN" : "pl-PL", {
                      style: "currency",
                      currency: currency || "PLN",
                    }).format(item.compareamount)
                  }}
                </span>
              </template>
              <template v-slot:item.procquantity="{ item }">
                <v-chip
                  label
                  v-if="item.avgquantity"
                  :color="
                    (item.quantity / item.avgquantity) * 100 - 100 < 0
                      ? 'red'
                      : 'green'
                  "
                  dark
                  >{{
                    Math.round((item.quantity / item.avgquantity) * 100) - 100
                  }}%</v-chip
                >
              </template>
              <template v-slot:item.procamount="{ item }">
                <v-chip
                  label
                  v-if="item.avgamount"
                  :color="
                    (item.amount / item.avgamount) * 100 - 100 < 0
                      ? 'red'
                      : 'green'
                  "
                  dark
                  >{{
                    Math.round((item.amount / item.avgamount) * 100) - 100
                  }}%</v-chip
                >
              </template>
              <template v-slot:item.proccompareamount="{ item }">
                <v-chip
                  label
                  v-if="item.compareamount"
                  :color="
                    (item.amount / item.compareamount) * 100 - 100 < 0
                      ? 'red'
                      : 'green'
                  "
                  dark
                  >{{
                    Math.round((item.amount / item.compareamount) * 100) - 100
                  }}%</v-chip
                >
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import piechart from "../../charts/pie";
import barchart from "../../charts/bar";
import linechart from "../../charts/line";
import doughnutchart from "../../charts/doughnut";
import colors from "../../charts/colors";
import filter from "../../dataTable/filter";
export default {
  props: ["customer", "mode"],
  data() {
    return {
      loading: true,
      filters: { customer: this.customer._id },
      datefrom: new Date(new Date().getFullYear() - 3, 0, 2)
        .toISOString()
        .substr(0, 10),
      dateto: null,
      search: null,
      compare: true,
      items: {},
      initdate: null,
      manufacturers: [],
      manufacturer: [],
      company: null,
      customercurriencies: [],
      currencies: [],
      currency: null,
      intercompany: false,
      companies: [],
      data: null,
      rows: [5],
      show: false,
      toPrint: "test",
      periods: [
        { name: "Weekly", _id: "weekly" },
        { name: "Monthly", _id: "monthly" },
        { name: "Quarterly", _id: "quarterly" },
        { name: "Yearly", _id: "yearly" },
      ],
      period: "quarterly",
      headers: [
        { text: "Item", value: "item" },
        { text: "Manufacturer", value: "manufacturergroup" },
        { text: "Makes", value: "makegroups" },
        { text: "Category", value: "category" },
        { text: "Sold", value: "quantity" },
        { text: "Last Sold", value: "date" },
      ],
      headers2: [
        { text: "Period", value: "name" },
        //{ text: "Sold", value: "quantity" },
        { text: "Value", value: "amount" },
        { text: "Value", value: "compareamount" },
        { text: "%", value: "proccompareamount" },
      ],
      menufrom: false,
      menuto: false,
      stacked: {
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
      },
      companiesFilter: [],
    };
  },
  async created() {
    if (this.$store.state.user.company)
      this.companiesFilter.push(this.$store.state.user.company);
    if (
      this.$store.state.user.forcompanies &&
      this.$store.state.user.forcompanies.length
    )
      this.companiesFilter.push(...this.$store.state.user.forcompanies);
    this.companies = await service("users", {
      action: "find",
      data: [
        {
          recordtype: "company",
          _id: this.companiesFilter.length
            ? { $in: this.companiesFilter }
            : { $exists: true },
        },
      ],
    });
    this.company = this.companiesFilter;
    this.manufacturers = await service("classification", {
      action: "find",
      data: [{ recordtype: "manufacturergroup" }],
    }).then((res) => {
      //res.forEach((manufacturer) => this.manufacturer.push(manufacturer._id));
      return res;
    });

    if (this.customer.recordtype == "company") this.intercompany = true;

    await this.getData();
  },
  watch: {
    async period() {
      await this.getData();
    },
    async currency() {
      await this.getData();
    },
  },
  methods: {
    adjust(color, amount) {
      return (
        "#" +
        color
          .replace(/^#/, "")
          .replace(/../g, (color) =>
            (
              "0" +
              Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(
                16
              )
            ).substr(-2)
          )
      );
    },
    toChartData(type, data, filter) {
      let chartData = JSON.parse(JSON.stringify(data));
      if (type == "pie" || type == "doughnut") {
        chartData.datasets = chartData.datasets2;
        chartData.labels = chartData.labels2;
        chartData.datasets.map((data) => {
          data.backgroundColor = colors;
        });
      } else {
        chartData.datasets.map((data, index) => {
          data.backgroundColor = colors[index];
        });
        if (filter) {
          let tmpLabels = [];
          let tmpDatasets = {};
          if (this.compare) {
            chartData.labels.forEach((label) => {
              if (!tmpLabels.includes(label.split("-")[1]))
                tmpLabels.push(label.split("-")[1]);
              if (!tmpLabels.includes(label.split("-")[0]))
                tmpDatasets[label.split("-")[0]] = {
                  label: label.split("-")[0],
                  data: [],
                  fill: false,
                };
            });
          }
          chartData.datasets = chartData.datasets.filter((data) => {
            console.log(filter, data.label);
            return data.label == filter;
          });
          if (this.compare) {
            Object.keys(tmpDatasets).forEach((set, index) => {
              console.log(set, index);
              tmpDatasets[set].backgroundColor = this.adjust(
                chartData.datasets[0].backgroundColor,
                index * -50
              );
            });
            chartData.datasets[0].data.forEach((data, index) => {
              tmpDatasets[chartData.labels[index].split("-")[0]].data.push(
                data
              );
            });
            chartData.labels = tmpLabels;
            chartData.datasets = Object.values(tmpDatasets);
          }
        }
      }
      return chartData;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    async getData() {
      this.loading = true;
      let data = await service(
        "report",
        {
          action: "getStatistics",
          datefrom: this.datefrom,
          dateto: this.dateto,
          customer: this.customer._id,
          period: this.period,
          compare: this.compare,
          manufacturer: this.manufacturer,
          company: this.company,
          intercompany: this.intercompany,
          mode: this.mode,
          currency: this.currency,
        },
        false,
        true
      );
      //this.data = data.data;
      this.items = data.items;
      this.customercurriencies = data.currencies;
      this.currencies = await service("accounting", {
        action: "find",
        data: [
          { recordtype: "currency", _id: { $in: this.customercurriencies } },
        ],
      });
      this.loading = false;
    },
    printReport() {
      document.querySelector("#printarea").classList.add("active");
      document.querySelector(".row.first").style.display = "";
      document.querySelector(".v-application--wrap").style.display = "none";
      document.querySelector("#app").appendChild(this.$refs.reportview);
      //document.querySelectorAll(".chartjs-size-monitor")
      let charts = document.querySelectorAll(".chartjs-size-monitor");
      charts.forEach((chart) => {
        chart.style.display = "none";
      });
      document
        .querySelector("#printarea")
        .insertAdjacentHTML(
          "afterend",
          '<style type="text/css" media="print">@media print {  @page {    size: A3 landscape;    margin: 20;  }}</style>'
        );
      window.focus();
      window.print();
      document.querySelector("#reportview").appendChild(this.$refs.reportview);
      document.querySelector(".v-application--wrap").style.display = "";
      document.querySelector(".row.first").style.display = "none";
      document.querySelector("#printarea").classList.remove("active");
    },
  },
  components: { piechart, barchart, linechart, doughnutchart },
};
</script>
<style scoped>
#printarea {
  background-color: #ffffff !important;
}
#printarea .row {
  page-break-inside: avoid;
}
#printarea .row.first {
  page-break-after: always;
}
#printarea.active .row {
  padding: 50px 20px;
}
</style>
 <template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          loading-text="Loading... Please wait"
          item-key="_id"
          :search="search"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
        >
          <template v-slot:item.date="{ item }">
            <span>{{ new Date(item.date).toISOString().substr(0, 10) }}</span>
          </template>
          <template v-slot:item.transaction="{ item }">
            <router-link
              :to="{
                path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
              }"
              class="link"
              >{{
                item.transaction.displayname || item.transaction.name
              }}</router-link
            >
          </template>
          <template v-slot:item.customer="{ item }">
            <router-link
              :to="{
                path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
              }"
              class="link"
              >{{
                item.customer.displayname || item.customer.name
              }}</router-link
            >
          </template>
          <template v-slot:item.accountonhold="{ item }">
            <v-chip label :color="item.accountonhold ? 'red' : 'none'" dark>{{
              item.accountonhold ? "Yes" : "No"
            }}</v-chip>
          </template>
          <template v-slot:item.openinvoices="{ item }">
            <v-chip label :color="item.openinvoices ? 'red' : 'none'" dark>{{
              item.openinvoices ? "Yes" : "No"
            }}</v-chip>
          </template>
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col>
                  <exportData :headers="headers" :items="items" />
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                  <v-select
                    :items="companies"
                    v-model="company"
                    label="Company"
                    item-text="name"
                    item-value="_id"
                    class="mt-2 mb-2"
                    outlined
                    multiple
                    dense
                  ></v-select>
                </v-col>
                <v-col>
                  <v-btn
                    @click="getData()"
                    block
                    tile
                    color="primary"
                    class="mt-2 mb-2"
                    >Refresh</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";
export default {
  props: ["customer"],
  data() {
    return {
      loading: false,
      company: [],
      companies: [],
      headers: [
        { text: "Date", value: "date" },
        { text: "Type", value: "recordtype" },
        { text: "Payment", value: "transaction", sortable: false },
        { text: "Payment Method", value: "paymentmethod" },
        { text: "Customer", value: "customer", sortable: false },
        { text: "Enova Type", value: "customtype", sortable: false },
        { text: "Currency", value: "currency" },
        { text: "Unapplied", value: "unapplied" },
        { text: "Account on Hold", value: "accountonhold" },
        { text: "Open Invoices", value: "openinvoices" },
      ],
      items: [],
      rows: [100],
      search: "",
      show: false,
    };
  },
  async created() {
    this.loading = true;
    this.companies = await service("users", {
      action: "find",
      data: [{ recordtype: "company" }],
    });
    //await this.getData();
    this.loading = false;
  },
  methods: {
    async getData() {
      this.loading = true;
      let result = await service(
        "orders",
        {
          action: "getOpenPayments",
          company: this.company,
          customer: this.customer,
        },
        false,
        true
      );
      this.items = result.items;
      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
  },
  components: {
    exportData: exportData,
  },
};
</script>
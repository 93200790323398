var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-tabs',{attrs:{"dark":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_vm._l((Object.values(_vm.tabs)),function(tab,index){return _c('v-tab',{key:index},[_c('v-badge',{attrs:{"color":"primary"}},[_c('span',[_vm._v(_vm._s(tab.name))]),_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_vm._v(_vm._s(tab.items.length))])])],1)}),_vm._l((Object.values(_vm.tabs)),function(tab,index){return _c('v-tab-item',{key:index},[_c('v-data-table',{attrs:{"headers":tab.headers,"items":tab.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","show-select":false,"search":_vm.search,"single-select":_vm.singleSelect,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter,"custom-sort":_vm.sort,"item-class":_vm.rowClass},on:{"current-items":_vm.getFiltered},scopedSlots:_vm._u([{key:"item.payment_done",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.payment_done ? 'green' : 'red',"dark":""}},[_vm._v(_vm._s(item.payment_done ? "Paid" : "Unpaid"))])]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.transaction)?_c('div',[_c('router-link',{staticClass:"link",attrs:{"to":{
                      path: ("/users/customer/view/" + (item.transaction.customer._id)),
                    }}},[_vm._v(_vm._s(item.transaction.customer.displayname || item.transaction.customer.name))])],1):_c('div',[_vm._v(_vm._s(item.customer))]),(item.email)?_c('p',[_vm._v(_vm._s(item.email))]):_c('v-text-field',{staticClass:"mt-0 mb-4",attrs:{"placeholder":"email","flat":"","error":"","dense":"","hide-details":""},on:{"change":function($event){return _vm.setField(item, 'email')}},model:{value:(item.email),callback:function ($$v) {_vm.$set(item, "email", $$v)},expression:"item.email"}}),_c('div',[_vm._v(_vm._s(item.source))]),_c('div',[_vm._v(_vm._s(item.date))])],1)]}},{key:"item.order_status_id",fn:function(ref){
                    var item = ref.item;
return [_c('div',[(item.validation && item.validation.length)?_c('v-alert',{attrs:{"dense":"","type":"error"}},_vm._l((item.validation),function(dif,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(dif.field)+" ")])}),0):_c('v-alert',{attrs:{"dense":"","type":item.transaction ? 'success' : 'warning'}},[_vm._v(" "+_vm._s(!item.transaction ? "Not Connected" : "OK")+" ")]),_c('v-select',{staticClass:"mt-2 mb-2",attrs:{"items":_vm.statuses,"label":"Status","item-text":"name","item-value":"id","outlined":"","dense":"","hide-details":""},on:{"change":function($event){return _vm.setStatus(item)}},model:{value:(item.order_status_id),callback:function ($$v) {_vm.$set(item, "order_status_id", $$v)},expression:"item.order_status_id"}})],1)]}},{key:"item.order_id",fn:function(ref){
                    var item = ref.item;
return [_c('div',[_c('p',[_c('a',{staticClass:"link",attrs:{"href":item.order_page,"target":"_blank"}},[_vm._v(_vm._s(item.order_id))])]),_c('div',[_vm._v(_vm._s(item.payment_method))]),_c('div',[_vm._v(_vm._s(item.delivery_method))])])]}},{key:"item.transaction",fn:function(ref){
                    var item = ref.item;
return [(item.transaction)?_c('div',[_c('router-link',{staticClass:"link",attrs:{"to":{
                    path: ("/" + (item.transaction.collection) + "/" + (item.transaction.recordtype) + "/view/" + (item.transaction._id)),
                  }}},[_vm._v(_vm._s(item.transaction.displayname || item.transaction.name))]),_c('p',[_vm._v(_vm._s(item.transaction.status))])],1):_c('v-btn',{attrs:{"tile":"","color":"primary","text":"","disabled":_vm.loading2},on:{"click":function($event){return _vm.create(item)}}},[_vm._v("Create an Order")])]}},{key:"item.transactions",fn:function(ref){
                  var item = ref.item;
return _vm._l((item.transactions),function(transaction){return _c('router-link',{key:transaction._id,staticClass:"link",attrs:{"to":{
                  path: ("/" + (transaction.collection) + "/" + (transaction.recordtype) + "/view/" + (transaction._id)),
                }}},[_vm._v(_vm._s(transaction.displayname || transaction.name))])})}},{key:"item.products",fn:function(ref){
                var item = ref.item;
return _vm._l((item.products),function(product,index){return _c('div',{key:index},[(product.item)?_c('router-link',{staticClass:"link",attrs:{"to":{
                    path: ("/items/" + (product.item.recordtype) + "/view/" + (product.item._id)),
                  }}},[_vm._v(_vm._s(product.quantity)+"x "+_vm._s(product.item.displayname || product.item.name))]):_c('div',[_vm._v(_vm._s(product.quantity)+"x "+_vm._s(product.name))])],1)})}},{key:"item.admin_comments",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"my-3"},[_vm._v(" "+_vm._s(item.user_comments)+" "),_c('v-textarea',{staticClass:"mt-2",attrs:{"outlined":"","hide-details":"","dense":"","label":"Internal Comment","value":item.admin_comments},on:{"change":function($event){return _vm.setField(item, 'admin_comments')}},model:{value:(item.admin_comments),callback:function ($$v) {_vm.$set(item, "admin_comments", $$v)},expression:"item.admin_comments"}})],1)]}},{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('exportData',{attrs:{"headers":_vm.headers,"items":_vm.items}})],1),_c('v-col'),_c('v-col',[_c('v-btn',{attrs:{"block":"","tile":"","color":"primary","disabled":_vm.loading},on:{"click":function($event){return _vm.getData()}}},[_vm._v("Refresh")])],1),_c('v-spacer'),(false)?_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('v-icon',[_vm._v(_vm._s(_vm.show ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1):_vm._e()],1)],1)]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
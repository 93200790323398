<template>
  <v-form
    v-model="valid"
    lazy-validation
    ref="form"
    :disabled="
      query.recordtype == 'report' &&
      $route.params.mode == 'edit' &&
      ((query.employee && query.employee._id != $store.state.user._id) ||
        !query.employee)
    "
  >
    <record-toolbar :record="query" :form="$refs.form"></record-toolbar>

    <v-progress-linear
      :indeterminate="loading"
      height="3"
      class="ma-0"
    ></v-progress-linear>

    <v-stepper v-model="activeStep" v-if="!loading">
      <v-stepper-header>
        <template v-for="(step, index) in steps">
          <v-stepper-step
            :key="`${index}-step`"
            :step="index + 1"
            :complete="activeStep > index + 1"
            :editable="query.setup.collection"
            >{{ step.name }}</v-stepper-step
          >
        </template>
      </v-stepper-header>
      <v-divider></v-divider>
      <v-stepper-items>
        <v-stepper-content
          v-for="(step, index) in steps"
          :key="`${index}-content`"
          :step="index + 1"
          class="pa-0"
        >
          <component
            :is="step.component"
            :mode="mode"
            :rid="rid"
            :query="query"
            :fieldsObj="fieldsObj"
            :fields="fields"
            :subrecords="subrecords"
            :valid="valid"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-snackbar v-model="msg.show" :timeout="6000" top absolute right>{{
      msg.message
    }}</v-snackbar>
  </v-form>
</template>

<script>
import toolbar from "../../../components/record/toolbar";
import stages from "./stages";
import setup from "./setup";
import columns from "./columns";
import schedule from "./schedule";
import saveRecord from "../../../common/methods/saveRecord";
import deleteRecord from "../../../common/methods/deleteRecord";
import getRecord from "../../../common/methods/getRecord";
import service from "../../../api/index";
import getFields from "../../../common/methods/getFields";
/* eslint-disable */
export default {
  data() {
    return {
      valid: false,
      loading: true,
      msg: false,
      activeStep: 1,
      fieldsObj: {},
      fields: [],

      fixedfields: {
        company:{
          _id: "company",
          name: "Company",
          recordtype: "field",
          collection: "fields",
          setup: {
            type: "field-autocomplete",
            collection: "users",
          },
          form: [
            { collection: "transactions_lines" },
            { collection: "deliveries_items" },
          ],
        },
        customer: {
          _id: "customer",
          name: "Customer",
          recordtype: "field",
          collection: "fields",
          setup: {
            type: "field-autocomplete",
            collection: "users",
          },
          form: [
            { collection: "transactions_lines" },
            { collection: "deliveries_items" },
          ],
        },
        created: {
          _id: "created",
          name: "Created",
          recordtype: "field",
          collection: "fields",
          setup: {
            type: "field-date",
          },
          form: [
            { collection: "transactions" },
            { collection: "transactions_lines" },
            { collection: "items" },
            { collection: "users" },
            { collection: "deliveries" },
            { collection: "deliveries_items" },
          ],
        },
        datelastsales: {
          _id: "datelastsales",
          name: "Date Last Sales",
          recordtype: "field",
          collection: "fields",
          setup: {
            type: "field-date",
          },
          form: [{ collection: "items_inventory" }],
        },
        datefirstsales: {
          _id: "datefirstsales",
          name: "Date First Sales",
          recordtype: "field",
          collection: "fields",
          setup: {
            type: "field-date",
          },
          form: [{ collection: "items_inventory" }],
        },
        recordtype: {
          _id: "recordtype",
          name: "Record Type",
          recordtype: "field",
          collection: "fields",
          setup: {
            type: "field-select",
            collection: "recordtypes",
          },
          form: [
            { collection: "transactions" },
            { collection: "transactions_lines" },
            { collection: "items" },
            { collection: "users" },
            { collection: "deliveries" },
            { collection: "deliveries_items" },
          ],
        },
        parentrecordtype: {
          _id: "parentrecordtype",
          name: "Parent Record Type",
          recordtype: "field",
          collection: "fields",
          setup: {
            type: "field-select",
            collection: "recordtypes",
          },
          form: [
            { collection: "transactions_lines" },
            { collection: "deliveries_items" },
          ],
        },
        date: {
          _id: "date",
          name: "Date",
          recordtype: "field",
          collection: "fields",
          setup: {
            type: "field-date",
          },
          form: [{ collection: "transactions_lines" }],
        },
        currency: {
          _id: "currency",
          name: "Currency",
          recordtype: "field",
          collection: "fields",
          setup: {
            type: "field-select",
            collection: "accounting",
          },
          form: [{ collection: "transactions_lines" }],
        },
        exchangerate: {
          _id: "exchangerate",
          name: "Exchange Rate",
          recordtype: "field",
          collection: "fields",
          setup: {
            type: "field-currency",
            precision: 4,
          },
          form: [{ collection: "transactions_lines" }],
        },
        transaction: {
          _id: "transaction",
          name: "Transaction",
          recordtype: "field",
          collection: "fields",
          setup: {
            type: "field-autocomplete",
            collection: "transactions",
          },
          form: [{ collection: "transactions_lines" }],
        },
        relatedtransactionsbycustomer: {
          _id: "relatedtransactionsbycustomer",
          name: "Related Transactions",
          recordtype: "subrecords",
          collection: "fields",
          setup: {
            collection: "transactions_lines",
            parentrecordtype: "NONE",
            localField: "_id",
            foreignField: "customer",
          },
          form: [{ collection: "users" }],
        },
        relatedtransactionsbyitem: {
          _id: "relatedtransactionsbyitem",
          name: "Related Transactions",
          recordtype: "subrecords",
          collection: "fields",
          setup: {
            collection: "transactions_lines",
            parentrecordtype: "NONE",
            localField: "_id",
            foreignField: "item",
          },
          form: [{ collection: "items" }],
        },
        changelogs: {
          _id: "changelogs",
          name: "Change Logs",
          recordtype: "sublist",
          collection: "fields",
          setup: {
            collection: "logs",
            parentrecordtype: "NONE",
            localField: "_id",
            foreignField: "record",
          },
          form: [{ collection: "all" }],
        },
        formulatext: {
          _id: "formulatext",
          name: "Formula Text",
          recordtype: "field",
          collection: "fields",
          setup: {
            type: "field-text",
          },
          form: [{ collection: "all" }],
        },
        formulacurrency: {
          _id: "formulacurrency",
          name: "Formula Currency",
          recordtype: "field",
          collection: "fields",
          setup: {
            type: "field-currency",
          },
          form: [{ collection: "all" }],
        },
        formulaint: {
          _id: "formulaint",
          name: "Formula Int",
          recordtype: "field",
          collection: "fields",
          setup: {
            type: "field-int",
          },
          form: [{ collection: "all" }],
        },
        formulapercent: {
          _id: "formulapercent",
          name: "Formula Percent",
          recordtype: "field",
          collection: "fields",
          setup: {
            type: "field-percent",
          },
          form: [{ collection: "all" }],
        },
      },
      subrecords: [],
      steps: [
        { name: "Query Setup", component: "setup" },
        { name: "Query Filters", component: "stages" },
        { name: "Query Columns", component: "columns" },
       // { name: "Query Schedule", component: "schedule" },
      ],
      mode: this.$route.params.mode,
      rid: this.$route.params.id,
      query: {
        recordtype: "report",
        collection: "queries",
        setup: { columns: [], filters: [] },
      },
    };
  },
  components: {
    stages,
    setup,
    columns,
    schedule,
    "record-toolbar": toolbar,
  },
  methods: {
    async getFields() {
      //if (this.settings.recordtype) {
      this.fieldsObj = await getFields(this, {});
      Object.assign(this.fieldsObj, this.fixedfields);
      await this.getForms(this.query.setup.collection);
      Object.entries(this.fieldsObj).forEach(([key, value]) => {
        if (value.recordtype == "field") this.fields.push(value);
        if (value.recordtype == "subrecords" || value.recordtype == "sublist")
          this.subrecords.push(value);
      });
      // }
    },
    async getForms(collection) {
      let forms = await service("forms", {
        action: "find",
        data: [
          {
            //"setup.collection": collection,
            recordtype: "form",
          },
        ],
      });
      //console.log(forms)
      forms.forEach((form) => {
        form.tabs.forEach((tab) => {
          tab.sections.forEach((section) => {
            (section.columns || []).forEach((column) => {
              column.forEach((field) => {
                //console.log(field)
                if (this.fieldsObj[field.field]) {
                  this.fieldsObj[field.field].form =
                    this.fieldsObj[field.field].form || [];
                  this.fieldsObj[field.field].form.push({
                    name: form.name,
                    _id: form._id,
                    recordtype: form.setup.recordtype,
                    collection: form.setup.collection,
                  });
                }
                //this.fields.push(field);
              });
            });
            (section.fields || []).forEach((field) => {
              if (this.fieldsObj[field.field]) {
                this.fieldsObj[field.field].form =
                  this.fieldsObj[field.field].form || [];
                this.fieldsObj[field.field].form.push({
                  name: form.name,
                  _id: form._id,
                  recordtype: form.setup.recordtype,
                  collection: form.setup.collection,
                });
              }
              //this.fields.push(field);
            });
            if (section.sublist) {
              if (this.fieldsObj[section.sublist.field]) {
                this.fieldsObj[section.sublist.field].form =
                  this.fieldsObj[section.sublist.field].form || [];
                this.fieldsObj[section.sublist.field].form.push({
                  name: form.name,
                  _id: form._id,
                  recordtype: form.setup.recordtype,
                  collection: form.setup.collection,
                });
              }
              for (const [key, value] of Object.entries(
                this.fieldsObj[section.sublist.field].setup.filters || {}
              )) {
                if (this.fieldsObj[key]) {
                  this.fieldsObj[key].form = this.fieldsObj[key].form || [];
                  this.fieldsObj[key].form.push({
                    name: form.name,
                    _id: form._id,
                    recordtype: form.setup.recordtype,
                    collection:
                      this.fieldsObj[section.sublist.field].setup.collection,
                  });
                }
              }

              (section.sublist.columns || []).forEach((column) => {
                this.fieldsObj[column.field].form =
                  this.fieldsObj[column.field].form || [];
                this.fieldsObj[column.field].form.push({
                  name: form.name,
                  _id: form._id,
                  recordtype: form.setup.recordtype,
                  collection:
                    this.fieldsObj[section.sublist.field].setup.collection,
                });
              });
            }
          });
        });
      });
      console.log(this.fieldsObj);
    },
  },
  async created() {
    try {
      if (this.mode != "create") {
        this.query = await getRecord(this, this.rid, "queries", "query");
        // this.query.stages.forEach(stage => {
        //   stage.type = { type: stage.type };
        // });
      }
      await this.getFields();
      for (let [key, value] of Object.entries(this.$route.params)) {
        this.$set(this.query, key, value);
      }
      this.loading = false;
    } catch (error) {
      this.msg = { message: `${error.message}`, show: true };
    }
  },
};
</script>

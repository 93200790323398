<template>
  <v-card-title
    v-if="$route.params.recordtype == 'itemfulfillment'  && $route.params.mode && $route.params.mode!='view' && settings && settings._id == 'transactionlines'"
  >
    <v-spacer></v-spacer>
    <v-text-field
      v-model="scan"
      append-icon="search"
      label="Search"
      v-on:keyup="decodeBarcode"
      single-line
      hide-details
      outlined
    ></v-text-field>
  </v-card-title>
</template>
<script>
export default {
  props: ["settings","items"],
  data() {
    return {
      scan: ""
    };
  },
  methods: {
    decodeBarcode(e) {
      if (e.keyCode === 13) {
        for (let line of this.items) {
          if (this.scan == line.item.barcode) {
            line.quantity++;
            if (line.item.recordtype == "kititem") {
              this.items.forEach(subline => {
                if (subline.init == line._id) {
                  subline.quantity = subline.componentquantity * line.quantity;
                }
              });
            }
            if (line.init) {
              let components = [];
              let kitline = false;
              this.items.forEach(subline => {
                if (subline._id == line.init) kitline = subline;
                if (subline.init == line.init) {
                  components.push(
                    parseInt(line.quantity || 0) / line.componentquantity
                  );
                }
              });
              kitline.quantity = Math.floor(Math.min(components));
            }
            break;
          }
        }
        this.scan = "";
      }
    }
  }
};
</script>>
<template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          loading-text="Loading... Please wait"
          item-key="_id"
          :show-select="!!vendor"
          v-model="selected"
          :search="search"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
          :custom-sort="sort"
          @current-items="getFiltered"
        >
          <template v-slot:item.date="{ item }">
            <span class="d-block"
              >{{ new Date(item.created).toISOString().substr(0, 10) }}:
              {{
                item.created
                  ? new Date(
                      new Date(item.created).getTime() -
                        new Date(item.created).getTimezoneOffset() * 60000
                    )
                      .toISOString()
                      .substr(11, 5)
                  : ""
              }}</span
            >
            <span> {{ item.createdby.name }}</span>
          </template>
          <template v-slot:item.created="{ item }">
            <span></span>
          </template>
          <template v-slot:item.transaction="{ item }">
            <router-link
              v-if="hasAccess"
              :to="{
                path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
              }"
              class="link"
              >{{
                item.transaction.displayname || item.transaction.name
              }}</router-link
            ><span v-else>{{
              item.transaction.displayname || item.transaction.name
            }}</span>
          </template>
          <template v-slot:item.internalspecialorders="{ item }">
            <div v-if="hasAccess">
              <router-link
                v-for="(po, index) of item.internalspecialorders"
                :key="index"
                :to="{
                  path: `/${po.collection}/${po.recordtype}/view/${po._id}`,
                }"
                class="link"
                >{{ po.displayname || po.name }}</router-link
              >
            </div>
            <span
              v-else
              v-for="(po, index) of item.internalspecialorders"
              :key="index"
              >{{ po.displayname || po.name }}</span
            >
          </template>
          <template v-slot:item.specialorders="{ item }">
            <div v-if="hasAccess">
              <router-link
                v-for="(po, index) of item.specialorders"
                :key="index"
                :to="{
                  path: `/${po.collection}/${po.recordtype}/view/${po._id}`,
                }"
                class="link"
                >{{ po.displayname || po.name }}</router-link
              >
            </div>
            <span
              v-else
              v-for="(po, index) of item.specialorders"
              :key="index"
              >{{ po.displayname || po.name }}</span
            >
          </template>
          <template v-slot:item.deliverymethod="{ item }">
            <span v-for="(po, index) of item.specialorders" :key="index">{{
              po.deliverymethod
            }}</span>
          </template>
          <template v-slot:item.additionalservice="{ item }">
            <span v-for="(po, index) of item.additionalservice" :key="index">{{
              po ? po.displayname || po.name : ""
            }}</span>
          </template>
          <template v-slot:item.item="{ item }">
            <router-link
              v-if="hasAccess"
              :to="{
                path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`,
              }"
              class="link"
              >{{ item.item.displayname || item.item.name }}</router-link
            >
            <span v-else>{{ item.item.displayname || item.item.name }}</span>
          </template>
          <template v-slot:item.customer="{ item }">
            <router-link
              v-if="hasAccess"
              :to="{
                path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
              }"
              class="link"
              >{{
                item.customer.displayname || item.customer.name
              }}</router-link
            >
            <span v-else>{{
              item.customer.displayname || item.customer.name
            }}</span>
          </template>
          <template v-slot:item.createdby="{ item }">
            {{ item.createdby.name }}
          </template>
          <template v-slot:item.confirmed="{ item }">
            <v-switch
              label
              inset
              v-model="item.confirmed"
              @change="changeDate($event, item._id, 'confirmed')"
            >
            </v-switch>
          </template>
          <template v-slot:item.etd="{ item }">
            <v-menu
              v-model="item.menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  placeholder
                  v-model="item.etd"
                  append-icon="event"
                  readonly
                  v-on="on"
                  outlined
                  dense
                  hide-details
                  clearable
                  @change="changeDate($event, item._id, 'etd')"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="item.etd"
                @input="item.menu = false"
                @change="changeDate($event, item._id, 'etd')"
                :first-day-of-week="1"
              ></v-date-picker>
            </v-menu>
          </template>
          <template v-slot:item.memo="{ item }">
            <v-textarea
              v-model="item.memo"
              @change="changeDate($event, item._id, 'memo')"
            ></v-textarea>
          </template>
          <template v-slot:item.quantityonorder="{ item }">
            {{ item.quantitybackorder }} / {{ item.quantityonorder }}
          </template>

          <template
            v-slot:item.data-table-select="{ isSelected, select, item }"
          >
            <v-simple-checkbox
              color="primary"
              :value="isSelected"
              @input="select($event)"
              :disabled="item.confirmed"
            ></v-simple-checkbox>
          </template>
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col>
                  <exportData
                    :headers="headersExport"
                    :items="filteredItems"
                    :customexport="{
                      headers: headersAllExport,
                      name: 'All Columns',
                    }"
                  />
                </v-col>
                <v-col v-if="hasAccess">
                  <v-select
                    :items="vendors"
                    v-model="vendor"
                    label="Vendor"
                    item-text="displayname"
                    item-value="_id"
                    return-object
                    outlined
                    dense
                    clearable
                    :loading="loading"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-btn
                    @click="getData()"
                    block
                    tile
                    color="primary"
                    class="mb-2"
                  >
                    <v-icon left>mdi-refresh</v-icon>
                    Refresh
                  </v-btn>
                  <v-switch
                    :label="
                      !showFilled ? 'Show All Requests' : 'Hide Filled Requests'
                    "
                    inset
                    v-model="showFilled"
                  >
                  </v-switch>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-data-table>
        <writeemail
          v-if="vendor && selected.length"
          :record="record"
          :emailtemplate="emailtemplate"
          label="Compose Email"
          @emit="catchEmit($event)"
        ></writeemail>
      </v-col>
    </v-row>
  </div>
</template>
  <script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";
import sort from "../../dataTable/sort";
import writeemail from "../../inbox/write.vue";
export default {
  data() {
    return {
      loading: false,
      showFilled: true,
      headers: [
        { text: "Created", value: "date", width: "200px" },
        // { text: "Created", value: "created" },
        // { text: "Created By", value: "createdby" },
        { text: "Item", value: "item" },
        { text: "Stock Category", value: "stockcategory" },
        { text: "Manufacturer", value: "manufacturergroup" },
        { text: "Vendor", value: "customer" },
        { text: "SO#", value: "transaction" },
        { text: "Service", value: "additionalservice" },
        { text: "PO#", value: "specialorders" },
        { text: "Delivery Method", value: "deliverymethod" },
        { text: "Internal PO#", value: "internalspecialorders" },
        { text: "Qty.", value: "quantity" },
        { text: "ETD", value: "etd", width: "200px" },
        { text: "Sent", value: "confirmed" },
        { text: "Req#", value: "referencenumber" },
        {
          text: "Qty. BO / On Order",
          value: "quantityonorder",
          sortable: false,
        },
        { text: "Comments", value: "memo", sortable: false },

        //{ text: "Qty. BO", value: "quantitybackorder" },
      ],
      headersVendors: [
        { text: "Date", value: "date" },
        { text: "Item", value: "item" },
        { text: "Stock Category", value: "stockcategory" },
        { text: "Vendor", value: "customer" },
        { text: "Ozparts SO#", value: "transaction" },
        { text: "PO#", value: "specialorders" },
        { text: "Delivery Method", value: "deliverymethod" },
        { text: "Qty.", value: "quantity" },
        { text: "ETD", value: "etd" },
        { text: "Req#", value: "referencenumber" },
      ],
      headersExport: [
        { text: "Name", value: "name" },
        { text: "Item", value: "item" },
        { text: "ETD", value: "etd" },
      ],
      headersAllExport: [
        { text: "Created", value: "created", width: "200px" },
        // { text: "Created", value: "created" },
        // { text: "Created By", value: "createdby" },
        { text: "Item", value: "item" },
        { text: "Stock Category", value: "stockcategory" },
        { text: "Manufacturer", value: "manufacturergroup" },
        { text: "Vendor", value: "customer" },
        { text: "SO#", value: "transaction" },
        { text: "Service", value: "additionalservice" },
        { text: "PO#", value: "specialorders" },
        { text: "Delivery Method", value: "deliverymethod" },
        { text: "Internal PO#", value: "internalspecialorders" },
        { text: "Qty.", value: "quantity" },
        { text: "ETD", value: "etd", width: "200px" },
        { text: "Sent", value: "confirmed" },
        { text: "Req#", value: "referencenumber" },
        {
          text: "Qty. BO / On Order",
          value: "quantityonorder",
          sortable: false,
        },
        { text: "Comments", value: "memo", sortable: false },

        //{ text: "Qty. BO", value: "quantitybackorder" },
      ],
      items: [],
      rows: [-1, 100],
      search: "",
      show: true,
      selected: [],
      filteredItems: [],
      record: {
        // recordtype: "transactionrequest",
        collection: "transactions",
        name: "BO Request",
        customer: {
          email: "",
        },
      },
      vendor: null,
      vendors: [],
    };
  },
  async created() {
    this.loading = true;

    if (
      this.$store.state.user &&
      this.$store.state.user.role._id == "role_vendor"
    ) {
      this.headers = this.headersVendors;
    }

    this.headers = this.customheaders || this.headers;
    await this.getData();
    this.showFilled = false;
    this.loading = false;
  },

  computed: {
    hasAccess() {
      if (
        this.$store.state.user &&
        this.$store.state.user.role._id != "role_vendor"
      )
        return true;
      else return false;
    },
    emailtemplate() {
      let manufacturer = this.selected[0].manufacturergroup;
      let number = `${manufacturer}-${new Date().toISOString().substr(0, 10)}`;
      let subject = `OZPARTS - ETA FOR BO REQUEST (${number})`;
      let text = `

        <p>Hi,</p>
    <p>Can you please advise ETA for the below:</p>

<p>Thanks in advance.</p>
<br/>
        `;

      let list = `<table style="width: 400px">
            <tr><th style="background-color: #dddddd">Item</th><th style="background-color: #dddddd">PO#</th><th style="background-color: #dddddd">Qty.</th></tr>
            ${this.selected
              .map(
                (line, i) =>
                  `<tr><td style="text-align: center">${
                    line.item.name
                  }</td><td style="text-align: center">${
                    line.specialorders.map((l) => l.name).join(", ") ||
                    "Purchase order to be placed shortly"
                  }</td>
                    <td style="text-align: center">${line.quantity}</td>
                    </tr>`
              )
              .join("")}  
            </table>`;

      let signature = `<br/><br/>__
<table>
<tbody><tr style="font-weight:700"><td colspan="2" style="font-size:14px;padding-bottom:5px">Pozdrawiam / Best Regards,</td></tr><tr style="font-size:16px;font-weight:700"><td colspan="2">${
        this.$store.state.user.name
      }</td></tr><tr style="color:rgb(102,102,102)"><td colspan="2">${
        this.$store.state.user.jobtitle
      }</td></tr><tr><td colspan="2" style="padding:10px 0px"><a href="https://ozparts.eu/" target="_blank"><img src="https://ozparts.eu/signature-2023/15th.png" title="Ozparts" alt="Ozparts"></a></td></tr><tr><td><b>E-mail:</b>&nbsp;<a href="mailto:${
        this.$store.state.user.email
      }" style="color:rgb(0,0,0)" target="_blank">${
        this.$store.state.user.email
      }</a></td><td><b>Ozparts PL sp. z o.o.</b></td></tr><tr><td><b>Mob:&nbsp;</b>${
        this.$store.state.user.phone || ""
      }</td><td>Słowackiego 32/5</td></tr><tr><td><b>Web:</b>&nbsp;<a href="https://ozparts.eu/" style="color:rgb(0,0,0)" target="_blank" >ozparts.eu</a></td><td>87-100 Toruń</td></tr>
  
  <tr><td colspan="2"><a href="https://ozparts.eu/#brands" target="_blank" ><img src="https://ozparts.eu/signature-2023/sig500eu.png" title="Our brands" alt="Our brands" style="max-width:500px"></a></td></tr>
  <tr style="font-size:10px"><td colspan="2"><div style="width:500px"><b>Ozparts PL sp. z o.o.</b>&nbsp;is registered in Toruń on the street Słowackiego 32/5, 87-100 Toruń, KRS 0000984204, company records in Toruń District Court, REGON 340473620, NIP 9562230129, Share Capital 30.000 PLN.&nbsp;<b>Warehouse address:</b>&nbsp;Mazowiecka 46-48, 87-100 Toruń. Your data is processed by us in compliance with RODO (GDPR). You can find the link to our Privacy Policy&nbsp;<a href="https://ozparts.eu/privacy_policy_5" style="color:rgb(0,0,0)" target="_blank" >here</a>.</div><div><br></div></td></tr></tbody>
</table>

            
          `;
      return {
        number: number,
        subject: subject,
        text: text + list + signature,
        cclist: ["import@ozparts.eu", "orders@ozparts.eu"],
      };
    },
  },
  watch: {
    async vendor(v) {
      if (v) {
        delete this.record.email;
        this.record.customer = v;
        if (this.receivergroup || true) {
          await service("users_contacts", {
            action: "find",
            data: [
              { customer: v._id, receivergroups: "665ebc9a1a637e59a1574b44" },
            ],
          }).then((res) => {
            if (res.length) this.record.email = res.map((c) => c.email);
          });
        }
        console.log(this.record.email);
        await this.getData();

        this.selected = [];
      } else {
        await this.getData();
      }
    },
    async showFilled(v) {
      if (!v) this.items = this.items.filter((i) => !i.etd);
      else await this.getData();
    },
  },
  methods: {
    async changeDate(e, id, field = "etd") {
      try {
        await service("transactions_requests", {
          action: "setField",
          collection: "transactions_requests",
          rid: id,
          field: field,
          value: e,
        });
        //await this.getData();
      } catch (error) {
        alert(error.message);
      }
    },
    async catchEmit(e) {
      try {
        this.loading = true;
        for (let s of this.selected) {
          s.referencenumber = e.number;
          let result = await service("orders", {
            action: "setField",
            collection: "transactions_requests",
            rid: s._id,
            field: "confirmed",
            value: true,
          });
          await service("orders", {
            action: "setField",
            collection: "transactions_requests",
            rid: s._id,
            field: "referencenumber",
            value: e.number,
          });
        }
        this.loading = false;
        await this.getData();
      } catch (error) {
        alert(error.message);
      }
    },
    async getData() {
      this.loading = true;
      let result = await service(
        "report",
        {
          action: "getRequests",
        },
        false,
        true
      );

      this.items = this.vendor
        ? result.items.filter((item) => item.customer._id == this.vendor._id)
        : result.items;

      result.items.forEach((line) => {
        line.isSelectable = !line.confirmed;
        this.vendors.push(line.customer);
      });
      if (!this.showFilled) this.items = this.items.filter((i) => !i.etd);
      this.selected = [];
      this.loading = false;
    },
    getFiltered(e) {
      this.filteredItems = e;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    sort(items, sortBy, sortDesc) {
      return sort(items, sortBy, sortDesc);
    },
  },
  components: {
    exportData,
    writeemail,
  },
};
</script>
  <style scoped>
.specialorder {
  background: #b5b5b5;
}
.kitcomponent {
  background: #ececec;
}
</style>
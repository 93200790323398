<template>
  <div>
    <v-row>
      <v-col>
        <v-tabs dark v-model="active">
          <v-tab
            v-for="(tab, index) of Object.values(tabs).filter(
              (tab) =>
                (this.locationsFilter.includes(tab.location) ||
                  !this.locationsFilter.length) &&
                !tab.hidden
            )"
            :key="index"
          >
            <v-badge color="primary">
              <span>{{ tab.name }}</span>
              <span slot="badge">{{ tab.items.length }}</span>
            </v-badge>
          </v-tab>

          <v-tab-item
            v-for="(tab, index) of Object.values(tabs).filter(
              (tab) =>
                (this.locationsFilter.includes(tab.location) ||
                  !this.locationsFilter.length) &&
                !tab.hidden
            )"
            :key="index"
          >
            <v-data-table
              :headers="tab.headers"
              :items="tab.items"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              :search="search"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
              :custom-sort="sort"
              :sort-by="sortBy"
              :show-select="tab.selected"
              :single-select="singleSelect"
              :item-class="rowClass"
              v-model="selected"
              :group-by="tab.group"
            >
              <template v-slot:item.shipcountry="{ item }">
                <span>
                  {{ item.shipcountry }}
                  <v-tooltip bottom v-if="item.exportcustomsclearance">
                    <span
                      >Export Customs Clearance
                      {{ item.childs.length ? "(Invoiced)" : "" }}</span
                    >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        :color="item.childs.length ? 'green' : 'red'"
                        >mdi-alert</v-icon
                      >
                    </template>
                  </v-tooltip>
                </span>
              </template>
              <template v-slot:item.comment2="{ item }">
                  <p><pre>{{ item.comment2 }}</pre></p>
              </template>
              
              <template v-slot:item.whocollected="{ item }">
                <v-select
                  :items="employees.filter(e=>e.company==tab.company)"
                  v-model="item.whocollected"
                  label="Collected"
                  item-text="name"
                  item-value="_id"
                  outlined
                  dense
                  hide-details
                  class="mt-2 mb-2"
                  :disabled="item.archived"
                  @change="setWhocollected(item)"
                ></v-select>
              </template>

              <template v-slot:item.comment="{ item }">
                <span>
                  <v-tooltip bottom v-if="item.comment">
                    <span><pre>{{ item.comment }}</pre></span>
                    <template v-slot:activator="{ on }">
                      <v-chip
                        label
                        v-on="on"
                        :color="item.comment ? 'red' : 'none'"
                        dark
                        >{{ item.comment ? "Yes" : "No" }}</v-chip
                      >
                    </template>
                  </v-tooltip>
                  {{ item.terms }}
                </span>
              </template>
              <template v-slot:item.location="{ item }">
                <span>{{
                  item.location.displayname || item.location.name
                }}</span>
              </template>
              <template v-slot:item.shippingmethod="{ item }">
                <v-row>
                  <v-col v-if="item.logo" cols="1" style="min-width: 24px" class="px-0">
                    <v-img
                      max-height="24"
                      max-width="24"
                      :src="item.logo.url"
                    ></v-img
                  ></v-col>
                  <v-col>
                    <span>{{ item.shippingmethod }}</span>
                    <span class="d-block"><small> Est. Weight: {{item.weight}}kg</small></span>
                    <span class="d-block">
                      <a
                        v-for="(file, index) of item.files"
                        :key="index"
                        :href="file.url"
                        target="_blank"
                        class="mr-2"
                      >
                        <v-icon color="primary" class="mr-1">mdi-label</v-icon
                        >Label
                      </a>
                    </span>
                    <p v-if="item.dropship || item.customerdropship">
                      <v-chip dark color="orange" label>Drop Shipping</v-chip>
                    </p>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item.date="{ item }">
                <span>{{
                  new Date(item.date).toISOString().substr(0, 10)
                }}</span>
              </template>
              <template v-slot:item.created="{ item }">
                <span class="created">{{
                  item.created
                    ? new Date(
                        new Date(item.created).getTime() -
                          new Date(item.created).getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substr(0, 16)
                        .replaceAll("T", "\n")
                    : ""
                }}</span>
              </template>
              <template v-slot:item.print="{ item }">
                <v-btn
                  :disabled="item.status._id != 'readytopack'"
                  @click="printPDF(item)"
                  block
                  tile
                  color="primary"
                  class="mt-2 mb-2"
                  ><v-icon>mdi-printer</v-icon></v-btn
                >
              </template>
              <template v-slot:item.status="{ item }">
                <v-select
                  :items="statuslist"
                  v-model="item.status"
                  :label="`${(item.quantity||item.quantityscanned) ? 'Status: '+(item.quantity||item.quantityscanned)+'/'+item.quantitytopack : 'Status'}`"
                  item-text="name"
                  item-value="_id"
                  outlined
                  dense
                  hide-details
                  class="mt-2 mb-0"
                  :disabled="item.archived"
                  @change="setStatus(item)"
                ></v-select>
                <v-progress-linear style="margin-top: -3px;" v-if="(item.quantity||item.quantityscanned)" rounded :value="Math.ceil(((item.quantity||item.quantityscanned)/item.quantitytopack)*100)"></v-progress-linear>
              </template>
              <template v-slot:item.transaction="{ item }">
                <router-link
                  :to="{
                    path: `/${item.transaction.collection}/${item.transaction.recordtype}/edit/${item.transaction._id}`,
                  }"
                  class="link"
                  :target="tab.location=='61e14c6ae4a35a668517a8fe'?'_self':'_blank'"
                  >{{
                    item.transaction.displayname || item.transaction.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                  }"
                  class="link"
                  >{{
                    item.customer.displayname || item.customer.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.createdfrom="{ item }">
                <router-link
                  v-for="(so, index) in item.more
                    ? item.createdfrom
                    : item.createdfrom.slice(0, 3)"
                  :key="index"
                  :to="{
                    path: `/${so.collection}/${so.recordtype}/view/${so._id}`,
                  }"
                  class="link"
                >
                  {{ so.displayname || so.name }}
                  <span v-if="index + 1 < item.createdfrom.length">,</span>
                </router-link>
                <span
                  v-if="item.createdfrom.length > 3"
                  @click="$set(item, 'more', !item.more)"
                  >(more...)</span
                >
              </template>
              <template v-slot:item.shipmethod="{ item }">
                <span>{{ item.shipmethod ? item.shipmethod.name : "" }}</span>
              </template>
              <template v-slot:item.statusref="{ item }">
                <span>{{ item.statusref ? item.statusref.name : "" }}</span>
              </template>
              <template v-slot:top>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-btn
                        @click="getData()"
                        block
                        tile
                        color="primary"
                        class="mb-2"
                        :loading="loading"
                        >Refresh</v-btn
                      >
                    </v-col>
                    <v-col>
                      <v-btn
                        v-if="tab.name == 'TS EU'"
                        :disabled="!tab.invoices"
                        @click="initDelivery()"
                        block
                        tile
                        color="primary"
                        class="mb-2"
                        :loading="loading"
                        >Create Delivery ({{ tab.invoices }} Invoices)</v-btn
                      >
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="text-right">
                      <v-btn icon @click="show = !show">
                        <v-icon>{{
                          show ? "mdi-chevron-up" : "mdi-chevron-down"
                        }}</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-expand-transition>
                    <div v-show="show || selected.length > 1">
                      <v-divider></v-divider>
                      <v-row>
                        <v-col>
                          <v-btn
                            @click="join()"
                            block
                            tile
                            color="primary"
                            class="mb-2"
                            :loading="loading"
                            >Join Selected ({{ selected.length }})</v-btn
                          ></v-col
                        >
                        <v-col
                          v-if="tab.location != '61e14c6ae4a35a668517a8fe'"
                        >
                          <v-btn
                            @click="setShippedStatus()"
                            block
                            tile
                            color="primary"
                            class="mb-2"
                            :loading="loading"
                            >Set Selected as Shipped ({{
                              selected.length
                            }})</v-btn
                          ></v-col
                        >
                      </v-row>
                    </div>
                  </v-expand-transition>
                </v-container>
              </template>
              <template v-slot:footer>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-btn
                        :disabled="!selected.length"
                        @click="print()"
                        tile
                        color="primary"
                        class="mb-2"
                        :loading="loading"
                        ><v-icon>mdi-printer</v-icon> Print Selected</v-btn
                      >
                    </v-col>
                    <v-col v-if="tab.location == '5e0dbaba9e33df43f0b3a495'">
                      <v-btn
                        @click="createEmptyIF(tab.location)"
                        tile
                        color="primary"
                        class="mb-2 float-right"
                        :loading="loading"
                        ><v-icon>mdi-new</v-icon> New (Empty)</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import sort from "../../dataTable/sort";
export default {
  data() {
    return {
      loading: false,
      employees: [],
      headers: [
        //{ text: "Location", value: "location" },
        { text: "Ship Country", value: "shipcountry" },
        { text: "CSC", value: "comment" },
        //{ text: "Date", value: "date" },
        { text: "Created", value: "created" },
        //{ text: "Created From", value: "createdfrom" },
        { text: "Customer", value: "customer" },
        { text: "Shipping Address", value: "shippingaddress" },
        { text: "Shipping Method", value: "shippingmethod" },
        { text: "Item Fulfillment", value: "transaction" },
        { text: "Assign To", value: "whocollected" },
        { text: "Status", value: "status" },
        { text: "Warehouse Comments", value: "memo" },

        { text: "Print", value: "print" },
      ],
      headersUK: [
        //{ text: "Location", value: "location" },
        { text: "Ship Country", value: "shipcountry" },
        //{ text: "CSC", value: "comment" },
        //{ text: "Date", value: "date" },
        { text: "Created", value: "created" },
        { text: "Created From", value: "createdfrom" },
        { text: "Customer", value: "customer" },
        { text: "Shipping Address", value: "shippingaddress" },
        { text: "Shipping Method", value: "shippingmethod" },
        { text: "Item Fulfillment", value: "transaction" },
        { text: "Status", value: "status" },
        { text: "Warehouse Comments", value: "memo" },

        { text: "Print", value: "print" },
      ],
      headersTS: [
        //{ text: "Location", value: "location" },
        { text: "Ship Country", value: "shipcountrycode" },
        //{ text: "CSC", value: "memomain" },
        { text: "Date", value: "datecreated" },
        //{ text: "Created", value: "datecreated" },
        { text: "Sales Order", value: "tranid" },
        { text: "Customer", value: "altname" },
        { text: "Shipping Address", value: "shipaddress" },
        // { text: "Shipping Method", value: "shipmethod" },
        // { text: "Ref", value: "refnumber" },
        // { text: "Ref2", value: "otherrefnum" },
        { text: "Status", value: "statusref" },
        { text: "Warehouse Comments", value: "memomain" },

        //{ text: "Print", value: "print" },
      ],
      items: [],
      itemsPL: [],
      itemsUK: [],
      itemsPEDDERSUK: [],
      itemsNL: [],
      rows: [-1],
      search: "",
      show: false,
      statuslist: [],
      locationsFilter: [],
      singleSelect: false,
      selected: [],
      tabs: {},
      sortBy: [],
      active: 0,
    };
  },
  async created() {
    this.loading = true;
    this.getEmployees();
    if (
      this.$store.state.user &&
      ["role_warehouse", "role_warehouse_manager"].includes(
        this.$store.state.user.role._id
      )
    )
      this.sortBy = ["shippingaddress"];
    if (
      this.$store.state.user &&
      this.$store.state.user.role._id == "role_sales"
    )
      this.headers = [
        { text: "Location", value: "location" },
        { text: "Date", value: "date" },
        { text: "Created", value: "created" },
        { text: "Created From", value: "createdfrom" },
        { text: "Customer", value: "customer" },
        { text: "Shipping Address", value: "shippingaddress" },
        { text: "Shipping Method", value: "shippingmethod" },
        { text: "Item Fulfillment", value: "transaction" },
        { text: "Status", value: "status" },
        { text: "Warehouse Comments", value: "memo" },
        { text: "Customer Service Comments", value: "comment2" },
        { text: "Sales Rep", value: "salesrep" },
        { text: "Print", value: "print" },
      ];
    this.tabs = {
      PL: {
        name: "Warehouse PL",
        location: "5e0dbaba9e33df43f0b3a495",
        company: "maincompany",
        items: [],
        headers: this.headers,
        selected: true,
      },
      NL: {
        name: "Warehouse NL",
        location: "66e04fcba16b2e1e7d873be8",
        company: "65cf227de8d07217f255104f",
        items: [],
        headers: this.headers,
        selected: true,
      },
      PeddersUK: {
        name: "Warehouse UK",
        location: "61e14c6ae4a35a668517a8fe",
        company: "ozpartsuklimited",
        items: [],
        headers: this.headersUK,
        group: "Category",
        selected: true,
      },
      BrakesPL: {
        name: "Brakes PL",
        location: "62de6196fa2fc522dc692c7e",
        company: "",
        items: [],
        headers: this.headers,
        selected: true,
      },
    };
    this.statuslist = await service("classification", {
      action: "find",
      data: [{ recordtype: "itemfulfillmentstatus" }],
    });
    if (this.$store.state.user.location)
      this.locationsFilter.push(this.$store.state.user.location);
    if (
      this.$store.state.user.forlocations &&
      this.$store.state.user.forlocations.length
    )
      this.locationsFilter.push(...this.$store.state.user.forlocations);
    await this.getData();

    this.loading = false;
  },
  watch: {
    async active(v) {
      this.selected = [];
      this.getEmployees();
    },
    show(v) {
      this.selected.forEach((line, index) => {
        if (line.comment && v) this.selected.splice(index, 1);
      });
    },
    selected: function (val) {
      val.forEach((line, index) => {
        if (line.comment && this.show) this.selected.splice(index, 1);
      });
    },
  },
  methods: {
    async getEmployees() {
      this.employees = await service("users", {
        action: "find",
        data: [
          {
            recordtype: "employee",
            department: "5e0dd7bd66f4af3210842500",
          },
        ],
      });
    },
    rowClass(item) {
      if (["maincompany", "65cf227de8d07217f255104f"].includes(item.company)) {
        if (new Date() - new Date(item.created) > 172800000) {
          return "overdue2";
        }
        if (new Date() - new Date(item.created) > 86400000) {
          return "overdue";
        }
      }
    },
    async setShippedStatus() {
      this.loading = true;
      if (this.selected && this.selected.length > 0) {
        var response = confirm("Change status (Shipped) - Are you sure?");
        if (response) {
          for (let select of this.selected) {
            select.status = "shipped";
            await this.setStatus(select);
          }
        }
      }
      this.selected = [];
      this.loading = false;
    },

    async join() {
      this.loading = true;
      if (this.selected && this.selected.length > 1) {
        if (
          this.selected.every((rec) => {
            return (
              rec.shippingaddress == this.selected[0].shippingaddress &&
              rec.transaction.location ==
                this.selected[0].transaction.location &&
              rec.transaction.shippingmethod ==
                this.selected[0].transaction.shippingmethod
            );
          })
        ) {
          var response = confirm("Join Transactions - Are you sure?");
          if (response) {
            try {
              await service("transactions", {
                action: "joinTransactions",
                records: this.selected,
              });
              this.selected = [];
              this.show = false;
              await this.getData();
            } catch (error) {
              this.selected = [];
              alert(error.message);
            }
          }
        } else
          alert(
            "The shipping address and location must be the same for all records!"
          );
      } else {
        alert("Please select more than one records");
      }
      this.selected = [];
      this.loading = false;
    },

    async getTSEU() {
      if (!this.tabs.TSEU) {
        this.tabs.TSEU = {
          name: "TS EU",
          location: "5e0dbaba9e33df43f0b3a495",
          items: [],
          headers: this.headersTS,
          selected: false,
          invoices: 0,
        };
      } else {
        this.tabs.TSEU.items = [];
      }
      this.tabs.TSEU.items = await fetch(
        `http://localhost:8080/api/turbosmart/getPendingFulfillment`,
        {
          method: "GET",
        }
      ).then(async (response) => {
        let responseData = {};
        responseData = await response.json();

        if (response.ok) {
          console.log(responseData);
          return responseData;
        } else {
          console.log("nieok");
          throw responseData;
        }
      });
      this.tabs.TSEU.invoices = await fetch(
        `http://localhost:8080/api/turbosmart/getInvoicesGroup`,
        {
          method: "GET",
        }
      ).then(async (response) => {
        let responseData = {};
        responseData = await response.json();

        if (response.ok) {
          console.log("Pending to Transfer", responseData);
          return responseData;
        } else {
          console.log("nieok");
          throw responseData;
        }
      });
    },
    async initDelivery() {
      this.loading = true;
      await fetch(`http://localhost:8080/api/turbosmart/getInvoices`, {
        method: "GET",
      }).then(async (response) => {
        let responseData = {};
        responseData = await response.json();

        if (response.ok) {
          console.log(responseData);
          alert("Done");
          this.$router.push({
            path: `deliveries/delivery/view/${responseData}`,
          });
          this.loading = false;
          return responseData;
        } else {
          console.log("nieok");
          this.loading = false;
          throw responseData;
        }
      });
      this.loading = false;
    },
    async getData() {
      this.loading = true;
      let result = await service(
        "orders",
        {
          action: "getReadyToPack",
        },
        false,
        true
      );
      this.tabs["PL"].items = [];
      this.tabs["NL"].items = [];
      //this.tabs["UK"].items = [];
      this.tabs["PeddersUK"].items = [];
      this.tabs["BrakesPL"].items = [];
      result.items.forEach((item) => {
        item.whocollected =
          item.whocollected && item.whocollected[0]
            ? item.whocollected[0]
            : null;
        item.Category = item.source;
        item.comment2 = item.comment;
        item.shippingaddress =
          item.shippingaddress || item.shipaddressee || item.shipaddress;
        if (item.company && ["maincompany"].includes(item.company)) {
          this.tabs["PL"].items.push(item);
        }
        if (
          item.company &&
          ["65cf227de8d07217f255104f"].includes(item.company)
        ) {
          // Ozparts BV
          this.tabs["NL"].items.push(item);
        }
        if (
          item.company &&
          ["ozpartsuklimited"].includes(item.company) &&
          item.location &&
          !["61e14c6ae4a35a668517a8fe", "620bd994628dd8bb034ce62f"].includes(
            item.location._id
          )
        ) {
          this.tabs["UK"].items.push(item);
        }
        if (
          item.location &&
          ["61e14c6ae4a35a668517a8fe", "620bd994628dd8bb034ce62f"].includes(
            item.location._id
          )
        ) {
          this.tabs["PeddersUK"].items.push(item);
        }
        if (item.company && ["brakespl"].includes(item.company)) {
          this.tabs["BrakesPL"].items.push(item);
        }
      });
      this.items = result.items;

      //TS EU
      if (
        this.$store.state.user &&
        [
          "admin",
          "role_warehouse",
          "role_procurement",
          "role_warehouse_manager",
          "role_procurement_import",
        ].includes(this.$store.state.user.role._id)
      ) {
        await this.getTSEU();
      }

      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    sort(items, sortBy, sortDesc) {
      return sort(items, sortBy, sortDesc);
    },
    async createEmptyIF(location) {
      let url = `http://localhost:8080/transactions/itemfulfillment/create/?location=${location}&external=${true}`;
      window.open(url, "_blank");
    },
    async setWhocollected(item) {
      this.loading = true;
      await service("transactions", {
        action: "setField",
        collection: "transactions",
        rid: item._id,
        field: "whocollected",
        value: [item.whocollected],
      }).catch((error) => {
        item.status = null;
        alert(error.message);
      });
      this.loading = false;
    },
    async setStatus(item) {
      this.loading = true;
      await service("transactions", {
        action: "changeStatus",
        rid: item._id,
        status: item.status,
      }).catch((error) => {
        item.status = null;
        alert(error.message);
      });
      //await this.getData();
      this.loading = false;
    },
    async printPDF(item) {
      item.status = "picked";
      await this.setStatus(item);
      let pdf = `http://localhost:8080/api/pdf/transactions/${item._id}/?pdfform=pdfpickingticket`;
      window.open(pdf, "_blank");
    },
    async print() {
      this.loading = true;
      if (this.selected.length > 100) {
        alert("Max 100 Docs");
        return false;
      }
      let rids = [];
      for (let item of this.selected) {
        if (item.status && item.status._id == "readytopack") {
          item.status = "picked";
          await this.setStatus(item);
        }
        rids.push(item._id);
      }
      try {
        let pdf = await service("transactions", {
          action: "printTransactions",
          rids: rids,
          pdfform: "pdfpickingticket",
        });
        var file = pdf;
        var fileURL = URL.createObjectURL(file);
        console.log(fileURL);
        window.open(fileURL);
      } catch (error) {
        alert(error.message);
      }
      this.loading = false;
    },
    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
  },
};
</script>
<style>
.overdue2 {
  background: #ff9988;
}
</style>>
<template>
  <v-tabs vertical dark>
    <v-tooltip bottom>
      <span>Overview</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-information-outline</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Receive</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>assignment_turned_in</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Related Transactions</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-format-list-bulleted</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Communications</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-email-open</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Logs</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-math-log</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tab-item>
      <v-container fluid>
        <v-row v-if="record.location._id == '61e14c6ae4a35a668517a8fe'">
          <v-col>
            <v-alert
              type="error"
              v-if="record.location._id == '61e14c6ae4a35a668517a8fe'"
            >
              Send Email Notify After Receipt!</v-alert
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>Quantity:</v-list-item-subtitle>
                      <v-list-item-title>{{
                        record.quantity || 0
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle
                        >Quantity Scanned:</v-list-item-subtitle
                      >
                      <v-list-item-title>{{
                        record.quantityscanned || 0
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle
                        >Quantity Received:</v-list-item-subtitle
                      >
                      <v-list-item-title>{{
                        record.quantityreceived || 0
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row>
              <v-col>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle
                        >Item Receipt Date:</v-list-item-subtitle
                      >
                      <v-list-item-title>{{
                        record.receiptdate
                          ? new Date(record.receiptdate)
                              .toISOString()
                              .substr(0, 10)
                          : ""
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-tab-item>
    <v-tab-item>
      <tool-receivedelivery :record="record._id" />
    </v-tab-item>
    <v-tab-item>
      <tool-relatedtransactions :record="record" />
    </v-tab-item>
    <v-tab-item>
      <tool-inbox :record="record" :customer="record._id" filter="record"/>
    </v-tab-item>
    <v-tab-item>
      <tool-logs :record="record._id" />
    </v-tab-item>
  </v-tabs>
</template>
<script>
/* eslint-disable */
export default {
  props: ["record"],
  data() {
    return {
      loading: false,
      photosrc: "",
    };
  },
  computed: {
    date() {
      return new Date(this.record.date).toISOString().substr(0, 10);
    },
    quantity() {
      //   let qtytopack = 0;
      //   this.record.transactionlines.forEach(line => {
      //     qtytopack += parseInt(line.quantitytopack);
      //   });
      return `${this.record.quantity}/${this.record.quantityscanned}/${this.record.quantityreceived}`;
    },
  },
};
</script>

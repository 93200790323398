<template>
  <div v-if="($store.state.user && $store.state.user.role._id!='role_warehouse_uk')">
    <!-- <orgchart v-if="$route.params.recordtype=='employee' && !$route.params.mode"/> -->
    <queryresult v-if="!$route.params.mode" :queryid="$route.params.recordtype"/>
    <record collection="users" v-else/>
  </div>
  <v-alert v-else type="error">Access Denied</v-alert>
</template>

<script>
//import orgchart from "./OrgChart";
/* eslint-disable */
export default {
  data() {
    return {
      loading: true
    };
  },
  components: {
    //orgchart
  }
};
</script>
<template>
  <v-card tile>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Notes</v-toolbar-title>
    </v-toolbar>
  <notes />
   </v-card>
</template>

<script>
import notes from "../components/tools/notes/View";

/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
    };
  },

  components: {
    notes,
  },
};
</script>
<template>
  <v-layout wrap>
    <v-flex md6 class="mb-3">
      <v-card class="mt-3">
        <v-card-text>
          <bar-chart />
        </v-card-text>
        <v-card-text class="pt-0">
          <div class="title font-weight-light mb-2">Active Users</div>
          <div class="subheading font-weight-light grey--text">Last Campaign Performance</div>
          <v-divider class="my-2"></v-divider>
          <v-icon class="mr-2" small>mdi-clock</v-icon>
          <span class="caption grey--text font-weight-light">last registration 26 minutes ago</span>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import BarChart from "../components/charts/bar";
export default {
  data: () => ({
    date: new Date().toISOString().substr(0, 10)
  }),
  components: { BarChart }
};
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-progress-linear',{attrs:{"indeterminate":_vm.recalc || _vm.loading,"height":"3"}}),(!_vm.loading || _vm.fields)?_c('v-tabs',{attrs:{"dark":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},_vm._l((_vm.schema.tabs),function(tab,tabindex){return _c('v-tab',{key:tabindex,attrs:{"ripple":""}},[_vm._v(_vm._s(tab.name))])}),1):_vm._e(),_c('v-tabs-items',{attrs:{"touchless":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},_vm._l((_vm.schema.tabs),function(tab,tabindex){return _c('v-tab-item',{key:tabindex},[(tab.sections && tab.sections.length > 0)?_c('v-container',{attrs:{"fluid":""}},_vm._l((tab.sections),function(section,sectionindex){return _c('v-row',{key:sectionindex},[_c('v-subheader',[_vm._v(_vm._s(section.name))]),_c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[(section.columns)?_c('v-row',_vm._l((section.columns),function(col,index){return _c('v-col',{key:index,staticClass:"pb-0"},_vm._l((col),function(field,index){return _c('div',{key:index},[(
                        _vm.fields[field.field] &&
                        field.editable != false &&
                        (field.status && _vm.record.status
                          ? field.status.includes(_vm.record.status._id)
                          : true) &&
                        (field.mode
                          ? field.mode.includes(_vm.$route.params.mode)
                          : true) &&
                        (field.iftrue ? _vm.record[field.iftrue] : true)
                      )?_c(_vm.fields[field.field].setup.type,{key:_vm.update,tag:"component",attrs:{"record":_vm.record,"fieldSettings":_vm.fields[field.field],"formSettings":field,"id":_vm.fields[field.field].update},on:{"emit":_vm.catchEmit},model:{value:(_vm.record[field.field]),callback:function ($$v) {_vm.$set(_vm.record, field.field, $$v)},expression:"record[field.field]"}}):_vm._e()],1)}),0)}),1):_c('v-row',_vm._l((section.fields),function(field,index){return _c('v-col',{key:index,staticClass:"pb-0"},[(
                      _vm.fields[field.field] &&
                      (field.status && _vm.record.status
                        ? field.status.includes(_vm.record.status._id)
                        : true) &&
                      (field.mode
                        ? field.mode.includes(_vm.$route.params.mode)
                        : true) &&
                      (field.iftrue ? _vm.record[field.iftrue] : true)
                    )?_c(_vm.fields[field.field].setup.type,{key:_vm.update,tag:"component",attrs:{"record":_vm.record,"fieldSettings":_vm.fields[field.field],"formSettings":field,"id":_vm.fields[field.field].update},on:{"emit":_vm.catchEmit},model:{value:(_vm.record[field.field]),callback:function ($$v) {_vm.$set(_vm.record, field.field, $$v)},expression:"record[field.field]"}}):_vm._e()],1)}),1)],1),(section.tool && _vm.record._id)?_c('div',{staticStyle:{"width":"100%","position":"relative"}},[(
                  section.tool.status && _vm.record.status
                    ? section.tool.status.includes(_vm.record.status._id)
                    : true && section.tool.mode
                    ? section.tool.mode.includes(_vm.$route.params.mode)
                    : true
                )?_c(section.tool.tool,{tag:"component",attrs:{"record":_vm.record,"id":section.tool.tool,"config":section.tool.config},on:{"update:record":function($event){_vm.record=$event},"emit":_vm.catchEmit}}):_vm._e()],1):_vm._e(),(
                section.sublist &&
                _vm.fields[section.sublist.field] &&
                !section.sublist.hidden &&
                (section.sublist.status && _vm.record.status
                  ? section.sublist.status.includes(_vm.record.status._id)
                  : true) &&
                (section.sublist.mode
                  ? section.sublist.mode.includes(_vm.$route.params.mode)
                  : true)
              )?_c('datatable',{staticStyle:{"width":"100%","position":"relative"},attrs:{"record":_vm.record,"settings":_vm.fields[section.sublist.field],"formSettings":section.sublist,"id":section.sublist.field},on:{"update:record":function($event){_vm.record=$event},"emit":_vm.catchEmit}}):_vm._e()],1)}),1):_vm._e(),(tab.table && tab.table.query)?_c('result',{attrs:{"queryid":tab.table.query,"filters":tab.table.filters},on:{"emit":_vm.catchEmit}}):_vm._e()],1)}),1),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1)],1),_c('v-snackbar',{attrs:{"color":_vm.msg.type,"timeout":6000,"top":"","center":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
              var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.msg.show = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.msg.show),callback:function ($$v) {_vm.$set(_vm.msg, "show", $$v)},expression:"msg.show"}},[_vm._v(_vm._s(_vm.msg.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container fluid>
    <v-textarea
      :label=label
      placeholder
      v-model="pasteData"
      outlined
      dense
    ></v-textarea>
  </v-container>
</template>
<script>
export default {
  props: ["record", "config"],
  data() {
    return {
      pasteData: "",
      table: "uniquelabels",
    };
  },
  computed:{
label(){
  return `Paste the data according to the columns`
}
  },
  watch: {
    async pasteData(value) {
      if (value) {
        let data = value.split("\n");
        data.forEach((row) => {
          let values = row.split("\t");
          let map = { parameter: values[0], value: values[1] };
          //  this.record["rulestable"].push({ rangevalue: values[0], chargevalue: values[1] });
          if (values.some((v) => v)) {
            if (this.config) {
              if (this.config.table) this.table = this.config.table;
              if (this.config.fields) {
                map = {};
                this.config.fields.forEach((field, index) => {
                  map[field] = values[index];
                });
              }
            }
            this.record[this.table].push(map);
          }
        });
        await this.$emit("emit", {});
        this.pasteData = "";
      }
    },
  },
};
</script>
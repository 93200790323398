<template>
  <v-data-table
    v-if="query.setup.collection"
    :headers="advanced ? advancedheaders : headers"
    :items="query.setup.columns"
    loading-text="Loading... Please wait"
    hide-default-footer
    item-key="index"
    :footer-props="{ itemsPerPageOptions: rows }"
    :item-class="rowClass"
  >
    <template v-slot:item.name="{ item }">
      <v-text-field
        class="mt-4 mb-2"
        v-model="item.name"
        label="Label"
        outlined
        hide-details
        dense
        :rules="[rules.required]"
        clearable
      ></v-text-field>
      <v-select
        :items="sorts"
        v-model="item.sort"
        label="Sort"
        item-text="name"
        item-value="_id"
        outlined
        dense
        clearable
      ></v-select>
    </template>
    <template v-slot:item.field="{ item }">
      <v-autocomplete
        class="mt-4 mb-2"
        :items="
          getFilteredFields(
            item.sourcesubrecord || item.source || item.subrecord
          )
        "
        v-model="item.field"
        label="Field"
        item-text="name"
        item-value="_id"
        outlined
        dense
        hide-details
        :rules="[rules.required]"
        clearable
      ></v-autocomplete>
      <v-textarea
        v-if="item.field && item.field.indexOf('formula') == 0"
        v-model="item.formula"
        label="Formula"
        outlined
        hide-details
        dense
        clearable
      ></v-textarea>
      <v-switch
        class="mt-0 mb-0"
        v-model="item.hidden"
        label="Hidden"
        outlined
        hide-details
        dense
        clearable
      ></v-switch>
    </template>
    <template v-slot:item.subrecord="{ item }">
      <v-autocomplete
        class="mt-4"
        :items="getFilteredSubrecords()"
        v-model="item.subrecord"
        label="Subrecords / Lists"
        item-text="name"
        item-value="_id"
        outlined
        dense
        clearable
      ></v-autocomplete>
    </template>

    <template v-slot:item.source="{ item }">
      <v-autocomplete
        class="mt-4 mb-2"
        :items="getFilteredFields(item.subrecord, true)"
        v-model="item.source"
        label="Source Field"
        item-text="name"
        item-value="_id"
        hide-details
        outlined
        dense
        clearable
      ></v-autocomplete>
      <v-autocomplete
        :items="getFilteredSubrecords(item.source)"
        v-model="item.sourcesubrecord"
        label="Subrecords / Lists"
        item-text="name"
        item-value="_id"
        outlined
        dense
        :disabled="!item.source"
        clearable
      ></v-autocomplete>
    </template>
    <!-- <template v-slot:item.sourcesubrecord="{ item }">
      <v-autocomplete
        class="mt-4"
        :items="getFilteredSubrecords(item.source)"
        v-model="item.sourcesubrecord"
        label="Source Subrecord"
        item-text="name"
        item-value="_id"
        outlined
        dense
        :disabled="!item.source"
      ></v-autocomplete>
    </template>-->
    <template v-slot:item.function="{ item }">
      <v-select
        class="mt-4 mb-2"
        :items="functions"
        v-model="item.function"
        label="Function"
        item-text="name"
        item-value="_id"
        outlined
        hide-details
        dense
        clearable
      ></v-select>
      <v-select
        :items="summaries"
        v-model="item.summary"
        label="Summary"
        item-text="name"
        item-value="_id"
        outlined
        dense
        clearable
      ></v-select>
    </template>
    <template v-slot:item.hidden="{ item }">
      <v-switch
        v-if="item.field && fieldsObj[item.field].setup.array"
        class="mt-0 mb-2"
        v-model="item.group"
        label="Group Array"
        outlined
        dense
      ></v-switch>
      <br />
      <v-switch
        v-if="item.field && item.field.indexOf('formula') == 0"
        class="mt-0 mb-2"
        v-model="item.aftersummary"
        label="Execute after summary"
        outlined
        dense
      ></v-switch>
      <!-- <br />
      <v-switch
        v-if="item.summary"
        class="mt-0 mb-0"
        v-model="item.aftersummary"
        label="After Summary"
        outlined
        dense
      ></v-switch> -->
      <!-- <v-fab-transition>
        <v-btn
          color="grey"
          dark
          absolute
          top
          x-small
          right
          fab
          @click="delColumn(item)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-fab-transition> -->
    </template>
    <template v-slot:item.action="{ item }">
      <v-btn @click="upColumn(item)" block icon tile color="primary">
        <v-icon>mdi-arrow-up-bold</v-icon>
      </v-btn>
      <v-btn @click="delColumn(item)" block icon tile>
        <v-icon>mdi-delete</v-icon>
      </v-btn>

      <v-btn @click="downColumn(item)" block icon tile color="primary">
        <v-icon>mdi-arrow-down-bold</v-icon>
      </v-btn>
    </template>
    <template v-slot:footer>
      <v-container style="position: relative">
        <v-row>
          <v-col>
            <v-fab-transition>
              <v-btn
                color="primary"
                dark
                absolute
                top
                small
                left
                fab
                @click="addColumn"
                style="margin-left: -20px"
              >
                <v-icon>add</v-icon>
              </v-btn>
            </v-fab-transition>
          </v-col>
          <v-switch v-model="advanced" label="Advanced"></v-switch>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              :items="groupby"
              v-model="query.setup.groupby"
              label="Group By"
              item-text="name"
              item-value="_id"
              outlined
              dense
              clearable
            ></v-select>
          </v-col>
          <v-col>
            <v-switch
              class="my-0"
              v-model="query.setup.collapse"
              label="Collapse All"
            ></v-switch>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-data-table>
</template>
<script>
/* eslint-disable */
export default {
  props: ["rid", "mode", "query", "fieldsObj", "fields", "subrecords"],
  data() {
    return {
      advanced: true,
      headers: [
        { text: "Action", value: "action", sortable: false },
        { text: "Name", value: "name", sortable: false },
        { text: "Field", value: "field", sortable: false },
        { text: "Function / Summary", value: "function", sortable: false },
        { text: "Options", value: "hidden", sortable: false },
      ],
      advancedheaders: [
        { text: "Action", value: "action", sortable: false },
        { text: "Name", value: "name", sortable: false },
        { text: "Subrecords / Lists", value: "subrecord", sortable: false },
        { text: "Field Source", value: "source", sortable: false },
        { text: "Field", value: "field", sortable: false },
        { text: "Function / Summary", value: "function", sortable: false },
        { text: "Options", value: "hidden", sortable: false },
      ],
      functions: [
        { name: "Day", _id: "$day" },
        { name: "Week", _id: "$week" },
        { name: "Month", _id: "$month" },
        { name: "Year", _id: "$year" },
        { name: "Quarter", _id: "$quarter" },
        { name: "ABS", _id: "$abs" },
      ],
      summaries: [
        { name: "Timeline", _id: "$timeline" },
        { name: "Group", _id: "$group" },
        { name: "Sum", _id: "$sum" },
        { name: "Max", _id: "$max" },
        { name: "Min", _id: "$min" },
      ],
      sorts: [
        { name: "ASC", _id: -1 },
        { name: "DSC", _id: 1 },
      ],
      rows: [100],
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  computed: {
    groupby() {
      return this.query.setup.columns
        .map((c, index) => {
          return { name: c.name, field: c.field, _id: `column#${index}` };
        })
        .filter(
          (g) =>
            this.fieldsObj[g.field] && this.fieldsObj[g.field].setup.collection
        );
    },
  },
  methods: {
    rowClass() {
      return "queryrow";
    },
    addColumn() {
      let idx = this.query.setup.columns.length;
      if (idx <= 100) {
        this.query.setup.columns.push({
          subrecord: null,
          field: null,
        });
      } else {
        alert("to many columns");
      }
    },
    delColumn(item) {
      const index = this.query.setup.columns.indexOf(item);
      confirm("Are you sure?") && this.query.setup.columns.splice(index, 1);
    },
    upColumn(item) {
      const index = this.query.setup.columns.indexOf(item);
      if (index > 0) {
        let tmp = { ...this.query.setup.columns[index - 1] };
        this.$set(
          this.query.setup.columns,
          index - 1,
          this.query.setup.columns[index]
        );
        this.$set(this.query.setup.columns, index, tmp);
      }
    },
    downColumn(item) {
      const index = this.query.setup.columns.indexOf(item);
      if (index < this.query.setup.columns.length - 1) {
        let tmp = { ...this.query.setup.columns[index + 1] };
        this.$set(
          this.query.setup.columns,
          index + 1,
          this.query.setup.columns[index]
        );
        this.$set(this.query.setup.columns, index, tmp);
      }
    },
    getFilteredFields(source, obj) {
      let collection = this.fieldsObj[source]
        ? this.fieldsObj[source].setup.collection
        : this.query.setup.collection;
      let filteredFields = this.fields.filter((field) => {
        return (
          field.setup.collection == collection ||
          (field &&
            field.form &&
            field.form.some((form) => {
              return (
                [collection, "all"].includes(form.collection) &&
                (obj && !field.setup.collection ? false : true)
              );
            }))
        );
      });
      return filteredFields;
    },
    getFilteredSubrecords(source) {
      let collection = this.fieldsObj[source]
        ? this.fieldsObj[source].setup.collection
        : this.query.setup.collection;
      let filteredFields = this.subrecords.filter((field) => {
        return (
          field &&
          field.form &&
          field.form.some((form) => {
            return form.collection == collection;
          })
        );
      });
      return filteredFields;
    },
  },
};
</script>
<style>
.queryrow {
  position: relative;
}
</style>
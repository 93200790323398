 <template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="mode == 'inventorytransfer' ? headers2 : headers"
          :items="items"
          :loading="loading"
          loading-text="Loading... Please wait"
          item-key="_id"
          v-model="selected"
          show-select
          :search="search"
          :single-select="singleSelect"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
          :custom-sort="sort"
        >
          <template v-slot:item.quantity="{ item }">
            <v-text-field
              label="To Order"
              single-line
              type="number"
              v-model="item.quantity"
            ></v-text-field>
          </template>
          <template v-slot:item.quantity2="{ item }">
            <v-text-field
              label="To Order"
              single-line
              type="number"
              v-model="item.quantity2"
            ></v-text-field>
          </template>
          <template v-slot:item.item="{ item }">
            <router-link
              :to="{
                path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`,
              }"
              class="link"
              >{{ item.item.displayname || item.item.name }}</router-link
            >
          </template>
          <template v-slot:item.customer="{ item }">
            <router-link
              :to="{
                path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
              }"
              class="link"
              >{{
                item.customer.displayname || item.customer.name
              }}</router-link
            >
          </template>
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col>
                  <exportData :headers="mode == 'inventorytransfer' ? headers2 : headers" :items="items" />
                </v-col>
                <v-col>
                  <v-select
                    :items="locations"
                    v-model="location"
                    :label="
                      mode == 'inventorytransfer'
                        ? 'Transfer From'
                        : 'PO Warehouse'
                    "
                    item-text="name"
                    item-value="_id"
                    outlined
                    clearable
                    hide-details
                    dense
                  ></v-select>
                </v-col>
                <v-col>
                  <v-select
                    ref="destination"
                    :items="locations"
                    v-model="destination"
                    :label="
                      internal == 'false'
                        ? 'Warehouse#2'
                        : mode == 'inventorytransfer'
                        ? 'Transfer To'
                        : 'From Warehouse'
                    "
                    item-text="name"
                    item-value="_id"
                    outlined
                    clearable
                    hide-details
                    dense
                  ></v-select>
                </v-col>
                <v-col>
                  <v-select
                    ref="buffer"
                    :items="locations"
                    v-model="buffer"
                    label="Warehouse#3"
                    item-text="name"
                    item-value="_id"
                    outlined
                    clearable
                    hide-details
                    dense
                  ></v-select>
                </v-col>
                <v-col>
                  <v-select
                    :items="vendors"
                    v-model="vendor"
                    label="Vendor"
                    item-text="name"
                    item-value="_id"
                    outlined
                    dense
                    clearable
                    object-return
                    hide-details
                  ></v-select>
                </v-col>
                <v-col v-if="size">
                  <v-switch v-model="custombox" label="Heavy Items"></v-switch>
                </v-col>
                <v-col>
                  <v-btn @click="getData()" block tile color="primary"
                    >Refresh</v-btn
                  >
                </v-col>
                <v-spacer></v-spacer>

                <v-col class="text-right">
                  <v-btn icon @click="show = !show">
                    <v-icon>{{
                      show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-expand-transition>
                <div v-show="show">
                  <toolfooter
                    :items="items"
                    :selected="selected"
                    :field.sync="field"
                    :defvendor="vendor"
                    stockorder="true"
                    :recordtype="mode"
                    :location="location"
                    :destination="internal == 'true' ? destination : null"
                    v-if="!loading"
                    :key="field"
                  />
                </div>
              </v-expand-transition>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import footer from "../backorderitems/footer";
import exportData from "../../dataTable/exportData";
import sort from "../../dataTable/sort";
export default {
  props: ["location", "destination", "buffer", "size", "mode", "internal"],
  data() {
    return {
      loading: false,
      headers: [
        { text: "Item", value: "item" },
        { text: "Stock Category", value: "stockcategory" },
        { text: "Stock Group", value: "stockgroup" },

        { text: "Available", value: "quantityavailable" },
        { text: "BO", value: "quantitybackorder" },
        { text: "Pref. Stock", value: "preferedstocklevel" },
        { text: "On Order", value: "quantityonorder" },
        { text: "In Transit", value: "intransit" },
        { text: "Purchase Price", value: "purchaseprice" },

        { text: "Available2", value: "destinationquantityavailable" },
        { text: "Pref. Stock2", value: "destinationpreferedstocklevel" },

        { text: "Available3", value: "bufferquantityavailable" },
        { text: "Pref. Stock3", value: "bufferpreferedstocklevel" },

        { text: "Weight", value: "weight" },
        { text: "To Order", value: "quantity" },
        { text: "Total", value: "total" },
        { text: "Total Weight", value: "totalweight" },
      ],
      headers2: [
        { text: "Item", value: "item" },

        { text: "Available", value: "quantityavailable" },
        { text: "Pref. Stock", value: "preferedstocklevel" },
        { text: "On Order", value: "quantityonorder" },
        { text: "In Transit", value: "intransit" },
        { text: "Purchase Price", value: "purchaseprice" },

        { text: "Available2", value: "destinationquantityavailable" },
        { text: "Pref. Stock2", value: "destinationpreferedstocklevel" },
        { text: "BO2", value: "destinationquantitybackorder" },

        { text: "Available3", value: "bufferquantityavailable" },
        { text: "Pref. Stock3", value: "bufferpreferedstocklevel" },
        { text: "BO3", value: "bufferquantitybackorder" },

        { text: "Max. to Transfer", value: "quantitytopack" },
        { text: "To Transfer", value: "quantity" },
      ],
      items: [],
      rows: [-1],
      singleSelect: false,
      selected: [],
      search: "",
      show: false,
      vendor: null,
      vendors: [],
      currency: null,
      currencies: [],
      message: "",
      countries: [],
      address: {},
      deliverymethod: null,
      deliverymethods: [],
      pricegroup: [],
      pricegroups: [],
      //   location: [],
      //   destination: [],
      //   buffer: [],
      locations: [],
      quantity: 0,
      weight: 0,
      amount: 0,
      fleetorder: true,
      open: true,
      dropship: true,
      custombox: true,
      field: "quantity",
    };
  },
  async created() {
    this.loading = true;
    this.vendors = await service("users", {
      action: "find",
      data: [
        {
          recordtype: { $in: ["vendor", "company"] },
          _id: {
            $in: [
              "maincompany",
              "5e14abf00398a160b00f5697",
              "5e14abfb0398a160b00f57c0",
              "6229bc5ad1f8737e98912e13"
            ],
          },
        },
      ],
    });

    this.locations = await service("accounting", {
      action: "find",
      data: [{ recordtype: "location" }],
    });

    //await this.getData();
    this.loading = false;
  },

  methods: {
    async getData() {
      this.loading = true;
      let result = await service(
        "orders",
        {
          action: "getTransferFomWarehouse",
          vendor: this.vendor ? this.vendor._id || this.vendor : null,
          location: this.location ? this.location._id || this.location : null,
          destination: this.destination
            ? this.destination._id || this.destination
            : null,
          buffer: this.buffer ? this.buffer._id || this.buffer : null,
          smallsize: this.size && !this.custombox ? true : false,
          custombox: this.size && this.custombox ? true : false,
          mode: this.mode,
        },
        false,
        true
      );
      this.items = result.items;
      if (this.mode == "purchaseorder") {
        this.headers[9].text = `Available (${this.$refs["destination"].selectedItems[0].name})`;
        this.headers[10].text = `PSL (${this.$refs["destination"].selectedItems[0].name})`;

        this.headers[11].text = `Available (${this.$refs["buffer"].selectedItems[0].name})`;
        this.headers[12].text = `PSL (${this.$refs["buffer"].selectedItems[0].name})`;
      } else {
        this.headers2[6].text = `Available (${this.$refs["destination"].selectedItems[0].name})`;
        this.headers2[7].text = `PSL (${this.$refs["destination"].selectedItems[0].name})`;
        this.headers2[8].text = `BO (${this.$refs["destination"].selectedItems[0].name})`;
        this.headers2[9].text = `Available (${this.$refs["buffer"].selectedItems[0].name})`;
        this.headers2[10].text = `PSL (${this.$refs["buffer"].selectedItems[0].name})`;
        this.headers2[11].text = `BO (${this.$refs["buffer"].selectedItems[0].name})`;
      }
      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    sort(items, sortBy, sortDesc) {
      return sort(items, sortBy, sortDesc);
    },
  },
  components: {
    toolfooter: footer,
    exportData: exportData,
  },
};
</script>
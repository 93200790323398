var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-tabs',{attrs:{"dark":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_vm._l((Object.values(_vm.tabs).filter(
            function (tab) { return (this$1.locationsFilter.includes(tab.location) ||
                !this$1.locationsFilter.length) &&
              !tab.hidden; }
          )),function(tab,index){return _c('v-tab',{key:index},[_c('v-badge',{attrs:{"color":"primary"}},[_c('span',[_vm._v(_vm._s(tab.name))]),_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_vm._v(_vm._s(tab.items.length))])])],1)}),_vm._l((Object.values(_vm.tabs).filter(
            function (tab) { return (this$1.locationsFilter.includes(tab.location) ||
                !this$1.locationsFilter.length) &&
              !tab.hidden; }
          )),function(tab,index){return _c('v-tab-item',{key:index},[_c('v-data-table',{attrs:{"headers":tab.headers,"items":tab.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","show-select":_vm.showselected,"search":_vm.search,"single-select":_vm.singleSelect,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter,"custom-sort":_vm.sort,"group-by":"Category"},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.date).toISOString().substr(0, 10)))])]}},{key:"item.shippingaddress",fn:function(ref){
          var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.shippingaddress))]),_c('div',[_vm._v(" "+_vm._s(item.shipzip)+" "+_vm._s(item.shipcountry)+" ")])]}},{key:"item.transaction",fn:function(ref){
          var item = ref.item;
return [_c('div',[_c('router-link',{staticClass:"link",attrs:{"to":{
                    path: ("/" + (item.transaction.collection) + "/" + (item.transaction.recordtype) + "/view/" + (item.transaction._id)),
                  }}},[_vm._v(_vm._s(item.transaction.displayname || item.transaction.name))]),_c('br'),(
                    item.nofiscal
                  )?_c('small',{staticStyle:{"background-color":"red","color":"white","padding":"2px"}},[_vm._v("Req. Internal Invoice")]):_vm._e(),(item.nofiscal)?_c('br'):_vm._e(),(
                    item.quantity ==
                    item.quantityfulfilled - item.quantitybilled
                  )?_c('small',{staticStyle:{"background-color":"#4caf50","color":"white","padding":"2px"}},[_vm._v("Fully Invoicing")]):_c('small',{staticStyle:{"background-color":"#555555","color":"white","padding":"2px"}},[_vm._v("Partly Invoicing")])],1)]}},{key:"item.customer",fn:function(ref){
                  var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                  path: ("/" + (item.customer.collection) + "/" + (item.customer.recordtype) + "/view/" + (item.customer._id)),
                }}},[_vm._v(_vm._s(item.customer.displayname || item.customer.name))])]}},{key:"item.trackingnumber",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.trackingnumber ? 'green' : 'none',"dark":""}},[_vm._v(_vm._s(item.trackingnumber ? "Yes" : "No"))])]}},{key:"item.comment",fn:function(ref){
                var item = ref.item;
return _vm._l((item.childs),function(wz,index){return _c('div',{key:wz._id,staticClass:"pt-2"},[(item.childs.length > 1)?_c('b',[_vm._v(_vm._s(wz.name))]):_vm._e(),_c('p',[_c('pre',[_vm._v(_vm._s(item.comment[index]))])])])})}},{key:"item.warehousememo",fn:function(ref){
                var item = ref.item;
return _vm._l((item.childs),function(wz,index){return _c('div',{key:wz._id},[(item.childs.length > 1)?_c('b',[_vm._v(_vm._s(wz.name)+": ")]):_vm._e(),_vm._v(" "+_vm._s(item.warehousememo[index])),_c('br'),_c('p',[_vm._v(_vm._s(item.packages[index].join(", ")))])])})}},{key:"item.shippingmethod",fn:function(ref){
                var item = ref.item;
return [_c('span',[(item.difshipmethod)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-alert")])]}}],null,true)},[_c('span',[_vm._v("Shipping Method different than on original SO! ("+_vm._s(item.originalshippingmethod)+")")])]):_vm._e(),_vm._l((item.childs),function(wz,index){return _c('span',{key:wz._id},[_vm._v(_vm._s(item.shippingmethod[index])),(index)?_c('br'):_vm._e(),(index)?_c('br'):_vm._e()])})],2)]}},{key:"item.billcountry",fn:function(ref){
                var item = ref.item;
return [_c('span',[(item.exportcustomsclearance)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-alert")])]}}],null,true)},[_c('span',[_vm._v("Export Customs Clearance")])]):_vm._e(),_vm._v(" "+_vm._s(item.billcountry)+" ")],1)]}},{key:"item.terms",fn:function(ref){
                var item = ref.item;
return [_c('span',[(item.waitingforpayment)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-alert")])]}}],null,true)},[_c('span',[_vm._v("Waiting for Payment!")])]):_vm._e(),_vm._v(" "+_vm._s(item.terms)+" ")],1)]}},{key:"item.childs",fn:function(ref){
                var item = ref.item;
return _vm._l((item.childs),function(wz){return _c('span',{key:wz._id},[(item.childs.length > 1)?_c('v-checkbox',{staticClass:"ma-0",attrs:{"disabled":!_vm.selected.find(function (s) { return s._id == item._id; }),"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('small',[_vm._v(_vm._s(wz.name))])]},proxy:true}],null,true),model:{value:(wz.filter),callback:function ($$v) {_vm.$set(wz, "filter", $$v)},expression:"wz.filter"}}):_c('span',[_c('router-link',{staticClass:"link",attrs:{"to":{
                      path: ("/transactions/itemfulfillment/view/" + (wz._id)),
                    }}},[_vm._v(_vm._s(wz.displayname || wz.name))])],1)],1)})}},{key:"top",fn:function(){return [_c('v-divider'),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col',[(_vm.showselected)?_c('v-btn',{attrs:{"disabled":!_vm.selected.length,"block":"","tile":"","color":"primary"},on:{"click":_vm.action}},[_vm._v("Init Invoice from Selected Orders")]):_vm._e()],1),_c('v-col',[(
                        _vm.showselected &&
                        tab.location == '61e14c6ae4a35a668517a8fe'
                      )?_c('v-btn',{attrs:{"disabled":!_vm.selected.length,"block":"","tile":"","color":"primary"},on:{"click":_vm.bulkAction}},[_vm._v("Create Invoices from Selected Orders")]):_vm._e()],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('v-icon',[_vm._v(_vm._s(_vm.show ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1)],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}]},[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.customers,"label":"Customer","item-text":"displayname","item-value":"_id","return-object":"","outlined":"","dense":"","clearable":""},model:{value:(_vm.customer),callback:function ($$v) {_vm.customer=$$v},expression:"customer"}}),_c('v-text-field',{attrs:{"type":"number","label":"Shipping Cost","outlined":"","dense":""},model:{value:(_vm.shippingcost),callback:function ($$v) {_vm.shippingcost=$$v},expression:"shippingcost"}})],1),_c('v-col',[_c('v-textarea',{attrs:{"outlined":"","label":"Message"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)],1)],1)])],1)]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col',[(_vm.showselected)?_c('v-btn',{attrs:{"disabled":!_vm.selected.length,"block":"","tile":"","color":"primary"},on:{"click":_vm.action}},[_vm._v("Init Invoice from Selected Orders")]):_vm._e()],1),_c('v-col',[(
                        _vm.showselected &&
                        tab.location == '61e14c6ae4a35a668517a8fe'
                      )?_c('v-btn',{attrs:{"disabled":!_vm.selected.length,"block":"","tile":"","color":"primary"},on:{"click":_vm.bulkAction}},[_vm._v("Create Invoices from Selected Orders")]):_vm._e()],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('v-icon',[_vm._v(_vm._s(_vm.show ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1)],1)],1)],1)})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
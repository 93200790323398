<template>
  <v-tabs dark v-model="active">
    <v-tab :key="1">Customers On Hold</v-tab>
    <v-tab :key="2">Export Customs Clearance (v.Beta)</v-tab>
    <v-tab :key="3">Prepaid Invoices - Not Shipped Yet</v-tab>
    <v-tab :key="4">Returns</v-tab>
    <v-tab :key="5">Statistics</v-tab>
    <v-tab :key="6">Open Item Fullfilments</v-tab>
    <v-tab :key="5"><v-icon>mdi-close-outline</v-icon></v-tab>
    <!-- <v-tab :key="5">Sales Report</v-tab> -->
    <v-tab-item :key="1">
      <customersonhold v-if="active == 0" />
    </v-tab-item>
    <v-tab-item :key="2">
      <exportcustomsclearance v-if="active == 1" />
    </v-tab-item>
    <v-tab-item :key="3">
      <queryresult queryid="5f2be7f654fc0b1f78c04951" v-if="active == 2" />
    </v-tab-item>
    <v-tab-item :key="4">
      <returnrequest v-if="active == 3" />
    </v-tab-item>
    <v-tab-item :key="5">
      <statistics v-if="active == 4" />
    </v-tab-item>
    <v-tab-item :key="6">
      <itemFulfillments v-if="active == 5" />
    </v-tab-item>
  </v-tabs>
</template>

<script>
import customersonhold from "../components/tools/customersonhold/View";
import exportcustomsclearance from "../components/tools/exportcustomsclearance/View";
import reports from "../components/tools/reports/View";
import returnrequest from "../components/tools/returnrequest/View";
import statistics from "../components/tools/statistics/ViewNEW";
import itemFulfillments from "../components/tools/itemfulfillments/View";
/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
      active: 6,
    };
  },
  components: {
    customersonhold,
    exportcustomsclearance,
    reports,
    returnrequest,
    statistics,
    itemFulfillments,
  },
};
</script>